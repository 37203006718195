import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { StoreListItemModel } from 'src/app/models';

@Component({
  selector: 'app-store-delete-confirmation',
  templateUrl: './store.delete.confirmation.component.html',
  styleUrls: ['./store.delete.confirmation.component.css']
})

export class StoreDeleteConfirmationComponent implements OnDestroy {

  @ViewChild('storeConfirmationModal', { static: false }) confirmationModal: any;
  @Output() delete = new EventEmitter<StoreListItemModel>();

  message: string;
  modalReference: NgbModalRef;
  store: StoreListItemModel;

  constructor(private modalService: NgbModal) {
  }

  openModal(store: StoreListItemModel) {
    this.store = store;
    this.modalReference = this.modalService.open(this.confirmationModal, { centered: true });
    this.message = this.getMessage();
  }

  getMessage() {
    return "Are you sure that you want to delete this store?";
  }

  onConfirm() {
    setTimeout(() => {
      this.delete.emit(this.store);
    });
    this.closeModal();
  }

  closeModal() {
    if(!this.modalReference){
        return;
    }
    this.modalReference.close();
  }
    
  ngOnDestroy(): void {
      this.closeModal();
  }
}
