<section class="invoiceFSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="invoicefh">
                    <h2>Invoice Format</h2>
                    <p>1912 Harvest Lane<br />
                        New York, NY 12220</p>
                </div>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-12 col-md-6">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="billShip">
                            <h3>Bill To</h3>
                            <p>John Smith<br />
                                2 Court Square<br />
                                New York, NY 12220</p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="billShip">
                            <h3>Ship To</h3>
                            <p>John Smith <br />
                                3787 Pineview Drive <br />
                                Cambridge, MA 12210</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="invoiceDtl">
                    <ul>
                        <li>
                            <span>Invoice #</span>
                            <span>-</span>
                            <span>US-001</span>
                        </li>
                        <li>
                            <span>Invoice Date</span>
                            <span>-</span>
                            <span>11-11-2020</span>
                        </li>
                        <li>
                            <span>P. O. #</span>
                            <span>-</span>
                            <span>23-11-202</span>
                        </li>
                        <li>
                            <span>Due Date</span>
                            <span>-</span>
                            <span>08-11-2020</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="tableWrp table-responsive mb-5">
                    <table class="table dataTable table-striped">
                        <thead>
                            <tr>
                                <th colspan="4">Invoice Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Invoice Number</td>
                                <td>000.0</td>
                                <td>Tax Date</td>
                                <td>01-11-2020</td>
                            </tr>
                            <tr>
                                <td>PO Number</td>
                                <td>0000.0</td>
                                <td>Tax Date</td>
                                <td>01-11-2020</td>
                            </tr>
                            <tr>
                                <td>PO Number</td>
                                <td>0000.0</td>
                                <td>Tax Date</td>
                                <td>01-11-2020</td>
                            </tr>
                            <tr>
                                <td>PO Number</td>
                                <td>0000.0</td>
                                <td>Tax Date</td>
                                <td>01-11-2020</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="tableWrp table-responsive">
                    <table class="table dataTable table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Description</th>
                                <th scope="col">Rate</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Gross</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Sample Item 1</td>
                                <td>$ 329.00</td>
                                <td>2.00</td>
                                <td>$ 10.00</td>
                            </tr>
                            <tr>
                                <td>Sample Item 1</td>
                                <td>$ 329.00</td>
                                <td>2.00</td>
                                <td>$ 10.00</td>
                            </tr>
                            <tr>
                                <td>Sample Item 1</td>
                                <td>$ 329.00</td>
                                <td>2.00</td>
                                <td>$ 10.00</td>
                            </tr>
                            <tr>
                                <td>Sample Item 1</td>
                                <td>$ 329.00</td>
                                <td>2.00</td>
                                <td>$ 10.00</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>