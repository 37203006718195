<div class="taskBox" [dndDropzone]="['category']" (dndDrop)="onDropCategory($event)"
    [ngClass]="{ 'disabled': isAllCategoriesDisabled, 'highlight': hasMandatoryOPCodes}"
    [dndDisableIf]="!isAllCategoryActive">
    <div class="taskBoxInner" *ngFor="let item of workflowStageModel.workflowStageCategories; let i = index;">
        <span class="taskContent">
            {{item.categoryName}} <span *ngIf="item.subCategoryName">- {{item.subCategoryName}}</span></span>
        <span class="taskIcon" *ngIf="i === 0">
            <button type="button" *ngIf="workflowStageModel.dependencyLocked" title="Dependency Locked">
                <em class="fa fa-lock" aria-hidden="true"></em></button>
            <button type="button" (click)="openWorkflowStepEditModal()" title="Edit" *ngIf="isAllCategoryActive"><em class="fa fa-edit"
                    aria-hidden="true"></em></button>
            <button type="button" (click)="openWorkflowStepDeleteModal()" title="Delete"><em class="fa fa-trash"
                    aria-hidden="true"></em></button>
        </span>
    </div>

    <div class="taskBoxInner drag-placeholder" dndPlaceholderRef>
        Drag Here
    </div>
</div>

<app-edit-workflow-stage #editModal (updated)="updated($event)" [categories]='categories'
    [subCategories]='subCategories'></app-edit-workflow-stage>
<app-workflow-stage-delete-confirmation #deleteConfirmationModal (delete)="delete($event)">
</app-workflow-stage-delete-confirmation>