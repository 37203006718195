import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { ApplicationRoleType, Category, PermissionType, QuotationStatus, VehicleQueueStatus, VehicleType } from "src/app/enums";
import { AuthenticateResponseModel, QuotationModel, SelectListModel, VehicleQueueModel, VehicleQueueStageModel } from "src/app/models";
import { MessagingService, QuotationService, VehicleQueueService, VehicleService } from "src/app/services";
import { VehicleTypeConfirmationComponent } from "../../assessment";
import { VendorQuoteComponent } from "../vendor-quote/vendor.quote.component";
import { VendorStatusComponent } from "../vendor-status/vendor.status.component";

@Component({
    selector: 'app-vehicle-queue-item',
    templateUrl: './vehicle-queue-item.component.html',
    styleUrls: ['./vehicle-queue-item.component.css']
})
export class VehicleQueueItemComponent implements OnInit, OnChanges {

    @ViewChild('vehicleTypeConfirmationComponent', { static: false }) vehicleTypeConfirmationComponent: VehicleTypeConfirmationComponent;
    @ViewChild('vendorQuoteComponent', { static: false }) vendorQuoteComponent: VendorQuoteComponent;
    @ViewChild('vendorStatusComponent', { static: false }) vendorStatusComponent: VendorStatusComponent;
    @ViewChild('slickCarouselComponent', { static: false }) slickCarouselComponent: SlickCarouselComponent;

    @Input() vehicleQueueModel: VehicleQueueModel;
    @Input() authenticateUser: AuthenticateResponseModel;
    @Input() categories: Array<string>;
    @Input() permissions: Array<number>;

    VehicleQueueStatus = VehicleQueueStatus;
    PermissionType = PermissionType;
    vehicleImageUrl: string;
    clockTimer: string = "/assets/images/clock-timer.png";
    vendors: Array<SelectListModel> = new Array<SelectListModel>();

    dynamicWorkflowCategories: Array<Category> = [
        Category.Assessment,
        Category.Approval,
        Category.Detail,
        Category.FinalInspection,
        Category.MechanicalAdvising,
        Category.Mechanical,
        Category.ReconPackage,
        Category.Wholesale,
        Category.Quote,

        Category.Paint,
        Category.DentRemoval,
        Category.Glass,
        Category.Wheel,
        Category.Interior,
        Category.Detail
    ];

    constructor(
        private route: Router,
        private messagingService: MessagingService,
        private vehicleQueueService: VehicleQueueService,
        private vehicleService: VehicleService,
        private quotationService: QuotationService
    ) {

    }

    ngOnInit() {
        if (this.vehicleQueueModel.imageUrl == null) {
            this.vehicleService.getImage(this.vehicleQueueModel.id).subscribe(url => {
                this.vehicleImageUrl = url || this.getDefaultImageUrl();
            });
        } else {
            this.vehicleImageUrl = this.vehicleQueueModel.imageUrl;
        }
    }

    getDefaultImageUrl(): string {
        if (this.vehicleQueueModel.vehicleType == VehicleType.Car) {
            return "/assets/images/default-car-image.jpg";
        }
        if (this.vehicleQueueModel.vehicleType == VehicleType.Suv) {
            return "/assets/images/default-suv-image.jpg";
        }
        if (this.vehicleQueueModel.vehicleType == VehicleType.Truck) {
            return "/assets/images/default-truck-image.jpg";
        }

        return "";
    }

    ngOnChanges(changes: SimpleChanges): void {

    }

    get currentStage(): VehicleQueueStageModel {
        return this.getInProgressStage() || this.getInWaitingStage();
    }

    getInProgressStage() {
        if (!this.vehicleQueueModel) {
            return null;
        }

        if (this.categories.length) {
            return this.vehicleQueueModel.vehicleQueueStages.find(x => this.categories.includes(x.categoryName) && x.status == VehicleQueueStatus.InProgress);
        }

        return this.vehicleQueueModel.vehicleQueueStages.find(x => x.status == VehicleQueueStatus.InProgress)
    }

    getInWaitingStage() {
        if (!this.vehicleQueueModel) {
            return null;
        }

        if (this.categories.length) {
            return this.vehicleQueueModel.vehicleQueueStages.find(x => this.categories.includes(x.categoryName) && x.status == VehicleQueueStatus.Waiting);
        }

        return this.vehicleQueueModel.vehicleQueueStages.find(x => x.status == VehicleQueueStatus.Waiting)
    }

    showStart() {
        let currentStage = this.getInProgressStage() || this.getInWaitingStage();

        //If current  stage is defined and is not in progress
        if (currentStage == null || currentStage.status != VehicleQueueStatus.Waiting) {
            return false;
        }

        return true;
    }

    showResume() {
        //If current  stage is defined and is not in progress
        if (this.currentStage == null || this.currentStage.status == VehicleQueueStatus.Waiting) {
            return false;
        }

        // If current stages belongs to dynamic workflow categories
        // then show resume        
        return this.dynamicWorkflowCategories.some(x => x == this.currentStage.categoryName);
    }

    showStop() {
        //If current  stage is defined and is not in progress
        if (this.currentStage == null || this.currentStage.status != VehicleQueueStatus.InProgress || this.currentStage.isQuoteRequired) {
            return false;
        }

        // If current stages belongs to dynamic workflow categories,
        // then hide stop
        return !this.dynamicWorkflowCategories.some(x => x == this.currentStage.categoryName);
    }

    showPause() {
        //If current  stage is defined and is not in progress
        if (this.currentStage == null || this.currentStage.status != VehicleQueueStatus.InProgress) {
            return false;
        }

        // If current stages belongs to dynamic workflow categories,
        // then hide stop
        return !this.dynamicWorkflowCategories.some(x => x == this.currentStage.categoryName);
    }

    /********* Open IVL Page Methods  - START*********** */
    onSelectVehicleType(type: VehicleType) {
        this.vehicleQueueModel.vehicleType = type;
        this.updateVehicleQueue();
    }

    openAssementModel() {
        if (this.vehicleQueueModel.vehicleType != VehicleType.None) {
            this.updateVehicleQueue();
            return;
        }
        this.vehicleTypeConfirmationComponent.openModal();
    }

    /********* Open IVL Page Methods - END *********** */

    start() {
        if (this.currentStage == null) {
            return;
        }

        switch (this.currentStage.categoryName) {
            case Category.Assessment:
                this.openAssementModel();
                break;
            case Category.Quote:
                if (this.currentStage.isQuoteRequired) {
                    if (this.authenticateUser.applicationRoleType === ApplicationRoleType.Vendor) {
                        this.startQuotation(this.currentStage.quotations[0])
                    } else {
                        this.vendors = this.currentStage
                            .quotations
                            .filter(quotation => quotation.status == QuotationStatus.NotStarted || quotation.status == QuotationStatus.InProgress)
                            .map(quotation => {
                                return new SelectListModel(quotation.vendorId, quotation.vendorName);
                            });
                        this.vendorQuoteComponent.openModal("START");
                    }
                } else {
                    this.updateVehicleQueue();
                }
                break;

            default:
                this.updateVehicleQueue();

        }
    }

    updateVehicleQueue() {
        if (this.currentStage.id == 0)
            return;

        if (this.currentStage.status == VehicleQueueStatus.InProgress) {
            this.performActionBasedOnCategory();
            return;
        }

        if (this.currentStage.categoryName == Category.Assessment) {
            this.vehicleQueueService.startAssessmentQueueStage(this.vehicleQueueModel.id, this.currentStage.id, this.vehicleQueueModel.vehicleType, this.vehicleQueueModel.storeId)
                .subscribe(() => {
                    this.performActionBasedOnCategory();
                }, error => {
                    this.messagingService.ProcessErrorResponse(error);
                });
        } else {
            this.vehicleQueueService.startQueueStage(this.vehicleQueueModel.id, this.currentStage.id, this.vehicleQueueModel.storeId)
                .subscribe(() => {
                    this.performActionBasedOnCategory();
                }, error => {
                    this.messagingService.ProcessErrorResponse(error);
                });
        }
    }

    performActionBasedOnCategory() {
        if (this.currentStage == null) {
            return;
        }

        switch (this.currentStage.categoryName) {
            case Category.Assessment:
                //Route to vehicle assessment
                this.route.navigate(['vehicle/assessment', this.vehicleQueueModel.storeId, this.vehicleQueueModel.id, this.currentStage.id, this.vehicleQueueModel.workflowId, this.vehicleQueueModel.vehicleType]);
                break;
            case Category.Approval:
                //Route to vehicle approval
                this.route.navigate(['vehicle/approval', this.vehicleQueueModel.id, this.currentStage.id]);
                break;
            case Category.FinalInspection:
                //Route to vehicle mechanical advising
                this.route.navigate(['vehicle/final-inspection', this.vehicleQueueModel.storeId, this.vehicleQueueModel.id, this.currentStage.id]);
                break;
            case Category.MechanicalAdvising:
                //Route to vehicle mechanical advising
                this.route.navigate(['vehicle/mechanical/advising', this.vehicleQueueModel.storeId, this.vehicleQueueModel.id, this.currentStage.id]);
                break;
            case Category.Mechanical:
                //Route to vehicle recon package
                this.route.navigate(['vehicle/mechanical/dispatch', this.vehicleQueueModel.storeId, this.vehicleQueueModel.id, this.currentStage.id]);
                break;
            case Category.ReconPackage:
                //Route to vehicle recon package
                this.route.navigate(['vehicle/recon/package', this.vehicleQueueModel.id, this.currentStage.id]);
                break;
            case Category.Paint:
            case Category.DentRemoval:
            case Category.Glass:
            case Category.Wheel:
            case Category.Interior:
            case Category.Detail:
                //Route to vendor action
                this.route.navigate(['vehicle/vendor/action', this.vehicleQueueModel.storeId, this.vehicleQueueModel.id, this.currentStage.id]);
                break;
            case Category.Quote:
                if (!this.currentStage.isQuoteRequired) {
                    this.route.navigate(['vehicle/vendor/action', this.vehicleQueueModel.storeId, this.vehicleQueueModel.id, this.currentStage.id]);
                }
                break;
            case Category.Wholesale:
                break;
        }
    }

    reject() {
        if (this.currentStage.isQuoteRequired) {
            if (this.authenticateUser.applicationRoleType === ApplicationRoleType.Vendor) {
                this.rejectQuotation(this.currentStage.quotations[0])
            } else {
                this.vendors = this.currentStage.quotations
                    .filter(quotation => quotation.status == QuotationStatus.InProgress)
                    .map(quotation => {
                        return new SelectListModel(quotation.vendorId, quotation.vendorName);
                    });
                this.vendorQuoteComponent.openModal("REJECT");
            }
            return;
        }

        this.vehicleQueueService.cancelQueueStage(this.vehicleQueueModel.id, this.vehicleQueueModel.storeId, this.currentStage.id).subscribe(() => {
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        });
    }

    stop() {
        if (this.currentStage.isQuoteRequired) {
            return;
        }

        this.vehicleQueueService.stopQueueStage(this.vehicleQueueModel.id, this.vehicleQueueModel.storeId, this.currentStage.id).subscribe(() => {
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        });
    }

    pause() {
        if (this.currentStage.isQuoteRequired) {
            if (this.authenticateUser.applicationRoleType === ApplicationRoleType.Vendor) {
                this.pauseQuotation(this.currentStage.quotations[0])
            } else {
                this.vendors = this.currentStage.quotations
                    .filter(quotation => quotation.status == QuotationStatus.InProgress)
                    .map(quotation => {
                        return new SelectListModel(quotation.vendorId, quotation.vendorName);
                    });
                this.vendorQuoteComponent.openModal("PAUSE");
            }
        }

        this.vehicleQueueService.pauseQueueStage(this.vehicleQueueModel.id, this.vehicleQueueModel.storeId, this.currentStage.id).subscribe(() => {
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        });
    }

    revise() {

    }

    convertSecondsIntoDays(seconds: number): string {
        //86400 = 24 * 60 * 60
        return (seconds / 86400).toFixed(1);
    }

    //#region Status
    getStageStatus(stage: VehicleQueueStageModel) {
        //If it has quotation, then status will be based on it
        //For Vendor We will get only one quotation
        if (stage.isQuoteRequired && Array.isArray(stage.quotations) && stage.quotations.length) {
            //If the authenticated use is not vendor
            if (this.authenticateUser.applicationRoleType == ApplicationRoleType.Vendor) {
                if (stage.status == VehicleQueueStatus.Completed ||
                    stage.status == VehicleQueueStatus.Hold) {
                    return this.getStatusBGClass(stage.status);
                }

                //Even Submitted then show it as completed
                if (stage.quotations.find(q => q.status == QuotationStatus.NotStarted)) {
                    return this.getStatusBGClass(VehicleQueueStatus.Waiting);
                }

                //Even If one is progress
                if (stage.quotations.find(q => q.status == QuotationStatus.InProgress)) {
                    return this.getStatusBGClass(VehicleQueueStatus.InProgress);
                }

                // if (stage.quotations.find(q => q.status == QuotationStatus.Submitted)) {
                //     return this.getStatusBGClass(VehicleQueueStatus.Completed);
                // }

                return this.getStatusBGClass(VehicleQueueStatus.Waiting);
            } else {
                if (stage.status == VehicleQueueStatus.Completed ||
                    stage.status == VehicleQueueStatus.Hold) {
                    return this.getStatusBGClass(stage.status);
                }

                //Even If one is progress
                if (stage.quotations.find(q => q.status == QuotationStatus.InProgress)) {
                    return this.getStatusBGClass(VehicleQueueStatus.InProgress);
                }

                return this.getStatusBGClass(VehicleQueueStatus.Waiting);
            }
        }

        return this.getStatusBGClass(stage.status);
    }

    private getStatusBGClass(status: VehicleQueueStatus): string {
        if (status == VehicleQueueStatus.NotStarted) {
            return 'black-bk';
        }
        if (status == VehicleQueueStatus.Waiting) {
            return 'orange-bk';
        }
        if (status == VehicleQueueStatus.InProgress) {
            return 'green-bk';
        }
        if (status == VehicleQueueStatus.Completed) {
            return 'grey-bk';
        }
        if (status == VehicleQueueStatus.Hold) {
            return 'red-bk';
        }
    }

    getQueueStatus() {
        return {
            'hold-bk': this.vehicleQueueModel.status == VehicleQueueStatus.Hold
        }
    }

    //#endregion

    //#region /****************Quotation Related*****************/
    startQuotation(quotation: QuotationModel) {
        if (!quotation) {
            return;
        }

        if (quotation.status === QuotationStatus.NotStarted) {
            this.quotationService.startQuotation(quotation.id, this.vehicleQueueModel.storeId, this.vehicleQueueModel.id).subscribe(() => {
                this.route.navigate(['vehicle/quotation', this.vehicleQueueModel.storeId, quotation.id, this.vehicleQueueModel.id, this.currentStage.id]);
            }, error => {
                this.messagingService.ProcessErrorResponse(error);
            });
        } else {
            this.route.navigate(['vehicle/quotation', this.vehicleQueueModel.storeId, quotation.id, this.vehicleQueueModel.id, this.currentStage.id]);
        }
    }

    rejectQuotation(quotation: QuotationModel) {
        if (!quotation) {
            return;
        }

        if (quotation.status == QuotationStatus.InProgress) {
            this.quotationService.cancelQuotation(quotation.id).subscribe(() => {
            }, error => {
                this.messagingService.ProcessErrorResponse(error);
            });
        }
    }

    pauseQuotation(quotation: QuotationModel) {
        if (!quotation) {
            return;
        }

        if (quotation.status == QuotationStatus.InProgress) {
            this.quotationService.pauseQuotation(quotation.id).subscribe(() => {
            }, error => {
                this.messagingService.ProcessErrorResponse(error);
            });
        }
    }

    onSelectVendor($event: any) {
        let quotation = this.currentStage.quotations.find(x => x.vendorId == $event.vendor.id);
        switch ($event.action) {
            case 'START':
                this.startQuotation(quotation);
                break
            case 'PAUSE':
                this.pauseQuotation(quotation);
                break
            case 'REJECT':
                this.rejectQuotation(quotation);
                break

        }
    }

    onSlideClick(vehicleQueueStage: VehicleQueueStageModel) {
        if (this.authenticateUser.applicationRoleType === ApplicationRoleType.Vendor || !vehicleQueueStage.isQuoteRequired) {
            return;
        }

        if (vehicleQueueStage.status == VehicleQueueStatus.Waiting || vehicleQueueStage.status == VehicleQueueStatus.InProgress) {
            this.vendorStatusComponent.openModal(vehicleQueueStage.id);
        }
    }

    //#endregion /****************Quotation Related*****************/

    //#region Slick carousel
    public ngAfterViewInit(): void {
        if (this.slickCarouselComponent) {
            let initialSlide = this.vehicleQueueModel.vehicleQueueStages.findIndex(x => x.id == this.currentStage.id);
            if (initialSlide == -1) {
                initialSlide = this.vehicleQueueModel.vehicleQueueStages.findIndex(x => x.status == VehicleQueueStatus.InProgress);
            }
            if (initialSlide == -1) {
                initialSlide = this.vehicleQueueModel.vehicleQueueStages.findIndex(x => x.status == VehicleQueueStatus.Waiting);
            }
            this.slickCarouselComponent.slickGoTo(initialSlide);
        }
    }

    slideConfig: any = {
        infinite: false,
        slidesToShow: 8,
        slidesToScroll: 4,
        autoplay: false,
        centerMode: false,
        arrows: true,
        prevArrow: '<div class="slick-prev"><em class="fa fa-chevron-left"></em></div>',
        nextArrow: '<div class="slick-next"><em class="fa fa-chevron-right"></em></div>',
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }

        ]
    };
    //#endregion
    showUpdateWorkflow() {
        return this.authenticateUser.permissions.includes(PermissionType.Approval) && this.currentStage.categoryName == Category.Approval;
    }

    navigateToUpdateWorkflow() {
        this.route.navigate(['/vehicle/workflow', this.vehicleQueueModel.workflowId, this.vehicleQueueModel.id, this.currentStage.id, 'edit'])
    }
}
