import { VehicleQueueStatus } from "src/app/enums";
import { QuotationModel } from "../quotation/quotation.model";

export class VehicleQueueStageModel {
    public id: number;
    public vehicleQueueId: number;
    public status: VehicleQueueStatus;
    public queueTimeInSeconds: number;
    public workTimeInSeconds: number;
    public sequence: number;
    public dependencyLocked: boolean;
    public dependencyLockedSequence: Nullable<number>;
    public categoryId: number;
    public subCategoryId: Nullable<number>;
    public workflowStageCategoryId: number;
    public workerUserId: string;

    public categoryCode: string;
    public categoryName: string;
    public subCategoryCode: string;
    public subCategoryName: string;
    public workerUserName: string;
    public isMandatoryStage: boolean;
    public isActive: boolean;
    public isQuoteRequired: boolean;
    public winningVendorId: Nullable<number>;

    public quotations: Array<QuotationModel>;

    constructor() {
        this.quotations = new Array<QuotationModel>();
    }
}