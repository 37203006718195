<section class="loginSec">
  <div class="container">
    <div class="loginLogo"><img [src]="logo" alt="Recon Partners Logo" /></div>
    <div class="loginWrp" *blockUI="'container-blockui'">

      <form class="loginform" [formGroup]="frm" (ngSubmit)="onSubmit()">
        <h2>Forgot Password</h2>
        <div class="form-group">
          <div class="labelWrp"><label>User Name/Email</label></div>
          <div class="fieldWrp">
            <input type="text" formControlName="email" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && frm.controls.email.errors }" />
            <div *ngIf="submitted && frm.controls.email.errors" class="invalid-feedback">
              <div *ngIf="frm.controls.email.errors.required">User Name/Email Id is required</div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <button class="btn btn-primary">Reset Password</button>
        </div>
        <div class="form-group" *ngIf="formSubmitted">
          <p>A temporary verification code and link has been sent to your registered email. Please click on the link
            to proceed with the verification process</p>
        </div>
      </form>

      <div class="forgotKpLogin">
        <div class="backtologin">
          <a [routerLink]="['/account/login']"><em class="fa fa-long-arrow-left" aria-hidden="true"></em> Back to
            Login</a>
        </div>
      </div>
    </div>
    <div class="wellcomDashboard">
      <h1>Welcome to <strong>My Dashboard</strong></h1>
    </div>
    <div class="copyright">
      <p>Copyright &copy; 2020. All rights reserved.</p>
    </div>
  </div>
</section>