
import { Component, EventEmitter, OnDestroy, Output, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { CheckInTypeDetailModel } from "src/app/models";


@Component({
    selector: 'app-delete-confirmation-check-in-type',
    templateUrl: './delete-confirmation.check-in-type.component.html',
    styleUrls: ['./delete-confirmation.check-in-type.component.css']
})

export class CheckInTypeDeleteConfirmationComponent implements OnDestroy {
    @BlockUI('container-blockui-confirmation') blockUI: NgBlockUI;
    @ViewChild('confirmationModal', { static: false }) confirmationModal: any;
    @Output() delete = new EventEmitter<any>();

    modalReference: NgbModalRef;
    model: CheckInTypeDetailModel;
    form: FormGroup;
    submitted: boolean;

    constructor(private modalService: NgbModal,
        private formBuilder: FormBuilder) {
    }

    openModal(category: CheckInTypeDetailModel) {
        this.model = category;
        this.form = this.formBuilder.group({
        });

        this.modalReference = this.modalService.open(this.confirmationModal, { centered: true });
    }

    onSubmit() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        this.form.reset();
        this.submitted = false;

        setTimeout(() => {
            this.delete.emit(this.model);
        });
        this.onCancel();
    }

    onCancel() {
        if(!this.modalReference){
            return;
        }
        this.modalReference.close();
    }
    
    ngOnDestroy(): void {
        this.onCancel();
    }
}