import { ApprovalQuotationModel } from "./approval.quotation.model";
import { ApprovalVehicleWorkDetailModel } from "./approval.vehicle.work.detail.model";

export class ApprovalModel {
    public vehicleQueueId: number;
    public vehicleQueueStageId: number;
    public approvalQuotations: Array<ApprovalQuotationModel>;
    public mechnicalAdvisingWorkModels: Array<ApprovalVehicleWorkDetailModel>;
    public addedCosmeticWorkModels: Array<ApprovalVehicleWorkDetailModel>;

    constructor() {
        this.approvalQuotations = new Array<ApprovalQuotationModel>();
        this.mechnicalAdvisingWorkModels = new Array<ApprovalVehicleWorkDetailModel>();        
        this.addedCosmeticWorkModels = new Array<ApprovalVehicleWorkDetailModel>();
    }
}