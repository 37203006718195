<ng-template #confirmationModal let-modal>
    <div *blockUI="'container-blockui-confirmation'">
        <div class="modal-body">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="confirmPopup">
                    <h5 class="modal-title">Delete</h5>

                    <p>Are you sure that you want to <span
                            class="highlight">delete</span>
                        this OP Code?</p>

                    <div class="buttonWrp">
                        <button type="submit" class="btn btn-red m-r-5">Yes</button>
                        <button type="button" class="btn btn-tsrp" (click)="onCancel($event)">No</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>