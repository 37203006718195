<section class="gnrlformSec" *blockUI="'container-blockui'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6">
        <!-- <h2 class="approvalH">Approval Form</h2> -->
      </div>

      <div class="col-12 col-md-12 col-lg-6 text-right">
        <app-queue-timer [vehicleQueueStageModel]="vehicleQueueStageModel"></app-queue-timer>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12 col-lg-12">
        <div class="tableWrp table-responsive">
          <app-vehicle-info [vehicleQueueModel]="vehicleQueueModel" [showFullVin]="false"></app-vehicle-info>
        </div>
      </div>
    </div>
  </div>

  <br>
  <div class="container-fluid">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
          <div class="tableWrp ">
            <div class="bluetableWrp  table-responsive">
              <table class="table dataTable table-striped">
                <thead>
                  <tr>
                    <th scope="col">Category</th>
                    <th scope="col">Tool</th>
                    <th scope="col">Panel</th>
                    <th scope="col">Location</th>
                    <th scope="col">Side</th>
                    <th scope="col">Cost ($)</th>
                    <th scope="col">Added Date</th>
                    <th scope="col">Worker Name</th>
                    <th scope="col">Comment</th>

                    <!-- <th scope="col">Is Rejected</th>
                    <th scope="col">Rejection Comment</th>
                    <th scope="col">New Cost</th> -->

                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody formArrayName="vehicleWorkDetailModels">
                  <tr *ngFor="let item of form.get('vehicleWorkDetailModels').controls; let i = index;"
                    [formGroupName]="i">
                    <td>{{ item.controls.operationCodeDetailModel.value.categoryName }}</td>
                    <td>{{ item.controls.operationCodeDetailModel.value.subCategoryName }}</td>
                    <td>{{ item.controls.operationCodeDetailModel.value.carLayoutPanelName }}</td>
                    <td>{{ item.controls.operationCodeDetailModel.value.carLayoutLocationName }}</td>
                    <td>{{ item.controls.operationCodeDetailModel.value.carLayoutSideName }}</td>
                    <td>{{ item.controls.cost.value| currency : 'USD' : '' : '1.2-2'}}</td>
                    <td>{{ item.controls.addedOn.value | date:'MM/dd/yyyy hh:mm:ss a' }}</td>
                    <td>{{ item.controls.addedByUser.value}}</td>
                    <td>{{ item.controls.assessorComment.value}} </td>

                    <!-- <td>{{ item.controls.isRejected.value ? 'Yes' : 'No' }}</td>
                    <td>{{ item.controls.rejectedComment.value }}</td>
                    <td>{{ item.controls.newCost.value| currency : 'USD' : '' : '1.2-2'  }}</td> -->

                    <td class="text-center nowrap">
                      <button type="button" class="btn btn-sm btn-red" (click)="onReject(item.controls.id.value)">
                        <em class="fa fa-ban" aria-hidden="true"></em> Reject </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12">
          <div class="btnWrp text-right mt-0">
            <button class="btn btn-tsrp m-r-5" type="button" (click)="onPause()">Pause</button>
            <button class="btn btn-red" type="submit">Finish</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>

<app-vendor-item-rejection #vendorItemRejectionComponent (reject)="rejectItem($event)">
</app-vendor-item-rejection>
<app-vendor-invoice #vendorInvoiceComponent (invoiceSubmit)="onInvoiceSubmit($event)"></app-vendor-invoice>
