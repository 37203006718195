<table class="table dataTable table-striped" summary="Vehicle Info">
	<thead>
		<tr>
			<th scope="col">Year</th>
			<th scope="col">Make</th>
			<th scope="col">Model</th>
			<th scope="col">Color</th>
			<th scope="col">Stock#</th>
			<th scope="col">VIN</th>
			<th scope="col">Mileage</th>
			<th scope="col" *ngIf="vehicleQueueModel.repairOrderNumber">RO #</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>{{vehicleQueueModel.year}}</td>
			<td>{{vehicleQueueModel.make}}</td>
			<td>{{vehicleQueueModel.model}}</td>
			<td>{{vehicleQueueModel.basicColor}}</td>
			<td>{{vehicleQueueModel.stockNumber}}</td>
			<td *ngIf="showFullVin">{{vehicleQueueModel.vehicleIdentificationNumber}}</td>
			<td *ngIf="!showFullVin">{{vehicleQueueModel.vehicleIdentificationNumber | slice:-8}}</td>
			<td>{{vehicleQueueModel.mileage}}</td>
			<td *ngIf="vehicleQueueModel.repairOrderNumber">{{vehicleQueueModel.repairOrderNumber}}</td>
		</tr>
	</tbody>
</table>