<ng-template #confirmationModal let-modal>
  <div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="confirmPopup">
        <h5 class="modal-title">Vendor</h5>

        <div class="form-group">
          <select class="form-control" formControlName="vendorId"
            [ngClass]="{ 'is-invalid': submitted && form.controls.vendorId.errors }" placeholder="Vendor">
            <option value="">Select Vendor</option>
            <option *ngFor="let item of vendors" [value]="item.id">
              {{item.name}}
            </option>
          </select>
          <div *ngIf="submitted && form.controls.vendorId.errors && form.controls.vendorId.errors.required"
            class="invalid-feedback">
            Vendor is
            required
          </div>
        </div>

        <div class="buttonWrp">
          <button type="submit" class="btn btn-red m-r-5">Submit</button>
          <button type="button" class="btn btn-tsrp" (click)="onCancel($event)">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
