var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI } from 'ng-block-ui';
import { StorageContainerType } from 'src/app/enums';
import { StorageService } from 'src/app/services';
export class PlayVideoComponent {
    constructor(modalService, storageService) {
        this.modalService = modalService;
        this.storageService = storageService;
        this.playImg = 'assets/images/play.png';
    }
    ngOnInit() {
        this.storageService.getPublicUrl(StorageContainerType.AssessmentVideo, this.recordingFileName)
            .subscribe((url) => {
            this.publicUrl = url;
        });
    }
    ngOnDestroy() {
        this.onCancel();
    }
    openModal() {
        this.modalReference = this.modalService.open(this.playVideoModal, {
            centered: true,
            scrollable: true,
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });
    }
    onCancel() {
        if (!this.modalReference) {
            return;
        }
        this.modalReference.close();
    }
    onPlayRecording(videoTag) {
        if (!this.publicUrl) {
            return;
        }
        videoTag.src = this.publicUrl;
        videoTag.type = 'video/mp4';
        videoTag.play();
    }
    ;
}
__decorate([
    BlockUI('container-blockui-vehicle-note'),
    __metadata("design:type", Object)
], PlayVideoComponent.prototype, "blockUI", void 0);
