import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SubCategoryService {
    constructor(http) {
        this.http = http;
    }
    get(id) {
        return this.http.get(`${environment.apiBaseUrl}/sub-category/${id}`);
    }
    add(model) {
        return this.http.post(`${environment.apiBaseUrl}/sub-category`, model);
    }
    update(model) {
        return this.http.put(`${environment.apiBaseUrl}/sub-category`, model);
    }
    delete(id) {
        return this.http.delete(`${environment.apiBaseUrl}/sub-category/${id}`);
    }
    toggleStatus(id) {
        return this.http.patch(`${environment.apiBaseUrl}/sub-category/toggle-status/${id}`, null);
    }
    query(dataTablesParameters) {
        return this.http.post(`${environment.apiBaseUrl}/sub-category/query`, dataTablesParameters, {});
    }
    selectList(categoryId) {
        return this.http.get(`${environment.apiBaseUrl}/category/${categoryId}/sub-category/select-list`);
    }
    getAllSubCategories() {
        return this.http.get(`${environment.apiBaseUrl}/sub-category/select-list`);
    }
    selectListByStore(id, categoryId) {
        return this.http.get(`${environment.apiBaseUrl}/store/${id}/${categoryId}/sub-category/select-list`);
    }
}
SubCategoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubCategoryService_Factory() { return new SubCategoryService(i0.ɵɵinject(i1.HttpClient)); }, token: SubCategoryService, providedIn: "root" });
