import { Component, ViewChild, OnInit } from '@angular/core';
import { AppUtils } from 'src/app/helpers';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { RoleService } from '../../../services/role.service';
import { RoleModel } from '../../../models/role/role.model';
import { PermissionModel, RoleDetailModel, SelectListModel } from 'src/app/models';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FilterStateService, MessagingService } from 'src/app/services';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';

@Component({
	selector: 'app-role-index',
	templateUrl: './index.role.component.html',
	styleUrls: ['./index.role.component.css']
})

export class RoleIndexComponent implements OnInit {

	frmSearch: FormGroup;
	filterOptions: any;
	statusOptions: any;

	//Data Table Options
	@ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
	dtOptions: DataTables.Settings = {};
	dtInstance: DataTables.Api;
	rowIndex: number = 0;
	applicationRoleTypes: Array<SelectListModel>;
	permissions: Array<PermissionModel> = new Array<PermissionModel>();
	@BlockUI('container-blockui-role-grid') blockUI: NgBlockUI;
	@ViewChild('deleteRole', { static: false }) deleteRole: any;

	constructor(private formBuilder: FormBuilder,
		private router: Router,
		private messagingService: MessagingService,
		public appUtils: AppUtils,
		private roleService: RoleService,
		private filterStateService: FilterStateService
	) {
		this.filterOptions = this.appUtils.getFilterOperationOptions();
		this.statusOptions = this.appUtils.getStatusOptions();
		this.applicationRoleTypes = this.appUtils.getApplicationRoleTypes();
	}

	ngAfterViewInit() {
		this.updateFormFromFilterModel();

		this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);
	}

	updateFormFromFilterModel() {
        const tempFilterModel = this.filterStateService.getFilterModel(FilterPageType.roleManage);
        if (tempFilterModel && tempFilterModel.isActive) {
            this.frmSearch.controls.roleName.setValue(tempFilterModel.filterKey);
            this.frmSearch.controls.filterOption.setValue(tempFilterModel.filterOption);
            this.frmSearch.controls.statusOption.setValue(tempFilterModel.statusOption);
        }
    }

    updateFilterModel() {
        const filterModel = this.filterStateService.getFilterModel(FilterPageType.roleManage);
        filterModel.page = FilterPageType.roleManage;
        filterModel.filterKey = this.frmSearch.controls.roleName.value;
        filterModel.filterOption = this.frmSearch.controls.filterOption.value;
        filterModel.statusOption = this.frmSearch.controls.statusOption.value;
        filterModel.isActive = false;
    }


	ngOnDestroy(): void {
		
	}


	ngOnInit() {
		this.frmSearch = this.formBuilder.group({
			roleName: [''],
			filterOption: [1], //Contains
			statusOption: [2] //Active
		});

		this.loadPermissions();
		this.initDataTable();

		this.frmSearch.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
			this.applyFilter();
		});
	}

	applyFilter() {
		this.dtInstance.ajax.reload();
	}

	initDataTable() {
		//Data Table Example.
		const self = this;
		this.dtOptions = {
			dom: '<"top">rt<"bottom"lip><"clear">',
			serverSide: true,
			processing: true,
			language: {
				loadingRecords: '&nbsp;',
				processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
				searchPlaceholder: 'Filter...',
				paginate: {
					first: '<em class="fa fa-angle-double-left">',
					last: '<em class="fa fa-angle-double-right">',
					previous: '<em class="fa fa-angle-left">',
					next: '<em class="fa fa-angle-right">'
				}
			},
			search: { search: null },
			searching: false,
			displayStart: self.rowIndex,
			paging: false,
			pagingType: 'full_numbers',
			pageLength: environment.paging.defaultPageSize,
			lengthMenu: environment.paging.lengthMenu,
			order: [[0, 'asc']],
			stateSave: true,
			info: false,
			scrollY: (window.innerHeight - 70 - 45) + "px",
            scrollCollapse:true,
			ajax: (dataTablesParameters: any, callback) => {

				self.updateFilterModel();

				dataTablesParameters.name = self.frmSearch.controls.roleName.value;
				dataTablesParameters.filterBy = Number(self.frmSearch.controls.filterOption.value);
				dataTablesParameters.status = self.statusOptions.find(x => x.id == self.frmSearch.controls.statusOption.value).value;

				self.blockUI.start();
				self.roleService.query(dataTablesParameters)
					.subscribe(resp => {
						callback({
							recordsTotal: resp.recordsTotal,
							recordsFiltered: resp.recordsFiltered,
							data: resp.data
						});
						self.blockUI.stop();
					}, error => {
						self.messagingService.ProcessErrorResponse(error);
						self.blockUI.stop();
					});
			},
			columns: [
				{
					data: 'name',
					title: 'Name',
					width: '20%',
					render: function (data, type, row) {
						return `<span class='link' action-type='link-detail'>${row.name}</a>`;
					}
				},
				{
					data: 'applicationRoleType',
					title: 'Role Type',
					width: '10%',
					render: function (applicationRoleType) {
						return self.applicationRoleTypes.find(x => x.id == applicationRoleType).name;
					}
				},
				{
					data: 'permissions',
					title: 'Permissions',
					orderable: false,
					width: '50%',
					render: function (permissions) {
						if (!Array.isArray(permissions) || permissions.length == 0) {
							return '';
						}
						return self.permissions.filter(x => permissions.includes(x.permission)).map(x => x.name).sort().join(', ');
					}
				},
				{
					data: 'isActive',
					title: 'Status',
					width: '8%',
					render: function (isActive) {
						return isActive
							? '<span class="badge badge-success">Active</span>'
							: '<span class="badge badge-danger">Inactive</span>';
					}
				},
				{
					data: null,
					title: 'Action',
					width: '12%',
					orderable: false,
					className: 'text-center',
					visible: true,
					render: function (data, type, row) {
						//Render Activate or Deactivate Button
						let statusHtml = row.isActive ?
							`<button title='Deactivate' action-type='toggle-status'><em class="fa fa-ban" aria-hidden="true"></em></button>` :
							`<button title='Activate' action-type='toggle-status'><em class="fa fa-check" aria-hidden="true"></em></button>`;

						return `
						${statusHtml}
                		<button title='Edit' action-type='edit'><em class="fa fa-edit" aria-hidden="true"></em></button>
                		<button title='Delete' action-type='delete'><em class="fa fa-trash" aria-hidden="true"></em></button>
					`;
				}
			}
		],
			rowCallback: function (row, data: RoleModel, dataIndex: number) {
				const _row = $(row);

				_row.find('[action-type="edit"]')
					.off('click')
					.on('click', function () {
						self.router.navigate(['/role/edit/', data.id]);
					});

				_row.find('[action-type="delete"]')
					.off('click')
					.on('click', function () {
						self.openRoleDeleteModal(data);
					});

				_row.find('[action-type="toggle-status"]')
					.off('click')
					.on('click', function () {
						self.toggleStatus(data);
					});
			}
		};
	}

	openRoleDeleteModal(data: RoleModel) {
		this.deleteRole.openModal(data);
	}

	emitDeleteConfirmation(role: RoleModel) {
		this.blockUI.start();
		this.roleService.delete(role.id).subscribe(
			() => {
				setTimeout(() => {
					this.dtInstance.ajax.reload(null, false);
				});
				this.messagingService.success(`Role has been deleted successfully.`);
			},
			error => {
				this.messagingService.ProcessErrorResponse(error);
			},
			() => {
				this.blockUI.stop();
			});
	}

	toggleStatus(role: RoleModel) {
		this.blockUI.start();
		this.roleService.toggleStatus(role.id).subscribe(
			() => {
				setTimeout(() => {
					this.dtInstance.ajax.reload(null, false);
				});
				this.messagingService.success(`Role has been ${(role.isActive ? 'deactivated' : 'activated')} successfully.`);
			},
			error => {
				this.messagingService.ProcessErrorResponse(error);
			},
			() => {
				this.blockUI.stop();
			});
	}

	private loadPermissions() {
		this.roleService.getAllPermissions().subscribe((permissions) => {
			this.permissions = permissions;
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
		});
	}
}
