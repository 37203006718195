import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl, AbstractControl } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { VehicleAgeType, VehicleType } from 'src/app/enums';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { AutoImportViewModel, CompanyWithStoreModel, SelectListModel, VehicleImportFilterModel, VehicleImportModel, WorkflowSelectListModel } from 'src/app/models';
import { ApplicationContextService, MessagingService, VehicleImportService, VehicleService, WorkflowService } from 'src/app/services';

@Component({
	selector: 'app-auto-import-vehicle-details',
	templateUrl: './auto-import-vehicle.component.html',
	styleUrls: ['./auto-import-vehicle.component.css']
})
export class AutoImportVehicleComponent implements OnInit, OnDestroy {
	@BlockUI('container-blockui') blockUI: NgBlockUI;
	dateModel: NgbDateStruct;
	calenderIcon: string = "/assets/images/calender-icon.png";
	filterForm: FormGroup;
	form: FormGroup;
	submitted: boolean;

	viewModels: Array<AutoImportViewModel> = new Array<AutoImportViewModel>();
	workflowSelectList: Array<WorkflowSelectListModel> = new Array<WorkflowSelectListModel>();
	stores: Array<SelectListModel> = new Array<SelectListModel>();
	basicColors: Array<string> = new Array<string>();

	vehicleAgeTypes: Array<SelectListModel> = new Array<SelectListModel>();
	vehicleTypes: Array<SelectListModel> = new Array<SelectListModel>();

	maxDate = {
		year: new Date().getFullYear(),
		month: new Date().getMonth() + 1,
		day: new Date().getDate()
	};

	companySubscription: any;
	storeSubscription: any;

	constructor(private formBuilder: FormBuilder,
		private messagingService: MessagingService,
		private vehicleImportService: VehicleImportService,
		public stringLengthConstants: StringLengthConstants,
		private workflowService: WorkflowService,
		private appUtils: AppUtils,
		private applicationContextService: ApplicationContextService,
		private vehicleService: VehicleService
	) {
		this.basicColors = this.appUtils.getBasicColors();
		this.vehicleAgeTypes = this.appUtils.getVehicleAgeTypes();
		this.vehicleTypes = this.appUtils.getVehicleTypes();
	}

	ngAfterViewInit(): void {
	}

	ngOnDestroy(): void {
		this.companySubscription.unsubscribe();
		this.storeSubscription.unsubscribe();
	}

	ngOnInit() {
		this.filterForm = this.formBuilder.group({
			vehicleAgeType: [VehicleAgeType.Used],
			vehicleType: [''],
			companyId: [],
			storeId: [''],
			vin: [''],
			stockNumber: [''],
			onHold: ['false']
		});

		this.form = this.formBuilder.group({
			selectAll: [false],
			vehicles: this.formBuilder.array([])
		});

		this.loadData();
		this.onFilterChange();

		this.companySubscription = this.applicationContextService.company$.subscribe((company: CompanyWithStoreModel) => {
			if (company == null) {
				this.stores = new Array<SelectListModel>();
				this.filterForm.controls.companyId.setValue(null);
			} else {
				this.stores = company.stores;
				this.filterForm.controls.companyId.setValue(company.id);
			}

		});
		this.storeSubscription = this.applicationContextService.store$.subscribe((storeSelectListItem: SelectListModel) => {
			if (storeSelectListItem) {
				this.filterForm.controls.storeId.setValue(storeSelectListItem.id);
			} else {
				this.filterForm.controls.storeId.setValue('');
			}
		});
	}

	private getFilterModel(): VehicleImportFilterModel {
		let filterModel = new VehicleImportFilterModel();
		filterModel.companyId = this.filterForm.value.companyId ? Number(this.filterForm.value.companyId) : null;
		filterModel.vehicleIdentificationNumber = this.filterForm.value.vin ? this.filterForm.value.vin : null;
		filterModel.stockNumber = this.filterForm.value.stockNumber ? this.filterForm.value.stockNumber : null;
		filterModel.storeId = this.filterForm.value.storeId ? Number(this.filterForm.value.storeId) : null;
		filterModel.vehicleAgeType = this.filterForm.value.vehicleAgeType ? Number(this.filterForm.value.vehicleAgeType) : null;
		filterModel.vehicleType = this.filterForm.value.vehicleType ? Number(this.filterForm.value.vehicleType) : null;
		filterModel.onHold = this.filterForm.value.onHold ? this.filterForm.value.onHold == 'true' : null;
		return filterModel;
	}

	private loadData() {
		// this.blockUI.start();
		this.workflowService.selectList().subscribe((workflows) => {
			this.workflowSelectList = workflows;
			// this.blockUI.stop();
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
			// this.blockUI.stop();
		});
	}

	private onFilterChange() {
		this.filterForm.valueChanges.pipe(
			debounceTime(500),
			distinctUntilChanged()
		).subscribe(() => {
			this.form.controls.selectAll.setValue(false);
			this.fetchImportableVehicles();
		});
	}

	private fetchImportableVehicles() {
		this.blockUI.start();
		this.vehicleImportService.fetchImportableVehicles(this.getFilterModel()).subscribe(importableVehicles => {
			this.removePreviousImportedList();
			this.populateVehicles(importableVehicles);
			this.blockUI.stop();
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
			this.blockUI.stop();
		});
	}

	private removePreviousImportedList() {
		//Remove previous imported list
		let vehicles = this.form.controls.vehicles as FormArray;
		let indices = new Array<number>();
		this.viewModels.forEach((elem, index) => {
			if (!elem.autoImportModel.isAddedManually) {
				indices.push(index);
			}
		});
		indices.reverse().forEach(i => {
			vehicles.removeAt(i);
			this.viewModels.splice(i, 1);
		});
	}

	private addVehicle(model: AutoImportViewModel): void {

		let autoImportModel = model.autoImportModel;
		let fb = this.formBuilder.group({
			storeId: [autoImportModel.storeIds.length ? autoImportModel.storeIds[0] : '', [Validators.required]],
			workflowId: [autoImportModel.workflowId || '', [Validators.required]],
			year: [autoImportModel.year || (new Date).getFullYear(), [
				Validators.min(1000),
				Validators.max(new Date().getFullYear()),
				Validators.required
			]],
			make: [autoImportModel.make || '', [
				Validators.required,
				Validators.maxLength(this.stringLengthConstants.Default)
			]],
			model: [autoImportModel.model || '', [
				Validators.required,
				Validators.maxLength(this.stringLengthConstants.Default)
			]],
			color: [autoImportModel.color || '', [
				Validators.maxLength(this.stringLengthConstants.Default)
			]],
			basicColor: [autoImportModel.basicColor || '', [
				Validators.required,
				Validators.maxLength(this.stringLengthConstants.Default)
			]],
			stockNumber: [autoImportModel.stockNumber || '', [
				Validators.required,
				Validators.maxLength(this.stringLengthConstants.Default),
				Validators.pattern('^[A-Z0-9]+$')
			]],
			vin: [autoImportModel.vehicleIdentificationNumber || '', [
				Validators.required,
				Validators.minLength(this.stringLengthConstants.VIN),
				Validators.maxLength(this.stringLengthConstants.VIN),
				Validators.pattern('^[A-Z0-9]+$')
			]],
			mileage: [autoImportModel.mileage || 0, [
				Validators.required,
				Validators.min(0),
				//Validators.pattern(new RegExp('^[0-9]*$', 'g'))
			]],
			inventoryDate: [{ value: autoImportModel.inventoryDate, disabled: true }],
			isSelected: [false],
			isAddedManually: [autoImportModel.isAddedManually],
			id: [autoImportModel.id],
			vehicleAgeType: [autoImportModel.vehicleAgeType || VehicleAgeType.Other],
			body: [autoImportModel.body],
			modelNumber: [autoImportModel.modelNumber],
			series: [autoImportModel.series, [
				Validators.maxLength(this.stringLengthConstants.Default)
			]],
			onHold: [autoImportModel.onHold],
			vehicleType: [autoImportModel.vehicleType || VehicleType.None],
		}, {
			validators: [
				(formGroup: FormGroup) => {
					this.validateControl(formGroup.controls.isSelected.value, formGroup.controls.storeId);
					this.validateControl(formGroup.controls.isSelected.value, formGroup.controls.workflowId);
					this.validateControl(formGroup.controls.isSelected.value, formGroup.controls.year);
					this.validateControl(formGroup.controls.isSelected.value, formGroup.controls.make);
					this.validateControl(formGroup.controls.isSelected.value, formGroup.controls.model);
					this.validateControl(formGroup.controls.isSelected.value, formGroup.controls.series);
					this.validateControl(formGroup.controls.isSelected.value, formGroup.controls.basicColor);
					this.validateControl(formGroup.controls.isSelected.value, formGroup.controls.stockNumber);
					this.validateControl(formGroup.controls.isSelected.value, formGroup.controls.vin);
					this.validateControl(formGroup.controls.isSelected.value, formGroup.controls.mileage);
				}
			]
		});

		(this.form.controls.vehicles as FormArray).insert(0, fb);
		this.form.controls.selectAll.setValue(false);
	}

	private validateControl(condition: boolean, control: AbstractControl) {
		if (condition && !control.value) {
			this.appUtils.addError(control, 'required');
		} else {
			this.appUtils.removeError(control, 'required');
		}
	}

	public onAddVehicle() {
		let vehicleImportModel = new VehicleImportModel();
		vehicleImportModel.isAddedManually = true;
		vehicleImportModel.inventoryDate = (new Date()).toISOString();
		let model = new AutoImportViewModel(vehicleImportModel);
		this.viewModels.splice(0, 0, model);
		this.addVehicle(model);
	}

	private populateVehicles(importableVehicles: Array<VehicleImportModel>) {
		if (importableVehicles.length == 0)
			return;

		importableVehicles.reverse().forEach(importableVehicleModel => {
			let model = new AutoImportViewModel(importableVehicleModel);

			this.viewModels.splice(0, 0, model);
			this.addVehicle(model);
		});

		this.onStoreInitChange();
	}

	public onSubmit() {

		// console.log(this.form)
		this.submitted = true;
		if (this.form.invalid) {
			return;
		}

		let vehicleImportModels = new Array<VehicleImportModel>();
		(this.form.controls.vehicles as FormArray).controls.forEach((elem, index) => {
			let fb = elem as FormGroup;
			if (fb.controls.isSelected.value && fb.valid) {
				let model = new VehicleImportModel();
				model.id = fb.controls.id.value;
				model.inventoryDate = fb.controls.inventoryDate.value;
				model.make = fb.controls.make.value ? fb.controls.make.value : null;
				model.vehicleAgeType = Number(fb.controls.vehicleAgeType.value);
				model.vehicleType = Number(fb.controls.vehicleType.value);
				model.stockNumber = fb.controls.stockNumber.value ? fb.controls.stockNumber.value : null;
				model.vehicleIdentificationNumber = fb.controls.vin.value;
				model.mileage = Number(fb.controls.mileage.value);
				model.storeId = Number(fb.controls.storeId.value);
				model.workflowId = Number(fb.controls.workflowId.value);
				model.year = Number(fb.controls.year.value);
				model.color = fb.controls.color.value ? fb.controls.color.value : null;
				model.basicColor = fb.controls.basicColor.value ? fb.controls.basicColor.value : null;
				model.model = fb.controls.model.value ? fb.controls.model.value : null;
				model.modelNumber = fb.controls.modelNumber.value ? fb.controls.modelNumber.value : null;
				model.series = fb.controls.series.value ? fb.controls.series.value : null;
				model.body = fb.controls.body.value ? fb.controls.body.value : null;
				model.isAddedManually = this.viewModels[index].autoImportModel.isAddedManually;

				vehicleImportModels.push(model);
			}
		});

		if (vehicleImportModels.length === 0) {
			this.messagingService.warning('No vehicle with valid data exist to import.');
			return;
		}

		this.blockUI.start();
		this.vehicleImportService.import(vehicleImportModels).subscribe((failedImports) => {
			this.blockUI.stop();
			this.fetchImportableVehicles();
			setTimeout(() => {
				if (Array.isArray(failedImports) && failedImports.length > 0) {
					failedImports.forEach((failedImport) => {
						this.messagingService.error(failedImport.errorMessage);
					});
				} else {
					this.messagingService.success('Vehicles imported to queue successfully.');
				}
			}, 300);
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
			this.blockUI.stop();
		});
	}

	public onDeleteSelected() {
		var deletedIds = new Array<number>();

		let vehicles = this.form.controls.vehicles as FormArray;
		let indices = new Array<number>();
		vehicles.controls.forEach((elem, index) => {
			let fb = elem as FormGroup;
			if (fb.controls.isSelected.value) {
				indices.push(index);

				if (!fb.controls.isAddedManually.value) {
					deletedIds.push(fb.controls.id.value);
				}
			}
		});

		indices.reverse().forEach(i => {
			vehicles.removeAt(i);
			this.viewModels.splice(i, 1);
		});

		if (deletedIds.length === 0) {
			return;
		}

		this.blockUI.start();
		this.vehicleImportService.delete(deletedIds).subscribe(() => {
			this.blockUI.stop();
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
			this.blockUI.stop();
		});
	}

	public onPutOnHold() {
		var ids = new Array<number>();

		let vehicles = this.form.controls.vehicles as FormArray;
		vehicles.controls.forEach((elem, index) => {
			let fb = elem as FormGroup;
			if (fb.controls.isSelected.value && !fb.controls.isAddedManually.value) {
				ids.push(fb.controls.id.value);
			}
		});

		if (ids.length === 0) {
			return;
		}

		this.blockUI.start();
		this.vehicleImportService.putOnHold(ids).subscribe(() => {
			this.blockUI.stop();
			this.fetchImportableVehicles();
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
			this.blockUI.stop();
		});
	}

	public onRemoveHold() {
		var ids = new Array<number>();

		let vehicles = this.form.controls.vehicles as FormArray;
		vehicles.controls.forEach((elem, index) => {
			let fb = elem as FormGroup;
			if (fb.controls.isSelected.value && !fb.controls.isAddedManually.value) {
				ids.push(fb.controls.id.value);
			}
		});

		if (ids.length === 0) {
			return;
		}

		this.blockUI.start();
		this.vehicleImportService.removeHold(ids).subscribe(() => {
			this.blockUI.stop();
			this.fetchImportableVehicles();
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
			this.blockUI.stop();
		});
	}

	public onSelectAllChange() {
		let vehicles = this.form.controls.vehicles as FormArray;
		vehicles.controls.forEach(elem => {
			let fb = elem as FormGroup;
			fb.controls.isSelected.setValue(this.form.controls.selectAll.value);
		});
	}

	public onIsSelectedChange() {
		let vehicles = this.form.controls.vehicles as FormArray;
		let selectValues = vehicles.controls.map(elem => {
			let fb = elem as FormGroup;
			return fb.controls.isSelected.value;
		});

		//If every element is selected
		this.form.controls.selectAll.setValue(selectValues.every(x => x == true));
	}

	public onStoreChange(storeId: any, index: number) {
		if (!storeId) {
			return;
		}

		storeId = Number(storeId);

		let vm = this.viewModels[index];
		vm.workflowSelectList = this.workflowSelectList.filter(x => x.storeId == storeId);

		let dWorkflow = vm.workflowSelectList.find(x => x.isDefault);

		let vehicles = this.form.controls.vehicles as FormArray;
		let fb = vehicles.controls[index] as FormGroup;
		fb.controls.workflowId.setValue(dWorkflow == null ? '' : dWorkflow.id);
	}

	private onStoreInitChange() {
		this.viewModels.forEach((model, index) => {
			if (model.autoImportModel.storeIds.length) {
				this.onStoreChange(model.autoImportModel.storeIds[0], index);
			}
		});
	}

	public getVehicleAgeName(vehicleAgeType: any) {
		return this.vehicleAgeTypes.find(x => x.id == vehicleAgeType).name;
	}

	public populateVehicleInfo(vin: string, fg: FormGroup) {

		this.vehicleService.get(vin).subscribe((vehicleImportModel: VehicleImportModel) => {

			if (vehicleImportModel == null) {
				fg.controls.model.setValue('');
				fg.controls.make.setValue('');
				fg.controls.year.setValue((new Date).getFullYear());
				fg.controls.modelNumber.setValue('');
				fg.controls.series.setValue(null);
				fg.controls.vehicleAgeType.setValue(VehicleAgeType.Other);
				fg.controls.vehicleType.setValue(VehicleType.None);
				fg.controls.color.setValue('');
				fg.controls.basicColor.setValue('');
			} else {
				fg.controls.model.setValue(vehicleImportModel.model);
				fg.controls.make.setValue(vehicleImportModel.make);
				fg.controls.year.setValue(vehicleImportModel.year);
				fg.controls.modelNumber.setValue(vehicleImportModel.modelNumber);
				fg.controls.series.setValue(vehicleImportModel.series);
				fg.controls.vehicleAgeType.setValue(vehicleImportModel.vehicleAgeType);
				fg.controls.vehicleType.setValue(vehicleImportModel.vehicleType);
				fg.controls.color.setValue(vehicleImportModel.color);
				if (vehicleImportModel.basicColor) {
					fg.controls.basicColor.setValue(vehicleImportModel.basicColor);
				}
			}

			fg.controls.model.updateValueAndValidity();
			fg.controls.make.updateValueAndValidity();
			fg.controls.year.updateValueAndValidity();
			fg.controls.modelNumber.updateValueAndValidity();
			fg.controls.series.updateValueAndValidity();
			fg.controls.vehicleAgeType.updateValueAndValidity();
			fg.controls.vehicleType.updateValueAndValidity();
			fg.controls.color.updateValueAndValidity();
			fg.controls.basicColor.updateValueAndValidity();
		});
	}
}
