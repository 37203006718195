import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime } from 'luxon';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { QuotationStatus } from 'src/app/enums';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { AuthenticateResponseModel, QuotationItemModel, QuotationModel, SelectListModel, VehicleAssessmentModel, VehicleQueueModel, VehicleQueueStageModel } from 'src/app/models';
import { ApplicationContextService, AuthenticationService, MessagingService, OperationCodeService, QuotationService, SubCategoryService, VehicleQueueService } from 'src/app/services';
import { NoBidConfirmationComponent } from '../no-bid/no.bid.confirmation.component';

@Component({
	selector: 'app-quotation-form',
	templateUrl: './quotation-form.component.html',
	styleUrls: ['./quotation-form.component.css']
})
export class QuotationFormComponent implements OnInit, OnDestroy {
	@BlockUI('container-blockui') blockUI: NgBlockUI;
	@ViewChild('noBidConfirmationModal', { static: false }) noBidConfirmationModal: NoBidConfirmationComponent;

	form: FormGroup;
	submitted: boolean;

	private quotationId: number;
	private companyId: number;
	private storeId: number;
	private vendorId: number;
	private vehicleQueueId: number;
	private vehicleQueueStageId: number;
	private authenticateUser: AuthenticateResponseModel;
	private recordingFileName: string;

	private quotationModel: QuotationModel = new QuotationModel();

	vehicleQueueModel: VehicleQueueModel = new VehicleQueueModel();
	vehicleQueueStageModel: VehicleQueueStageModel = new VehicleQueueStageModel();
	totalPrice: number = 0;

	constructor(private formBuilder: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private authenticationService: AuthenticationService,
		private messagingService: MessagingService,
		private vehicleQueueService: VehicleQueueService,
		private quotationService: QuotationService,
		private stringLengthConstants: StringLengthConstants,
		private subCategoryService: SubCategoryService
	) {
		this.authenticateUser = null;
		this.activatedRoute.params.subscribe((data) => {
			this.quotationId = Number(data.quotationId);
			this.companyId = Number(data.companyId);
			this.storeId = Number(data.storeId);
			this.vendorId = Number(data.vendorId);
			this.vehicleQueueId = Number(data.vehicleQueueId);
			this.vehicleQueueStageId = Number(data.vehicleQueueStageId);
		});
	}

	ngOnInit() {

		this.authenticationService.user$.subscribe((user) => {
			let isValidUser = user != null && !this.authenticationService.isTokenExpired();
			if (isValidUser) {
				this.authenticateUser = user;
				// console.log(this.authenticateUser)
			}
		});

		this.form = this.formBuilder.group({
			quotes: this.formBuilder.array([])
		});

		this.loadData();

		//Listen for changes
		this.form.controls.quotes.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe((formValues) => {
			this.totalPrice = formValues.map(x => Number(x.price)).reduce((a: number, b: number) => a + b, 0);
		});
	}

	ngOnDestroy(): void {
	}

	private loadData() {
		this.blockUI.start();
		forkJoin([
			this.quotationService.getQuotation(this.quotationId),
			this.vehicleQueueService.get(this.vehicleQueueId),
			this.vehicleQueueService.getVehicleQuoteAssessment(this.vehicleQueueId),
			this.quotationService.startQuotation(this.quotationId, this.storeId, this.vehicleQueueId)
		]).subscribe(([quotationModel, vehicleQueue, vehicleQuoteAssessments]) => {
			this.quotationModel = quotationModel;
			if (this.quotationModel.isExpired) {
				setTimeout(() => {
					this.router.navigate(['/']);
				}, 10)
				setTimeout(() => {
					this.messagingService.error('Quotation is closed for bidding.');
				}, 300)
				return;
			}
			if (quotationModel.status == QuotationStatus.Submitted || quotationModel.status == QuotationStatus.Won || quotationModel.status == QuotationStatus.NoBid) {
				setTimeout(() => {
					this.router.navigate(['/']);
				}, 10)
				setTimeout(() => {
					this.messagingService.error('Quotation is already recorded for processing.');
				}, 300)
				return;
			}

			this.vehicleQueueModel = vehicleQueue;
			//Get Desired Stage OPCodes
			this.vehicleQueueStageModel = this.vehicleQueueModel.vehicleQueueStages.find(x => x.id == this.vehicleQueueStageId);

			this.createQuoteItemModels(vehicleQuoteAssessments
				.filter(x => x.categoryId == this.vehicleQueueStageModel.categoryId && x.subCategoryId == this.vehicleQueueStageModel.subCategoryId));

			this.loadSubCategories();
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
		}, () => {
			this.blockUI.stop();
		});
	}

	private loadSubCategories() {
		if (this.vehicleQueueModel.vehicleVideos.length === 0) {
			return;
		}

		this.subCategoryService.selectList(this.vehicleQueueStageModel.categoryId).subscribe((subCategoriesList: Array<SelectListModel>) => {
			let assocatitedSubCategory = subCategoriesList.find(x => x.id == this.vehicleQueueStageModel.subCategoryId);
			let startsWith = this.vehicleQueueId + '/' + assocatitedSubCategory.code.toLocaleLowerCase();
			let video = this.vehicleQueueModel.vehicleVideos.find(x => x.fileName.toLocaleLowerCase().startsWith(startsWith));
			if (video != null) {
				this.recordingFileName = video.fileName;
			}
		});
	}

	public createQuoteItemModels(vehicleQuoteAssessments: Array<VehicleAssessmentModel>) {

		//Safe check
		this.quotationModel.quotationItems = this.quotationModel.quotationItems || new Array<QuotationItemModel>();

		vehicleQuoteAssessments.forEach((vehicleQuoteAssessment, index) => {
			let item = this.quotationModel.quotationItems.find(x => x.operationCodeId == vehicleQuoteAssessment.operationCodeId);

			if (item == null) {
				item = new QuotationItemModel();
				item.id = 0;
				item.price = 0;
				this.quotationModel.quotationItems.push(item);
			}

			item.operationCodeId = vehicleQuoteAssessment.operationCodeId;
			item.operationCodeName = vehicleQuoteAssessment.operationCodeName;
			item.categoryName = vehicleQuoteAssessment.categoryName;
			item.carLayoutSideName = vehicleQuoteAssessment.carLayoutSideName;
			item.carLayoutLocationName = vehicleQuoteAssessment.carLayoutLocationName;
			item.carLayoutPanelName = vehicleQuoteAssessment.carLayoutPanelName;
			item.subCategoryName = vehicleQuoteAssessment.subCategoryName;

			item.assessorComment = vehicleQuoteAssessment.comment;
			item.description = vehicleQuoteAssessment.description;
		});

		let fa = this.form.controls.quotes as FormArray;
		this.quotationModel.quotationItems.forEach((quotationItem, index) => {
			let fb = this.formBuilder.group({

				operationCodeId: quotationItem.operationCodeId,
				operationCodeName: quotationItem.operationCodeName,
				categoryName: quotationItem.categoryName,
				carLayoutPanelName: quotationItem.carLayoutPanelName,
				carLayoutSideName: quotationItem.carLayoutSideName,
				carLayoutLocationName: quotationItem.carLayoutLocationName,
				subCategoryName: quotationItem.subCategoryName,
				assessorComment: quotationItem.assessorComment,
				description: quotationItem.description,

				price: [quotationItem.price, [Validators.required, Validators.min(0)]],
				comment: [quotationItem.comment, [Validators.maxLength(this.stringLengthConstants.Default)]]
			});

			fa.push(fb);
		});
	}

	public onSubmit() {
		this.submitted = true;
		if (this.form.invalid) {
			return;
		}

		//Iterate and populate Price and Comment
		(this.form.controls.quotes as FormArray).controls.forEach((elem, index) => {
			let fg = elem as FormGroup;
			if (fg.valid) {
				let item = this.quotationModel.quotationItems.find(x => x.operationCodeId == Number(fg.controls.operationCodeId.value));
				item.operationCodeId = Number(fg.controls.operationCodeId.value);
				item.price = Number(fg.controls.price.value);
				item.comment = fg.controls.comment.value || null;
			}
		});

		this.blockUI.start();
		this.quotationService.addQuotation(this.vehicleQueueModel.storeId, this.quotationModel).subscribe(() => {
			setTimeout(() => {
				this.router.navigate(['/']);
			}, 10)
			setTimeout(() => {
				this.messagingService.success('Quotation submitted successfully.');
			}, 300)
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
		}, () => {
			this.blockUI.stop();
		});
	}

	public onNoBid() {
		this.noBidConfirmationModal.openModal();
	}

	public onNoBidConfirmation() {
		this.blockUI.start();
		this.quotationService.quotationNoBid(this.quotationId, this.vehicleQueueModel.storeId, this.vehicleQueueModel.id).subscribe(() => {
			setTimeout(() => {
				this.router.navigate(['/']);
			}, 10)
			setTimeout(() => {
				this.messagingService.success('Quotation submitted successfully.');
			}, 300)
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
		}, () => {
			this.blockUI.stop();
		});
	}
}
