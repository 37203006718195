var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BlobService } from 'angular-azure-blob-service';
import * as uuid from 'uuid';
import { BlockUI } from 'ng-block-ui';
import { InvoicingPreference, NotificationType, StorageContainerType } from 'src/app/enums';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { CountryModel, SelectListModel, StateModel, SubCategorySelectListModel, VendorAddModel, VendorDetailModel, VendorDocumentModel } from 'src/app/models';
import { ApplicationContextService, CategoryService, CountryService, MessagingService, StorageService, StoreService, SubCategoryService, VendorService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';
export class VendorAddComponent {
    constructor(formBuilder, router, messagingService, appUtils, blobService, storageService, storeService, countryService, vendorService, categoryService, subCategoryService, stringLengthConstants, applicationContextService) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.messagingService = messagingService;
        this.appUtils = appUtils;
        this.blobService = blobService;
        this.storageService = storageService;
        this.storeService = storeService;
        this.countryService = countryService;
        this.vendorService = vendorService;
        this.categoryService = categoryService;
        this.subCategoryService = subCategoryService;
        this.stringLengthConstants = stringLengthConstants;
        this.applicationContextService = applicationContextService;
        this.calenderIcon = "/assets/images/calender-icon.png";
        this.downloadIcon = "/assets/images/download.png";
        this.InvoicingPreference = InvoicingPreference;
        this.NotificationType = NotificationType;
        this.countries = new Array();
        this.states = new Array();
        this.categories = new Array();
        this.subCategories = new Array();
        //For store multi select 
        this.storeDropdownSettings = {};
        this.categoryDropdownSettings = {};
        this.subCategoryDropdownSettings = {};
        this.selectedStores = new Array();
        this.selectedCategories = new Array();
        this.selectedSubCategories = new Array();
        this.filteredSubCategories = new Array();
        this.submitted = false;
        this.minExpireDate = {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate()
        };
        this.uploadedPercent = 0;
        this.storeSelectList = new Array();
        this.categories = new Array();
        this.subCategories = new Array();
        this.model = new VendorAddModel();
    }
    ngOnInit() {
        this.multiSelectDropdownSettings();
        this.initForm();
        this.loadData();
        this.storeSubscription = this.applicationContextService.store$.subscribe((storeSelectListItem) => {
            if (storeSelectListItem) {
                this.selectedStores = new Array();
                this.selectedStores.push(storeSelectListItem);
            }
        });
    }
    ngOnDestroy() {
        this.storeSubscription.unsubscribe();
    }
    initForm() {
        this.frmAddVendor = this.formBuilder.group({
            // storeIds:['',Validators.required],
            name: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            streetAddress: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Default)]],
            blockAddress: [null, [Validators.maxLength(this.stringLengthConstants.Default)]],
            city: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Default)]],
            state: ['', [Validators.required]],
            country: ['', [Validators.required]],
            zipCode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5),
                    Validators.pattern(new RegExp('^[a-zA-Z0-9-]*$', 'g')), Validators.maxLength(this.stringLengthConstants.PostalCode)]],
            phoneCode: ['', [Validators.required]],
            phoneNumber: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.PhoneNumber),
                    this.appUtils.validatePhoneNumber()]],
            taxId: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(this.stringLengthConstants.TaxId)]],
            invoiceEmail: ['', [Validators.required, Validators.email, Validators.maxLength(this.stringLengthConstants.Email)]],
            insurancePolicy: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Default)]],
            insuranceProvider: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Default)]],
            insuranceExpireDate: ['', [Validators.required]],
            invoicingPreference: [InvoicingPreference.SingleInvoice, Validators.required],
            isEmailNotificationActive: [true],
            isSMSNotificationActive: [true]
        });
        this.vendorAutoId = Math.floor(Math.random() * (99999 - 10000) + 10000).toString();
    }
    onDocumentSelect(event) {
        if (!event.target.files || event.target.files.length === 0) {
            return;
        }
        [...event.target.files].forEach(file => this.processDocument(file));
        event.target.value = null;
    }
    processDocument(file) {
        this.storageService.getSasToken().subscribe((sasToken) => __awaiter(this, void 0, void 0, function* () {
            const cloudConfig = {
                sas: sasToken.toString(),
                storageAccount: environment.azureAccount,
                containerName: StorageContainerType.VendorDocument
            };
            const fileName = `${uuid.v4()}.${this.appUtils.getFileExtension(file.name)}`;
            const blobUrl = this.blobService.generateBlobUrl(cloudConfig, fileName);
            let document = new VendorDocumentModel();
            document.originalFileName = file.name;
            document.fileName = fileName;
            document.isDeleted = false;
            this.blockUI.start();
            let config = {
                baseUrl: `${blobUrl}?`,
                sasToken: cloudConfig.sas,
                blockSize: 1024 * 64,
                file: file,
                complete: () => {
                    this.storageService.getPublicUrl(StorageContainerType.VendorDocument, document.fileName).subscribe(res => {
                        document.fileUrl = res.toString();
                    });
                    this.model.documents.push(document);
                    this.blockUI.stop();
                },
                error: (err) => {
                    this.messagingService.ProcessErrorResponse(err, 'Vendor File upload timeout.');
                    this.blockUI.stop();
                },
                progress: (percent) => {
                    this.uploadedPercent = percent;
                }
            };
            setTimeout(() => {
                this.blobService.upload(config);
            });
        }), error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    deleteDocument(document, index) {
        this.blockUI.start();
        this.vendorService.deleteDocument(document.fileName).subscribe(() => {
            this.model.documents.splice(index, 1);
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    onSubmit() {
        this.submitted = true;
        if (this.frmAddVendor.invalid
            || this.selectedStores.length == 0
            || this.selectedCategories.length == 0
            || this.model.documents.length == 0) {
            return;
        }
        this.model.storeIds = this.selectedStores.map(x => x.id);
        this.model.categoryIds = this.selectedCategories.map(x => x.id);
        this.model.subCategoryIds = this.selectedSubCategories.map(x => x.id);
        this.model.name = this.frmAddVendor.controls.name.value;
        this.model.streetAddress = this.frmAddVendor.controls.streetAddress.value;
        this.model.blockAddress = this.frmAddVendor.controls.blockAddress.value;
        this.model.city = this.frmAddVendor.controls.city.value;
        this.model.state = this.frmAddVendor.controls.state.value;
        this.model.country = this.frmAddVendor.controls.country.value;
        this.model.zipCode = this.frmAddVendor.controls.zipCode.value;
        this.model.phoneCode = this.frmAddVendor.controls.phoneCode.value;
        this.model.phone = this.frmAddVendor.controls.phoneNumber.value;
        this.model.taxId = this.frmAddVendor.controls.taxId.value;
        this.model.invoiceEmail = this.frmAddVendor.controls.invoiceEmail.value;
        this.model.insurancePolicy = this.frmAddVendor.controls.insurancePolicy.value;
        this.model.insuranceProvider = this.frmAddVendor.controls.insuranceProvider.value;
        this.model.insuranceExpireDate = this.frmAddVendor.controls.insuranceExpireDate.value;
        this.model.invoicingPreference = this.frmAddVendor.controls.invoicingPreference.value;
        this.model.isEmailNotificationActive = this.frmAddVendor.controls.isEmailNotificationActive.value;
        this.model.isSMSNotificationActive = this.frmAddVendor.controls.isSMSNotificationActive.value;
        this.vendorService.add(this.model)
            .subscribe((data) => {
            setTimeout(() => {
                this.router.navigate(['/manage/vendor']);
            }, 10);
            setTimeout(() => {
                this.messagingService.success('Vendor has been added successfully.');
            }, 300);
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    loadData() {
        this.blockUI.start();
        forkJoin([
            this.countryService.getCountries(),
            this.categoryService.selectList(),
            this.subCategoryService.getAllSubCategories(),
            this.storeService.storeSelectList()
        ]).subscribe(([countries, categories, subCategories, stores]) => {
            this.countries = countries;
            if (this.countries.length == 1) {
                this.loadStates(this.countries[0].id);
                this.frmAddVendor.controls['country'].setValue(this.countries[0].shortName);
                this.frmAddVendor.controls['phoneCode'].setValue(this.countries[0].phoneCode);
            }
            this.categories = categories;
            this.subCategories = subCategories;
            this.storeSelectList = stores;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    loadStates(countryId) {
        this.blockUI.start();
        this.countryService.getStates(countryId).subscribe((data) => {
            this.states = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    onCategorySelect() {
        let categoryIds = this.selectedCategories.map(x => x.id);
        if (categoryIds.length == 0) {
            this.onCategoryDeSelectAll();
            return;
        }
        this.filteredSubCategories = [...this.subCategories.filter(x => categoryIds.find(z => z == x.categoryId))];
        this.selectedSubCategories = this.selectedSubCategories.filter(x => this.filteredSubCategories.find(z => z.id == x.id));
    }
    onCategorySelectAll() {
        this.filteredSubCategories = [...this.subCategories];
        this.selectedSubCategories = this.selectedSubCategories.filter(x => this.filteredSubCategories.find(z => z.id == x.id));
    }
    onCategoryDeSelectAll() {
        this.selectedSubCategories = this.filteredSubCategories = new Array();
    }
    multiSelectDropdownSettings() {
        this.storeDropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
        this.categoryDropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
        this.subCategoryDropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
    }
}
__decorate([
    BlockUI('container-blockui-vendor'),
    __metadata("design:type", Object)
], VendorAddComponent.prototype, "blockUI", void 0);
