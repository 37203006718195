import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApprovalStatus, Category } from 'src/app/enums';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { MechanicalAdviseAddModel, MechanicalOperationCodeModel, PriceMatrixEngineModel, OperationCodeSelectListModel, VehicleQueueModel, VehicleQueueStageModel, VehicleWorkDetailModel } from 'src/app/models';
import { ApplicationContextService, MessagingService, OperationCodeService, PriceMatrixService, VehicleQueueService } from 'src/app/services';

@Component({
	selector: 'app-mechanical-advising',
	templateUrl: './mechanical-advising.component.html',
	styleUrls: ['./mechanical-advising.component.css']
})
export class MechanicalAdvisingComponent implements OnInit, OnDestroy {
	@BlockUI('container-blockui') blockUI: NgBlockUI;

	frmMechAdvising: FormGroup;
	private storeId: number;
	private vehicleQueueId: number;
	private vehicleQueueStageId: number;
	vehicleQueueModel: VehicleQueueModel = new VehicleQueueModel();
	vehicleQueueStageModel: VehicleQueueStageModel = new VehicleQueueStageModel();

	opCodes: Array<OperationCodeSelectListModel> = new Array<OperationCodeSelectListModel>();
	priceMatrices: Array<PriceMatrixEngineModel> = new Array<PriceMatrixEngineModel>();
	totalParts: number = 0;
	totalLabor: number = 0;
	totalCost: number = 0;
	submitted = false;

	constructor(private formBuilder: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private stringLengthConstants: StringLengthConstants,
		private applicationContextService: ApplicationContextService,
		private appUtils: AppUtils,
		private messagingService: MessagingService,
		private vehicleQueueService: VehicleQueueService,
		private operationCodeService: OperationCodeService,
		private priceMatrixService: PriceMatrixService,
		public currencyPipe: CurrencyPipe
	) {
		this.activatedRoute.params.subscribe((data) => {
			this.storeId = data.storeId;
			this.vehicleQueueId = data.vehicleQueueId;
			this.vehicleQueueStageId = data.vehicleQueueStageId;
		});


	}

	ngOnInit() {
		this.frmMechAdvising = this.formBuilder.group({
			roNumber: ['', [RxwebValidators.compose({
				validators: [
					RxwebValidators.required()
				]
			})]],
			opCodePrices: this.formBuilder.array([]),
		});

		this.frmMechAdvising.controls.opCodePrices.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
			this.calculatePrices();
		});

		this.loadVehicleDetail();
	}

	getControls() {
		return (this.frmMechAdvising.get('opCodePrices') as FormArray).controls;
	}

	getOpCodeFormArray() {
		return this.frmMechAdvising.get('opCodePrices') as FormArray;
	}

	ngOnDestroy(): void {
	}

	private loadVehicleDetail() {
		this.blockUI.start();
		this.vehicleQueueService.get(this.vehicleQueueId).subscribe((vehicleQueue) => {
			if (!vehicleQueue) {
				this.messagingService.ProcessErrorResponse(null, "This vehicle is not belongs to the selected store");
				return;
			}

			if (vehicleQueue.storeId != this.storeId) {
				this.messagingService.ProcessErrorResponse(null, `This vehicle(VehicleQueueId ${vehicleQueue.id}| Store(${vehicleQueue.storeId})) is not belongs to the selected store(${this.storeId})`);
				return;
			}


			this.vehicleQueueModel = vehicleQueue;
			this.vehicleQueueStageModel = this.vehicleQueueModel.vehicleQueueStages.find(x => x.id == this.vehicleQueueStageId);
			this.loadData();

			//Set Ro Number if exists
			this.frmMechAdvising.controls.roNumber.setValue(vehicleQueue.repairOrderNumber);
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
		}, () => {
			this.blockUI.stop();
		});
	}

	private loadData() {
		forkJoin([
			this.priceMatrixService.getPriceMatrixByCategory(this.storeId, this.vehicleQueueModel.workflowId, Category.Mechanical),
			this.operationCodeService.getSelectListByCategory(Category.Mechanical),
			this.vehicleQueueService.getMechanicalAdvise(this.vehicleQueueId),
		]).subscribe(([
			priceMatrices,
			opCodes,
			mechnicalVehicleWorkDetails
		]) => {
			this.opCodes = opCodes;
			this.priceMatrices = priceMatrices;

			if (mechnicalVehicleWorkDetails.length == 0) {
				this.onAddOpCode();
			}
			this.fillOpCodePricing(mechnicalVehicleWorkDetails);
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
		}, () => {

		});
	}

	public onAddOpCode() {
		this.addOpCodePricing();
	}

	public removeOpCode(index) {
		let items = this.getOpCodeFormArray();
		if (items.length != 1) {
			items.removeAt(index);
		}
	}

	private addOpCodePricing(): any {
		let fb = this.formBuilder.group({
			id: [],
			opCodeId: ['', [RxwebValidators.compose({
				validators: [
					RxwebValidators.required()
				]
			})]],
			opCodeName: [],
			parts: [0, [RxwebValidators.compose({
				validators: [
					RxwebValidators.required(),
					RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
					RxwebValidators.minNumber({ value: 0 })
				]
			})]],
			labor: [0, [RxwebValidators.compose({
				validators: [
					RxwebValidators.required(),
					RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
					RxwebValidators.minNumber({ value: 0 })
				]
			})]],
			cost: [0],
			comments: ['', [RxwebValidators.compose({
				validators: [
					RxwebValidators.maxLength({ value: this.stringLengthConstants.Default }),
				]
			})]]
		});

		this.getOpCodeFormArray().push(fb);
	}

	private fillOpCodePricing(data): any {
		data.forEach((x: VehicleWorkDetailModel) => {
			let priceMatrix = this.priceMatrices.find(z => z.operationCodeId == x.operationCodeId);
			let comments = x.commentModels.filter(x => x.category == Category.Approval);
			let isDisabled = (x.approvalStatus != ApprovalStatus.Question && x.approvalStatus != ApprovalStatus.None);
			let fb = this.formBuilder.group({
				id: [x.id],
				opCodeId: [{ value: x.operationCodeId, disabled: (isDisabled || x.isMandatory) },
				[RxwebValidators.compose({
					validators: [
						RxwebValidators.required()
					]
				})]],
				opCodeName: [x.operationCodeDetailModel.name],
				parts: [{ value: x.partPrice, disabled: isDisabled },
				[RxwebValidators.compose({
					validators: [
						RxwebValidators.required(),
						RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
						RxwebValidators.minNumber({ value: 0 })
					]
				})]],
				labor: [{ value: x.laborPrice > 0 ? x.laborPrice : priceMatrix.price, disabled: isDisabled },
				[RxwebValidators.compose({
					validators: [
						RxwebValidators.required(),
						RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
						RxwebValidators.minNumber({ value: 0 })
					]
				})]],
				cost: [0],
				comments: [{ value: comments.length > 0 ? comments[0].comment : '', disabled: isDisabled },
				[RxwebValidators.compose({
					validators: [
						RxwebValidators.maxLength({ value: this.stringLengthConstants.Default }),
					]
				})]]
			});

			this.getOpCodeFormArray().push(fb);
		});
	}

	private calculatePrices() {
		this.totalParts = 0;
		this.totalLabor = 0;
		this.totalCost = 0;
		this.frmMechAdvising.getRawValue().opCodePrices.forEach((x: any, index: number) => {
			let labor = Number(x.labor);
			let parts = Number(x.parts);
			let cost = parts + labor;
			(this.getControls()[index] as FormGroup).controls.cost.setValue(cost);
			this.totalParts += parts;
			this.totalLabor += labor;
			this.totalCost += cost;

		});
	}

	public onOpCodeSelect(index: number) {
		let fg = this.getControls()[index] as FormGroup;
		let priceMatrix = this.priceMatrices.find(x => x.operationCodeId == fg.value.opCodeId);
		fg.controls.labor.setValue(priceMatrix.price);
		fg.controls.opCodeName.setValue(this.opCodes.find(x => x.id == fg.value.opCodeId).name);
	}

	onSubmit() {
		this.submitted = true;
		if (this.frmMechAdvising.invalid) {
			return;
		}

		let adviseModel = new MechanicalAdviseAddModel();
		adviseModel.vehicleQueueId = Number(this.vehicleQueueId);
		adviseModel.vehicleQueueStageId = Number(this.vehicleQueueStageId);
		adviseModel.roNumber = this.frmMechAdvising.value.roNumber;
		this.getOpCodeFormArray().controls.forEach((elem, index) => {
			let fg = elem as FormGroup;
			if (fg.valid) {
				let model = new MechanicalOperationCodeModel();
				model.id = Number(fg.controls.id.value);
				model.operationCodeId = Number(fg.controls.opCodeId.value);
				model.partsPrice = Number(fg.controls.parts.value);
				model.laborPrice = Number(fg.controls.labor.value);
				model.comment = fg.controls.comments.value;
				adviseModel.mechanicalOperationCodes.push(model);
			}
		});

		this.blockUI.start();
		this.vehicleQueueService.addMechanicalAdvise(adviseModel).subscribe(() => {
			setTimeout(() => {
				this.router.navigate(['/']);
			}, 10)
			setTimeout(() => {
				this.messagingService.success('Mechanical advising complete successfully.');
			}, 300)
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
		}, () => {
			this.blockUI.stop();
		});
	}
}
