<section class="datatableSer">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <h1>Manage Workflow</h1>
      </div>
      <div class="col-12 col-md-6 text-center text-md-right">
        <button class="btn btn-red" type="button" [routerLink]="['/add/workflow']">Add System Workflow</button>
        <button class="btn btn-red" type="button" (click)="openImportModal()">Associate Workflow</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="tableWrp">
          <div class="filterWrp">
            <form [formGroup]="frm">
              <div class="row justify-content-end">
                <div class="col-12 col-md-12 col-lg-9">
                  <div class="row">
                    <div class="col-12 col-sm-4">

                      <div class="form-group">
                        <span>
                          <input type="checkbox" class="form-control" id="chkViewSystemWorlflows"
                            formControlName="viewOnlySystemWorkflow" />
                          <label for="chkViewSystemWorlflows">View System Workflows</label>
                        </span>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4">
                      <label>Is Discontinued</label>
                      <select class="form-control" formControlName="isDeprecated">
                        <option *ngFor="let item of deprecatedOptions" [value]="item.id">
                          {{item.name}}
                        </option>
                      </select>
                    </div>
                    <div class="col-12 col-sm-4">
                      <label>Status</label>
                      <select class="form-control" formControlName="statusOption">
                        <option *ngFor="let item of statusOptions" [value]="item.id">
                          {{item.name}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div *blockUI="'container-blockui-grid'" class="bluetableWrp table-responsive">
            <table datatable [dtOptions]="dtOptions" data-page="2" class="table-hover break-all table"
              summary="Workflows">
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>

<app-workflow-delete-confirmation #deleteConfirmationModal (delete)="delete($event)">
</app-workflow-delete-confirmation>
<app-workflow-status-change-confirmation #statusChangeConfirmationModal (toggle)="toggle($event)">
</app-workflow-status-change-confirmation>
<app-workflow-import [stores]="storeSelectList" #importModal (imported)="imported($event)"></app-workflow-import>
