var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { CurrencyPipe } from '@angular/common';
import { OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators';
import { BlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApprovalStatus, Category } from 'src/app/enums';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { MechanicalAdviseAddModel, MechanicalOperationCodeModel, VehicleQueueModel, VehicleQueueStageModel } from 'src/app/models';
import { ApplicationContextService, MessagingService, OperationCodeService, PriceMatrixService, VehicleQueueService } from 'src/app/services';
export class MechanicalAdvisingComponent {
    constructor(formBuilder, activatedRoute, router, stringLengthConstants, applicationContextService, appUtils, messagingService, vehicleQueueService, operationCodeService, priceMatrixService, currencyPipe) {
        this.formBuilder = formBuilder;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.stringLengthConstants = stringLengthConstants;
        this.applicationContextService = applicationContextService;
        this.appUtils = appUtils;
        this.messagingService = messagingService;
        this.vehicleQueueService = vehicleQueueService;
        this.operationCodeService = operationCodeService;
        this.priceMatrixService = priceMatrixService;
        this.currencyPipe = currencyPipe;
        this.vehicleQueueModel = new VehicleQueueModel();
        this.vehicleQueueStageModel = new VehicleQueueStageModel();
        this.opCodes = new Array();
        this.priceMatrices = new Array();
        this.totalParts = 0;
        this.totalLabor = 0;
        this.totalCost = 0;
        this.submitted = false;
        this.activatedRoute.params.subscribe((data) => {
            this.storeId = data.storeId;
            this.vehicleQueueId = data.vehicleQueueId;
            this.vehicleQueueStageId = data.vehicleQueueStageId;
        });
    }
    ngOnInit() {
        this.frmMechAdvising = this.formBuilder.group({
            roNumber: ['', [RxwebValidators.compose({
                        validators: [
                            RxwebValidators.required()
                        ]
                    })]],
            opCodePrices: this.formBuilder.array([]),
        });
        this.frmMechAdvising.controls.opCodePrices.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
            this.calculatePrices();
        });
        this.loadVehicleDetail();
    }
    getControls() {
        return this.frmMechAdvising.get('opCodePrices').controls;
    }
    getOpCodeFormArray() {
        return this.frmMechAdvising.get('opCodePrices');
    }
    ngOnDestroy() {
    }
    loadVehicleDetail() {
        this.blockUI.start();
        this.vehicleQueueService.get(this.vehicleQueueId).subscribe((vehicleQueue) => {
            if (!vehicleQueue) {
                this.messagingService.ProcessErrorResponse(null, "This vehicle is not belongs to the selected store");
                return;
            }
            if (vehicleQueue.storeId != this.storeId) {
                this.messagingService.ProcessErrorResponse(null, `This vehicle(VehicleQueueId ${vehicleQueue.id}| Store(${vehicleQueue.storeId})) is not belongs to the selected store(${this.storeId})`);
                return;
            }
            this.vehicleQueueModel = vehicleQueue;
            this.vehicleQueueStageModel = this.vehicleQueueModel.vehicleQueueStages.find(x => x.id == this.vehicleQueueStageId);
            this.loadData();
            //Set Ro Number if exists
            this.frmMechAdvising.controls.roNumber.setValue(vehicleQueue.repairOrderNumber);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    loadData() {
        forkJoin([
            this.priceMatrixService.getPriceMatrixByCategory(this.storeId, this.vehicleQueueModel.workflowId, Category.Mechanical),
            this.operationCodeService.getSelectListByCategory(Category.Mechanical),
            this.vehicleQueueService.getMechanicalAdvise(this.vehicleQueueId),
        ]).subscribe(([priceMatrices, opCodes, mechnicalVehicleWorkDetails]) => {
            this.opCodes = opCodes;
            this.priceMatrices = priceMatrices;
            if (mechnicalVehicleWorkDetails.length == 0) {
                this.onAddOpCode();
            }
            this.fillOpCodePricing(mechnicalVehicleWorkDetails);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
        });
    }
    onAddOpCode() {
        this.addOpCodePricing();
    }
    removeOpCode(index) {
        let items = this.getOpCodeFormArray();
        if (items.length != 1) {
            items.removeAt(index);
        }
    }
    addOpCodePricing() {
        let fb = this.formBuilder.group({
            id: [],
            opCodeId: ['', [RxwebValidators.compose({
                        validators: [
                            RxwebValidators.required()
                        ]
                    })]],
            opCodeName: [],
            parts: [0, [RxwebValidators.compose({
                        validators: [
                            RxwebValidators.required(),
                            RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
                            RxwebValidators.minNumber({ value: 0 })
                        ]
                    })]],
            labor: [0, [RxwebValidators.compose({
                        validators: [
                            RxwebValidators.required(),
                            RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
                            RxwebValidators.minNumber({ value: 0 })
                        ]
                    })]],
            cost: [0],
            comments: ['', [RxwebValidators.compose({
                        validators: [
                            RxwebValidators.maxLength({ value: this.stringLengthConstants.Default }),
                        ]
                    })]]
        });
        this.getOpCodeFormArray().push(fb);
    }
    fillOpCodePricing(data) {
        data.forEach((x) => {
            let priceMatrix = this.priceMatrices.find(z => z.operationCodeId == x.operationCodeId);
            let comments = x.commentModels.filter(x => x.category == Category.Approval);
            let isDisabled = (x.approvalStatus != ApprovalStatus.Question && x.approvalStatus != ApprovalStatus.None);
            let fb = this.formBuilder.group({
                id: [x.id],
                opCodeId: [{ value: x.operationCodeId, disabled: (isDisabled || x.isMandatory) },
                    [RxwebValidators.compose({
                            validators: [
                                RxwebValidators.required()
                            ]
                        })]],
                opCodeName: [x.operationCodeDetailModel.name],
                parts: [{ value: x.partPrice, disabled: isDisabled },
                    [RxwebValidators.compose({
                            validators: [
                                RxwebValidators.required(),
                                RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
                                RxwebValidators.minNumber({ value: 0 })
                            ]
                        })]],
                labor: [{ value: x.laborPrice > 0 ? x.laborPrice : priceMatrix.price, disabled: isDisabled },
                    [RxwebValidators.compose({
                            validators: [
                                RxwebValidators.required(),
                                RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
                                RxwebValidators.minNumber({ value: 0 })
                            ]
                        })]],
                cost: [0],
                comments: [{ value: comments.length > 0 ? comments[0].comment : '', disabled: isDisabled },
                    [RxwebValidators.compose({
                            validators: [
                                RxwebValidators.maxLength({ value: this.stringLengthConstants.Default }),
                            ]
                        })]]
            });
            this.getOpCodeFormArray().push(fb);
        });
    }
    calculatePrices() {
        this.totalParts = 0;
        this.totalLabor = 0;
        this.totalCost = 0;
        this.frmMechAdvising.getRawValue().opCodePrices.forEach((x, index) => {
            let labor = Number(x.labor);
            let parts = Number(x.parts);
            let cost = parts + labor;
            this.getControls()[index].controls.cost.setValue(cost);
            this.totalParts += parts;
            this.totalLabor += labor;
            this.totalCost += cost;
        });
    }
    onOpCodeSelect(index) {
        let fg = this.getControls()[index];
        let priceMatrix = this.priceMatrices.find(x => x.operationCodeId == fg.value.opCodeId);
        fg.controls.labor.setValue(priceMatrix.price);
        fg.controls.opCodeName.setValue(this.opCodes.find(x => x.id == fg.value.opCodeId).name);
    }
    onSubmit() {
        this.submitted = true;
        if (this.frmMechAdvising.invalid) {
            return;
        }
        let adviseModel = new MechanicalAdviseAddModel();
        adviseModel.vehicleQueueId = Number(this.vehicleQueueId);
        adviseModel.vehicleQueueStageId = Number(this.vehicleQueueStageId);
        adviseModel.roNumber = this.frmMechAdvising.value.roNumber;
        this.getOpCodeFormArray().controls.forEach((elem, index) => {
            let fg = elem;
            if (fg.valid) {
                let model = new MechanicalOperationCodeModel();
                model.id = Number(fg.controls.id.value);
                model.operationCodeId = Number(fg.controls.opCodeId.value);
                model.partsPrice = Number(fg.controls.parts.value);
                model.laborPrice = Number(fg.controls.labor.value);
                model.comment = fg.controls.comments.value;
                adviseModel.mechanicalOperationCodes.push(model);
            }
        });
        this.blockUI.start();
        this.vehicleQueueService.addMechanicalAdvise(adviseModel).subscribe(() => {
            setTimeout(() => {
                this.router.navigate(['/']);
            }, 10);
            setTimeout(() => {
                this.messagingService.success('Mechanical advising complete successfully.');
            }, 300);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
}
__decorate([
    BlockUI('container-blockui'),
    __metadata("design:type", Object)
], MechanicalAdvisingComponent.prototype, "blockUI", void 0);
