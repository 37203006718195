import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { mapTo, tap, catchError, map } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { AccountActivateModel, AccountRecoverModel, AccountResetModel, AuthenticateRequestModel, AuthenticateResponseModel, ChangePasswordModel } from "../models";
import * as jwt_decode from 'jwt-decode';
import { PermissionType } from "../enums";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private readonly JWT_TOKEN = 'JWT_TOKEN';
    private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';

    private userSubject$ = new BehaviorSubject<AuthenticateResponseModel>(null);
    public user$ = this.userSubject$.asObservable();

    constructor(private http: HttpClient) {
    }

    public login(authenticateRequestModel: AuthenticateRequestModel): Observable<boolean> {
        const formData: FormData = new FormData();
        formData.append('userName', authenticateRequestModel.userName);
        formData.append('password', authenticateRequestModel.password);

        return this.http.post<any>(`${environment.apiBaseUrl}/auth/login`, formData)
            .pipe(
                tap((user: AuthenticateResponseModel) => {
                    this.doLoginUser(user);
                }),
                mapTo(true),
                catchError(error => {
                    throw error;
                }));
    }

    public logout() {
        const formData: FormData = new FormData();
        formData.append('refreshToken', this.getRefreshToken());
        formData.append('jwt', this.getJwtToken());
        return this.http.post<any>(`${environment.apiBaseUrl}/auth/logout`, formData)
            .pipe(
                tap(() => {
                    this.doLogoutUser();
                }),
                mapTo(true),
                catchError(error => {
                    this.doLogoutUser()
                    return of(false);
                }));
    }

    public changePassword(model: ChangePasswordModel) {
        const formData: FormData = new FormData();
        formData.append('currentPassword', model.currentPassword);
        formData.append('newPassword', model.newPassword);
        formData.append('confirmPassword', model.confirmPassword);

        return this.http.post(`${environment.apiBaseUrl}/auth/change-password`, formData);
    }

    public recover(model: AccountRecoverModel) {
        const formData: FormData = new FormData();
        formData.append('email', model.email);
        return this.http.post(`${environment.apiBaseUrl}/auth/recover`, formData);
    }

    public reset(model: AccountResetModel) {
        const formData: FormData = new FormData();
        formData.append('key', model.key);
        formData.append('secret', model.secret);
        formData.append('newPassword', model.newPassword);
        formData.append('confirmPassword', model.confirmPassword);
        formData.append('temporaryPassword', model.temporaryPassword);
        return this.http.post(`${environment.apiBaseUrl}/auth/reset`, formData);
    }

    public sendActivationMail( applicationUserId:string){
        const formData: FormData = new FormData();
        formData.append('applicationUserId',applicationUserId);
        return this.http.post(`${environment.apiBaseUrl}/auth/send/activation/mail`, formData);
    }

    public activate(model: AccountActivateModel) {
        const formData: FormData = new FormData();
        formData.append('key', model.key);
        formData.append('secret', model.secret);
        formData.append('password', model.password);
        formData.append('confirmPassword', model.confirmPassword);
        formData.append('temporaryPassword', model.temporaryPassword);
        return this.http.post(`${environment.apiBaseUrl}/auth/activate`, formData);
    }

    public isLoggedIn() {
        return !!this.getJwtToken();
    }

    public refreshToken() {
        const formData: FormData = new FormData();
        formData.append('refreshToken', this.getRefreshToken());
        formData.append('jwt', this.getJwtToken());
        return this.http.post<any>(`${environment.apiBaseUrl}/auth/refresh`, formData)
            .pipe(
                tap((user: AuthenticateResponseModel) => {
                    this.doLoginUser(user)
                }),
                mapTo(true),
                catchError(error => {
                    this.doLogoutUser();
                    return of(false);
                }));
    }

    public isTokenExpired(): boolean {
        let token = this.getJwtToken();
        if (!token) return true;

        const date = this.getTokenExpirationDate(token);
        if (date === undefined) return false;
        return !(date.valueOf() > new Date().valueOf());
    }

    public validatePermission(permissions: PermissionType[]): Observable<boolean> {
        return this.user$.pipe(map((user: AuthenticateResponseModel) => {
            if (user == null || user.permissions == null || user.permissions.length == 0) {
                return false;
            }
            return user.permissions.some(permission => permissions.includes(permission))
        }));
    }

    // Used in interceptor
    public getJwtToken() {
        return localStorage.getItem(this.JWT_TOKEN);
    }

    private doLoginUser(authenticateResponseModel: AuthenticateResponseModel) {
        this.storeTokens(authenticateResponseModel);
        this.userSubject$.next(authenticateResponseModel);
    }

    private doLogoutUser() {
        //Clear all data
        localStorage.clear();
        this.removeTokens();
        this.userSubject$.next(null);
    }

    private getRefreshToken() {
        return localStorage.getItem(this.REFRESH_TOKEN);
    }

    private storeTokens(authenticateResponseModel: AuthenticateResponseModel) {
        localStorage.setItem(this.JWT_TOKEN, authenticateResponseModel.jwt);
        localStorage.setItem(this.REFRESH_TOKEN, authenticateResponseModel.refreshToken);
    }

    private removeTokens() {
        localStorage.removeItem(this.JWT_TOKEN);
        localStorage.removeItem(this.REFRESH_TOKEN);
    }

    private getTokenExpirationDate(token: string): Date {
        const decoded = jwt_decode(token);
        if (decoded.exp === undefined) {
            return null
        };
        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }
}