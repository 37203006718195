<div class="videoIconWrp">
  <a href="javascript:void(0);" (click)="openModal()"><img [src]="VidoeIcon" alt="Video"></a>
</div>
<ng-template #recordModal let-modal>
  <div class="modal-body commentPopupWrp" *blockUI="'container-blockui'">
    <div class="row">
      <div class="col-sm-12">
        <video #videoTag controlsList="nodownload"></video>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-sm-12 col-md-6" *ngIf="uploadTypes.length > 0">
        <select class="form-control p-1" [(ngModel)]="selectedUploadTypeOption" [disabled]="isStartRecording">
          <option *ngFor="let uploadType of uploadTypes" [value]="uploadType">
            {{VehicleVideoType[uploadType]}}</option>
        </select>
      </div>
      <div class="col-sm-12 col-md-6">
        <select class="form-control p-1" [(ngModel)]="selectedCameraOption" [disabled]="isStartRecording"
          (change)="cameraSelection()">
          <option *ngFor="let device of devices" [value]="device.deviceId">{{ device.label || "Camera" }}</option>
        </select>
      </div>
      <div class="col-sm-12">
        <div class="buttonWrp">
          <button type="submit" class="btn btn-red m-r-5" (click)="onStart(videoTag)" *ngIf="!isStarted">
            Start
          </button>
          <button type="button" class="btn btn-tsrp" (click)="onStartRecording()"
            *ngIf="isStarted && !isStartRecording">Start Recording</button>
          <button type="button" class="btn btn-tsrp" (click)="onStopRecording()" *ngIf="isStarted && isStartRecording">
            Stop Recording
          </button>
          <button type="button" class="btn btn-tsrp" (click)="onCancel()">Close</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
