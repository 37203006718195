/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error.404.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./error.404.component";
var styles_Error404Component = [i0.styles];
var RenderType_Error404Component = i1.ɵcrt({ encapsulation: 0, styles: styles_Error404Component, data: {} });
export { RenderType_Error404Component as RenderType_Error404Component };
export function View_Error404Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 404: Not Found "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" There may be a misspelling in the URL entered,"])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" or the page you are looking for may no longer exist "]))], null, null); }
export function View_Error404Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-404", [], [[2, "full_width", null]], null, null, View_Error404Component_0, RenderType_Error404Component)), i1.ɵdid(1, 49152, null, 0, i2.Error404Component, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).full_width; _ck(_v, 0, 0, currVal_0); }); }
var Error404ComponentNgFactory = i1.ɵccf("app-404", i2.Error404Component, View_Error404Component_Host_0, {}, {}, []);
export { Error404ComponentNgFactory as Error404ComponentNgFactory };
