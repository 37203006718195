import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SelectListModel } from 'src/app/models';
import { ApplicationContextService, WorkflowService } from 'src/app/services';

@Component({
    selector: 'app-wholesale-button',
    templateUrl: './wholesale-button.component.html',
    styleUrls: ['./wholesale-button.component.css']
})
export class WholesaleButtonComponent implements OnInit {
    @Output() emitAfterWholesaleAction = new EventEmitter<any>();
    storeId: number;
    hasWholesale: boolean;
    storeSubscription: any;

    constructor(
        private applicationContextService: ApplicationContextService,
        private workflowService: WorkflowService
    ) {
    }

    ngOnInit(): void {

        this.storeSubscription = this.applicationContextService.store$.subscribe((storeSelectListItem: SelectListModel) => {
            if (storeSelectListItem) {
                this.storeId = storeSelectListItem.id;
                this.loadData();
            };
        });

    }

    private loadData() {
        this.workflowService.storeHasWholesaleWorkflow(this.storeId)
            .subscribe((hasWholesale: boolean) => {
                this.hasWholesale = hasWholesale;
            });
    }

    onWholesale() {

    }
}