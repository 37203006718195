import { ApplicationRoleType } from "src/app/enums";


export class UserAddModel {
    public name: string;
    public email: string;
    public phoneCode: string;
    public phoneNumber: string;

    public applicationRoleType: ApplicationRoleType;
    public isSMSNotificationActive: boolean;
    public isEmailNotificationActive: boolean;
    public roles: Array<string>;

    public userStoreAssociations: Array<number>;
    public userCompanyAssociations: Array<number>;
    public userVendorAssociations: Array<number>;

    constructor() {
        this.roles = new Array<string>();
        this.userStoreAssociations = new Array<number>();
        this.userCompanyAssociations = new Array<number>();
        this.userVendorAssociations = new Array<number>();
    }
}