import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CategoryDetailModel, OperationCodeSelectListModel, SelectListModel, SubCategorySelectListModel, WorkflowStageAddCategoriesViewModel, WorkflowStageCategoryModel, WorkflowStageCategoryOperationCodeModel, WorkflowStageModel } from 'src/app/models';
import { MessagingService, OperationCodeService } from 'src/app/services';

@Component({
    selector: 'app-edit-workflow-stage',
    templateUrl: './edit-workflow-stage.component.html',
    styleUrls: ['./edit-workflow-stage.component.css']
})
export class WorkflowEditStageComponent implements OnChanges, OnDestroy {
    @BlockUI('container-blockui-work-step') blockUI: NgBlockUI;
    @ViewChild('addNewTask', { static: false }) addNewTask: any;

    @Input('categories') categories: Array<CategoryDetailModel>;
    @Input('subCategories') subCategories: Array<SubCategorySelectListModel>;
    @Output() updated = new EventEmitter<WorkflowStageModel>();

    modalReference: NgbModalRef;
    model: WorkflowStageModel;
    form: FormGroup;
    submitted: boolean;
    categorySelectList: Array<SelectListModel> = new Array<SelectListModel>();
    viewModels: Array<WorkflowStageAddCategoriesViewModel>;

    opCodeDropdownSettings: IDropdownSettings = {};

    constructor(private modalService: NgbModal,
        private formBuilder: FormBuilder,
        private messagingService: MessagingService,
        private operationCodeService: OperationCodeService
    ) {
        this.opCodeDropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: true
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    private createStepCategoryWithCategoryId(categoryId: any): FormGroup {
        this.viewModels.push(new WorkflowStageAddCategoriesViewModel());
        return this.formBuilder.group({
            categoryId: [categoryId, [Validators.required]],
            subCategoryId: ['']
        });
    }

    private createStepCategory(): FormGroup {
        this.viewModels.push(new WorkflowStageAddCategoriesViewModel());
        return this.formBuilder.group({
            categoryId: ['', [Validators.required]],
            subCategoryId: ['']
        });
    }

    public addStepCategory(): void {
        let items = this.form.controls.stepCategory as FormArray;
        items.push(this.createStepCategory());
    }

    public removeStepCategory(i: number): void {
        let items = this.form.controls.stepCategory as FormArray;
        items.removeAt(i);
        this.viewModels.splice(i, 1);
    }

    public init(model: WorkflowStageModel) {
        this.viewModels = new Array<WorkflowStageAddCategoriesViewModel>();
        this.categorySelectList = this.categories.filter(x => model.workflowStageCategories.some(sc => sc.categoryId == x.id) ||
            x.useToolInWorkflow || !x.isUsedInWorkflow);

        this.model = model;

        this.form = this.formBuilder.group({
            dependencyLocked: [this.model.dependencyLocked],
            stepCategory: this.formBuilder.array([])
        });

        //Populate categories
        if (this.model.workflowStageCategories.length > 0) {
            let stepCategories = this.form.get('stepCategory') as FormArray;
            this.model.workflowStageCategories.forEach((x: WorkflowStageCategoryModel, i: number) => {
                //Populate View MOdel
                let vm = new WorkflowStageAddCategoriesViewModel();
                vm.categoryId = x.categoryId;
                vm.subCategoryId = x.subCategoryId;
                vm.selectedOperationCodeIds = x.operationCodes.map((o: WorkflowStageCategoryOperationCodeModel) => {
                    return o.operationCodeId;
                });
                this.viewModels.push(vm);

                //Populate Form Step Category
                stepCategories.push(this.formBuilder.group({
                    categoryId: [x.categoryId, [Validators.required]],
                    subCategoryId: [x.subCategoryId || ''],
                    id: x.id
                }));

                this.onCategoryChange(x.categoryId, i, x.subCategoryId);
            });
        }
    }

    public openModal(model: WorkflowStageModel) {
        // Clone so that if click on cancelled underlying
        this.init(JSON.parse(JSON.stringify(model)));
        this.modalReference = this.modalService.open(this.addNewTask, { size: 'lg', centered: true });
    }

    public openModalWithCategoryId(model: WorkflowStageModel, categoryId: any) {
        // Clone so that if click on cancelled underlying
        this.init(JSON.parse(JSON.stringify(model)));

        //Add New Category
        let stepCategories = this.form.get('stepCategory') as FormArray;
        stepCategories.push(this.createStepCategoryWithCategoryId(categoryId));

        //Get OP Codes for last category
        this.onCategoryChange(categoryId, this.viewModels.length - 1, null);
        this.modalReference = this.modalService.open(this.addNewTask, { size: 'lg', centered: true });
    }

    onSubmit() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }

        this.model.dependencyLocked = Boolean(this.form.controls.dependencyLocked.value);
        this.model.workflowStageCategories = this.viewModels
            .filter(x => !x.isDeleted)
            .map((x: WorkflowStageAddCategoriesViewModel, i: number) => {
                let vm = new WorkflowStageCategoryModel();
                vm.categoryId = x.categoryId;
                vm.subCategoryId = x.subCategoryId;
                vm.sequence = i;
                vm.isActive = true;
                vm.operationCodes = x.selectedOperationCodeSelectList.map((selectList: SelectListModel, j: number) => {
                    let m = new WorkflowStageCategoryOperationCodeModel();
                    m.operationCodeId = selectList.id;
                    return m;
                })
                return vm;
            });

        this.viewModels = new Array<WorkflowStageAddCategoriesViewModel>();
        this.form.reset();
        this.submitted = false;

        setTimeout(() => {
            this.updated.emit(this.model);
        });
        this.onCancel();
    }

    onCancel() {
        if (!this.modalReference) {
            return;
        }
        this.modalReference.close();
    }

    onCategoryChange(categoryId: any, index: number, subCategoryId: Nullable<number>) {
        if (!categoryId) {
            return;
        }

        let vm = this.viewModels[index];

        let stepCategories = this.form.get('stepCategory') as FormArray;
        let fg = stepCategories.controls[index] as FormGroup;

        categoryId = Number(categoryId);
        let category = this.categories.find(z => z.id == categoryId);
        if (!category.isActive) {
            fg.controls.categoryId.setErrors({ 'incorrect': true });
            vm.operationCodeSelectList = new Array<OperationCodeSelectListModel>();
            vm.selectedOperationCodeSelectList = new Array<OperationCodeSelectListModel>();
            vm.filteredOperationCodeSelectList = new Array<OperationCodeSelectListModel>();
            vm.subCategorySelectList = new Array<SelectListModel>();
            return;
        } else {
            fg.controls.categoryId.setErrors(null);
        }

        this.blockUI.start();
        this.operationCodeService.selectListByCategoryId(categoryId).subscribe((data: Array<OperationCodeSelectListModel>) => {

            vm.categoryId = categoryId;
            vm.showTool = this.categories.find(x => x.id == categoryId).useToolInWorkflow;
            vm.operationCodeSelectList = data;
            vm.selectedOperationCodeSelectList = data.filter(x => vm.selectedOperationCodeIds.includes(x.id)) || new Array<OperationCodeSelectListModel>();
            vm.subCategorySelectList = this.subCategories.filter(x => x.categoryId == categoryId);
            if (!vm.showTool) {
                this.onSubCategoryChange(null, index);
            } else {
                this.onSubCategoryChange(subCategoryId, index);
            }
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    public onSubCategoryChange(subCategoryId: any, index: number) {
        let vm = this.viewModels[index];

        if (!subCategoryId) {
            vm.filteredOperationCodeSelectList = vm.operationCodeSelectList.filter(x => true);
            vm.subCategoryId = null;

            //Reset tool
            let stepCategory = this.form.controls.stepCategory as FormArray;
            let fb = stepCategory.controls[index] as FormGroup;
            fb.controls.subCategoryId.setValue('');
        } else {
            vm.subCategoryId = Number(subCategoryId);
            vm.filteredOperationCodeSelectList = vm.operationCodeSelectList.filter(x => x.subCategoryId == subCategoryId);
        }
    }

    ngOnDestroy(): void {
        this.onCancel();
    }
}
