import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { VehicleQueueModel } from 'src/app/models';

@Component({
    selector: 'app-vehicle-info',
    templateUrl: './vehicle-info.component.html',
    styleUrls: ['./vehicle-info.component.css']
})
export class VehicleInfoComponent implements OnInit, OnDestroy {
    @Input() vehicleQueueModel: VehicleQueueModel;
    @Input() showFullVin: boolean;

    constructor() {
    }

    ngOnInit() {
       
    }

    ngOnDestroy(): void {
       
    }

    
}