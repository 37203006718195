export class OperationCodeSelectListModel {
    public id: number;
    public name: string;
    public code: string;
    public categoryId: number;
    public categoryName: string;
    public subCategoryId: Nullable<number>;
    public subCategoryName: string;
    public carLayoutPanelId: Nullable<number>;
    public carLayoutPanelName: string;
    public carLayoutSideId: Nullable<number>;
    public carLayoutSideName: string;
    public carLayoutLocationId: Nullable<number>;
    public carLayoutLocationName: string;
}