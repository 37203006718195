var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { UnitOfMeasure } from 'src/app/enums';
import { PriceMatrixAddModel } from 'src/app/models';
import { ApplicationContextService, CarLayoutPanelService, CategoryService, CheckInTypeService, MessagingService, PriceMatrixService, SubCategoryService } from 'src/app/services';
import { conditionalValidator } from 'src/app/validators';
export class AddPriceMatrixComponent {
    constructor(formBuilder, router, toastr, messagingService, categoryService, checkInTypeService, subCategoryService, carLayoutPanelService, priceMatrixService, applicationContextService) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.toastr = toastr;
        this.messagingService = messagingService;
        this.categoryService = categoryService;
        this.checkInTypeService = checkInTypeService;
        this.subCategoryService = subCategoryService;
        this.carLayoutPanelService = carLayoutPanelService;
        this.priceMatrixService = priceMatrixService;
        this.applicationContextService = applicationContextService;
        this.UnitOfMeasure = UnitOfMeasure;
        this.submitted = false;
        this.hasPanel = true;
        this.stores = new Array();
        this.model = new PriceMatrixAddModel();
        this.panels = new Array();
        this.categories = new Array();
        this.subCategories = new Array();
        this.checkInTypes = new Array();
        this.panelDropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: true
        };
        this.selectedPanels = new Array();
    }
    ngOnInit() {
        this.initForm();
    }
    ngOnDestroy() {
        this.storeSubscription.unsubscribe();
        this.companySubscription.unsubscribe();
    }
    initForm() {
        this.frmPriceMatrix = this.formBuilder.group({
            companyId: ['', [Validators.required]],
            storeId: ['', [Validators.required]],
            checkInTypeId: ['', [Validators.required]],
            categoryId: ['', [Validators.required]],
            subCategoryId: ['', [conditionalValidator(() => this.subCategories.length > 0, Validators.required)]],
            unitOfMeasure: [UnitOfMeasure.Panel],
            unitPrices: this.formBuilder.array([this.createUnitPrice()]),
            isReconPackage: [false],
            isApprovalRequired: [false],
            isPORequired: [false],
            isSharedWorkQueue: [false],
            isInspectionRequired: [false],
            isInvoice: [false]
        });
        //this.setValidationForFirstUnitPrice();
        this.companySubscription = this.applicationContextService.company$.subscribe((company) => {
            if (company == null) {
                this.stores = new Array();
            }
            else {
                this.stores = [...company.stores];
                this.frmPriceMatrix.controls.companyId.setValue(company.id);
            }
        });
        this.storeSubscription = this.applicationContextService.store$.subscribe((storeSelectListItem) => {
            if (storeSelectListItem) {
                this.frmPriceMatrix.controls.storeId.setValue(storeSelectListItem.id);
                this.frmPriceMatrix.controls.storeId.disable();
                this.onStoreSelectionChange();
                this.loadCheckInTypes();
            }
        });
    }
    setValidationForFirstUnitPrice() {
        let unitPriceControls = this.getControls();
        if (unitPriceControls.length == 0)
            return;
        let firstControl = unitPriceControls[0];
        firstControl.controls.price.setValidators([Validators.required, Validators.min(1)]);
        firstControl.controls.price.updateValueAndValidity();
    }
    createUnitPrice() {
        return this.formBuilder.group({
            price: ['', [Validators.required, Validators.min(0)]]
        });
    }
    getControls() {
        return this.frmPriceMatrix.get('unitPrices').controls;
    }
    getUnitPriceFormArray() {
        return this.frmPriceMatrix.get('unitPrices');
    }
    addUnitPrice() {
        this.getUnitPriceFormArray().push(this.createUnitPrice());
        //this.setValidationForFirstUnitPrice();
    }
    removeUnitPrice(index) {
        let items = this.getUnitPriceFormArray();
        if (items.length != 1) {
            items.removeAt(index);
        }
        //this.setValidationForFirstUnitPrice();
    }
    onUnitOfMeasureChange() {
        let items = this.getUnitPriceFormArray();
        items.clear();
        items.push(this.createUnitPrice());
        //this.setValidationForFirstUnitPrice();
    }
    loadCheckInTypes() {
        this.blockUI.start();
        this.checkInTypeService.getFilteredCheckInTypes(this.frmPriceMatrix.controls.companyId.value, this.frmPriceMatrix.controls.storeId.value)
            .subscribe((data) => {
            this.checkInTypes = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    onStoreSelectionChange() {
        this.blockUI.start();
        this.categoryService.selectListByStore(this.frmPriceMatrix.controls.storeId.value)
            .subscribe((data) => {
            this.categories = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    onCategorySelectionChange(event) {
        if (!event.target.value) {
            this.frmPriceMatrix.controls.subCategoryId.setValue(null);
            this.subCategories = new Array();
            return;
        }
        this.blockUI.start();
        let storeId = this.frmPriceMatrix.controls.storeId.value;
        this.subCategoryService.selectListByStore(storeId, event.target.value)
            .subscribe((data) => {
            this.subCategories = data;
            this.frmPriceMatrix.controls.subCategoryId.updateValueAndValidity();
            if (this.subCategories.length > 0) {
                this.frmPriceMatrix.controls.subCategoryId.enable();
            }
            else {
                this.frmPriceMatrix.controls.subCategoryId.disable();
                this.loadPanel(false);
            }
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    onSubCategorySelectionChange(event) {
        if (!event.target.value) {
            this.panels = new Array();
            this.selectedPanels = new Array();
            return;
        }
        this.loadPanel(true);
    }
    loadPanel(hasSubCategory) {
        this.blockUI.start();
        let categoryId = this.frmPriceMatrix.controls.categoryId.value;
        let subCategoryId = this.frmPriceMatrix.controls.subCategoryId.value;
        this.selectedPanels = new Array();
        if (!hasSubCategory) {
            this.carLayoutPanelService.selectListByCategory(categoryId)
                .subscribe((data) => {
                this.panels = data;
                this.enablePanelSelection();
            }, error => {
                this.messagingService.ProcessErrorResponse(error);
            }, () => {
                this.blockUI.stop();
            });
        }
        else {
            this.carLayoutPanelService.selectListByCategoryAndSubCategory(categoryId, subCategoryId)
                .subscribe((data) => {
                this.panels = data;
                this.enablePanelSelection();
            }, error => {
                this.messagingService.ProcessErrorResponse(error);
            }, () => {
                this.blockUI.stop();
            });
        }
    }
    enablePanelSelection() {
        this.hasPanel = this.panels.length > 0;
        if (!this.hasPanel) {
            this.frmPriceMatrix.controls.unitOfMeasure.setValue(UnitOfMeasure.Vehicle);
            this.onUnitOfMeasureChange();
        }
    }
    onSubmit() {
        this.submitted = true;
        if (this.frmPriceMatrix.invalid) {
            return;
        }
        if (this.selectedPanels.length == 0 && this.frmPriceMatrix.controls.unitOfMeasure.value != UnitOfMeasure.Vehicle) {
            return;
        }
        this.blockUI.start();
        this.model.storeId = Number(this.frmPriceMatrix.controls.storeId.value);
        this.model.checkInTypeId = Number(this.frmPriceMatrix.controls.checkInTypeId.value);
        this.model.categoryId = Number(this.frmPriceMatrix.controls.categoryId.value);
        let subCategoryId = Number(this.frmPriceMatrix.controls.subCategoryId.value);
        this.model.subCategoryId = subCategoryId > 0 && this.subCategories.length > 0 ? subCategoryId : null;
        this.model.carLayoutPanelIds = this.selectedPanels.map(x => x.id);
        this.model.unitOfMeasure = this.frmPriceMatrix.controls.unitOfMeasure.value;
        // this.model.unitPrices = Number.parseFloat();
        this.model.unitPrices = this.frmPriceMatrix.controls.unitPrices.value
            .filter((x) => x.price != null && x.price >= 0).map((x) => Number(x.price));
        this.model.isApprovalRequired = this.frmPriceMatrix.controls.isApprovalRequired.value;
        this.model.isReconPackage = this.frmPriceMatrix.controls.isReconPackage.value;
        this.model.isPORequired = this.frmPriceMatrix.controls.isPORequired.value;
        this.model.isSharedWorkQueue = this.frmPriceMatrix.controls.isSharedWorkQueue.value;
        this.model.isInspectionRequired = this.frmPriceMatrix.controls.isInspectionRequired.value;
        this.model.isInvoice = this.frmPriceMatrix.controls.isInvoice.value;
        this.priceMatrixService.add(this.model)
            .subscribe(() => {
            setTimeout(() => {
                this.router.navigate(['/manage/price-matrix']);
            }, 10);
            setTimeout(() => {
                this.toastr.success('Price matrix has been created successfully.');
            }, 300);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
}
__decorate([
    BlockUI('container-blockui-store'),
    __metadata("design:type", Object)
], AddPriceMatrixComponent.prototype, "blockUI", void 0);
