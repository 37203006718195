<div class="gnrlformSec">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <a [routerLink]="['/manage/company']" class="backtolist"><em class="fa fa-long-arrow-left"
                        aria-hidden="true"></em> Back to list</a>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-12 col-lg-12 col-xl-8 offset-xl-2">
                <div class="gnrlformbox" *blockUI="'container-blockui-company'">
                    <h2>Add Managing Company</h2>
                    <div class="gnrlformDtl">
                        <form class="matrixFrom" [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Managing Company Name</h5>
                                </div>
                                <div class="matrixFromField">
                                    <input type="text" class="form-control" formControlName="companyName"
                                        [ngClass]="{ 'is-invalid': submitted && form.controls.companyName.errors }">
                                    <div *ngIf="submitted && form.controls.companyName.errors" class="invalid-feedback">
                                        <div *ngIf="form.controls.companyName.errors.required">
                                            Managing Company Name is required
                                        </div>
                                        <div *ngIf="form.controls.companyName.errors.maxlength">
                                            Managing Company Name should be max
                                            {{form.controls.companyName.errors.maxlength.requiredLength}} characters
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Address</h5>
                                </div>
                                <div class="matrixFromField">
                                    <div class="row">
                                        <div class="col-6 mb-3">
                                            <input type="text" class="form-control" formControlName="streetAddress"
                                                [ngClass]="{ 'is-invalid': submitted && form.controls.streetAddress.errors }"
                                                placeholder="Street 1" />
                                            <div *ngIf="submitted && form.controls.streetAddress.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="form.controls.streetAddress.errors.required">Street
                                                    Line 1 is required</div>
                                                <div *ngIf="form.controls.streetAddress.errors.maxlength">
                                                    Street
                                                    Line 1 should be max
                                                    {{form.controls.streetAddress.errors.maxlength.requiredLength}}
                                                    characters
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 mb-3">
                                            <input type="text" class="form-control" formControlName="blockAddress"
                                                [ngClass]="{ 'is-invalid': submitted && form.controls.blockAddress.errors }"
                                                placeholder="Street 2" />
                                            <div *ngIf="submitted && form.controls.blockAddress.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="form.controls.blockAddress.errors.required">Street Line
                                                    2 is required</div>
                                                <div *ngIf="form.controls.blockAddress.errors.maxlength">
                                                    Street
                                                    Line 2 should be max
                                                    {{form.controls.blockAddress.errors.maxlength.requiredLength}}
                                                    characters
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 mb-3">
                                            <input type="text" class="form-control" formControlName="city"
                                                [ngClass]="{ 'is-invalid': submitted && form.controls.city.errors }"
                                                placeholder="City" />
                                            <div *ngIf="submitted && form.controls.city.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="form.controls.city.errors.required">City is
                                                    required</div>
                                                <div *ngIf="form.controls.city.errors.maxlength">
                                                    City should be max
                                                    {{form.controls.city.errors.maxlength.requiredLength}}
                                                    characters</div>
                                            </div>
                                        </div>
                                        <div class="col-6 mb-3">
                                            <select class="form-control hDropDown" formControlName="state"
                                                [ngClass]="{ 'is-invalid': submitted && form.controls.state.errors }">
                                                <option value="">Select State</option>
                                                <option *ngFor="let item of states" [value]="item.shortName">
                                                    {{item.shortName}}
                                                </option>
                                            </select>
                                            <div *ngIf="submitted && form.controls.state.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="form.controls.state.errors.required">State is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 mb-3">
                                            <input type="text" class="form-control" formControlName="zipCode"
                                                [ngClass]="{ 'is-invalid': submitted && form.controls.zipCode.errors }"
                                                placeholder="ZIP" />
                                            <div *ngIf="submitted && form.controls.zipCode.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="form.controls.zipCode.errors.required">ZIP Code is
                                                    required</div>
                                                <div
                                                    *ngIf="form.controls.zipCode.errors.pattern || form.controls.zipCode.errors.minlength || form.controls.zipCode.errors.maxlength">
                                                    ZIP Code should be valid</div>
                                            </div>
                                        </div>
                                        <div class="col-6 mb-3">
                                            <select class="form-control hDropDown" formControlName="country"
                                                [ngClass]="{ 'is-invalid': submitted && form.controls.country.errors }">
                                                <option *ngFor="let item of countries" [value]="item.shortName">
                                                    {{item.shortName}}
                                                </option>
                                            </select>
                                            <div *ngIf="submitted && form.controls.country.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="form.controls.country.errors.required">Country is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Email Address</h5>
                                </div>
                                <div class="matrixFromField">
                                    <input type="text" class="form-control" formControlName="email"
                                        [ngClass]="{ 'is-invalid': submitted && form.controls.email.errors }" />
                                    <div *ngIf="submitted && form.controls.email.errors" class="invalid-feedback">
                                        <div *ngIf="form.controls.email.errors.required">Email Address is
                                            required</div>
                                        <div *ngIf="form.controls.email.errors.email">Email Address should
                                            be valid</div>
                                        <div *ngIf="form.controls.email.errors.maxlength">
                                            Email Address should be max
                                            {{form.controls.email.errors.maxlength.requiredLength}}
                                            characters</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Phone Number</h5>
                                </div>

                                <div class="matrixFromField">
                                <div class="row">
                                <div class="col-4">
                                    <select class="form-control hDropDown" formControlName = "phoneCode"
                                    [ngClass]="{'is-invalid':submitted && form.controls.phoneCode.errors}">
                                        <option *ngFor="let item of countries " [value]="item.phoneCode">{{item.phoneCode}} </option>
                                    </select>
                                </div>
                                <div class="col-8">
                                    <input type="text" class="form-control" formControlName="phoneNumber"
                                        [ngClass]="{ 'is-invalid': submitted && form.controls.phoneNumber.errors }" />
                                    <div *ngIf="submitted && form.controls.phoneNumber.errors" class="invalid-feedback">
                                        <div *ngIf="form.controls.phoneNumber.errors.required">Phone Number
                                            is required</div>
                                        <div *ngIf="form.controls.phoneNumber.errors.pattern">Phone Number
                                            should be valid format i.e. XXXXXXXXXX</div>
                                        <div *ngIf="form.controls.phoneNumber.errors.maxlength">
                                            Phone Number should be max
                                            {{form.controls.phoneNumber.errors.maxlength.requiredLength}} characters
                                        </div>
                                    </div>
                                </div>
                                </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Account Manager</h5>
                                </div>
                                <div class="matrixFromField">
                                    <select class="form-control hDropDown" formControlName="accountManagerId"
                                        [ngClass]="{ 'is-invalid': submitted && form.controls.accountManagerId.errors }">
                                        <option value="">Select Account Manager</option>
                                        <option *ngFor="let item of accountMangers" [value]="item.id">{{item.name}}
                                            ({{item.email}})
                                        </option>
                                    </select>
                                    <div *ngIf="submitted && form.controls.accountManagerId.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="form.controls.accountManagerId.errors.required">Account
                                            Manager is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Tax ID</h5>
                                </div>
                                <div class="matrixFromField">
                                    <input type="text" class="form-control" formControlName="taxId"
                                        [ngClass]="{ 'is-invalid': submitted && form.controls.taxId.errors }" />
                                    <div *ngIf="submitted && form.controls.taxId.errors" class="invalid-feedback">
                                        <div *ngIf="form.controls.taxId.errors.required">Tax ID is required
                                        </div>
                                        <div *ngIf="form.controls.taxId.errors.pattern">Tax ID should be numeric
                                        </div>
                                        <div *ngIf="form.controls.taxId.errors.maxlength">
                                            Tax ID should be max {{form.controls.taxId.errors.maxlength.requiredLength}}
                                            characters</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>QuickBooks Client Id</h5>
                                </div>
                                <div class="matrixFromField">
                                    <input type="text" class="form-control" formControlName="quickBookClientId"
                                        [ngClass]="{ 'is-invalid': submitted && form.controls.quickBookClientId.errors }" />
                                    <div *ngIf="submitted && form.controls.quickBookClientId.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="form.controls.quickBookClientId.errors.required">
                                            QuickBooks Client Id is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>QuickBooks Client Secret</h5>
                                </div>
                                <div class="matrixFromField">
                                    <input type="text" class="form-control" formControlName="quickBookClientSecret"
                                        [ngClass]="{ 'is-invalid': submitted && form.controls.quickBookClientSecret.errors }" />
                                    <div *ngIf="submitted && form.controls.quickBookClientSecret.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="form.controls.quickBookClientSecret.errors.required">
                                            QuickBooks Client Secret is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>QuickBooks Application Environment</h5>
                                </div>
                                <div class="matrixFromField">
                                    <select class="form-control hDropDown" formControlName="quickBookAppEnvironment"
                                        [ngClass]="{ 'is-invalid': submitted && form.controls.quickBookAppEnvironment.errors }">
                                        <option value="">Select Environment</option>
                                        <option value="sandbox">Sandbox</option>
                                        <option value="production">Production</option>
                                    </select>
                                    <div *ngIf="submitted && form.controls.quickBookAppEnvironment.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="form.controls.quickBookAppEnvironment.errors.required">
                                            QuickBooks Application Environment is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>W-9 document</h5>
                                </div>
                                <div class="matrixFromField">
                                    <button type="button" class="form-control attachBtn"
                                        (click)="documentAttachment.click()"
                                        [ngClass]="{ 'is-invalid': submitted && model.documents.length == 0 }">Choose
                                        your file</button>
                                    <input type="file" class="d-none" accept="*" #documentAttachment multiple
                                        (change)="onDocumentSelect($event)" />
                                    <div *ngIf="submitted && model.documents.length == 0" class="invalid-feedback">
                                        <div>Please upload W-9 document </div>
                                    </div>
                                    <div class="attachemntWrp" *ngIf="model.documents.length">
                                        <ul>
                                            <li *ngFor="let item of model.documents; let $index = index">
                                                <a [href]="item.fileUrl" title="{{item.originalFileName}}"
                                                    download="{{item.originalFileName}}" target="_blank">
                                                    <!-- <em class="fa fa-picture-o" aria-hidden="true"></em> -->
                                                    <span class="attachmentUrl"
                                                        [textContent]="item.originalFileName"></span>
                                                </a>
                                                <span class="close" (click)="deleteDocument(item, $index)"
                                                    title="Delete {{item.originalFileName}}"><em class="fa fa-times"
                                                        aria-hidden="true"></em></span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Contact Name</h5>
                                </div>
                                <div class="matrixFromField">
                                    <div class="contactName" formArrayName="contacts"
                                        *ngFor="let item of form.get('contacts').controls; let i = index;">
                                        <div [formGroupName]="i">
                                            <input type="text" class="form-control" formControlName="name"
                                                [ngClass]="{ 'is-invalid': submitted && (form.controls.contacts.errors || item.controls.name.errors) }" />

                                            <div *ngIf="submitted && item.controls.name.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="item.controls.name.errors.maxlength">
                                                    Contact Name should be
                                                    max
                                                    {{item.controls.name.errors.maxlength.requiredLength}} characters
                                                </div>
                                            </div>
                                            <div *ngIf="submitted && form.controls.contacts.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="form.controls.contacts.errors.required">Atleast one Contact
                                                    Name is required</div>
                                            </div>
                                        </div>
                                    </div>


                                    <a class="addMComp" href="javascript:void(0)" (click)="addContact()">
                                        <em class="fa fa-plus" aria-hidden="true"></em> Add
                                    </a>

                                </div>
                            </div>
                            <div class="btnwrp">
                                <button class="btn btn-red" type="submit">Save</button>
                                <button class="btn btn-tsrp" type="button" (click)="onCancel()">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>