var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { DataTableDirective } from "angular-datatables";
import { BlockUI } from "ng-block-ui";
import { AppUtils } from "src/app/helpers";
import { MessagingService, CarLayoutLocationService } from "src/app/services";
import { environment } from "src/environments/environment";
export class CarLayoutManageLocationComponent {
    constructor(messagingService, carLayoutPanelService, appUtils) {
        this.messagingService = messagingService;
        this.carLayoutPanelService = carLayoutPanelService;
        this.appUtils = appUtils;
        this.dtOptions = {};
        this.rowIndex = 0;
    }
    ngAfterViewInit() {
        this.datatableElement.dtInstance.then((dtInstance) => this.dtInstance = dtInstance);
        $(document).on('mouseup.custom', function (e) {
            if ($(e.target).closest(".dropdown-menu.action-dropdown").length === 0) {
                $(".dropdown-menu.action-dropdown").hide();
            }
        });
    }
    ngOnDestroy() {
        $(document).off('mouseup.custom');
    }
    ngOnInit() {
        this.initDataTable();
    }
    initDataTable() {
        const self = this;
        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter user groups...',
                paginate: {
                    first: '<em class="fa fa-angle-double-left">',
                    last: '<em class="fa fa-angle-double-right">',
                    previous: '<em class="fa fa-angle-left">',
                    next: '<em class="fa fa-angle-right">'
                }
            },
            search: { search: null },
            searching: false,
            displayStart: self.rowIndex,
            paging: false,
            pagingType: 'full_numbers',
            pageLength: environment.paging.defaultPageSize,
            lengthMenu: environment.paging.lengthMenu,
            order: [[0, 'asc']],
            stateSave: true,
            info: false,
            scrollY: (window.innerHeight - 70 - 45) + "px",
            scrollCollapse: true,
            ajax: (dataTablesParameters, callback) => {
                self.blockUI.start();
                self.carLayoutPanelService.query(dataTablesParameters)
                    .subscribe(resp => {
                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: resp.data
                    });
                    self.blockUI.stop();
                }, error => {
                    self.messagingService.ProcessErrorResponse(error);
                    self.blockUI.stop();
                });
            },
            columns: [
                {
                    data: 'name',
                    title: 'Name',
                    width: '10%'
                },
                {
                    data: 'code',
                    title: 'Code',
                    width: '10%'
                }
            ]
        };
    }
}
__decorate([
    BlockUI('container-blockui-grid'),
    __metadata("design:type", Object)
], CarLayoutManageLocationComponent.prototype, "blockUI", void 0);
