<section class="compnyDtlPSec">
     <div class="container">
          <div class="row">
               <div class="col-12">
                    <a [routerLink]="['/manage/vendor']" class="backtolist">
                         <em class="fa fa-long-arrow-left" aria-hidden="true"></em>
                         Back to list
                    </a>
               </div>
          </div>
          <div class="row">
               <div class="col-12 col-md-12 col-lg-8 col-xl-8 offset-xl-2 offset-lg-2">
                    <div class="compnydtlwrp" *blockUI="'container-blockui'">
                         <div class="compnydtlH">
                              <div class="row">
                                   <div class="col-8 col-md-8">
                                        <h2>Vendor Detail</h2>
                                   </div>
                                   <div class="col-4 col-md-4">
                                        <div class="form-group">
                                             <div ngbDropdown class="actionWrp">
                                                  <button class="btn btn-red" id="dropdownBasic1"
                                                       ngbDropdownToggle>Actions</button>
                                                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                       <ul>
                                                            <li>
                                                                 <a ngbDropdownItem href="javascript:void(0);"
                                                                      [routerLink]="['/edit/vendor/',model.id]">
                                                                      <em class="fa fa-edit" aria-hidden="true"></em>
                                                                      Edit
                                                                 </a>
                                                            </li>
                                                            <li>
                                                                 <a ngbDropdownItem href="javascript:void(0);"
                                                                      (click)="openVendorStatusModal()">
                                                                      <em class="fa fa-times-circle-o"
                                                                           aria-hidden="true"></em>
                                                                      <span
                                                                           [innerHTML]="model.isActive?'Deactivate':'Activate'"></span>
                                                                 </a>
                                                            </li>
                                                            <li><a ngbDropdownItem href="javascript:void(0);"
                                                                      (click)="openVendorDeleteModal()">
                                                                      <em class="fa fa-trash-o" aria-hidden="true"></em>
                                                                      Delete
                                                                 </a>
                                                            </li>
                                                       </ul>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>

                         <h4>General Information</h4>
                         <div class="row">
                              <div class="col-12">
                                   <ul class="cmpnyDtlList">
                                        <li>
                                             <span>Vendor Name</span> <span>:</span>
                                             <span>{{model.name}}</span>
                                        </li>
                                        <li>
                                             <span>Store Names</span> <span>:</span>
                                             <span>{{stores}}</span>
                                        </li>

                                        <li>
                                             <span>Tax ID</span> <span>:</span>
                                             <span>{{model.taxId}}</span>
                                        </li>
                                        <li>
                                             <span>Name of Insurance Provider</span> <span>:</span>
                                             <span>{{model.insuranceProvider}}</span>
                                        </li>
                                        <li>
                                             <span>Insurance Policy Number</span> <span>:</span>
                                             <span>{{model.insurancePolicy}}</span>
                                        </li>

                                        <li>
                                             <span>Insurance Policy Expiration Date</span> <span>:</span>
                                             <span>{{model.insuranceExpireDate}}</span>
                                        </li>
                                        <li>
                                             <span>Vendor Accounting Email</span> <span>:</span>
                                             <span>{{model.invoiceEmail}}</span>
                                        </li>
                                        <li>
                                             <span>Phone Number</span> <span>:</span>
                                             <span>{{model.phoneCode}} {{model.phone}}</span>
                                        </li>
                                        <li>
                                             <span>Invoice Method</span> <span>:</span>
                                             <span>{{InvoicingPreference[model.invoicingPreference]}}</span>
                                        </li>
                                        <li>
                                             <span>Notification Method</span> <span>:</span>
                                             <span [innerHTML]="getNotificationText()"></span>
                                        </li>
                                        <li>
                                             <span>Categories</span> <span>:</span>
                                             <span
                                                  [innerHTML]="getConCatStringFromStringArray(model.categoryNames)"></span>
                                        </li>
                                        <li>
                                             <span>Tools</span> <span>:</span>
                                             <span
                                                  [innerHTML]="getConCatStringFromStringArray(model.subCategoryNames)"></span>
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <br><br>
                         <div>
                              <h4>Address</h4>
                              <div class="row">
                                   <div class="col-12">
                                        <ul class="cmpnyDtlList">
                                             <li>
                                                  <span>Address l</span>
                                                  <span>:</span>
                                                  <span>{{model.streetAddress}}</span>
                                             </li>
                                             <li>
                                                  <span>Address 2</span>
                                                  <span>:</span>
                                                  <span>{{model.blockAddress}}</span>
                                             </li>
                                             <li>
                                                  <span>City</span> <span>:</span>
                                                  <span>{{model.city}}</span>
                                             </li>
                                             <li>
                                                  <span>State</span> <span>:</span>
                                                  <span>{{model.state}}</span>
                                             </li>
                                             <li>
                                                  <span>ZIP</span> <span>:</span>
                                                  <span>{{model.zipCode}}</span>
                                             </li>
                                             <li>
                                                  <span>Country</span> <span>:</span>
                                                  <span>{{model.country}}</span>
                                             </li>
                                        </ul>
                                   </div>

                              </div>
                         </div>

                         <br><br>
                         <div class="attachemntWrp">
                              <h4>Attachments</h4>
                              <ul>
                                   <li *ngFor="let item of model.documents">
                                        <a [href]="item.fileUrl" title="{{item.originalFileName}}"
                                             download="{{item.originalFileName}}" target="_blank">
                                             <span class="attachmentUrl" [textContent]="item.originalFileName"></span>
                                             <span class="downloadFile">
                                                  <em class="fa fa-download" aria-hidden="true"></em>
                                             </span>
                                        </a>
                                   </li>
                              </ul>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</section>

<app-vendor-delete-confirmation #deleteConfirmationModal (delete)="delete($event)">
</app-vendor-delete-confirmation>
<app-vendor-status-change-confirmation #statusChangeConfirmationModal (toggle)="toggle($event)">
</app-vendor-status-change-confirmation>