import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { ApplicationRoleType, NotificationType } from 'src/app/enums';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { AppUtils } from 'src/app/helpers';
import { AccountRecoverModel, CategoryDetailModel, SelectListModel, SubCategorySelectListModel, UserDetailModel } from 'src/app/models';
import { UserService, MessagingService, AuthenticationService, CategoryService, SubCategoryService, FilterStateService } from 'src/app/services';

@Component({
    selector: 'app-user-detail',
    templateUrl: './user.detail.component.html',
    styleUrls: ['./user.detail.component.css']
})
export class UserDetailComponent implements OnInit {
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild('deleteConfirmationModal', { static: false }) deleteConfirmationModal: any;
    @ViewChild('statusChangeConfirmationModal', { static: false }) statusChangeConfirmationModal: any;
    @ViewChild('resetPasswordOtherUserConfirmationModal', { static: false }) resetPasswordOtherUserConfirmationModal: any;

    model: UserDetailModel;
    roleType: string;
    roles: string;
    storeNames: string;
    companyNames: string;
    vendorNames: string;

    ApplicationRoleType = ApplicationRoleType;
    roleTypeFilterOptions: Array<SelectListModel>;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
        private filterStateService: FilterStateService,
        private authenticationService: AuthenticationService,
        private messagingService: MessagingService,
        public appUtils: AppUtils
    ) {
        this.model = new UserDetailModel();

        filterStateService.changeFilterModelStatues(FilterPageType.userManage, true);

        this.route.params.subscribe(params => {
            this.model.id = params.userId;
        });
        this.roleTypeFilterOptions = this.appUtils.getApplicationRoleTypeOptions();
    }

    ngOnInit() {
        this.loadUser();
    }

    goToEdit() {
        this.router.navigate(['edit/user', this.model.id]);
    }

    loadUser() {
        this.blockUI.start();
        forkJoin([
            this.userService.getUserDetail(this.model.id)
        ]).subscribe(([
            userModel
        ]) => {
            this.model = userModel;
            this.roles = this.model.roles.join(', ');

            this.roleType = this.roleTypeFilterOptions.find(x => x.id == this.model.applicationRoleType).name;
            this.storeNames = this.model.userStoreAssociations.map(x => x.name).join(', ');
            this.companyNames = this.model.userCompanyAssociations.map(x => x.name).join(', ');
            this.vendorNames = this.model.userVendorAssociations.map(x => x.name).join(', ');
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    openDeleteConfirmation() {
        this.deleteConfirmationModal.openModal(this.model);
    }

    delete(user: UserDetailModel) {
        this.blockUI.start();
        this.userService.delete(user.id).subscribe(
            () => {
                setTimeout(() => {
                    this.router.navigate(['/manage/user']);
                });
                this.messagingService.success(`User has been deleted successfully.`);
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
            });
    }

    openStatusChangeConfirmation() {
        this.statusChangeConfirmationModal.openModal(this.model);
    }

    toggle(user: UserDetailModel) {
        this.blockUI.start();
        this.userService.toggleStatus(user.id).subscribe(
            () => {
                this.messagingService.success(`User has been ${(user.isActive ? 'deactivated' : 'activated')} successfully.`);
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
            });
    }

    openResetPasswordOtherUserConfirmation() {
        this.resetPasswordOtherUserConfirmationModal.openModal(this.model);
    }

    resetPassword(user: UserDetailModel) {
        this.blockUI.start();
        let accountRecoverModel = new AccountRecoverModel();
        accountRecoverModel.email = user.userName;
        this.authenticationService.recover(accountRecoverModel).subscribe(
            () => {

                this.messagingService.success(`Password for ${user.userName} reset successfully.`);
                this.blockUI.stop();
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
                this.blockUI.stop();
            });
    }

    getNotificationText(): string {
        let arr = new Array<string>();
        if (this.model != null) {
            if (this.model.isEmailNotificationActive) {
                arr.push('Email');
            }
            if (this.model.isSMSNotificationActive) {
                arr.push('SMS');
            }
        }
        return arr.join(',&nbsp;');
    }
}
