import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ApplicationRoleType } from "../enums";
import { PermissionModel, DataTableResponseModel, RoleDetailModel, RoleAddModel, RoleEditModel, RoleModel } from "../models";

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(private http: HttpClient) {
  }

  getAlRoles(): Observable<RoleModel[]> {
    return this.http.get<RoleModel[]>(`${environment.apiBaseUrl}/role/all`);
  }

  getRoleByType(applicationRoleType: ApplicationRoleType): Observable<RoleModel[]> {
    return this.http.get<RoleModel[]>(`${environment.apiBaseUrl}/role/type/${applicationRoleType}`);
  }

  getAllPermissions(): Observable<PermissionModel[]> {
    return this.http.get<PermissionModel[]>(`${environment.apiBaseUrl}/role/permissions`);
  }

  query(dataTablesParameters: any) {
    return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/role/query`, dataTablesParameters);
  }

  get(id: string): Observable<RoleEditModel> {
    return this.http.get<RoleEditModel>(`${environment.apiBaseUrl}/role/${id}`);
  }

  add(data: RoleAddModel) {
    return this.http.post<RoleDetailModel>(`${environment.apiBaseUrl}/role`, data);
  }

  update(data: RoleEditModel) {
    return this.http.put<RoleDetailModel>(`${environment.apiBaseUrl}/role`, data);
  }

  delete(id: string) {
    return this.http.delete<object>(`${environment.apiBaseUrl}/role/${id}`);
  }

  toggleStatus(id: string) {
    return this.http.post<object>(`${environment.apiBaseUrl}/role/toggle-status/${id}`, null);
  }
}
