
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { FilterPageType } from "src/app/enums/filter.page.type.enum";
import { CheckInTypeDetailModel } from "src/app/models";
import { CheckInTypeService, FilterStateService, MessagingService } from "src/app/services";


@Component({
    selector: 'app-detail-check-in-type',
    templateUrl: './detail.check-in-type.component.html',
    styleUrls: ['./detail.check-in-type.component.css']
})

export class CheckInTypeDetailComponent implements OnInit {

    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild('deleteConfirmationModal', { static: false }) deleteConfirmationModal: any;
    @ViewChild('statusChangeConfirmationModal', { static: true }) statusChangeConfirmationModal: any;

    model: CheckInTypeDetailModel;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private messagingService: MessagingService,
        private checkInTypeService: CheckInTypeService,
        private filterStateService: FilterStateService
    ) {
        filterStateService.changeFilterModelStatues(FilterPageType.checkInTypeManage, true);
        this.model = new CheckInTypeDetailModel();
        this.route.params.subscribe(params => this.model.id = Number(params.checkInTypeId));
    }

    ngOnInit() {
        this.loadCheckInType();
    }

    loadCheckInType() {
        this.blockUI.start();
        this.checkInTypeService.get(this.model.id).subscribe((data: any) => {
            this.model = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    openDeleteModal() {
        this.deleteConfirmationModal.openModal(this.model);
    }

    delete(checkInType: CheckInTypeDetailModel) {
        this.blockUI.start();
        this.checkInTypeService.delete(checkInType.id).subscribe(
            () => {
                setTimeout(() => {
                    this.router.navigate(['manage/check-in-type']);
                });
                this.messagingService.success(`Check-In type has been deleted successfully.`);
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
            });
    }

    openStatusModal() {
        this.statusChangeConfirmationModal.openModal(this.model);
    }

    toggle(checkInType: CheckInTypeDetailModel) {
        this.blockUI.start();
        this.checkInTypeService.toggleStatus(checkInType.id).subscribe(
            () => {
                this.messagingService.success(`Check-In type has been ${(checkInType.isActive ? 'deactivated' : 'activated')} successfully.`);
                this.model.isActive = !this.model.isActive;
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
            });
    }
}