import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessagingService } from 'src/app/services';

@Component({
    selector: 'app-no-bid-confirmation',
    templateUrl: './no.bid.confirmation.component.html',
    styleUrls: ['./no.bid.confirmation.component.css']
})
export class NoBidConfirmationComponent implements OnDestroy {

    @BlockUI('container-blockui-confirmation') blockUI: NgBlockUI;
    @ViewChild('confirmationModal', { static: false }) confirmationModal: any;
    @Output() nobid = new EventEmitter<boolean>();

    modalReference: NgbModalRef;
    form: FormGroup;
    submitted: boolean;


    constructor(private modalService: NgbModal,
        private formBuilder: FormBuilder,
        private messagingService: MessagingService) {
    }

    openModal() {
        this.form = this.formBuilder.group({
        });

        this.modalReference = this.modalService.open(this.confirmationModal, { centered: true });
    }

    onSubmit() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }

        this.form.reset();
        this.submitted = false;

        setTimeout(() => {
            this.nobid.emit(true);
        });
        this.onCancel();
    }

    onCancel() {
        if (!this.modalReference) {
            return;
        }
        this.modalReference.close();
    }

    ngOnDestroy(): void {
        this.onCancel();
    }
}
