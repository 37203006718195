import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, RoutesRecognized } from '@angular/router';
import { PermissionType } from 'src/app/enums';
import { AuthenticateResponseModel, CompanyWithStoreModel, SelectListModel } from 'src/app/models';
import { ApplicationContextService, AuthenticationService, CompanyService, MessagingService } from 'src/app/services';

@Component({
	selector: 'app-nav-menu',
	templateUrl: './nav-menu.component.html',
	styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit, OnDestroy {
	applicationLogo: string = "assets/images/recon-partners-logo.png";
	isExpanded: boolean = false;
	isValidUser: boolean = false;
	PermissionType = PermissionType;
	user: AuthenticateResponseModel;

	companies: Array<CompanyWithStoreModel> = new Array<CompanyWithStoreModel>();
	stores: Array<SelectListModel> = new Array<SelectListModel>();
	headerConfig: any = {};

	//Form Group
	form: FormGroup;
	submitted = false;
	contextSubscription: any;

	constructor(
		private router: Router,
		private authenticationService: AuthenticationService,
		private messagingService: MessagingService,
		private companyService: CompanyService,
		private formBuilder: FormBuilder,
		private applicationContextService: ApplicationContextService
	) {

	}

	ngOnDestroy(): void {
		this.contextSubscription && this.contextSubscription.unsubscribe();
	}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			company: [{ value: null }],
			store: [{ value: null }]
		});

		this.router.events.subscribe(event => {
			if (event instanceof RoutesRecognized) {
				this.headerConfig = Object.assign({
					showControls: false,
					disableControls: false,
					showStoreControl: true
				}, event.state.root.firstChild.data.header);


				const action = this.headerConfig.disableControls ? 'disable' : 'enable';
				this.form.controls.company[action]();
				this.form.controls.store[action]();
			}
		});

		this.authenticationService.user$.subscribe((user) => {
			this.isValidUser = user != null && !this.authenticationService.isTokenExpired();
			this.user = user;

			if (this.isValidUser) {
				this.contextSubscription = this.applicationContextService.context$.subscribe((str: string) => {
					this.loadCompanyWithStore();
				});
			}
		});

		if (localStorage.getItem('selectedCompanyId')) {
			this.form.controls.company.setValue(+localStorage.getItem('selectedCompanyId'));
		}
		if (localStorage.getItem('selectedStoreId')) {
			this.form.controls.store.setValue(+localStorage.getItem('selectedStoreId'));
		}
	}

	private loadCompanyWithStore() {
		this.companyService.getLoggedInUserCompaniesWithStores().subscribe(companies => {
			this.setContext(companies);
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
		});
	}

	private setContext(companies: Array<CompanyWithStoreModel>) {
		if (!Array.isArray(companies) || companies.length == 0) {
			this.form.controls.company.setValue(null);
			this.form.controls.store.setValue(null);

			this.applicationContextService.setCompany(null);
			this.applicationContextService.setStore(null);
			return;
		}

		//Set Companies list
		this.companies = companies;

		let companyId = this.form.controls.company.value;
		let storeId = this.form.controls.store.value;

		//Check if any preselected company exists
		let company = companies.find(x => x.id == companyId);

		// Predefined company doesn't exists set first company and exit
		if (company == null) {
			company = this.companies[0];
			this.form.controls.company.setValue(company.id);
			this.applicationContextService.setCompany(company);

			this.stores = company.stores;
			this.form.controls.store.setValue(Array.isArray(this.stores) && this.stores.length ? this.stores[0].id : null);
			this.applicationContextService.setStore(Array.isArray(this.stores) && this.stores.length ? this.stores[0] : null);
			return;
		}

		//Predefined company exists 
		this.form.controls.company.setValue(company.id);
		this.applicationContextService.setCompany(company);

		//Set stores
		this.stores = company.stores;
		if (!Array.isArray(company.stores) || company.stores.length == 0) {
			this.form.controls.store.setValue(null);
			this.applicationContextService.setStore(null);
			return;
		}

		let store = company.stores.find(x => x.id == storeId) || company.stores[0];
		this.form.controls.store.setValue(store.id);
		this.applicationContextService.setStore(store);
	}

	public onCompanyChange() {
		let companyId = Number(this.form.value.company);
		let company = this.companies.find(x => x.id == companyId);
		this.applicationContextService.setCompany(company);

		this.stores = company.stores;
		this.form.controls.store.setValue(Array.isArray(this.stores) && this.stores.length ? this.stores[0].id : null);
		this.applicationContextService.setStore(Array.isArray(this.stores) && this.stores.length ? this.stores[0] : null);
	}

	public onStoreChange() {
		let storeId = Number(this.form.value.store);
		let store = this.stores.find(x => x.id == storeId);
		this.applicationContextService.setStore(store);
	}

	public collapse() {
		this.isExpanded = false;
	}

	public toggle() {
		this.isExpanded = !this.isExpanded;
	}

	public logout() {
		this.authenticationService.logout().subscribe(() => {
			localStorage.clear();
			this.router.navigate(['/account/login']);
			this.isValidUser = false;
		});
	}
}