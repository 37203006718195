import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Console } from 'console';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PricingType, UnitOfMeasure } from 'src/app/enums';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { AppUtils } from 'src/app/helpers';
import { CompanyWithStoreModel, SelectListModel, StoreListItemModel } from 'src/app/models';
import { ApplicationContextService, FilterStateService, MessagingService, StoreService } from 'src/app/services';
import { PriceMatrixService } from 'src/app/services/price-matrix.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-manage-pricing-matrix',
    templateUrl: './manage.price-matrix.component.html',
    styleUrls: ['./manage.price-matrix.component.css']
})
export class ManagePriceMatrixComponent implements OnInit, OnDestroy, AfterViewInit {
    frmSearch: FormGroup;
    filterOptions: any;
    statusOptions: any;
    //Data Table Options
    @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtInstance: DataTables.Api;
    rowIndex: number = 0;

    filterCategoryOrTool: string;

    @BlockUI('container-blockui-grid') blockUI: NgBlockUI;
    @ViewChild('deleteConfirmationModal', { static: true }) deleteConfirmationModal: any;
    @ViewChild('statusChangeConfirmationModal', { static: true }) statusChangeConfirmationModal: any;

    storeSelectList: Array<SelectListModel> = new Array<SelectListModel>();
    storeSubscription: any;
    companySubscription: any;
    PricingType = PricingType;


    constructor(private formBuilder: FormBuilder,
        private router: Router,
        private messagingService: MessagingService,
        public appUtils: AppUtils,
        private storeService: StoreService,
        private priceMatrixService: PriceMatrixService,
        private applicationContextService: ApplicationContextService,
        public currencyPipe: CurrencyPipe,
        private filterStateService: FilterStateService
    ) {
        this.filterOptions = this.appUtils.getFilterOperationOptions();
        this.statusOptions = this.appUtils.getStatusOptions();
    }

    ngAfterViewInit() {
        this.updateFormFromFilterModel();

        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);

    }

    updateFormFromFilterModel() {
        const tempFilterModel = this.filterStateService.getFilterModel(FilterPageType.priceMatrixManage);
        if (tempFilterModel && tempFilterModel.isActive) {
            this.frmSearch.controls.name.setValue(tempFilterModel.filterKey);
            this.frmSearch.controls.filterOption.setValue(tempFilterModel.filterOption);
            this.frmSearch.controls.statusOption.setValue(tempFilterModel.statusOption);
        }
    }

    updateFilterModel() {
        const filterModel = this.filterStateService.getFilterModel(FilterPageType.priceMatrixManage);
        filterModel.page = FilterPageType.priceMatrixManage;
        filterModel.filterKey = this.frmSearch.controls.name.value;
        filterModel.filterOption = this.frmSearch.controls.filterOption.value;
        filterModel.statusOption = this.frmSearch.controls.statusOption.value;
        filterModel.isActive = false;
    }


    ngOnInit() {
        this.frmSearch = this.formBuilder.group({
            storeId: [-1],
            name: [''],
            filterOption: [1], //Contains
            statusOption: [2] //Active
        });

        this.companySubscription = this.applicationContextService.company$.subscribe((company: CompanyWithStoreModel) => {
            if (company == null) {
                this.storeSelectList = new Array<SelectListModel>();
            } else {
                this.storeSelectList = [...company.stores];
            }
        });
        this.storeSubscription = this.applicationContextService.store$.subscribe((storeSelectListItem: SelectListModel) => {
            if (storeSelectListItem) {
                this.frmSearch.controls.storeId.setValue(storeSelectListItem.id);
            }
        });
        this.frmSearch.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
            this.applyFilter();
        });
        this.initDataTable();
    }

    ngOnDestroy(): void {

        this.storeSubscription.unsubscribe();
        this.companySubscription.unsubscribe();
    }

    applyFilter() {
        this.dtInstance.ajax.reload();
    }

    initDataTable() {
        //Data Table Example.
        const self = this;
        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter...',
                paginate: {
                    first: '<em class="fa fa-angle-double-left">',
                    last: '<em class="fa fa-angle-double-right">',
                    previous: '<em class="fa fa-angle-left">',
                    next: '<em class="fa fa-angle-right">'
                }
            },
            search: { search: null },
            searching: false,
            displayStart: self.rowIndex,
            paging: false,
            pagingType: 'full_numbers',
            pageLength: environment.paging.defaultPageSize,
            lengthMenu: environment.paging.lengthMenu,
            order: [[1, 'asc']],
            info: false,
            stateSave: true,
            scrollY: (window.innerHeight - 70 - 45) + "px",
            scrollCollapse: true,
            ajax: (dataTablesParameters: any, callback) => {

                self.updateFilterModel();

                let selectedStoreId = Number(self.frmSearch.controls.storeId.value);
                dataTablesParameters.storeId = selectedStoreId; // > 0 ? selectedStoreId : null;


                dataTablesParameters.name = self.frmSearch.controls.name.value;
                dataTablesParameters.filterBy = Number(self.frmSearch.controls.filterOption.value);
                dataTablesParameters.status = self.statusOptions.find(x => x.id == self.frmSearch.controls.statusOption.value).value;

                self.blockUI.start();
                self.priceMatrixService.query(dataTablesParameters)
                    .subscribe(resp => {
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
                        self.blockUI.stop();
                    }, error => {
                        self.messagingService.ProcessErrorResponse(error);
                        self.blockUI.stop();
                    });


            },
            columns: [
                {
                    data: 'checkInTypeName',
                    title: 'CheckIn Type',
                    width: '12%',
                },
                {
                    data: 'categoryName',
                    title: 'Category',
                    width: '12%',
                },
                {
                    data: 'subCategoryName',
                    title: 'Tool',
                    width: '12%',
                },
                {
                    data: 'carLayoutPanelNames',
                    title: 'Panel',
                    width: '15%',
                    orderable: false,
                    render: function (carLayoutPanelNames) {
                        return carLayoutPanelNames.join(', ');
                    }
                },
                {
                    data: 'unitOfMeasure',
                    title: 'Unit of measure for Pricing',
                    width: '10%',
                    render: function (unitOfMeasure) {
                        if (UnitOfMeasure.Panel == unitOfMeasure) {
                            return 'Panel';
                        }
                        if (UnitOfMeasure.Spot == unitOfMeasure) {
                            return 'Spot';
                        }
                        if (UnitOfMeasure.Vehicle == unitOfMeasure) {
                            return 'Vehicle';
                        }
                        return '';
                    }
                },
                {
                    data: 'prices',
                    title: 'Price',
                    width: '10%',
                    orderable: false,
                    render: function (prices, type, row) {
                        // console.log(row.unitOfMeasure)
                        if (row.unitOfMeasure == UnitOfMeasure.Vehicle) {
                            return `<span>$ ${prices[0]}</span>`;
                        }

                        let prefix = row.unitOfMeasure == UnitOfMeasure.Spot ? 'Spot' : 'Panel';
                        return prices.map((price, index) => {
                            const totalPrice = self.currencyPipe.transform(price, 'USD' , '', '1.2-2');
                            return `<span>${prefix} ${index + 1}: $ ${totalPrice}</span>`;
                        }).join('<br/> ');
                    }
                },
                {
                    data: "isReconPackage",
                    title: 'Recon Pkg',
                    width: '8%',
                    render: function (isReconPackage) {
                        return isReconPackage ? 'Yes' : 'No';
                    }
                },
                {
                    data: 'isActive',
                    title: 'Status',
                    width: '7%',
                    render: function (isActive) {
                        return isActive
                            ? '<span class="badge badge-success">Active</span>'
                            : '<span class="badge badge-danger">Inactive</span>';
                    }
                },
                {
                    data: null,
                    title: 'Action',
                    width: '14%',
                    orderable: false,
                    className: 'text-center',
                    visible: true,
                    render: function (data, type, row) {
                        //Render Activate or Deactivate Button
                        let statusHtml = row.isActive ?
                            `<button title='Deactivate' action-type='toggle-status'><em class="fa fa-ban" aria-hidden="true"></em></button>` :
                            `<button title='Activate' action-type='toggle-status'><em class="fa fa-check" aria-hidden="true"></em></button>`;

                        return `                    
                        <button title='View Detail' action-type='view'><em class="fa fa-eye" aria-hidden="true"></em></button>
                        <button title='Clone' action-type='clone'><em class="fa fa-clone" aria-hidden="true"></em></button>
                        ${statusHtml}
                        <button title='Edit' action-type='edit'><em class="fa fa-edit" aria-hidden="true"></em></button>
                        <button title='Delete' action-type='delete'><em class="fa fa-trash" aria-hidden="true"></em></button>
                        `;
                    }
                }
            ],
            rowCallback: function (row, data: StoreListItemModel) {
                const _row = $(row);

                _row.find('[action-type="link-detail"],[action-type="view"]')
                    .off('click')
                    .on('click', function () {
                        self.router.navigate(['price-matrix', 'detail', data.id]);
                    });

                _row.find('[action-type="clone"]')
                    .off('click')
                    .on('click', function () {
                        self.router.navigate(['clone', 'price-matrix', data.id]);
                    });

                _row.find('[action-type="edit"]')
                    .off('click')
                    .on('click', function () {
                        self.router.navigate(['edit', 'price-matrix', data.id]);
                    });

                _row.find('[action-type="delete"]')
                    .off('click')
                    .on('click', function () {
                        self.openDeleteModal(data);
                    });

                _row.find('[action-type="toggle-status"]')
                    .off('click')
                    .on('click', function () {
                        self.openStatusConfirmationModal(data);
                    });
            }
        };
    }

    openDeleteModal(data: StoreListItemModel) {
        this.deleteConfirmationModal.openModal(data);
    }

    delete(store: StoreListItemModel) {
        this.blockUI.start();
        this.priceMatrixService.delete(store.id).subscribe(
            () => {
                setTimeout(() => {
                    this.dtInstance.ajax.reload(null, false);
                });
                this.messagingService.success(`Price matrix has been deleted successfully.`);
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
            });
    }

    openStatusConfirmationModal(data: StoreListItemModel) {
        this.statusChangeConfirmationModal.openModal(data);
    }

    toggle(store: StoreListItemModel) {
        this.blockUI.start();
        this.priceMatrixService.toggleStatus(store.id).subscribe(
            () => {
                setTimeout(() => {
                    this.dtInstance.ajax.reload(null, false);
                });
                this.messagingService.success(`Price matrix has been ${(store.isActive ? 'deactivated' : 'activated')} successfully.`);
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
            });
    }

    navigateAddStore() {
        this.router.navigate(['add', 'store']);
    }
}
