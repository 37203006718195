<section class="gnrlformSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-6">
                <h2 class="approvalH">Invoice List</h2>
            </div>
            <div class="col-6 text-right">
                <ul class="printDown">
                    <li><a href="#"><img [src]="prinImg" alt="Print" /> </a></li>
                    <li><a href="#"><img [src]="downloadImg" alt="Download" /></a></li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-12 col-lg-5">
                <div class="searchBy">
                    <p>Search by :
                        <span><input type="checkbox" id="invoiceNumber" /> <label for="invoiceNumber">Invoice
                                Number</label></span>
                        <span><input type="checkbox" id="vINNumber" /> <label for="vINNumber">VIN Number</label></span>
                        <span><input type="checkbox" id="stockNumber" /> <label for="stockNumber">Stock
                                Number</label></span>
                    </p>
                </div>
                <div class="searchIcon">
                    <form>
                        <div class="form-group">
                            <input type="text" placeholder="Search Invoice" class="form-control">
                            <button class="searchBtn"><em class="fa fa-search" aria-hidden="true"></em></button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-7">
                <div class="filterBy">
                    <h5>Filter by :</h5>
                    <form>
                        <div class="filterFieldWrp">
                            <div class="filterField">
                                <div class="form-group hidden">
                                    <div class="input-group">
                                        <input name="datepicker" class="form-control" ngbDatepicker
                                            #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                            (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                                            outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
                                        <ng-template #t let-date let-focused="focused">
                                            <span class="custom-day" [class.focused]="focused"
                                                [class.range]="isRange(date)"
                                                [class.faded]="isHovered(date) || isInside(date)"
                                                (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                                {{ date.day }}
                                            </span>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                            <div class="filterField">
                                <div class="form-group">
                                    <div class="input-group">
                                        <input #dpFromDate class="form-control" placeholder="yyyy-mm-dd"
                                            name="dpFromDate" [value]="fromDate"
                                            (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar"
                                                (click)="datepicker.toggle()" type="button"><img
                                                    [src]="calenderImg" alt="Calender" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="filterField">
                                <div class="form-group ml-2">
                                    <div class="input-group">
                                        <input #dpToDate class="form-control" placeholder="yyyy-mm-dd" name="dpToDate"
                                            [value]="toDate" (input)="toDate = validateInput(toDate, dpToDate.value)">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar"
                                                (click)="datepicker.toggle()" type="button"><img
                                                    [src]="calenderImg" alt="Calender" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="filterField">
                                <div class="form-group">
                                    <select class="form-control">
                                        <option>Check-In</option>
                                        <option>Check-In</option>
                                        <option>Check-In</option>
                                        <option>Check-In</option>
                                    </select>
                                </div>
                            </div>
                            <div class="filterField">
                                <div class="form-group">
                                    <select class="form-control">
                                        <option>Bill To</option>
                                        <option>Bill To</option>
                                        <option>Bill To</option>
                                    </select>
                                </div>
                            </div>
                            <div class="filterField">
                                <div class="form-group">
                                    <select class="form-control">
                                        <option>Pay To</option>
                                        <option>Pay To</option>
                                        <option>Pay To</option>
                                    </select>
                                </div>
                            </div>
                            <div class="filterField">
                                <div class="form-group">
                                    <select class="form-control">
                                        <option>Status To</option>
                                        <option>Status To</option>
                                        <option>Status To</option>
                                    </select>
                                </div>
                            </div>
                            <div class="filterField">
                                <div class="form-group">
                                    <button class="btn btn-red">Applay</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="tableWrp table-responsive mt-5">
            <table class="table dataTable table-striped">
                <thead>
                    <tr>
                        <th scope="col">Invoice Number</th>
                        <th scope="col">Invoice Date</th>
                        <th scope="col">Invoice Amount</th>
                        <th scope="col">Invoice Status</th>
                        <th scope="col">Store Name</th>
                        <th scope="col">Bill To</th>
                        <th scope="col">Pay To</th>
                        <th scope="col">Year</th>
                        <th scope="col">Make</th>
                        <th scope="col">Model</th>
                        <th scope="col">Stock No.</th>
                        <th scope="col">VIN (Last 8 Digit)</th>
                        <th scope="col"><input type="checkbox"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>#RP98725</td>
                        <td>06/11/2020</td>
                        <td>$5000</td>
                        <td>Pending</td>
                        <td>Lorem</td>
                        <td></td>
                        <td></td>
                        <td>1999</td>
                        <td></td>
                        <td></td>
                        <td>87654</td>
                        <td>87654321</td>
                        <td><span><input type="checkbox" id="one"><label for="one"></label></span></td>
                    </tr>
                    <tr>
                        <td>#RP98725</td>
                        <td>06/11/2020</td>
                        <td>$5000</td>
                        <td>Pending</td>
                        <td>Lorem</td>
                        <td></td>
                        <td></td>
                        <td>1999</td>
                        <td></td>
                        <td></td>
                        <td>87654</td>
                        <td>87654321</td>
                        <td><span><input type="checkbox" id="two"><label for="two"></label></span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</section>