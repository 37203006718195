<ng-template #stepDetail let-modal>
    <div>
        <div class="modal-body">
            <div class="workflowPopupWrp" *blockUI="'container-blockui-work-step'">
                <h2>Category behavior</h2>

                <div class="row">
                    <div class="col-12 col-sm-6">
                        <div class="from-group"><label>Dependency Locked</label></div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="from-group"><label>{{ model.dependencyLocked ? 'Yes' : 'No'}}</label></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <div class="from-group"><label>Category</label></div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="from-group"><label>Mandatory OP Code</label></div>
                    </div>
                </div>
                <div class="row" *ngFor="let item of viewModels; let i = index;">
                    <div class="col-12 col-sm-6">
                        <div class="from-group"><label>{{item.categoryName}}</label> <label
                                *ngIf="item.subCategoryName">- {{item.subCategoryName}}</label> </div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="from-group">
                            <span class="pill-box"
                                *ngFor="let opCode of item.selectedOperationCodeSelectList">{{opCode.name}}</span>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="fonm-control">
                            <div class="btnWrp">
                                <button class="btn btn-tsrp" type="button" (click)="onClose()">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>