import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { ColorPickerService } from "ngx-color-picker";
import { ToastrService } from "ngx-toastr";
import { AppUtils, StringLengthConstants } from "src/app/helpers";
import { CategoryAddModel, CategoryEditModel } from "src/app/models";
import { CategoryService, MessagingService } from "src/app/services";


@Component({
    selector: 'app-add-category',
    templateUrl: './add.category.component.html',
    styleUrls: ['./add.category.component.css']
})

export class CategoryAddComponent implements OnInit {
    frmCategory: FormGroup;
    submitted: boolean = false;
    model: CategoryAddModel;
    presetColors: Array<string>;
    colorCode: string;
    @BlockUI('blockui-container') blockUI: NgBlockUI;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private toastr: ToastrService,
        private messagingService: MessagingService,
        private categoryService: CategoryService,
        private stringLengthConstants: StringLengthConstants,
        private cpService: ColorPickerService,
        private appUtils: AppUtils
    ) {
        this.model = new CategoryAddModel();
        this.presetColors = this.appUtils.getPresetColors();
        this.colorCode = this.presetColors[0];
    }

    ngOnInit() {
        this.frmCategory = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            code: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.ShortCode), Validators.minLength(2), Validators.pattern(new RegExp('^[A-Za-z0-9]*$'))]],
            description: ['', [Validators.maxLength(this.stringLengthConstants.Description)]],
            sequence: ['', [Validators.min(1), Validators.pattern(new RegExp('^[-+]?[0-9]*\.?[0-9]*$'))]],
            useToolInWorkflow: [false]
        });
    }

    onSubmit() {
        this.submitted = true;
        if (this.frmCategory.invalid) {
            return;
        }

        this.model.name = this.frmCategory.controls.name.value;
        this.model.description = this.frmCategory.controls.description.value;
        this.model.code = this.frmCategory.controls.code.value;
        this.model.sequence = Number(this.frmCategory.controls.sequence.value);
        this.model.colorCode = this.colorCode;
        this.model.useToolInWorkflow = this.frmCategory.controls.useToolInWorkflow.value;
        this.categoryService.add(this.model)
            .subscribe((data: CategoryEditModel) => {
                setTimeout(() => {
                    this.router.navigate(['/manage/category']);
                }, 10);
                setTimeout(() => {
                    this.toastr.success('Category has been added successfully.');
                }, 300);
                this.blockUI.stop();
            }, error => {
                this.messagingService.ProcessErrorResponse(error);
                this.blockUI.stop();
            });
    }

    public onChangeColorHex8(color: string): string {
        const hsva = this.cpService.stringToHsva(color, true);
        if (hsva) {
            this.colorCode = this.cpService.outputFormat(hsva, "hex", null);
            return this.colorCode;
        }
        return "";
    }
}
