<section class="datatableSer">
     <div class="container">
          <div class="row">
               <div class="col-12 col-md-6">
                    <h1>Manage Price Matrix</h1>
               </div>
               <div class="col-12 col-md-6 text-center text-md-right">
                    <button class="btn btn-red" type="button" [routerLink]="['/add/price-matrix']">Add Price
                         Matrix</button>
               </div>
          </div>
          <div class="row">
               <div class="col-12">
                    <div class="tableWrp">
                         <div class="filterWrp">
                              <form [formGroup]="frmSearch">
                                   <div class="row justify-content-end">
                                        <div class="col-12 col-md-12 col-lg-9">
                                             <div class="row">
                                                  <div class="col-12 col-sm-4">
                                                       <label>Category or Tool</label>
                                                       <input class="form-control" type="text" formControlName="name"
                                                            (keydown.enter)="applyFilter()" />
                                                  </div>
                                                  <div class="col-12 col-sm-4">
                                                       <label>Operation</label>
                                                       <select class="form-control" formControlName="filterOption">
                                                            <option *ngFor="let item of filterOptions"
                                                                 [value]="item.id">{{item.name}}
                                                            </option>
                                                       </select>
                                                  </div>
                                                  <div class="col-12 col-sm-4">
                                                       <label>Status</label>
                                                       <select class="form-control" formControlName="statusOption">
                                                            <option *ngFor="let item of statusOptions"
                                                                 [value]="item.id">{{item.name}}
                                                            </option>
                                                       </select>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </form>
                         </div>
                         <div *blockUI="'container-blockui-grid'" class="bluetableWrp table-responsive">
                              <table datatable [dtOptions]="dtOptions" data-page="2"
                                   class="table-hover break-all table w-100" summary="Price matrices"></table>
                         </div>
                    </div>
               </div>
          </div>

     </div>
</section>

<app-delete-confirmation-price-matrix #deleteConfirmationModal (delete)="delete($event)">
</app-delete-confirmation-price-matrix>
<app-status-confirmation-price-matrix #statusChangeConfirmationModal (toggle)="toggle($event)">
</app-status-confirmation-price-matrix>