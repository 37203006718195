import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Label } from "ng2-charts";
import { forkJoin, Subscription } from "rxjs";
import { KpiFilterModel, SelectListModel } from "src/app/models";
import { MessagingService, KpiBannerService, ApplicationContextService } from 'src/app/services';

@Component({
  selector: 'app-vendor-time-chart',
  templateUrl: './vendor-time-chart.component.html',
  styleUrls: ['./vendor-time-chart.component.css']
})
export class VendorTimeChartComponent implements OnInit, OnDestroy {
  @ViewChild('canvas', { static: false }) canvas: ElementRef;
  @BlockUI('container-blockui-vendor-time') blockUI: NgBlockUI;

  maxValue: number;
  chartData = false;
  barChartData: ChartDataSets[];
  barChartLabels: Label[] = ['Series A', 'Series B', 'Series C'];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartOptions: ChartOptions;
  filterModel = new KpiFilterModel();

  subscriptions = new Array<Subscription>();

  constructor(private kpiBannerService: KpiBannerService,
    private messagingService: MessagingService,
    private applicationContextService: ApplicationContextService) {
      const storeSubscription = this.applicationContextService.store$.subscribe((storeSelectListItem: SelectListModel) => {
        if (storeSelectListItem) {
          this.filterModel.storeId = storeSelectListItem.id;
        } else {
          this.filterModel.storeId = null;
        }
        this.loadData();
      });
      this.subscriptions.push(storeSubscription);
     }



  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  private loadData() {
    this.blockUI.start();
    forkJoin([
      this.kpiBannerService.getVendorTimeForCurrentMonth(this.filterModel),
      this.kpiBannerService.getVendorTimeForLastOneMonth(this.filterModel),
      this.kpiBannerService.getVendorTimeForLastTwoMonth(this.filterModel),
    ]).subscribe((result) => {
      this.barChartData = [{
        data: [result[2].totalVendorTime, result[1].totalVendorTime, result[0].totalVendorTime], label: '',
        backgroundColor: ['#e5444d', '#eb6066', '#ef9296'],
        borderColor: ['#e5444d', '#eb6066', '#ef9296'],
      }];

      const greatestValue = Math.round(Math.max(result[2].totalVendorTime, result[1].totalVendorTime, result[0].totalVendorTime) + 10);
      const calculatedMaxValue = Math.ceil((greatestValue + 1) / 10) * 10;
      this.maxValue = Math.max(calculatedMaxValue, 50);


      this.barChartOptions = {
        responsive: false,
        maintainAspectRatio: true,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              offsetGridLines: false
            }
          }],
          yAxes: [{
            ticks: {
              stepSize: 10,
              beginAtZero: true,
              min: 0,
              max: this.maxValue
            }
          }]
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
          }
        },
      };
      this.chartData = true;
      this.blockUI.stop();
    }, error => {
      this.messagingService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }
}
