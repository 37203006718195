import { Component, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { forkJoin } from "rxjs";
import { Category } from "src/app/enums";
import { RejectedVehicleWorkDetailModel, VehicleQueueModel, VehicleQueueStageModel, VehicleWorkDetailModel, VendorActionModel } from "src/app/models";
import { MessagingService, VehicleQueueService, OperationCodeService, PriceMatrixService } from "src/app/services";
import { VendorInvoiceComponent } from "../vendor-invoice/vendor.invoice.component";
import { VendorItemRejectionComponent } from "../vendor-item-rejection/vendor.item.rejection.component";

@Component({
    selector: 'app-vendor-action',
    templateUrl: './vendor-action.component.html',
    styleUrls: ['./vendor-action.component.css']
})
export class VendorActionComponent implements OnInit {
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild('vendorItemRejectionComponent', { static: false }) vendorItemRejectionComponent: VendorItemRejectionComponent;
    @ViewChild('vendorInvoiceComponent', { static: false }) vendorInvoiceComponent: VendorInvoiceComponent;


    form: FormGroup;
    submitted = false;

    private storeId: number;
    private vehicleQueueId: number;
    private vehicleQueueStageId: number;

    vehicleQueueModel: VehicleQueueModel = new VehicleQueueModel();
    vehicleWorkDetailModels: Array<VehicleWorkDetailModel> = new Array<VehicleWorkDetailModel>();
    vehicleQueueStageModel: VehicleQueueStageModel = new VehicleQueueStageModel();
    vendorActionModel: VendorActionModel = new VendorActionModel();
    rejectedVehicleWorkDetailModel: RejectedVehicleWorkDetailModel = new RejectedVehicleWorkDetailModel();

    constructor(private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private messagingService: MessagingService,
        private vehicleQueueService: VehicleQueueService,
        private operationCodeService: OperationCodeService,
        private priceMatrixService: PriceMatrixService
    ) {
        this.activatedRoute.params.subscribe((data) => {
            this.storeId = Number(data.storeId);
            this.vehicleQueueId = Number(data.vehicleQueueId);
            this.vehicleQueueStageId = Number(data.vehicleQueueStageId);
        });
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            selectAll: [],
            vehicleWorkDetailModels: this.formBuilder.array([]),
        });

        this.loadData();
    }

    private loadData() {
        this.blockUI.start();

        forkJoin([
            this.vehicleQueueService.get(this.vehicleQueueId),
            this.vehicleQueueService.getVendorAction(this.vehicleQueueId, this.vehicleQueueStageId)
        ]).subscribe(([
            vehicleQueue,
            vehicleWorkDetailModels
        ]) => {
            if (!vehicleQueue) {
                this.messagingService.ProcessErrorResponse(null, "This vehicle is not belongs to the selected store");
                return;
            }

            if (vehicleQueue.storeId != this.storeId) {
                this.messagingService.ProcessErrorResponse(null, `This vehicle(VehicleQueueId ${vehicleQueue.id}| Store(${vehicleQueue.storeId})) is not belongs to the selected store(${this.storeId})`);
                return;
            }

            this.vehicleQueueModel = vehicleQueue;
            this.vehicleQueueStageModel = this.vehicleQueueModel.vehicleQueueStages.find(x => x.id == this.vehicleQueueStageId);
            this.vehicleWorkDetailModels = vehicleWorkDetailModels;

            vehicleWorkDetailModels.forEach((model: VehicleWorkDetailModel) => {

                let assessorComment = model.commentModels.find(x => x.category == Category.Assessment);
                let fb = this.formBuilder.group({
                    id: model.id,
                    operationCodeDetailModel: model.operationCodeDetailModel,
                    cost: model.cost,
                    partPrice: model.partPrice,
                    laborPrice: model.laborPrice,
                    totalPrice: model.totalPrice,

                    addedOn: model.addedOn,
                    addedByUser: model.addedByUser,
                    assessorComment: assessorComment != null ? assessorComment.comment : null,
                    isRejected: [false],
                    rejectedComment: [''],
                    newCost: [0]
                });

                (this.form.controls.vehicleWorkDetailModels as FormArray).push(fb);
            });
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    onPause() {

    }

    onSubmit() {
        (this.form.controls.vehicleWorkDetailModels as FormArray).controls.forEach((elem, index) => {
            let fb = elem as FormGroup;
            //let model = this.vehicleWorkDetailModels.find(x => x.id == fb.controls.id.value);
            if (fb.controls.isRejected.value == true) {
                let rejectedModel = new RejectedVehicleWorkDetailModel();
                rejectedModel.id = Number(fb.controls.id.value);
                rejectedModel.rejectedComment = fb.controls.rejectedComment.value;
                rejectedModel.newCost = Number(fb.controls.newCost.value);
                this.vendorActionModel.rejectedItems.push(rejectedModel);
            }
        });

        //If all items are accepted then ask for invoice number
        if (this.vendorActionModel.rejectedItems.length == 0) {
            this.vendorInvoiceComponent.openModal(this.vendorActionModel.invoiceNumber);
        } else {
            setTimeout(() => {
                this.router.navigate(['/']);
            }, 10)
            //this.saveVendorActions();
        }
    }

    onInvoiceSubmit($event: any) {
        this.vendorActionModel.invoiceNumber = $event.invoiceNumber;
        this.saveVendorActions();
    }

    saveVendorActions() {
        this.blockUI.start();

        this.vendorActionModel.vehicleQueueId = this.vehicleQueueId;
        this.vendorActionModel.vehicleQueueStageId = this.vehicleQueueStageId;

        this.vehicleQueueService.saveVendorActions(this.vendorActionModel).subscribe(() => {

        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    onReject(id: number) {
        let model = this.vehicleWorkDetailModels.find(x => x.id == id);
        (this.form.controls.vehicleWorkDetailModels as FormArray).controls.forEach((elem, index) => {
            let fb = elem as FormGroup;
            if (fb.controls.id.value == model.id) {
                this.vendorItemRejectionComponent.openModal(model, fb.controls.rejectedComment.value, fb.controls.newCost.value);
            }
        });

    }

    rejectItem($event: any) {
        (this.form.controls.vehicleWorkDetailModels as FormArray).controls.forEach((elem, index) => {
            let fb = elem as FormGroup;
            if (fb.controls.id.value == $event.id) {
                fb.controls.isRejected.setValue(true);
                fb.controls.rejectedComment.setValue($event.comment);
                fb.controls.newCost.setValue($event.cost);
            }
        });
        this.rejectedVehicleWorkDetailModel.id = $event.id;
        this.rejectedVehicleWorkDetailModel.newCost = Number.parseFloat($event.cost.toString());
        this.rejectedVehicleWorkDetailModel.rejectedComment = $event.comment;

        this.vendorActionModel.rejectedItems = new Array<RejectedVehicleWorkDetailModel>();
        this.vendorActionModel.rejectedItems.push(this.rejectedVehicleWorkDetailModel);
        this.vendorActionModel.vehicleQueueId = this.vehicleQueueModel.id;
        this.vendorActionModel.vehicleQueueStageId = this.vehicleQueueStageModel.id;

        this.vehicleQueueService.rejectQueueStage(this.vendorActionModel).subscribe(() => {
            this.blockUI.start();

            this.form = this.formBuilder.group({
                selectAll: [],
                vehicleWorkDetailModels: this.formBuilder.array([]),
            });
            this.loadData();

            this.vendorItemRejectionComponent.onCancel();
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        });
    }
}