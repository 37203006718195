var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApprovalStatus, Category } from 'src/app/enums';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { ApprovalModel, ApprovalQuotationModel, ApprovalVehicleWorkDetailModel, VehicleQueueModel, VehicleQueueStageModel } from 'src/app/models';
import { MessagingService, VehicleQueueService, QuotationService, ApprovalService } from 'src/app/services';
export class ApprovalComponent {
    constructor(formBuilder, activatedRoute, router, quotationService, messagingService, vehicleQueueService, stringLengthConstants, approvalService, appUtils) {
        this.formBuilder = formBuilder;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.quotationService = quotationService;
        this.messagingService = messagingService;
        this.vehicleQueueService = vehicleQueueService;
        this.stringLengthConstants = stringLengthConstants;
        this.approvalService = approvalService;
        this.appUtils = appUtils;
        this.ApprovalStatus = ApprovalStatus;
        this.vehicleQueueModel = new VehicleQueueModel();
        this.vehicleQueueStageModel = new VehicleQueueStageModel();
        this.activatedRoute.params.subscribe((data) => {
            this.vehicleQueueId = Number(data.vehicleQueueId);
            this.vehicleQueueStageId = Number(data.vehicleQueueStageId);
        });
    }
    ngOnInit() {
        this.form = this.formBuilder.group({
            quotes: this.formBuilder.array([]),
            mechanicalAdvisingLaborPrice: 0,
            mechanicalAdvisingPartPrice: 0,
            mechanicalAdvisingTotalPrice: 0,
            mechanicalAdvisingItems: this.formBuilder.array([]),
            mechanicalAdvisingApprovalStatus: null,
            addedCosmeticsItems: this.formBuilder.array([]),
            addedCosmeticTotalPrice: 0,
            addedCosmeticApprovalStatus: null,
            totalPrice: 0
        });
        this.loadData();
        this.form.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
            //Calculated Added Cosmetic Total Price
            let addedCosmeticTotalPrice = 0;
            let addedCosmeticsItems = this.form.controls.addedCosmeticsItems;
            addedCosmeticsItems.controls.forEach((elem, index) => {
                let fb = elem;
                if (fb.controls.approvalStatus.value === ApprovalStatus.Accepted) {
                    addedCosmeticTotalPrice += fb.controls.totalPrice.value;
                }
            });
            this.form.controls.addedCosmeticTotalPrice.setValue(addedCosmeticTotalPrice);
            //Calculated Mechanical Advising Price
            let mechanicalAdvisingTotalPrice = 0;
            let mechanicalAdvisingLaborPrice = 0;
            let mechanicalAdvisingPartPrice = 0;
            let mechanicalAdvisingItems = this.form.controls.mechanicalAdvisingItems;
            mechanicalAdvisingItems.controls.forEach((elem, index) => {
                let fb = elem;
                if (fb.controls.approvalStatus.value === ApprovalStatus.Accepted) {
                    mechanicalAdvisingLaborPrice += fb.controls.laborPrice.value;
                    mechanicalAdvisingPartPrice += fb.controls.partsPrice.value;
                    mechanicalAdvisingTotalPrice += fb.controls.totalPrice.value;
                }
            });
            this.form.controls.mechanicalAdvisingLaborPrice.setValue(mechanicalAdvisingLaborPrice);
            this.form.controls.mechanicalAdvisingPartPrice.setValue(mechanicalAdvisingPartPrice);
            this.form.controls.mechanicalAdvisingTotalPrice.setValue(mechanicalAdvisingTotalPrice);
            //Calculate the selected quote price for each category
            let quotePrice = 0;
            let quotes = this.form.controls.quotes;
            quotes.controls.forEach((fb, index) => {
                let quote = fb;
                let vendorQuotations = quote.controls.vendorQuotations;
                vendorQuotations.controls.forEach((elem, index) => {
                    let vendorQuotation = elem;
                    if (vendorQuotation.controls.isSelected.value) {
                        quotePrice += vendorQuotation.controls.totalPrice.value;
                    }
                });
            });
            //Total Approved Price
            this.form.controls.totalPrice.setValue(quotePrice + mechanicalAdvisingTotalPrice + addedCosmeticTotalPrice);
        });
    }
    ngOnDestroy() {
    }
    getQuotes() {
        return this.form.controls.quotes.controls;
    }
    getMechanicalAdvisingItems() {
        return this.form.controls.mechanicalAdvisingItems.controls;
    }
    getAddedCosmeticsItems() {
        return this.form.controls.addedCosmeticsItems.controls;
    }
    loadData() {
        this.blockUI.start();
        forkJoin([
            this.vehicleQueueService.get(this.vehicleQueueId),
            this.quotationService.getSubmittedQuotations(this.vehicleQueueId),
            this.vehicleQueueService.getMechanicalAdvise(this.vehicleQueueId),
            this.vehicleQueueService.getAddedCosmetics(this.vehicleQueueId),
        ]).subscribe(([vehicleQueueModel, quotationModels, vehicleWorkDetails, addedCosmetics]) => {
            this.vehicleQueueModel = vehicleQueueModel;
            this.storeId = vehicleQueueModel.storeId;
            this.vehicleQueueStageModel = this.vehicleQueueModel.vehicleQueueStages.find(x => x.id == this.vehicleQueueStageId);
            //Create Quotation Component
            if (Array.isArray(quotationModels) && quotationModels.length > 0) {
                //Get Unique subCategoryIds
                const uniqueSubCategories = [...new Set(quotationModels.map(item => item.subCategoryId))];
                //Create an array of quote subcategories
                let faQuotes = this.form.controls.quotes;
                uniqueSubCategories.forEach(subCategoryId => {
                    let fb = this.formBuilder.group({
                        subCategoryId: subCategoryId,
                        subCategoryIdentifier: null,
                        categoryId: null,
                        categoryName: null,
                        subCategoryName: null,
                        vendorQuotations: this.formBuilder.array([]),
                    }, {
                        validator: [
                            (formGroup) => {
                                let vendorQuotations = formGroup.controls.vendorQuotations;
                                if (vendorQuotations.errors) {
                                    return;
                                }
                                if (vendorQuotations.controls.every(elem => elem.controls.isSelected.value == false)) {
                                    this.appUtils.addError(vendorQuotations, 'required');
                                }
                                else {
                                    this.appUtils.removeError(vendorQuotations, 'required');
                                }
                            }
                        ]
                    });
                    faQuotes.push(fb);
                });
                //Iterate each quotes and populate vendor quotation
                faQuotes.controls.forEach((elem, index) => {
                    let quote = elem;
                    //Get Quotation associated with subcategory to get its name 
                    let quotation = quotationModels.find(quotationModel => quote.controls.subCategoryId.value == quotationModel.subCategoryId);
                    if (quotation == null) {
                        return;
                    }
                    //Set Categories names
                    quote.controls.categoryId.setValue(quotation.categoryId);
                    quote.controls.categoryName.setValue(quotation.categoryName);
                    quote.controls.subCategoryName.setValue(quotation.subCategoryName);
                    quote.controls.subCategoryIdentifier.setValue(quotation.subCategoryName.replace(/ /g, ''));
                    quote.addControl(quotation.subCategoryName.replace(/ /g, ''), new FormControl(false));
                    let vendorQuotations = quote.controls.vendorQuotations;
                    quotationModels.forEach(quotationModel => {
                        if (quote.controls.subCategoryId.value != quotationModel.subCategoryId) {
                            return;
                        }
                        let quotationTotalPrice = 0;
                        let vendorQuotation = this.formBuilder.group({
                            id: quotationModel.id,
                            vendorId: quotationModel.vendorId,
                            vendorName: quotationModel.vendorName,
                            totalPrice: 0,
                            isSelected: false,
                            quotationItems: this.formBuilder.array([])
                        });
                        quotationModel.quotationItems.forEach(quotationItem => {
                            quotationTotalPrice += quotationItem.price;
                            let quotationItemFb = this.formBuilder.group({
                                id: quotationItem.id,
                                operationCodeDetailModel: quotationItem.operationCodeDetailModel,
                                price: quotationItem.price,
                                comment: quotationItem.comment
                            });
                            vendorQuotation.controls.quotationItems.push(quotationItemFb);
                        });
                        vendorQuotation.controls.totalPrice.setValue(quotationTotalPrice);
                        vendorQuotations.push(vendorQuotation);
                    });
                });
            }
            if (Array.isArray(vehicleWorkDetails) && vehicleWorkDetails.length > 0) {
                let faMechanicalAdvisingItems = this.form.controls.mechanicalAdvisingItems;
                vehicleWorkDetails.forEach(vehicleWorkDetail => {
                    let commentModel = vehicleWorkDetail.commentModels.find(x => x.category == Category.MechanicalAdvising || x.category == Category.Mechanical);
                    let approverComment = vehicleWorkDetail.commentModels.find(x => x.category == Category.Approval);
                    let fb = this.formBuilder.group({
                        id: vehicleWorkDetail.id,
                        operationCodeDetailModel: vehicleWorkDetail.operationCodeDetailModel,
                        laborPrice: vehicleWorkDetail.laborPrice,
                        partsPrice: vehicleWorkDetail.partPrice,
                        totalPrice: vehicleWorkDetail.totalPrice,
                        comment: commentModel != null ? commentModel.comment : null,
                        approvalStatus: [null, [Validators.required]],
                        approverComment: [approverComment != null ? approverComment.comment : null, [Validators.maxLength(this.stringLengthConstants.Default)]]
                    }, {
                        validator: [
                            (formGroup) => {
                                const approvalStatus = formGroup.controls.approvalStatus;
                                if (approvalStatus.errors) {
                                    return;
                                }
                                const approverComment = formGroup.controls.approverComment;
                                if (approvalStatus.value == ApprovalStatus.Question
                                    && (approverComment.value == null || approverComment.value.length == 0)) {
                                    this.appUtils.addError(approverComment, 'required');
                                }
                                else {
                                    this.appUtils.removeError(approverComment, 'required');
                                }
                            }
                        ]
                    });
                    if (vehicleWorkDetail.approvalStatus != ApprovalStatus.None) {
                        let approvalStatusControl = fb.controls.approvalStatus;
                        approvalStatusControl.setValue(vehicleWorkDetail.approvalStatus);
                        if (vehicleWorkDetail.approvalStatus == ApprovalStatus.Accepted) {
                            approvalStatusControl.disable();
                        }
                    }
                    faMechanicalAdvisingItems.push(fb);
                });
                this.onMechanicalAdvisingItemChange();
            }
            if (Array.isArray(addedCosmetics) && addedCosmetics.length > 0) {
                let faAddedCosmeticsItems = this.form.controls.addedCosmeticsItems;
                addedCosmetics.forEach(vehicleWorkDetail => {
                    let commentModel = vehicleWorkDetail.commentModels.find(x => x.category == Category.Assessment);
                    let approverComment = vehicleWorkDetail.commentModels.find(x => x.category == Category.Approval);
                    let fb = this.formBuilder.group({
                        id: vehicleWorkDetail.id,
                        operationCodeDetailModel: vehicleWorkDetail.operationCodeDetailModel,
                        totalPrice: vehicleWorkDetail.totalPrice,
                        comment: commentModel != null ? commentModel.comment : null,
                        approvalStatus: [null, [Validators.required]],
                        approverComment: [approverComment != null ? approverComment.comment : null, [Validators.maxLength(this.stringLengthConstants.Default)]]
                    });
                    if (vehicleWorkDetail.approvalStatus != ApprovalStatus.None) {
                        let approvalStatusControl = fb.controls.approvalStatus;
                        approvalStatusControl.setValue(vehicleWorkDetail.approvalStatus);
                        if (vehicleWorkDetail.approvalStatus == ApprovalStatus.Accepted) {
                            approvalStatusControl.disable();
                        }
                    }
                    faAddedCosmeticsItems.push(fb);
                });
                this.onAddedCosmeticItemChange();
            }
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    onApprovalChange(quote, vendorQuotationId) {
        //Identify the approved quotation
        let vendorQuotations = quote.controls.vendorQuotations;
        vendorQuotations.controls.forEach((elem, index) => {
            let fb = elem;
            fb.controls.isSelected.setValue(fb.controls.id.value == vendorQuotationId);
        });
    }
    onMechanicalAdvisingHeaderChange(approvalStatus) {
        this.form.controls.mechanicalAdvisingItems.controls.forEach((elem, index) => {
            let fb = elem;
            if (!fb.controls.approvalStatus.disabled) {
                fb.controls.approvalStatus.setValue(approvalStatus);
                fb.controls.approvalStatus.updateValueAndValidity();
            }
        });
    }
    onMechanicalAdvisingItemChange() {
        let mechanicalAdvisingItems = this.form.controls.mechanicalAdvisingItems;
        if (mechanicalAdvisingItems.controls.every(elem => elem.controls.approvalStatus.value == ApprovalStatus.Accepted)) {
            this.form.controls.mechanicalAdvisingApprovalStatus.setValue(ApprovalStatus.Accepted);
            return;
        }
        if (mechanicalAdvisingItems.controls.every(elem => elem.controls.approvalStatus.value == ApprovalStatus.Rejected)) {
            this.form.controls.mechanicalAdvisingApprovalStatus.setValue(ApprovalStatus.Rejected);
            return;
        }
        if (mechanicalAdvisingItems.controls.every(elem => elem.controls.approvalStatus.value == ApprovalStatus.Question)) {
            this.form.controls.mechanicalAdvisingApprovalStatus.setValue(ApprovalStatus.Question);
            return;
        }
        this.form.controls.mechanicalAdvisingApprovalStatus.setValue(null);
    }
    onAddedCosmeticHeaderChange(approvalStatus) {
        this.form.controls.addedCosmeticsItems.controls.forEach((elem, index) => {
            let fb = elem;
            if (!fb.controls.approvalStatus.disabled) {
                fb.controls.approvalStatus.setValue(approvalStatus);
                fb.controls.approvalStatus.updateValueAndValidity();
            }
        });
    }
    onAddedCosmeticItemChange() {
        let addedCosmeticsItems = this.form.controls.addedCosmeticsItems;
        if (addedCosmeticsItems.controls.every(elem => elem.controls.approvalStatus.value == ApprovalStatus.Accepted)) {
            this.form.controls.addedCosmeticApprovalStatus.setValue(ApprovalStatus.Accepted);
            return;
        }
        if (addedCosmeticsItems.controls.every(elem => elem.controls.approvalStatus.value == ApprovalStatus.Rejected)) {
            this.form.controls.addedCosmeticApprovalStatus.setValue(ApprovalStatus.Rejected);
            return;
        }
        this.form.controls.addedCosmeticApprovalStatus.setValue(null);
    }
    onSubmit() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        let approvalModel = new ApprovalModel();
        approvalModel.vehicleQueueId = this.vehicleQueueId;
        approvalModel.vehicleQueueStageId = this.vehicleQueueStageId;
        //Calculated Added Cosmetic Total Price
        this.form.controls.addedCosmeticsItems.controls.forEach((elem, index) => {
            let fb = elem;
            let model = new ApprovalVehicleWorkDetailModel();
            model.id = Number(fb.controls.id.value);
            model.approvalStatus = Number(fb.controls.approvalStatus.value);
            model.comment = fb.controls.approverComment.value;
            approvalModel.addedCosmeticWorkModels.push(model);
        });
        //Mechanical Advising Price
        this.form.controls.mechanicalAdvisingItems.controls.forEach((elem, index) => {
            let fb = elem;
            let model = new ApprovalVehicleWorkDetailModel();
            model.id = Number(fb.controls.id.value);
            model.approvalStatus = Number(fb.controls.approvalStatus.value);
            model.comment = fb.controls.approverComment.value;
            approvalModel.mechnicalAdvisingWorkModels.push(model);
        });
        //Quote price for each category
        this.form.controls.quotes.controls.forEach((fb, index) => {
            let quote = fb;
            let vendorQuotations = quote.controls.vendorQuotations;
            vendorQuotations.controls.forEach((elem, index) => {
                let vendorQuotation = elem;
                if (vendorQuotation.controls.isSelected.value) {
                    let model = new ApprovalQuotationModel();
                    model.categoryId = Number(quote.controls.categoryId.value);
                    model.subCategoryId = Number(quote.controls.subCategoryId.value);
                    model.quotationId = Number(vendorQuotation.controls.id.value);
                    approvalModel.approvalQuotations.push(model);
                }
            });
        });
        this.blockUI.start();
        this.approvalService.submitApproval(approvalModel)
            .subscribe(() => {
            setTimeout(() => {
                this.router.navigate(['/']);
            }, 10);
            setTimeout(() => {
                this.messagingService.success('Approval submitted successfully.');
            }, 300);
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
}
__decorate([
    BlockUI('container-blockui'),
    __metadata("design:type", Object)
], ApprovalComponent.prototype, "blockUI", void 0);
