import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { VehicleVideoType } from 'src/app/enums';
import * as RecordRTC from 'recordrtc';

declare var MediaRecorder: any;

@Component({
    selector: 'app-video-recorder',
    templateUrl: './video-recorder.component.html',
    styleUrls: ['./video-recorder.component.css']
})
export class VideoRecorderComponent implements OnInit, OnDestroy {
    VidoeIcon: string = "/assets/images/vidoe-icon.png";
    @BlockUI('container-blockui-vehicle-note') blockUI: NgBlockUI;
    @Output() emitRecordedData = new EventEmitter<any>();
    @Input() uploadTypes: Array<VehicleVideoType>;
    @ViewChild('recordModal', { static: false }) recordModal: ElementRef;
    VehicleVideoType = VehicleVideoType;
    video: any;
    selectedCameraOption: any;
    selectedUploadTypeOption: any;
    devices: Array<MediaDeviceInfo> = new Array<MediaDeviceInfo>();

    modalReference: NgbModalRef;
    private isStarted: boolean = false;
    private isStartRecording: boolean = false;
    private isRecorded: boolean = false;
    private streamData: any;
    private recordedBlobs: any;
    private mediaRecorder: any;
    private videoConstraints = {
        audio: {
            echoCancellation: { exact: true }
        },
        video: {
            width: { exact: 640 },
            height: { exact: 390 },
            deviceId: {
                exact: null
            }
        },
    };

    constructor(
        private modalService: NgbModal,
    ) {
    }

    ngOnInit(): void {
        this.selectedUploadTypeOption = this.uploadTypes[0];
    }

    ngOnDestroy(): void {
        this.onCancel();
    }

    openModal() {
        this.isStarted = this.isStartRecording = false;
        this.modalReference = this.modalService.open(this.recordModal, {
            centered: true,
            scrollable: true,
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });
        this.isRecorded = false;
        this.getCameraOptions();
    }

    getCameraOptions() {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
            this.devices = devices.filter(x => x.kind === "videoinput");
            this.selectedCameraOption = this.devices[0].deviceId;
        });
    }

    onCancel() {
        if (!this.modalReference) {
            return;
        }

        this.onStop();
        setTimeout(() => {
            if (this.recordedBlobs && this.recordedBlobs.size > 0) {
                this.emitRecordedData.emit({
                    blobData: this.recordedBlobs,
                    vehicleVideoType: this.selectedUploadTypeOption
                });
            }
        }, 300);

        this.modalReference.close();
    }

    onStart(videoTag: any) {
        this.isRecorded = false;
        this.video = videoTag;
        this.video.muted = true;
        this.video.volume = 0;
        this.initRecorder();
    }

    // onPlayRecording(videoTag: any) {
    //     if (this.recordedBlobs.length == 0) {
    //         return;
    //     }

    //     videoTag.srcObject = null;
    //     videoTag.src = window.URL.createObjectURL(this.recordedBlobs);
    //     videoTag.controls = true;
    //     videoTag.play();
    // }

    recorder: any;
    onStartRecording() {
        this.isStartRecording = true;
        this.recorder.startRecording();
    }

    onStopRecording() {
        this.isStartRecording = false;
        if (!this.recorder) {
            return;
        }

        this.recorder.stopRecording(() => {
            this.recordedBlobs = this.recorder.getBlob();
            this.isRecorded = true;
        });
    }

    onStop() {
        this.isStarted = false;
        this.streamData = null;
        this.onStopRecording();

        if (this.video == undefined)
            return;

        const stream = this.video.srcObject;
        this.video.srcObject = null;

        if (stream == null)
            return;
        const tracks = stream.getTracks();
        tracks.forEach(function (track) {
            track.stop();
        });

    }

    cameraSelection() {
        this.onStop();
        this.initRecorder();
    }

    initRecorder() {
        this.recordedBlobs = null;
        this.videoConstraints.video.deviceId.exact = this.selectedCameraOption;

        // Some browsers partially implement mediaDevices. We can't just assign an object
        // with getUserMedia as it would overwrite existing properties.
        // Here, we will just add the getUserMedia property if it's missing.
        if (navigator.mediaDevices.getUserMedia === undefined) {
            navigator.mediaDevices.getUserMedia = function (constraints) {

                // First get ahold of the legacy getUserMedia, if present
                var getUserMedia = navigator["webkitGetUserMedia"] || navigator["mozGetUserMedia"];

                // Some browsers just don't implement it - return a rejected promise with an error
                // to keep a consistent interface
                if (!getUserMedia) {
                    return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
                }

                // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
                return new Promise(function (resolve, reject) {
                    getUserMedia.call(navigator, constraints, resolve, reject);
                });
            }
        }

        navigator.mediaDevices.getUserMedia(this.videoConstraints).then((stream) => {
            if (!this.selectedCameraOption)
                this.getCameraOptions();

            setTimeout(() => {
                this.isStarted = true;
                this.streamData = this.video.srcObject = stream;
                this.video.play();
                this.recorder = RecordRTC(this.streamData, {
                    type: 'video',
                    mimeType: 'video/mp4\;codecs=vp8,opus',
                });
            }, 300);
        }, error => {
            console.error('navigator.getUserMedia error:', error);
        });
    }
}