<button type="button" class="btn-fav" (click)="openModal()" *ngIf="recordingFileName && isFavIcon">
  <em class="fa fa-play"></em>
</button>
<div class="playbtn" *ngIf="recordingFileName && !isFavIcon">
  <a href="javascript:void(0);" (click)="openModal()">Play Video <img [src]="playImg" alt="Play" /></a>
</div>
<ng-template #playVideoModal let-modal>
  <div class="modal-body commentPopupWrp" *blockUI="'container-blockui'">
    <div>
      <video #videoTag controls controlsList="nodownload"></video>
    </div>
    <div class="buttonWrp">
      <button type="button" class="btn btn-tsrp" (click)="onPlayRecording(videoTag)">Play</button>
      <!-- <a [href]="videoTag.src" title="{{videoTag.src}}" download="{{videoTag.src}}" target="_blank">
        <em class="fa fa-picture-o"></em>
      </a> -->
      <button type="button" class="btn btn-tsrp" (click)="onCancel()">Close</button>
    </div>
  </div>
</ng-template>
