import { ApplicationRoleType } from "src/app/enums";

export class AuthenticateResponseModel {
    public id: string;
    public name: string;
    public roles: Array<AuthenticateRoleModel>;
    public companies: Array<number>;
    public stores: Array<number>;
    public vendors: Array<number>;
    public permissions: Array<number>;
    public jwt: string;
    public refreshToken: string;
    public applicationRoleType: ApplicationRoleType;

    constructor() {
        this.roles = new Array<AuthenticateRoleModel>();
        this.companies = new Array<number>();
        this.stores = new Array<number>();
        this.vendors = new Array<number>();
        this.permissions = new Array<number>();
    }
}

export class AuthenticateRoleModel {
    public name: string;
    public permissions: Array<number>;

    constructor() {
        this.permissions = new Array<number>()
    }
}