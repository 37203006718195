import { VendorDocumentModel } from "..";

export class VendorDetailModel{
    public id:number;
    public name:string;
    public streetAddress:string;
    public blockAddress:string;
    public city:string;
    public state:string;
    public country:string;
    public phoneCode:string;
    public phone:string;
    public zipCode:string;
    public taxId:string;
    public insurancePolicy:string;
    public insuranceProvider:string;
    public insuranceExpireDate:Date;
    public invoiceEmail:string;
    public invoicingPreference:number;
    public isSMSNotificationActive: boolean;
    public isEmailNotificationActive: boolean;
    public storeIds:number[];
    public storeNames: Array<string>;

    public categoryIds:number[];
    public categoryNames: Array<string>;

    public subCategoryIds:number[];
    public subCategoryNames: Array<string>;
    
    public isActive:boolean;
    public documents: Array<VendorDocumentModel>;

    constructor() {
        this.documents = new Array<VendorDocumentModel>();
        this.storeNames = new Array<string>();
    }
}
