/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./store.delete.confirmation.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng-block-ui";
import * as i3 from "./store.delete.confirmation.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
var styles_StoreDeleteConfirmationComponent = [i0.styles];
var RenderType_StoreDeleteConfirmationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StoreDeleteConfirmationComponent, data: {} });
export { RenderType_StoreDeleteConfirmationComponent as RenderType_StoreDeleteConfirmationComponent };
function View_StoreDeleteConfirmationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "confirmPopup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirm Delete"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "P", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "buttonWrp"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-red m-r-5"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onConfirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-tsrp"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 6, 0, currVal_0); }); }
function View_StoreDeleteConfirmationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_StoreDeleteConfirmationComponent_2)), i1.ɵdid(1, 212992, null, 0, i2.ɵf, [i2.BlockUIService, i2.ɵc, i1.ViewContainerRef, i1.TemplateRef, i1.Renderer2, i1.ComponentFactoryResolver], { blockUI: [0, "blockUI"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = "container-blockui-confirmation"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_StoreDeleteConfirmationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { confirmationModal: 0 }), (_l()(), i1.ɵand(0, [[1, 2], ["storeConfirmationModal", 2]], null, 0, null, View_StoreDeleteConfirmationComponent_1))], null, null); }
export function View_StoreDeleteConfirmationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-store-delete-confirmation", [], null, null, null, View_StoreDeleteConfirmationComponent_0, RenderType_StoreDeleteConfirmationComponent)), i1.ɵdid(1, 180224, null, 0, i3.StoreDeleteConfirmationComponent, [i4.NgbModal], null, null)], null, null); }
var StoreDeleteConfirmationComponentNgFactory = i1.ɵccf("app-store-delete-confirmation", i3.StoreDeleteConfirmationComponent, View_StoreDeleteConfirmationComponent_Host_0, {}, { delete: "delete" }, []);
export { StoreDeleteConfirmationComponentNgFactory as StoreDeleteConfirmationComponentNgFactory };
