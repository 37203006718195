import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { VehicleQueueNoteModel, VehicleQueueNoteAddModel } from "../models";

@Injectable({
    providedIn: 'root'
})

export class VehicleQueueNoteService {
    constructor(private http: HttpClient) {

    }

    getNotes(vehicelQueueId: number): Observable<Array<VehicleQueueNoteModel>> {
        return this.http.get<Array<VehicleQueueNoteModel>>(`${environment.apiBaseUrl}/vehicle-queue-note/notes/${vehicelQueueId}`);
    }

    add(model: VehicleQueueNoteAddModel) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle-queue-note`, model);
    }
}