<ng-template #confirmationModal let-modal>
  <div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="confirmPopup">
        <div class="form-group">
          <table border="1">
            <thead>
              <tr>
                <th>Vendor</th>
                <th>Status</th>
                <th>Amount ($)</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let quotation of quotations">
                <td>{{quotation.vendorName}}</td>
                <td>{{getStatus(quotation.status)}}</td>
                <td>{{getQuoteAmount(quotation)| currency : 'USD' : '' : '1.2-2'}}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </form>
  </div>
</ng-template>
