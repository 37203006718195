import { PricingType, VehicleLayoutType } from "src/app/enums";

export class OperationCodeEditModel {
    public id: number;
    public name: string;
    public description: string;
    public categoryId: number;
    public subCategoryId: Nullable<number>;
    public carLayoutPanelId: Nullable<number>;
    public carLayoutSideId: Nullable<number>;
    public carLayoutLocationId: Nullable<number>;
    public isLocationSpecific: boolean;
    public isTiedToIVL: boolean;
    public vehicleLayoutType: VehicleLayoutType;
}
