import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Category } from "../enums";
import { DataTableResponseModel, OperationCodeAddModel, OperationCodeDetailModel, OperationCodeEditModel, OperationCodeSelectListModel } from "../models";


@Injectable({
    providedIn: 'root'
})

export class OperationCodeService {
    constructor(private http: HttpClient) {
    }

    public add(model: OperationCodeAddModel): Observable<OperationCodeDetailModel> {
        return this.http.post<OperationCodeDetailModel>(`${environment.apiBaseUrl}/operation-code/add`, model);
    }

    public edit(model: OperationCodeEditModel): Observable<OperationCodeDetailModel> {
        return this.http.patch<OperationCodeDetailModel>(`${environment.apiBaseUrl}/operation-code/edit`, model);
    }

    public get(id: number): Observable<OperationCodeDetailModel> {
        return this.http.get<OperationCodeDetailModel>(`${environment.apiBaseUrl}/operation-code/${id}`);
    }

    public delete(id: number) {
        return this.http.delete(`${environment.apiBaseUrl}/operation-code/${id}`);
    }

    public toggleStatus(id: number) {
        return this.http.patch(`${environment.apiBaseUrl}/operation-code/toggle-status/${id}`, {});
    }

    public query(dataTablesParameters: any) {
        return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/operation-code/query`, dataTablesParameters, {});
    }

    public selectListByCategoryId(id: number): Observable<Array<OperationCodeSelectListModel>> {
        return this.http.get<Array<OperationCodeSelectListModel>>(`${environment.apiBaseUrl}/category/${id}/operation-code/select-list`);
    }

    public quoteSelectList(): Observable<Array<OperationCodeSelectListModel>> {
        return this.http.get<Array<OperationCodeSelectListModel>>(`${environment.apiBaseUrl}/category/quote/operation-code/select-list`);
    }

    public getSelectListByCategory(category: Category): Observable<Array<OperationCodeSelectListModel>> {
        return this.http.get<Array<OperationCodeSelectListModel>>(`${environment.apiBaseUrl}/category/operation-code/select-list?categoryName=${category}`);
    }

    public selectListBySubCategoryId(id: number): Observable<Array<OperationCodeSelectListModel>> {
        return this.http.get<Array<OperationCodeSelectListModel>>(`${environment.apiBaseUrl}/operation-code/sub-category/${id}/select-list`);
    }

    public getAllOperationCodes(): Observable<Array<OperationCodeSelectListModel>> {
        return this.http.get<Array<OperationCodeSelectListModel>>(`${environment.apiBaseUrl}/operation-code/select-list`);
    }

    public getReconPackageOperationCodes(storeId: number, workflowId: number): Observable<Array<OperationCodeSelectListModel>> {
        return this.http.get<Array<OperationCodeSelectListModel>>(`${environment.apiBaseUrl}/operation-code/recon-package/store/${storeId}/workflow/${workflowId}/select-list`);
    }
}