import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AccountRecoverModel } from 'src/app/models';
import { MessagingService, AuthenticationService } from 'src/app/services';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {
  @BlockUI('container-blockui') blockUI: NgBlockUI;
  logo: string = "assets/images/recon-partners-logo.png";

  frm: FormGroup;
  submitted: boolean = false;
  formSubmitted: boolean = false;
  model: AccountRecoverModel = new AccountRecoverModel();

  constructor(
    private router: Router,
    private messagingService: MessagingService,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.frm = this.formBuilder.group({
      email: ['', [Validators.required]],
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.frm.invalid) {
      return;
    }

    this.model.email = this.frm.controls.email.value;
    this.blockUI.start();
    this.authenticationService.recover(this.model)
      .subscribe(
        () => {
          this.blockUI.stop();

          //Reset
          this.submitted = false;
          this.formSubmitted = true;
          this.frm.reset();          
          this.model = new AccountRecoverModel();

          // setTimeout(() => {
          //   this.router.navigate(['/account/login']);
          // }, 100);
          // setTimeout(() => {
          //   this.messagingService.success(`A temporary verification code and link has been sent to your registered email. Please click on the link
          //    to proceed with the verification process`);
          // }, 500);
        },
        error => {
          this.blockUI.stop();
          this.messagingService.ProcessErrorResponse(error);
        });
  }
}
