import { ApplicationRoleType, NotificationType } from "src/app/enums";
import { SelectListModel, UserStoreAssociationListModel } from "..";

export class UserDetailModel {
    public id: string;
    public userId: number;
    public userName: string;
    public isActive: boolean;
    public email: string;
    public emailConfirmed:boolean;
    public name: string;
    public phoneCode: string;
    public phoneNumber: string;
    public applicationRoleType: ApplicationRoleType;
    public isSMSNotificationActive: boolean;
    public isEmailNotificationActive: boolean;
    public roles: Array<string>;

    public userStoreAssociations: Array<UserStoreAssociationListModel>;
    public userCompanyAssociations: Array<SelectListModel>;
    public userVendorAssociations: Array<SelectListModel>;

    constructor() {
        this.roles = new Array<string>();
        this.userStoreAssociations = new Array<UserStoreAssociationListModel>();
        this.userCompanyAssociations = new Array<SelectListModel>();
        this.userVendorAssociations = new Array<SelectListModel>();
    }
}

