<section class="gnrlformSec" *blockUI="'container-blockui'">
	<div class="container">
		<div class="row">
            <div class="col-12">
                <a [routerLink]="['/manage/user']" class="backtolist"><em class="fa fa-long-arrow-left"
                        aria-hidden="true"></em> Back to list</a>
            </div>
        </div>
		<div class="row">
			<div class="col-12 col-md-12 col-lg-8 col-xl-8 offset-xl-2 offset-lg-2">
				<div class="gnrlformbox">
					<h2>Edit User Profile</h2>
					<div class="gnrlformDtl">
						<form [formGroup]="form" (ngSubmit)="updateUser()">
							<input type="hidden" formControlName="id" />
							<ul>
								<li>
									<div class="profilelabel"><label>Name</label></div>
									<div class="profileValue">
										<div class="form-group">
											<input type="text" class="form-control" placeholder="Name"
												formControlName="name"
												[ngClass]="{ 'is-invalid': submitted && form.controls.name.errors }" />
											<div *ngIf="submitted && form.controls.name.errors"
												class="invalid-feedback">
												<div *ngIf="form.controls.name.errors.required">Name is
													required</div>
												<div *ngIf="form.controls.name.errors.maxlength">
													Name should be max
													{{form.controls.name.errors.maxlength.requiredLength}} characters
												</div>
											</div>
										</div>
									</div>
								</li>
								<li>
									<div class="profilelabel"><label>Email Address</label></div>
									<div class="profileValue">
										<div class="form-group">
											<input type="Email" class="form-control" placeholder="Email Address"
												formControlName="email"
												[ngClass]="{ 'is-invalid': submitted && form.controls.email.errors }" />
											<div *ngIf="submitted && form.controls.email.errors"
												class="invalid-feedback">
												<div *ngIf="form.controls.email.errors.required">Email Address is
													required</div>
												<div *ngIf="form.controls.email.errors.email">Email Address should
													be valid</div>
												<div *ngIf="form.controls.email.errors.maxlength">
													Email Address should be max
													{{form.controls.email.errors.maxlength.requiredLength}}
													characters</div>
											</div>
										</div>
									</div>
								</li>
								<li>
									<div class="profilelabel"><label>Phone Number</label></div>
									<div class="profileValue">
										<div class="row">
										<div class="col-4">
										<select class="form-control hDropDown" formControlName = "phoneCode"
                                    	[ngClass]="{'is-invalid':submitted && form.controls.phoneCode.errors}">
                                        <option *ngFor="let item of countries " [value]="item.phoneCode">{{item.phoneCode}} </option>
                                    	</select>
										</div>
										<div class="col-8">
											<input #phoneNumber type="text" class="form-control"
												[ngClass]="{ 'is-invalid': submitted && form.controls.phoneNumber.errors }"
												placeholder="Phone Number" formControlName="phoneNumber" />
											<div *ngIf="submitted && form.controls.phoneNumber.errors"
												class="invalid-feedback">
												<div *ngIf="form.controls.phoneNumber.errors.required">Phone Number
													is required</div>
												<div *ngIf="form.controls.phoneNumber.errors.pattern">Phone Number
													should be valid format i.e. XXXXXXXXXX</div>
												<div *ngIf="form.controls.phoneNumber.errors.maxlength">
													Phone Number should be max
													{{form.controls.phoneNumber.errors.maxlength.requiredLength}}
													characters
												</div>
											</div>
										</div>
										</div>
									</div>
								</li>
								<li>
									<div class="profilelabel"><label>Notification</label></div>
									<div class="profileValue">
										<div class="form-group">
											<span>
												<input class="form-check-input" id="chkNotificationTypeEmail"
													type="checkbox" formControlName="isEmailNotificationActive">
												<label class="form-check-label" for="chkNotificationTypeEmail">
													Email
												</label>
											</span>
											<span>
												<input class="form-check-input" id="chkNotificationTypeSMS"
													type="checkbox" formControlName="isSMSNotificationActive">
												<label class="form-check-label" for="chkNotificationTypeSMS">
													SMS
												</label>
											</span>
										</div>
									</div>
								</li>

								<li>
									<div class="profilelabel"><label>Role Type</label></div>
									<div class="profileValue">
										<div class="form-group">
											<select class="form-control" formControlName="applicationRoleType"
												(change)="applyApplicationRoleTypeFilter()"
												[ngClass]="{ 'is-invalid': submitted && form.controls.applicationRoleType.errors }">
												<option *ngFor="let item of roleTypeFilterOptions" [value]="item.id">
													{{item.name}}
												</option>
											</select>
											<div *ngIf="submitted && form.controls.applicationRoleType.errors"
												class="invalid-feedback">
												<div *ngIf="form.controls.applicationRoleType.errors.required">Role Type
													is required</div>
											</div>
										</div>
									</div>
								</li>

								<li *ngIf="selectedApplicationRoleType == ApplicationRoleType.Partner">
									<div class="profilelabel"><label>Managing Company</label></div>
									<div class="profileValue">
										<div class="form-group">
											<select class="form-control hDropDown" formControlName="company"
												placeholder="Managing Company" (change)="applyApplicationRoleTypeFilter()"
												[ngClass]="{ 'is-invalid': submitted && form.controls.company.errors }">
												<option value="">Select Managing Company</option>
												<option *ngFor="let item of companies" [value]="item.id">
													{{item.name}}
												</option>
											</select>
											<div *ngIf="submitted && form.controls.company.errors"
												class="invalid-feedback">
												<div *ngIf="form.controls.company.errors.required">
													Managing Company is required
												</div>
											</div>
										</div>
									</div>
								</li>
								<li *ngIf="selectedApplicationRoleType == ApplicationRoleType.Vendor">
									<div class="profilelabel"><label>Vendor</label></div>
									<div class="profileValue">
										<div class="form-group">
											<select class="form-control hDropDown" formControlName="vendor"
												placeholder="Vendor" (change)="applyApplicationRoleTypeFilter()"
												[ngClass]="{ 'is-invalid': submitted && form.controls.vendor.errors }">
												<option value="">Select Vendor</option>
												<option *ngFor="let item of vendors" [value]="item.id">
													{{item.name}}
												</option>
											</select>
											<div *ngIf="submitted && form.controls.vendor.errors"
												class="invalid-feedback">
												<div *ngIf="form.controls.vendor.errors.required">Vendor
													is required</div>
											</div>
										</div>
									</div>
								</li>
								<li *ngIf="selectedApplicationRoleType == ApplicationRoleType.Store">
									<div class="profilelabel">
										<label>Auto Group Name</label>
									</div>
									<div class="profileValue">
										<div class="form-group">
											<select class="form-control hDropDown" formControlName="autoGroupName"
												placeholder="Auto Group Name" (change)="autoGroupSelectFilter()"
												[ngClass]="{ 'is-invalid': submitted && form.controls.autoGroupName.errors }">
												<option value="">Select Auto Group Name</option>
												<option *ngFor="let item of autoGroupNames" [value]="item">
													{{item}}
												</option>
											</select>
											<div *ngIf="submitted && form.controls.autoGroupName.errors"
												class="invalid-feedback">
												<div *ngIf="form.controls.autoGroupName.errors.required">Auto Group Name
													is required</div>
											</div>
										</div>
									</div>
								</li>
								<li *ngIf="selectedApplicationRoleType == ApplicationRoleType.Store">
									<div class="profilelabel"><label>Store</label></div>
									<div class="profileValue">
										<div class="form-group">
											<ng-multiselect-dropdown [placeholder]="'Select Store'" [data]="stores"
												[ngModelOptions]="{standalone: true}" [(ngModel)]="selectedStores"
												[settings]="storeDropdownSettings"
												[ngClass]="{ 'is-invalid': submitted && selectedStores.length == 0 }">
											</ng-multiselect-dropdown>
											<div *ngIf="submitted && selectedStores.length == 0"
												class="invalid-feedback">
												Store is required
											</div>
										</div>
									</div>
								</li>
								<li>
									<div class="profilelabel"><label>Roles</label></div>
									<div class="profileValue">
										<div class="form-group">
											<ng-multiselect-dropdown [placeholder]="'Select Roles'" [data]="roles"
												[ngModelOptions]="{standalone: true}" [(ngModel)]="selectedRoles"
												[settings]="roleDropdownSettings"
												[ngClass]="{ 'is-invalid': submitted && selectedRoles.length == 0 }">
											</ng-multiselect-dropdown>
											<div *ngIf="submitted && selectedRoles.length == 0"
												class="invalid-feedback">
												Role is required
											</div>
										</div>
									</div>
								</li>								

								<div class="btnWrp">
									<button type="submit" class="btn btn-red">Save</button>
									<button class="btn btn-tsrp" [routerLink]="['/manage/user']">Cancel</button>
								</div>
							</ul>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>