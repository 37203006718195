var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EventEmitter, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI } from 'ng-block-ui';
import { WorkflowImportModel } from 'src/app/models';
import { ApplicationContextService, CheckInTypeService, MessagingService } from 'src/app/services';
export class WorkflowImportComponent {
    constructor(modalService, formBuilder, checkInTypeService, messagingService, applicationContextService) {
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.checkInTypeService = checkInTypeService;
        this.messagingService = messagingService;
        this.applicationContextService = applicationContextService;
        this.imported = new EventEmitter();
        this.checkInTypeSelectList = new Array();
        this.storeName = null;
    }
    ngOnChanges(changes) {
    }
    init() {
        this.form = this.formBuilder.group({
            storeId: [null, Validators.required],
            checkInTypeId: [null, Validators.required]
        });
        this.storeName = null;
    }
    openModal() {
        this.init();
        this.storeSubscription = this.applicationContextService.store$.subscribe((storeSelectListItem) => {
            if (storeSelectListItem) {
                this.form.controls.storeId.setValue(storeSelectListItem.id);
                this.onStoreChange(storeSelectListItem.id);
            }
            else {
                this.form.controls.storeId.setValue(null);
            }
        });
        this.modalReference = this.modalService.open(this.importModal, { size: 'lg', centered: true });
    }
    onStoreChange(storeId) {
        if (!storeId) {
            return;
        }
        storeId = Number(storeId);
        this.blockUI.start();
        this.checkInTypeService.getCheckInTypeExcludingExistingWorkflowsforStore(storeId).subscribe((data) => {
            this.checkInTypeSelectList = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    onSubmit() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        let model = new WorkflowImportModel();
        model.checkInTypeId = Number(this.form.controls.checkInTypeId.value);
        model.storeId = Number(this.form.controls.storeId.value);
        model.workflowId = this.checkInTypeSelectList.find(x => x.checkinTypeId == model.checkInTypeId).workflowId;
        setTimeout(() => {
            this.imported.emit(model);
        });
        this.onCancel();
        this.form.reset();
        this.submitted = false;
    }
    onCancel() {
        if (!this.modalReference) {
            return;
        }
        this.modalReference.close();
    }
    ngOnDestroy() {
        this.onCancel();
        this.storeSubscription && this.storeSubscription.unsubscribe();
    }
}
__decorate([
    BlockUI('container-blockui-import'),
    __metadata("design:type", Object)
], WorkflowImportComponent.prototype, "blockUI", void 0);
