import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class QuotationService {
    constructor(http) {
        this.http = http;
    }
    getQuotation(id) {
        return this.http.get(`${environment.apiBaseUrl}/quotation/${id}`);
    }
    quotationNoBid(id, storeId, vehicleQueueId) {
        return this.http.patch(`${environment.apiBaseUrl}/quotation/${id}/no-bid`, {});
    }
    addQuotation(storeId, model) {
        return this.http.post(`${environment.apiBaseUrl}/quotation`, model);
    }
    startQuotation(quotationId, storeId, vehicleQueueId) {
        return this.http.post(`${environment.apiBaseUrl}/quotation/${quotationId}/start`, {});
    }
    pauseQuotation(quotationId) {
        return this.http.post(`${environment.apiBaseUrl}/quotation/${quotationId}/pause`, {});
    }
    cancelQuotation(quotationId) {
        return this.http.post(`${environment.apiBaseUrl}/quotation/${quotationId}/cancel`, {});
    }
    getSubmittedQuotations(vehicleQueueId) {
        return this.http.get(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/quotations`);
    }
    getVehicleQueueStageQuotations(vehicleQueueStageId) {
        return this.http.get(`${environment.apiBaseUrl}/vehicle/queue/stage/${vehicleQueueStageId}/quotations`);
    }
}
QuotationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QuotationService_Factory() { return new QuotationService(i0.ɵɵinject(i1.HttpClient)); }, token: QuotationService, providedIn: "root" });
