import { HttpClient } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { DataTableResponseModel, OperationCodeSelectListModel, WorkflowModel, WorkflowSelectListModel } from "../models";
import * as uuid from 'uuid';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class WorkflowService {
    constructor(http) {
        this.http = http;
    }
    add(model) {
        return this.http.post(`${environment.apiBaseUrl}/workflow/add`, model);
    }
    edit(model) {
        return this.http.put(`${environment.apiBaseUrl}/workflow/edit`, model);
    }
    get(id) {
        return this.http.get(`${environment.apiBaseUrl}/workflow/${id}`).pipe(tap((model) => {
            model.uniqueIdentifer = uuid.v4();
            model.workflowDynamicSequences.forEach(workflowDynamicSequence => {
                workflowDynamicSequence.uniqueIdentifer = uuid.v4();
                workflowDynamicSequence.workflowStages.forEach(workflowStage => {
                    workflowStage.uniqueIdentifer = uuid.v4();
                    workflowStage.workflowStageCategories.forEach(x => {
                        x.uniqueIdentifer = uuid.v4();
                        x.operationCodes.forEach(y => {
                            y.uniqueIdentifer = uuid.v4();
                        });
                    });
                });
            });
        }), catchError(error => {
            throw error;
        }));
    }
    toggleStatus(id) {
        return this.http.patch(`${environment.apiBaseUrl}/workflow/toggle-status/${id}`, null);
    }
    delete(id) {
        return this.http.delete(`${environment.apiBaseUrl}/workflow/${id}`);
    }
    query(dataTablesParameters) {
        return this.http.post(`${environment.apiBaseUrl}/workflow/query`, dataTablesParameters);
    }
    selectList() {
        return this.http.get(`${environment.apiBaseUrl}/workflow/select-list`);
    }
    getStoreSelectList(storeId) {
        return this.http.get(`${environment.apiBaseUrl}/workflow/store/${storeId}/select-list`);
    }
    getMandatoryOperationCode(workflowId, categoryName) {
        return this.http.get(`${environment.apiBaseUrl}/workflow/${workflowId}/mandatory-operation-code?categoryName=${categoryName}`);
    }
    storeHasWholesaleWorkflow(storeId) {
        return this.http.get(`${environment.apiBaseUrl}/workflow/store/${storeId}/has-wholesale`);
    }
}
WorkflowService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WorkflowService_Factory() { return new WorkflowService(i0.ɵɵinject(i1.HttpClient)); }, token: WorkflowService, providedIn: "root" });
