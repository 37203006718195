import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SelectListModel } from "src/app/models";

@Component({
    selector: 'app-vendor-quote',
    templateUrl: './vendor.quote.component.html',
    styleUrls: ['./vendor.quote.component.css']
})

export class VendorQuoteComponent implements OnInit, OnDestroy {
    @Input() vendors: Array<SelectListModel>;
    @ViewChild('confirmationModal', { static: false }) confirmationModal: any;
    @Output() select = new EventEmitter<any>();

    modalReference: NgbModalRef;

    form: FormGroup;
    submitted: boolean = false;
    action: string;

    constructor(
        private formBuilder: FormBuilder,
        private modalService: NgbModal
    ) {

    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            vendorId: ['', Validators.required]
        });
    }

    openModal(action: string) {
        this.action = action;
        this.modalReference = this.modalService.open(this.confirmationModal, { centered: true, scrollable: true });
    }

    onSubmit() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }

        this.select.emit({
            action: this.action,
            vendor: this.vendors.find(x => x.id == this.form.controls.vendorId.value)
        });
        this.onCancel();
    }

    onCancel() {
        if (!this.modalReference) {
            return;
        }
        this.modalReference.close();
    }

    ngOnDestroy(): void {
        this.onCancel();
    }
}