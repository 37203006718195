import { ApplicationRoleType } from "src/app/enums";
import { SelectListModel } from "../generic/select.list.model";

export class RoleDetailModel {
	public id: string;
	public name: string;
	public applicationRoleType: ApplicationRoleType;
	public permissions: Array<number>;
	public categoryAssociations: Array<SelectListModel>;
	constructor() {
		this.permissions = new Array<number>();
		this.categoryAssociations = new Array<SelectListModel>();
	}
}
