<section class="datatableSer">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <h1>Manage Role</h1>
      </div>
      <div class="col-12 col-md-6 text-center text-md-right">
        <!-- <button type="button" (click)="openRoleAddModal()"  class="btn btn-red">Add Role</button> -->
        <button type="button" [routerLink]="['/role/add']" class="btn btn-red">Add Role</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="tableWrp">
          <div class="filterWrp">
            <form [formGroup]="frmSearch">
              <div class="row justify-content-end">
                <div class="col-12 col-md-9">
                  <div class="row">
                    <div class="col-12 col-sm-4">
                      <label>Role Name</label>
                      <input class="form-control" type="text" formControlName="roleName"
                        (keydown.enter)="applyFilter()" />
                    </div>
                    <div class="col-12 col-sm-4">
                      <label>Operation</label>
                      <select class="form-control" formControlName="filterOption">
                        <option *ngFor="let item of filterOptions" [value]="item.id">
                          {{item.name}}
                        </option>
                      </select>
                    </div>
                    <div class="col-12 col-sm-4">
                      <label>Status</label>
                      <select class="form-control" formControlName="statusOption">
                        <option *ngFor="let item of statusOptions" [value]="item.id">
                          {{item.name}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div *blockUI="'container-blockui-role-grid'" class="bluetableWrp table-responsive">
            <table datatable [dtOptions]="dtOptions" data-page="2" class="table-hover break-all table" summary="Roles">
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>

<app-role-delete-confirmation #deleteRole (delete)="emitDeleteConfirmation($event)"></app-role-delete-confirmation>