var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI } from 'ng-block-ui';
import { forkJoin, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { InvoicingPreference, InvoicingStructure } from 'src/app/enums';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { CountryModel, SelectListModel, StateModel, StoreDetailModel, StoreEditModel, SubCategorySelectListModel } from 'src/app/models';
import { ApplicationContextService, CategoryService, CompanyService, CountryService, FilterStateService, MessagingService, SubCategoryService } from 'src/app/services';
import { StoreService } from 'src/app/services/store.service';
export class StoreEditComponent {
    constructor(formBuilder, messagingService, router, activeRoute, countryService, companyService, appUtils, storeService, categoryService, subCategoryService, stringLengthConstants, applicationContextService, filterStateService) {
        this.formBuilder = formBuilder;
        this.messagingService = messagingService;
        this.router = router;
        this.activeRoute = activeRoute;
        this.countryService = countryService;
        this.companyService = companyService;
        this.appUtils = appUtils;
        this.storeService = storeService;
        this.categoryService = categoryService;
        this.subCategoryService = subCategoryService;
        this.stringLengthConstants = stringLengthConstants;
        this.applicationContextService = applicationContextService;
        this.filterStateService = filterStateService;
        this.isCreateAnotherStore = false;
        this.InvoicingStructure = InvoicingStructure;
        this.InvoicingPreference = InvoicingPreference;
        //For dropdown
        this.countries = new Array();
        this.states = new Array();
        this.companies = new Array();
        this.categories = new Array();
        this.subCategories = new Array();
        this.autoGroups = new Array();
        //For store multi select 
        this.categoryDropdownSettings = {};
        this.subCategoryDropdownSettings = {};
        //For get/set mutli select dropdown value
        this.selectedCategories = new Array();
        this.selectedSubCategories = new Array();
        this.filteredSubCategories = new Array();
        this.focus$ = new Subject();
        this.click$ = new Subject();
        this.autoGroupSearch = (text$) => {
            const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
            const clicksWithClosedPopup$ = this.click$.pipe(filter(() => this.instance && !this.instance.isPopupOpen()));
            const inputFocus$ = this.focus$;
            return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(map((term) => term === ''
                ? this.autoGroups
                : this.autoGroups.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)));
        };
        this.model = new StoreEditModel();
        this.categories = new Array();
        this.subCategories = new Array();
        this.activeRoute.params.subscribe(p => this.model.id = parseInt(p.storeId));
        filterStateService.changeFilterModelStatues(FilterPageType.storeManage, true);
    }
    ngOnInit() {
        this.multiSelectDropdownSettings();
        this.resetForm();
        this.loadData();
    }
    resetForm() {
        this.submitted = false;
        this.frmStore = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            autoGroupName: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Default)]],
            streetAddress: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Default)]],
            blockAddress: [null, [Validators.maxLength(this.stringLengthConstants.Default)]],
            city: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Default)]],
            state: ['', [Validators.required]],
            country: ['', [Validators.required]],
            phoneCode: ['', [Validators.required]],
            phoneNumber: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.PhoneNumber), this.appUtils.validatePhoneNumber()]],
            zipCode: ['', [Validators.required, Validators.minLength(5),
                    Validators.maxLength(5), Validators.pattern(new RegExp('^[a-zA-Z0-9-]*$', 'g')),
                    Validators.maxLength(this.stringLengthConstants.PostalCode)]],
            vAutoStoreId: ['', [Validators.required]],
            invocingEmail: ['', [Validators.required, Validators.email,
                    Validators.maxLength(this.stringLengthConstants.Email)]],
            invoicingStructure: [null],
            invoicingPreference: [null],
            companyId: ['', [Validators.required]]
        });
        this.frmStore.controls.invoicingStructure.setValue(InvoicingStructure.Consolidate);
        this.frmStore.controls.invoicingPreference.setValue(InvoicingPreference.SingleInvoice);
    }
    loadData() {
        this.blockUI.start();
        forkJoin([
            this.storeService.get(this.model.id),
            this.countryService.getCountries(),
            this.companyService.getCompanies(),
            this.categoryService.selectList(),
            this.subCategoryService.getAllSubCategories(),
            this.storeService.allAutoGroupName(),
        ]).subscribe(([store, countries, companies, categories, subCategories, autogroups]) => {
            this.storeDetail = store;
            this.countries = countries;
            if (this.countries.length == 1) {
                this.loadStates(this.countries[0].id);
                this.frmStore.controls['country'].setValue(this.countries[0].shortName);
                this.frmStore.controls['phoneCode'].setValue(this.countries[0].phoneCode);
            }
            this.companies = companies;
            this.categories = categories;
            this.subCategories = subCategories;
            this.autoGroups = autogroups;
            this.filteredSubCategories = this.subCategories.filter(x => this.storeDetail.categoryIds.find(z => x.categoryId == z));
            this.initForm();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    loadStates(countryId) {
        this.blockUI.start();
        this.countryService.getStates(countryId).subscribe((data) => {
            this.states = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    initForm() {
        this.selectedCategories = this.categories.filter(x => this.storeDetail.categoryIds.includes(x.id));
        this.selectedSubCategories = this.subCategories.filter(x => this.storeDetail.subCategoryIds.includes(x.id));
        this.frmStore.controls.autoGroupName.setValue(this.storeDetail.autoGroupName);
        this.frmStore.controls.name.setValue(this.storeDetail.name);
        this.frmStore.controls.streetAddress.setValue(this.storeDetail.streetAddress);
        this.frmStore.controls.blockAddress.setValue(this.storeDetail.blockAddress);
        this.frmStore.controls.city.setValue(this.storeDetail.city);
        this.frmStore.controls.state.setValue(this.storeDetail.state);
        this.frmStore.controls.country.setValue(this.storeDetail.country);
        this.frmStore.controls.zipCode.setValue(this.storeDetail.zipCode);
        this.frmStore.controls.phoneCode.setValue(this.storeDetail.phoneCode);
        this.frmStore.controls.phoneNumber.setValue(this.storeDetail.phoneNumber);
        this.frmStore.controls.vAutoStoreId.setValue(this.storeDetail.vAutoId);
        this.frmStore.controls.invocingEmail.setValue(this.storeDetail.invocingEmail);
        this.frmStore.controls.companyId.setValue(this.storeDetail.companyId);
        this.frmStore.controls.invoicingStructure.setValue(this.storeDetail.invoicingStructure);
        this.frmStore.controls.invoicingPreference.setValue(this.storeDetail.invoicingPreference);
    }
    onSubmit() {
        this.submitted = true;
        if (this.frmStore.invalid
            || this.selectedCategories.length == 0) {
            return;
        }
        this.model.categoryIds = this.selectedCategories.map(x => x.id);
        this.model.subCategoryIds = this.selectedSubCategories.map(x => x.id);
        this.model.autoGroupName = this.frmStore.controls.autoGroupName.value;
        this.model.name = this.frmStore.controls.name.value;
        this.model.streetAddress = this.frmStore.controls.streetAddress.value;
        this.model.blockAddress = this.frmStore.controls.blockAddress.value;
        this.model.city = this.frmStore.controls.city.value;
        this.model.state = this.frmStore.controls.state.value;
        this.model.country = this.frmStore.controls.country.value;
        this.model.phoneCode = this.frmStore.controls.phoneCode.value;
        this.model.phoneNumber = this.frmStore.controls.phoneNumber.value;
        this.model.zipCode = this.frmStore.controls.zipCode.value;
        this.model.vAutoId = this.frmStore.controls.vAutoStoreId.value;
        this.model.invocingEmail = this.frmStore.controls.invocingEmail.value;
        this.model.companyId = parseInt(this.frmStore.controls.companyId.value);
        this.model.invoicingStructure = parseInt(this.frmStore.controls.invoicingStructure.value);
        this.model.invoicingPreference = parseInt(this.frmStore.controls.invoicingPreference.value);
        this.blockUI.start();
        this.storeService.update(this.model)
            .subscribe((data) => {
            setTimeout(() => {
                this.navigateToManageStore();
            }, 10);
            setTimeout(() => {
                this.messagingService.success('Store has been updated successfully.');
            }, 300);
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
        if (this.isCreateAnotherStore) {
            this.resetForm();
            this.isCreateAnotherStore = false;
        }
    }
    navigateToManageStore() {
        this.router.navigate(['manage', 'store']);
    }
    onCategorySelect() {
        let categoryIds = this.selectedCategories.map(x => x.id);
        if (categoryIds.length == 0) {
            this.onCategoryDeSelectAll();
            return;
        }
        this.filteredSubCategories = [...this.subCategories.filter(x => categoryIds.find(z => z == x.categoryId))];
        this.selectedSubCategories = this.selectedSubCategories.filter(x => this.filteredSubCategories.find(z => z.id == x.id));
    }
    onCategorySelectAll() {
        this.filteredSubCategories = [...this.subCategories];
    }
    onCategoryDeSelectAll() {
        this.selectedSubCategories = this.filteredSubCategories = new Array();
    }
    multiSelectDropdownSettings() {
        this.categoryDropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
        this.subCategoryDropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
    }
}
__decorate([
    BlockUI('container-blockui-store'),
    __metadata("design:type", Object)
], StoreEditComponent.prototype, "blockUI", void 0);
