import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CountryService {
    constructor(http) {
        this.http = http;
    }
    getCountries() {
        return this.http.get(`${environment.apiBaseUrl}/country`);
    }
    getStates(countryId) {
        return this.http.get(`${environment.apiBaseUrl}/country/${countryId}/states`);
    }
}
CountryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CountryService_Factory() { return new CountryService(i0.ɵɵinject(i1.HttpClient)); }, token: CountryService, providedIn: "root" });
