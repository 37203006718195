import { FilterStateModel } from '../models';
import * as i0 from "@angular/core";
export class FilterStateService {
    constructor() {
        this.filterStateModels = new Array();
    }
    getFilterModel(page) {
        const model = this.filterStateModels.find(x => x.page == page);
        if (model) {
            return model;
        }
        else {
            const newFilterModelState = new FilterStateModel();
            newFilterModelState.page = page;
            this.filterStateModels.push(newFilterModelState);
            return newFilterModelState;
        }
    }
    changeFilterModelStatues(page, isActive) {
        const selectedFilterModel = this.filterStateModels.find(x => x.page == page);
        selectedFilterModel.isActive = isActive;
    }
    removeFilterModel(page) {
        const selectedFilterModel = this.filterStateModels.find(x => x.page == page);
        const index = this.filterStateModels.indexOf(selectedFilterModel);
        this.filterStateModels.slice(index, 1);
    }
}
FilterStateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FilterStateService_Factory() { return new FilterStateService(); }, token: FilterStateService, providedIn: "root" });
