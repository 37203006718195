import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { CategoryDetailModel, SelectListModel, SubCategorySelectListModel, WorkflowModel } from 'src/app/models';
import { ApplicationContextService, CategoryService, CheckInTypeService, FilterStateService, MessagingService, StoreService, SubCategoryService, WorkflowService } from 'src/app/services';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';

@Component({
	selector: 'app-workflow-detail',
	templateUrl: './workflow.detail.component.html',
	styleUrls: ['./workflow.detail.component.css']
})
export class WorkflowDetailComponent implements OnInit {
	@BlockUI('container-blockui') blockUI: NgBlockUI;
	@ViewChild('deleteConfirmationModal', { static: false }) deleteConfirmationModal: any;
	@ViewChild('statusChangeConfirmationModal', { static: false }) statusChangeConfirmationModal: any;
	categories: Array<CategoryDetailModel> = new Array<CategoryDetailModel>();
	checkInTypeSelectList: Array<SelectListModel> = new Array<SelectListModel>();
	storeSelectList: Array<SelectListModel> = new Array<SelectListModel>();
	subCategories: Array<SubCategorySelectListModel> = new Array<SubCategorySelectListModel>();
	workflowModel: WorkflowModel;
	checkInTypeName: string;

	constructor(private router: Router,
		private route: ActivatedRoute,
		public location: Location,
		private messagingService: MessagingService,
		private checkInTypeService: CheckInTypeService,
		private categoryService: CategoryService,
		private workflowService: WorkflowService,
		private storeService: StoreService,
		private subCategoryService: SubCategoryService,
		private applicationContextService: ApplicationContextService,
		private filterStateService: FilterStateService
	) {
		filterStateService.changeFilterModelStatues(FilterPageType.workFlowManage, true);
		this.workflowModel = new WorkflowModel();

		this.route.params.subscribe(params => {
			this.workflowModel.id = params.id;
		});
	}

	ngOnInit() {
		this.loadData();
	}

	private loadData() {
		this.blockUI.start();
		forkJoin([
			this.categoryService.detailSelectList(),
			this.checkInTypeService.selectList(),
			this.workflowService.get(this.workflowModel.id),
			this.storeService.storeSelectList(),
			this.subCategoryService.getAllSubCategories()
		]).subscribe((result) => {
			this.categories = result[0];
			this.checkInTypeSelectList = result[1];
			this.workflowModel = result[2];
			this.storeSelectList = result[3];
			this.subCategories = result[4];
			
			let checkInType = this.checkInTypeSelectList.find(x => x.id == this.workflowModel.checkInTypeId);
			if (checkInType != null) {
				this.checkInTypeName = checkInType.name;
			}

			this.blockUI.stop();
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
			this.blockUI.stop();
		});
	}

	openDeleteConfirmation() {
		this.deleteConfirmationModal.openModal(this.workflowModel);
	}

	delete() {
		this.blockUI.start();
		this.workflowService.delete(this.workflowModel.id).subscribe(
			() => {
				setTimeout(() => {
					this.router.navigate(['/manage/workflow']);
				});
				this.messagingService.success(`Workflow has been deleted successfully.`);
			},
			error => {
				this.messagingService.ProcessErrorResponse(error);
			},
			() => {
				this.blockUI.stop();
			});
	}

	openStatusChangeConfirmation() {
		this.statusChangeConfirmationModal.openModal(this.workflowModel);
	}

	toggle() {
		this.blockUI.start();
		this.workflowService.toggleStatus(this.workflowModel.id).subscribe(
			() => {
				this.messagingService.success(`Workflow has been ${(this.workflowModel.isActive ? 'deactivated' : 'activated')} successfully.`);
				this.workflowModel.isActive = !this.workflowModel.isActive;
			},
			error => {
				this.messagingService.ProcessErrorResponse(error);
			},
			() => {
				this.blockUI.stop();
			});
	}

}