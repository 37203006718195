var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { BlockUI } from "ng-block-ui";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { FilterPageType } from "src/app/enums/filter.page.type.enum";
import { AppUtils } from "src/app/helpers";
import { SubCategoryDetailModel } from "src/app/models";
import { FilterStateService, MessagingService, SubCategoryService } from "src/app/services";
import { environment } from "src/environments/environment";
export class SubCategoryManageComponent {
    constructor(formBuilder, router, messagingService, subCategoryService, appUtils, filterStateService) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.messagingService = messagingService;
        this.subCategoryService = subCategoryService;
        this.appUtils = appUtils;
        this.filterStateService = filterStateService;
        this.dtOptions = {};
        this.rowIndex = 0;
        this.filterOptions = this.appUtils.getFilterOperationOptions();
        this.statusOptions = this.appUtils.getStatusOptions();
    }
    ngAfterViewInit() {
        this.updateFormFromFilterModel();
        this.datatableElement.dtInstance.then((dtInstance) => this.dtInstance = dtInstance);
    }
    updateFormFromFilterModel() {
        const tempFilterModel = this.filterStateService.getFilterModel(FilterPageType.toolManage);
        if (tempFilterModel && tempFilterModel.isActive) {
            this.frmSearch.controls.search.setValue(tempFilterModel.filterKey);
            this.frmSearch.controls.filterOption.setValue(tempFilterModel.filterOption);
            this.frmSearch.controls.statusOption.setValue(tempFilterModel.statusOption);
        }
    }
    updateFilterModel() {
        const filterModel = this.filterStateService.getFilterModel(FilterPageType.toolManage);
        filterModel.page = FilterPageType.toolManage;
        filterModel.filterKey = this.frmSearch.controls.search.value;
        filterModel.filterOption = this.frmSearch.controls.filterOption.value;
        filterModel.statusOption = this.frmSearch.controls.statusOption.value;
        filterModel.isActive = false;
    }
    ngOnDestroy() {
    }
    ngOnInit() {
        this.frmSearch = this.formBuilder.group({
            search: [''],
            filterOption: [1],
            statusOption: [2] //Active
        });
        this.initDataTable();
        this.frmSearch.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
            this.applyFilter();
        });
    }
    initDataTable() {
        const self = this;
        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter user groups...',
                paginate: {
                    first: '<em class="fa fa-angle-double-left">',
                    last: '<em class="fa fa-angle-double-right">',
                    previous: '<em class="fa fa-angle-left">',
                    next: '<em class="fa fa-angle-right">'
                }
            },
            search: { search: null },
            searching: false,
            displayStart: self.rowIndex,
            paging: false,
            pagingType: 'full_numbers',
            pageLength: environment.paging.defaultPageSize,
            lengthMenu: environment.paging.lengthMenu,
            order: [[0, 'asc']],
            stateSave: true,
            info: false,
            scrollY: (window.innerHeight - 70 - 45) + "px",
            scrollCollapse: true,
            ajax: (dataTablesParameters, callback) => {
                self.updateFilterModel();
                dataTablesParameters.customSearch = self.frmSearch.controls.search.value; //Changes done here//
                dataTablesParameters.filterBy = Number(self.frmSearch.controls.filterOption.value);
                dataTablesParameters.status = self.statusOptions.find(x => x.id == self.frmSearch.controls.statusOption.value).value;
                self.blockUI.start();
                self.subCategoryService.query(dataTablesParameters)
                    .subscribe(resp => {
                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: resp.data
                    });
                    self.blockUI.stop();
                }, error => {
                    self.messagingService.ProcessErrorResponse(error);
                    self.blockUI.stop();
                });
            },
            columns: [
                {
                    data: 'categoryName',
                    title: 'Category Name'
                },
                {
                    data: 'name',
                    title: 'Name',
                    render: function (data, type, row) {
                        return `<span class='link' action-type='link-detail'>${row.name}</a>`;
                    }
                },
                {
                    data: 'description',
                    title: 'Description'
                },
                {
                    data: 'code',
                    title: 'Code',
                    width: '8%'
                },
                {
                    data: 'sequence',
                    title: 'IVL Rank',
                    width: '8%'
                },
                {
                    data: 'isActive',
                    title: 'Status',
                    width: '8%',
                    render: function (isActive) {
                        return isActive
                            ? '<span class="badge badge-success">Active</span>'
                            : '<span class="badge badge-danger">Inactive</span>';
                    }
                },
                {
                    data: null,
                    title: 'Action',
                    width: '15%',
                    orderable: false,
                    className: 'text-center',
                    visible: true,
                    render: function (data, type, row) {
                        //Render Activate or Deactivate Button
                        let statusHtml = row.isActive ?
                            `<button title='Deactivate' action-type='toggle-status'><em class="fa fa-ban" aria-hidden="true"></em></button>` :
                            `<button title='Activate' action-type='toggle-status'><em class="fa fa-check" aria-hidden="true"></em></button>`;
                        return `
                        <button title='View Detail' action-type='view'><em class="fa fa-eye" aria-hidden="true"></em></button>
                        ${statusHtml}
                        <button title='Edit' action-type='edit'><em class="fa fa-edit" aria-hidden="true"></em></button>
                        <button title='Delete' action-type='delete'><em class="fa fa-trash" aria-hidden="true"></em></button>

                        `;
                    }
                }
            ],
            rowCallback: function (row, data) {
                const _row = $(row);
                _row.find('[action-type="view"],[action-type="link-detail"]')
                    .off('click')
                    .on('click', function () {
                    self.router.navigate(['sub-category', 'detail', data.id]);
                });
                _row.find('[action-type="edit"]')
                    .off('click')
                    .on('click', function () {
                    self.router.navigate(['edit', 'sub-category', data.id]);
                });
                _row.find('[action-type="delete"]')
                    .off('click')
                    .on('click', function () {
                    self.openCheckInTypeDeleteModal(data);
                });
                _row.find('[action-type="toggle-status"]')
                    .off('click')
                    .on('click', function () {
                    self.openCheckInTypeStatusModal(data);
                });
            }
        };
    }
    applyFilter() {
        this.dtInstance.ajax.reload();
    }
    openCheckInTypeDeleteModal(data) {
        this.deleteConfirmationModal.openModal(data);
    }
    delete(subCategory) {
        this.blockUI.start();
        this.subCategoryService.delete(subCategory.id).subscribe(() => {
            setTimeout(() => {
                this.dtInstance.ajax.reload(null, false);
            });
            this.messagingService.success(`Tool has been deleted successfully.`);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    openCheckInTypeStatusModal(data) {
        this.statusChangeConfirmationModal.openModal(data);
    }
    toggle(vendor) {
        this.blockUI.start();
        this.subCategoryService.toggleStatus(vendor.id).subscribe(() => {
            setTimeout(() => {
                this.dtInstance.ajax.reload(null, false);
            });
            this.messagingService.success(`Tool has been ${(vendor.isActive ? 'deactivated' : 'activated')} successfully.`);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
}
__decorate([
    BlockUI('container-blockui-vendor-grid'),
    __metadata("design:type", Object)
], SubCategoryManageComponent.prototype, "blockUI", void 0);
