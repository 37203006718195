import { VehicleQueueStatus } from "src/app/enums";

export class VehicleQueueCategoryModel {
    public categoryId: number;
    public categoryName: string;
    public categoryCode: string;
    public vehicleCount: number;
}

export class VehicleQueueStatusModel {
    public status: VehicleQueueStatus;
    public vehicleCount: number;
}

export class VehicleQueueFilterResponseModel {
    public vehicleQueueStatuses: Array<VehicleQueueStatusModel>;
    public vehicleQueueCategories: Array<VehicleQueueCategoryModel>;

    constructor(){
        this.vehicleQueueStatuses = new Array<VehicleQueueStatusModel>();
        this.vehicleQueueCategories = new Array<VehicleQueueCategoryModel>();
    }
}