<ng-template #roleConfirmationModal let-modal>
  <div *blockUI="'container-blockui-confirmation'">
      <div class="modal-body">
          <div class="confirmPopup">
              <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                  <span aria-hidden="true">&times;</span>
              </button>
              <h5 class="modal-title">Confirm Delete</h5>
              <P>{{message}}</P>
              <div class="buttonWrp text-center">
                  <button type="button" class="btn btn-red m-r-5" (click)="onConfirm()">Delete</button>
                  <button type="button" class="btn btn-trsp" (click)="closeModal()">Cancel</button>
              </div>
          </div>
      </div>
  </div>
</ng-template>