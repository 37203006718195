import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { InvoicingPreference, InvoicingStructure } from 'src/app/enums';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { AppUtils } from 'src/app/helpers';
import { StoreDetailModel, StoreListItemModel } from 'src/app/models';
import { ApplicationContextService, FilterStateService, MessagingService } from 'src/app/services';
import { StoreService } from 'src/app/services/store.service';

@Component({
    selector: 'app-store-detail',
    templateUrl: './store.detail.component.html',
    styleUrls: ['./store.detail.component.css']
})
export class StoreDetailComponent implements OnInit {
    @BlockUI('container-blockui-store') blockUI: NgBlockUI;
    @ViewChild('deleteStore', { static: false }) deleteStore: any;
    @ViewChild('statusChangeConfirmationModal', { static: true }) statusChangeConfirmationModal: any;

    storeId:number;
    model:StoreDetailModel;
    InvoicingStructure = InvoicingStructure;
    InvoicingPreference = InvoicingPreference;
    
    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        public appUtils: AppUtils,
        private messagingService: MessagingService,
        private storeService: StoreService,
        private applicationContextService: ApplicationContextService,
        private filterStateService: FilterStateService
    ) {
        filterStateService.changeFilterModelStatues(FilterPageType.storeManage, true);
        this.activeRoute.params.subscribe(params => this.storeId = params.storeId);
        this.model = new StoreDetailModel();
    }
    
    ngOnInit() {
        this.loadStore();
    }


    loadStore() {
        this.blockUI.start();
        this.storeService.get(this.storeId).subscribe((data: any) => {
            this.model = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    goToEdit() {
        this.router.navigate(['edit', 'store', this.model.id]);
    }
    

    goToClone() {
        this.router.navigate(['clone', 'store', this.model.id]);
    }
    
    openStoreDeleteModal() {
        let data=new StoreListItemModel();
        data.id = this.model.id;
        this.deleteStore.openModal(data);
    }
    
    emitDeleteConfirmation(store: StoreListItemModel) {
        this.blockUI.start();
        this.storeService.delete(store.id)
        .subscribe(
            () => {
                setTimeout(() => {
                    this.navigateToManageStore();
                });
                this.messagingService.success(`Store has been deleted successfully.`);
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
        });
      }
    
      
    toggleStatus() {
        this.statusChangeConfirmationModal.openModal(this.model);
    }
    
    toggle(store: StoreListItemModel) {
        this.blockUI.start();
        this.storeService.toggleStatus(store.id).subscribe(
          () => {
            this.messagingService.success(`Store has been ${(store.isActive ? 'deactivated' : 'activated')} successfully.`);
            this.model.isActive = !this.model.isActive;
          },
          error => {
            this.messagingService.ProcessErrorResponse(error);
          },
          () => {
            this.blockUI.stop();
        });
      }
    
    navigateToManageStore(){
        this.router.navigate(['manage', 'store']);
    }
    
    getConCatStringFromStringArray(values: Array<string>) {
        if (!values)
            return;
        return values.join(", ");
    }
}
