import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { ToastrService } from "ngx-toastr";
import { FilterPageType } from "src/app/enums/filter.page.type.enum";
import { StringLengthConstants } from "src/app/helpers";
import { CategoryEditModel, CheckInTypeDetailModel, CheckInTypeEditModel } from "src/app/models";
import { CategoryService, CheckInTypeService, FilterStateService, MessagingService } from "src/app/services";


@Component({
    selector: 'app-edit-check-in-type',
    templateUrl: './edit.check-in-type.component.html',
    styleUrls: ['./edit.check-in-type.component.css']
})

export class CheckInTypeEditComponent implements OnInit {
    frmCheckInType: FormGroup;
    submitted: boolean = false;
    model: CheckInTypeEditModel;
    @BlockUI('blockui-container') blockUI: NgBlockUI;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private messagingService: MessagingService,
        private checkInTypeService: CheckInTypeService,
        private stringLengthConstants: StringLengthConstants,
        private filterStateService: FilterStateService
    ) {
        filterStateService.changeFilterModelStatues(FilterPageType.checkInTypeManage, true);
        this.model = new CheckInTypeEditModel();
        this.route.params.subscribe(p => this.model.id = Number(p.checkInTypeId));
    }

    ngOnInit() {
        this.frmCheckInType = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            description: ['', [Validators.maxLength(this.stringLengthConstants.Description)]]
        });

        this.loadCheckInType();
    }

    private loadCheckInType() {
        this.blockUI.start();
        this.checkInTypeService.get(this.model.id).subscribe((data: CheckInTypeDetailModel) => {
            this.model = data;
            this.initForm();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    private initForm() {
        this.frmCheckInType.controls.name.setValue(this.model.name);
        this.frmCheckInType.controls.description.setValue(this.model.description);
    }

    onSubmit() {
        this.submitted = true;
        if (this.frmCheckInType.invalid) {
            return;
        }

        // this.model.name = this.frmCategory.controls.name.value;
        this.model.description = this.frmCheckInType.controls.description.value;
        
        this.checkInTypeService.update(this.model)
            .subscribe((data: CheckInTypeEditModel) => {
                setTimeout(() => {
                    this.router.navigate(['/manage/check-in-type']);
                }, 10);
                setTimeout(() => {
                    this.toastr.success('Category has been updated successfully.');
                }, 300);
                this.blockUI.stop();
            }, error => {
                this.messagingService.ProcessErrorResponse(error);
                this.blockUI.stop();
            });
    }
}