import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { AppUtils } from 'src/app/helpers';
import { CompanyWithStoreModel, StoreListItemModel } from 'src/app/models';
import { ApplicationContextService, CompanyService, FilterStateService, MessagingService } from 'src/app/services';
import { StoreService } from 'src/app/services/store.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-store-manage',
	templateUrl: './store.manage.component.html',
	styleUrls: ['./store.manage.component.css']
})

export class StoreManageComponent implements OnInit, OnDestroy {

	frmSearch: FormGroup;
	filterOptions: any;
	statusOptions: any;
	entityName: string;

	//Data Table Options
	@ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
	dtOptions: DataTables.Settings = {};
	dtInstance: DataTables.Api;
	rowIndex: number = 0;

	@BlockUI('container-blockui-store-grid') blockUI: NgBlockUI;
	@ViewChild('deleteStore', { static: false }) deleteStore: any;
	@ViewChild('statusChangeConfirmationModal', { static: true }) statusChangeConfirmationModal: any;

	companySubscription: any;

	constructor(private formBuilder: FormBuilder,
		private router: Router,
		private messagingService: MessagingService,
		public appUtils: AppUtils,
		private applicationContextService: ApplicationContextService,
		private storeService: StoreService,
		private filterStateService: FilterStateService) {
		this.filterOptions = this.appUtils.getFilterOperationOptions();
		this.statusOptions = this.appUtils.getStatusOptions();
	}


	ngAfterViewInit() {

		this.updateFormFromFilterModel();

		this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);
	}

	updateFormFromFilterModel() {
		const tempFilterModel = this.filterStateService.getFilterModel(FilterPageType.storeManage);
		if (tempFilterModel && tempFilterModel.isActive) {
			this.frmSearch.controls.storeName.setValue(tempFilterModel.filterKey);
			this.frmSearch.controls.filterOption.setValue(tempFilterModel.filterOption);
			this.frmSearch.controls.statusOption.setValue(tempFilterModel.statusOption);
		}
	}

	updateFilterModel() {
		const filterModel = this.filterStateService.getFilterModel(FilterPageType.storeManage);
		filterModel.page = FilterPageType.storeManage;
		filterModel.filterKey = this.frmSearch.controls.storeName.value;
		filterModel.filterOption = this.frmSearch.controls.filterOption.value;
		filterModel.statusOption = this.frmSearch.controls.statusOption.value;
		filterModel.isActive = false;
	}

	ngOnDestroy(): void {
		this.companySubscription.unsubscribe();
	}

	ngOnInit() {
		this.frmSearch = this.formBuilder.group({
			companyId: [null],
			storeName: [''],
			filterOption: [1], //Contains
			statusOption: [2] //Active
		});
		this.initDataTable();

		this.companySubscription = this.applicationContextService.company$.subscribe((company: CompanyWithStoreModel) => {
			if (company == null) {
				this.frmSearch.controls.companyId.setValue(null);
			} else {
				this.frmSearch.controls.companyId.setValue(company.id);
			}
		});

		this.frmSearch.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
			this.applyFilter();
		});
	}

	applyFilter() {
		this.dtInstance.ajax.reload();
	}

	initDataTable() {
		//Data Table Example.
		const self = this;
		this.dtOptions = {
			dom: '<"top">rt<"bottom"lip><"clear">',
			serverSide: true,
			processing: true,
			language: {
				loadingRecords: '&nbsp;',
				processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
				searchPlaceholder: 'Filter...',
				paginate: {
					first: '<em class="fa fa-angle-double-left">',
					last: '<em class="fa fa-angle-double-right">',
					previous: '<em class="fa fa-angle-left">',
					next: '<em class="fa fa-angle-right">'
				}
			},
			search: { search: null },
			searching: false,
			displayStart: self.rowIndex,
			paging: false,
			pagingType: 'full_numbers',
			pageLength: environment.paging.defaultPageSize,
			lengthMenu: environment.paging.lengthMenu,
			order: [[2, 'asc']],
			stateSave: true,
			info: false,
			scrollY: (window.innerHeight - 70 - 45) + "px",
			scrollCollapse: true,
			ajax: (dataTablesParameters: any, callback) => {


				self.updateFilterModel();

				dataTablesParameters.companyId = self.frmSearch.controls.companyId.value;
				dataTablesParameters.name = self.frmSearch.controls.storeName.value;
				dataTablesParameters.filterBy = Number(self.frmSearch.controls.filterOption.value);
				dataTablesParameters.status = self.statusOptions.find(x => x.id == self.frmSearch.controls.statusOption.value).value;

				self.blockUI.start();
				self.storeService.query(dataTablesParameters)
					.subscribe(resp => {
						callback({
							recordsTotal: resp.recordsTotal,
							recordsFiltered: resp.recordsFiltered,
							data: resp.data
						});
						self.blockUI.stop();
					}, error => {
						self.messagingService.ProcessErrorResponse(error);
						self.blockUI.stop();
					});
			},
			columns: [
				{
					data: 'companyName',
					title: 'Managing Company'
				},
				{
					data: 'autoGroupName',
					title: 'Auto Group'
				},
				{
					data: 'name',
					title: 'Store Name',
					render: function (data, type, row) {
						return `<span class='link' action-type='link-detail'>${row.name}</a>`;
					}
				},
				{
					data: 'vAutoId',
					title: 'VAuto Id',
					width: '10%'
				},
				{
					data: 'streetAddress',
					title: 'Address',
					width: '15%'
				},
				{
					data: 'phoneNumber',
					title: 'Phone Number',
					width: '12%',
					render: function (data, type, row) {
						return `<span>${row.phoneCode} ${row.phoneNumber}</span>`;
					}
				},
				{
					data: 'city',
					title: 'City',
					width: '8%'
				},
				{
					data: 'state',
					title: 'State',
					width: '4%'
				},
				{
					data: 'isActive',
					title: 'Status',
					width: '5%',
					render: function (isActive) {
						return isActive
							? '<span class="badge badge-success">Active</span>'
							: '<span class="badge badge-danger">Inactive</span>';
					}
				},
				{
					data: null,
					title: 'Action',
					width: '15%',
					orderable: false,
					className: 'text-center',
					visible: true,
					render: function (data, type, row) {
						//Render Activate or Deactivate Button
						let statusHtml = row.isActive ?
							`<button title='Deactivate' action-type='toggle-status'><em class="fa fa-ban" aria-hidden="true"></em></button>` :
							`<button title='Activate' action-type='toggle-status'><em class="fa fa-check" aria-hidden="true"></em></button>`;


						return `
						<button title='View Detail' action-type='view'><em class="fa fa-eye" aria-hidden="true"></em></button>
                    	<button title='Clone' action-type='clone'><em class="fa fa-clone" aria-hidden="true"></em></button>
                    	${statusHtml}
                    	<button title='Edit' action-type='edit'><em class="fa fa-edit" aria-hidden="true"></em></button>
                    	<button title='Delete' action-type='delete'><em class="fa fa-trash" aria-hidden="true"></em></button>
               			
						`;
					}
				}
			],
			rowCallback: function (row, data: StoreListItemModel) {
				const _row = $(row);

				_row.find('[action-type="link-detail"],[action-type="view"]')
					.off('click')
					.on('click', function () {
						self.router.navigate(['store', 'detail', data.id]);
					});

				_row.find('[action-type="edit"]')
					.off('click')
					.on('click', function () {
						self.router.navigate(['edit', 'store', data.id]);
					});

				_row.find('[action-type="clone"]')
					.off('click')
					.on('click', function () {
						self.router.navigate(['clone', 'store', data.id]);
					});

				_row.find('[action-type="delete"]')
					.off('click')
					.on('click', function () {
						self.openStoreDeleteModal(data);
					});

				_row.find('[action-type="toggle-status"]')
					.off('click')
					.on('click', function () {
						self.openStoreStatusConfirmationModal(data);
					});
			}
		};
	}

	openStoreDeleteModal(data: StoreListItemModel) {
		this.deleteStore.openModal(data);
	}

	openStoreStatusConfirmationModal(data: StoreListItemModel) {
		this.statusChangeConfirmationModal.openModal(data);
	}

	emitDeleteConfirmation(store: StoreListItemModel) {
		this.blockUI.start();
		this.storeService.delete(store.id).subscribe(
			() => {
				setTimeout(() => {
					this.dtInstance.ajax.reload(null, false);
				});
				this.messagingService.success(`Store has been deleted successfully.`);
			},
			error => {
				this.messagingService.ProcessErrorResponse(error);
			},
			() => {
				this.blockUI.stop();
			});
	}

	toggle(store: StoreListItemModel) {
		this.blockUI.start();
		this.storeService.toggleStatus(store.id).subscribe(
			() => {
				setTimeout(() => {
					this.dtInstance.ajax.reload(null, false);
				});
				this.messagingService.success(`Store has been ${(store.isActive ? 'deactivated' : 'activated')} successfully.`);
			},
			error => {
				this.messagingService.ProcessErrorResponse(error);
			},
			() => {
				this.blockUI.stop();
			});
	}

	navigateAddStore() {
		this.router.navigate(['add', 'store']);
	}
}
