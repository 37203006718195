import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { VehicleImportModel } from "../models";

@Injectable({
    providedIn: 'root'
})

export class VehicleService {
    constructor(private http: HttpClient) {
    }

    public get(vin: string): Observable<VehicleImportModel> {
        return this.http.get<VehicleImportModel>(`${environment.apiBaseUrl}/vehicle/${vin}`);
    }

    public getImage(vehicleQueueId: number): Observable<string> {
        return this.http.get<string>(`${environment.apiBaseUrl}/vehicle/image/${vehicleQueueId}`);
    }
}