<div *blockUI="'container-blockui'">
  <section class="advisorgraphSec" *hasPermissions='[PermissionType.WorkflowStatusView]'>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-12">
          <div class="graphChart ">
            <app-turn-time-chart></app-turn-time-chart>
            <app-shop-time-chart
              *hasPermissions='[PermissionType.Master, PermissionType.ManagingCompanyAdmin, PermissionType.Approval]'>
            </app-shop-time-chart>
            <app-vendor-time-chart
              *hasPermissions='[PermissionType.Master, PermissionType.ManagingCompanyAdmin, PermissionType.Approval, PermissionType.VendorAdmin , PermissionType.Vendor]'>
            </app-vendor-time-chart>
            <app-quote-saving-chart *hasPermissions='[PermissionType.Master, PermissionType.ManagingCompanyAdmin]'>
            </app-quote-saving-chart>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="advisorSec">
    <div class="container-fluid">
      <div *hasPermissions='[PermissionType.WorkflowStatusView]' class="main-vehiclSec border-top"
        [ngClass]="isToggleClass ? 'collapsLeft' : ''">
        <div class="checkBox-sec">
          <div class="arrow-LfIcon">
            <a href="javascript:void(0)" (click)="hideSidebar()">
              <em class="fa fa-long-arrow-left" aria-hidden="true"></em></a>
          </div>
          <div class="checkBox">
            <ngb-accordion (panelChange)="onSideBarAccordionChange($event)"
              [activeIds]=" innerWidth >= 767 ? 'vehicle-filter, checkintype-0, checkintype-1, workflow-stage-status-filter' : 'vehicle-filter'">
              <ngb-panel id="vehicle-filter">
                <ng-template ngbPanelTitle>
                  <h2 class="checkBox-hdr">Vehicle Properties
                    <em class="float-right text-red"
                      [ngClass]="!openAccordionByIds['vehicle-filter']?'fa fa-plus':'fa fa-minus'"
                      aria-hidden="true"></em>
                  </h2>
                </ng-template>
                <ng-template ngbPanelContent>
                  <form [formGroup]="searchForm">
                    <div class="jchapmanWrp">
                      <div class="jchapmanSearchF">
                        <input type="text" placeholder="Search" class="form-control" formControlName="searchText" />
                        <button><em class="fa fa-search" aria-hidden="true"></em></button>
                      </div>
                      <ng-container formArrayName="searchByItems">
                        <div class="form-check"
                          *ngFor="let searchByItem of searchForm.get('searchByItems').controls; let i = index;"
                          [formGroupName]="i">
                          <input class="form-check-input" id="{{searchByItem.controls.key.value}}" type="checkbox"
                            formControlName="isSelected">
                          <label class="form-check-label" for="{{searchByItem.controls.key.value}}">
                            {{searchByItem.controls.label.value}}
                          </label>
                        </div>
                      </ng-container>
                    </div>
                  </form>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="checkintype-0">
                <ng-template ngbPanelTitle>
                  <h2 class="checkBox-hdr">
                    Check in Type
                    <em class="float-right text-red"
                      [ngClass]="!openAccordionByIds['checkintype-0']?'fa fa-plus':'fa fa-minus'"
                      aria-hidden="true"></em>
                  </h2>
                </ng-template>
                <ng-template ngbPanelContent>
                  <form [formGroup]="checkInTypeForm">
                    <ng-container formArrayName="checkInTypes">
                      <div class="form-check"
                        *ngFor="let checkInType of checkInTypeForm.get('checkInTypes').controls; let i = index;"
                        [formGroupName]="i">
                        <input class="form-check-input" id="ckhCheckInTypeIsSelected{{i}}" type="checkbox"
                          formControlName="isSelected" (change)="loadCategoriesWithVehichleCount()">
                        <label class="form-check-label" for="ckhCheckInTypeIsSelected{{i}}">
                          {{checkInType.controls.name.value}}
                        </label>
                      </div>
                    </ng-container>
                  </form>
                </ng-template>
              </ngb-panel>
              <!-- <ngb-panel id="workflow-stage-status-filter" >
              <ng-template ngbPanelTitle>
                <h2 class="checkBox-hdr">
                  Stage Status   
                  <em class="float-right text-red"
                    [ngClass]="!openAccordionByIds['workflow-stage-status-filter']?'fa fa-plus':'fa fa-minus'"
                    aria-hidden="true"></em>
                </h2>
              </ng-template>
              <ng-template ngbPanelContent>
                <form [formGroup]="workflowStageStatusForm">
                  <ng-container formArrayName="workflowStageStatuses">
                    <div class="form-check"
                      *ngFor="let workflowStageStatus of workflowStageStatusForm.get('workflowStageStatuses').controls; let i = index;"
                      [formGroupName]="i">
                      <input class="form-check-input" id="ckhWorkflowStageStatusIsSelected{{i}}" type="checkbox"
                        formControlName="isSelected" (change)="updateCategoriesWithVehichleCountAndQueue()">
                      <label class="form-check-label" for="ckhWorkflowStageStatusIsSelected{{i}}">
                        <span>{{workflowStageStatus.controls.count.value}}</span>
                        {{workflowStageStatus.controls.name.value}}
                      </label>
                    </div>
                  </ng-container>
                </form>
              </ng-template>
            </ngb-panel> -->

              <ngb-panel id="checkintype-1">
                <ng-template ngbPanelTitle>
                  <h2 class="checkBox-hdr">
                    Workflow
                    <em class="float-right text-red"
                      [ngClass]="!openAccordionByIds['checkintype-1']?'fa fa-plus':'fa fa-minus'"
                      aria-hidden="true"></em>
                  </h2>
                </ng-template>
                <ng-template ngbPanelContent>
                  <form [formGroup]="workflowCategoriesForm">
                    <ng-container formArrayName="vehicleQueueCategories">
                      <div class="form-check"
                        *ngFor="let vehicleQueueCategory of workflowCategoriesForm.get('vehicleQueueCategories').controls; let i = index;"
                        [formGroupName]="i">
                        <input class="form-check-input" id="ckhVehicleQueueCategoryIsSelected{{i}}" type="checkbox"
                          formControlName="isSelected" (change)="fetchQueueItems()">
                        <label class="form-check-label" for="ckhVehicleQueueCategoryIsSelected{{i}}">
                          <span>{{vehicleQueueCategory.controls.count.value}}</span>
                          {{vehicleQueueCategory.controls.name.value}}
                        </label>
                      </div>
                    </ng-container>
                  </form>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </div>

        <div class="vehicl-sec">
          <ngb-accordion [activeIds]="activeIds" (panelChange)="onQueueAccordionChange($event)">
            <ng-container *ngFor="let queue of landingPageQueueModels;let idx = index;">
              <ngb-panel *ngIf="queue.isVisible" [id]="queue.panelId">
                <ng-template ngbPanelTitle>
                  <div class="adviseHdr">
                    <span class="adviseTxt">{{queue.name}}</span>
                    <span class="adviseIcon"><em class="fa" aria-hidden="true" [ngClass]="{
                                    'fa-caret-down': queue.isExpaned,
                                    'fa-caret-up': !queue.isExpaned
                                 }"></em></span>
                    <span class="adviseVehicl">{{queue.vehicleQueueItems.length}}
                      Vehicles</span>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <ng-container *ngFor="let item of queue.vehicleQueueItems;let i = index;">
                    <app-vehicle-queue-item [vehicleQueueModel]="item" [authenticateUser]="authenticateUser"
                      [categories]="queue.categories" [permissions]="queue.permissions"></app-vehicle-queue-item>
                  </ng-container>
                </ng-template>
              </ngb-panel>
            </ng-container>
          </ngb-accordion>
        </div>
      </div>
    </div>
  </section>
</div>
