<section class="datatableSer">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <h1>Manage {{entityName}} Users</h1>
            </div>
            <div class="col-12 col-md-6 text-center text-md-right">
                <button class="btn btn-red" type="button" [routerLink]="['/add/user']">Add User</button>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="tableWrp">
                    <div class="filterWrp">
                        <form [formGroup]="form">
                            <div class="row justify-content-end">
                                <div class="col-12 col-sm-3">
                                    <label>Role Type</label>
                                    <select class="form-control" formControlName="applicationRoleType">
                                        <option *ngFor="let item of roleTypeFilterOptions" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-12 col-sm-3"
                                    *ngIf="selectedApplicationRoleType == ApplicationRoleType.Partner">
                                    <label>Managing Company</label>
                                    <select class="form-control hDropDown" formControlName="company"
                                        placeholder="Managing Company">
                                        <option value="">Select Managing Company</option>
                                        <option *ngFor="let item of companies" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-12 col-sm-3"
                                    *ngIf="selectedApplicationRoleType == ApplicationRoleType.Store">
                                    <label>Store</label>
                                    <select class="form-control hDropDown" formControlName="store" placeholder="Store">
                                        <option value="">Select Store</option>
                                        <option *ngFor="let item of stores" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-12 col-sm-3"
                                    *ngIf="selectedApplicationRoleType == ApplicationRoleType.Vendor">
                                    <label>Vendor</label>
                                    <select class="form-control hDropDown" formControlName="vendor"
                                        placeholder="Vendor">
                                        <option value="">Select Vendor</option>
                                        <option *ngFor="let item of vendors" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-12 col-sm-3">
                                    <label>Status</label>
                                    <select class="form-control" formControlName="statusOption">
                                        <option *ngFor="let item of statusOptions" [value]="item.id">{{item.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div *blockUI="'container-blockui-manage'" class="bluetableWrp table-responsive">
                        <table datatable [dtOptions]="dtOptions" data-page="2" class="table-hover break-all table"
                            summary="Users">
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-user-delete-confirmation #deleteConfirmationModal (delete)="delete($event)"> </app-user-delete-confirmation>
<app-user-status-change-confirmation #statusChangeConfirmationModal (toggle)="toggle($event)">
</app-user-status-change-confirmation>
<app-reset-other-user-password-confirmation #resetPasswordOtherUserConfirmationModal (reset)="resetPassword($event)">
</app-reset-other-user-password-confirmation>
<app-welcome-mail-confirmation #welcomeMailConfirmationModal (send)="sendWelcomeMail($event)" ></app-welcome-mail-confirmation>