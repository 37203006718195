export class WorkflowImportModel {
    public storeId: number;
    public checkInTypeId: number;
    public workflowId: number;
}

export class WorkflowImportSelectListModel {
    public checkinTypeId: number;
    public checkinTypeName: string;
    public workflowId: number;
}