import { ChangeDetectorRef, Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ChangePasswordModel } from 'src/app/models';
import { MessagingService, AuthenticationService } from 'src/app/services';
import { MustMatch, MustNotMatch } from 'src/app/validators';

@Component({
    selector: 'app-change-password',
    templateUrl: './change.password.component.html',
    styleUrls: ['./change.password.component.css']
})
export class ChangePasswordComponent {

    @BlockUI('container-blockui-change-password') blockUI: NgBlockUI;
    frm: FormGroup;
    submitted = false;
    passwordIsValid = false;

    constructor(        private cdr: ChangeDetectorRef,
        private messagingService: MessagingService,
        private authenticationService: AuthenticationService,
        private formBuilder: FormBuilder
    ) {

    }

    ngOnInit() {
        this.frm = this.formBuilder.group({
            currentPassword: ['', [Validators.required]],
            newPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
            confirmPassword: ['', Validators.required]
        }, {
            validator: [
                MustNotMatch('currentPassword', 'newPassword'),
                MustMatch('newPassword', 'confirmPassword')
            ]
        });
    }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid.
        if (!this.passwordIsValid || this.frm.invalid) {
            return;
        }

        let model = new ChangePasswordModel();
        model.currentPassword = this.frm.controls.currentPassword.value;
        model.newPassword = this.frm.controls.newPassword.value;
        model.confirmPassword = this.frm.controls.confirmPassword.value;

        this.blockUI.start();
        this.authenticationService.changePassword(model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    this.messagingService.success('Your password has been changed successfully');
                    this.submitted = false;
                    this.frm.reset();
                },
                error => {
                    this.blockUI.stop();
                    this.messagingService.ProcessErrorResponse(error);
                });
    }

    passwordValid($event:boolean) {
        this.passwordIsValid = $event;
        this.cdr.detectChanges();
    }
}
