export enum FilterPageType {
    companyManage = 'companyManage',
    storeManage = 'storeManage',
    vendorManage = 'vendorManage',
    categoryManage = 'categoryManage',
    toolManage = 'toolManage',
    opCodeManage = 'opCodeManage',
    checkInTypeManage = 'checkInTypeManage',
    workFlowManage = 'workFlowManage',
    priceMatrixManage = 'priceMatrix',
    roleManage = 'roleManage',
    userManage = 'userManage'
}