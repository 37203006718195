import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class VehicleQueueNoteService {
    constructor(http) {
        this.http = http;
    }
    getNotes(vehicelQueueId) {
        return this.http.get(`${environment.apiBaseUrl}/vehicle-queue-note/notes/${vehicelQueueId}`);
    }
    add(model) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle-queue-note`, model);
    }
}
VehicleQueueNoteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VehicleQueueNoteService_Factory() { return new VehicleQueueNoteService(i0.ɵɵinject(i1.HttpClient)); }, token: VehicleQueueNoteService, providedIn: "root" });
