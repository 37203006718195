/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./manage.car-layout.panel.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "angular-datatables/src/angular-datatables.directive";
import * as i3 from "ng-block-ui";
import * as i4 from "./manage.car-layout.panel.component";
import * as i5 from "../../../../services/messaging.service";
import * as i6 from "../../../../services/car.layout.panel.service";
import * as i7 from "../../../../helpers/app.utils";
var styles_CarLayoutManagePanelComponent = [i0.styles];
var RenderType_CarLayoutManagePanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CarLayoutManagePanelComponent, data: {} });
export { RenderType_CarLayoutManagePanelComponent as RenderType_CarLayoutManagePanelComponent };
function View_CarLayoutManagePanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "bluetableWrp table-responsive"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "table", [["class", "table-hover break-all table w-100"], ["data-page", "2"], ["datatable", ""], ["summary", "Car Layout Sides"]], null, null, null, null, null)), i1.ɵdid(2, 212992, [[1, 4]], 0, i2.DataTableDirective, [i1.ElementRef], { dtOptions: [0, "dtOptions"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dtOptions; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CarLayoutManagePanelComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { datatableElement: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 10, "section", [["class", "datatableSer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-12 col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Car Layout Panel"])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "tableWrp"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarLayoutManagePanelComponent_1)), i1.ɵdid(11, 212992, null, 0, i3.ɵf, [i3.BlockUIService, i3.ɵc, i1.ViewContainerRef, i1.TemplateRef, i1.Renderer2, i1.ComponentFactoryResolver], { blockUI: [0, "blockUI"] }, null)], function (_ck, _v) { var currVal_0 = "container-blockui-grid"; _ck(_v, 11, 0, currVal_0); }, null); }
export function View_CarLayoutManagePanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-car-layout-panel", [], null, null, null, View_CarLayoutManagePanelComponent_0, RenderType_CarLayoutManagePanelComponent)), i1.ɵdid(1, 4440064, null, 0, i4.CarLayoutManagePanelComponent, [i5.MessagingService, i6.CarLayoutPanelService, i7.AppUtils], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CarLayoutManagePanelComponentNgFactory = i1.ɵccf("app-car-layout-panel", i4.CarLayoutManagePanelComponent, View_CarLayoutManagePanelComponent_Host_0, {}, {}, []);
export { CarLayoutManagePanelComponentNgFactory as CarLayoutManagePanelComponentNgFactory };
