import { Routes } from "@angular/router";
import { Error403Component, Error404Component } from "./components/error";
import { CompanyAddComponent, CompanyDetailComponent, CompanyEditComponent, CompanyManageComponent } from "./components/company";
import { UserManageComponent, UserAddComponent, UserEditComponent, UserDetailComponent, UserProfileComponent, UserEditProfileComponent } from "./components/users";
import { PermissionType } from "./enums";
import { AccountActivateComponent, AccountResetComponent, ChangePasswordComponent, ForgotPasswordComponent, LoginComponent } from "./components/authenticate";
import { ContactUsComponent, FAQComponent } from "./components/shared";
import { StoreAddComponent, StoreCloneComponent, StoreDetailComponent, StoreEditComponent, StoreManageComponent } from "./components/store";
import { VendorManageComponent, VendorAddComponent, VendorEditComponent, VendorDetailComponent } from "./components/vendor";
import { RoleAddComponent, RoleEditComponent, RoleIndexComponent } from "./components/roles";
import { AuthenticatedUserGuard, UnauthenticatedUserGuard, PermissionGuard } from "./guards";
import { AddPriceMatrixComponent, ClonePriceMatrixComponent, EditPriceMatrixComponent, ManagePriceMatrixComponent, PriceMatrixDetailComponent } from "./components/price-matrix";
import { AddOpCodeComponent, EditOpCodeComponent, ManageOpCodeComponent, OpCodeDetailComponent } from "./components/op-code";
import { AccountingComponent } from './components/accounting';
import { WorkflowAddComponent, WorkflowDetailComponent, WorkflowEditComponent, WorkflowManageComponent } from "./components/workflow";
import { CheckInTypeAddComponent, CheckInTypeDetailComponent, CheckInTypeEditComponent, CheckInTypeManageComponent } from "./components/check-in-type";
import { ApprovalComponent } from "./components/approval";
import { VehicleQueueComponent } from "./components/home";
import { QuotationFormComponent } from "./components/quotation";
import { SubCategoryManageComponent, SubCategoryAddComponent, SubCategoryEditComponent, SubCategoryDetailComponent } from "./components/sub-category";
import { CategoryManageComponent, CategoryAddComponent, CategoryEditComponent, CategoryDetailComponent } from "./components/category";
import { CarLayoutManageLocationComponent } from './components/car-layout/location';
import { CarLayoutManagePanelComponent } from './components/car-layout/side';
import { CarLayoutManageSideComponent } from './components/car-layout/panel';
import { AutoImportVehicleComponent, VechilefinalInspectionComponent, EditVehicleDetailsComponent } from "./components/vehicle";
import { InvoiceViewComponent } from "./components/invoice/invoice-view/invoice-view.component";
import { InvoiceListComponent } from "./components/invoice/invoice-list/invoice-list.component";
import { InvoiceFormatComponent } from "./components/invoice/invoice-format/invoice-format.component";
import { MechanicalAdvisingComponent, MechanicalDispatchComponent } from "./components/mechanical";
import { AssessmentComponent } from "./components/assessment";
import { ReconPackageComponent } from "./components/recon-package";
import { VendorActionComponent } from "./components/worker-action";
import { UpdateVehicleWorkflowComponent } from './components/approval/update-vehicle-workflow/update-vehicle-workflow.component';
import { FinalInspectionComponent as FinalInspectionComponent } from "./components/inspection";
const ɵ0 = {
    header: {
        showControls: true,
        disableControls: false
    }
}, ɵ1 = {
    permissions: [PermissionType.Master]
}, ɵ2 = {
    permissions: [PermissionType.Master]
}, ɵ3 = {
    permissions: [PermissionType.Master, PermissionType.ManagingCompanyAdmin]
}, ɵ4 = {
    permissions: [PermissionType.Master, PermissionType.ManagingCompanyAdmin]
}, ɵ5 = {
    permissions: [PermissionType.Master, PermissionType.StoreAdmin, PermissionType.StoreManagement],
    header: {
        showControls: true,
        disableControls: false,
        showStoreControl: false
    }
}, ɵ6 = {
    permissions: [PermissionType.Master, PermissionType.StoreAdmin],
    header: {
        showControls: true,
        disableControls: true,
        showStoreControl: false
    }
}, ɵ7 = {
    permissions: [PermissionType.Master, PermissionType.StoreAdmin],
    header: {
        showControls: true,
        disableControls: true,
        showStoreControl: false
    }
}, ɵ8 = {
    permissions: [PermissionType.Master, PermissionType.StoreAdmin],
    header: {
        showControls: true,
        disableControls: true,
        showStoreControl: false
    }
}, ɵ9 = {
    permissions: [PermissionType.Master, PermissionType.StoreAdmin, PermissionType.StoreManagement
    ],
    header: {
        showControls: true,
        disableControls: true,
        showStoreControl: false
    }
}, ɵ10 = {
    permissions: [PermissionType.Master, PermissionType.ManagingCompanyAdmin, PermissionType.StoreAdmin, PermissionType.VendorAdmin]
}, ɵ11 = {
    permissions: [PermissionType.Master, PermissionType.ManagingCompanyAdmin, PermissionType.StoreAdmin, PermissionType.VendorAdmin]
}, ɵ12 = {
    permissions: [PermissionType.Master, PermissionType.ManagingCompanyAdmin, PermissionType.StoreAdmin, PermissionType.VendorAdmin]
}, ɵ13 = {
    permissions: [PermissionType.Master, PermissionType.ManagingCompanyAdmin, PermissionType.StoreAdmin, PermissionType.VendorAdmin]
}, ɵ14 = {
    permissions: [PermissionType.Master, PermissionType.VendorAdmin],
    header: {
        showControls: true,
        disableControls: false
    }
}, ɵ15 = {
    permissions: [PermissionType.Master, PermissionType.VendorAdmin],
    header: {
        showControls: true,
        disableControls: true
    }
}, ɵ16 = {
    permissions: [PermissionType.Master, PermissionType.VendorAdmin],
    header: {
        showControls: true,
        disableControls: true
    }
}, ɵ17 = {
    permissions: [PermissionType.Master, PermissionType.VendorAdmin],
    header: {
        showControls: true,
        disableControls: true
    }
}, ɵ18 = {
    permissions: [PermissionType.Master]
}, ɵ19 = {
    permissions: [PermissionType.Master]
}, ɵ20 = {
    permissions: [PermissionType.Master]
}, ɵ21 = {
    permissions: [PermissionType.Master],
    header: {
        showControls: true,
        disableControls: false
    }
}, ɵ22 = {
    permissions: [PermissionType.Master, PermissionType.WorkflowAddition],
    header: {
        showControls: true,
        disableControls: true
    }
}, ɵ23 = {
    permissions: [PermissionType.Master, PermissionType.WorkflowAddition],
    header: {
        showControls: true,
        disableControls: true
    }
}, ɵ24 = {
    permissions: [PermissionType.Master, PermissionType.WorkflowAddition, PermissionType.WorkflowAdjustment],
    header: {
        showControls: true,
        disableControls: true
    }
}, ɵ25 = {
    permissions: [PermissionType.Master, PermissionType.WholesaleButtonAction, PermissionType.WorkflowAddition],
    header: {
        showControls: true,
        disableControls: true
    }
}, ɵ26 = {
    permissions: [PermissionType.Master, PermissionType.WorkflowAddition, PermissionType.WorkflowAdjustment],
    header: {
        showControls: true,
        disableControls: true
    }
}, ɵ27 = {
    permissions: [PermissionType.VehicleImport],
    header: {
        showControls: true,
        disableControls: false
    }
}, ɵ28 = {
    permissions: [PermissionType.Recon_PkgAdmin]
}, ɵ29 = {
    permissions: [PermissionType.VehicleInformationAdjustment]
}, ɵ30 = {
    permissions: [PermissionType.Inspection]
}, ɵ31 = {
    permissions: [PermissionType.CqHgGqEntry],
    header: {
        showControls: true,
        disableControls: true
    }
}, ɵ32 = {
    permissions: [PermissionType.CqHgGqEntry],
    header: {
        showControls: true,
        disableControls: true
    }
}, ɵ33 = {
    permissions: [PermissionType.MechanicalAdvisingDataEntry],
    header: {
        showControls: true,
        disableControls: true
    }
}, ɵ34 = {
    permissions: [PermissionType.Mechanical],
    header: {
        showControls: true,
        disableControls: true
    }
}, ɵ35 = {
    permissions: [
    //PermissionType.CqHgGqEntry
    ],
    header: {
        showControls: true,
        disableControls: true
    }
}, ɵ36 = {
    permissions: [
        PermissionType.Approval
    ],
    header: {
        showControls: true,
        disableControls: true
    }
}, ɵ37 = {
    permissions: [
        PermissionType.Approval
    ],
    header: {
        showControls: true,
        disableControls: true
    }
}, ɵ38 = {
    permissions: [PermissionType.Master, PermissionType.WorkflowAddition, PermissionType.WorkflowAdjustment],
    header: {
        showControls: true,
        disableControls: true
    }
}, ɵ39 = {
    permissions: [PermissionType.Master, PermissionType.VehicleImport, PermissionType.VehicleInformationAdjustment],
    header: {
        showControls: true,
        disableControls: true
    }
}, ɵ40 = {
    permissions: [PermissionType.Master]
}, ɵ41 = {
    permissions: [PermissionType.Master]
}, ɵ42 = {
    permissions: [PermissionType.Master]
}, ɵ43 = {
    permissions: [PermissionType.Master],
    header: {
        showControls: true,
        disableControls: false
    }
}, ɵ44 = {
    permissions: [PermissionType.Master],
    header: {
        showControls: true,
        disableControls: true
    }
}, ɵ45 = {
    permissions: [PermissionType.Master, PermissionType.PricingAdjustmentOPCodesIncludedInReconPackage, PermissionType.PricingAdjustmentOPCodesNotIncludedInReconPackage],
    header: {
        showControls: true,
        disableControls: true
    }
}, ɵ46 = {
    permissions: [PermissionType.ClientCreation],
    header: {
        showControls: true,
        disableControls: true
    }
}, ɵ47 = {
    permissions: [PermissionType.Master, PermissionType.PricingAdjustmentOPCodesIncludedInReconPackage, PermissionType.PricingAdjustmentOPCodesNotIncludedInReconPackage],
    header: {
        showControls: true,
        disableControls: true
    }
}, ɵ48 = {
    permissions: [PermissionType.Master]
}, ɵ49 = {
    permissions: [PermissionType.Master]
}, ɵ50 = {
    permissions: [PermissionType.Master]
}, ɵ51 = {
    permissions: [PermissionType.Master]
}, ɵ52 = {
    permissions: [PermissionType.Master]
}, ɵ53 = {
    permissions: [PermissionType.Master]
}, ɵ54 = {
    permissions: [PermissionType.Master]
}, ɵ55 = {
    permissions: [PermissionType.Master]
}, ɵ56 = {
    permissions: [PermissionType.Master]
}, ɵ57 = {
    permissions: [PermissionType.Master]
}, ɵ58 = {
    permissions: [PermissionType.Master]
}, ɵ59 = {
    permissions: [PermissionType.Master]
}, ɵ60 = {
    permissions: [PermissionType.Master]
}, ɵ61 = {
    permissions: [PermissionType.Master]
}, ɵ62 = {
    permissions: [PermissionType.Master]
}, ɵ63 = {
    permissions: [PermissionType.Master]
}, ɵ64 = {
    permissions: [PermissionType.StoreAccounting, PermissionType.VendorAccounting, PermissionType.WorkflowStatusView, PermissionType.InvoicingFunction]
};
const routes = [
    {
        path: '',
        component: VehicleQueueComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ0
    },
    {
        path: 'contact-us',
        component: ContactUsComponent
    },
    {
        path: 'faq',
        component: FAQComponent
    },
    {
        path: 'accounting',
        component: AccountingComponent
    },
    {
        path: 'error/403',
        component: Error403Component
    },
    {
        path: 'error/404',
        component: Error404Component
    },
    //Authentication Related Routes
    {
        path: 'account/login',
        component: LoginComponent,
        canActivate: [UnauthenticatedUserGuard, PermissionGuard]
    },
    {
        path: 'account/forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [UnauthenticatedUserGuard, PermissionGuard]
    },
    {
        path: 'account/activate/:key/:secret',
        component: AccountActivateComponent,
        canActivate: [UnauthenticatedUserGuard, PermissionGuard]
    },
    {
        path: 'account/reset/:key/:secret',
        component: AccountResetComponent,
        canActivate: [UnauthenticatedUserGuard, PermissionGuard]
    },
    //Only authenticated user can access these
    {
        path: 'account/change-password',
        component: ChangePasswordComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard]
    },
    //Company Related Routes
    {
        path: 'manage/company',
        component: CompanyManageComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ1
    },
    {
        path: 'add/company',
        component: CompanyAddComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ2
    },
    {
        path: 'edit/company/:id',
        component: CompanyEditComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ3
    },
    {
        path: 'company/detail/:id',
        component: CompanyDetailComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ4
    },
    //Store Related Routes
    {
        path: 'manage/store',
        component: StoreManageComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ5
    },
    {
        path: 'add/store',
        component: StoreAddComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ6
    },
    {
        path: 'edit/store/:storeId',
        component: StoreEditComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ7
    },
    {
        path: 'clone/store/:storeId',
        component: StoreCloneComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ8
    },
    {
        path: 'store/detail/:storeId',
        component: StoreDetailComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ9
    },
    //User Related Routes
    {
        path: 'manage/user',
        component: UserManageComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ10
    },
    {
        path: 'add/user',
        component: UserAddComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ11
    },
    {
        path: 'edit/user/:userId',
        component: UserEditComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ12
    },
    {
        path: 'user/detail/:userId',
        component: UserDetailComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ13
    },
    {
        path: 'my/profile',
        component: UserProfileComponent,
        canActivate: [AuthenticatedUserGuard]
    },
    {
        path: 'my/profile/edit',
        component: UserEditProfileComponent,
        canActivate: [AuthenticatedUserGuard]
    },
    //Vendor Related Routes
    {
        path: 'manage/vendor',
        component: VendorManageComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ14
    },
    {
        path: 'add/vendor',
        component: VendorAddComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ15
    },
    {
        path: 'edit/vendor/:vendorId',
        component: VendorEditComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ16
    },
    {
        path: 'vendor/detail/:vendorId',
        component: VendorDetailComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ17
    },
    //Role related
    {
        path: 'manage/role',
        component: RoleIndexComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ18
    },
    {
        path: 'role/add',
        component: RoleAddComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ19
    },
    {
        path: 'role/edit/:id',
        component: RoleEditComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ20
    },
    //WorkFlow
    {
        path: 'manage/workflow',
        component: WorkflowManageComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ21
    },
    {
        path: 'add/workflow',
        component: WorkflowAddComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ22
    },
    {
        path: 'add/workflow/check-in-type/:checkInTypeId',
        component: WorkflowAddComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ23
    },
    {
        path: 'workflow/:id/edit',
        component: WorkflowEditComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ24
    },
    {
        path: 'store/:storeId/associate/workflow/:id',
        component: WorkflowEditComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ25
    },
    {
        path: 'workflow/:id/detail',
        component: WorkflowDetailComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ26
    },
    //vehicle
    {
        path: 'inventory',
        component: AutoImportVehicleComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ27
    },
    {
        path: 'vehicle/recon/package/:vehicleQueueId/:vehicleQueueStageId',
        component: ReconPackageComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ28
    },
    {
        path: 'vehicle/final-inspection-screen',
        component: VechilefinalInspectionComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ29
    },
    {
        path: 'vehicle/final-inspection/:storeId/:vehicleQueueId/:vehicleQueueStageId',
        component: FinalInspectionComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ30
    },
    {
        path: 'vehicle/quotation/:storeId/:quotationId/:vehicleQueueId/:vehicleQueueStageId',
        component: QuotationFormComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ31
    },
    {
        path: 'vehicle/quotation/:companyId/:storeId/:vendorId/:quotationId/:vehicleQueueId/:vehicleQueueStageId',
        component: QuotationFormComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ32
    },
    {
        path: 'vehicle/mechanical/advising/:storeId/:vehicleQueueId/:vehicleQueueStageId',
        component: MechanicalAdvisingComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ33
    },
    {
        path: 'vehicle/mechanical/dispatch/:storeId/:vehicleQueueId/:vehicleQueueStageId',
        component: MechanicalDispatchComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ34
    },
    {
        path: 'vehicle/vendor/action/:storeId/:vehicleQueueId/:vehicleQueueStageId',
        component: VendorActionComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ35
    },
    {
        path: 'vehicle/assessment/:storeId/:vehicleQueueId/:vehicleQueueStageId/:workflowId/:vehicleTypeId',
        component: AssessmentComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ36
    },
    //Approval
    {
        path: 'vehicle/approval/:vehicleQueueId/:vehicleQueueStageId',
        component: ApprovalComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ37
    },
    {
        path: 'vehicle/workflow/:id/:vehicleQueueId/:vehicleQueueStageId/edit',
        component: UpdateVehicleWorkflowComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ38
    },
    //Vehicle Info
    {
        path: 'edit/vehicle-detail/:vehicleQueueModelId',
        component: EditVehicleDetailsComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ39
    },
    //Car layout
    {
        path: 'car-layout-location',
        component: CarLayoutManageLocationComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ40
    },
    {
        path: 'car-layout-side',
        component: CarLayoutManageSideComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ41
    },
    {
        path: 'car-layout-panel',
        component: CarLayoutManagePanelComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ42
    },
    //Price Matrix
    {
        path: 'manage/price-matrix',
        component: ManagePriceMatrixComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ43
    },
    {
        path: 'add/price-matrix',
        component: AddPriceMatrixComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ44
    },
    {
        path: 'edit/price-matrix/:priceMatrxId',
        component: EditPriceMatrixComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ45
    },
    {
        path: 'clone/price-matrix/:priceMatrxId',
        component: ClonePriceMatrixComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ46
    },
    {
        path: 'price-matrix/detail/:priceMatrxId',
        component: PriceMatrixDetailComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ47
    },
    //op-code
    {
        path: 'manage/op-code',
        component: ManageOpCodeComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ48
    },
    {
        path: 'add/op-code',
        component: AddOpCodeComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ49
    },
    {
        path: 'op-code/:id/edit',
        component: EditOpCodeComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ50
    },
    {
        path: 'op-code/:id/detail',
        component: OpCodeDetailComponent,
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ51
    },
    //Check-in type
    {
        path: 'manage/check-in-type',
        component: CheckInTypeManageComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ52
    },
    {
        path: 'add/check-in-type',
        component: CheckInTypeAddComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ53
    },
    {
        path: 'edit/check-in-type/:checkInTypeId',
        component: CheckInTypeEditComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ54
    },
    {
        path: 'check-in-type/detail/:checkInTypeId',
        component: CheckInTypeDetailComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ55
    },
    //Category
    {
        path: 'manage/category',
        component: CategoryManageComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ56
    },
    {
        path: 'add/category',
        component: CategoryAddComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ57
    },
    {
        path: 'edit/category/:categoryId',
        component: CategoryEditComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ58
    },
    {
        path: 'category/detail/:categoryId',
        component: CategoryDetailComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ59
    },
    //Sub category
    {
        path: 'manage/sub-category',
        component: SubCategoryManageComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ60
    },
    {
        path: 'add/sub-category',
        component: SubCategoryAddComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ61
    },
    {
        path: 'edit/sub-category/:subCategoryId',
        component: SubCategoryEditComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ62
    },
    {
        path: 'sub-category/detail/:subCategoryId',
        component: SubCategoryDetailComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ63
    },
    //Invoice
    {
        path: 'invoice/invoice-view',
        component: InvoiceViewComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard],
        data: ɵ64
    },
    {
        path: 'invoice/invoice-list',
        component: InvoiceListComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard]
    },
    {
        path: 'invoice/invoice-format',
        component: InvoiceFormatComponent,
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard, PermissionGuard]
    },
    {
        path: '**',
        redirectTo: ''
    },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30, ɵ31, ɵ32, ɵ33, ɵ34, ɵ35, ɵ36, ɵ37, ɵ38, ɵ39, ɵ40, ɵ41, ɵ42, ɵ43, ɵ44, ɵ45, ɵ46, ɵ47, ɵ48, ɵ49, ɵ50, ɵ51, ɵ52, ɵ53, ɵ54, ɵ55, ɵ56, ɵ57, ɵ58, ɵ59, ɵ60, ɵ61, ɵ62, ɵ63, ɵ64 };
