import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CompanyListItemModel, CompanyStatusToggleModel, SelectListModel } from 'src/app/models';
import { CompanyService, MessagingService } from 'src/app/services';

@Component({
    selector: 'app-company-status-change-confirmation',
    templateUrl: './company.status.change.confirmation.component.html',
    styleUrls: ['./company.status.change.confirmation.component.css']
})
export class CompanyStatusChangeConfirmationComponent implements OnDestroy {
    @BlockUI('container-blockui-confirmation') blockUI: NgBlockUI;
    @ViewChild('confirmationModal', { static: false }) confirmationModal: any;
    @Output() toggle = new EventEmitter<CompanyStatusToggleModel>();
    companies: Array<SelectListModel>;
    message: string;
    modalReference: NgbModalRef;
    company: CompanyListItemModel;

    form: FormGroup;
    submitted: boolean;

    constructor(
        private modalService: NgbModal, 
        private companyService: CompanyService, 
        private formBuilder: FormBuilder, 
        private messagingService: MessagingService) {
    }

    openModal(company: CompanyListItemModel) {
        this.form = this.formBuilder.group({
            companyId: ['']
        });

        this.company = company;
        this.companies = new Array<SelectListModel>();

        //Load Company only if company is active
        if (this.company.isActive) {
            this.form.get('companyId').setValidators([Validators.required]);
            this.loadCompanies();
        }

        this.modalReference = this.modalService.open(this.confirmationModal, { centered: true });
    }


    onSubmit() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }

        let model = new CompanyStatusToggleModel();
        model.id = this.company.id;
        model.isActive = this.company.isActive;
        if (this.company.isActive) {
            model.reassignedCompanyId = Number(this.form.controls.companyId.value);
        }
        this.form.reset();
        this.submitted = false;

        setTimeout(() => {           
            this.toggle.emit(model);
        });
        this.onCancel();
    }

    onCancel() {
        if(!this.modalReference){
            return;
        }
        this.modalReference.close();
    }

    private loadCompanies() {
        this.blockUI.start();
        this.companyService.getCompanies().subscribe((data: Array<SelectListModel>) => {
            this.companies = data.filter(x => x.id !== this.company.id);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    
    ngOnDestroy(): void {
        this.onCancel();
    }
}
