export class FilterStateModel {
    page: string;
    filterKey: string;
    filterOption: number;
    statusOption: boolean;
    isActive: boolean;

    extras: any;
    constructor() {
        this.filterOption = 1;
        this.statusOption = true;
        this.extras = {};
    }
}
