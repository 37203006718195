var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BlockUI } from "ng-block-ui";
import { ToastrService } from "ngx-toastr";
import { StringLengthConstants } from "src/app/helpers";
import { CheckInTypeAddModel } from "src/app/models";
import { CheckInTypeService, MessagingService } from "src/app/services";
export class CheckInTypeAddComponent {
    constructor(formBuilder, router, toastr, messagingService, checkInTypeService, stringLengthConstants) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.toastr = toastr;
        this.messagingService = messagingService;
        this.checkInTypeService = checkInTypeService;
        this.stringLengthConstants = stringLengthConstants;
        this.submitted = false;
        this.model = new CheckInTypeAddModel();
    }
    ngOnInit() {
        this.frmCheckInType = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            description: ['', [Validators.maxLength(this.stringLengthConstants.Description)]]
        });
    }
    onSubmit() {
        this.submitted = true;
        if (this.frmCheckInType.invalid) {
            return;
        }
        this.model.name = this.frmCheckInType.controls.name.value;
        this.model.description = this.frmCheckInType.controls.description.value;
        this.checkInTypeService.add(this.model)
            .subscribe((data) => {
            setTimeout(() => {
                //this.router.navigate(['/check-in-type/detail', data.id]);
                this.router.navigate(['add/workflow/check-in-type', data.id]);
            }, 10);
            setTimeout(() => {
                this.toastr.success('Check-In type has been added successfully.');
            }, 300);
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
}
__decorate([
    BlockUI('blockui-container'),
    __metadata("design:type", Object)
], CheckInTypeAddComponent.prototype, "blockUI", void 0);
