import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CarLayoutLocationService {
    constructor(http) {
        this.http = http;
    }
    query(dataTablesParameters) {
        return this.http.post(`${environment.apiBaseUrl}/car-layout-location/query`, dataTablesParameters);
    }
    selectList() {
        return this.http.get(`${environment.apiBaseUrl}/car-layout-location/select-list`);
    }
}
CarLayoutLocationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CarLayoutLocationService_Factory() { return new CarLayoutLocationService(i0.ɵɵinject(i1.HttpClient)); }, token: CarLayoutLocationService, providedIn: "root" });
