var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockUI } from "ng-block-ui";
import { ToastrService } from "ngx-toastr";
import { FilterPageType } from "src/app/enums/filter.page.type.enum";
import { StringLengthConstants } from "src/app/helpers";
import { CheckInTypeDetailModel, CheckInTypeEditModel } from "src/app/models";
import { CheckInTypeService, FilterStateService, MessagingService } from "src/app/services";
export class CheckInTypeEditComponent {
    constructor(formBuilder, router, route, toastr, messagingService, checkInTypeService, stringLengthConstants, filterStateService) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.route = route;
        this.toastr = toastr;
        this.messagingService = messagingService;
        this.checkInTypeService = checkInTypeService;
        this.stringLengthConstants = stringLengthConstants;
        this.filterStateService = filterStateService;
        this.submitted = false;
        filterStateService.changeFilterModelStatues(FilterPageType.checkInTypeManage, true);
        this.model = new CheckInTypeEditModel();
        this.route.params.subscribe(p => this.model.id = Number(p.checkInTypeId));
    }
    ngOnInit() {
        this.frmCheckInType = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            description: ['', [Validators.maxLength(this.stringLengthConstants.Description)]]
        });
        this.loadCheckInType();
    }
    loadCheckInType() {
        this.blockUI.start();
        this.checkInTypeService.get(this.model.id).subscribe((data) => {
            this.model = data;
            this.initForm();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    initForm() {
        this.frmCheckInType.controls.name.setValue(this.model.name);
        this.frmCheckInType.controls.description.setValue(this.model.description);
    }
    onSubmit() {
        this.submitted = true;
        if (this.frmCheckInType.invalid) {
            return;
        }
        // this.model.name = this.frmCategory.controls.name.value;
        this.model.description = this.frmCheckInType.controls.description.value;
        this.checkInTypeService.update(this.model)
            .subscribe((data) => {
            setTimeout(() => {
                this.router.navigate(['/manage/check-in-type']);
            }, 10);
            setTimeout(() => {
                this.toastr.success('Category has been updated successfully.');
            }, 300);
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
}
__decorate([
    BlockUI('blockui-container'),
    __metadata("design:type", Object)
], CheckInTypeEditComponent.prototype, "blockUI", void 0);
