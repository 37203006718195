/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./workflow.stage.editable.widget.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-drag-drop";
import * as i4 from "../edit/edit-workflow-stage.component.ngfactory";
import * as i5 from "../edit/edit-workflow-stage.component";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "@angular/forms";
import * as i8 from "../../../../services/messaging.service";
import * as i9 from "../../../../services/operation.code.service";
import * as i10 from "../delete-confirmation/workflow.stage.delete.confirmation.component.ngfactory";
import * as i11 from "../delete-confirmation/workflow.stage.delete.confirmation.component";
import * as i12 from "./workflow.stage.editable.widget.component";
var styles_WorkflowStageEditableWidgetComponent = [i0.styles];
var RenderType_WorkflowStageEditableWidgetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WorkflowStageEditableWidgetComponent, data: {} });
export { RenderType_WorkflowStageEditableWidgetComponent as RenderType_WorkflowStageEditableWidgetComponent };
function View_WorkflowStageEditableWidgetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["- ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.subCategoryName; _ck(_v, 1, 0, currVal_0); }); }
function View_WorkflowStageEditableWidgetComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["title", "Dependency Locked"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "em", [["aria-hidden", "true"], ["class", "fa fa-lock"]], null, null, null, null, null))], null, null); }
function View_WorkflowStageEditableWidgetComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["title", "Edit"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openWorkflowStepEditModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "em", [["aria-hidden", "true"], ["class", "fa fa-edit"]], null, null, null, null, null))], null, null); }
function View_WorkflowStageEditableWidgetComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [["class", "taskIcon"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkflowStageEditableWidgetComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkflowStageEditableWidgetComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["title", "Delete"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openWorkflowStepDeleteModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "em", [["aria-hidden", "true"], ["class", "fa fa-trash"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.workflowStageModel.dependencyLocked; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isAllCategoryActive; _ck(_v, 4, 0, currVal_1); }, null); }
function View_WorkflowStageEditableWidgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "taskBoxInner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [["class", "taskContent"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkflowStageEditableWidgetComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkflowStageEditableWidgetComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.subCategoryName; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.index === 0); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.categoryName; _ck(_v, 2, 0, currVal_0); }); }
export function View_WorkflowStageEditableWidgetComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { editModal: 0 }), i1.ɵqud(671088640, 2, { deleteConfirmationModal: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "taskBox"]], null, [[null, "dndDrop"], [null, "drop"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("drop" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onDrop($event) !== false);
        ad = (pd_0 && ad);
    } if (("dndDrop" === en)) {
        var pd_1 = (_co.onDropCategory($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "disabled": 0, "highlight": 1 }), i1.ɵdid(6, 4341760, null, 1, i3.DndDropzoneDirective, [i1.NgZone, i1.ElementRef, i1.Renderer2], { dndDropzone: [0, "dndDropzone"], dndDisableIf: [1, "dndDisableIf"] }, { dndDrop: "dndDrop" }), i1.ɵqud(335544320, 3, { dndPlaceholderRef: 0 }), i1.ɵpad(8, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkflowStageEditableWidgetComponent_1)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "taskBoxInner drag-placeholder"], ["dndPlaceholderRef", ""]], null, null, null, null, null)), i1.ɵdid(12, 16384, [[3, 4]], 0, i3.DndPlaceholderRefDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, null, [" Drag Here "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-edit-workflow-stage", [], null, [[null, "updated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("updated" === en)) {
        var pd_0 = (_co.updated($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_WorkflowEditStageComponent_0, i4.RenderType_WorkflowEditStageComponent)), i1.ɵdid(15, 704512, [[1, 4], ["editModal", 4]], 0, i5.WorkflowEditStageComponent, [i6.NgbModal, i7.FormBuilder, i8.MessagingService, i9.OperationCodeService], { categories: [0, "categories"], subCategories: [1, "subCategories"] }, { updated: "updated" }), (_l()(), i1.ɵeld(16, 0, null, null, 1, "app-workflow-stage-delete-confirmation", [], null, [[null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("delete" === en)) {
        var pd_0 = (_co.delete($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_WorkflowStageDeleteConfirmationComponent_0, i10.RenderType_WorkflowStageDeleteConfirmationComponent)), i1.ɵdid(17, 180224, [[2, 4], ["deleteConfirmationModal", 4]], 0, i11.WorkflowStageDeleteConfirmationComponent, [i6.NgbModal, i7.FormBuilder], null, { delete: "delete" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "taskBox"; var currVal_1 = _ck(_v, 5, 0, _co.isAllCategoriesDisabled, _co.hasMandatoryOPCodes); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 8, 0, "category"); var currVal_3 = !_co.isAllCategoryActive; _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = _co.workflowStageModel.workflowStageCategories; _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.categories; var currVal_6 = _co.subCategories; _ck(_v, 15, 0, currVal_5, currVal_6); }, null); }
export function View_WorkflowStageEditableWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workflow-stage-editable-widget", [], null, null, null, View_WorkflowStageEditableWidgetComponent_0, RenderType_WorkflowStageEditableWidgetComponent)), i1.ɵdid(1, 573440, null, 0, i12.WorkflowStageEditableWidgetComponent, [], null, null)], null, null); }
var WorkflowStageEditableWidgetComponentNgFactory = i1.ɵccf("app-workflow-stage-editable-widget", i12.WorkflowStageEditableWidgetComponent, View_WorkflowStageEditableWidgetComponent_Host_0, { categories: "categories", subCategories: "subCategories", workflowStageModel: "workflowStageModel" }, { deleteWorkflowStage: "deleteWorkflowStage", updateWorkflowStage: "updateWorkflowStage" }, []);
export { WorkflowStageEditableWidgetComponentNgFactory as WorkflowStageEditableWidgetComponentNgFactory };
