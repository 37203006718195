<section class="compnyDtlPSec" *blockUI="'container-blockui'">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <a [routerLink]="['/manage/user']" class="backtolist"><em class="fa fa-long-arrow-left"
                        aria-hidden="true"></em> Back to list</a>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-12 col-lg-8 col-xl-8 offset-xl-2 offset-lg-2">
                <div class="compnydtlwrp">
                    <div class="compnydtlH">
                        <div class="row">
                            <div class="col-8 col-md-8">
                                <h2>User Detail</h2>
                            </div>
                            <div class="col-4 col-md-4">
                                <div class="form-group">
                                    <div ngbDropdown class="actionWrp">
                                        <button class="btn btn-red" id="dropdownBasic1"
                                            ngbDropdownToggle>Actions</button>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                            <ul>
                                                <li>
                                                    <a ngbDropdownItem href="javascript:void(0);" (click)="goToEdit()">
                                                        <em class="fa fa-edit" aria-hidden="true"></em>
                                                        Edit
                                                    </a>
                                                </li>
                                                <li>
                                                    <a ngbDropdownItem href="javascript:void(0);"
                                                        (click)="openStatusChangeConfirmation()">
                                                        <em class="fa fa-times-circle-o" aria-hidden="true"></em>
                                                        {{model.isActive ? "Deactivate" : "Activate" }}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a ngbDropdownItem href="javascript:void(0);"
                                                        (click)="openResetPasswordOtherUserConfirmation()">
                                                        <em class="fa fa-key" aria-hidden="true"></em>
                                                        Reset Password
                                                    </a>
                                                </li>
                                                <li><a ngbDropdownItem href="javascript:void(0);"
                                                        (click)="openDeleteConfirmation()">
                                                        <em class="fa fa-trash-o" aria-hidden="true"></em>
                                                        Delete</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="gnrlformDtl">
                        <ul class="cmpnyDtlList">
                            <!-- <li><span>Id</span> <span>:</span> <span>{{model.userId}}</span></li> -->
                            <li><span>Name</span> <span>:</span> <span>{{model.name}}</span></li>
                            <li><span>User Name</span> <span>:</span> <span>{{model.userName}}</span></li>
                            <li><span>Email Address</span> <span>:</span> <span>{{model.email}}</span></li>
                            <li><span>Phone Number</span> <span>:</span> <span>{{model.phoneCode}} {{model.phoneNumber}}</span></li>
                            <li><span>Notification</span> <span>:</span>
                                <span [innerHTML]="getNotificationText()"></span>
                            </li>
                            <li><span>Role Type</span> <span>:</span> <span>{{roleType}}</span></li>
                            <li *ngIf="model.applicationRoleType == ApplicationRoleType.Partner"><span>Managing Company</span>
                                <span>:</span> <span>{{companyNames}}</span>
                            </li>
                            <li *ngIf="model.applicationRoleType == ApplicationRoleType.Store">
                                <span>Auto Group</span> <span>:</span>
                                <span>{{model.userStoreAssociations[0].autoGroupName}}</span>
                            </li>
                            <li *ngIf="model.applicationRoleType == ApplicationRoleType.Store">
                                <span>Stores</span> <span>:</span> <span>{{storeNames}}</span>
                            </li>
                            <li *ngIf="model.applicationRoleType == ApplicationRoleType.Vendor">
                                <span>Vendor</span> <span>:</span> <span>{{vendorNames}}</span>
                            </li>
                            <li><span>Role</span> <span>:</span> <span>{{roles}}</span></li>                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-user-delete-confirmation #deleteConfirmationModal (delete)="delete($event)"> </app-user-delete-confirmation>
<app-user-status-change-confirmation #statusChangeConfirmationModal (toggle)="toggle($event)">
</app-user-status-change-confirmation>
<app-reset-other-user-password-confirmation #resetPasswordOtherUserConfirmationModal (reset)="resetPassword($event)">
</app-reset-other-user-password-confirmation>