var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI } from 'ng-block-ui';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { AppUtils } from 'src/app/helpers';
import { CompanyDeleteModel, CompanyListItemModel, CompanyStatusToggleModel } from 'src/app/models';
import { CompanyService, FilterStateService, MessagingService } from 'src/app/services';
import { environment } from 'src/environments/environment';
export class CompanyManageComponent {
    constructor(router, filterStateService, companyService, messagingService, formBuilder, appUtils) {
        this.router = router;
        this.filterStateService = filterStateService;
        this.companyService = companyService;
        this.messagingService = messagingService;
        this.formBuilder = formBuilder;
        this.appUtils = appUtils;
        this.dtOptions = {};
        this.rowIndex = 0;
        this.filterOptions = this.appUtils.getFilterOperationOptions();
        this.statusOptions = this.appUtils.getStatusOptions();
    }
    ngOnInit() {
        this.frm = this.formBuilder.group({
            companyName: [''],
            filterOption: [1],
            statusOption: [2] //Active
        });
        //Data Table Example.
        const self = this;
        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter...',
                paginate: {
                    first: '<em class="fa fa-angle-double-left">',
                    last: '<em class="fa fa-angle-double-right">',
                    previous: '<em class="fa fa-angle-left">',
                    next: '<em class="fa fa-angle-right">'
                }
            },
            search: { search: null },
            searching: false,
            displayStart: self.rowIndex,
            paging: false,
            pagingType: 'full_numbers',
            pageLength: environment.paging.defaultPageSize,
            lengthMenu: environment.paging.lengthMenu,
            order: [[0, 'asc']],
            info: false,
            stateSave: true,
            scrollY: (window.innerHeight - 70 - 45) + "px",
            scrollCollapse: true,
            ajax: (dataTablesParameters, callback) => {
                self.updateFilterModel();
                dataTablesParameters.name = self.frm.controls.companyName.value;
                dataTablesParameters.filterBy = Number(self.frm.controls.filterOption.value);
                dataTablesParameters.status = self.statusOptions.find(x => x.id == self.frm.controls.statusOption.value).value;
                self.blockUI.start();
                self.companyService.query(dataTablesParameters)
                    .subscribe(resp => {
                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: resp.data
                    });
                    self.blockUI.stop();
                }, error => {
                    self.messagingService.ProcessErrorResponse(error);
                    self.blockUI.stop();
                });
            },
            columns: [
                {
                    data: 'name',
                    title: 'Managing Company Name',
                    width: '30%',
                    render: function (data, type, row) {
                        return `<span class='link' action-type='link-detail'>${row.name}</a>`;
                    }
                },
                {
                    data: 'accountMangerName',
                    title: 'Account Manager Name',
                    width: '20%'
                },
                {
                    data: 'accountMangerEmail',
                    title: 'Account Manager Email',
                    width: '20%'
                },
                {
                    data: 'phoneNumber',
                    title: 'Phone Number',
                    width: '10%',
                    render: function (data, type, row) {
                        return `<span>${row.phoneCode} ${row.phoneNumber}</span>`;
                    }
                },
                {
                    data: 'isActive',
                    title: 'Status',
                    width: '5%',
                    render: function (isActive) {
                        return isActive
                            ? '<span class="badge badge-success">Active</span>'
                            : '<span class="badge badge-danger">Inactive</span>';
                    }
                },
                {
                    data: null,
                    title: 'Action',
                    width: '20%',
                    orderable: false,
                    className: 'text-center',
                    visible: true,
                    render: function (data, type, row) {
                        //Render Activate or Deactivate Button
                        let statusHtml = row.isActive ?
                            `<button title='Deactivate' action-type='toggle-status'><em class="fa fa-ban" aria-hidden="true"></em></button>` :
                            `<button title='Activate' action-type='toggle-status'><em class="fa fa-check" aria-hidden="true"></em></button>`;
                        return `
                        <button title='View Detail' action-type='view'><em class="fa fa-eye" aria-hidden="true"></em></button>
                        ${statusHtml}
                        <button title='Edit' action-type='edit'><em class="fa fa-edit" aria-hidden="true"></em></button>
                        <button title='Delete' action-type='delete'><em class="fa fa-trash" aria-hidden="true"></em></button>
                        `;
                    }
                }
            ],
            rowCallback: function (row, data, dataIndex) {
                const _row = $(row);
                _row.find('[action-type="toggle-status"]')
                    .off('click')
                    .on('click', function () {
                    self.openStatusChangeConfirmation(data, dataIndex);
                    //dropdownTrigger.trigger('click');
                });
                _row.find('[action-type="edit"]')
                    .off('click')
                    .on('click', function () {
                    self.router.navigate(['/edit/company', data.id]);
                });
                _row.find('[action-type="delete"]')
                    .off('click')
                    .on('click', function () {
                    self.openDeleteConfirmation(data, dataIndex);
                    //dropdownTrigger.trigger('click');
                });
                _row.find('[action-type="link-detail"],[action-type="view"]')
                    .off('click')
                    .on('click', function () {
                    self.router.navigate(['/company/detail', data.id]);
                });
            }
        };
    }
    ngAfterViewInit() {
        this.updateFormFromFilterModel();
        this.datatableElement.dtInstance.then((dtInstance) => this.dtInstance = dtInstance);
        $(document).on('mouseup.custom', function (e) {
            if ($(e.target).closest(".dropdown-menu.action-dropdown").length === 0) {
                $(".dropdown-menu.action-dropdown").hide();
            }
        });
    }
    updateFormFromFilterModel() {
        const tempFilterModel = this.filterStateService.getFilterModel(FilterPageType.companyManage);
        if (tempFilterModel && tempFilterModel.isActive) {
            this.frm.controls.companyName.setValue(tempFilterModel.filterKey);
            this.frm.controls.filterOption.setValue(tempFilterModel.filterOption);
            this.frm.controls.statusOption.setValue(tempFilterModel.statusOption);
        }
    }
    updateFilterModel() {
        const filterModel = this.filterStateService.getFilterModel(FilterPageType.companyManage);
        filterModel.page = FilterPageType.companyManage;
        filterModel.filterKey = this.frm.controls.companyName.value;
        filterModel.filterOption = this.frm.controls.filterOption.value;
        filterModel.statusOption = this.frm.controls.statusOption.value;
        filterModel.isActive = false;
    }
    ngOnDestroy() {
        $(document).off('mouseup.custom');
    }
    openDeleteConfirmation(company, dataIndex) {
        this.deleteConfirmationModal.openModal(company);
    }
    delete(companyDeleteModel) {
        this.blockUI.start();
        this.companyService.delete(companyDeleteModel).subscribe(() => {
            setTimeout(() => {
                this.dtInstance.ajax.reload(null, false);
            });
            this.messagingService.success(`Managing Company has been deleted successfully.`);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    openStatusChangeConfirmation(company, dataIndex) {
        this.statusChangeConfirmationModal.openModal(company);
    }
    toggle(companyStatusToggleModel) {
        this.blockUI.start();
        this.companyService.toggleStatus(companyStatusToggleModel).subscribe(() => {
            setTimeout(() => {
                this.dtInstance.ajax.reload(null, false);
            });
            this.messagingService.success(`Managing Company has been ${(companyStatusToggleModel.isActive ? 'deactivated' : 'activated')} successfully.`);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    applyFilter() {
        this.dtInstance.ajax.reload();
    }
}
__decorate([
    BlockUI('container-blockui-manage'),
    __metadata("design:type", Object)
], CompanyManageComponent.prototype, "blockUI", void 0);
