import { EventEmitter } from "@angular/core";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
export class VehicleQueueSignalService {
    constructor() {
        this.connectionEstablished = new EventEmitter();
        this.vehicleQueueUpdated = new EventEmitter();
        this.init();
    }
    init() {
        this._hubConnection = new HubConnectionBuilder()
            .withUrl(`${environment.signalRUrl}`)
            .withAutomaticReconnect()
            .build();
        this._hubConnection
            .start()
            .then(() => {
            this.isConnectionEstablished = true;
            this.connectionEstablished.emit(true);
        })
            .catch(err => {
            console.log('Error while establishing connection, retrying...', err);
        });
        this._hubConnection.on('VehicleUpdated', (data) => {
            this.vehicleQueueUpdated.emit(data);
        });
    }
    associateStore(oldStoreId, newStoreId) {
        this._hubConnection.invoke("AssociateStore", {
            oldStoreId: oldStoreId,
            currentStoreId: newStoreId
        }).catch(function (err) {
            return console.error(err.toString());
        });
    }
}
VehicleQueueSignalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VehicleQueueSignalService_Factory() { return new VehicleQueueSignalService(); }, token: VehicleQueueSignalService, providedIn: "root" });
