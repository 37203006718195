export class StoreDetailModel{
    public id:number;
    public name:string;
    public autoGroupName:string;
    public streetAddress:string;
    public blockAddress:string;
    public city:string;
    public state:string;
    public country:string;
    public phoneCode: string;
    public phoneNumber: string;
    public zipCode:string;
    public vAutoId:string;
    public invocingEmail:string;
    public invoicingStructure:number;
    public invoicingPreference:number;
    public companyId:number;
    public companyName:number;
    public isActive:boolean;
    
    public categoryIds:number[];
    public categoryNames: Array<string>;

    public subCategoryIds:number[];
    public subCategoryNames: Array<string>;
}