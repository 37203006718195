import { IVLSubCategorySelectListModel, IVLOperationCodeSelectListModel } from "..";

export class IVLCategorySelectListModel {
    public id: number;
    public name: string;
    public code: string;
    public colorCode: string;
    public subCategories:Array<IVLSubCategorySelectListModel>;
    public operationCodes:Array<IVLOperationCodeSelectListModel>;
    constructor(){
        this.subCategories = new Array<IVLSubCategorySelectListModel>();
        this.operationCodes = new Array<IVLOperationCodeSelectListModel>();
    }
}