import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CheckInTypeService {
    constructor(http) {
        this.http = http;
    }
    get(id) {
        return this.http.get(`${environment.apiBaseUrl}/check-in-type/${id}`);
    }
    add(model) {
        return this.http.post(`${environment.apiBaseUrl}/check-in-type`, model);
    }
    update(model) {
        return this.http.put(`${environment.apiBaseUrl}/check-in-type`, model);
    }
    delete(id) {
        return this.http.delete(`${environment.apiBaseUrl}/check-in-type/${id}`);
    }
    toggleStatus(id) {
        return this.http.patch(`${environment.apiBaseUrl}/check-in-type/toggle-status/${id}`, null);
    }
    query(dataTablesParameters) {
        return this.http.post(`${environment.apiBaseUrl}/check-in-type/query`, dataTablesParameters, {});
    }
    selectList() {
        return this.http.get(`${environment.apiBaseUrl}/check-in-type/select-list`);
    }
    checkInTypeSelectList() {
        return this.http.get(`${environment.apiBaseUrl}/check-in-type/select-list-extended`);
    }
    getCheckInTypeExcludingExistingWorkflowsforStore(storeId) {
        return this.http.get(`${environment.apiBaseUrl}/check-in-type/select-list/exclude-created-workflow/store/${storeId}`);
    }
    getFilteredCheckInTypes(companyId, storeId) {
        return this.http.get(`${environment.apiBaseUrl}/check-in-type/${companyId}/${storeId}/select-list`);
    }
}
CheckInTypeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CheckInTypeService_Factory() { return new CheckInTypeService(i0.ɵɵinject(i1.HttpClient)); }, token: CheckInTypeService, providedIn: "root" });
