import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FilterStateService, MessagingService, RoleService } from 'src/app/services';
import { RoleEditModel, SelectListModel } from 'src/app/models';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { ApplicationRoleType } from 'src/app/enums';
import { forkJoin } from 'rxjs';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';

@Component({
	selector: 'app-role-edit',
	templateUrl: './edit.role.component.html',
	styleUrls: ['./edit.role.component.css']
})

export class RoleEditComponent {
	roleFrm: FormGroup;
	submitted: boolean = false;
	model: RoleEditModel;
	applicationRoleTypes: Array<SelectListModel>;

	@BlockUI('container-blockui-edit-role') blockUI: NgBlockUI;	

	constructor(private formBuilder: FormBuilder,
		private router: Router,
		private activeRoute: ActivatedRoute,
		private messagingService: MessagingService,
		private roleService: RoleService,
		private stringLengthConstants: StringLengthConstants,
		private appUtils: AppUtils,
		private filterStateService: FilterStateService
	) {
		filterStateService.changeFilterModelStatues(FilterPageType.roleManage, true);
		this.model = new RoleEditModel();
		this.activeRoute.params.subscribe(params => this.model.id = params['id']);
		this.applicationRoleTypes = this.appUtils.getApplicationRoleTypes();
	}

	ngOnInit() {

		this.roleFrm = this.formBuilder.group({
			id: this.model.id,
			roleName: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
			permissions: this.formBuilder.array([]),
			applicationRoleType: [ApplicationRoleType.System]
		});

		this.loadData();
	}

	private loadData() {
		this.blockUI.start();
		forkJoin([
			this.roleService.getAllPermissions(),
			this.roleService.get(this.model.id)
		]).subscribe(([
			permissions,
			roleEditModel
		]) => {		

			this.model = roleEditModel;
			this.roleFrm.controls.roleName.setValue(this.model.name);
			this.roleFrm.controls.applicationRoleType.setValue(this.model.applicationRoleType);

			const fa: FormArray = this.roleFrm.get('permissions') as FormArray;
			fa.clear();
			permissions.forEach(p => {
				fa.push(this.formBuilder.group({
					permission: [p.permission],
					name: [p.name],
					description: [p.description],
					groupName: [p.groupName],
					isSelected: [this.model.permissions.includes(p.permission)]
				}));
			});
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
		}, () => {
			this.blockUI.stop();
		});
	}

	onSubmit() {
		this.submitted = true;
		if (this.roleFrm.invalid)
			return;

		this.model.name = this.roleFrm.controls.roleName.value;
		this.model.permissions = this.roleFrm.value.permissions.filter(x => x.isSelected).map(x => x.permission);
		this.model.applicationRoleType = Number(this.roleFrm.controls.applicationRoleType.value);
		this.blockUI.start();
		this.roleService.update(this.model).subscribe(() => {
			setTimeout(() => {
				this.router.navigate(['/manage/role']);
			}, 10);
			setTimeout(() => {
				this.messagingService.success('Role has been updated successfully.');
			}, 300);
			this.blockUI.stop();
		}, (error) => {
			this.messagingService.ProcessErrorResponse(error);
			this.blockUI.stop();
		});
	}

	onCancel() {
        this.router.navigate(['/manage/role']);
    }
}
