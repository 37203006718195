import { Injectable } from "@angular/core";
import { AbstractControl, FormControl, ValidatorFn } from "@angular/forms";
import { DateTime } from "luxon";
import { Observable, fromEvent } from "rxjs";
import { pluck } from "rxjs/operators";
import { VehicleLayoutType, NotificationType, ApplicationRoleType, VehicleAgeType, ApprovalStatus, VehicleType } from "../enums"
import { SelectListModel } from "../models";

@Injectable()
export class AppUtils {

    public getFormattedDateTime(date: string) {
        return date ? DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : '';
    }

    public getFileExtension(filename: string) {
        return filename.indexOf('.') !== -1 ? filename.split('.').pop() : null;
    }

    public sort(items: any[], propertyName: string) {
        if (!items) {
            return;
        }

        items.sort((item1, item2) => {
            const text1 = item1[propertyName].toUpperCase();
            const text2 = item2[propertyName].toUpperCase();
            return (text1 < text2)
                ? -1
                : (text1 > text2) ? 1 : 0;
        });
    }

    public imageToBase64(fileReader: FileReader, fileToRead: File): Observable<string> {
        fileReader.readAsDataURL(fileToRead);
        return fromEvent(fileReader, 'load').pipe(pluck('currentTarget', 'result'));
    }

    public getFilterOperationOptions() {
        return [
            { id: 1, name: 'Contains' },
            { id: 2, name: 'Starts With' },
            { id: 3, name: 'Ends With' }
        ];
    }

    public getStatusOptions() {
        return [
            { id: 1, name: 'Both', value: null },
            { id: 2, name: 'Active', value: true },
            { id: 3, name: 'Inactive', value: false }
        ];
    }

    public getDeprecatedOptions() {
        return [
            { id: 1, name: 'Both', value: null },
            { id: 2, name: 'Yes', value: true },
            { id: 3, name: 'No', value: false }
        ];
    }

    public getApprovalStatusTypeOptions(): Array<SelectListModel> {
        return [
            new SelectListModel(ApprovalStatus.Accepted, 'Y'),
            new SelectListModel(ApprovalStatus.Rejected, 'N'),
            new SelectListModel(ApprovalStatus.Question, '?')
        ];
    }


    public getApplicationRoleTypeOptions(): Array<SelectListModel> {
        return [
            new SelectListModel(ApplicationRoleType.None, 'All'),
            new SelectListModel(ApplicationRoleType.System, 'System'),
            new SelectListModel(ApplicationRoleType.Partner, 'Partner'),
            new SelectListModel(ApplicationRoleType.Store, 'Store'),
            new SelectListModel(ApplicationRoleType.Vendor, 'Vendor')
        ];
    }

    public getApplicationRoleTypes(): Array<SelectListModel> {
        return [
            new SelectListModel(ApplicationRoleType.System, 'System'),
            new SelectListModel(ApplicationRoleType.Partner, 'Partner'),
            new SelectListModel(ApplicationRoleType.Store, 'Store'),
            new SelectListModel(ApplicationRoleType.Vendor, 'Vendor')
        ];
    }

    public getVehicleLayoutTypeOptions(): Array<SelectListModel> {
        return [
            new SelectListModel(VehicleLayoutType.Exterior, 'Exterior'),
            new SelectListModel(VehicleLayoutType.Interior, 'Interior'),
        ];
    }

    public validatePhoneNumber(): ValidatorFn {
        const rex = new RegExp('^[0-9]{3}[0-9]{3}[0-9]{4}$', 'g');
        const validator: ValidatorFn = (formControl: FormControl) => {
            const isValidated = rex.test(formControl.value);
            return isValidated ? null : { pattern: true };
        };
        return validator;
    }

    public getNotificationText(notificationType: NotificationType): string {
        switch (notificationType) {
            case NotificationType.All:
                return 'Both';
            case NotificationType.Email:
                return 'Email';
            case NotificationType.SMS:
                return 'SMS';
            default:
                return '';
        }
    }

    public generateOperationCode(categoryCode: string,
        subCategoryCode: string,
        carLayoutPanelCode: string,
        carLayoutLocationCode: string,
        carLayoutSide: string): string {

        const deliminator = '_';
        let names: Array<string> = new Array<string>();

        names.push(categoryCode || '#');
        names.push(subCategoryCode || '#');
        names.push(carLayoutPanelCode || '#');
        names.push(carLayoutLocationCode || '#');
        names.push(carLayoutSide || '#');

        return names.join(deliminator);
    }

    public getPresetColors(): Array<string> {
        return [
            "#f44336",
            "#e91e63",
            '#F78DA7',
            '#ff00ff',
            "#9c27b0",
            "#673ab7",
            "#3f51b5",
            "#2196f3",
            "#03a9f4",
            "#00bcd4",
            "#009688",
            "#4caf50",
            "#8bc34a",
            "#cddc39",
            "#ffeb3b",
            "#ffc107",
            "#ff9800",
            "#ff5722",
            "#795548",
            "#607d8b",
            '#8ED1FC',
            '#ABB8C3'
        ];
    }

    public getBasicColors(): Array<string> {
        return [
            "Aqua",
            "Beige",
            "Black",
            "Blue",
            "Brown",
            "Fuchsia",
            "Gray",
            "Green",
            "Lime",
            "Maroon",
            "Orange",
            "Navy",
            "Olive",
            "Purple",
            "Red",
            "Silver",
            "Teal",
            "White",
            "Yellow"
        ];
    }

    public getVehicleAgeTypes(): Array<SelectListModel> {
        return [
            new SelectListModel(VehicleAgeType.Other, 'Other'),
            new SelectListModel(VehicleAgeType.New, 'New'),
            new SelectListModel(VehicleAgeType.Used, 'Used')
        ];
    }

    public getVehicleTypes(): Array<SelectListModel> {
        return [
            new SelectListModel(VehicleType.None, 'Other'),
            new SelectListModel(VehicleType.Truck, 'Truck'),
            new SelectListModel(VehicleType.Car, 'Car'),
            new SelectListModel(VehicleType.Suv, 'SUV')
        ];
    }

    public getVehicleAgeName(vehicleAgeType: any) {
        return this.getVehicleAgeTypes().find(x => x.id == vehicleAgeType).name;
    }

    public getVehicleBodyName(vehicleBodyType: any) {
        return this.getVehicleTypes().find(x => x.id == vehicleBodyType).name;
    }

    public replaceHashWithUnderscore(value: string) {
        return value.replace(/#/g, '_');
    }

    public removeError(control: AbstractControl, error: string) {
        const err = control.errors; // get control errors
        if (err) {
            delete err[error]; // delete your own error
            if (!Object.keys(err).length) { // if no errors left
                control.setErrors(null); // set control errors to null making it VALID
            } else {
                control.setErrors(err); // controls got other errors so set them back
            }
        }
    }


    public addError(control: AbstractControl, error: string) {
        let errorToSet = {};
        errorToSet[error] = true;
        control.setErrors({ ...control.errors, ...errorToSet });
    }
}
