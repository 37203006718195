var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI } from 'ng-block-ui';
import { CompanyStatusToggleModel } from 'src/app/models';
import { CompanyService, MessagingService } from 'src/app/services';
export class CompanyStatusChangeConfirmationComponent {
    constructor(modalService, companyService, formBuilder, messagingService) {
        this.modalService = modalService;
        this.companyService = companyService;
        this.formBuilder = formBuilder;
        this.messagingService = messagingService;
        this.toggle = new EventEmitter();
    }
    openModal(company) {
        this.form = this.formBuilder.group({
            companyId: ['']
        });
        this.company = company;
        this.companies = new Array();
        //Load Company only if company is active
        if (this.company.isActive) {
            this.form.get('companyId').setValidators([Validators.required]);
            this.loadCompanies();
        }
        this.modalReference = this.modalService.open(this.confirmationModal, { centered: true });
    }
    onSubmit() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        let model = new CompanyStatusToggleModel();
        model.id = this.company.id;
        model.isActive = this.company.isActive;
        if (this.company.isActive) {
            model.reassignedCompanyId = Number(this.form.controls.companyId.value);
        }
        this.form.reset();
        this.submitted = false;
        setTimeout(() => {
            this.toggle.emit(model);
        });
        this.onCancel();
    }
    onCancel() {
        if (!this.modalReference) {
            return;
        }
        this.modalReference.close();
    }
    loadCompanies() {
        this.blockUI.start();
        this.companyService.getCompanies().subscribe((data) => {
            this.companies = data.filter(x => x.id !== this.company.id);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    ngOnDestroy() {
        this.onCancel();
    }
}
__decorate([
    BlockUI('container-blockui-confirmation'),
    __metadata("design:type", Object)
], CompanyStatusChangeConfirmationComponent.prototype, "blockUI", void 0);
