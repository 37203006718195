import { IVLOperationCodeSelectListModel } from "..";

export class IVLSubCategorySelectListModel {
    public id: number;
    public name: string;
    public code: string;
    public colorCode: string;
    public categoryId: Nullable<number>;
    public sequence: number;
    public operationCodes:Array<IVLOperationCodeSelectListModel>;

    //It is not part of response. It is used only for reverse highlighting
    public isHighlight:boolean;

    constructor(){
        this.operationCodes = new Array<IVLOperationCodeSelectListModel>();
    }
}