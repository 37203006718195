<section class="gnrlformSec" *blockUI="'container-blockui'">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-12 col-lg-8 col-xl-8 offset-xl-2 offset-lg-2">
				<div class="gnrlformbox">
					<div class="compnydtlH">
						<div class="row">
							<div class="col-8 col-md-8">
								<h2>My Profile</h2>
							</div>
							<div class="col-4 col-md-4 text-right">
								<button class="btn btn-red" id="dropdownBasic1"
									[routerLink]="['/my/profile/edit']">Edit</button>
							</div>
						</div>
					</div>
					<div class="gnrlformDtl">
						<ul>
							<!-- <li>
								<div class="profilelabel"><label>Id</label></div>
								<div class="profileValue">
									<span>{{model.userId}}</span>
								</div>
							</li> -->
							<li>
								<div class="profilelabel"><label>Name</label></div>
								<div class="profileValue">
									<span>{{model.name}}</span>
								</div>
							</li>
							<li>
								<div class="profilelabel"><label>User Name</label></div>
								<div class="profileValue"><span>{{model.userName}}</span> </div>
							</li>
							<li>
								<div class="profilelabel"><label>Email Address</label></div>
								<div class="profileValue">
									<span>{{model.email}}</span>
								</div>
							</li>
							<li>
								<div class="profilelabel"><label>Phone Number</label></div>
								<div class="profileValue">
									<span>{{model.phoneCode}} {{model.phoneNumber}}</span>
								</div>
							</li>
							<li>
								<div class="profilelabel"><label>Notification</label></div>
								<div class="profileValue">
									<span [innerHTML]="getNotificationText()"></span>
								</div>
							</li>
							<li>
								<div class="profilelabel"><label>Role</label></div>
								<div class="profileValue"><span>{{roles}}</span></div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>