<section class="datatableSer">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <h1>Manage OP Code</h1>
      </div>
      <div class="col-12 col-md-6 text-center text-md-right">
        <button class="btn btn-red" type="button" [routerLink]="['/add/op-code']">Add OP Code</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="tableWrp">
          <div class="filterWrp">
            <form [formGroup]="frmSearch">
              <div class="row justify-content-end">
                <div class="col-12 col-md-9">
                  <div class="row">
                    <div class="col-12 col-sm-4">
                      <label>Search</label>
                      <input class="form-control" type="text" formControlName="opCodeName"
                        (keydown.enter)="applyFilter()" />
                    </div>
                    <div class="col-12 col-sm-4">
                      <label>Operation</label>
                      <select class="form-control" formControlName="filterOption">
                        <option *ngFor="let item of filterOptions" [value]="item.id">{{item.name}}
                        </option>
                      </select>
                    </div>
                    <div class="col-12 col-sm-4">
                      <label>Status</label>
                      <select class="form-control" formControlName="statusOption">
                        <option *ngFor="let item of statusOptions" [value]="item.id">{{item.name}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div *blockUI="'container-blockui'" class="bluetableWrp table-responsive">
            <!-- <app-infinite-scroll [scrollOffset]="2500" [scrollDelay]="1000"
              (scrolled)="handleScroll($event)" [class.full]="full" [class.part]="!full">
              <table datatable [dtOptions]="dtOptions" data-page="2" class="table-hover break-all table w-100"></table>
            </app-infinite-scroll> -->
            <table datatable [dtOptions]="dtOptions" data-page="2" class="table-hover break-all table w-100"></table>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>

<app-op-code-delete-confirmation #deleteConfirmationModal (delete)="delete($event)">
</app-op-code-delete-confirmation>
<app-op-code-status-change-confirmation #statusChangeConfirmationModal (toggle)="toggle($event)">
</app-op-code-status-change-confirmation>
