import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { DataTableResponseModel,CategoryDetailModel, OperationCodeSelectListModel, WorkflowImportModel, WorkflowModel, WorkflowSelectListModel } from "../models";
import * as uuid from 'uuid';

@Injectable({
    providedIn: 'root'
})
export class WorkflowService {
    constructor(private http: HttpClient) {
    }

    public add(model: WorkflowModel): Observable<WorkflowModel> {
        return this.http.post<WorkflowModel>(`${environment.apiBaseUrl}/workflow/add`, model);
    }

    public edit(model: WorkflowModel): Observable<WorkflowModel> {
        return this.http.put<WorkflowModel>(`${environment.apiBaseUrl}/workflow/edit`, model);
    }

    public get(id: number): Observable<WorkflowModel> {
        return this.http.get<WorkflowModel>(`${environment.apiBaseUrl}/workflow/${id}`).pipe(
            tap((model: WorkflowModel) => {
                model.uniqueIdentifer = uuid.v4();
                model.workflowDynamicSequences.forEach(workflowDynamicSequence => {
                    workflowDynamicSequence.uniqueIdentifer = uuid.v4();
                    workflowDynamicSequence.workflowStages.forEach(workflowStage => {
                        workflowStage.uniqueIdentifer = uuid.v4();
                        workflowStage.workflowStageCategories.forEach(x => {
                            x.uniqueIdentifer = uuid.v4();
                            x.operationCodes.forEach(y => {
                                y.uniqueIdentifer = uuid.v4();
                            });
                        });
                    });
                });
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    public toggleStatus(id: number) {
        return this.http.patch(`${environment.apiBaseUrl}/workflow/toggle-status/${id}`, null);
    }

    public delete(id: number) {
        return this.http.delete(`${environment.apiBaseUrl}/workflow/${id}`);
    }

    public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
        return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/workflow/query`, dataTablesParameters);
    }

    public selectList(): Observable<Array<WorkflowSelectListModel>> {
        return this.http.get<Array<WorkflowSelectListModel>>(`${environment.apiBaseUrl}/workflow/select-list`);
    }

    public getStoreSelectList(storeId: number): Observable<Array<WorkflowSelectListModel>> {
        return this.http.get<Array<WorkflowSelectListModel>>(`${environment.apiBaseUrl}/workflow/store/${storeId}/select-list`);
    }

    public getMandatoryOperationCode(workflowId: number,categoryName:string): Observable<Array<OperationCodeSelectListModel>> {
        return this.http.get<Array<OperationCodeSelectListModel>>(`${environment.apiBaseUrl}/workflow/${workflowId}/mandatory-operation-code?categoryName=${categoryName}`);
    }

    public storeHasWholesaleWorkflow(storeId: number): Observable<boolean> {
        return this.http.get<boolean>(`${environment.apiBaseUrl}/workflow/store/${storeId}/has-wholesale`);
    }
}