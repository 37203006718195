import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Injectable({
    providedIn: 'root'
})
export class MessagingService {
    constructor(private router: Router, private toastr: ToastrService) { }

    public success(message: string) {
        this.toastr.success(message);
    }

    public error(message: string) {
        this.toastr.error(message);
    }

    public warning(message: string) {
        this.toastr.warning(message);
    }

    public ProcessErrorResponse(response: any, customErrorMessage = '') {
        // console.log(response);
        if (response && response.status === 400) {
            if (response.error instanceof Array) {
                response.error.forEach((item) => {
                    this.toastr.error(item);
                });
            } else {
                this.toastr.error(response.error);
            }
        } else if (response && response.status === 404) {
            setTimeout(() => {
                this.router.navigate(['/error/404']);
            }, 100);
        } else if (customErrorMessage === '') {
            this.toastr.error('Internal server error.');
        } else {
            this.toastr.error(customErrorMessage);
        }
    }
}