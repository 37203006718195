
<div class="gnrlformSec">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <a class="backtolist" [routerLink]="['/manage/check-in-type']">
                    <em class="fa fa-long-arrow-left" aria-hidden="true"></em> Back to list
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-12 col-lg-8 col-xl-8 offset-xl-2 offset-lg-2">
                <div class="gnrlformbox" *blockUI="'blockui-container'">
                    <h2>Create Check-In Type</h2>
                    <form class="matrixFrom" [formGroup]="frmCheckInType" (ngSubmit)="onSubmit()">
                        
                        <div class="form-group">
                            <div class="matrixFromLabel">
                                <h5>Name</h5>
                            </div>
                            <div class="matrixFromField">
                                <input type="text" class="form-control" formControlName="name"
                                    [ngClass]="{ 'is-invalid': submitted && frmCheckInType.controls.name.errors }" />
                                <div *ngIf="submitted && frmCheckInType.controls.name.errors" class="invalid-feedback">
                                    <div *ngIf="frmCheckInType.controls.name.errors.required">
                                        Check-In type name is required
                                    </div>
                                    <div *ngIf="frmCheckInType.controls.name.errors.maxlength">
                                        Check-In type name should be max {{frmCheckInType.controls.name.errors.maxlength.requiredLength}}
                                        characters
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="matrixFromLabel">
                                <h5>Description</h5>
                            </div>
                            <div class="matrixFromField">
                                <input type="text" class="form-control" formControlName="description"
                                    [ngClass]="{ 'is-invalid': submitted && frmCheckInType.controls.description.errors }" />
                                <div *ngIf="submitted && frmCheckInType.controls.description.errors" class="invalid-feedback">
                                    <div *ngIf="frmCheckInType.controls.description.errors.maxlength">
                                        Description should be max {{frmCheckInType.controls.description.errors.maxlength.requiredLength}}
                                        characters
                                    </div>
                                </div>
                            </div>
                        </div>                       
                        <div class="btnWrp">
                            <button class="btn btn-red" type="submit">Save</button>
                            <button class="btn btn-tsrp" type="button" [routerLink]="['/manage/check-in-type']">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>