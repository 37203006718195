import { ApprovalStatus, InspectionStatus, UnitOfMeasure, WorkStatus } from "src/app/enums";
import { OperationCodeDetailModel } from "../op-code/op-code.detail.model";

export class VehicleWorkDetailModel {

    public id: number;
    public storeId: number;
    public vehicleQueueId: number;
    public vendorId: number;
    public vendorName: string;

    public partPrice: number;
    public laborPrice: number;
    public cost: number;
    public totalPrice: number;

    public operationCodeId: number;

    public unitOfMeasure: UnitOfMeasure;
    public isReconPackage: boolean;
    public isQuoteRequired: boolean;
    public isApprovalRequired: boolean;
    public isPORequired: boolean;
    public isInspectionRequired: boolean;

    public category: string;

    public addedOn: Date;
    public addedBy: string;
    public addedByUser: string;

    public approvalBy: Date;
    public approvalUser: string;
    public approvalStatus: ApprovalStatus;
    public approvalOn: Nullable<Date>;

    public workerUserId: string;
    public workerUser: string;
    public workStatus: WorkStatus;
    public workStartedOn: Nullable<Date>;
    public workCompletedOn: Nullable<Date>;

    public inspectedBy: string;
    public inspectedByUser: string;
    public inspectionStatus: InspectionStatus;
    public inspectedOn: Nullable<Date>;

    public commentModels: Array<VehicleWorkDetailCommentModel>;
    public operationCodeDetailModel: OperationCodeDetailModel;

    public isMandatory:boolean;
    public coordinateX:number;
    public coordinateY:number;
    constructor() {
        this.operationCodeDetailModel = new OperationCodeDetailModel();
        this.commentModels = new Array<VehicleWorkDetailCommentModel>();
    }
}

export class VehicleWorkDetailCommentModel {
    public commentBy: string;
    public commentByUser: string;
    public comment: string;
    public category: string;
}
