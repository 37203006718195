var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ElementRef, OnDestroy, OnInit } from "@angular/core";
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BlockUI } from "ng-block-ui";
import { forkJoin } from "rxjs";
import { KpiFilterModel } from "src/app/models";
import { ApplicationContextService, KpiBannerService, MessagingService } from "src/app/services";
export class QuoteSavingChartComponent {
    constructor(kpiBannerService, messagingService, applicationContextService) {
        this.kpiBannerService = kpiBannerService;
        this.messagingService = messagingService;
        this.applicationContextService = applicationContextService;
        this.chartData = false;
        this.barChartLabels = ['Series A', 'Series B', 'Series C'];
        this.barChartType = 'bar';
        this.barChartLegend = true;
        this.filterModel = new KpiFilterModel();
        this.subscriptions = new Array();
        const storeSubscription = this.applicationContextService.store$.subscribe((storeSelectListItem) => {
            if (storeSelectListItem) {
                this.filterModel.storeId = storeSelectListItem.id;
            }
            else {
                this.filterModel.storeId = null;
            }
            this.loadData();
        });
        this.subscriptions.push(storeSubscription);
    }
    ngOnInit() {
        this.loadData();
    }
    ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }
    ngAfterViewInit() {
    }
    loadData() {
        this.blockUI.start();
        forkJoin([
            this.kpiBannerService.getQuoteSavingForCurrentMonth(this.filterModel),
            this.kpiBannerService.getQuoteSavingForLastOneMonth(this.filterModel),
            this.kpiBannerService.getQuoteSavingForLastTwoMonth(this.filterModel),
        ]).subscribe((result) => {
            this.barChartData = [{
                    data: [result[2].totalQuoteSaving, result[1].totalQuoteSaving, result[0].totalQuoteSaving], label: '',
                    backgroundColor: ['#e5444d', '#eb6066', '#ef9296'],
                    borderColor: ['#e5444d', '#eb6066', '#ef9296'],
                }];
            const greatestValue = Math.round(Math.max(result[2].totalQuoteSaving, result[1].totalQuoteSaving, result[0].totalQuoteSaving) + 10);
            const calculatedMaxValue = Math.ceil((greatestValue + 1) / 1000) * 1000;
            this.maxValue = Math.max(calculatedMaxValue, 10000);
            this.barChartOptions = {
                responsive: false,
                maintainAspectRatio: true,
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                            gridLines: {
                                offsetGridLines: false
                            }
                        }],
                    yAxes: [{
                            ticks: {
                                stepSize: 2000,
                                beginAtZero: true,
                                min: 0,
                                max: this.maxValue
                            }
                        }]
                },
                plugins: {
                    datalabels: {
                        anchor: 'end',
                        align: 'end',
                    }
                },
            };
            this.chartData = true;
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
}
__decorate([
    BlockUI('container-blockui-quote-saving'),
    __metadata("design:type", Object)
], QuoteSavingChartComponent.prototype, "blockUI", void 0);
