var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { BlockUI } from 'ng-block-ui';
import { AppUtils } from 'src/app/helpers';
import { UserDetailModel } from 'src/app/models';
import { MessagingService, UserService } from 'src/app/services';
export class UserProfileComponent {
    constructor(userService, appUtils, messagingService) {
        this.userService = userService;
        this.appUtils = appUtils;
        this.messagingService = messagingService;
        this.model = new UserDetailModel();
    }
    ngOnInit() {
        this.loadUser();
    }
    loadUser() {
        this.blockUI.start();
        this.userService.getMyProfile().subscribe((data) => {
            this.model = data;
            this.roles = this.model.roles.join(', ');
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    getNotificationText() {
        let arr = new Array();
        if (this.model != null) {
            if (this.model.isEmailNotificationActive) {
                arr.push('Email');
            }
            if (this.model.isSMSNotificationActive) {
                arr.push('SMS');
            }
        }
        return arr.join(',&nbsp;');
    }
}
__decorate([
    BlockUI('container-blockui'),
    __metadata("design:type", Object)
], UserProfileComponent.prototype, "blockUI", void 0);
