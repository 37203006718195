import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "ngx-toastr";
export class MessagingService {
    constructor(router, toastr) {
        this.router = router;
        this.toastr = toastr;
    }
    success(message) {
        this.toastr.success(message);
    }
    error(message) {
        this.toastr.error(message);
    }
    warning(message) {
        this.toastr.warning(message);
    }
    ProcessErrorResponse(response, customErrorMessage = '') {
        // console.log(response);
        if (response && response.status === 400) {
            if (response.error instanceof Array) {
                response.error.forEach((item) => {
                    this.toastr.error(item);
                });
            }
            else {
                this.toastr.error(response.error);
            }
        }
        else if (response && response.status === 404) {
            setTimeout(() => {
                this.router.navigate(['/error/404']);
            }, 100);
        }
        else if (customErrorMessage === '') {
            this.toastr.error('Internal server error.');
        }
        else {
            this.toastr.error(customErrorMessage);
        }
    }
}
MessagingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessagingService_Factory() { return new MessagingService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.ToastrService)); }, token: MessagingService, providedIn: "root" });
