var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI } from 'ng-block-ui';
import { AccountRecoverModel } from 'src/app/models';
import { MessagingService, AuthenticationService } from 'src/app/services';
export class ForgotPasswordComponent {
    constructor(router, messagingService, authenticationService, formBuilder) {
        this.router = router;
        this.messagingService = messagingService;
        this.authenticationService = authenticationService;
        this.formBuilder = formBuilder;
        this.logo = "assets/images/recon-partners-logo.png";
        this.submitted = false;
        this.formSubmitted = false;
        this.model = new AccountRecoverModel();
    }
    ngOnInit() {
        this.frm = this.formBuilder.group({
            email: ['', [Validators.required]],
        });
    }
    onSubmit() {
        this.submitted = true;
        if (this.frm.invalid) {
            return;
        }
        this.model.email = this.frm.controls.email.value;
        this.blockUI.start();
        this.authenticationService.recover(this.model)
            .subscribe(() => {
            this.blockUI.stop();
            //Reset
            this.submitted = false;
            this.formSubmitted = true;
            this.frm.reset();
            this.model = new AccountRecoverModel();
            // setTimeout(() => {
            //   this.router.navigate(['/account/login']);
            // }, 100);
            // setTimeout(() => {
            //   this.messagingService.success(`A temporary verification code and link has been sent to your registered email. Please click on the link
            //    to proceed with the verification process`);
            // }, 500);
        }, error => {
            this.blockUI.stop();
            this.messagingService.ProcessErrorResponse(error);
        });
    }
}
__decorate([
    BlockUI('container-blockui'),
    __metadata("design:type", Object)
], ForgotPasswordComponent.prototype, "blockUI", void 0);
