<section class="gnrlformSec" *blockUI="'container-blockui'">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-12 col-lg-8 col-xl-8 offset-xl-2 offset-lg-2">
				<div class="gnrlformbox">
					<h2>Edit My Profile</h2>
					<div class="gnrlformDtl">
						<form [formGroup]="form" (ngSubmit)="updateUser()">
							<input type="hidden" formControlName="id" />
							<ul>
								<li>
									<div class="profilelabel"><label>Name</label></div>
									<div class="profileValue">
										<div class="form-group">
											<input type="text" class="form-control" placeholder="Name"
												formControlName="name"
												[ngClass]="{ 'is-invalid': submitted && form.controls.name.errors }" />
											<div *ngIf="submitted && form.controls.name.errors"
												class="invalid-feedback">
												<div *ngIf="form.controls.name.errors.required">Name is
													required</div>
												<div *ngIf="form.controls.name.errors.maxlength">
													Name should be max
													{{form.controls.name.errors.maxlength.requiredLength}} characters
												</div>
											</div>
										</div>
									</div>
								</li>
								<li>
									<div class="profilelabel"><label>Email Address</label></div>
									<div class="profileValue">
										<div class="form-group">
											<input type="Email" class="form-control" placeholder="Email Address"
												formControlName="email"
												[ngClass]="{ 'is-invalid': submitted && form.controls.email.errors }" />
											<div *ngIf="submitted && form.controls.email.errors"
												class="invalid-feedback">
												<div *ngIf="form.controls.email.errors.required">Email Address is
													required</div>
												<div *ngIf="form.controls.email.errors.email">Email Address should
													be valid</div>
												<div *ngIf="form.controls.email.errors.maxlength">
													Email Address should be max
													{{form.controls.email.errors.maxlength.requiredLength}}
													characters</div>
											</div>
										</div>
									</div>
								</li>
								<li>
									<div class="profilelabel"><label>Phone Number</label></div>
									<div class="profileValue">
									<div class="row">
										<div class="col-4">
										<select class="form-control hDropDown" formControlName = "phoneCode"
                                    	[ngClass]="{'is-invalid':submitted && form.controls.phoneCode.errors}">
                                        <option *ngFor="let item of countries " [value]="item.phoneCode">{{item.phoneCode}} </option>
                                    	</select>
										</div>									
										<div class="col-8">
											<input #phoneNumber type="text" class="form-control"
												[ngClass]="{ 'is-invalid': submitted && form.controls.phoneNumber.errors }"
												placeholder="Phone Number" formControlName="phoneNumber" />
											<div *ngIf="submitted && form.controls.phoneNumber.errors"
												class="invalid-feedback">
												<div *ngIf="form.controls.phoneNumber.errors.required">Phone Number
													is required</div>
												<div *ngIf="form.controls.phoneNumber.errors.pattern">Phone Number
													should be valid format i.e. +1XXXXXXXXXX</div>
												<div *ngIf="form.controls.phoneNumber.errors.maxlength">
													Phone Number should be max
													{{form.controls.phoneNumber.errors.maxlength.requiredLength}}
													characters
												</div>
											</div>
										</div>
										</div>
									</div>
								</li>
								<li>
									<div class="profilelabel"><label>Notification</label></div>
									<div class="profileValue">
										<div class="form-group">
											<span>
												<input class="form-check-input" id="chkNotificationTypeEmail" type="checkbox"
													formControlName="isEmailNotificationActive">
												<label class="form-check-label" for="chkNotificationTypeEmail">
													Email
												</label>
											</span>
											<span>
												<input class="form-check-input" id="chkNotificationTypeSMS" type="checkbox"
													formControlName="isSMSNotificationActive">
												<label class="form-check-label" for="chkNotificationTypeSMS">
													SMS
												</label>
											</span>   
										</div>
									</div>
								</li>
								<div class="btnWrp">
									<button type="submit" class="btn btn-red">Save</button>
									<button class="btn btn-tsrp" [routerLink]="['/my/profile']">Cancel</button>
								</div>
							</ul>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>