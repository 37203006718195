import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { StringLengthConstants } from 'src/app/helpers';

@Component({
    selector: 'app-vendor-invoice',
    templateUrl: './vendor.invoice.component.html',
    styleUrls: ['./vendor.invoice.component.css']
})
export class VendorInvoiceComponent implements OnDestroy {
    @BlockUI('container-blockui-confirmation') blockUI: NgBlockUI;
    @ViewChild('confirmationModal', { static: false }) confirmationModal: any;
    @Output() invoiceSubmit = new EventEmitter<any>();

    modalReference: NgbModalRef;
    form: FormGroup;
    submitted: boolean;

    constructor(private modalService: NgbModal,
        private formBuilder: FormBuilder,
        private stringLengthConstants: StringLengthConstants) {
    }

    openModal(invoiceNumber: string) {
        this.form = this.formBuilder.group({
            invoiceNumber: [invoiceNumber || '', [RxwebValidators.compose({
                validators: [
                    RxwebValidators.required(),
                    RxwebValidators.maxLength({ value: this.stringLengthConstants.Default }),
                ]
            })]]
        });

        this.modalReference = this.modalService.open(this.confirmationModal, { centered: true });
    }

    onSubmit() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }

        setTimeout(() => {
            this.invoiceSubmit.emit({
                invoiceNumber: this.form.controls.invoiceNumber.value
            });
            this.form.reset();
            this.submitted = false;
        }, 0);

        this.onCancel();
    }

    onCancel() {
        if (!this.modalReference) {
            return;
        }
        this.modalReference.close();
    }

    ngOnDestroy(): void {
        this.onCancel();
    }
}
