import * as uuid from 'uuid';

export class WorkflowModel {
    public uniqueIdentifer: string;
    public id: Nullable<number>;
    public storeId: Nullable<number>;
    public checkInTypeId: number;
    public isActive: boolean;
    public isDeprecated: boolean;
    public parentWorkflowId:number;
    public vehicleQueueId:number;
    public isDefault: boolean;
    public workflowDynamicSequences: Array<WorkflowSequenceModel>;
    constructor() {
        this.workflowDynamicSequences = new Array<WorkflowSequenceModel>();
        this.uniqueIdentifer = uuid.v4();
        this.isDeprecated = true;
    }
}

export class WorkflowSequenceModel {
    public uniqueIdentifer: string;
    public id: Nullable<number>;
    public sequence: number;
    public workflowStages: Array<WorkflowStageModel>;
    constructor() {
        this.workflowStages = new Array<WorkflowStageModel>();
        this.uniqueIdentifer = uuid.v4();
    }
}

export class WorkflowStageModel {
    public uniqueIdentifer: string;
    public id: Nullable<number>;
    public sequence: number;
    public dependencyLocked: boolean;
    public workflowStageCategories: Array<WorkflowStageCategoryModel>;
    constructor() {
        this.workflowStageCategories = new Array<WorkflowStageCategoryModel>();
        this.uniqueIdentifer = uuid.v4();
    }
}

export class WorkflowStageCategoryModel {
    public uniqueIdentifer: string;
    public id: Nullable<number>;
    public sequence: number;
    public categoryId: number;
    public categoryName: string;
    public operationCodes: Array<WorkflowStageCategoryOperationCodeModel>;    
    public subCategoryId: Nullable<number>;
    public subCategoryName: string;
    public isActive: boolean;
    constructor() {
        this.operationCodes = new Array<WorkflowStageCategoryOperationCodeModel>();
        this.uniqueIdentifer = uuid.v4();
    }
}

export class WorkflowStageCategoryOperationCodeModel {
    public uniqueIdentifer: string;
    public workerUserId: string;
    public operationCodeId: number;
    public operationCodeName: string;
    constructor() {
        this.uniqueIdentifer = uuid.v4();
    }
}

export class UpdateVehicleWorkflowModel {
    public vehicleQueueId: number;
    public workflowId: number;
    public vehicleApprovalStageId: number;
    public vehicleApprovalSequenceId : number;
    public status: number;
    public sequence: number;
    public dependencyLocked : boolean;
    public dependencyLockedSequence : Nullable<number>;
    public categoryId : number;
    public subCategoryId : Nullable<number>;
    public workflowStageCategoryId : number;
}
