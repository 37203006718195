import { VehicleLayoutType } from "src/app/enums";

export class IVLOperationCodeSelectListModel {
    public id: number;
    public name: string;
    public code: string;
    public categoryId: number;
    public subCategoryId: Nullable<number>;
    public carLayoutPanelId: Nullable<number>;
    public vehicleLayoutType:Nullable<VehicleLayoutType>;
    public coordinateX:number;
    public coordinateY:number;
}