<ng-template #addNewTask let-modal>
  <div>
    <div class="modal-body">
      <div class="workflowPopupWrp" *blockUI="'container-blockui-work-step'">
        <h2>Manage Category behavior</h2>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <span>
              <input type="checkbox" class="form-control" id="dependencyLocked" formControlName="dependencyLocked" />
              <label for="dependencyLocked">Dependency Locked?</label>
            </span>
          </div>

          <div class="row">
            <div class="col-12 col-sm-6">
              <div class="from-group"><label>Category</label></div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="from-group"><label>Mandatory OP Code</label></div>
            </div>
          </div>

          <ng-container formArrayName="stepCategory">
            <div class="row" *ngFor="let item of form.get('stepCategory').controls; let i = index;">
              <ng-container [formGroupName]="i">
                <div class="col-12"
                  [ngClass]="{ 'col-sm-3': viewModels[i].showTool, 'col-sm-6': !viewModels[i].showTool }">
                  <div class="from-group">
                    <select class="form-control" formControlName="categoryId" placeholder="Category"
                      (change)="onCategoryChange(item.controls.categoryId.value, i)"
                      [ngClass]="{ 'is-invalid': (submitted || item.controls.categoryId.value) && item.controls.categoryId.errors }">
                      <option value="">Select Category</option>
                      <option *ngFor="let category of categorySelectList" [value]="category.id">
                        {{category.name}}
                      </option>
                    </select>
                    <div *ngIf="(submitted || item.controls.categoryId.value) && item.controls.categoryId.errors"
                      class="invalid-feedback">
                      <div *ngIf="item.controls.categoryId.errors.required">Category
                        is required</div>
                      <div *ngIf="item.controls.categoryId.errors.incorrect">Category is not
                        associated store. Please select another category</div>
                    </div>
                  </div>
                </div>
                <div class="col-12"
                  [ngClass]="{ 'col-sm-3': viewModels[i].showTool, 'col-sm-6': !viewModels[i].showTool }"
                  *ngIf="viewModels[i].showTool">
                  <div class="from-group">
                    <select class="form-control" formControlName="subCategoryId" placeholder="Tool"
                      (change)="onSubCategoryChange(item.controls.subCategoryId.value, i)"
                      [ngClass]="{ 'is-invalid': submitted && form.controls.subCategoryId.errors }">
                      <option value="">Select Tool</option>
                      <option *ngFor="let item of viewModels[i].subCategorySelectList" [value]="item.id">
                        {{item.name}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="from-group">
                    <div class="d-flex align-items-center">
                      <div class="slectOpcodeWrp">
                        <ng-multiselect-dropdown [placeholder]="'Select  OP Code'"
                          [data]="viewModels[i].filteredOperationCodeSelectList" [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="viewModels[i].selectedOperationCodeSelectList"
                          [settings]="opCodeDropdownSettings">
                        </ng-multiselect-dropdown>
                      </div>
                      <div class="removeOpCode ml-1">
                        <button type="button" class="stagebtn-sub" (click)="removeStepCategory(i)"
                          *ngIf="viewModels.length > 1">
                          <em class="fa fa-minus-circle" aria-hidden="true"></em></button>

                      </div>
                    </div>

                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>

          <div class="text-right mt-1">
            <button class="stagebtn-add" type="button" (click)="addStepCategory()">
              <em class="fa fa-plus-circle" aria-hidden="true"></em></button>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="fonm-control">
                <div class="btnWrp">
                  <button class="btn btn-red" type="submit">Save</button>
                  <button class="btn btn-tsrp" type="button" (click)="onCancel()">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
