import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { VehicleQueueStageModel } from 'src/app/models';

@Component({
    selector: 'app-queue-timer',
    templateUrl: './queue-timer.component.html',
    styleUrls: ['./queue-timer.component.css']
})
export class QueueTimerComponent implements OnInit, OnDestroy {
    @Input() vehicleQueueStageModel: VehicleQueueStageModel;

    constructor() {
    }

    ngOnInit(): void  {       
    }

    ngOnDestroy(): void {       
    }    
}