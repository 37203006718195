<section class="compnyDtlPSec">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <a [routerLink]="['/manage/op-code']" class="backtolist">
                    <em class="fa fa-long-arrow-left" aria-hidden="true"></em>
                    Back to list
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-12 col-lg-8 col-xl-8 offset-xl-2 offset-lg-2">
                <div class="compnydtlwrp">
                    <div class="compnydtlH">
                        <div class="row">
                            <div class="col-8 col-md-8">
                                <h2>OP Code Detail</h2>
                            </div>
                            <div class="col-4 col-md-4">
                                <div class="form-group">
                                    <div ngbDropdown class="actionWrp">
                                        <button class="btn btn-red" id="dropdownBasic1"
                                            ngbDropdownToggle>Actions</button>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                            <ul>
                                                <li>
                                                    <a ngbDropdownItem href="javascript:void(0);"
                                                        [routerLink]="['/op-code/',model.id, 'edit']">
                                                        <em class="fa fa-edit" aria-hidden="true"></em>
                                                        Edit
                                                    </a>
                                                </li>
                                                <li>
                                                    <a ngbDropdownItem href="javascript:void(0);"
                                                        (click)="openOperationCodeStatusModal()">
                                                        <em class="fa fa-times-circle-o" aria-hidden="true"></em>
                                                        <span
                                                            [innerHTML]="model.isActive?'Deactivate':'Activate'"></span>
                                                    </a>
                                                </li>
                                                <li><a ngbDropdownItem href="javascript:void(0);"
                                                        (click)="openOperationCodeDeleteModal()">
                                                        <em class="fa fa-trash-o" aria-hidden="true"></em>
                                                        Delete
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <h4>General Information</h4> -->
                    <div class="row" *blockUI="'container-blockui'">
                        <div class="col-12">
                            <ul class="cmpnyDtlList">
                                <li>
                                    <span>Name</span> <span>:</span>
                                    <span>{{model.name}}</span>
                                </li>
                                <li>
                                    <span>Description</span> <span>:</span>
                                    <span>{{model.description}}</span>
                                </li>
                                <li>
                                    <span>Is Location Specific</span> <span>:</span>
                                    <span>{{model.isLocationSpecific ? 'Yes' : 'No'}}</span>
                                </li>
                                <li>
                                    <span>Category</span> <span>:</span>
                                    <span>{{model.categoryName}}</span>
                                </li>
                                <li>
                                    <span>Tool</span> <span>:</span>
                                    <span>{{model.subCategoryName}}</span>
                                </li>
                                <li *ngIf="model.isLocationSpecific">
                                    <span>Panel</span> <span>:</span>
                                    <span>{{model.carLayoutPanelName}}</span>
                                </li>
                                <li *ngIf="model.isLocationSpecific">
                                    <span>Location</span> <span>:</span>
                                    <span>{{model.carLayoutLocationName}}</span>
                                </li>
                                <li *ngIf="model.isLocationSpecific">
                                    <span>Side</span> <span>:</span>
                                    <span>{{model.carLayoutSideName}}</span>
                                </li>
                                <li>
                                    <span>Is Tied To Intelligent Layout</span> <span>:</span>
                                    <span>{{model.isTiedToIVL ? 'Yes' : 'No'}}</span>
                                </li>
                                <li *ngIf="model.isTiedToIVL">
                                    <span>Layout</span> <span>:</span>
                                    <span>{{VehicleLayoutType[model.vehicleLayoutType]}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<app-op-code-delete-confirmation #deleteConfirmationModal (delete)="delete($event)">
</app-op-code-delete-confirmation>
<app-op-code-status-change-confirmation #statusChangeConfirmationModal (toggle)="toggle($event)">
</app-op-code-status-change-confirmation>