import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { StorageContainerType } from 'src/app/enums';
import { StorageService } from 'src/app/services';

@Component({
    selector: 'app-play-video',
    templateUrl: './play-video.component.html',
    styleUrls: ['./play-video.component.css']
})
export class PlayVideoComponent implements OnInit, OnDestroy {
    @BlockUI('container-blockui-vehicle-note') blockUI: NgBlockUI;
    @Input() recordingFileName: string;
    @Input() isFavIcon: boolean;
    @ViewChild('playVideoModal', { static: false }) playVideoModal: ElementRef;
    video: any;
    modalReference: NgbModalRef;
    publicUrl: string;
    playImg: string = 'assets/images/play.png';

    constructor(
        private modalService: NgbModal,
        private storageService: StorageService
    ) {
    }

    ngOnInit() {
        this.storageService.getPublicUrl(StorageContainerType.AssessmentVideo, this.recordingFileName)
            .subscribe((url: string) => {
                this.publicUrl = url
            });
    }

    ngOnDestroy(): void {
        this.onCancel();
    }

    openModal() {
        this.modalReference = this.modalService.open(this.playVideoModal, {
            centered: true,
            scrollable: true,
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

    }

    onCancel() {
        if (!this.modalReference) {
            return;
        }
        this.modalReference.close();
    }

    onPlayRecording(videoTag: any) {
        if (!this.publicUrl) {
            return;
        }
        videoTag.src = this.publicUrl;
        videoTag.type = 'video/mp4';
        videoTag.play();
    };
}