import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { VehicleLayoutType, PricingType } from 'src/app/enums';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { OperationCodeDetailModel } from 'src/app/models';
import { FilterStateService, MessagingService, OperationCodeService } from 'src/app/services';

@Component({
    selector: 'app-detail-op-code',
    templateUrl: './detail-op-code.component.html',
    styleUrls: ['./detail-op-code.component.css']
})
export class OpCodeDetailComponent implements OnInit {

    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild('deleteConfirmationModal', { static: true }) deleteConfirmationModal: any;
    @ViewChild('statusChangeConfirmationModal', { static: true }) statusChangeConfirmationModal: any;
    
    PricingType = PricingType;
    VehicleLayoutType = VehicleLayoutType;
    model: OperationCodeDetailModel;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private messagingService: MessagingService,
        private operationCodeService: OperationCodeService,
        private filterStateService: FilterStateService
    ) {
        filterStateService.changeFilterModelStatues(FilterPageType.opCodeManage, true);
        this.model = new OperationCodeDetailModel();
        this.route.params.subscribe(params => {
            this.model.id = params.id;
        });
    }
    ngOnInit() {
        this.loadData();
    }

    private loadData() {
        this.blockUI.start();
        this.operationCodeService.get(this.model.id).subscribe((data: OperationCodeDetailModel) => {
            this.model = data;
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }

    openOperationCodeDeleteModal() {
        this.deleteConfirmationModal.openModal(this.model);
    }

    delete(operationCode: OperationCodeDetailModel) {
        this.blockUI.start();
        this.operationCodeService.delete(operationCode.id).subscribe(
            () => {
                setTimeout(() => {
                    this.router.navigate(['/manage/op-code']);
                });
                this.messagingService.success(`OP Code has been deleted successfully.`);
                this.blockUI.stop();
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
                this.blockUI.stop();
            });
    }

    openOperationCodeStatusModal() {
        this.statusChangeConfirmationModal.openModal(this.model);
    }

    toggle(operationCode: OperationCodeDetailModel) {
        this.blockUI.start();
        this.operationCodeService.toggleStatus(operationCode.id).subscribe(
            () => {
                this.messagingService.success(`OP Code has been ${(operationCode.isActive ? 'deactivated' : 'activated')} successfully.`);
                this.blockUI.stop();
                this.model.isActive = !this.model.isActive;
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
                this.blockUI.stop();
            });
    }
}
