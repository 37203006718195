import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class OperationCodeService {
    constructor(http) {
        this.http = http;
    }
    add(model) {
        return this.http.post(`${environment.apiBaseUrl}/operation-code/add`, model);
    }
    edit(model) {
        return this.http.patch(`${environment.apiBaseUrl}/operation-code/edit`, model);
    }
    get(id) {
        return this.http.get(`${environment.apiBaseUrl}/operation-code/${id}`);
    }
    delete(id) {
        return this.http.delete(`${environment.apiBaseUrl}/operation-code/${id}`);
    }
    toggleStatus(id) {
        return this.http.patch(`${environment.apiBaseUrl}/operation-code/toggle-status/${id}`, {});
    }
    query(dataTablesParameters) {
        return this.http.post(`${environment.apiBaseUrl}/operation-code/query`, dataTablesParameters, {});
    }
    selectListByCategoryId(id) {
        return this.http.get(`${environment.apiBaseUrl}/category/${id}/operation-code/select-list`);
    }
    quoteSelectList() {
        return this.http.get(`${environment.apiBaseUrl}/category/quote/operation-code/select-list`);
    }
    getSelectListByCategory(category) {
        return this.http.get(`${environment.apiBaseUrl}/category/operation-code/select-list?categoryName=${category}`);
    }
    selectListBySubCategoryId(id) {
        return this.http.get(`${environment.apiBaseUrl}/operation-code/sub-category/${id}/select-list`);
    }
    getAllOperationCodes() {
        return this.http.get(`${environment.apiBaseUrl}/operation-code/select-list`);
    }
    getReconPackageOperationCodes(storeId, workflowId) {
        return this.http.get(`${environment.apiBaseUrl}/operation-code/recon-package/store/${storeId}/workflow/${workflowId}/select-list`);
    }
}
OperationCodeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OperationCodeService_Factory() { return new OperationCodeService(i0.ɵɵinject(i1.HttpClient)); }, token: OperationCodeService, providedIn: "root" });
