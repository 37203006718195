import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { StoreListItemModel } from 'src/app/models';

@Component({
    selector: 'app-store-status-change-confirmation',
    templateUrl: './store.status.change.confirmation.component.html',
    styleUrls: ['./store.status.change.confirmation.component.css']
})
export class StoreStatusChangeConfirmationComponent implements OnDestroy {
    @BlockUI('container-blockui-confirmation') blockUI: NgBlockUI;
    @ViewChild('confirmationModal', { static: false }) confirmationModal: any;
    @Output() toggle = new EventEmitter<any>();

    modalReference: NgbModalRef;
    model: StoreListItemModel;
    form: FormGroup;
    submitted: boolean;

    constructor(private modalService: NgbModal,
        private formBuilder: FormBuilder) {
    }

    openModal(userModel: StoreListItemModel) {
        this.model = userModel;
        this.form = this.formBuilder.group({
        });

        this.modalReference = this.modalService.open(this.confirmationModal, { centered: true });
    }

    onSubmit() {

        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        this.form.reset();
        this.submitted = false;

        setTimeout(() => {
            this.toggle.emit(this.model);
        });
        this.onCancel();
    }

    onCancel() {
        if(!this.modalReference){
            return;
        }
        this.modalReference.close();
    }
    
    ngOnDestroy(): void {
        this.onCancel();
    }
}
