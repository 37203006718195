import { OperationCodeDetailModel } from "../op-code/op-code.detail.model";

export class MechanicalAdviseModel {
    public id: number;
    public operationCodeId: number;
    public partsPrice: number;
    public laborPrice: number;
    public comments: string;
    public vehicleQueueId: number;

    public operationCodeDetailModel: OperationCodeDetailModel;

    constructor() {
        this.operationCodeDetailModel = new OperationCodeDetailModel();
    }
}