var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlobService } from 'angular-azure-blob-service';
import * as uuid from 'uuid';
import { BlockUI } from 'ng-block-ui';
import { StorageContainerType } from 'src/app/enums';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { CompanyContactModel, CompanyDocumentModel, CompanyEditModel } from 'src/app/models';
import { CompanyService, CountryService, FilterStateService, MessagingService, StorageService, UserService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
export class CompanyEditComponent {
    constructor(router, route, userService, messagingService, storageService, countryService, blobService, companyService, appUtils, formBuilder, stringLengthConstants, filterStateService) {
        this.router = router;
        this.route = route;
        this.userService = userService;
        this.messagingService = messagingService;
        this.storageService = storageService;
        this.countryService = countryService;
        this.blobService = blobService;
        this.companyService = companyService;
        this.appUtils = appUtils;
        this.formBuilder = formBuilder;
        this.stringLengthConstants = stringLengthConstants;
        this.filterStateService = filterStateService;
        this.accountMangers = new Array();
        this.countries = new Array();
        this.states = new Array();
        this.submitted = false;
        this.uploadedPercent = 0;
        this.model = new CompanyEditModel();
        filterStateService.changeFilterModelStatues(FilterPageType.companyManage, true);
        this.route.params.subscribe(params => this.model.id = params['id']);
    }
    ngOnInit() {
        //Create Empty group
        this.form = this.formBuilder.group({
            companyName: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            streetAddress: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            blockAddress: [null, [Validators.maxLength(this.stringLengthConstants.Name)]],
            city: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            state: ['', [Validators.required]],
            country: ['', [Validators.required]],
            zipCode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(this.stringLengthConstants.PostalCode), Validators.pattern(new RegExp('^[a-zA-Z0-9-]*$', 'g'))]],
            email: ['', [Validators.required, Validators.email, Validators.maxLength(this.stringLengthConstants.Email)]],
            phoneCode: ['', [Validators.required]],
            phoneNumber: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.PostalCode), this.appUtils.validatePhoneNumber()]],
            accountManagerId: ['', [Validators.required]],
            quickBookClientId: ['', [Validators.required]],
            quickBookClientSecret: ['', [Validators.required]],
            quickBookAppEnvironment: ['', [Validators.required]],
            taxId: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.TaxId), Validators.pattern("^[0-9]*$")]],
            contacts: this.formBuilder.array([], this.hasContacts())
        });
        this.loadCompany();
        this.loadAccountManagers();
        this.loadCountries();
    }
    loadCompany() {
        this.blockUI.start();
        this.companyService.get(this.model.id).subscribe((data) => {
            this.model = data;
            this.initForm();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    initForm() {
        this.form.controls.companyName.setValue(this.model.name);
        this.form.controls.streetAddress.setValue(this.model.streetAddress);
        this.form.controls.blockAddress.setValue(this.model.blockAddress);
        this.form.controls.city.setValue(this.model.city);
        this.form.controls.city.setValue(this.model.city);
        this.form.controls.state.setValue(this.model.state);
        this.form.controls.country.setValue(this.model.country);
        this.form.controls.zipCode.setValue(this.model.zipCode);
        this.form.controls.email.setValue(this.model.email);
        this.form.controls.phoneCode.setValue(this.model.phoneCode);
        this.form.controls.phoneNumber.setValue(this.model.phoneNumber);
        this.form.controls.accountManagerId.setValue(this.model.accountManagerId);
        this.form.controls.taxId.setValue(this.model.taxId);
        this.form.controls.quickBookClientId.setValue(this.model.quickBookClientId);
        this.form.controls.quickBookClientSecret.setValue(this.model.quickBookClientSecret);
        this.form.controls.quickBookAppEnvironment.setValue(this.model.quickBookAppEnvironment);
        let items = this.form.get('contacts');
        this.model.contacts.forEach(contact => {
            items.push(this.formBuilder.group({
                name: [contact.name, [Validators.maxLength(this.stringLengthConstants.Name)]],
                id: contact.id,
                isDeleted: contact.isDeleted,
                companyId: contact.companyId
            }));
        });
    }
    createContact() {
        return this.formBuilder.group({
            name: ['', [Validators.maxLength(this.stringLengthConstants.Name)]],
            id: 0,
            isDeleted: false,
            companyId: this.model.id
        });
    }
    addContact() {
        let items = this.form.get('contacts');
        items.push(this.createContact());
    }
    hasContacts() {
        const validator = (formArray) => {
            const hasContact = formArray.controls.some(control => control.value.name.trim().length >= 2);
            return hasContact ? null : { required: true };
        };
        return validator;
    }
    onDocumentSelect(event) {
        if (!event.target.files || event.target.files.length === 0) {
            return;
        }
        [...event.target.files].forEach(file => this.processDocument(file));
    }
    processDocument(file) {
        this.storageService.getSasToken().subscribe((sasToken) => __awaiter(this, void 0, void 0, function* () {
            const cloudConfig = {
                sas: sasToken.toString(),
                storageAccount: environment.azureAccount,
                containerName: StorageContainerType.CompanyDocuments
            };
            const fileName = `${uuid.v4()}.${this.appUtils.getFileExtension(file.name)}`;
            const blobUrl = this.blobService.generateBlobUrl(cloudConfig, fileName);
            let document = new CompanyDocumentModel();
            document.originalFileName = file.name;
            document.fileName = fileName;
            document.isDeleted = false;
            this.blockUI.start();
            let config = {
                baseUrl: `${blobUrl}?`,
                sasToken: cloudConfig.sas,
                blockSize: 1024 * 64,
                file: file,
                complete: () => {
                    this.storageService.getPublicUrl(StorageContainerType.CompanyDocuments, document.fileName).subscribe(res => {
                        document.fileUrl = res.toString();
                    });
                    this.model.documents.push(document);
                    this.blockUI.stop();
                },
                error: (err) => {
                    this.messagingService.ProcessErrorResponse(err, 'File upload timeout.');
                    this.blockUI.stop();
                },
                progress: (percent) => {
                    this.uploadedPercent = percent;
                }
            };
            setTimeout(() => {
                this.blobService.upload(config);
            });
        }), error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    deleteDocument(document, index) {
        this.model.documents.splice(index, 1);
    }
    loadSASToken() {
        this.blockUI.start();
    }
    onSubmit() {
        this.submitted = true;
        if (this.form.invalid || this.model.documents.length === 0) {
            return;
        }
        this.model.name = this.form.controls.companyName.value;
        this.model.streetAddress = this.form.controls.streetAddress.value;
        this.model.blockAddress = this.form.controls.blockAddress.value;
        this.model.city = this.form.controls.city.value;
        this.model.state = this.form.controls.state.value;
        this.model.country = this.form.controls.country.value;
        this.model.zipCode = this.form.controls.zipCode.value;
        this.model.email = this.form.controls.email.value;
        this.model.phoneCode = this.form.controls.phoneCode.value;
        this.model.phoneNumber = this.form.controls.phoneNumber.value;
        this.model.accountManagerId = this.form.controls.accountManagerId.value;
        this.model.quickBookClientId = this.form.controls.quickBookClientId.value;
        this.model.quickBookClientSecret = this.form.controls.quickBookClientSecret.value;
        this.model.quickBookAppEnvironment = this.form.controls.quickBookAppEnvironment.value;
        this.model.taxId = this.form.controls.taxId.value;
        this.model.contacts = this.form.controls.contacts.value.filter((x) => x.name != null && x.name.length > 0).map(x => {
            var cm = new CompanyContactModel();
            cm.name = x.name;
            cm.id = x.id;
            cm.isDeleted = x.isDeleted;
            cm.companyId = x.companyId;
            return cm;
        });
        this.blockUI.start();
        this.companyService.edit(this.model)
            .subscribe((data) => {
            setTimeout(() => {
                this.router.navigate(['/manage/company']);
            }, 10);
            setTimeout(() => {
                this.messagingService.success('Managing Company has been updated successfully.');
            }, 300);
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    onCancel() {
        this.router.navigate(['/manage/company']);
    }
    loadAccountManagers() {
        this.blockUI.start();
        this.userService.getAccountManagers().subscribe((data) => {
            this.accountMangers = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    loadCountries() {
        this.blockUI.start();
        this.countryService.getCountries().subscribe((data) => {
            this.countries = data;
            if (this.countries.length == 1) {
                this.loadStates(this.countries[0].id);
                this.form.controls['country'].setValue(this.countries[0].shortName);
                this.form.controls['phoneCode'].setValue(this.countries[0].phoneCode);
            }
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    loadStates(countryId) {
        this.blockUI.start();
        this.countryService.getStates(countryId).subscribe((data) => {
            this.states = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
}
__decorate([
    BlockUI('container-blockui-company'),
    __metadata("design:type", Object)
], CompanyEditComponent.prototype, "blockUI", void 0);
