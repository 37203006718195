<section class="advisorSec">
  <div class="container-fluid">
    <div class="main-vehiclSec">
      <div class="row">
        <div class="col-12 col-md-6">
          <h2>Final Inspection</h2>
        </div>
        <div class="col-12 col-md-6">
          <app-queue-timer [vehicleQueueStageModel]="vehicleQueueStageModel"></app-queue-timer>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="bluetableWrp">
            <app-vehicle-info [vehicleQueueModel]="vehicleQueueModel" [showFullVin]="false"></app-vehicle-info>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <form [formGroup]="frmFinalInspection" (ngSubmit)="onSubmit()">
            <div class="tableWrp table-responsive bluetableWrp">
              <table class="table dataTable table-striped">
                <thead>
                  <tr>
                    <th scope="col" style="width: 100px;">Operation Code</th>
                    <th scope="col">Category</th>
                    <th scope="col">Tool</th>
                    <th scope="col">Panel</th>
                    <th scope="col">Location</th>
                    <th scope="col">Side</th>
                    <th scope="col">Worker Name</th>
                    <th scope="col">
                      <div class="from-group">
                        <span>
                          <input type="checkbox" class="form-control" id="chkSelectAll" formControlName="selectAll"
                            (change)="onSelectAllChange()" />
                          <label for="chkSelectAll" class="mb-0">Approve</label>
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody formArrayName="opCodes">
                  <tr *ngFor="let opCode of getFormArrayControls(); let i = index;" [formGroupName]="i">
                    <td [innerHtml]="opCode.controls.operationCodeName.value"></td>
                    <td [innerHtml]="opCode.controls.categoryName.value"></td>
                    <td [innerHtml]="opCode.controls.subCategoryName.value"></td>
                    <td [innerHtml]="opCode.controls.panelName.value"></td>
                    <td [innerHtml]="opCode.controls.locationName.value"></td>
                    <td [innerHtml]="opCode.controls.sideName.value"></td>
                    <td [innerHtml]="opCode.controls.workerName.value"></td>
                    <td>
                      <div class="from-group">
                        <span>
                          <input type="checkbox" class="form-control" id="ckhIsSelected{{i}}"
                            formControlName="isApproved"
                            (change)="onIsSelectedChange()" />
                          <label for="chkIsSelected{{i}}"></label>
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="text-right">
              <button class="btn btn-red">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
