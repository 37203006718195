import { polyfill } from 'mobile-drag-drop';
// optional import of scroll behaviour
import { scrollBehaviourDragImageTranslateOverride } from "mobile-drag-drop/scroll-behaviour";
import { AppInitializer } from './services';
// #############################################################
// https://www.npmjs.com/package/ngx-drag-drop#touch-support
polyfill({
    // use this to make use of the scroll behaviour
    dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride
});
// workaround to make scroll prevent work in iOS Safari > 10
try {
    window.addEventListener("touchmove", function () { }, { passive: false });
}
catch (e) { }
const ɵ0 = AppInitializer;
// #############################################################
export class AppModule {
}
export { ɵ0 };
