/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./role.delete.confirmation.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng-block-ui";
import * as i3 from "./role.delete.confirmation.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
var styles_RoleDeleteConfirmationComponent = [i0.styles];
var RenderType_RoleDeleteConfirmationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RoleDeleteConfirmationComponent, data: {} });
export { RenderType_RoleDeleteConfirmationComponent as RenderType_RoleDeleteConfirmationComponent };
function View_RoleDeleteConfirmationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "confirmPopup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirm Delete"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "P", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "buttonWrp text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-red m-r-5"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onConfirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-trsp"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 9, 0, currVal_0); }); }
function View_RoleDeleteConfirmationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RoleDeleteConfirmationComponent_2)), i1.ɵdid(1, 212992, null, 0, i2.ɵf, [i2.BlockUIService, i2.ɵc, i1.ViewContainerRef, i1.TemplateRef, i1.Renderer2, i1.ComponentFactoryResolver], { blockUI: [0, "blockUI"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = "container-blockui-confirmation"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RoleDeleteConfirmationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { confirmationModal: 0 }), (_l()(), i1.ɵand(0, [[1, 2], ["roleConfirmationModal", 2]], null, 0, null, View_RoleDeleteConfirmationComponent_1))], null, null); }
export function View_RoleDeleteConfirmationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-role-delete-confirmation", [], null, null, null, View_RoleDeleteConfirmationComponent_0, RenderType_RoleDeleteConfirmationComponent)), i1.ɵdid(1, 180224, null, 0, i3.RoleDeleteConfirmationComponent, [i4.NgbModal], null, null)], null, null); }
var RoleDeleteConfirmationComponentNgFactory = i1.ɵccf("app-role-delete-confirmation", i3.RoleDeleteConfirmationComponent, View_RoleDeleteConfirmationComponent_Host_0, {}, { delete: "delete" }, []);
export { RoleDeleteConfirmationComponentNgFactory as RoleDeleteConfirmationComponentNgFactory };
