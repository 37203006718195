import { ReconPackageItemModel } from "./recon-package.approval.item.model";

export class ReconPackageApprovalModel {
    public vehicleQueueId: number;
    public vehicleQueueStageId: number;
    public reconPackageItemModels: Array<ReconPackageItemModel>;

    constructor() {
        this.reconPackageItemModels = new Array<ReconPackageItemModel>();
    }
}