/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./components/shared/nav-menu/nav-menu.component.ngfactory";
import * as i2 from "./components/shared/nav-menu/nav-menu.component";
import * as i3 from "@angular/router";
import * as i4 from "./services/authentication.service";
import * as i5 from "./services/messaging.service";
import * as i6 from "./services/company.service";
import * as i7 from "@angular/forms";
import * as i8 from "./services/application.context.service";
import * as i9 from "./app.component";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-nav-menu", [], null, null, null, i1.View_NavMenuComponent_0, i1.RenderType_NavMenuComponent)), i0.ɵdid(2, 245760, null, 0, i2.NavMenuComponent, [i3.Router, i4.AuthenticationService, i5.MessagingService, i6.CompanyService, i7.FormBuilder, i8.ApplicationContextService], null, null), (_l()(), i0.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(4, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 49152, null, 0, i9.AppComponent, [i3.Router], null, null)], null, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i9.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
