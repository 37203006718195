<ng-template #confirmationModal let-modal>
  <div *blockUI="'container-blockui-confirmation'">
    <div class="modal-body">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="confirmPopup">
          <div class="form-group">
            <label>Invoice Number</label>
            <input type="text" class="form-control" placeholder="invoiceNumber" formControlName="invoiceNumber" />
            <div *ngIf="submitted && form.controls.invoiceNumber.errors" class="invalid-feedback">
              <div *ngIf="form.controls.invoiceNumber.errors.required">
                Invoice Number is required
              </div>
              <div *ngIf="form.controls.invoiceNumber.errors.maxLength">
                Invoice Number should be maximum {{form.controls.comment.validatorConfig.maxLength.value} characters.
              </div>
            </div>
          </div>
          <div class="buttonWrp">
            <button type="submit" class="btn btn-red m-r-5">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
