<ng-template #confirmationModal let-modal>
  <div *blockUI="'container-blockui-confirmation'">
    <div class="modal-body">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="confirmPopup">
          <div class="form-group">
            <label>Comment</label>
            <input type="text" class="form-control" placeholder="Comment" formControlName="comment" />
            <div *ngIf="submitted && form.controls.comment.errors" class="invalid-feedback">
              <div *ngIf="form.controls.comment.errors.required">
                Comment is required
              </div>
              <div *ngIf="form.controls.comment.errors.maxLength">
                Comment should be maximum {{form.controls.comment.validatorConfig.maxLength.value} characters.
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>New Cost</label>

            <input class="form-control" placeholder="" formControlName="cost"  mask="separator.2" thousandSeparator=","
              [ngClass]="{ 'is-invalid': submitted && form.controls.cost.errors }" />
            <div *ngIf="submitted && form.controls.cost.errors" class="invalid-feedback">
              <div *ngIf="form.controls.cost.errors.required">
                New Cost is required
              </div>
              <div *ngIf="form.controls.cost.errors.numeric">
                New Cost should be numbers
              </div>
              <div *ngIf="form.controls.cost.errors.minNumber">
                New Cost should be min {{form.controls.cost.validatorConfig.minNumber.value}}
              </div>
            </div>

          </div>
          <div class="buttonWrp">
            <button type="submit" class="btn btn-red m-r-5">Submit</button>
            <button type="button" class="btn btn-tsrp" (click)="onCancel($event)">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
