var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { BlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { StoreDetailModel, WorkflowModel, WorkflowSequenceModel, WorkflowStageCategoryModel, WorkflowStageModel } from 'src/app/models';
import { MessagingService, CategoryService, WorkflowService, CheckInTypeService, StoreService, SubCategoryService, ApplicationContextService, FilterStateService } from 'src/app/services';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
export class WorkflowEditComponent {
    constructor(router, route, location, formBuilder, messagingService, checkInTypeService, categoryService, workflowService, storeService, subCategoryService, applicationContextService, filterStateService) {
        this.router = router;
        this.route = route;
        this.location = location;
        this.formBuilder = formBuilder;
        this.messagingService = messagingService;
        this.checkInTypeService = checkInTypeService;
        this.categoryService = categoryService;
        this.workflowService = workflowService;
        this.storeService = storeService;
        this.subCategoryService = subCategoryService;
        this.applicationContextService = applicationContextService;
        this.filterStateService = filterStateService;
        this.dragType = {
            CATEGORY: 'category',
            WORKFLOWSTAGE: 'workflowStage'
        };
        this.submitted = false;
        this.categories = new Array();
        this.checkInTypeSelectList = new Array();
        this.store = new StoreDetailModel();
        this.subCategories = new Array();
        //-----------Drag Methods--------------
        this.selectedCategoryDetailModel = null;
        this.selectedWorkflowStageModel = null;
        filterStateService.changeFilterModelStatues(FilterPageType.workFlowManage, true);
        this.workflowModel = new WorkflowModel();
        this.route.params.subscribe(params => {
            this.workflowModel.id = params.id;
            if (params.storeId) {
                this.store.id = params.storeId;
            }
        });
    }
    ngOnInit() {
        this.loadData();
        this.form = this.formBuilder.group({
            isDefault: [false],
            checkInTypeId: ['', [Validators.required]]
        });
    }
    loadData() {
        this.blockUI.start();
        forkJoin([
            this.categoryService.detailSelectList(),
            this.checkInTypeService.selectList(),
            this.workflowService.get(this.workflowModel.id),
            this.subCategoryService.getAllSubCategories()
        ]).subscribe(([categories, checkInTypeSelectList, workflowModel, subCategories]) => {
            this.categories = categories;
            this.checkInTypeSelectList = checkInTypeSelectList;
            this.workflowModel = workflowModel;
            this.subCategories = subCategories;
            this.form.controls.isDefault.setValue(this.workflowModel.isDefault);
            this.form.controls.checkInTypeId.setValue(this.workflowModel.checkInTypeId);
            let checkInType = this.checkInTypeSelectList.find(x => x.id == this.workflowModel.checkInTypeId);
            if (checkInType != null) {
                this.checkInTypeName = checkInType.name;
            }
            if (this.workflowModel.storeId != null && !this.store.id) {
                this.store.id = this.workflowModel.storeId;
            }
            this.loadStore();
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    loadStore() {
        if (!this.store.id) {
            return;
        }
        this.storeService.get(this.store.id).subscribe((store) => {
            this.store = store;
            this.setCategoryStatus();
            this.updateCategoryUsage();
        });
    }
    setCategoryStatus() {
        this.categories.forEach(x => {
            x.isActive = this.store.categoryIds.includes(x.id);
        });
        this.workflowModel.workflowDynamicSequences.forEach(workflowDynamicSequence => {
            workflowDynamicSequence.workflowStages.forEach(workflowStage => {
                workflowStage.workflowStageCategories.forEach(x => {
                    x.isActive = this.categories.some(z => z.id == x.categoryId && z.isActive);
                });
            });
        });
    }
    associateCategoryToStore(categoryDetailModel) {
        this.blockUI.start();
        this.storeService.associateCategory(this.store.id, categoryDetailModel.id).subscribe(() => {
            this.store.categoryIds.push(categoryDetailModel.id);
            this.setCategoryStatus();
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    ;
    openAddWorkflowCategoryModal(workflowSequenceModel) {
        this.operatingWorkflowSequenceModel = workflowSequenceModel;
        this.addWorkflowStageModal.openModal(new WorkflowStageModel());
    }
    //Push WorkflowSequenceModel to Stage
    addWorkflowStage(model) {
        //Dependency locked is false, break into separate workflow categories
        if (model.dependencyLocked === false) {
            model.workflowStageCategories.forEach(x => {
                let categoryModel = new WorkflowStageCategoryModel();
                categoryModel.categoryId = x.categoryId;
                categoryModel.subCategoryId = x.subCategoryId;
                categoryModel.operationCodes = x.operationCodes;
                categoryModel.isActive = this.categories.some(z => z.id == x.categoryId && z.isActive);
                ;
                let nm = new WorkflowStageModel();
                nm.workflowStageCategories.push(categoryModel);
                this.operatingWorkflowSequenceModel.workflowStages.push(nm);
            });
        }
        else {
            this.operatingWorkflowSequenceModel.workflowStages.push(model);
        }
        this.updateCategoryUsage();
    }
    deleteWorkflowStage(workflowStageModel, workflowSequenceModel) {
        let index = workflowSequenceModel.workflowStages.findIndex(x => x.uniqueIdentifer == workflowStageModel.uniqueIdentifer);
        if (index > -1) {
            workflowSequenceModel.workflowStages.splice(index, 1);
        }
        this.updateCategoryUsage();
    }
    updateWorkflowStage(model, workflowSequenceModel) {
        if (model.dependencyLocked === false) {
            let newModels = model.workflowStageCategories.map(x => {
                let categoryModel = new WorkflowStageCategoryModel();
                categoryModel.categoryId = x.categoryId;
                categoryModel.subCategoryId = x.subCategoryId;
                categoryModel.operationCodes = x.operationCodes;
                categoryModel.isActive = this.categories.some(z => z.id == x.categoryId && z.isActive);
                let sm = new WorkflowStageModel();
                sm.workflowStageCategories.push(categoryModel);
                return sm;
            });
            //New to create new models
            let index = workflowSequenceModel.workflowStages.findIndex(x => x.uniqueIdentifer == model.uniqueIdentifer);
            if (index > -1) {
                workflowSequenceModel.workflowStages.splice(index, 1, ...newModels);
            }
        }
        else {
            let index = workflowSequenceModel.workflowStages.findIndex(x => x.uniqueIdentifer == model.uniqueIdentifer);
            if (index > -1) {
                workflowSequenceModel.workflowStages.splice(index, 1, model);
            }
        }
        this.updateCategoryUsage();
    }
    updateCategoryUsage() {
        this.categories.forEach(category => {
            category.isUsedInWorkflow = this.workflowModel.workflowDynamicSequences.some(stage => {
                return stage.workflowStages.some(stage => {
                    return stage.workflowStageCategories.some(x => x.categoryId == category.id);
                });
            });
        });
    }
    //Add New Workflow Stage
    addWorkflowSequence(currentIndex) {
        this.workflowModel.workflowDynamicSequences.splice(currentIndex + 1, 0, new WorkflowSequenceModel());
    }
    removeWorkflowSequence(currentIndex) {
        if (this.workflowModel.workflowDynamicSequences[currentIndex].workflowStages.length === 0) {
            this.deleteWorkflowSequence(currentIndex);
        }
        else {
            this.deleteSequenceConfirmationModal.openModal(currentIndex);
        }
    }
    //Remove New Workflow Stage
    deleteWorkflowSequence(currentIndex) {
        this.workflowModel.workflowDynamicSequences.splice(currentIndex, 1);
        this.updateCategoryUsage();
    }
    //Submit form for creation, Remove New Workflow Stage
    onSubmit() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        //If Store Id is defined set it
        if (this.store.id) {
            this.workflowModel.storeId = Number(this.store.id);
        }
        this.workflowModel.parentWorkflowId = this.workflowModel.id;
        this.workflowModel.isDefault = this.form.controls.isDefault.value;
        this.workflowModel.checkInTypeId = Number(this.form.controls.checkInTypeId.value);
        //Filter Out Empty Dynamic Sequence
        this.workflowModel.workflowDynamicSequences = this.workflowModel.workflowDynamicSequences.filter(x => x.workflowStages.length > 0);
        //Iterate and assign sequence
        this.workflowModel.workflowDynamicSequences.forEach((workflowSequenceModel, index) => {
            workflowSequenceModel.sequence = index + 1;
            workflowSequenceModel.workflowStages.forEach((workflowStageModel, idx) => {
                workflowStageModel.sequence = idx + 1;
                workflowStageModel.workflowStageCategories.forEach((workflowStageCategoryModel, i) => {
                    workflowStageCategoryModel.sequence = i + 1;
                });
            });
        });
        this.blockUI.start();
        this.workflowService.edit(this.workflowModel).subscribe((data) => {
            setTimeout(() => {
                this.messagingService.success('Workflow has been updated successfully.');
            }, 10);
            setTimeout(() => {
                this.router.navigate(['/workflow', data.id, 'detail']);
            }, 200);
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    categoryDragStart(categoryDetailModel) {
        this.selectedCategoryDetailModel = categoryDetailModel;
    }
    categoryDragEnd() {
        this.selectedCategoryDetailModel = null;
    }
    workflowStageDragStart(workflowStageModel) {
        this.selectedWorkflowStageModel = workflowStageModel;
    }
    workflowStageDragEnd() {
        this.selectedWorkflowStageModel = null;
    }
    workflowDropDisableDropIfStageHasCategory(workflowSequenceModel) {
        // if (this.selectedCategoryDetailModel != null) {
        // 	return this.getCategoriesUsedInWorkFlowStage(workflowSequenceModel).includes(this.selectedCategoryDetailModel.id);
        // }
        return false;
    }
    workflowDrop($event, workflowSequenceModel) {
        //Handle CATEGORY Drop
        if ($event.type === this.dragType.CATEGORY) {
            let workflowStageCategoryModel = new WorkflowStageCategoryModel();
            workflowStageCategoryModel.categoryId = $event.data.id;
            workflowStageCategoryModel.isActive = this.categories.some(z => z.id == $event.data.id && z.isActive);
            let workflowStageModel = new WorkflowStageModel();
            workflowStageModel.workflowStageCategories.push(workflowStageCategoryModel);
            workflowSequenceModel.workflowStages.push(workflowStageModel);
            this.updateCategoryUsage();
        }
        //Handle WORKFLOWSTAGE Drop
        else if ($event.type === this.dragType.WORKFLOWSTAGE) {
            //Remove Workflow stage from old stage
            this.workflowModel.workflowDynamicSequences.forEach(x => {
                let idx = x.workflowStages.findIndex(y => y.uniqueIdentifer == $event.data.uniqueIdentifer);
                if (idx > -1) {
                    x.workflowStages.splice(idx, 1);
                }
            });
            //Push to new stage
            workflowSequenceModel.workflowStages.push($event.data);
        }
    }
    getCategoriesUsedInWorkFlowStage(workflowSequenceModel) {
        let categoryIds = new Array();
        workflowSequenceModel.workflowStages.forEach(x => {
            x.workflowStageCategories.forEach(y => {
                categoryIds.push(y.categoryId);
            });
        });
        return categoryIds;
    }
    onCancel() {
        this.router.navigate(['/manage/workflow']);
    }
}
__decorate([
    BlockUI('container-blockui'),
    __metadata("design:type", Object)
], WorkflowEditComponent.prototype, "blockUI", void 0);
