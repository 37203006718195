import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class VehicleImportService {
    constructor(http) {
        this.http = http;
    }
    fetchImportableVehicles(vehicleImportFilterModel) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/import/importable`, vehicleImportFilterModel);
    }
    delete(model) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/import/delete`, model);
    }
    putOnHold(model) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/import/put-on-hold`, model);
    }
    removeHold(model) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/import/remove-hold`, model);
    }
    import(vehicles) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/import`, vehicles);
    }
}
VehicleImportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VehicleImportService_Factory() { return new VehicleImportService(i0.ɵɵinject(i1.HttpClient)); }, token: VehicleImportService, providedIn: "root" });
