<section class="compnyDtlPSec">
     <div class="container">
          <div class="row">
               <div class="col-12">
                    <a [routerLink]="['/manage/price-matrix']" class="backtolist">
                         <em class="fa fa-long-arrow-left" aria-hidden="true"></em>
                         Back to list
                    </a>
               </div>
          </div>
          <div class="row">
               <div class="col-12 col-md-12 col-lg-8 col-xl-8 offset-xl-2 offset-lg-2">
                    <div class="compnydtlwrp" *blockUI="'container-blockui'">
                         <div class="compnydtlH">
                              <div class="row">
                                   <div class="col-8 col-md-8">
                                        <h2>Price Matrix Detail</h2>
                                   </div>
                                   <div class="col-4 col-md-4">
                                        <div class="form-group">
                                             <div ngbDropdown class="actionWrp dropdown">
                                                  <button class="btn btn-red" id="dropdownBasic1"
                                                       ngbDropdownToggle>Actions</button>
                                                  <div class="dropdown-menu" ngbDropdownMenu
                                                       aria-labelledby="dropdownBasic1">
                                                       <ul>
                                                            <li>
                                                                 <a ngbDropdownItem
                                                                      [routerLink]="['/clone/price-matrix',model.id]">
                                                                      <em class="fa fa-clone" aria-hidden="true"></em>
                                                                      Clone
                                                                 </a>
                                                            </li>
                                                            <li>
                                                                 <a ngbDropdownItem
                                                                      [routerLink]="['/edit/price-matrix',model.id]">
                                                                      <em class="fa fa-edit" aria-hidden="true"></em>
                                                                      Edit
                                                                 </a>
                                                            </li>
                                                            <li>
                                                                 <a ngbDropdownItem href="javascript:void(0);"
                                                                      (click)="openStatusModal()">
                                                                      <em class="fa fa-times-circle-o"
                                                                           aria-hidden="true"></em>
                                                                      <span
                                                                           [innerHTML]="model.isActive?'Deactivate':'Activate'"></span>
                                                                 </a>
                                                            </li>
                                                            <li>
                                                                 <a ngbDropdownItem href="javascript:void(0);"
                                                                      (click)="openDeleteModal()">
                                                                      <em class="fa fa-trash-o" aria-hidden="true"></em>
                                                                      Delete
                                                                 </a>
                                                            </li>
                                                       </ul>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div class="row">
                              <div class="col-12">
                                   <ul class="cmpnyDtlList">
                                        <li>
                                             <span>Check-In Type</span>
                                             <span>:</span>
                                             <span>{{model.checkInTypeName}}</span>
                                        </li>
                                        <li>
                                             <span>Category</span>
                                             <span>:</span>
                                             <span>{{model.categoryName}}</span>
                                        </li>
                                        <li>
                                             <span>Tool</span>
                                             <span>:</span>
                                             <span>{{model.subCategoryName}}</span>
                                        </li>
                                        <li>
                                             <span>Panel</span>
                                             <span>:</span>
                                             <span [innerHTML]="getPanelNames()"></span>
                                        </li>
                                        <li>
                                             <span>Unit of measure for Pricing</span>
                                             <span>:</span>
                                             <span>{{UnitOfMeasure[model.unitOfMeasure]}}</span>
                                        </li>
                                        <li>
                                             <span>Price</span>
                                             <span>:</span>
                                             <span [innerHTML]="getUnitPrices()"></span>
                                        </li>
                                        <li>
                                             <span>Pricing Type</span>
                                            <span>:</span>
                                            <span>{{model.isReconPackage ? 'Recon Package' : ''}}<br/> {{model.isApprovalRequired ? 'Approval Required' : ''}}</span>
                                        </li>
                                        <li>
                                            <span>Is PO Required?</span>
                                            <span>:</span>
                                            <span>{{model.isPORequired ? 'Yes' : 'No'}}</span>
                                        </li>
                                        <li>
                                            <span>Is Shared Queue?</span>
                                            <span>:</span>
                                            <span>{{model.isSharedWorkQueue ? 'Yes' : 'No'}}</span>
                                        </li>
                                        <li>
                                            <span>Is Inspection Required</span>
                                            <span>:</span>
                                            <span>{{model.isInspectionRequired ? 'Yes' : 'No'}}</span>
                                        </li>
                                        <li>
                                            <span>Is Invoice?</span>
                                            <span>:</span>
                                            <span>{{model.isInvoice ? 'Yes' : 'No'}}</span>
                                        </li>
                                   </ul>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</section>
<app-delete-confirmation-price-matrix #deleteConfirmationModal (delete)="delete($event)">
</app-delete-confirmation-price-matrix>
<app-status-confirmation-price-matrix #statusChangeConfirmationModal (toggle)="toggle($event)">
</app-status-confirmation-price-matrix>