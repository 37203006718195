export class CategoryDetailModel {
    public id: number;
    public name: string;
    public code: string;
    public description: string;
    public isActive: boolean;
    public isUsedInWorkflow: boolean;
    public sequence: number;
    public colorCode: string;
    public useToolInWorkflow: boolean;
}