<section class="datatableSer">
     <div class="container">
          <div class="row">
               <div class="col-12 col-md-6">
                    <h1>Manage Category</h1>
               </div>
               <div class="col-12 col-md-6 text-center text-md-right">
                    <button class="btn btn-red" type="button" [routerLink]="['/add/category']">Add Category</button>
               </div>
          </div>
          <div class="row">
               <div class="col-12">
                    <div class="tableWrp">
                         <div class="filterWrp">
                              <form [formGroup]="frmSearch">
                                   <div class="row justify-content-end">
                                        <div class="col-12 col-md-9">
                                             <div class="row">
                                                  <div class="col-12 col-sm-4">
                                                       <label>Name</label>
                                                       <input class="form-control" type="text" formControlName="name"
                                                            (keydown.enter)="applyFilter()" (change)="applyFilter()" />
                                                  </div>
                                                  <div class="col-12 col-sm-4">
                                                       <label>Operation</label>
                                                       <select class="form-control" formControlName="filterOption"
                                                            (change)="applyFilter()">
                                                            <option *ngFor="let item of filterOptions"
                                                                 [value]="item.id">{{item.name}}
                                                            </option>
                                                       </select>
                                                  </div>
                                                  <div class="col-12 col-sm-4">
                                                       <label>Status</label>
                                                       <select class="form-control" formControlName="statusOption"
                                                            (change)="applyFilter()">
                                                            <option *ngFor="let item of statusOptions"
                                                                 [value]="item.id">{{item.name}}
                                                            </option>
                                                       </select>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </form>
                         </div>
                         <div *blockUI="'container-blockui-grid'" class="bluetableWrp table-responsive">
                              <table datatable [dtOptions]="dtOptions" data-page="2"
                                   class="table-hover break-all table w-100" summary="Categories"></table>
                         </div>
                    </div>
               </div>
          </div>

     </div>
</section>

<app-delete-confirmation-category #deleteConfirmationModal (delete)="delete($event)">
</app-delete-confirmation-category>
<app-status-confirmation-category #statusChangeConfirmationModal (toggle)="toggle($event)">
</app-status-confirmation-category>