<section class="advisorSec" *blockUI="'blockui-container'">
  <div class="container-fluid">
    <div class="main-vehiclSec">
      <div class="row">
        <div class="col-12 col-md-6">
          <h2>Quotation</h2>
        </div>
        <div class="col-12 col-md-6">
          <div class="queueTImeWrp">
            <div class="queueTimer">
              <ul>
                <li>
                  <div class="timerH">
                    <h4>Waiting</h4><small>Time </small>
                  </div>
                  <app-time [seconds]="quotationModel.queueTimeInSeconds"></app-time>
                </li>
                <li>
                  <div class="timerH">
                    <h4>Total</h4><small>Time </small>
                  </div>
                  <app-time [seconds]="quotationModel.queueTimeInSeconds + quotationModel.workTimeInSeconds">
                  </app-time>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="dispatch-dtl-FormWrp">
        <div class="row">
          <div class="col-12 col-md-6">
            <h5 class="vendorName">Vendor Name: {{quotationModel.vendorName}}</h5>
          </div>
          <div class="col-12 col-md-6 text-right" *ngIf="recordingFileName">
            <app-play-video [recordingFileName]="recordingFileName" [isFavIcon]="true">
            </app-play-video>
          </div>
        </div>

        <div class="tableWrp table-responsive">
          <app-vehicle-info [vehicleQueueModel]="vehicleQueueModel"></app-vehicle-info>
        </div>

        <form [formGroup]="form" (ngSubmit)="onSubmit()">

          <div class="tableWrp dispatchCostTbl table-responsive">
            <table class="table dataTable table-striped">
              <thead>
                <tr>
                  <th scope="col">S.NO</th>
                  <th scope="col">Category</th>
                  <th scope="col">Tool</th>
                  <th scope="col">Panel</th>
                  <th scope="col">Location</th>
                  <th scope="col">Side</th>
                  <th scope="col">OP Code</th>
                  <th scope="col">Assessor Comment</th>
                  <th scope="col">Price ($)</th>
                  <th scope="col">Comment</th>
                </tr>
              </thead>
              <tbody formArrayName="quotes">
                <tr *ngFor="let item of form.get('quotes').controls; let i= index;" [formGroupName]="i">
                  <td>{{i + 1}}</td>
                  <td>{{item.controls.categoryName.value}}</td>
                  <td>{{item.controls.subCategoryName.value}}</td>
                  <td>{{item.controls.carLayoutPanelName.value}}</td>
                  <td>{{item.controls.carLayoutLocationName.value}}</td>
                  <td>{{item.controls.carLayoutSideName.value}}</td>
                  <td>{{item.controls.operationCodeName.value}}</td>
                  <td>{{item.controls.assessorComment.value}}</td>

                  <td>
                    <input type="text" class="form-control" placeholder="Price" formControlName="price"  mask="separator.2" thousandSeparator=","
                      [ngClass]="{ 'is-invalid': submitted && item.controls.price.errors }" />
                    <div *ngIf="submitted && item.controls.price.errors" class="invalid-feedback">
                      <div *ngIf="item.controls.price.errors.required">
                        Price is required
                      </div>
                      <div *ngIf="item.controls.price.errors.min">
                        Price should be greater than or equal to 0
                      </div>
                      <div *ngIf="item.controls.price.errors.pattern">
                        Please enter numeric value
                      </div>
                    </div>
                  </td>

                  <td>
                    <input type="text" class="form-control" placeholder="Comment" formControlName="comment"
                      [ngClass]="{ 'is-invalid': submitted && item.controls.comment.errors }" />
                    <div *ngIf="submitted && item.controls.comment.errors" class="invalid-feedback">
                      <div *ngIf="item.controls.comment.errors.maxlength">
                        Comment should be maximum
                        {{item.controls.comment.errors.maxlength.requiredLength}} characters
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="quotationTotle">
                <span>Total PRICE : $ {{totalPrice}}</span>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="btnWrp text-right mt-0">
                <button type="submit" class="btn btn-red">Submit</button>
                <button type="button" class="btn btn-tsrp" [routerLink]="['/']">Cancel</button>
                <button type="button" class="btn btn-red" (click)="onNoBid()">No Bid</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<app-no-bid-confirmation #noBidConfirmationModal (nobid)="onNoBidConfirmation()"></app-no-bid-confirmation>
