var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI } from 'ng-block-ui';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PricingType, UnitOfMeasure } from 'src/app/enums';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { AppUtils } from 'src/app/helpers';
import { ApplicationContextService, FilterStateService, MessagingService, StoreService } from 'src/app/services';
import { PriceMatrixService } from 'src/app/services/price-matrix.service';
import { environment } from 'src/environments/environment';
export class ManagePriceMatrixComponent {
    constructor(formBuilder, router, messagingService, appUtils, storeService, priceMatrixService, applicationContextService, currencyPipe, filterStateService) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.messagingService = messagingService;
        this.appUtils = appUtils;
        this.storeService = storeService;
        this.priceMatrixService = priceMatrixService;
        this.applicationContextService = applicationContextService;
        this.currencyPipe = currencyPipe;
        this.filterStateService = filterStateService;
        this.dtOptions = {};
        this.rowIndex = 0;
        this.storeSelectList = new Array();
        this.PricingType = PricingType;
        this.filterOptions = this.appUtils.getFilterOperationOptions();
        this.statusOptions = this.appUtils.getStatusOptions();
    }
    ngAfterViewInit() {
        this.updateFormFromFilterModel();
        this.datatableElement.dtInstance.then((dtInstance) => this.dtInstance = dtInstance);
    }
    updateFormFromFilterModel() {
        const tempFilterModel = this.filterStateService.getFilterModel(FilterPageType.priceMatrixManage);
        if (tempFilterModel && tempFilterModel.isActive) {
            this.frmSearch.controls.name.setValue(tempFilterModel.filterKey);
            this.frmSearch.controls.filterOption.setValue(tempFilterModel.filterOption);
            this.frmSearch.controls.statusOption.setValue(tempFilterModel.statusOption);
        }
    }
    updateFilterModel() {
        const filterModel = this.filterStateService.getFilterModel(FilterPageType.priceMatrixManage);
        filterModel.page = FilterPageType.priceMatrixManage;
        filterModel.filterKey = this.frmSearch.controls.name.value;
        filterModel.filterOption = this.frmSearch.controls.filterOption.value;
        filterModel.statusOption = this.frmSearch.controls.statusOption.value;
        filterModel.isActive = false;
    }
    ngOnInit() {
        this.frmSearch = this.formBuilder.group({
            storeId: [-1],
            name: [''],
            filterOption: [1],
            statusOption: [2] //Active
        });
        this.companySubscription = this.applicationContextService.company$.subscribe((company) => {
            if (company == null) {
                this.storeSelectList = new Array();
            }
            else {
                this.storeSelectList = [...company.stores];
            }
        });
        this.storeSubscription = this.applicationContextService.store$.subscribe((storeSelectListItem) => {
            if (storeSelectListItem) {
                this.frmSearch.controls.storeId.setValue(storeSelectListItem.id);
            }
        });
        this.frmSearch.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
            this.applyFilter();
        });
        this.initDataTable();
    }
    ngOnDestroy() {
        this.storeSubscription.unsubscribe();
        this.companySubscription.unsubscribe();
    }
    applyFilter() {
        this.dtInstance.ajax.reload();
    }
    initDataTable() {
        //Data Table Example.
        const self = this;
        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter...',
                paginate: {
                    first: '<em class="fa fa-angle-double-left">',
                    last: '<em class="fa fa-angle-double-right">',
                    previous: '<em class="fa fa-angle-left">',
                    next: '<em class="fa fa-angle-right">'
                }
            },
            search: { search: null },
            searching: false,
            displayStart: self.rowIndex,
            paging: false,
            pagingType: 'full_numbers',
            pageLength: environment.paging.defaultPageSize,
            lengthMenu: environment.paging.lengthMenu,
            order: [[1, 'asc']],
            info: false,
            stateSave: true,
            scrollY: (window.innerHeight - 70 - 45) + "px",
            scrollCollapse: true,
            ajax: (dataTablesParameters, callback) => {
                self.updateFilterModel();
                let selectedStoreId = Number(self.frmSearch.controls.storeId.value);
                dataTablesParameters.storeId = selectedStoreId; // > 0 ? selectedStoreId : null;
                dataTablesParameters.name = self.frmSearch.controls.name.value;
                dataTablesParameters.filterBy = Number(self.frmSearch.controls.filterOption.value);
                dataTablesParameters.status = self.statusOptions.find(x => x.id == self.frmSearch.controls.statusOption.value).value;
                self.blockUI.start();
                self.priceMatrixService.query(dataTablesParameters)
                    .subscribe(resp => {
                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: resp.data
                    });
                    self.blockUI.stop();
                }, error => {
                    self.messagingService.ProcessErrorResponse(error);
                    self.blockUI.stop();
                });
            },
            columns: [
                {
                    data: 'checkInTypeName',
                    title: 'CheckIn Type',
                    width: '12%',
                },
                {
                    data: 'categoryName',
                    title: 'Category',
                    width: '12%',
                },
                {
                    data: 'subCategoryName',
                    title: 'Tool',
                    width: '12%',
                },
                {
                    data: 'carLayoutPanelNames',
                    title: 'Panel',
                    width: '15%',
                    orderable: false,
                    render: function (carLayoutPanelNames) {
                        return carLayoutPanelNames.join(', ');
                    }
                },
                {
                    data: 'unitOfMeasure',
                    title: 'Unit of measure for Pricing',
                    width: '10%',
                    render: function (unitOfMeasure) {
                        if (UnitOfMeasure.Panel == unitOfMeasure) {
                            return 'Panel';
                        }
                        if (UnitOfMeasure.Spot == unitOfMeasure) {
                            return 'Spot';
                        }
                        if (UnitOfMeasure.Vehicle == unitOfMeasure) {
                            return 'Vehicle';
                        }
                        return '';
                    }
                },
                {
                    data: 'prices',
                    title: 'Price',
                    width: '10%',
                    orderable: false,
                    render: function (prices, type, row) {
                        // console.log(row.unitOfMeasure)
                        if (row.unitOfMeasure == UnitOfMeasure.Vehicle) {
                            return `<span>$ ${prices[0]}</span>`;
                        }
                        let prefix = row.unitOfMeasure == UnitOfMeasure.Spot ? 'Spot' : 'Panel';
                        return prices.map((price, index) => {
                            const totalPrice = self.currencyPipe.transform(price, 'USD', '', '1.2-2');
                            return `<span>${prefix} ${index + 1}: $ ${totalPrice}</span>`;
                        }).join('<br/> ');
                    }
                },
                {
                    data: "isReconPackage",
                    title: 'Recon Pkg',
                    width: '8%',
                    render: function (isReconPackage) {
                        return isReconPackage ? 'Yes' : 'No';
                    }
                },
                {
                    data: 'isActive',
                    title: 'Status',
                    width: '7%',
                    render: function (isActive) {
                        return isActive
                            ? '<span class="badge badge-success">Active</span>'
                            : '<span class="badge badge-danger">Inactive</span>';
                    }
                },
                {
                    data: null,
                    title: 'Action',
                    width: '14%',
                    orderable: false,
                    className: 'text-center',
                    visible: true,
                    render: function (data, type, row) {
                        //Render Activate or Deactivate Button
                        let statusHtml = row.isActive ?
                            `<button title='Deactivate' action-type='toggle-status'><em class="fa fa-ban" aria-hidden="true"></em></button>` :
                            `<button title='Activate' action-type='toggle-status'><em class="fa fa-check" aria-hidden="true"></em></button>`;
                        return `                    
                        <button title='View Detail' action-type='view'><em class="fa fa-eye" aria-hidden="true"></em></button>
                        <button title='Clone' action-type='clone'><em class="fa fa-clone" aria-hidden="true"></em></button>
                        ${statusHtml}
                        <button title='Edit' action-type='edit'><em class="fa fa-edit" aria-hidden="true"></em></button>
                        <button title='Delete' action-type='delete'><em class="fa fa-trash" aria-hidden="true"></em></button>
                        `;
                    }
                }
            ],
            rowCallback: function (row, data) {
                const _row = $(row);
                _row.find('[action-type="link-detail"],[action-type="view"]')
                    .off('click')
                    .on('click', function () {
                    self.router.navigate(['price-matrix', 'detail', data.id]);
                });
                _row.find('[action-type="clone"]')
                    .off('click')
                    .on('click', function () {
                    self.router.navigate(['clone', 'price-matrix', data.id]);
                });
                _row.find('[action-type="edit"]')
                    .off('click')
                    .on('click', function () {
                    self.router.navigate(['edit', 'price-matrix', data.id]);
                });
                _row.find('[action-type="delete"]')
                    .off('click')
                    .on('click', function () {
                    self.openDeleteModal(data);
                });
                _row.find('[action-type="toggle-status"]')
                    .off('click')
                    .on('click', function () {
                    self.openStatusConfirmationModal(data);
                });
            }
        };
    }
    openDeleteModal(data) {
        this.deleteConfirmationModal.openModal(data);
    }
    delete(store) {
        this.blockUI.start();
        this.priceMatrixService.delete(store.id).subscribe(() => {
            setTimeout(() => {
                this.dtInstance.ajax.reload(null, false);
            });
            this.messagingService.success(`Price matrix has been deleted successfully.`);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    openStatusConfirmationModal(data) {
        this.statusChangeConfirmationModal.openModal(data);
    }
    toggle(store) {
        this.blockUI.start();
        this.priceMatrixService.toggleStatus(store.id).subscribe(() => {
            setTimeout(() => {
                this.dtInstance.ajax.reload(null, false);
            });
            this.messagingService.success(`Price matrix has been ${(store.isActive ? 'deactivated' : 'activated')} successfully.`);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    navigateAddStore() {
        this.router.navigate(['add', 'store']);
    }
}
__decorate([
    BlockUI('container-blockui-grid'),
    __metadata("design:type", Object)
], ManagePriceMatrixComponent.prototype, "blockUI", void 0);
