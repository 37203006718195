var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { CurrencyPipe } from "@angular/common";
import { OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockUI } from "ng-block-ui";
import { UnitOfMeasure } from "src/app/enums";
import { FilterPageType } from "src/app/enums/filter.page.type.enum";
import { PriceMatrixDetailModel } from "src/app/models";
import { FilterStateService, MessagingService, PriceMatrixService } from "src/app/services";
export class PriceMatrixDetailComponent {
    constructor(router, route, messagingService, priceMatrixService, currencyPipe, filterStateService) {
        this.router = router;
        this.route = route;
        this.messagingService = messagingService;
        this.priceMatrixService = priceMatrixService;
        this.currencyPipe = currencyPipe;
        this.filterStateService = filterStateService;
        this.UnitOfMeasure = UnitOfMeasure;
        filterStateService.changeFilterModelStatues(FilterPageType.priceMatrixManage, true);
        this.model = new PriceMatrixDetailModel();
        this.route.params.subscribe(params => this.model.id = Number(params.priceMatrxId));
    }
    ngOnInit() {
        this.loadDetail();
    }
    loadDetail() {
        this.blockUI.start();
        this.priceMatrixService.get(this.model.id).subscribe((data) => {
            this.model = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    openDeleteModal() {
        this.deleteConfirmationModal.openModal(this.model);
    }
    delete(priceMatrix) {
        this.blockUI.start();
        this.priceMatrixService.delete(priceMatrix.id).subscribe(() => {
            setTimeout(() => {
                this.router.navigate(['manage/price-matrix']);
            });
            this.messagingService.success(`Price matrix has been deleted successfully.`);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    openStatusModal() {
        this.statusChangeConfirmationModal.openModal(this.model);
    }
    toggle(checkInType) {
        this.blockUI.start();
        this.priceMatrixService.toggleStatus(checkInType.id).subscribe(() => {
            this.messagingService.success(`Price matrix has been ${(checkInType.isActive ? 'deactivated' : 'activated')} successfully.`);
            this.model.isActive = !this.model.isActive;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    getPanelNames() {
        return this.model.carLayoutPanelNames.join(', ');
    }
    getUnitPrices() {
        let prices = this.model.unitPrices.map(x => x.price);
        if (this.model.unitOfMeasure == UnitOfMeasure.Vehicle) {
            return `<span>$ ${prices[0]}`;
        }
        let prefix = this.model.unitOfMeasure == UnitOfMeasure.Spot ? 'Spot' : 'Panel';
        return prices.map((price, index) => {
            const totalPrice = this.currencyPipe.transform(price, 'USD', '', '1.2-2');
            return `<span>${prefix} ${index + 1}: $ ${totalPrice} </span>`;
        }).join('<br/> ');
    }
}
__decorate([
    BlockUI('container-blockui'),
    __metadata("design:type", Object)
], PriceMatrixDetailComponent.prototype, "blockUI", void 0);
