import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BlobModule } from 'angular-azure-blob-service';
import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import { BlockUIModule } from 'ng-block-ui';
import { DndModule } from 'ngx-drag-drop';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators"
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxMaskModule } from 'ngx-mask';
import { polyfill } from 'mobile-drag-drop';
// optional import of scroll behaviour
import { scrollBehaviourDragImageTranslateOverride } from "mobile-drag-drop/scroll-behaviour";

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';

import { JwtInterceptor } from './interceptors';
import { AppUtils, StringLengthConstants } from './helpers';
import { AuthenticatedUserGuard, UnauthenticatedUserGuard, PermissionGuard } from './guards';
import { AppInitializer, ApplicationContextService, AuthenticationService, CarLayoutLocationService, CarLayoutPanelService, CarLayoutSideService, CategoryService, CheckInTypeService, CompanyService, CountryService, CustomNgbDateAdapter, CustomNgbDateParserFormatter, MessagingService, OperationCodeService, RoleService, StorageService, StoreService, SubCategoryService, UserService, VehicleQueueService, VehicleQueueSignalService, VendorService, WorkflowService, KpiBannerService } from './services';

import { Error403Component, Error404Component } from "./components/error";
import { CompanyAddComponent, CompanyDeleteConfirmationComponent, CompanyDetailComponent, CompanyEditComponent, CompanyManageComponent, CompanyStatusChangeConfirmationComponent } from './components/company';
import { UserManageComponent, UserAddComponent, UserEditComponent, UserDetailComponent, UserProfileComponent, UserEditProfileComponent, UserDeleteConfirmationComponent, UserStatusChangeConfirmationComponent, ResetOtherUserPasswordConfirmationComponent, WelcomeMailConfirmationComponent } from "./components/users";
import { AccountActivateComponent, AccountResetComponent, ChangePasswordComponent, ForgotPasswordComponent, LoginComponent, PasswordStrengthComponent } from './components/authenticate';
import { ConfirmationDialogComponent, ContactUsComponent, FAQComponent, HasPermissionsDirective, InfiniteScrollComponent, NavMenuComponent, PlayVideoComponent, QueueTimerComponent, TimerComponent, VehicleInfoComponent, VideoRecorderComponent, WholesaleButtonComponent } from './components/shared';
import { StoreAddComponent, StoreDetailComponent, StoreEditComponent, StoreManageComponent, StoreDeleteConfirmationComponent, StoreStatusChangeConfirmationComponent, StoreCloneComponent } from './components/store';
import { VendorAddComponent, VendorDetailComponent, VendorEditComponent, VendorManageComponent, VendorStatusChangeConfirmationComponent, VendorDeleteConfirmationComponent } from './components/vendor';
import { RoleAddComponent, RoleDeleteConfirmationComponent, RoleEditComponent, RoleIndexComponent } from './components/roles';
import { AddPriceMatrixComponent, ClonePriceMatrixComponent, EditPriceMatrixComponent, ManagePriceMatrixComponent, PriceMatrixDeleteConfirmationComponent, PriceMatrixDetailComponent, PriceMatrixStatusConfirmationComponent } from './components/price-matrix';
import { AddOpCodeComponent, EditOpCodeComponent, ManageOpCodeComponent, OPCodeDeleteConfirmationComponent, OpCodeDetailComponent, OPCodeStatusChangeConfirmationComponent } from "./components/op-code";
import { WorkflowAddComponent, WorkflowAddStageComponent, WorkflowDeleteConfirmationComponent, WorkflowSequenceDeleteConfirmationComponent, WorkflowDetailComponent, WorkflowDetailStageComponent, WorkflowEditStageComponent, WorkflowManageComponent, WorkflowStageDeleteConfirmationComponent, WorkflowStageDetailWidgetComponent, WorkflowStageEditableWidgetComponent, WorkflowStatusChangeConfirmationComponent, WorkflowEditComponent, WorkflowImportComponent } from "./components/workflow";
import { CategoryAddComponent, CategoryEditComponent, CategoryDeleteConfirmationComponent, CategoryStatusConfirmationComponent, CategoryManageComponent, CategoryDetailComponent } from './components/category';
import { CheckInTypeAddComponent, CheckInTypeEditComponent, CheckInTypeDetailComponent, CheckInTypeDeleteConfirmationComponent, CheckInTypeStatusConfirmationComponent, CheckInTypeManageComponent } from './components/check-in-type';
import { SubCategoryManageComponent, SubCategoryAddComponent, SubCategoryEditComponent, SubCategoryDetailComponent, SubCategoryDeleteConfirmationComponent, SubCategoryStatusConfirmationComponent } from './components/sub-category';
import { AccountingComponent } from './components/accounting';
import { CarLayoutManageLocationComponent } from './components/car-layout/location';
import { CarLayoutManagePanelComponent } from './components/car-layout/side';
import { CarLayoutManageSideComponent } from './components/car-layout/panel';
import { AutoImportVehicleComponent, VechilefinalInspectionComponent, EditVehicleDetailsComponent } from './components/vehicle';
import { ApprovalComponent } from './components/approval';
import { BarChartComponent, VehicleQueueNoteComponent, VendorQuoteComponent, TurnTimeChartComponent, ShopTimeChartComponent, VendorTimeChartComponent, QuoteSavingChartComponent, VendorStatusComponent, VehicleQueueComponent, VehicleQueueItemComponent } from './components/home';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NoBidConfirmationComponent, QuotationFormComponent } from './components/quotation';

import { VehicleVideoComponent } from './components/car-layout/vehicle-video/vehicle-video.component';
import { InvoiceViewComponent } from './components/invoice/invoice-view/invoice-view.component';
import { InvoiceListComponent } from './components/invoice/invoice-list/invoice-list.component';
import { InvoiceFormatComponent } from './components/invoice/invoice-format/invoice-format.component';
import { ReconPackageComponent } from './components/recon-package';
import { MechanicalAdvisingComponent, MechanicalDispatchComponent } from './components/mechanical';
import { AssessmentComponent, VehicleTypeConfirmationComponent } from './components/assessment';
import { UpdateVehicleWorkflowComponent } from './components/approval/update-vehicle-workflow/update-vehicle-workflow.component';
import { VendorActionComponent, VendorInvoiceComponent, VendorItemRejectionComponent } from './components/worker-action';
import { ArraySortPipe } from './pipes';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { CurrencyPipe } from '@angular/common';
import { FinalInspectionComponent } from './components/inspection';
import { PurchaseOrderComponent } from './components/purchase-order';


// #############################################################
// https://www.npmjs.com/package/ngx-drag-drop#touch-support
polyfill({
  // use this to make use of the scroll behaviour
  dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride
});

// workaround to make scroll prevent work in iOS Safari > 10
try {
  window.addEventListener("touchmove", function () { }, { passive: false });
}
catch (e) { }
// #############################################################

@NgModule({
  declarations: [
    AppComponent,

    ArraySortPipe,

    HasPermissionsDirective,

    LoginComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    PasswordStrengthComponent,
    AccountActivateComponent,
    AccountResetComponent,

    Error403Component,
    Error404Component,

    NavMenuComponent,
    ContactUsComponent,
    FAQComponent,
    AccountingComponent,

    CompanyAddComponent,
    CompanyEditComponent,
    CompanyDetailComponent,
    CompanyManageComponent,
    CompanyDeleteConfirmationComponent,
    CompanyStatusChangeConfirmationComponent,

    UserManageComponent,
    UserAddComponent,
    UserEditComponent,
    UserDetailComponent,
    UserProfileComponent,
    UserEditProfileComponent,
    UserStatusChangeConfirmationComponent,
    UserDeleteConfirmationComponent,
    ResetOtherUserPasswordConfirmationComponent,
    WelcomeMailConfirmationComponent,

    //Vehicle
    AutoImportVehicleComponent,
    ReconPackageComponent,
    VechilefinalInspectionComponent,
    FinalInspectionComponent,


    StoreAddComponent,
    StoreDetailComponent,
    StoreEditComponent,
    StoreCloneComponent,
    StoreManageComponent,
    StoreDeleteConfirmationComponent,
    StoreStatusChangeConfirmationComponent,

    //Vendor
    VendorAddComponent,
    VendorDetailComponent,
    VendorEditComponent,
    VendorManageComponent,
    VendorDeleteConfirmationComponent,
    VendorStatusChangeConfirmationComponent,

    //Role
    RoleIndexComponent,
    RoleAddComponent,
    RoleEditComponent,
    RoleDeleteConfirmationComponent,

    VehicleVideoComponent,

    //Car layout
    CarLayoutManageLocationComponent,
    CarLayoutManagePanelComponent,
    CarLayoutManageSideComponent,

    //Price Matrix
    AddPriceMatrixComponent,
    ClonePriceMatrixComponent,
    EditPriceMatrixComponent,
    ManagePriceMatrixComponent,
    PriceMatrixDetailComponent,
    PriceMatrixStatusConfirmationComponent,
    PriceMatrixDeleteConfirmationComponent,

    //OP Code
    AddOpCodeComponent,
    ManageOpCodeComponent,
    EditOpCodeComponent,
    OpCodeDetailComponent,
    OPCodeDeleteConfirmationComponent,
    OPCodeStatusChangeConfirmationComponent,

    //Landing page
    ApprovalComponent,

    //WorkFlow
    WorkflowManageComponent,
    WorkflowAddComponent,
    WorkflowEditComponent,
    WorkflowDetailComponent,
    WorkflowDeleteConfirmationComponent,
    WorkflowStatusChangeConfirmationComponent,
    WorkflowImportComponent,

    WorkflowAddStageComponent,
    WorkflowEditStageComponent,
    WorkflowDetailStageComponent,
    WorkflowStageDeleteConfirmationComponent,
    WorkflowStageDetailWidgetComponent,
    WorkflowStageEditableWidgetComponent,
    WorkflowSequenceDeleteConfirmationComponent,

    //Check-In Type
    CheckInTypeManageComponent,
    CheckInTypeAddComponent,
    CheckInTypeEditComponent,
    CheckInTypeDetailComponent,
    CheckInTypeDeleteConfirmationComponent,
    CheckInTypeStatusConfirmationComponent,

    //Category
    CategoryManageComponent,
    CategoryAddComponent,
    CategoryEditComponent,
    CategoryDetailComponent,
    CategoryDeleteConfirmationComponent,
    CategoryStatusConfirmationComponent,

    //Sub category
    SubCategoryManageComponent,
    SubCategoryAddComponent,
    SubCategoryEditComponent,
    SubCategoryDetailComponent,
    SubCategoryDeleteConfirmationComponent,
    SubCategoryStatusConfirmationComponent,

    //Invoice
    InvoiceViewComponent,
    InvoiceListComponent,
    InvoiceFormatComponent,

    //Vehicle Queue
    VehicleQueueNoteComponent,
    BarChartComponent,
    VendorQuoteComponent,
    TurnTimeChartComponent,
    ShopTimeChartComponent,
    VendorTimeChartComponent,
    VendorStatusComponent,
    VehicleQueueComponent,
    VehicleQueueItemComponent,
    QuoteSavingChartComponent,

    //Dynamic Workflows
    AssessmentComponent,
    VehicleTypeConfirmationComponent,
    EditVehicleDetailsComponent,
    MechanicalAdvisingComponent,
    MechanicalDispatchComponent,

    //Shared component
    VideoRecorderComponent,
    ConfirmationDialogComponent,
    InfiniteScrollComponent,
    PlayVideoComponent,
    QueueTimerComponent,
    TimerComponent,
    VehicleInfoComponent,
    WholesaleButtonComponent,

    //Quotation
    QuotationFormComponent,
    NoBidConfirmationComponent,

    //Vendor Action
    VendorActionComponent,
    VendorItemRejectionComponent,
    UpdateVehicleWorkflowComponent,
    VendorInvoiceComponent,

    // Purchase Order
    PurchaseOrderComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    NgMultiSelectDropDownModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    NgbModule,
    DataTablesModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    BlockUIModule.forRoot({ message: 'Loading...' }),
    BlobModule.forRoot(),
    DndModule,
    ColorPickerModule,
    SlickCarouselModule,
    AppRoutingModule,
    ChartsModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    AppUtils,
    StringLengthConstants,

    //Adapters
    { provide: NgbDateAdapter, useClass: CustomNgbDateAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter },

    //Guards
    AuthenticatedUserGuard,
    UnauthenticatedUserGuard,
    PermissionGuard,

    //Services
    AuthenticationService,
    ApplicationContextService,
    CompanyService,
    CountryService,
    MessagingService,
    StorageService,
    UserService,
    RoleService,
    VendorService,
    StoreService,
    CheckInTypeService,
    CategoryService,
    SubCategoryService,
    WorkflowService,
    OperationCodeService,
    CarLayoutLocationService,
    CarLayoutPanelService,
    CarLayoutSideService,
    VehicleQueueService,
    VehicleQueueSignalService,
    KpiBannerService,
    ThemeService,
    CurrencyPipe,

    //Provders
    { provide: APP_INITIALIZER, useFactory: AppInitializer, multi: true, deps: [AuthenticationService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
