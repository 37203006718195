import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class VehicleService {
    constructor(http) {
        this.http = http;
    }
    get(vin) {
        return this.http.get(`${environment.apiBaseUrl}/vehicle/${vin}`);
    }
    getImage(vehicleQueueId) {
        return this.http.get(`${environment.apiBaseUrl}/vehicle/image/${vehicleQueueId}`);
    }
}
VehicleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VehicleService_Factory() { return new VehicleService(i0.ɵɵinject(i1.HttpClient)); }, token: VehicleService, providedIn: "root" });
