var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { PricingType, UnitOfMeasure } from 'src/app/enums';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { PriceMatrixAddModel } from 'src/app/models';
import { ApplicationContextService, CarLayoutPanelService, CategoryService, CheckInTypeService, FilterStateService, MessagingService, PriceMatrixService, SubCategoryService } from 'src/app/services';
import { conditionalValidator } from 'src/app/validators';
export class ClonePriceMatrixComponent {
    constructor(formBuilder, router, route, toastr, messagingService, categoryService, subCategoryService, carLayoutPanelService, checkInTypeService, priceMatrixService, applicationContextService, filterStateService) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.route = route;
        this.toastr = toastr;
        this.messagingService = messagingService;
        this.categoryService = categoryService;
        this.subCategoryService = subCategoryService;
        this.carLayoutPanelService = carLayoutPanelService;
        this.checkInTypeService = checkInTypeService;
        this.priceMatrixService = priceMatrixService;
        this.applicationContextService = applicationContextService;
        this.filterStateService = filterStateService;
        this.UnitOfMeasure = UnitOfMeasure;
        this.PricingType = PricingType;
        this.submitted = false;
        this.stores = new Array();
        this.panels = new Array();
        this.categories = new Array();
        this.subCategories = new Array();
        this.checkInTypes = new Array();
        this.hasPanel = true;
        this.panelDropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: true
        };
        this.isCategoryAssociatedWithStore = true;
        this.isSubCategoryAssociatedWithStore = true;
        this.selectedPanels = new Array();
        filterStateService.changeFilterModelStatues(FilterPageType.priceMatrixManage, true);
        this.route.params.subscribe(params => this.priceMatrixId = Number(params.priceMatrxId));
        this.model = new PriceMatrixAddModel();
    }
    ngOnInit() {
        this.initForm();
        this.loadDetail();
    }
    ngOnDestroy() {
        this.companySubscription.unsubscribe();
    }
    initForm() {
        this.frmPriceMatrix = this.formBuilder.group({
            companyId: ['', [Validators.required]],
            storeId: ['', [Validators.required]],
            checkInTypeId: ['', [Validators.required]],
            categoryId: ['', [Validators.required]],
            subCategoryId: ['', [conditionalValidator(() => this.subCategories.length > 0, Validators.required)]],
            unitOfMeasure: [UnitOfMeasure.Panel],
            unitPrices: this.formBuilder.array([]),
            isReconPackage: [false],
            isApprovalRequired: [false],
            isPORequired: [false],
            isSharedWorkQueue: [false],
            isInspectionRequired: [false],
            isInvoice: [false]
        });
        this.companySubscription = this.applicationContextService.company$.subscribe((company) => {
            if (company == null) {
                this.stores = new Array();
            }
            else {
                this.stores = [...company.stores];
                this.frmPriceMatrix.controls.companyId.setValue(company.id);
            }
        });
    }
    setValidationForFirstUnitPrice() {
        let unitPriceControls = this.getControls();
        if (unitPriceControls.length == 0)
            return;
        let firstControl = unitPriceControls[0];
        firstControl.controls.price.setValidators([Validators.required, Validators.min(1)]);
        firstControl.controls.price.updateValueAndValidity();
    }
    createUnitPrice() {
        return this.formBuilder.group({
            price: ['', [Validators.required, Validators.min(0)]]
        });
    }
    getControls() {
        return this.frmPriceMatrix.get('unitPrices').controls;
    }
    addUnitPrice() {
        let items = this.frmPriceMatrix.get('unitPrices');
        items.push(this.createUnitPrice());
        this.setValidationForFirstUnitPrice();
    }
    removeUnitPrice(index) {
        let items = this.frmPriceMatrix.get('unitPrices');
        if (items.length != 1) {
            items.removeAt(index);
        }
        this.setValidationForFirstUnitPrice();
    }
    onUnitOfMeasureChange() {
        let items = this.frmPriceMatrix.get('unitPrices');
        items.clear();
        items.push(this.createUnitPrice());
        this.setValidationForFirstUnitPrice();
    }
    onStoreSelectionChange(event) {
        this.isCategoryAssociatedWithStore = true;
        this.blockUI.start();
        forkJoin([
            this.categoryService.selectListByStore(event.target.value),
            this.checkInTypeService.getFilteredCheckInTypes(this.frmPriceMatrix.value.companyId, this.priceMatrixDetailModel.storeId)
        ]).subscribe(([categories, checkInTypes]) => {
            this.categories = categories;
            this.checkInTypes = checkInTypes;
            this.isCategoryAssociatedWithStore = categories.some(x => x.id == this.priceMatrixDetailModel.categoryId);
            if (!this.isCategoryAssociatedWithStore) {
                this.frmPriceMatrix.controls.categoryId.setValue('');
                this.frmPriceMatrix.controls.categoryId.updateValueAndValidity();
            }
            else {
                this.frmPriceMatrix.controls.categoryId.setValue(this.priceMatrixDetailModel.categoryId);
            }
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    onCategorySelectionChange(event) {
        if (!event.target.value || !this.frmPriceMatrix.controls.storeId.value) {
            this.frmPriceMatrix.controls.subCategoryId.setValue(null);
            this.subCategories = new Array();
            return;
        }
        this.isSubCategoryAssociatedWithStore = true;
        this.blockUI.start();
        let storeId = this.frmPriceMatrix.controls.storeId.value;
        this.subCategoryService.selectListByStore(storeId, event.target.value)
            .subscribe((data) => {
            this.subCategories = data;
            if (this.subCategories.length > 0) {
                this.frmPriceMatrix.controls.subCategoryId.enable();
                this.isSubCategoryAssociatedWithStore = this.subCategories.some(x => x.id == this.frmPriceMatrix.value.subCategoryId);
                if (!this.isSubCategoryAssociatedWithStore) {
                    this.frmPriceMatrix.controls.subCategoryId.setValue('');
                    this.frmPriceMatrix.controls.subCategoryId.updateValueAndValidity();
                }
            }
            else {
                this.frmPriceMatrix.controls.subCategoryId.disable();
                this.loadPanel(false);
            }
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    onSubCategorySelectionChange(event) {
        if (!event.target.value) {
            this.panels = new Array();
            this.selectedPanels = new Array();
            return;
        }
        this.loadPanel(true);
    }
    loadPanel(hasSubCategory) {
        this.blockUI.start();
        let categoryId = this.frmPriceMatrix.controls.categoryId.value;
        let subCategoryId = this.frmPriceMatrix.controls.subCategoryId.value;
        this.selectedPanels = new Array();
        if (!hasSubCategory) {
            this.carLayoutPanelService.selectListByCategory(categoryId)
                .subscribe((data) => {
                this.panels = data;
                this.enablePanelSelection();
            }, error => {
                this.messagingService.ProcessErrorResponse(error);
            }, () => {
                this.blockUI.stop();
            });
        }
        else {
            this.carLayoutPanelService.selectListByCategoryAndSubCategory(categoryId, subCategoryId)
                .subscribe((data) => {
                this.panels = data;
                this.enablePanelSelection();
            }, error => {
                this.messagingService.ProcessErrorResponse(error);
            }, () => {
                this.blockUI.stop();
            });
        }
    }
    enablePanelSelection() {
        this.hasPanel = this.panels.length > 0;
        if (this.hasPanel == false) {
            this.frmPriceMatrix.controls.unitOfMeasure.setValue(UnitOfMeasure.Vehicle);
            this.onUnitOfMeasureChange();
        }
    }
    loadDetail() {
        this.blockUI.start();
        this.priceMatrixService.get(this.priceMatrixId).subscribe((data) => {
            this.priceMatrixDetailModel = data;
            this.loadData();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    loadData() {
        this.blockUI.start();
        forkJoin([
            this.categoryService.selectListByStore(this.priceMatrixDetailModel.storeId),
            this.subCategoryService.selectListByStore(this.priceMatrixDetailModel.storeId, this.priceMatrixDetailModel.categoryId),
            this.carLayoutPanelService.selectListByCategory(this.priceMatrixDetailModel.categoryId),
            this.carLayoutPanelService.selectListByCategoryAndSubCategory(this.priceMatrixDetailModel.categoryId, this.priceMatrixDetailModel.subCategoryId),
            this.checkInTypeService.getFilteredCheckInTypes(this.frmPriceMatrix.value.companyId, this.priceMatrixDetailModel.storeId)
        ]).subscribe(([categories, subCategories, categoryPanels, subCategoryPanels, checkInTypes]) => {
            this.categories = categories;
            this.subCategories = subCategories;
            this.checkInTypes = checkInTypes;
            if (subCategoryPanels.length > 0) {
                this.panels = subCategoryPanels;
            }
            else {
                this.panels = categoryPanels;
            }
            this.fillFormData();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    fillFormData() {
        this.frmPriceMatrix.patchValue({
            categoryId: this.priceMatrixDetailModel.categoryId,
            checkInTypeId: this.priceMatrixDetailModel.checkInTypeId,
            subCategoryId: this.priceMatrixDetailModel.subCategoryId,
            unitOfMeasure: this.priceMatrixDetailModel.unitOfMeasure,
            isReconPackage: this.priceMatrixDetailModel.isReconPackage,
            isApprovalRequired: this.priceMatrixDetailModel.isApprovalRequired,
        });
        this.frmPriceMatrix.controls.subCategoryId.updateValueAndValidity();
        if (this.priceMatrixDetailModel.subCategoryId > 0) {
            this.frmPriceMatrix.controls.subCategoryId.enable();
        }
        else {
            this.frmPriceMatrix.controls.subCategoryId.disable();
        }
        let items = this.frmPriceMatrix.get('unitPrices');
        this.priceMatrixDetailModel.unitPrices.forEach(unitPrice => {
            items.push(this.formBuilder.group({
                price: [unitPrice.price, [Validators.required, Validators.min(0)]],
            }));
        });
        this.setValidationForFirstUnitPrice();
        this.selectedPanels = this.panels.filter(x => this.priceMatrixDetailModel.carLayoutPanelIds.includes(x.id));
    }
    onSubmit() {
        this.submitted = true;
        if (!this.isCategoryAssociatedWithStore || !this.isSubCategoryAssociatedWithStore || this.frmPriceMatrix.invalid) {
            return;
        }
        this.blockUI.start();
        this.model.storeId = Number(this.frmPriceMatrix.controls.storeId.value);
        this.model.checkInTypeId = Number(this.frmPriceMatrix.value.checkInTypeId);
        this.model.categoryId = Number(this.frmPriceMatrix.controls.categoryId.value);
        let subCategoryId = Number(this.frmPriceMatrix.controls.subCategoryId.value);
        this.model.subCategoryId = subCategoryId > 0 && this.subCategories.length > 0 ? subCategoryId : null;
        this.model.carLayoutPanelIds = this.selectedPanels.map(x => x.id);
        this.model.unitOfMeasure = this.frmPriceMatrix.controls.unitOfMeasure.value;
        this.model.unitPrices = this.frmPriceMatrix.controls.unitPrices.value
            .filter((x) => x.price != null && x.price >= 0).map((x) => Number(x.price));
        this.model.isApprovalRequired = this.frmPriceMatrix.controls.isApprovalRequired.value;
        this.model.isReconPackage = this.frmPriceMatrix.controls.isReconPackage.value;
        this.model.isPORequired = this.frmPriceMatrix.controls.isPORequired.value;
        this.model.isSharedWorkQueue = this.frmPriceMatrix.controls.isSharedWorkQueue.value;
        this.model.isInspectionRequired = this.frmPriceMatrix.controls.isInspectionRequired.value;
        this.model.isInvoice = this.frmPriceMatrix.controls.isInvoice.value;
        this.priceMatrixService.add(this.model)
            .subscribe((detail) => {
            setTimeout(() => {
                this.router.navigate(['/manage/price-matrix']);
            }, 10);
            setTimeout(() => {
                this.toastr.success('Price matrix has been cloned successfully.');
            }, 300);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
}
__decorate([
    BlockUI('container-blockui-store'),
    __metadata("design:type", Object)
], ClonePriceMatrixComponent.prototype, "blockUI", void 0);
