import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ApprovalModel, ReconPackageApprovalModel } from "../models";

@Injectable({
    providedIn: 'root'
})
export class ApprovalService {

    constructor(private http: HttpClient) {
    }

    submitApproval(model: ApprovalModel) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/approval`, model);
    }

    submitReconPackageApproval(model: ReconPackageApprovalModel) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/recon-package/approval`, model);
    }
}