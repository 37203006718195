var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI } from 'ng-block-ui';
import { NotificationType, InvoicingPreference } from 'src/app/enums';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { AppUtils } from 'src/app/helpers';
import { VendorDetailModel } from 'src/app/models';
import { FilterStateService, MessagingService, VendorService } from 'src/app/services';
export class VendorDetailComponent {
    constructor(formBuilder, router, route, messagingService, appUtils, vendorService, filterStateService) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.route = route;
        this.messagingService = messagingService;
        this.appUtils = appUtils;
        this.vendorService = vendorService;
        this.filterStateService = filterStateService;
        this.NotificationType = NotificationType;
        this.InvoicingPreference = InvoicingPreference;
        filterStateService.changeFilterModelStatues(FilterPageType.vendorManage, true);
        this.model = new VendorDetailModel();
        this.route.params.subscribe(params => {
            this.model.id = params.vendorId;
        });
    }
    ngOnInit() {
        this.loadVendor();
    }
    loadVendor() {
        this.blockUI.start();
        this.vendorService.get(this.model.id).subscribe((data) => {
            this.model = data;
            this.stores = this.model.storeNames.join(', ');
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    openVendorDeleteModal() {
        this.deleteConfirmationModal.openModal(this.model);
    }
    delete(vendor) {
        this.blockUI.start();
        this.vendorService.delete(vendor.id).subscribe(() => {
            setTimeout(() => {
                this.router.navigate(['/manage/vendor']);
            });
            this.messagingService.success(`vendor has been deleted successfully.`);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    openVendorStatusModal() {
        this.statusChangeConfirmationModal.openModal(this.model);
    }
    toggle(vendor) {
        this.blockUI.start();
        this.vendorService.toggleStatus(vendor.id).subscribe(() => {
            this.messagingService.success(`Vendor has been ${(vendor.isActive ? 'deactivated' : 'activated')} successfully.`);
            this.model.isActive = !this.model.isActive;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    getNotificationText() {
        let arr = new Array();
        if (this.model !== null) {
            if (this.model.isEmailNotificationActive) {
                arr.push('Email');
            }
            if (this.model.isSMSNotificationActive) {
                arr.push('SMS');
            }
        }
        return arr.join(',&nbsp;');
    }
    getConCatStringFromStringArray(values) {
        if (!values)
            return;
        return values.join(", ");
    }
}
__decorate([
    BlockUI('container-blockui'),
    __metadata("design:type", Object)
], VendorDetailComponent.prototype, "blockUI", void 0);
