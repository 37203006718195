import { FinalInspectionItemModel } from "./final-inspection.item.model";

export class FinalInspectionModel {
    public vehicleQueueId: number;
    public vehicleQueueStageId: number;
    public finalInspectionItemModels: Array<FinalInspectionItemModel>;

    constructor(){
        this.finalInspectionItemModels=new Array<FinalInspectionItemModel>();
    }
}