import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DndDropEvent } from 'ngx-drag-drop';
import { forkJoin } from 'rxjs';
import { CategoryDetailModel, SelectListModel, SubCategorySelectListModel, WorkflowModel, WorkflowSequenceModel, WorkflowStageCategoryModel, WorkflowStageModel } from 'src/app/models';
import { MessagingService, CategoryService, WorkflowService, CheckInTypeService, SubCategoryService, ApplicationContextService } from 'src/app/services';

@Component({
	selector: 'app-workflow-add',
	templateUrl: './workflow.add.component.html',
	styleUrls: ['./workflow.add.component.css']
})
export class WorkflowAddComponent implements OnInit {

	dragType = {
		CATEGORY: 'category',
		WORKFLOWSTAGE: 'workflowStage'
	};

	form: FormGroup;
	submitted = false;

	@BlockUI('container-blockui') blockUI: NgBlockUI;
	@ViewChild('addWorkflowStageModal', { static: false }) addWorkflowStageModal: any;
	@ViewChild('deleteSequenceConfirmationModal', { static: false }) deleteSequenceConfirmationModal: any;

	categories: Array<CategoryDetailModel> = new Array<CategoryDetailModel>();
	checkInTypeSelectList: Array<SelectListModel> = new Array<SelectListModel>();
	subCategories: Array<SubCategorySelectListModel> = new Array<SubCategorySelectListModel>();
	workflowModel: WorkflowModel;

	constructor(private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private messagingService: MessagingService,
		private checkInTypeService: CheckInTypeService,
		private categoryService: CategoryService,
		private workflowService: WorkflowService,
		private subCategoryService: SubCategoryService,
		private applicationContextService: ApplicationContextService,
	) {
		this.workflowModel = new WorkflowModel();
		this.workflowModel.workflowDynamicSequences.push(new WorkflowSequenceModel());

		this.route.params.subscribe(params => {
			if (params.checkInTypeId) {
				this.workflowModel.checkInTypeId = params.checkInTypeId;
			}
		});
	}

	ngOnInit(): void {
		this.loadData();

		this.form = this.formBuilder.group({
			isDefault: [false],
			checkInTypeId: [{ value: this.workflowModel.checkInTypeId > 0 ? this.workflowModel.checkInTypeId : '', disabled: this.workflowModel.checkInTypeId > 0 }, [Validators.required]]
		});
	}

	private loadData() {
		this.blockUI.start();
		forkJoin([
			this.categoryService.detailSelectList(),
			this.checkInTypeService.selectList(),
			this.subCategoryService.getAllSubCategories()
		]).subscribe((result) => {
			this.categories = result[0];
			this.checkInTypeSelectList = result[1];
			this.subCategories = result[2];
			this.blockUI.stop();
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
			this.blockUI.stop();
		});
	}

	//Open Modal for Addition of WorkflowSequenceModel
	operatingWorkflowSequenceModel: WorkflowSequenceModel
	public openAddWorkflowCategoryModal(workflowSequenceModel: WorkflowSequenceModel) {
		this.operatingWorkflowSequenceModel = workflowSequenceModel;
		this.addWorkflowStageModal.openModal(new WorkflowStageModel());
	}

	//Push WorkflowSequenceModel to Stage
	public addWorkflowStage(model: WorkflowStageModel) {
		//Dependency locked is false, break into separate workflow categories
		if (model.dependencyLocked === false) {
			model.workflowStageCategories.forEach(x => {
				let categoryModel = new WorkflowStageCategoryModel();
				categoryModel.categoryId = x.categoryId;
				categoryModel.subCategoryId = x.subCategoryId;
				categoryModel.operationCodes = x.operationCodes;
				categoryModel.isActive = true;
				let nm = new WorkflowStageModel();
				nm.workflowStageCategories.push(categoryModel);

				this.operatingWorkflowSequenceModel.workflowStages.push(nm);
			});
		} else {
			this.operatingWorkflowSequenceModel.workflowStages.push(model);
		}
		this.updateCategoryUsage();
	}

	public deleteWorkflowStage(workflowStageModel: WorkflowStageModel, workflowSequenceModel: WorkflowSequenceModel) {
		let index = workflowSequenceModel.workflowStages.findIndex(x => x.uniqueIdentifer == workflowStageModel.uniqueIdentifer);
		if (index > -1) {
			workflowSequenceModel.workflowStages.splice(index, 1);
		}
		this.updateCategoryUsage();
	}

	public updateWorkflowStage(model: WorkflowStageModel, workflowSequenceModel: WorkflowSequenceModel) {

		if (model.dependencyLocked === false) {
			let newModels = model.workflowStageCategories.map(x => {
				let categoryModel = new WorkflowStageCategoryModel();
				categoryModel.categoryId = x.categoryId;
				categoryModel.subCategoryId = x.subCategoryId;
				categoryModel.operationCodes = x.operationCodes;
				categoryModel.isActive = true;
				let sm = new WorkflowStageModel();
				sm.workflowStageCategories.push(categoryModel);

				return sm;
			});

			//New to create new models
			let index = workflowSequenceModel.workflowStages.findIndex(x => x.uniqueIdentifer == model.uniqueIdentifer);
			if (index > -1) {
				workflowSequenceModel.workflowStages.splice(index, 1, ...newModels);
			}
		} else {
			let index = workflowSequenceModel.workflowStages.findIndex(x => x.uniqueIdentifer == model.uniqueIdentifer);
			if (index > -1) {
				workflowSequenceModel.workflowStages.splice(index, 1, model);
			}
		}

		this.updateCategoryUsage();
	}

	private updateCategoryUsage() {
		this.categories.forEach(category => {
			category.isUsedInWorkflow = this.workflowModel.workflowDynamicSequences.some(stage => {
				return stage.workflowStages.some(stage => {
					return stage.workflowStageCategories.some(x => x.categoryId == category.id);
				});
			});
		});
	}

	//Add New Workflow Stage
	public addWorkflowSequence(currentIndex: number) {
		this.workflowModel.workflowDynamicSequences.splice(currentIndex + 1, 0, new WorkflowSequenceModel());
	}

	public removeWorkflowSequence(currentIndex: number) {
		if (this.workflowModel.workflowDynamicSequences[currentIndex].workflowStages.length === 0) {
			this.deleteWorkflowSequence(currentIndex)
		} else {
			this.deleteSequenceConfirmationModal.openModal(currentIndex);
		}
	}

	//Remove New Workflow Stage
	public deleteWorkflowSequence(currentIndex: number) {
		this.workflowModel.workflowDynamicSequences.splice(currentIndex, 1);
		this.updateCategoryUsage();
	}

	//Submit form for creation, Remove New Workflow Stage
	public onSubmit() {
		this.submitted = true;
		if (this.form.invalid) {
			return;
		}

		this.workflowModel.isDefault = this.form.controls.isDefault.value;
		this.workflowModel.checkInTypeId = Number(this.form.controls.checkInTypeId.value);

		//Filter Out Empty Dynamic Sequence
		this.workflowModel.workflowDynamicSequences = this.workflowModel.workflowDynamicSequences.filter(x => x.workflowStages.length > 0)

		//Iterate and assign sequence
		this.workflowModel.workflowDynamicSequences.forEach((workflowSequenceModel: WorkflowSequenceModel, index: number) => {
			workflowSequenceModel.sequence = index + 1;
			workflowSequenceModel.workflowStages.forEach((workflowStageModel: WorkflowStageModel, idx: number) => {
				workflowStageModel.sequence = idx + 1;
				workflowStageModel.workflowStageCategories.forEach((workflowStageCategoryModel: WorkflowStageCategoryModel, i: number) => {
					workflowStageCategoryModel.sequence = i + 1;
				});
			});
		});

		this.blockUI.start();
		this.workflowService.add(this.workflowModel).subscribe((data: WorkflowModel) => {
			setTimeout(() => {
				this.messagingService.success('Workflow has been added successfully.');
			}, 10);
			setTimeout(() => {
				this.router.navigate(['/workflow', data.id, 'detail']);
			}, 200);
			this.blockUI.stop();
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
			this.blockUI.stop();
		});
	}


	//-----------Drag Methods--------------

	selectedCategoryDetailModel: CategoryDetailModel = null;
	categoryDragStart(categoryDetailModel: CategoryDetailModel) {
		this.selectedCategoryDetailModel = categoryDetailModel;
	}
	categoryDragEnd() {
		this.selectedCategoryDetailModel = null;
	}

	selectedWorkflowStageModel: WorkflowStageModel = null;
	workflowStageDragStart(workflowStageModel: WorkflowStageModel) {
		this.selectedWorkflowStageModel = workflowStageModel;
	}
	workflowStageDragEnd() {
		this.selectedWorkflowStageModel = null;
	}

	workflowDropDisableDropIfStageHasCategory(workflowSequenceModel: WorkflowSequenceModel) {
		// if (this.selectedCategoryDetailModel != null) {
		// 	return this.getCategoriesUsedInWorkFlowStage(workflowSequenceModel).includes(this.selectedCategoryDetailModel.id);
		// }
		return false;
	}

	workflowDrop($event: DndDropEvent, workflowSequenceModel: WorkflowSequenceModel) {
		//Handle CATEGORY Drop
		if ($event.type === this.dragType.CATEGORY) {
			let workflowStageCategoryModel = new WorkflowStageCategoryModel();
			workflowStageCategoryModel.categoryId = $event.data.id;
			workflowStageCategoryModel.isActive = this.categories.some(z => z.id == $event.data.id && z.isActive);
			let workflowStageModel = new WorkflowStageModel();
			workflowStageModel.workflowStageCategories.push(workflowStageCategoryModel);

			workflowSequenceModel.workflowStages.push(workflowStageModel);
			this.updateCategoryUsage();
		}
		//Handle WORKFLOWSTAGE Drop
		else if ($event.type === this.dragType.WORKFLOWSTAGE) {

			//Remove Workflow stage from old stage
			this.workflowModel.workflowDynamicSequences.forEach(x => {
				let idx = x.workflowStages.findIndex(y => y.uniqueIdentifer == $event.data.uniqueIdentifer);
				if (idx > -1) {
					x.workflowStages.splice(idx, 1);
				}
			});

			//Push to new stage
			workflowSequenceModel.workflowStages.push($event.data);
		}
	}


	getCategoriesUsedInWorkFlowStage(workflowSequenceModel: WorkflowSequenceModel): Array<number> {
		let categoryIds = new Array<number>();
		workflowSequenceModel.workflowStages.forEach(x => {
			x.workflowStageCategories.forEach(y => {
				categoryIds.push(y.categoryId);
			});
		});
		return categoryIds;
	}

	onCancel() {
		this.router.navigate(['/manage/workflow']);
	}
}
