var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EventEmitter, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI } from 'ng-block-ui';
import { WorkflowStageAddCategoriesViewModel, WorkflowStageCategoryModel, WorkflowStageCategoryOperationCodeModel } from 'src/app/models';
import { MessagingService, OperationCodeService } from 'src/app/services';
export class WorkflowAddStageComponent {
    constructor(modalService, formBuilder, messagingService, operationCodeService) {
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.messagingService = messagingService;
        this.operationCodeService = operationCodeService;
        this.added = new EventEmitter();
        this.categorySelectList = new Array();
        this.opCodeDropdownSettings = {};
        this.opCodeDropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: true
        };
    }
    ngOnChanges(changes) {
    }
    createStepCategory() {
        this.viewModels.push(new WorkflowStageAddCategoriesViewModel());
        return this.formBuilder.group({
            categoryId: ['', [Validators.required]],
            subCategoryId: ['']
        });
    }
    createStepCategoryWithCategoryId(categoryId) {
        this.viewModels.push(new WorkflowStageAddCategoriesViewModel());
        return this.formBuilder.group({
            categoryId: [categoryId, [Validators.required]],
            subCategoryId: ['']
        });
    }
    addStepCategory() {
        let items = this.form.controls.stepCategory;
        items.push(this.createStepCategory());
    }
    removeStepCategory(i) {
        let items = this.form.controls.stepCategory;
        items.removeAt(i);
        this.viewModels.splice(i, 1);
    }
    init(model) {
        this.viewModels = new Array();
        this.categorySelectList = this.categories.filter(x => x.useToolInWorkflow || !x.isUsedInWorkflow);
        this.model = model;
    }
    openModal(model) {
        this.init(model);
        this.form = this.formBuilder.group({
            dependencyLocked: [false],
            stepCategory: this.formBuilder.array([this.createStepCategory()])
        });
        this.modalReference = this.modalService.open(this.addNewTask, { size: 'lg', centered: true });
    }
    openModalWithCategoryId(model, categoryId) {
        this.init(model);
        this.form = this.formBuilder.group({
            dependencyLocked: [false],
            stepCategory: this.formBuilder.array([this.createStepCategoryWithCategoryId(categoryId)])
        });
        this.onCategoryChange(categoryId, 0);
        this.modalReference = this.modalService.open(this.addNewTask, { size: 'lg', centered: true });
    }
    onCategoryChange(categoryId, index) {
        if (!categoryId) {
            return;
        }
        let vm = this.viewModels[index];
        let stepCategories = this.form.get('stepCategory');
        let fg = stepCategories.controls[index];
        categoryId = Number(categoryId);
        let category = this.categories.find(z => z.id == categoryId);
        if (!category.isActive) {
            fg.controls.categoryId.setErrors({ 'incorrect': true });
            vm.operationCodeSelectList = new Array();
            vm.selectedOperationCodeSelectList = new Array();
            vm.filteredOperationCodeSelectList = new Array();
            vm.subCategorySelectList = new Array();
            return;
        }
        else {
            fg.controls.categoryId.setErrors(null);
        }
        this.blockUI.start();
        this.operationCodeService.selectListByCategoryId(categoryId).subscribe((data) => {
            let vm = this.viewModels[index];
            vm.categoryId = categoryId;
            vm.showTool = this.categories.find(x => x.id == categoryId).useToolInWorkflow;
            vm.operationCodeSelectList = data;
            vm.filteredOperationCodeSelectList = vm.operationCodeSelectList.filter(x => true);
            vm.subCategorySelectList = this.subCategories.filter(x => x.categoryId == categoryId);
            if (!vm.showTool) {
                this.onSubCategoryChange(null, index);
            }
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    onSubCategoryChange(subCategoryId, index) {
        let vm = this.viewModels[index];
        if (!subCategoryId) {
            vm.filteredOperationCodeSelectList = vm.operationCodeSelectList.filter(x => true);
            vm.subCategoryId = null;
            //Reset tool
            let stepCategory = this.form.controls.stepCategory;
            let fb = stepCategory.controls[index];
            fb.controls.subCategoryId.setValue('');
        }
        else {
            vm.subCategoryId = Number(subCategoryId);
            vm.filteredOperationCodeSelectList = vm.operationCodeSelectList.filter(x => x.subCategoryId == vm.subCategoryId);
        }
    }
    onSubmit() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        this.model.dependencyLocked = Boolean(this.form.controls.dependencyLocked.value);
        this.model.workflowStageCategories = this.viewModels.filter(x => !x.isDeleted).map((x, i) => {
            let vm = new WorkflowStageCategoryModel();
            vm.categoryId = x.categoryId;
            vm.subCategoryId = x.subCategoryId;
            vm.subCategoryName = x.subCategoryName;
            vm.isActive = true;
            vm.sequence = i;
            vm.operationCodes = x.selectedOperationCodeSelectList.map((selectList, j) => {
                let m = new WorkflowStageCategoryOperationCodeModel();
                m.operationCodeId = selectList.id;
                return m;
            });
            return vm;
        });
        this.viewModels = new Array();
        this.form.reset();
        this.submitted = false;
        setTimeout(() => {
            this.added.emit(this.model);
        });
        this.onCancel();
    }
    onCancel() {
        if (!this.modalReference) {
            return;
        }
        this.modalReference.close();
    }
    ngOnDestroy() {
        this.onCancel();
    }
}
__decorate([
    BlockUI('container-blockui-work-step'),
    __metadata("design:type", Object)
], WorkflowAddStageComponent.prototype, "blockUI", void 0);
