import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { Error403Component, Error404Component } from "./components/error";
import { CompanyAddComponent, CompanyDetailComponent, CompanyEditComponent, CompanyManageComponent } from "./components/company";
import { UserManageComponent, UserAddComponent, UserEditComponent, UserDetailComponent, UserProfileComponent, UserEditProfileComponent } from "./components/users";
import { PermissionType } from "./enums";
import { AccountActivateComponent, AccountResetComponent, ChangePasswordComponent, ForgotPasswordComponent, LoginComponent } from "./components/authenticate";
import { ContactUsComponent, FAQComponent } from "./components/shared";
import { StoreAddComponent, StoreCloneComponent, StoreDetailComponent, StoreEditComponent, StoreManageComponent } from "./components/store";
import { VendorManageComponent, VendorAddComponent, VendorEditComponent, VendorDetailComponent } from "./components/vendor";
import { RoleAddComponent, RoleEditComponent, RoleIndexComponent } from "./components/roles";
import { AuthenticatedUserGuard, UnauthenticatedUserGuard, PermissionGuard } from "./guards";
import { AddPriceMatrixComponent, ClonePriceMatrixComponent, EditPriceMatrixComponent, ManagePriceMatrixComponent, PriceMatrixDetailComponent } from "./components/price-matrix";
import { AddOpCodeComponent, EditOpCodeComponent, ManageOpCodeComponent, OpCodeDetailComponent } from "./components/op-code";
import { AccountingComponent } from './components/accounting';
import { WorkflowAddComponent, WorkflowDetailComponent, WorkflowEditComponent, WorkflowManageComponent } from "./components/workflow";
import { CheckInTypeAddComponent, CheckInTypeDetailComponent, CheckInTypeEditComponent, CheckInTypeManageComponent } from "./components/check-in-type";
import { ApprovalComponent } from "./components/approval";
import { VehicleQueueComponent } from "./components/home";
import { QuotationFormComponent } from "./components/quotation";
import { SubCategoryManageComponent, SubCategoryAddComponent, SubCategoryEditComponent, SubCategoryDetailComponent } from "./components/sub-category";
import { CategoryManageComponent, CategoryAddComponent, CategoryEditComponent, CategoryDetailComponent } from "./components/category";

import { CarLayoutManageLocationComponent } from './components/car-layout/location';
import { CarLayoutManagePanelComponent } from './components/car-layout/side';
import { CarLayoutManageSideComponent } from './components/car-layout/panel';
import { AutoImportVehicleComponent, VechilefinalInspectionComponent, EditVehicleDetailsComponent } from "./components/vehicle";

import { InvoiceViewComponent } from "./components/invoice/invoice-view/invoice-view.component";
import { InvoiceListComponent } from "./components/invoice/invoice-list/invoice-list.component";
import { InvoiceFormatComponent } from "./components/invoice/invoice-format/invoice-format.component";
import { MechanicalAdvisingComponent, MechanicalDispatchComponent } from "./components/mechanical";
import { AssessmentComponent } from "./components/assessment";
import { ReconPackageComponent } from "./components/recon-package";
import { VendorActionComponent } from "./components/worker-action";
import { UpdateVehicleWorkflowComponent } from './components/approval/update-vehicle-workflow/update-vehicle-workflow.component';
import { FinalInspectionComponent as FinalInspectionComponent } from "./components/inspection";

const routes: Routes = [
	{
		path: '',
		component: VehicleQueueComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			header: {
				showControls: true,
				disableControls: false
			}
		}
	},
	{
		path: 'contact-us',
		component: ContactUsComponent
	},
	{
		path: 'faq',
		component: FAQComponent
	},
	{
		path: 'accounting',
		component: AccountingComponent
	},
	{
		path: 'error/403',
		component: Error403Component
	},
	{
		path: 'error/404',
		component: Error404Component
	},

	//Authentication Related Routes
	{
		path: 'account/login',
		component: LoginComponent,
		canActivate: [UnauthenticatedUserGuard, PermissionGuard]
	},
	{
		path: 'account/forgot-password',
		component: ForgotPasswordComponent,
		canActivate: [UnauthenticatedUserGuard, PermissionGuard]
	},
	{
		path: 'account/activate/:key/:secret',
		component: AccountActivateComponent,
		canActivate: [UnauthenticatedUserGuard, PermissionGuard]
	},
	{
		path: 'account/reset/:key/:secret',
		component: AccountResetComponent,
		canActivate: [UnauthenticatedUserGuard, PermissionGuard]
	},

	//Only authenticated user can access these
	{
		path: 'account/change-password',
		component: ChangePasswordComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard]
	},

	//Company Related Routes
	{
		path: 'manage/company',
		component: CompanyManageComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},
	{
		path: 'add/company',
		component: CompanyAddComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},
	{
		path: 'edit/company/:id',
		component: CompanyEditComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.ManagingCompanyAdmin]
		}
	},
	{
		path: 'company/detail/:id',
		component: CompanyDetailComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.ManagingCompanyAdmin]
		}
	},
	//Store Related Routes
	{
		path: 'manage/store',
		component: StoreManageComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.StoreAdmin, PermissionType.StoreManagement],
			header: {
				showControls: true,
				disableControls: false,
				showStoreControl: false
			}
		}
	},
	{
		path: 'add/store',
		component: StoreAddComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.StoreAdmin],
			header: {
				showControls: true,
				disableControls: true,
				showStoreControl: false
			}
		}
	},
	{
		path: 'edit/store/:storeId',
		component: StoreEditComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.StoreAdmin],
			header: {
				showControls: true,
				disableControls: true,
				showStoreControl: false
			}
		}
	},
	{
		path: 'clone/store/:storeId',
		component: StoreCloneComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.StoreAdmin],
			header: {
				showControls: true,
				disableControls: true,
				showStoreControl: false
			}
		}
	},
	{
		path: 'store/detail/:storeId',
		component: StoreDetailComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.StoreAdmin, PermissionType.StoreManagement
			],
			header: {
				showControls: true,
				disableControls: true,
				showStoreControl: false
			}
		}
	},


	//User Related Routes
	{
		path: 'manage/user',
		component: UserManageComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.ManagingCompanyAdmin, PermissionType.StoreAdmin, PermissionType.VendorAdmin]
		}
	},
	{
		path: 'add/user',
		component: UserAddComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.ManagingCompanyAdmin, PermissionType.StoreAdmin, PermissionType.VendorAdmin]
		}
	},
	{
		path: 'edit/user/:userId',
		component: UserEditComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.ManagingCompanyAdmin, PermissionType.StoreAdmin, PermissionType.VendorAdmin]
		}
	},
	{
		path: 'user/detail/:userId',
		component: UserDetailComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.ManagingCompanyAdmin, PermissionType.StoreAdmin, PermissionType.VendorAdmin]
		}
	},
	{
		path: 'my/profile',
		component: UserProfileComponent,
		canActivate: [AuthenticatedUserGuard]
	},
	{
		path: 'my/profile/edit',
		component: UserEditProfileComponent,
		canActivate: [AuthenticatedUserGuard]
	},

	//Vendor Related Routes
	{
		path: 'manage/vendor',
		component: VendorManageComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.VendorAdmin],
			header: {
				showControls: true,
				disableControls: false
			}
		}
	},
	{
		path: 'add/vendor',
		component: VendorAddComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.VendorAdmin],
			header: {
				showControls: true,
				disableControls: true
			}
		}
	},
	{
		path: 'edit/vendor/:vendorId',
		component: VendorEditComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.VendorAdmin],
			header: {
				showControls: true,
				disableControls: true
			}
		}
	},
	{
		path: 'vendor/detail/:vendorId',
		component: VendorDetailComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.VendorAdmin],
			header: {
				showControls: true,
				disableControls: true
			}
		}
	},



	//Role related
	{
		path: 'manage/role',
		component: RoleIndexComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},
	{
		path: 'role/add',
		component: RoleAddComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},
	{
		path: 'role/edit/:id',
		component: RoleEditComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},

	//WorkFlow
	{
		path: 'manage/workflow',
		component: WorkflowManageComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master],
			header: {
				showControls: true,
				disableControls: false
			}
		}
	},
	{
		path: 'add/workflow',
		component: WorkflowAddComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.WorkflowAddition],
			header: {
				showControls: true,
				disableControls: true
			}
		}
	},
	{
		path: 'add/workflow/check-in-type/:checkInTypeId',
		component: WorkflowAddComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.WorkflowAddition],
			header: {
				showControls: true,
				disableControls: true
			}
		}
	},
	{
		path: 'workflow/:id/edit',
		component: WorkflowEditComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.WorkflowAddition, PermissionType.WorkflowAdjustment],
			header: {
				showControls: true,
				disableControls: true
			}
		}
	},
	{
		path: 'store/:storeId/associate/workflow/:id',
		component: WorkflowEditComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.WholesaleButtonAction, PermissionType.WorkflowAddition],
			header: {
				showControls: true,
				disableControls: true
			}
		}
	},
	{
		path: 'workflow/:id/detail',
		component: WorkflowDetailComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.WorkflowAddition, PermissionType.WorkflowAdjustment],
			header: {
				showControls: true,
				disableControls: true
			}
		}
	},

	//vehicle
	{
		path: 'inventory',
		component: AutoImportVehicleComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.VehicleImport],
			header: {
				showControls: true,
				disableControls: false
			}
		}
	},
	{
		path: 'vehicle/recon/package/:vehicleQueueId/:vehicleQueueStageId',
		component: ReconPackageComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Recon_PkgAdmin]
		}
	},
	{
		path: 'vehicle/final-inspection-screen',
		component: VechilefinalInspectionComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.VehicleInformationAdjustment]
		}
	},
	{
		path: 'vehicle/final-inspection/:storeId/:vehicleQueueId/:vehicleQueueStageId',
		component: FinalInspectionComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Inspection]
		}
	},
	{
		path: 'vehicle/quotation/:storeId/:quotationId/:vehicleQueueId/:vehicleQueueStageId',
		component: QuotationFormComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.CqHgGqEntry],
			header: {
				showControls: true,
				disableControls: true
			}
		}
	},
	{
		path: 'vehicle/quotation/:companyId/:storeId/:vendorId/:quotationId/:vehicleQueueId/:vehicleQueueStageId',
		component: QuotationFormComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.CqHgGqEntry],
			header: {
				showControls: true,
				disableControls: true
			}
		}
	},
	{
		path: 'vehicle/mechanical/advising/:storeId/:vehicleQueueId/:vehicleQueueStageId',
		component: MechanicalAdvisingComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.MechanicalAdvisingDataEntry],
			header: {
				showControls: true,
				disableControls: true
			}
		}
	},
	{
		path: 'vehicle/mechanical/dispatch/:storeId/:vehicleQueueId/:vehicleQueueStageId',
		component: MechanicalDispatchComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Mechanical],
			header: {
				showControls: true,
				disableControls: true
			}
		}
	},
	{
		path: 'vehicle/vendor/action/:storeId/:vehicleQueueId/:vehicleQueueStageId',
		component: VendorActionComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [
				//PermissionType.CqHgGqEntry
			],
			header: {
				showControls: true,
				disableControls: true
			}
		}
	},

	{
		path: 'vehicle/assessment/:storeId/:vehicleQueueId/:vehicleQueueStageId/:workflowId/:vehicleTypeId',
		component: AssessmentComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [
				PermissionType.Approval
			],
			header: {
				showControls: true,
				disableControls: true
			}
		}
	},

	//Approval
	{
		path: 'vehicle/approval/:vehicleQueueId/:vehicleQueueStageId',
		component: ApprovalComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [
				PermissionType.Approval
			],
			header: {
				showControls: true,
				disableControls: true
			}
		}
	},
	{
		path: 'vehicle/workflow/:id/:vehicleQueueId/:vehicleQueueStageId/edit',
		component: UpdateVehicleWorkflowComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.WorkflowAddition, PermissionType.WorkflowAdjustment],
			header: {
				showControls: true,
				disableControls: true
			}
		}
	},

	//Vehicle Info
	{
		path: 'edit/vehicle-detail/:vehicleQueueModelId',
		component: EditVehicleDetailsComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.VehicleImport, PermissionType.VehicleInformationAdjustment],
			header: {
				showControls: true,
				disableControls: true
			}
		}
	},

	//Car layout
	{
		path: 'car-layout-location',
		component: CarLayoutManageLocationComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},
	{
		path: 'car-layout-side',
		component: CarLayoutManageSideComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},
	{
		path: 'car-layout-panel',
		component: CarLayoutManagePanelComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},

	//Price Matrix
	{
		path: 'manage/price-matrix',
		component: ManagePriceMatrixComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master],
			header: {
				showControls: true,
				disableControls: false
			}
		}
	},
	{
		path: 'add/price-matrix',
		component: AddPriceMatrixComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master],
			header: {
				showControls: true,
				disableControls: true
			}
		}
	},
	{
		path: 'edit/price-matrix/:priceMatrxId',
		component: EditPriceMatrixComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.PricingAdjustmentOPCodesIncludedInReconPackage, PermissionType.PricingAdjustmentOPCodesNotIncludedInReconPackage],
			header: {
				showControls: true,
				disableControls: true
			}
		}
	},
	{
		path: 'clone/price-matrix/:priceMatrxId',
		component: ClonePriceMatrixComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.ClientCreation],
			header: {
				showControls: true,
				disableControls: true
			}
		}
	},
	{
		path: 'price-matrix/detail/:priceMatrxId',
		component: PriceMatrixDetailComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master, PermissionType.PricingAdjustmentOPCodesIncludedInReconPackage, PermissionType.PricingAdjustmentOPCodesNotIncludedInReconPackage],
			header: {
				showControls: true,
				disableControls: true
			}
		}
	},

	//op-code
	{
		path: 'manage/op-code',
		component: ManageOpCodeComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},
	{
		path: 'add/op-code',
		component: AddOpCodeComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},
	{
		path: 'op-code/:id/edit',
		component: EditOpCodeComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},
	{
		path: 'op-code/:id/detail',
		component: OpCodeDetailComponent,
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},

	//Check-in type
	{
		path: 'manage/check-in-type',
		component: CheckInTypeManageComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},
	{
		path: 'add/check-in-type',
		component: CheckInTypeAddComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},
	{
		path: 'edit/check-in-type/:checkInTypeId',
		component: CheckInTypeEditComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},
	{
		path: 'check-in-type/detail/:checkInTypeId',
		component: CheckInTypeDetailComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},
	//Category
	{
		path: 'manage/category',
		component: CategoryManageComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},
	{
		path: 'add/category',
		component: CategoryAddComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},
	{
		path: 'edit/category/:categoryId',
		component: CategoryEditComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},
	{
		path: 'category/detail/:categoryId',
		component: CategoryDetailComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},
	//Sub category
	{
		path: 'manage/sub-category',
		component: SubCategoryManageComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},
	{
		path: 'add/sub-category',
		component: SubCategoryAddComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},
	{
		path: 'edit/sub-category/:subCategoryId',
		component: SubCategoryEditComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},
	{
		path: 'sub-category/detail/:subCategoryId',
		component: SubCategoryDetailComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.Master]
		}
	},

	//Invoice
	{
		path: 'invoice/invoice-view',
		component: InvoiceViewComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard],
		data: {
			permissions: [PermissionType.StoreAccounting, PermissionType.VendorAccounting, PermissionType.WorkflowStatusView, PermissionType.InvoicingFunction]
		}
	},
	{
		path: 'invoice/invoice-list',
		component: InvoiceListComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard]
	},
	{
		path: 'invoice/invoice-format',
		component: InvoiceFormatComponent,
		pathMatch: 'full',
		canActivate: [AuthenticatedUserGuard, PermissionGuard]
	},

	{
		path: '**',
		redirectTo: ''
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
