import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
export class CustomNgbDateAdapter extends NgbDateAdapter {
    constructor() {
        super(...arguments);
        this.DELIMITER = '-';
    }
    fromModel(value) {
        if (value) {
            const date = value.split(this.DELIMITER);
            return {
                year: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                day: parseInt(date[2], 10)
            };
        }
        return null;
    }
    toModel(date) {
        return date ? date.year + this.DELIMITER + `${date.month > 9 ? date.month : `0${date.month}`}` + this.DELIMITER + `${date.day > 9 ? date.day : `0${date.day}`}` : null;
    }
}
/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
export class CustomNgbDateParserFormatter extends NgbDateParserFormatter {
    constructor() {
        super(...arguments);
        this.DELIMITER = '/';
    }
    parse(value) {
        if (value) {
            const date = value.split(this.DELIMITER);
            return {
                month: parseInt(date[0], 10),
                day: parseInt(date[1], 10),
                year: parseInt(date[2], 10)
            };
        }
        return null;
    }
    format(date) {
        return date
            ? `${date.month > 9 ? date.month : `0${date.month}`}${this.DELIMITER}` +
                `${date.day > 9 ? date.day : `0${date.day}`}${this.DELIMITER}${date.year}`
            : '';
    }
}
