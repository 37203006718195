var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Renderer2, ElementRef, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlobService } from 'angular-azure-blob-service';
import { BlockUI } from 'ng-block-ui';
import { forkJoin, fromEvent, Observable } from 'rxjs';
import { PermissionType, StorageContainerType, SubCategory, UnitOfMeasure, VehicleLayoutType, VehicleType, VehicleVideoType } from 'src/app/enums';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { OperationCodePriceModel, VehicleAssessmentAddModel, VehicleQueueModel, VehicleQueueStageModel, VehicleVideoAddModel } from 'src/app/models';
import { AuthenticationService, CategoryService, MessagingService, PriceMatrixService, StorageService, VehicleQueueService, WorkflowService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { ConfirmationDialogComponent } from '../../shared';
import { Category } from 'src/app/enums';
import { tap } from 'rxjs/operators';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
export class AssessmentComponent {
    constructor(formBuilder, route, router, render2, appUtils, messagingService, blobService, storageService, priceMatrixService, categoryService, vehicleQueueService, workflowService, authenticationService) {
        this.formBuilder = formBuilder;
        this.route = route;
        this.router = router;
        this.render2 = render2;
        this.appUtils = appUtils;
        this.messagingService = messagingService;
        this.blobService = blobService;
        this.storageService = storageService;
        this.priceMatrixService = priceMatrixService;
        this.categoryService = categoryService;
        this.vehicleQueueService = vehicleQueueService;
        this.workflowService = workflowService;
        this.authenticationService = authenticationService;
        this.activeTabId = "exteriorTab";
        this.openToolAccordionByIds = {};
        this.CategoryEnum = Category;
        this.VehicleType = VehicleType;
        this.VehicleVideoType = VehicleVideoType;
        this.vehicleQueueModel = new VehicleQueueModel();
        this.vehicleQueueStageModel = new VehicleQueueStageModel();
        this.spotPriceMatrices = new Array();
        this.categories = new Array();
        this.mandatoryOpCodesExceptMechanical = new Array();
        this.selectedOpCodes = [];
        this.model = new VehicleAssessmentAddModel();
        this.vehicleWorkDetails = new Array();
        this.EnumCategory = Category;
        this.filteredCategories = new Array();
        this.submitted = false;
        this.dataSubmitted = false;
        this.UnitOfMeasure = UnitOfMeasure;
        this.tempVideoModel = new Array();
        this.isPriceEditable = false;
        this.isToolHide = false;
        this.oldScreenHeight = 0;
        this.oldScreenWidth = 0;
        this.oldSVGHeight = 0;
        this.oldSVGWidth = 0;
        this.uploadedPercent = 0;
        this.route.params.subscribe((data) => {
            this.storeId = data.storeId;
            this.vehicleQueueId = data.vehicleQueueId;
            this.vehicleQueueStageId = data.vehicleQueueStageId;
            this.workflowId = data.workflowId;
            this.vehicleTypeId = data.vehicleTypeId;
        });
        // customize default values of accordions used by this component tree
        //accordionConfig.closeOthers = true;
        //accordionConfig.type = 'info';
    }
    ngOnInit() {
        this.frmSummary = this.formBuilder.group({
            prices: this.formBuilder.array([])
        });
        this.authenticationService.validatePermission([PermissionType.EditPricingonAssessmentSummaryTab])
            .subscribe((valid) => {
            this.isPriceEditable = valid;
        });
        this.loadVehicleQueueDetail();
        this.calculateScreenHeight();
    }
    calculateScreenHeight() {
        this.screenHeight = window.innerHeight - 70 - 45 + "px";
    }
    ngOnDestroy() {
        this.resizeEvent$.unsubscribe();
        this.orientationEvent$.unsubscribe();
        // setTimeout(() => {
        // 	if (this.model.vehicleVideos.length > 0 && !this.dataSubmitted) {
        // 		this.model.vehicleVideos.forEach(x => {
        // 			this.storageService.delete(StorageContainerType.AssessmentVideo, x.fileName).subscribe();
        // 		});
        // 	}
        // }, 100);
    }
    setSVGWidth() {
        let svg = this.getActiveSVGElement();
        this.render2.setStyle(svg.nativeElement, "width", "auto");
    }
    ngAfterViewInit() {
        this.resizeEvent$ = fromEvent(window, 'resize').subscribe(() => {
            this.calculateScreenHeight();
            this.setSVGWidth();
        });
        this.orientationEvent$ = fromEvent(window, 'orientationchange').subscribe(() => {
            this.calculateScreenHeight();
            this.setSVGWidth();
        });
        if (this.carExteriorSVG) {
            this.carSVGClick$ = fromEvent(this.carExteriorSVG.nativeElement, 'click');
            this.carSVGClick$.pipe(tap((event) => {
                this.getMatchedSubCategory(event);
            })).subscribe();
        }
        if (this.suvExteriorSVG) {
            this.suvSVGClick$ = fromEvent(this.suvExteriorSVG.nativeElement, 'click');
            this.suvSVGClick$.pipe(tap((event) => {
                this.getMatchedSubCategory(event);
            })).subscribe();
        }
        if (this.truckExteriorSVG) {
            this.truckSVGClick$ = fromEvent(this.truckExteriorSVG.nativeElement, 'click');
            this.truckSVGClick$.pipe(tap((event) => {
                this.getMatchedSubCategory(event);
            })).subscribe();
        }
        this.interiorSVGClick$ = fromEvent(this.interiorSVG.nativeElement, 'click');
        this.interiorSVGClick$.pipe(tap((event) => {
            this.getMatchedSubCategory(event);
        })).subscribe();
        const self = this;
        let insideNode = ['path', 'rect', 'circle', 'ellipse', 'line'];
        $(document).on('mouseup', function (event) {
            if (insideNode.findIndex(x => x == event.target.nodeName) == -1 &&
                !event.target['classList'].contains('click-tool')) {
                self.highlightComponentBasedOnSubCategory(false);
                self.dragItem = null;
                self.reverseHighLightingElement = null;
                if (self.toolAccordion)
                    self.toolAccordion.collapseAll();
            }
        });
        this.defaultRotation();
    }
    defaultRotation() {
        this.isRotate = true;
        this.setRotation();
    }
    setRotation() {
        this.isRotate = !this.isRotate;
        let svg = this.getActiveSVGElement();
        this.render2.setStyle(svg.nativeElement, "transform", `${this.isRotate ? `rotate(180deg)` : ''}`);
        this.rotateOpCodeMarker();
    }
    rotateOpCodeMarker() {
        this.selectedOpCodes.forEach(e => {
            let marker = this.render2.selectRootElement(`g[id="${e.name}"] > text`);
            let x = this.render2.selectRootElement('g[id="' + e.name + '"] > text').x.baseVal[0].value;
            let y = this.render2.selectRootElement('g[id="' + e.name + '"] > text').y.baseVal[0].value;
            this.render2.setAttribute(marker, "transform", `${this.isRotate ? `rotate(180 ${x + (marker.dataset.code.length > 2 ? 13 : 10)} ${y - 6})` : ""}`);
            this.render2.appendChild(marker, this.render2.createText(marker.dataset.code));
        });
    }
    onAccordionChange(event) {
        this.openToolAccordionByIds[event.panelId] = event.nextState;
    }
    hideToolPanel(isHide) {
        this.isToolHide = isHide;
    }
    onTabChange(event) {
        this.activeTabId = event.nextId;
        if (this.toolAccordion)
            this.toolAccordion.collapseAll();
        if (this.activeTabId === 'infoTab') {
            this.hideToolPanel(true);
        }
        if (this.activeTabId === 'exteriorTab') {
            this.filterToolByLayout(VehicleLayoutType.Exterior);
            this.openToolAccordionByIds = {};
            this.hideToolPanel(false);
            this.defaultRotation();
        }
        if (this.activeTabId === 'interiorTab') {
            this.filterToolByLayout(VehicleLayoutType.Interior);
            this.openToolAccordionByIds = {};
            this.hideToolPanel(false);
            this.defaultRotation();
        }
        if (this.activeTabId === 'summaryTab') {
            this.preViewPriceOfSelectedOpCodes();
            this.hideToolPanel(true);
        }
    }
    filterToolByLayout(vehicleLayoutType) {
        let categoryWithoutSubcategory = this.categories.filter(x => x.subCategories == null
            && x.operationCodes.some(x => x.vehicleLayoutType == vehicleLayoutType));
        let categoryWithSubcategory = this.categories.filter(x => x.subCategories.some(x => x.operationCodes.some(x => x.vehicleLayoutType == vehicleLayoutType)));
        this.filteredCategories = categoryWithoutSubcategory.concat(categoryWithSubcategory);
    }
    loadVehicleQueueDetail() {
        this.vehicleQueueService.get(this.vehicleQueueId).subscribe((vehicleQueue) => {
            this.vehicleQueueModel = vehicleQueue;
            this.model.vehicleVideos = vehicleQueue.vehicleVideos;
            this.vehicleQueueStageModel = this.vehicleQueueModel.vehicleQueueStages.find(x => x.id == this.vehicleQueueStageId);
            if (this.vehicleQueueModel.storeId != this.storeId) {
                this.messagingService.ProcessErrorResponse(`This vehicle(VehicleQueueId ${vehicleQueue.id}| Store(${vehicleQueue.storeId})) is not belongs to the selected store(${this.storeId})`);
                return;
            }
            if (this.vehicleQueueModel.vehicleType != this.vehicleTypeId) {
                this.messagingService.error(`This is invalid vehicle type.`);
                return;
            }
            this.loadData();
        });
    }
    loadData() {
        this.blockUI.start();
        forkJoin([
            this.categoryService.selectListForIVL(this.storeId, this.workflowId),
            this.priceMatrixService.getAllPriceMatricesByUnitofMeasure(this.storeId, this.workflowId, UnitOfMeasure.Spot),
            this.workflowService.getMandatoryOperationCode(this.workflowId, Category.Assessment),
            this.workflowService.getStoreSelectList(this.storeId),
            this.vehicleQueueService.getVehicleWorkDetailByStage(this.vehicleQueueId, this.vehicleQueueStageId)
        ]).subscribe(([category, spotPriceMatrices, mandatoryOpCodes, workflows, vehicleWorkDetails]) => {
            this.categories = category;
            this.spotPriceMatrices = spotPriceMatrices;
            this.mandatoryOpCodesExceptMechanical = mandatoryOpCodes.filter(x => x.categoryName !== Category.Mechanical);
            this.hasWholesale = workflows.filter(x => x.checkInTypeName == Category.Wholesale).length > 0;
            this.filterToolByLayout(VehicleLayoutType.Exterior);
            this.vehicleWorkDetails = vehicleWorkDetails;
            setTimeout(() => {
                let exteriorSVGElement = this.getExteriorSVGElement();
                this.loadDraftMarker(exteriorSVGElement.nativeElement, exteriorSVGElement);
                this.loadDraftMarker(this.interiorSVG.nativeElement, this.interiorSVG);
            }, 500);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    // private fillFormData(vehicleWorkDetails: Array<VehicleWorkDetailModel>) {
    // 	vehicleWorkDetails.forEach(x => {
    // 		let opCode = new IVLOperationCodeSelectListModel();
    // 		opCode.id = x.operationCodeId;
    // 		opCode.name = x.operationCodeDetailModel.name;
    // 		opCode.categoryId = x.operationCodeDetailModel.categoryId;
    // 		opCode.subCategoryId = x.operationCodeDetailModel.subCategoryId;
    // 		opCode.carLayoutPanelId = x.operationCodeDetailModel.carLayoutPanelId;
    // 		opCode.coordinateX = x.coordinateX;
    // 		opCode.coordinateY = x.coordinateY;
    // 		this.selectedOpCodes.push(opCode);
    // 	});
    // }
    getExteriorSVGElement() {
        if (this.vehicleTypeId == this.VehicleType.Car)
            return this.carExteriorSVG;
        if (this.vehicleTypeId == this.VehicleType.Suv)
            return this.suvExteriorSVG;
        if (this.vehicleTypeId == this.VehicleType.Truck)
            return this.truckExteriorSVG;
    }
    getActiveSVGElement() {
        if (this.activeTabId === "exteriorTab") {
            return this.getExteriorSVGElement();
        }
        if (this.activeTabId === "interiorTab") {
            return this.interiorSVG;
        }
    }
    loadDraftMarker(element, parentElem) {
        for (var i = 0; i < element.children.length; i++) {
            let innerEle = element.children[i];
            if (this.vehicleWorkDetails.some(z => innerEle.id == this.appUtils.replaceHashWithUnderscore(z.operationCodeDetailModel.name).split('_').slice(2, 7).join('_'))) {
                this.categories.forEach(x => {
                    x.subCategories.forEach(y => {
                        let opCode = this.vehicleWorkDetails.find(z => y.operationCodes.some(s => this.appUtils.replaceHashWithUnderscore(s.name).split('_').slice(2, 7).join('_') == innerEle.id
                            && s.id == z.operationCodeId));
                        if (opCode) {
                            this.dragItem = y;
                            this.addOpCodeMarker({
                                target: innerEle,
                                clientX: opCode.coordinateX * window.innerWidth,
                                clientY: opCode.coordinateY * window.innerHeight
                            }, parentElem);
                        }
                    });
                });
            }
            this.loadDraftMarker(innerEle, parentElem);
        }
        this.dragItem = null;
    }
    dndStart(subCategory) {
        this.dragItem = subCategory;
        this.highlightComponentBasedOnSubCategory(true);
    }
    dndEnd() {
        this.highlightComponentBasedOnSubCategory(false);
    }
    onDrop($event) {
        this.addOpCodeMarker($event.event, this.getActiveSVGElement());
    }
    onDragCanceled() {
        this.dragItem = null;
        this.reverseHighLightingElement = null;
    }
    onClickSelectSubCategory(subCategory) {
        this.dragItem = subCategory;
        if (this.reverseHighLightingElement) {
            this.addOpCodeMarker(this.reverseHighLightingElement, this.getActiveSVGElement());
        }
        else {
            this.highlightComponentBasedOnSubCategory(false);
            this.highlightComponentBasedOnSubCategory(true);
        }
    }
    getMatchedSubCategory(ele) {
        // this.toolAccordion.collapseAll();
        // this.highlightComponentBasedOnSubCategory(false);
        if (!ele.target || !ele.target.id) {
            return null;
        }
        if (this.dragItem) {
            this.addOpCodeMarker(ele, this.getActiveSVGElement());
            return;
        }
        this.highlightComponentBasedOnSubCategory(false);
        let opCode = ele.target.id;
        this.reverseHighLightingElement = null;
        this.categories.forEach(x => {
            x.subCategories.forEach(y => {
                if (y.operationCodes.some(z => this.appUtils.replaceHashWithUnderscore(z.name).split('_').slice(2, 7).join('_') == opCode)) {
                    y.isHighlight = true;
                    this.reverseHighLightingElement = ele;
                    this.oldScreenHeight = window.innerHeight;
                    this.oldScreenWidth = window.innerWidth;
                    this.oldSVGHeight = this.getActiveSVGElement().nativeElement.height.animVal.value;
                    this.oldSVGWidth = this.getActiveSVGElement().nativeElement.width.animVal.value;
                }
            });
            if (x.subCategories.filter(x => x.isHighlight).length > 0) {
                if (!this.openToolAccordionByIds[x.name])
                    this.openToolAccordionByIds[x.name] = true;
                this.toolAccordion.expand(x.name);
            }
            else {
                this.openToolAccordionByIds[x.name] = false;
                this.toolAccordion.collapse(x.name);
            }
        });
        if (this.reverseHighLightingElement) {
            this.highlightAllChildPath(ele.target, true, 'highlight-component');
            this.highlightDuplicateComponent(ele.target, this.getActiveSVGElement().nativeElement, true, 'highlight-component');
        }
    }
    getMatchedOpCode(ele) {
        if (!ele.id || !this.dragItem) {
            return null;
        }
        let opCodes = this.dragItem.operationCodes.find(x => {
            let opCode = this.appUtils.replaceHashWithUnderscore(x.name).split('_');
            return ele.id === opCode.slice(2, 7).join('_');
        });
        return opCodes;
    }
    highlightComponentBasedOnSubCategory(isHighlight) {
        const imgElm = this.getActiveSVGElement();
        if (!imgElm)
            return;
        const gTag = imgElm.nativeElement;
        this.highlightComponent(gTag, isHighlight, 'highlight-component');
    }
    highlightDuplicateComponent(selectElm, parentElm, isHighlight, cssClass) {
        if (selectElm.id == parentElm.id) {
            this.highlightAllChildPath(parentElm, isHighlight, cssClass);
        }
        else {
            var i = 0;
            for (i = 0; i < parentElm.children.length; i++) {
                this.highlightDuplicateComponent(selectElm, parentElm.children[i], isHighlight, cssClass);
            }
        }
    }
    highlightComponent(ele, isHighlight, cssClass) {
        if (this.getMatchedOpCode(ele) || !isHighlight) {
            this.highlightAllChildPath(ele, isHighlight, cssClass);
        }
        else {
            var i = 0;
            for (i = 0; i < ele.children.length; i++) {
                const parentElm = ele.children[i];
                this.highlightComponent(parentElm, isHighlight, cssClass);
            }
        }
        if (this.reverseHighLightingElement) {
            this.categories.forEach(x => x.subCategories.forEach(x => {
                x.isHighlight = false;
            }));
        }
    }
    highlightAllChildPath(elm, isHighlight, cssClass) {
        if (elm.children.length == 0) {
            if (isHighlight) {
                this.render2.addClass(elm, cssClass);
            }
            else {
                this.render2.removeClass(elm, cssClass);
            }
        }
        else {
            var i = 0;
            for (i = 0; i < elm.children.length; i++) {
                const parentElm = elm.children[i];
                this.highlightAllChildPath(parentElm, isHighlight, cssClass);
            }
        }
    }
    getSelectedCategoryColorCode() {
        return this.categories.find(x => x.subCategories.some(z => z === this.dragItem)).colorCode;
    }
    preViewPriceOfSelectedOpCodes() {
        this.frmSummary.controls.prices.clear();
        if (this.selectedOpCodes.length == 0 && this.mandatoryOpCodesExceptMechanical.length == 0) {
            return;
        }
        this.blockUI.start();
        let opCodeIds = this.selectedOpCodes.map(x => x.id);
        if (this.mandatoryOpCodesExceptMechanical.length > 0) {
            opCodeIds = [...opCodeIds, ...this.mandatoryOpCodesExceptMechanical.map(x => x.id)];
        }
        this.priceMatrixService.getPriceOfSelectedOpCode(this.storeId, this.workflowId, opCodeIds)
            .subscribe((data) => {
            this.appUtils.sort(data, 'categoryName');
            data.forEach(x => {
                let mandatoryOpCode = this.mandatoryOpCodesExceptMechanical
                    .find(z => z.id == x.operationCodeId);
                if (x.subCategoryId || (mandatoryOpCode && mandatoryOpCode.subCategoryId && x.unitOfMeasure != UnitOfMeasure.None)) {
                    let previousSavedData = this.vehicleWorkDetails.find(y => y.operationCodeId == x.operationCodeId);
                    let lastComment = previousSavedData && previousSavedData.commentModels.length > 0 ?
                        previousSavedData.commentModels[previousSavedData.commentModels.length - 1].comment
                        : '';
                    let fb = this.formBuilder.group({
                        opCodeId: x.operationCodeId,
                        opCode: x.operationCodeName,
                        price: [previousSavedData ? previousSavedData.cost : x.price,
                            [Validators.required, Validators.min(0)]
                        ],
                        unitOfMeasure: x.unitOfMeasure,
                        categoryId: x.categoryId,
                        categoryName: x.categoryName,
                        subCategoryId: x.subCategoryId,
                        subCategoryName: x.subCategoryName,
                        carLayoutPanelId: x.carLayoutPanelId,
                        carLayoutPanelName: x.carLayoutPanelName,
                        carLayoutLocationName: x.carLayoutLocationName,
                        carLayoutSideName: x.carLayoutSideName,
                        comments: [lastComment, Validators.maxLength(new StringLengthConstants().Default)],
                        addedOn: new Date()
                    });
                    this.frmSummary.controls.prices.push(fb);
                }
            });
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    getFormArrayCategoryNames() {
        return [...new Set(this.frmSummary.controls.prices.controls.map(x => x.value.categoryName))];
    }
    getArrayControlsByCategory(categoryName) {
        return this.frmSummary.controls.prices.controls.filter(x => x.value.categoryName == categoryName);
    }
    getTotalPriceByCategory(categoryName) {
        var total = 0;
        this.frmSummary.controls.prices.controls.filter(x => x.value.categoryName == categoryName)
            .map(x => {
            total += Number(x.value.price);
        });
        return total;
    }
    isApplyOpCodeBasedOnUnitOfMeasure(currentOpCode) {
        let spotPrice = this.spotPriceMatrices.find(x => x.unitOfMeasure == this.UnitOfMeasure.Spot &&
            x.categoryId == currentOpCode.categoryId && x.subCategoryId == currentOpCode.subCategoryId);
        if (!spotPrice)
            return true;
        let selectedOpCodes = this.selectedOpCodes.filter(x => x.categoryId == currentOpCode.categoryId
            && x.subCategoryId == currentOpCode.subCategoryId
            && spotPrice.carLayoutPanelIds.includes(currentOpCode.carLayoutPanelId)
            && spotPrice.carLayoutPanelIds.includes(x.carLayoutPanelId));
        return selectedOpCodes.length < spotPrice.unitPrices.length;
    }
    addOpCodeMarker($event, parentElem) {
        let selectedSVGPath = $event.target;
        // this.highlightComponentBasedOnSubCategory(false);
        let currentOpCode = this.getMatchedOpCode(selectedSVGPath);
        if (!currentOpCode ||
            this.selectedOpCodes.some(x => x.name == currentOpCode.name) ||
            !this.isApplyOpCodeBasedOnUnitOfMeasure(currentOpCode)) {
            // this.highlightComponentBasedOnSubCategory(false);
            // this.dragItem = null;
            // this.reverseHighLightingElement = null;
            return;
        }
        this.render2.addClass(selectedSVGPath, 'selected-component');
        this.render2.addClass(selectedSVGPath, 'marker-' + this.appUtils.replaceHashWithUnderscore(currentOpCode.name));
        const wrapperGTag = parentElem.nativeElement.children[0];
        //Calculate SVG Points
        let pt = parentElem.nativeElement.createSVGPoint();
        pt.x = $event.clientX;
        pt.y = $event.clientY;
        currentOpCode.coordinateX = $event.clientX / window.innerWidth;
        currentOpCode.coordinateY = $event.clientY / window.innerHeight;
        // The cursor point, translated into svg coordinates
        var cursorpt = pt.matrixTransform(wrapperGTag.getScreenCTM().inverse());
        const gTag = this.createMarkerTag(currentOpCode, cursorpt.x - 10, cursorpt.y - 10);
        this.render2.appendChild(wrapperGTag, gTag);
        this.render2.listen(gTag, 'click', evt => {
            this.removeOpCodeMarker(evt, parentElem);
        });
        currentOpCode.code = this.dragItem.code;
        this.selectedOpCodes.push(currentOpCode);
        // this.dragItem = null;
        if (this.reverseHighLightingElement) {
            this.dragItem = null;
        }
        this.hasQuotation();
    }
    createMarkerTag(currentOpCode, x, y) {
        let markerHtml = `
		<svg xmlns="http://www.w3.org/2000/svg">
			<g id="${currentOpCode.name}"class="cursor-pointer">
				<rect width="40" height="40" fill="${this.getSelectedCategoryColorCode()}" ry="20" x="${x - 7}" y="${y - 5}"></rect>
				<text fill="#fff" x="${x + (this.dragItem.code.length > 2 ? -1 : 2)}" y="${y + 21}"
					transform= "${this.isRotate ? `rotate(180 ${x + (this.dragItem.code.length > 2 ? 12 : 13)} ${y + 15})` : ""}" 
					data-code="${this.dragItem.code}">
					${this.dragItem.code}
				</text>
			</g>
		</svg>`;
        return new DOMParser().parseFromString(markerHtml, 'text/html').body.firstElementChild.firstElementChild;
    }
    removeOpCodeMarker(evt, parentElem) {
        this.render2.removeChild(parentElem, evt.target.parentElement);
        const opCode = evt.target.parentElement.id;
        const selectedSVGPath = this.render2.selectRootElement('.marker-' + this.appUtils.replaceHashWithUnderscore(opCode));
        this.render2.removeClass(selectedSVGPath, 'marker-' + this.appUtils.replaceHashWithUnderscore(opCode));
        const classes = selectedSVGPath.classList;
        let hasMarker = false;
        classes.forEach(function (item) {
            if (item.indexOf('marker-') == 0) {
                hasMarker = true;
            }
        });
        if (!hasMarker) {
            this.render2.removeClass(selectedSVGPath, 'selected-component');
        }
        this.selectedOpCodes = this.selectedOpCodes.filter(x => x.name !== opCode);
        this.hasQuotation();
    }
    emitRecordedData(data) {
        this.uploadRecordedVideo(data);
    }
    onSubmit() {
        // if (this.selectedOpCodes.length == 0) {
        // 	this.messagingService.error("You can't proceed without operation code, please select at least one!");
        // 	return;
        // }
        if (this.isVideoRequiredForQuote()) {
            this.messagingService.error("You can't proceed without video recording, please record video for all selected quote!");
            return false;
        }
        this.saveAssessment(false);
    }
    onPause() {
        this.saveAssessment(true);
    }
    isVideoRequiredForQuote() {
        let isRequired = false;
        let category = this.categories.find(x => x.name == this.EnumCategory.Quote);
        if (category.subCategories.some(x => x.name == SubCategory.CollisionQuote
            && this.selectedOpCodes.some(z => z.subCategoryId == x.id))) {
            isRequired = !this.model.vehicleVideos.some(x => x.vehicleVideoType == VehicleVideoType.CQ);
            return isRequired;
        }
        if (category.subCategories.some(x => x.name == SubCategory.GlassQuote
            && this.selectedOpCodes.some(z => z.subCategoryId == x.id))) {
            isRequired = !this.model.vehicleVideos.some(x => x.vehicleVideoType == VehicleVideoType.GQ);
            return isRequired;
        }
        if (category.subCategories.some(x => x.name == SubCategory.HailQuote
            && this.selectedOpCodes.some(z => z.subCategoryId == x.id))) {
            isRequired = !this.model.vehicleVideos.some(x => x.vehicleVideoType == VehicleVideoType.HQ);
            return isRequired;
        }
        return isRequired;
    }
    saveAssessment(isPaused) {
        this.submitted = true;
        if (this.frmSummary.invalid) {
            return;
        }
        this.model.vehicleQueueId = Number(this.vehicleQueueId);
        this.model.storeId = Number(this.storeId);
        this.model.vehicleQueueStageId = Number(this.vehicleQueueStageId);
        this.model.operationCodePrices = this.frmSummary.controls.prices.value.map((x) => {
            let opCode = new OperationCodePriceModel();
            opCode.operationCodeId = Number(x.opCodeId);
            opCode.price = Number(x.price);
            opCode.categoryId = Number(x.categoryId);
            opCode.subCategoryId = Number(x.subCategoryId);
            opCode.carLayoutPanelId = Number(x.carLayoutPanelId);
            opCode.comment = x.comments;
            let coordinate = this.selectedOpCodes.find(z => z.id == x.opCodeId);
            if (coordinate) {
                opCode.coordinateX = coordinate.coordinateX;
                opCode.coordinateY = coordinate.coordinateY;
            }
            else {
                opCode.coordinateX = 0;
                opCode.coordinateY = 0;
            }
            return opCode;
        });
        this.blockUI.start();
        let result;
        if (isPaused) {
            result = this.vehicleQueueService.draftVehicleAssessment(this.model);
        }
        else {
            result = this.vehicleQueueService.saveVehicleAssessment(this.model);
        }
        result.subscribe(x => {
            this.dataSubmitted = true;
            setTimeout(() => {
                this.router.navigate(['']);
            }, 10);
            setTimeout(() => {
                if (isPaused) {
                    this.messagingService.success('Vehicle assessment paused successfully.');
                }
                else {
                    this.messagingService.success('Vehicle assessment completed successfully.');
                }
            }, 300);
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    reomveVideo(fileName) {
        // this.blockUI.start();
        this.model.vehicleVideos = this.model.vehicleVideos.filter(x => x.fileName != fileName);
        // this.storageService.delete(StorageContainerType.AssessmentVideo, fileName)
        // 	.subscribe((data) => {
        // 		this.blockUI.stop();
        // 	});
    }
    uploadRecordedVideo(data) {
        if (data.blobData.size < 1) {
            this.messagingService.error("video is not recorded, please try again!");
            return;
        }
        this.blockUI.start();
        this.storageService.getSasToken().subscribe((sasToken) => __awaiter(this, void 0, void 0, function* () {
            const cloudConfig = {
                sas: sasToken.toString(),
                storageAccount: environment.azureAccount,
                containerName: StorageContainerType.AssessmentVideo
            };
            const fileExtension = 'mp4';
            const fileName = `${this.vehicleQueueId}/${VehicleVideoType[data.vehicleVideoType]}_${this.vehicleQueueModel.stockNumber}_${new Date().getUTCFullYear()}${('0' + (new Date().getUTCMonth() + 1)).slice(-2)}${('0' + new Date().getUTCDate()).slice(-2)}.${fileExtension}`;
            const blobUrl = this.blobService.generateBlobUrl(cloudConfig, fileName);
            let config = {
                baseUrl: `${blobUrl}?`,
                sasToken: cloudConfig.sas,
                blockSize: 1024 * 64,
                file: new File([data.blobData], fileName),
                complete: () => {
                    let video = this.model.vehicleVideos.find(x => x.vehicleVideoType == data.vehicleVideoType);
                    if (video && video.fileName != fileName) {
                        this.storageService.delete(StorageContainerType.AssessmentVideo, video.fileName).subscribe();
                    }
                    this.model.vehicleVideos = this.model.vehicleVideos.filter(x => x.vehicleVideoType != data.vehicleVideoType);
                    let model = new VehicleVideoAddModel();
                    model.fileName = fileName;
                    model.vehicleVideoType = Number(data.vehicleVideoType);
                    this.model.vehicleVideos.push(model);
                    this.blockUI.stop();
                },
                error: (err) => {
                    this.messagingService.ProcessErrorResponse(err, 'Video File upload timeout.');
                    this.blockUI.stop();
                },
                progress: (percent) => {
                    this.uploadedPercent = percent;
                }
            };
            setTimeout(() => {
                this.blobService.upload(config);
            });
        }), error => {
            this.messagingService.ProcessErrorResponse(error);
        });
    }
    onBlurOnComment(event) {
        event.target.scrollTop = 0;
    }
    clearAll() {
        let frmArray = this.frmSummary.controls.prices;
        frmArray.controls.forEach((data) => {
            let frm = data;
            let element = this.render2.selectRootElement('g[id="' + frm.controls.opCode.value + '"]');
            if (frm.controls.categoryName.value != Category.Interior) {
                this.removeOpCodeMarker({ target: { parentElement: element } }, this.getExteriorSVGElement());
            }
            else {
                this.removeOpCodeMarker({ target: { parentElement: element } }, this.interiorSVG);
            }
        });
        frmArray.clear();
        this.selectedOpCodes = new Array();
        this.model.vehicleVideos = new Array();
    }
    hasQuotation() {
        this.videoUploadType = new Array();
        let category = this.categories.find(x => x.name == this.EnumCategory.Quote);
        this.hasVideo = this.selectedOpCodes.some(z => z.categoryId == category.id);
        if (category.subCategories.some(x => x.name == SubCategory.CollisionQuote
            && this.selectedOpCodes.some(z => z.subCategoryId == x.id))) {
            this.videoUploadType.push(VehicleVideoType.CQ);
        }
        if (category.subCategories.some(x => x.name == SubCategory.GlassQuote
            && this.selectedOpCodes.some(z => z.subCategoryId == x.id))) {
            this.videoUploadType.push(VehicleVideoType.GQ);
        }
        if (category.subCategories.some(x => x.name == SubCategory.HailQuote
            && this.selectedOpCodes.some(z => z.subCategoryId == x.id))) {
            this.videoUploadType.push(VehicleVideoType.HQ);
        }
    }
}
__decorate([
    BlockUI('container-blockui'),
    __metadata("design:type", Object)
], AssessmentComponent.prototype, "blockUI", void 0);
