var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { AfterViewInit, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI } from "ng-block-ui";
import { StringLengthConstants } from "src/app/helpers";
import { VehicleQueueNoteAddModel } from "src/app/models";
import { ApplicationContextService, MessagingService, VehicleQueueService } from "src/app/services";
export class VehicleQueueNoteComponent {
    constructor(formBuilder, modalService, stringLengthConstants, messagingService, vehicleQueueService, applicationContextService) {
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.stringLengthConstants = stringLengthConstants;
        this.messagingService = messagingService;
        this.vehicleQueueService = vehicleQueueService;
        this.applicationContextService = applicationContextService;
        this.lastComment = 'No Comment';
        this.submitted = false;
        this.queueNotes = new Array();
    }
    ngOnInit() {
        this.frmNote = this.formBuilder.group({
            storeId: [],
            note: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Default)]]
        });
    }
    ngAfterViewInit() {
        this.storeSubscription = this.applicationContextService.store$.subscribe((storeSelectListItem) => {
            if (storeSelectListItem) {
                this.frmNote.controls.storeId.setValue(storeSelectListItem.id);
            }
            else {
                this.frmNote.controls.storeId.setValue(null);
            }
        });
    }
    ngOnChanges(changes) {
        if (this.queueNotes.length > 0) {
            this.lastComment = this.queueNotes[0].comment;
        }
    }
    loadNotes() {
        this.vehicleQueueService.getNotes(this.vehicleQueueId)
            .subscribe((data) => {
            this.queueNotes = data;
            if (this.queueNotes.length > 0) {
                this.lastComment = this.queueNotes[0].comment;
            }
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    openModal() {
        this.loadNotes();
        this.modalReference = this.modalService.open(this.confirmationModal, { centered: true, scrollable: true, size: 'lg' });
    }
    onSubmit() {
        this.submitted = true;
        if (this.frmNote.invalid) {
            return;
        }
        let model = new VehicleQueueNoteAddModel();
        model.vehicleQueueId = this.vehicleQueueId;
        model.comment = this.frmNote.controls.note.value;
        model.storeId = this.frmNote.controls.storeId.value;
        this.blockUI.start();
        this.vehicleQueueService.add(model)
            .subscribe(() => {
            this.frmNote.controls.note.setValue('');
            this.loadNotes();
            this.submitted = false;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        }, () => {
            this.blockUI.stop();
        });
    }
    onCancel() {
        if (!this.modalReference) {
            return;
        }
        this.modalReference.close();
    }
    ngOnDestroy() {
        this.onCancel();
        this.storeSubscription.unsubscribe();
    }
}
__decorate([
    BlockUI('container-blockui-vehicle-note'),
    __metadata("design:type", Object)
], VehicleQueueNoteComponent.prototype, "blockUI", void 0);
