var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BlockUI } from "ng-block-ui";
import { ToastrService } from "ngx-toastr";
import { StringLengthConstants } from "src/app/helpers";
import { SubCategoryAddModel } from "src/app/models";
import { CategoryService, MessagingService, SubCategoryService } from "src/app/services";
export class SubCategoryAddComponent {
    constructor(formBuilder, router, toastr, messagingService, categoryService, subCategoryService, stringLengthConstants) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.toastr = toastr;
        this.messagingService = messagingService;
        this.categoryService = categoryService;
        this.subCategoryService = subCategoryService;
        this.stringLengthConstants = stringLengthConstants;
        this.submitted = false;
        this.model = new SubCategoryAddModel();
        this.categories = new Array();
    }
    ngOnInit() {
        this.loadCategory();
        this.frmSubCategory = this.formBuilder.group({
            categoryId: ['', [Validators.required]],
            name: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            code: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.ShortCode), Validators.minLength(2), Validators.pattern(new RegExp('^[A-Za-z0-9]*$'))]],
            description: ['', [Validators.maxLength(this.stringLengthConstants.Description)]],
            sequence: ['', [Validators.min(1), Validators.pattern(new RegExp('^[-+]?[0-9]*\.?[0-9]*$'))]]
        });
    }
    onSubmit() {
        this.submitted = true;
        if (this.frmSubCategory.invalid) {
            return;
        }
        this.model.categoryId = Number(this.frmSubCategory.controls.categoryId.value);
        this.model.name = this.frmSubCategory.controls.name.value;
        this.model.description = this.frmSubCategory.controls.description.value;
        this.model.code = this.frmSubCategory.controls.code.value;
        this.model.sequence = Number(this.frmSubCategory.controls.sequence.value);
        this.subCategoryService.add(this.model)
            .subscribe((data) => {
            setTimeout(() => {
                this.router.navigate(['/manage/sub-category']);
            }, 10);
            setTimeout(() => {
                this.toastr.success('Tool has been added successfully.');
            }, 300);
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    loadCategory() {
        this.blockUI.start();
        this.categoryService.selectList().subscribe((data) => {
            this.categories = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
}
__decorate([
    BlockUI('blockui-container'),
    __metadata("design:type", Object)
], SubCategoryAddComponent.prototype, "blockUI", void 0);
