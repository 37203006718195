import { PermissionType } from "src/app/enums";
import { VehicleQueueModel } from "../vehicle.queue.model";

export class LandingPageQueueModel {
    //View Properties
    get panelId(): string {
        return this.name.replace(/ /g, '');
    }
    public isVisible: boolean;

    public sequence: number;
    public isExpaned: boolean;
    public name: string;

    public categories: Array<string>;
    public permissions: Array<PermissionType>;

    public vehicleQueueItems: Array<VehicleQueueModel>;
    public isFullQueue: boolean;


    constructor() {
        this.categories = new Array<string>();
        this.permissions = new Array<PermissionType>();
        this.vehicleQueueItems = new Array<VehicleQueueModel>();
        this.isExpaned = false;
        this.isFullQueue = false;
        this.isVisible = false;
    }
}