var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI } from 'ng-block-ui';
import { InspectionStatus } from 'src/app/enums';
import { FinalInspectionItemModel, FinalInspectionModel, VehicleQueueModel, VehicleQueueStageModel } from 'src/app/models';
import { MessagingService, VehicleQueueService } from 'src/app/services';
export class FinalInspectionComponent {
    constructor(formBuilder, activatedRoute, router, messagingService, vehicleQueueService) {
        this.formBuilder = formBuilder;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.messagingService = messagingService;
        this.vehicleQueueService = vehicleQueueService;
        this.vehicleinqueue = "assets/images/vechile-in-queue.png";
        this.vehicleGraph = "assets/images/vechile-queue-graph.png";
        this.vehicleQueueModel = new VehicleQueueModel();
        this.vehicleQueueStageModel = new VehicleQueueStageModel();
        this.vehicleWorkDetailModel = new Array();
        this.activatedRoute.params.subscribe((data) => {
            this.vehicleQueueId = data.vehicleQueueId;
            this.vehicleQueueStageId = data.vehicleQueueStageId;
        });
    }
    ngOnInit() {
        this.frmFinalInspection = this.formBuilder.group({
            selectAll: [false],
            opCodes: this.formBuilder.array([]),
        });
        this.loadVehicleQueue();
    }
    getFormArrayControls() {
        return this.frmFinalInspection.controls.opCodes.controls;
    }
    loadVehicleQueue() {
        this.blockUI.start();
        this.vehicleQueueService.get(this.vehicleQueueId)
            .subscribe((vehicleQueue) => {
            if (!vehicleQueue) {
                this.messagingService.ProcessErrorResponse(null, "This vehicle is not belongs to the selected store");
                return;
            }
            this.vehicleQueueModel = vehicleQueue;
            this.vehicleQueueStageModel = this.vehicleQueueModel.vehicleQueueStages.find(x => x.id == this.vehicleQueueStageId);
            this.loadData();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    loadData() {
        this.blockUI.start();
        this.vehicleQueueService.getFinalInspectionWorkDetail(this.vehicleQueueId)
            .subscribe((workDetails) => {
            this.vehicleWorkDetailModel = workDetails;
            this.loadFormData();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    loadFormData() {
        this.vehicleWorkDetailModel.forEach(data => {
            let isApproved = data.inspectionStatus == InspectionStatus.Accepted;
            let frm = this.formBuilder.group({
                workDetailId: [data.id],
                operationCodeId: [data.operationCodeId],
                operationCodeName: [data.operationCodeDetailModel.name],
                categoryName: [data.operationCodeDetailModel.categoryName],
                subCategoryName: [data.operationCodeDetailModel.subCategoryName],
                locationName: [data.operationCodeDetailModel.carLayoutLocationName],
                panelName: [data.operationCodeDetailModel.carLayoutPanelName],
                sideName: [data.operationCodeDetailModel.carLayoutSideName],
                workerName: [data.workerUser],
                isApproved: [{ value: isApproved, disabled: isApproved }],
            });
            this.frmFinalInspection.controls.opCodes.push(frm);
        });
        let hasNotStarted = this.vehicleWorkDetailModel.every(x => x.inspectionStatus == InspectionStatus.NotStarted);
        if (!hasNotStarted)
            this.frmFinalInspection.controls.selectAll.disable();
    }
    onSelectAllChange() {
        let opCodes = this.frmFinalInspection.controls.opCodes;
        opCodes.controls.forEach(elem => {
            let fb = elem;
            fb.controls.isApproved.setValue(this.frmFinalInspection.controls.selectAll.value);
        });
    }
    onIsSelectedChange() {
        let opCodes = this.frmFinalInspection.controls.opCodes;
        let selectValues = opCodes.controls.map(elem => {
            let fb = elem;
            return fb.controls.isApproved.value;
        });
        //If every element is selected
        this.frmFinalInspection.controls.selectAll.setValue(selectValues.every(x => x == true));
    }
    onSubmit() {
        let model = new FinalInspectionModel();
        model.vehicleQueueId = this.vehicleQueueId;
        model.vehicleQueueStageId = this.vehicleQueueStageId;
        this.frmFinalInspection.controls.opCodes.controls.forEach(element => {
            let fb = element;
            let itemModel = new FinalInspectionItemModel();
            itemModel.workDetailId = Number(fb.controls.workDetailId.value);
            itemModel.operationCodeId = Number(fb.controls.operationCodeId.value);
            itemModel.inspectionStatus = Boolean(fb.controls.isApproved.value) ?
                InspectionStatus.Accepted : InspectionStatus.Rejected;
            model.finalInspectionItemModels.push(itemModel);
        });
        this.vehicleQueueService.saveFinalInspectionWorkDetail(model).subscribe(() => {
            setTimeout(() => {
                this.router.navigate(['/']);
            }, 10);
            setTimeout(() => {
                this.messagingService.success('Final inspection successfully.');
            }, 300);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
}
__decorate([
    BlockUI('container-blockui'),
    __metadata("design:type", Object)
], FinalInspectionComponent.prototype, "blockUI", void 0);
