import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { StringLengthConstants } from 'src/app/helpers';
import { VehicleQueueModel, VehicleQueueStageModel, VehicleWorkDetailModel } from 'src/app/models';
import { MessagingService, VehicleQueueService } from 'src/app/services';

@Component({
    selector: 'app-vendor-item-rejection',
    templateUrl: './vendor.item.rejection.component.html',
    styleUrls: ['./vendor.item.rejection.component.css']
})
export class VendorItemRejectionComponent implements OnDestroy {
    @BlockUI('container-blockui-confirmation') blockUI: NgBlockUI;
    @ViewChild('confirmationModal', { static: false }) confirmationModal: any;
    @Output() reject = new EventEmitter<any>();

    modalReference: NgbModalRef;

    model: VehicleWorkDetailModel;
    vehicleQueueStageModel: VehicleQueueStageModel = null;
    vehicleQueueModel: VehicleQueueModel = new VehicleQueueModel();

    id: number;
    rejectedComment: string;
    newCost: number;

    form: FormGroup;
    submitted: boolean;

    constructor(private modalService: NgbModal,
        private formBuilder: FormBuilder,
        private stringLengthConstants: StringLengthConstants,
        private messagingService: MessagingService,
        private vehicleQueueService: VehicleQueueService) {
    }

    openModal(model: VehicleWorkDetailModel, rejectedComment: string, newCost: number) {
        this.model = model;
        this.form = this.formBuilder.group({
            cost: [newCost || 0, [RxwebValidators.compose({
                validators: [
                    RxwebValidators.required(),
                    RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
                    RxwebValidators.minNumber({ value: 0 })
                ]
            })]],
            comment: [rejectedComment || '', [RxwebValidators.compose({
                validators: [
                    RxwebValidators.required(),
                    RxwebValidators.maxLength({ value: this.stringLengthConstants.Default }),
                ]
            })]]
        });

        this.modalReference = this.modalService.open(this.confirmationModal, { centered: true });
    }

    onSubmit() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        setTimeout(() => {
            this.reject.emit({
                id: this.model.id,
                comment: this.form.controls.comment.value,
                cost: this.form.controls.cost.value
            });
          
            this.submitted = false;
        }, 0);

        
       
    }

    onCancel() {
        if (!this.modalReference) {
            return;
        }
        this.modalReference.close();

    }

    ngOnDestroy(): void {
        this.onCancel();
    }
}
