import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class RoleService {
    constructor(http) {
        this.http = http;
    }
    getAlRoles() {
        return this.http.get(`${environment.apiBaseUrl}/role/all`);
    }
    getRoleByType(applicationRoleType) {
        return this.http.get(`${environment.apiBaseUrl}/role/type/${applicationRoleType}`);
    }
    getAllPermissions() {
        return this.http.get(`${environment.apiBaseUrl}/role/permissions`);
    }
    query(dataTablesParameters) {
        return this.http.post(`${environment.apiBaseUrl}/role/query`, dataTablesParameters);
    }
    get(id) {
        return this.http.get(`${environment.apiBaseUrl}/role/${id}`);
    }
    add(data) {
        return this.http.post(`${environment.apiBaseUrl}/role`, data);
    }
    update(data) {
        return this.http.put(`${environment.apiBaseUrl}/role`, data);
    }
    delete(id) {
        return this.http.delete(`${environment.apiBaseUrl}/role/${id}`);
    }
    toggleStatus(id) {
        return this.http.post(`${environment.apiBaseUrl}/role/toggle-status/${id}`, null);
    }
}
RoleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RoleService_Factory() { return new RoleService(i0.ɵɵinject(i1.HttpClient)); }, token: RoleService, providedIn: "root" });
