import { QuotationStatus } from "src/app/enums";
import { QuotationItemModel } from "./quotation.item.model";

export class QuotationModel {
    public id: number;
    public vehicleQueueId: number;
    public vehicleQueueStageId: number;

    public categoryId: number;
    public subCategoryId: number;
    public vendorId: number;

    public biddingStartTime: string;
    public biddingEndTime: string;

    public vendorName: string;
    public categoryName: string;
    public subCategoryName: string;

    public quotationItems: Array<QuotationItemModel>;
    public status: QuotationStatus;

    public queueTimeInSeconds: number;
    public workTimeInSeconds: number;

    public isExpired: boolean;

    constructor() {
        this.quotationItems = new Array<QuotationItemModel>();
    }
}