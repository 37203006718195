import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CheckInTypeAddModel, CheckInTypeDetailModel, CheckInTypeEditModel, CheckInTypeListItemModel, DataTableResponseModel, SelectListModel, WorkflowImportSelectListModel } from "../models";


@Injectable({
    providedIn: 'root'
})

export class CheckInTypeService {
    constructor(private http: HttpClient) {

    }

    public get(id: number): Observable<CheckInTypeDetailModel> {
        return this.http.get<CheckInTypeDetailModel>(`${environment.apiBaseUrl}/check-in-type/${id}`);
    }

    public add(model: CheckInTypeAddModel): Observable<CheckInTypeDetailModel> {
        return this.http.post<CheckInTypeDetailModel>(`${environment.apiBaseUrl}/check-in-type`, model);
    }

    public update(model: CheckInTypeEditModel): Observable<CheckInTypeDetailModel> {
        return this.http.put<CheckInTypeDetailModel>(`${environment.apiBaseUrl}/check-in-type`, model);
    }

    public delete(id: number) {
        return this.http.delete(`${environment.apiBaseUrl}/check-in-type/${id}`);
    }

    public toggleStatus(id: number) {
        return this.http.patch(`${environment.apiBaseUrl}/check-in-type/toggle-status/${id}`, null);
    }

    public query(dataTablesParameters: any) {
        return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/check-in-type/query`, dataTablesParameters, {});
    }

    public selectList(): Observable<Array<SelectListModel>> {
        return this.http.get<Array<SelectListModel>>(`${environment.apiBaseUrl}/check-in-type/select-list`);
    }

    public checkInTypeSelectList(): Observable<Array<CheckInTypeListItemModel>> {
        return this.http.get<Array<CheckInTypeListItemModel>>(`${environment.apiBaseUrl}/check-in-type/select-list-extended`);
    }

    public getCheckInTypeExcludingExistingWorkflowsforStore(storeId: number): Observable<Array<WorkflowImportSelectListModel>> {
        return this.http.get<Array<WorkflowImportSelectListModel>>(`${environment.apiBaseUrl}/check-in-type/select-list/exclude-created-workflow/store/${storeId}`);
    }

    public getFilteredCheckInTypes(companyId: number, storeId: number): Observable<Array<SelectListModel>> {
        return this.http.get<Array<SelectListModel>>(`${environment.apiBaseUrl}/check-in-type/${companyId}/${storeId}/select-list`);
    }
}