import { SelectListModel } from "../generic/select.list.model";

export class CompanyWithStoreModel {
    public id: number;
    public name: string;
    public stores: Array<SelectListModel>;

    constructor() {
        this.stores = new Array<SelectListModel>();
    }
}