import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class StorageService {
    constructor(private http: HttpClient) { }

    getSasToken() {
        return this.http.get(environment.apiBaseUrl + '/storage/get-sas-token');
    }

    getPublicUrl(containerName: string, fileKey: string) {
        return this.http.get(environment.apiBaseUrl + `/storage/get-public-url/${containerName}?fileKey=${fileKey}`);
    }

    delete(containerName: string, fileKey: string) {
        return this.http.delete(environment.apiBaseUrl + `/storage/${containerName}?fileKey=${fileKey}`);
    }
}