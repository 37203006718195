import { Component } from '@angular/core';

@Component({
  selector: 'app-final-Inspection',
  templateUrl: './final-Inspection.component.html',
  styleUrls: ['./final-Inspection.component.css']
})
export class VechilefinalInspectionComponent {

  vehicleinqueue: string = "assets/images/vechile-in-queue.png";
  vehicleGraph: string = "assets/images/vechile-queue-graph.png";
 
}
