import { BehaviorSubject } from "rxjs";
import * as uuid from 'uuid';
import * as i0 from "@angular/core";
export class ApplicationContextService {
    constructor() {
        this.contextSubject$ = new BehaviorSubject(null);
        this.context$ = this.contextSubject$.asObservable();
        this.companySubject$ = new BehaviorSubject(null);
        this.company$ = this.companySubject$.asObservable();
        this.storeSubject$ = new BehaviorSubject(null);
        this.store$ = this.storeSubject$.asObservable();
    }
    setCompany(model) {
        this.companySubject$.next(model);
        localStorage.setItem('selectedCompanyId', model.id.toString());
    }
    setStore(model) {
        this.storeSubject$.next(model);
        localStorage.setItem('selectedStoreId', model.id.toString());
    }
    update() {
        this.contextSubject$.next(uuid.v4());
    }
}
ApplicationContextService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApplicationContextService_Factory() { return new ApplicationContextService(); }, token: ApplicationContextService, providedIn: "root" });
