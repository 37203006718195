import { RejectedVehicleWorkDetailModel } from "./rejected.vehicle.work.detail.model";

export class VendorActionModel {
    public vehicleQueueId: number;
    public vehicleQueueStageId: number;
    public invoiceNumber: string;
    public rejectedItems: Array<RejectedVehicleWorkDetailModel>;

    constructor() {
        this.rejectedItems = new Array<RejectedVehicleWorkDetailModel>();
        this.invoiceNumber = null;
    }
}