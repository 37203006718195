import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { VendorDetailModel } from 'src/app/models';

@Component({
    selector: 'app-vendor-delete-confirmation',
    templateUrl: './vendor.delete.confirmation.component.html',
    styleUrls: ['./vendor.delete.confirmation.component.css']
})
export class VendorDeleteConfirmationComponent implements OnDestroy {
    @BlockUI('container-blockui-confirmation') blockUI: NgBlockUI;
    @ViewChild('confirmationModal', { static: false }) confirmationModal: any;
    @Output() delete = new EventEmitter<any>();

    modalReference: NgbModalRef;
    model: VendorDetailModel;
    form: FormGroup;
    submitted: boolean;

    constructor(private modalService: NgbModal,
        private formBuilder: FormBuilder) {
    }

    openModal(userModel: VendorDetailModel) {
        this.model = userModel;
        this.form = this.formBuilder.group({
        });

        this.modalReference = this.modalService.open(this.confirmationModal, { centered: true });
    }

    onSubmit() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        this.form.reset();
        this.submitted = false;

        setTimeout(() => {
            this.delete.emit(this.model);
        });
        this.onCancel();
    }

    onCancel() {
        if(!this.modalReference){
            return;
        }
        this.modalReference.close();
    }
    
    ngOnDestroy(): void {
        this.onCancel();
    }
}
