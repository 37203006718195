import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Chart, ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Label } from "ng2-charts";
import { forkJoin, Subscription } from "rxjs";
import { KpiFilterModel, SelectListModel } from "src/app/models";
import { ApplicationContextService, KpiBannerService, MessagingService } from "src/app/services";

@Component({
  selector: 'app-quote-saving-chart',
  templateUrl: './quote-saving-chart.component.html',
  styleUrls: ['./quote-saving-chart.component.css']
})
export class QuoteSavingChartComponent implements OnInit, OnDestroy {
  @BlockUI('container-blockui-quote-saving') blockUI: NgBlockUI;
  @ViewChild('canvas', { static: false }) canvas: ElementRef;

  maxValue: number;
  chartData = false;
  barChartData: ChartDataSets[];
  barChartLabels: Label[] = ['Series A', 'Series B', 'Series C'];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartOptions: ChartOptions;
  filterModel = new KpiFilterModel();

  subscriptions = new Array<Subscription>();

  constructor(private kpiBannerService: KpiBannerService,
    private messagingService: MessagingService,
    private applicationContextService: ApplicationContextService) {
    const storeSubscription = this.applicationContextService.store$.subscribe((storeSelectListItem: SelectListModel) => {
      if (storeSelectListItem) {
        this.filterModel.storeId = storeSelectListItem.id;
      } else {
        this.filterModel.storeId = null;
      }
      this.loadData();
    });
    this.subscriptions.push(storeSubscription);
  }



  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  ngAfterViewInit() {
  }

  private loadData() {
    this.blockUI.start();
    forkJoin([
      this.kpiBannerService.getQuoteSavingForCurrentMonth(this.filterModel),
      this.kpiBannerService.getQuoteSavingForLastOneMonth(this.filterModel),
      this.kpiBannerService.getQuoteSavingForLastTwoMonth(this.filterModel),
    ]).subscribe((result) => {
      this.barChartData = [{
        data: [result[2].totalQuoteSaving, result[1].totalQuoteSaving, result[0].totalQuoteSaving], label: '',
        backgroundColor: ['#e5444d', '#eb6066', '#ef9296'],
        borderColor: ['#e5444d', '#eb6066', '#ef9296'],
      }];

      const greatestValue = Math.round(Math.max(result[2].totalQuoteSaving, result[1].totalQuoteSaving, result[0].totalQuoteSaving) + 10);
      const calculatedMaxValue = Math.ceil((greatestValue + 1) / 1000) * 1000;
      this.maxValue = Math.max(calculatedMaxValue, 10000);

      this.barChartOptions = {
        responsive: false,
        maintainAspectRatio: true,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              offsetGridLines: false
            }
          }],
          yAxes: [{
            ticks: {
              stepSize: 2000,
              beginAtZero: true,
              min: 0,
              max: this.maxValue
            }
          }]
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
          }
        },
      }
      this.chartData = true;
      this.blockUI.stop();
    }, error => {
      this.messagingService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }
}
