import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ApplicationContextService } from "./application.context.service";
import { CompanyAddModel, CompanyDeleteModel, CompanyDetailModel, CompanyEditModel, CompanyStatusToggleModel, CompanyWithStoreModel, DataTableResponseModel, SelectListModel } from "../models";

@Injectable({
    providedIn: 'root'
})
export class CompanyService {
    constructor(private http: HttpClient,
        private applicationContextService: ApplicationContextService
    ) {
    }

    public add(model: CompanyAddModel): Observable<CompanyDetailModel> {
        return this.http.post<CompanyDetailModel>(`${environment.apiBaseUrl}/company/add`, model).pipe(
            tap(() => {
                this.applicationContextService.update();
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    public edit(model: CompanyEditModel): Observable<CompanyDetailModel> {
        return this.http.patch<CompanyDetailModel>(`${environment.apiBaseUrl}/company/edit`, model).pipe(
            tap(() => {
                this.applicationContextService.update();
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    public get(id: number): Observable<CompanyDetailModel> {
        return this.http.get<CompanyDetailModel>(`${environment.apiBaseUrl}/company/${id}`);
    }

    public getBasicInformation(id: number): Observable<CompanyDetailModel> {
        return this.http.get<CompanyDetailModel>(`${environment.apiBaseUrl}/company/${id}`);
    }

    public delete(model: CompanyDeleteModel) {
        return this.http.delete(`${environment.apiBaseUrl}/company/${model.id}/${model.reassignedCompanyId}`).pipe(
            tap(() => {
                this.applicationContextService.update();
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    public toggleStatus(model: CompanyStatusToggleModel) {
        return this.http.patch(`${environment.apiBaseUrl}/company/toggle-status`, model).pipe(
            tap(() => {
                this.applicationContextService.update();
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    public query(dataTablesParameters: any) {
        return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/company/query`, dataTablesParameters, {});
    }

    public deleteDocument(fileName: string) {
        return this.http.delete(`${environment.apiBaseUrl}/company/delete-document/${fileName}`);
    }

    public getCompanies(): Observable<Array<SelectListModel>> {
        return this.http.get<Array<SelectListModel>>(`${environment.apiBaseUrl}/company/companies`);
    }

    public getCompaniesWithStores(): Observable<Array<CompanyWithStoreModel>> {
        return this.http.get<Array<CompanyWithStoreModel>>(`${environment.apiBaseUrl}/company/companies/with/stores`);
    }

    public getLoggedInUserCompaniesWithStores(): Observable<Array<CompanyWithStoreModel>> {
        return this.http.get<Array<CompanyWithStoreModel>>(`${environment.apiBaseUrl}/user/companies/with/stores`);
    }

}
