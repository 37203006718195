import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApplicationContextService } from './application.context.service';
import { DataTableResponseModel, SelectListModel, StoreAddModel, StoreCloneModel, StoreDetailModel, StoreEditModel } from '../models';

@Injectable({
	providedIn: 'root'
})
export class StoreService {
	constructor(private http: HttpClient,
        private applicationContextService: ApplicationContextService) {
	}
	query(dataTablesParameters: any) {
		return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/store/query`, dataTablesParameters);
	}

	get(id: number): Observable<StoreDetailModel> {
		return this.http.get<StoreDetailModel>(`${environment.apiBaseUrl}/store/${id}`);
	}

	add(data: StoreAddModel): Observable<StoreDetailModel> {
		return this.http.post<StoreDetailModel>(`${environment.apiBaseUrl}/store`, data).pipe(
            tap(() => {
                this.applicationContextService.update();
            }),
            catchError(error => {
                throw error;
            })
        );
	}

	update(data: StoreEditModel) {
		return this.http.put<StoreDetailModel>(`${environment.apiBaseUrl}/store`, data).pipe(
            tap(() => {
                this.applicationContextService.update();
            }),
            catchError(error => {
                throw error;
            })
        );
	}

	clone(data: StoreCloneModel) {
		return this.http.post<StoreDetailModel>(`${environment.apiBaseUrl}/store/clone`, data).pipe(
            tap(() => {
                this.applicationContextService.update();
            }),
            catchError(error => {
                throw error;
            })
        );
	}

	delete(id: number) {
		return this.http.delete<object>(`${environment.apiBaseUrl}/store/${id}`).pipe(
            tap(() => {
                this.applicationContextService.update();
            }),
            catchError(error => {
                throw error;
            })
        );
	}

	associateCategory(id: number, categoryId: number) {
		return this.http.post<object>(`${environment.apiBaseUrl}/store/${id}/associate/category/${categoryId}`, null);
	}

	toggleStatus(id: number) {
		return this.http.patch<object>(`${environment.apiBaseUrl}/store/toggle-status/${id}`, null).pipe(
            tap(() => {
                this.applicationContextService.update();
            }),
            catchError(error => {
                throw error;
            })
        );
	}

	storeSelectList(): Observable<Array<SelectListModel>> {
		return this.http.get<Array<SelectListModel>>(`${environment.apiBaseUrl}/store/select-list-stores`);
	}

	allAutoGroupName(): Observable<Array<string>> {
		return this.http.get<Array<string>>(`${environment.apiBaseUrl}/store/all-auto-group-names`);
	}

	autoGroupStoreSelectList(term: string): Observable<Array<SelectListModel>> {
		return this.http.get<Array<SelectListModel>>(`${environment.apiBaseUrl}/store/auto-group-stores?term=${term}`);
	}
}