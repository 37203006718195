import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { AppUtils } from 'src/app/helpers';
import { CompanyDeleteModel, CompanyListItemModel, CompanyStatusToggleModel, FilterStateModel } from 'src/app/models';
import { CompanyService, FilterStateService, MessagingService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-company-manage',
    templateUrl: './company.manage.component.html',
    styleUrls: ['./company.manage.component.css']
})
export class CompanyManageComponent implements OnInit, AfterViewInit, OnDestroy {

    @BlockUI('container-blockui-manage') blockUI: NgBlockUI;
    @ViewChild('deleteConfirmationModal', { static: false }) deleteConfirmationModal: any;
    @ViewChild('statusChangeConfirmationModal', { static: false }) statusChangeConfirmationModal: any;

    //Data Table Options
    @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtInstance: DataTables.Api;
    rowIndex = 0;

    frm: FormGroup;
    filterOptions: any;
    statusOptions: any;


    constructor(private router: Router,
        private filterStateService: FilterStateService,
        private companyService: CompanyService,
        private messagingService: MessagingService,
        private formBuilder: FormBuilder,
        public appUtils: AppUtils) {
        this.filterOptions = this.appUtils.getFilterOperationOptions();
        this.statusOptions = this.appUtils.getStatusOptions();
    }

    ngOnInit(): void {

        this.frm = this.formBuilder.group({
            companyName: [''],
            filterOption: [1], //Contains
            statusOption: [2] //Active
        });

        //Data Table Example.
        const self = this;
        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter...',
                paginate: {
                    first: '<em class="fa fa-angle-double-left">',
                    last: '<em class="fa fa-angle-double-right">',
                    previous: '<em class="fa fa-angle-left">',
                    next: '<em class="fa fa-angle-right">'
                }
            },
            search: { search: null },
            searching: false,
            displayStart: self.rowIndex,
            paging: false,
            pagingType: 'full_numbers',
            pageLength: environment.paging.defaultPageSize,
            lengthMenu: environment.paging.lengthMenu,
            order: [[0, 'asc']],
            info: false,
            stateSave: true,
            scrollY: (window.innerHeight - 70 - 45) + "px",
            scrollCollapse: true,
            ajax: (dataTablesParameters: any, callback) => {

                self.updateFilterModel();

                dataTablesParameters.name = self.frm.controls.companyName.value;
                dataTablesParameters.filterBy = Number(self.frm.controls.filterOption.value);
                dataTablesParameters.status = self.statusOptions.find(x => x.id == self.frm.controls.statusOption.value).value;

                self.blockUI.start();
                self.companyService.query(dataTablesParameters)
                    .subscribe(resp => {
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
                        self.blockUI.stop();
                    }, error => {
                        self.messagingService.ProcessErrorResponse(error);
                        self.blockUI.stop();
                    });
            },
            columns: [
                {
                    data: 'name',
                    title: 'Managing Company Name',
                    width: '30%',
                    render: function (data, type, row) {
                        return `<span class='link' action-type='link-detail'>${row.name}</a>`;
                    }
                },
                {
                    data: 'accountMangerName',
                    title: 'Account Manager Name',
                    width: '20%'
                },
                {
                    data: 'accountMangerEmail',
                    title: 'Account Manager Email',
                    width: '20%'
                },
                {
                    data: 'phoneNumber',
                    title: 'Phone Number',
                    width: '10%',
                    render: function (data, type, row) {
                        return `<span>${row.phoneCode} ${row.phoneNumber}</span>`;
                    }
                },
                {
                    data: 'isActive',
                    title: 'Status',
                    width: '5%',
                    render: function (isActive) {
                        return isActive
                            ? '<span class="badge badge-success">Active</span>'
                            : '<span class="badge badge-danger">Inactive</span>';
                    }
                },
                {
                    data: null,
                    title: 'Action',
                    width: '20%',
                    orderable: false,
                    className: 'text-center',
                    visible: true,
                    render: function (data, type, row) {
                        //Render Activate or Deactivate Button
                        let statusHtml = row.isActive ?
                            `<button title='Deactivate' action-type='toggle-status'><em class="fa fa-ban" aria-hidden="true"></em></button>` :
                            `<button title='Activate' action-type='toggle-status'><em class="fa fa-check" aria-hidden="true"></em></button>`;

                        return `
                        <button title='View Detail' action-type='view'><em class="fa fa-eye" aria-hidden="true"></em></button>
                        ${statusHtml}
                        <button title='Edit' action-type='edit'><em class="fa fa-edit" aria-hidden="true"></em></button>
                        <button title='Delete' action-type='delete'><em class="fa fa-trash" aria-hidden="true"></em></button>
                        `;
                    }
                }
            ],
            rowCallback: function (row, data: CompanyListItemModel, dataIndex: number) {
                const _row = $(row);

                _row.find('[action-type="toggle-status"]')
                    .off('click')
                    .on('click', function () {
                        self.openStatusChangeConfirmation(data, dataIndex);
                        //dropdownTrigger.trigger('click');
                    });

                _row.find('[action-type="edit"]')
                    .off('click')
                    .on('click', function () {
                        self.router.navigate(['/edit/company', data.id]);
                    });

                _row.find('[action-type="delete"]')
                    .off('click')
                    .on('click', function () {
                        self.openDeleteConfirmation(data, dataIndex);
                        //dropdownTrigger.trigger('click');
                    });

                _row.find('[action-type="link-detail"],[action-type="view"]')
                    .off('click')
                    .on('click', function () {
                        self.router.navigate(['/company/detail', data.id]);
                    });
            }
        };


    }

    ngAfterViewInit() {
        this.updateFormFromFilterModel();

        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);

        $(document).on('mouseup.custom', function (e) {
            if ($(e.target).closest(".dropdown-menu.action-dropdown").length === 0) {
                $(".dropdown-menu.action-dropdown").hide();
            }
        });
    }

    updateFormFromFilterModel() {
        const tempFilterModel = this.filterStateService.getFilterModel(FilterPageType.companyManage);
        if (tempFilterModel && tempFilterModel.isActive) {
            this.frm.controls.companyName.setValue(tempFilterModel.filterKey);
            this.frm.controls.filterOption.setValue(tempFilterModel.filterOption);
            this.frm.controls.statusOption.setValue(tempFilterModel.statusOption);
        }
    }

    updateFilterModel() {
        const filterModel = this.filterStateService.getFilterModel(FilterPageType.companyManage);
        filterModel.page = FilterPageType.companyManage;
        filterModel.filterKey = this.frm.controls.companyName.value;
        filterModel.filterOption = this.frm.controls.filterOption.value;
        filterModel.statusOption = this.frm.controls.statusOption.value;
        filterModel.isActive = false;
    }

    ngOnDestroy(): void {
        $(document).off('mouseup.custom');
    }

    openDeleteConfirmation(company: CompanyListItemModel, dataIndex: number) {
        this.deleteConfirmationModal.openModal(company);
    }

    delete(companyDeleteModel: CompanyDeleteModel) {

        this.blockUI.start();
        this.companyService.delete(companyDeleteModel).subscribe(
            () => {
                setTimeout(() => {
                    this.dtInstance.ajax.reload(null, false);
                });
                this.messagingService.success(`Managing Company has been deleted successfully.`);
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
            });
    }

    openStatusChangeConfirmation(company: CompanyListItemModel, dataIndex: number) {
        this.statusChangeConfirmationModal.openModal(company);
    }

    toggle(companyStatusToggleModel: CompanyStatusToggleModel) {
        this.blockUI.start();
        this.companyService.toggleStatus(companyStatusToggleModel).subscribe(
            () => {
                setTimeout(() => {
                    this.dtInstance.ajax.reload(null, false);
                });
                this.messagingService.success(`Managing Company has been ${(companyStatusToggleModel.isActive ? 'deactivated' : 'activated')} successfully.`);
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
            });
    }

    applyFilter() {
        this.dtInstance.ajax.reload();
    }
}
