<div class="gnrlformSec">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<a class="backtolist" (click)="navigateToDashboard()">
					<em class="fa fa-long-arrow-left" aria-hidden="true"></em>
					Back to list
				</a>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-12 col-lg-8 col-xl-8 offset-xl-2 offset-lg-2">
				<div class="gnrlformbox" *blockUI="'container-blockui-store'">
					<h2>Edit Vehicle Detail</h2>
					<form class="matrixFrom" [formGroup]="frmVehicleDetail" (ngSubmit)="onSubmit()">
						<div class="form-group">
							<div class="matrixFromLabel">
								<h5>Stock Number</h5>
							</div>
							<div class="matrixFromField">
								<input type="text" class="form-control" formControlName="stockNumber"
									[ngClass]="{ 'is-invalid': submitted && frmVehicleDetail.controls.stockNumber.errors }" />
								<div *ngIf="submitted && frmVehicleDetail.controls.stockNumber.errors" class="invalid-feedback">
									<div *ngIf="frmVehicleDetail.controls.stockNumber.errors.required">
										Stock Number is required
									</div>
									<div *ngIf="frmVehicleDetail.controls.stockNumber.errors.upperCase">Stock Number
										should be in capital letters </div>
									<div *ngIf="frmVehicleDetail.controls.stockNumber.errors.alphaNumeric">Stock Number
										should be alpha numeric </div>
								</div>
							</div>
						</div>
						<div class="form-group">
							<div class="matrixFromLabel">
								<h5>VIN</h5>
							</div>
							<div class="matrixFromField">
								<input type="text" class="form-control" formControlName="vin"
									[ngClass]="{ 'is-invalid': submitted && frmVehicleDetail.controls.vin.errors }" />
								<div *ngIf="submitted && frmVehicleDetail.controls.vin.errors" class="invalid-feedback">
									<div *ngIf="frmVehicleDetail.controls.vin.errors.required">VIN
										is required</div>
									<div *ngIf="frmVehicleDetail.controls.vin.errors.upperCase">VIN
										should be in capital letters </div>
									<div *ngIf="frmVehicleDetail.controls.vin.errors.alphaNumeric">VIN
										should be alpha numeric </div>
									<div
										*ngIf="frmVehicleDetail.controls.vin.errors.minLength || frmVehicleDetail.controls.vin.errors.maxLength">
										VIN should be
										{{frmVehicleDetail.controls.vin.validatorConfig.minLength.value}}
										characters </div>
								</div>
							</div>
						</div>
						<div class="form-group">
							<div class="matrixFromLabel">
								<h5>Year</h5>
							</div>
							<div class="matrixFromField">
								<input type="text" class="form-control" formControlName="year"
									[ngClass]="{ 'is-invalid': submitted && frmVehicleDetail.controls.year.errors }" />
								<div *ngIf="submitted && frmVehicleDetail.controls.year.errors"
									class="invalid-feedback">
									<div *ngIf="frmVehicleDetail.controls.year.errors.required">
										Year is required
									</div>
									<div *ngIf="frmVehicleDetail.controls.year.errors.numeric">
										Year should be numbers
									</div>
									<div *ngIf="frmVehicleDetail.controls.year.errors.minNumber">Year
										should be min
										{{frmVehicleDetail.controls.year.validatorConfig.minNumber.value}}</div>
									<div *ngIf="frmVehicleDetail.controls.year.errors.maxNumber">Year
										should be max
										{{frmVehicleDetail.controls.year.validatorConfig.maxNumber.value}}</div>
								</div>
							</div>
						</div>
						<div class="form-group">
							<div class="matrixFromLabel">
								<h5>Make</h5>
							</div>
							<div class="matrixFromField">
								<input type="text" class="form-control" formControlName="make"
									[ngClass]="{ 'is-invalid': submitted && frmVehicleDetail.controls.make.errors }" />
								<div *ngIf="submitted && frmVehicleDetail.controls.make.errors"
									class="invalid-feedback">
									<div *ngIf="frmVehicleDetail.controls.make.errors.required">Make
										is required</div>
									<div *ngIf="frmVehicleDetail.controls.make.errors.maxLength">
										Make should be
										{{frmVehicleDetail.controls.make.validatorConfig.maxLength.value}}
										characters </div>
								</div>
							</div>
						</div>
						<div class="form-group">
							<div class="matrixFromLabel">
								<h5>Model</h5>
							</div>
							<div class="matrixFromField">
								<input type="text" class="form-control" formControlName="model"
									[ngClass]="{ 'is-invalid': submitted && frmVehicleDetail.controls.model.errors }" />
								<div *ngIf="submitted && frmVehicleDetail.controls.model.errors"
									class="invalid-feedback">
									<div *ngIf="frmVehicleDetail.controls.model.errors.required">Model
										is required</div>
									<div *ngIf="frmVehicleDetail.controls.model.errors.maxLength">
										Model should be
										{{frmVehicleDetail.controls.model.validatorConfig.maxLength.value}}
										characters </div>
								</div>
							</div>
						</div>
						<div class="form-group">
							<div class="matrixFromLabel">
								<h5>Series</h5>
							</div>
							<div class="matrixFromField">
								<input type="text" class="form-control" formControlName="series"
									[ngClass]="{ 'is-invalid': submitted && frmVehicleDetail.controls.series.errors }" />
								<div *ngIf="submitted && frmVehicleDetail.controls.series.errors"
									class="invalid-feedback">
									<div *ngIf="frmVehicleDetail.controls.series.errors.required">Series
										is required</div>
									<div *ngIf="frmVehicleDetail.controls.series.errors.maxLength">
										Series should be
										{{frmVehicleDetail.controls.series.validatorConfig.maxLength.value}}
										characters </div>
								</div>
							</div>
						</div>
						<div class="form-group">
							<div class="matrixFromLabel">
								<h5>Basic Color</h5>
							</div>
							<div class="matrixFromField">
								<select class="form-control" placeholder="Basic Color" formControlName="basicColor"
									[ngClass]="{ 'is-invalid': submitted && frmVehicleDetail.controls.basicColor.errors }">
									<option value="">Select Basic Color</option>
									<option *ngFor="let item of basicColors" [value]="item">
										{{item}}
									</option>
								</select>
								<div *ngIf="submitted && frmVehicleDetail.controls.basicColor.errors"
									class="invalid-feedback">
									<div *ngIf="frmVehicleDetail.controls.basicColor.errors.required">
										Basic Color is required
									</div>
								</div>
							</div>
						</div>						
						<div class="form-group">
							<div class="matrixFromLabel">
								<h5>Mileage</h5>
							</div>
							<div class="matrixFromField">
								<input type="text" class="form-control" formControlName="mileage"
									[ngClass]="{ 'is-invalid': submitted && frmVehicleDetail.controls.mileage.errors }" />
								<div *ngIf="submitted && frmVehicleDetail.controls.mileage.errors"
									class="invalid-feedback">
									<div *ngIf="frmVehicleDetail.controls.mileage.errors.required">Mileage
										is required</div>
									<div *ngIf="frmVehicleDetail.controls.mileage.errors.numeric">Mileage
										should be numeric</div>
								</div>
							</div>
						</div>
						<div class="form-group">
							<div class="matrixFromLabel">
								<h5>Type</h5>
							</div>
							<div class="matrixFromField">
								<select class="form-control" formControlName="vehicleType" placeholder="Type"
									[ngClass]="{ 'is-invalid': submitted && frmVehicleDetail.controls.vehicleType.errors }">
									<option *ngFor="let item of vehicleTypes" [value]="item.id">
										{{item.name}}
									</option>
								</select>
							</div>
						</div>
						<div class="btnWrp">
							<button class="btn btn-red" type="submit">Save</button>
							<button class="btn btn-tsrp" type="button" (click)="navigateToDashboard()">Cancel</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>