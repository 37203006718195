import { OperationCodeSelectListModel, SelectListModel } from "..";

export class WorkflowStageAddCategoriesViewModel {
    public categoryId: number;
    public categoryName: string;

    public showTool: boolean;
    public subCategoryId: number;
    public subCategoryName: string;

    public subCategorySelectList: Array<SelectListModel>;

    public operationCodeSelectList: Array<OperationCodeSelectListModel>;
    public filteredOperationCodeSelectList: Array<OperationCodeSelectListModel>;
    public selectedOperationCodeSelectList: Array<OperationCodeSelectListModel>;

    public selectedOperationCodeIds: Array<number>;
    public selectedOperationCodeNames: string;
    public isDeleted: boolean;
    constructor() {
        this.operationCodeSelectList = new Array<OperationCodeSelectListModel>();
        this.filteredOperationCodeSelectList = new Array<OperationCodeSelectListModel>();
        this.selectedOperationCodeSelectList = new Array<OperationCodeSelectListModel>();
        this.selectedOperationCodeIds = new Array<number>();
        this.showTool = false;
    }
}