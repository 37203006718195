/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timer.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./times.component";
var styles_TimerComponent = [i0.styles];
var RenderType_TimerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimerComponent, data: {} });
export { RenderType_TimerComponent as RenderType_TimerComponent };
export function View_TimerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "timerCount"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [["class", "days"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Days"])), (_l()(), i1.ɵted(-1, null, [" : "])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "span", [["class", "hours"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Hours"])), (_l()(), i1.ɵted(-1, null, [" : "])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "span", [["class", "mint"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", " "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Minutes"])), (_l()(), i1.ɵted(-1, null, [" : "])), (_l()(), i1.ɵeld(16, 0, null, null, 3, "span", [["class", "second"]], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["", " "])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Seconds"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.days; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.hours; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.minutes; _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.seconds; _ck(_v, 17, 0, currVal_3); }); }
export function View_TimerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-time", [], null, null, null, View_TimerComponent_0, RenderType_TimerComponent)), i1.ɵdid(1, 573440, null, 0, i2.TimerComponent, [], null, null)], null, null); }
var TimerComponentNgFactory = i1.ɵccf("app-time", i2.TimerComponent, View_TimerComponent_Host_0, { intervalInSeconds: "seconds" }, {}, []);
export { TimerComponentNgFactory as TimerComponentNgFactory };
