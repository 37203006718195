var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { ApplicationRoleType } from 'src/app/enums';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { AppUtils } from 'src/app/helpers';
import { AccountRecoverModel, SelectListModel, UserDetailModel } from 'src/app/models';
import { UserService, MessagingService, AuthenticationService, FilterStateService } from 'src/app/services';
export class UserDetailComponent {
    constructor(router, route, userService, filterStateService, authenticationService, messagingService, appUtils) {
        this.router = router;
        this.route = route;
        this.userService = userService;
        this.filterStateService = filterStateService;
        this.authenticationService = authenticationService;
        this.messagingService = messagingService;
        this.appUtils = appUtils;
        this.ApplicationRoleType = ApplicationRoleType;
        this.model = new UserDetailModel();
        filterStateService.changeFilterModelStatues(FilterPageType.userManage, true);
        this.route.params.subscribe(params => {
            this.model.id = params.userId;
        });
        this.roleTypeFilterOptions = this.appUtils.getApplicationRoleTypeOptions();
    }
    ngOnInit() {
        this.loadUser();
    }
    goToEdit() {
        this.router.navigate(['edit/user', this.model.id]);
    }
    loadUser() {
        this.blockUI.start();
        forkJoin([
            this.userService.getUserDetail(this.model.id)
        ]).subscribe(([userModel]) => {
            this.model = userModel;
            this.roles = this.model.roles.join(', ');
            this.roleType = this.roleTypeFilterOptions.find(x => x.id == this.model.applicationRoleType).name;
            this.storeNames = this.model.userStoreAssociations.map(x => x.name).join(', ');
            this.companyNames = this.model.userCompanyAssociations.map(x => x.name).join(', ');
            this.vendorNames = this.model.userVendorAssociations.map(x => x.name).join(', ');
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    openDeleteConfirmation() {
        this.deleteConfirmationModal.openModal(this.model);
    }
    delete(user) {
        this.blockUI.start();
        this.userService.delete(user.id).subscribe(() => {
            setTimeout(() => {
                this.router.navigate(['/manage/user']);
            });
            this.messagingService.success(`User has been deleted successfully.`);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    openStatusChangeConfirmation() {
        this.statusChangeConfirmationModal.openModal(this.model);
    }
    toggle(user) {
        this.blockUI.start();
        this.userService.toggleStatus(user.id).subscribe(() => {
            this.messagingService.success(`User has been ${(user.isActive ? 'deactivated' : 'activated')} successfully.`);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    openResetPasswordOtherUserConfirmation() {
        this.resetPasswordOtherUserConfirmationModal.openModal(this.model);
    }
    resetPassword(user) {
        this.blockUI.start();
        let accountRecoverModel = new AccountRecoverModel();
        accountRecoverModel.email = user.userName;
        this.authenticationService.recover(accountRecoverModel).subscribe(() => {
            this.messagingService.success(`Password for ${user.userName} reset successfully.`);
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    getNotificationText() {
        let arr = new Array();
        if (this.model != null) {
            if (this.model.isEmailNotificationActive) {
                arr.push('Email');
            }
            if (this.model.isSMSNotificationActive) {
                arr.push('SMS');
            }
        }
        return arr.join(',&nbsp;');
    }
}
__decorate([
    BlockUI('container-blockui'),
    __metadata("design:type", Object)
], UserDetailComponent.prototype, "blockUI", void 0);
