<section class="vehicleDtlSec" *blockUI="'container-blockui'">
    <div class="container-fluid">
        <div class="row">
            <div class="col-6">
                <div class="vehicleDtlHeading">
                    <h2>Vehicle Details</h2>
                </div>
            </div>
            <div class="col-6">
                <div class="approveBtnWrp">
                    <button class="btn btn-red" type="button" (click)="onAddVehicle()">Add Vehicle</button>
                </div>
            </div>
        </div>
    </div>

    <form [formGroup]="filterForm" (ngSubmit)="onFilterChange()">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-2">
                    <div class="form-group">
                        <label>Usage</label>
                        <select class="form-control" formControlName="vehicleAgeType" placeholder="New/Used">
                            <option value="">All</option>
                            <option *ngFor="let item of vehicleAgeTypes" [value]="item.id">
                                {{item.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-2">
                    <div class="form-group">
                        <label>Type</label>
                        <select class="form-control" formControlName="vehicleType" placeholder="Type">
                            <option value="">All</option>
                            <option *ngFor="let item of vehicleTypes" [value]="item.id">
                                {{item.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-2">
                    <div class="form-group">
                        <label>On Hold</label>
                        <select class="form-control" formControlName="onHold" placeholder="On Hold">
                            <option value="">Both</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <label>VIN</label>
                    <input type="text" class="form-control" placeholder="VIN" formControlName="vin" />
                    <div>
                        <em>* contains</em>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <label>Stock #</label>
                    <input type="text" class="form-control" placeholder="Stock #" formControlName="stockNumber" />
                    <em>* contains</em>
                </div>
            </div>
        </div>
    </form>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">

        <div class="vehicleDtlTableSec">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="bluetableWrp tableWrp table-responsive">
                            <table class="table dataTable table-striped" summary="Vehicles to be imported">
                                <thead>
                                    <tr>
                                        <th scope="col">Import Date</th>
                                        <th scope="col">Store</th>
                                        <th scope="col">Check-In Type</th>
                                        <th scope="col" style="width: 180px;">VIN</th>
                                        <th scope="col" style="width: 85px;">Stock #</th>
                                        <th scope="col" style="width: 40px;">Year</th>
                                        <th scope="col" style="width: 70px;">Make</th>
                                        <th scope="col" style="width: 70px;">Model</th>
                                        <th scope="col" style="width: 70px;">Series</th>
                                        <th scope="col">Basic Color</th>
                                        <th scope="col" style="width: 80px;">Color</th>
                                        <th scope="col" style="width: 60px;">Mileage</th>
                                        <th scope="col"> Type</th>
                                        <th scope="col" style="width: 40px;">Usage</th>
                                        <th scope="col">On Hold</th>
                                        <th scope="col">
                                            <div class="from-group">
                                                <span>
                                                    <input type="checkbox" class="form-control" id="chkSelectAll"
                                                        formControlName="selectAll" (change)="onSelectAllChange()" />
                                                    <label for="chkSelectAll"></label>
                                                </span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody formArrayName="vehicles">
                                    <tr *ngFor="let vehicle of form.get('vehicles').controls; let i = index;"
                                        [formGroupName]="i">
                                        <td>
                                            {{vehicle.controls.inventoryDate.value | date:'MM/dd/yyyy' }}
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <select class="form-control" formControlName="storeId"
                                                    placeholder="Store"
                                                    [ngClass]="{ 'is-invalid': submitted &&  vehicle.controls.storeId.errors }"
                                                    (change)="onStoreChange(vehicle.controls.storeId.value, i)">
                                                    <option value="">Select Store</option>
                                                    <option *ngFor="let item of stores" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                                <div *ngIf="submitted &&  vehicle.controls.storeId.errors"
                                                    class="invalid-feedback">
                                                    Store is required
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <select class="form-control" formControlName="workflowId"
                                                    placeholder="Check-In Type"
                                                    [ngClass]="{ 'is-invalid': submitted &&  vehicle.controls.workflowId.errors }">
                                                    <option value="">Select Check-In Type</option>
                                                    <option *ngFor="let item of viewModels[i].workflowSelectList"
                                                        [value]="item.id">
                                                        {{item.checkInTypeName}}
                                                    </option>
                                                </select>
                                                <div *ngIf="submitted &&  vehicle.controls.workflowId.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="vehicle.controls.workflowId.errors.required">Check-In
                                                        Type is
                                                        required</div>
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="form-group"
                                                *ngIf="!viewModels[i].autoImportModel.vehicleIdentificationNumber">
                                                <input type="text" class="form-control" placeholder="VIN"
                                                    formControlName="vin"
                                                    (change)="populateVehicleInfo(vehicle.controls.vin.value, vehicle)"
                                                    [ngClass]="{ 'is-invalid': submitted &&  vehicle.controls.vin.errors }" />
                                                <div *ngIf="submitted &&  vehicle.controls.vin.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="vehicle.controls.vin.errors.required">VIN
                                                        is required</div>
                                                    <div *ngIf="vehicle.controls.vin.errors.pattern">VIN
                                                        should be in aplha numeric with capital letters </div>
                                                    <div
                                                        *ngIf="vehicle.controls.vin.errors.minlength || vehicle.controls.vin.errors.maxlength">
                                                        VIN should be {{stringLengthConstants.VIN}} characters </div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewModels[i].autoImportModel.vehicleIdentificationNumber">
                                                {{viewModels[i].autoImportModel.vehicleIdentificationNumber}}</div>
                                        </td>
                                        <td>
                                            <div class="form-group" *ngIf="!viewModels[i].autoImportModel.stockNumber">
                                                <input style="width: 80px;" type="text" class="form-control"
                                                    placeholder="Stock #" formControlName="stockNumber"
                                                    [ngClass]="{ 'is-invalid': submitted &&  vehicle.controls.stockNumber.errors }" />
                                                <div *ngIf="submitted &&  vehicle.controls.stockNumber.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="vehicle.controls.stockNumber.errors.required">Stock #
                                                        is required</div>
                                                    <div *ngIf="vehicle.controls.stockNumber.errors.maxlength">
                                                        Stock # should be
                                                        {{vehicle.controls.stockNumber.errors.maxlength.requiredLength}}
                                                        characters </div>
                                                    <div *ngIf="vehicle.controls.stockNumber.errors.pattern">
                                                        Stock # should be in aplha numeric with capital letters </div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewModels[i].autoImportModel.stockNumber">
                                                {{viewModels[i].autoImportModel.stockNumber}}</div>
                                        </td>

                                        <td>
                                            <div class="form-group" *ngIf="!viewModels[i].autoImportModel.year">
                                                <input style="width: 80px;" type="text" class="form-control"
                                                    placeholder="Year" formControlName="year"
                                                    [ngClass]="{ 'is-invalid': submitted &&  vehicle.controls.year.errors }" />
                                                <div *ngIf="submitted &&  vehicle.controls.year.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="vehicle.controls.year.errors.required">Year
                                                        is required</div>
                                                    <div *ngIf="vehicle.controls.year.errors.pattern">Year
                                                        should be numbers</div>
                                                    <div *ngIf="vehicle.controls.year.errors.min">Year
                                                        should be min
                                                        {{vehicle.controls.year.errors.min.min}}</div>
                                                    <div *ngIf="vehicle.controls.year.errors.max">Year
                                                        should be max
                                                        {{vehicle.controls.year.errors.max.max}}</div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewModels[i].autoImportModel.year">
                                                {{viewModels[i].autoImportModel.year}}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group" *ngIf="!viewModels[i].autoImportModel.make">
                                                <input style="width: 80px;" type="text" class="form-control"
                                                    placeholder="Make" formControlName="make"
                                                    [ngClass]="{ 'is-invalid': submitted &&  vehicle.controls.make.errors }" />
                                                <div *ngIf="submitted &&  vehicle.controls.make.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="vehicle.controls.make.errors.required">Make
                                                        is required</div>
                                                    <div *ngIf="vehicle.controls.make.errors.maxlength">
                                                        Make should be
                                                        {{vehicle.controls.make.maxlength.requiredLength}}
                                                        characters </div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewModels[i].autoImportModel.make">
                                                {{viewModels[i].autoImportModel.make}}</div>
                                        </td>
                                        <td>
                                            <div class="form-group" *ngIf="!viewModels[i].autoImportModel.model">
                                                <input style="width: 80px;" type="text" class="form-control"
                                                    placeholder="Model" formControlName="model"
                                                    [ngClass]="{ 'is-invalid': submitted &&  vehicle.controls.model.errors }" />
                                                <div *ngIf="submitted && vehicle.controls.model.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="vehicle.controls.model.errors.required">Model
                                                        is required</div>
                                                    <div *ngIf="vehicle.controls.model.errors.maxlength">
                                                        Model should be
                                                        {{vehicle.controls.model.maxlength.requiredLength}}
                                                        characters </div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewModels[i].autoImportModel.model">
                                                {{ viewModels[i].autoImportModel.model}}</div>
                                        </td>
                                        <td>
                                            <div class="form-group" *ngIf="!viewModels[i].autoImportModel.series">
                                                <input style="width: 80px;" type="text" class="form-control"
                                                    placeholder="Series" formControlName="series"
                                                    [ngClass]="{ 'is-invalid': submitted &&  vehicle.controls.series.errors }" />
                                                <div *ngIf="submitted && vehicle.controls.series.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="vehicle.controls.series.errors.required">Series
                                                        is required</div>
                                                    <div *ngIf="vehicle.controls.series.errors.maxlength">
                                                        Series should be
                                                        {{vehicle.controls.series.maxlength.requiredLength}}
                                                        characters </div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewModels[i].autoImportModel.series">
                                                {{ viewModels[i].autoImportModel.series}}</div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <select class="form-control" placeholder="Basic Color"
                                                    formControlName="basicColor"
                                                    [ngClass]="{ 'is-invalid': submitted &&  vehicle.controls.basicColor.errors }">
                                                    <option value="">Select Basic Color</option>
                                                    <option *ngFor="let item of basicColors" [value]="item">
                                                        {{item}}
                                                    </option>
                                                </select>
                                                <div *ngIf="submitted &&  vehicle.controls.basicColor.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="vehicle.controls.basicColor.errors.required">Basic Color
                                                        is required</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group" *ngIf="vehicle.controls.isAddedManually.value">
                                                <input style="width: 100px;" type="text" class="form-control"
                                                    placeholder="Color" formControlName="color"
                                                    [ngClass]="{ 'is-invalid': submitted &&  vehicle.controls.color.errors }" />
                                                <div *ngIf="submitted &&  vehicle.controls.color.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="vehicle.controls.color.errors.required">Color
                                                        is required</div>
                                                    <div *ngIf="vehicle.controls.color.errors.maxlength">
                                                        Color should be
                                                        {{vehicle.controls.color.maxlength.requiredLength}}
                                                        characters </div>
                                                </div>
                                            </div>
                                            <div *ngIf="!vehicle.controls.isAddedManually.value">
                                                {{vehicle.controls.color.value}}</div>
                                        </td>


                                        <td>
                                            <div class="form-group" *ngIf="!viewModels[i].autoImportModel.mileage">
                                                <input style="width: 80px;" type="text" class="form-control"
                                                    placeholder="Mileage" formControlName="mileage"
                                                    [ngClass]="{ 'is-invalid': submitted &&  vehicle.controls.mileage.errors }" />
                                                <div *ngIf="submitted &&  vehicle.controls.mileage.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="vehicle.controls.mileage.errors.required">Mileage
                                                        is required</div>
                                                    <div *ngIf="vehicle.controls.mileage.errors.pattern">Mileage
                                                        should be numeric</div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewModels[i].autoImportModel.mileage">
                                                {{viewModels[i].autoImportModel.mileage}}</div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <select class="form-control" formControlName="vehicleType"
                                                    placeholder="Type">
                                                    <option *ngFor="let item of vehicleTypes" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group" *ngIf="vehicle.controls.isAddedManually.value">
                                                <select class="form-control" formControlName="vehicleAgeType"
                                                    placeholder="New/Used">
                                                    <option *ngFor="let item of vehicleAgeTypes" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div *ngIf="!vehicle.controls.isAddedManually.value">
                                                {{ getVehicleAgeName(vehicle.controls.vehicleAgeType.value) }}</div>
                                        </td>
                                        <td>
                                            <div> {{ vehicle.controls.onHold.value ? 'Yes' : 'No' }}</div>
                                        </td>
                                        <td>
                                            <div class="from-group">
                                                <span>
                                                    <input type="checkbox" class="form-control" id="ckhIsSelected{{i}}"
                                                        formControlName="isSelected"
                                                        (change)="onIsSelectedChange(vehicle.controls.isSelected.value)" />
                                                    <label for="chkIsSelected{{i}}"></label>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="approveBtnWrp">
                        <button class="btn btn-red" type="button" (click)="onPutOnHold()">Put On Hold</button>
                        <button class="btn btn-red" type="button" (click)="onRemoveHold()">Remove Hold</button>
                        <button class="btn btn-red" type="button" (click)="onDeleteSelected()">Delete Selected</button>
                        <button class="btn btn-red" type="submit">Import Selected</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</section>