import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PricingType } from 'src/app/enums';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { OperationCodeAddModel, OperationCodeDetailModel, SelectListModel } from 'src/app/models';
import { CarLayoutLocationService, CarLayoutPanelService, CarLayoutSideService, CategoryService, MessagingService, OperationCodeService, SubCategoryService } from 'src/app/services';

@Component({
    selector: 'app-add-op-code',
    templateUrl: './add-op-code.component.html',
    styleUrls: ['./add-op-code.component.css']
})
export class AddOpCodeComponent implements OnInit {
    @BlockUI('container-blockui') blockUI: NgBlockUI;

    PricingType = PricingType;
    form: FormGroup;
    submitted = false;
    categorySelectList: Array<SelectListModel> = new Array<SelectListModel>();
    subCategorySelectList: Array<SelectListModel> = new Array<SelectListModel>();
    carLayoutSideSelectList: Array<SelectListModel> = new Array<SelectListModel>();
    carLayoutPanelSelectList: Array<SelectListModel> = new Array<SelectListModel>();
    carLayoutLocationSelectList: Array<SelectListModel> = new Array<SelectListModel>();
    model: OperationCodeAddModel;
    vehicleLayoutTypeOptions: Array<SelectListModel> = new Array<SelectListModel>();

    constructor(private router: Router,
        private route: ActivatedRoute,
        private messagingService: MessagingService,
        public appUtils: AppUtils,
        private formBuilder: FormBuilder,
        private stringLengthConstants: StringLengthConstants,
        private operationCodeService: OperationCodeService,
        private carLayoutSideService: CarLayoutSideService,
        private carLayoutPanelService: CarLayoutPanelService,
        private categoryService: CategoryService,
        private carLayoutLocationService: CarLayoutLocationService,
        private subCategoryService: SubCategoryService,
    ) {
        this.model = new OperationCodeAddModel();
        this.vehicleLayoutTypeOptions = this.appUtils.getVehicleLayoutTypeOptions();
    }

    ngOnInit() {
        this.loadData();

        this.form = this.formBuilder.group({
            isLocationSpecific: [false],
            isTiedToIVL: [false],
            vehicleLayoutType: ['',
                [RxwebValidators.required({ conditionalExpression: (x, y) => x.isTiedToIVL == true })]
            ],
            categoryId: ['', [Validators.required]],
            subCategoryId: ['',
                RxwebValidators.required({ conditionalExpression: (x, y) => x.categoryId && Array.isArray(this.subCategorySelectList) && this.subCategorySelectList.length > 0 }),
            ],
            carLayoutPanelId: ['', [
                RxwebValidators.required({ conditionalExpression: (x, y) => x.isLocationSpecific == true })
            ]],
            carLayoutSideId: ['', []],
            carLayoutLocationId: ['', []],
            description: ['', [Validators.maxLength(this.stringLengthConstants.Default)]],
        });

        this.form.valueChanges.subscribe(() => {
            this.model = this.populateOperationCodeAddModel();
        });
    }

    private loadData() {
        this.blockUI.start();
        forkJoin([
            this.categoryService.selectList(),
            this.carLayoutSideService.selectList(),
            this.carLayoutPanelService.selectList(),
            this.carLayoutLocationService.selectList()
        ]).subscribe((result) => {
            this.categorySelectList = result[0];
            this.carLayoutSideSelectList = result[1];
            this.carLayoutPanelSelectList = result[2];
            this.carLayoutLocationSelectList = result[3];
            //Set default Name
            this.model.name = this.createOPCodeName(this.model);
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }

    public onCategoryChange() {
        if (!this.form.controls.categoryId.value) {
            return;
        }

        this.blockUI.start();
        this.subCategoryService.selectList(Number(this.form.controls.categoryId.value)).subscribe((data: Array<SelectListModel>) => {
            this.subCategorySelectList = data;
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }

    public onSubmit() {

        this.submitted = true;

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.model = this.populateOperationCodeAddModel();
        this.blockUI.start();
        this.operationCodeService.add(this.model).subscribe((data: OperationCodeDetailModel) => {
            setTimeout(() => {
                this.router.navigate(['/manage/op-code']);
            }, 10);
            setTimeout(() => {
                this.messagingService.success('Operation Code has been added successfully.');
            }, 300);
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }

    private populateOperationCodeAddModel(): OperationCodeAddModel {
        let model = new OperationCodeAddModel();

        model.isLocationSpecific = this.form.controls.isLocationSpecific.value;
        model.isTiedToIVL = this.form.controls.isTiedToIVL.value;
        model.vehicleLayoutType = this.form.controls.isTiedToIVL.value ?
            Number(this.form.controls.vehicleLayoutType.value) : 0;
        model.description = this.form.controls.description.value;
        model.categoryId = Number(this.form.controls.categoryId.value);
        if (this.form.controls.subCategoryId.value) {
            model.subCategoryId = Number(this.form.controls.subCategoryId.value);
        } else {
            model.subCategoryId = null;
        }


        if (model.isLocationSpecific) {
            if (this.form.controls.carLayoutSideId.value) {
                model.carLayoutSideId = Number(this.form.controls.carLayoutSideId.value);
            } else {
                model.carLayoutSideId = null;
            }

            if (this.form.controls.carLayoutPanelId.value) {
                model.carLayoutPanelId = Number(this.form.controls.carLayoutPanelId.value);
            } else {
                model.carLayoutPanelId = null;
            }

            if (this.form.controls.carLayoutLocationId.value) {
                model.carLayoutLocationId = Number(this.form.controls.carLayoutLocationId.value);
            } else {
                model.carLayoutLocationId = null;
            }
        } else {
            model.carLayoutSideId = null;
            model.carLayoutSideId = null;
            model.carLayoutSideId = null;
        }

        model.name = this.createOPCodeName(model);

        return model;
    }

    private createOPCodeName(model: OperationCodeAddModel): string {
        let category = this.categorySelectList.find(x => x.id == model.categoryId);
        let subCategory = this.subCategorySelectList.find(x => x.id == model.subCategoryId);
        let carLayoutPanel = this.carLayoutPanelSelectList.find(x => x.id == model.carLayoutPanelId);
        let carLayoutLocation = this.carLayoutLocationSelectList.find(x => x.id == model.carLayoutLocationId);
        let carLayoutSide = this.carLayoutSideSelectList.find(x => x.id == model.carLayoutSideId);

        return this.appUtils.generateOperationCode(
            category == null ? null : category.code,
            subCategory == null ? null : subCategory.code,
            carLayoutPanel == null ? null : carLayoutPanel.code,
            carLayoutLocation == null ? null : carLayoutLocation.code,
            carLayoutSide == null ? null : carLayoutSide.code,
        );
    }
}
