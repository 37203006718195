<ng-template #storeConfirmationModal let-modal>
  <div *blockUI="'container-blockui-confirmation'">
      <div class="modal-body">
          <div class="confirmPopup">
              <h5 class="modal-title">Confirm Delete</h5>
              <P>{{message}}</P>
              <div class="buttonWrp">
                  <button type="button" class="btn btn-red m-r-5" (click)="onConfirm()">Delete</button>
                  <button type="button" class="btn btn-tsrp" (click)="closeModal()">Cancel</button>
              </div>
          </div>
      </div>
  </div>
</ng-template>