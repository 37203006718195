import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DndDropEvent } from 'ngx-drag-drop';
import { CategoryDetailModel, SubCategorySelectListModel, WorkflowStageCategoryModel, WorkflowStageModel } from 'src/app/models';

@Component({
	selector: 'app-workflow-stage-editable-widget',
	templateUrl: './workflow.stage.editable.widget.component.html',
	styleUrls: ['./workflow.stage.editable.widget.component.css']
})
export class WorkflowStageEditableWidgetComponent implements OnChanges {

	@ViewChild('editModal', { static: false }) editModal: any;
	@ViewChild('deleteConfirmationModal', { static: false }) deleteConfirmationModal: any;

	@Input('categories') categories: Array<CategoryDetailModel>;
	@Input('subCategories') subCategories: Array<SubCategorySelectListModel>;
	@Input('workflowStageModel') workflowStageModel: WorkflowStageModel;
	@Output() deleteWorkflowStage = new EventEmitter<WorkflowStageModel>();
	@Output() updateWorkflowStage = new EventEmitter<WorkflowStageModel>();


	
	ngOnChanges(changes: SimpleChanges): void {
		this.processModelChange();
	}

	openWorkflowStepEditModal() {
		this.editModal.openModal(this.workflowStageModel);
	}

	updated(workflowStageModel: WorkflowStageModel) {
		this.workflowStageModel = workflowStageModel;
		this.processModelChange()
		this.updateWorkflowStage.emit(this.workflowStageModel);
	}

	openWorkflowStepDeleteModal() {
		this.deleteConfirmationModal.openModal(this.workflowStageModel);
	}

	delete(workflowStageModel: WorkflowStageModel) {
		this.deleteWorkflowStage.emit(this.workflowStageModel);
	}

	private processModelChange() {
		this.workflowStageModel.workflowStageCategories.forEach((elem: WorkflowStageCategoryModel) => {
			elem.categoryName = this.categories.find(x => x.id === elem.categoryId).name;
			if (elem.subCategoryId != null) {
				elem.subCategoryName = this.subCategories.find(x => x.id === elem.subCategoryId).name;
			}
		});
	}

	// ----------------
	onDropCategory($event: DndDropEvent) {
		//Create new step categories mapping
		let workflowStageCategoryModel = new WorkflowStageCategoryModel();
		workflowStageCategoryModel.categoryId = $event.data.id;
		workflowStageCategoryModel.isActive = $event.data.isActive;

		//Update it
		this.workflowStageModel.workflowStageCategories.push(workflowStageCategoryModel);
		this.workflowStageModel.dependencyLocked = Array.isArray(this.workflowStageModel.workflowStageCategories) && this.workflowStageModel.workflowStageCategories.length > 1;

		this.processModelChange()
		this.updateWorkflowStage.emit(this.workflowStageModel);
	}

	get isAllCategoriesDisabled() {
		var flag =  !this.workflowStageModel.workflowStageCategories.every(x => x.isActive) ;
		if(flag)
		{
		return true;
		}
		else{
			return false;
		}
	}

	get isAllCategoryActive() {
		return this.workflowStageModel.workflowStageCategories.every(x => x.isActive);
	}

	get hasMandatoryOPCodes() {
		return this.workflowStageModel.workflowStageCategories.some(x => x.operationCodes.length > 0);
	}
}
