<section class="loginSec">
    <div class="container">
        <div class="loginLogo"><a href="/"><img [src]="logo" alt="Recon Partners Logo" /></a></div>
        <div class="loginWrp" *blockUI="'blockui-frm-login'">
            <form class="loginform" [formGroup]="frm" (ngSubmit)="onSubmit()">
                <h2>Activate Password</h2>
                <div class="form-group">
                    <div class="labelWrp"><label>Temporary Password</label></div>
                    <div class="fieldWrp">
                        <input type="password" formControlName="temporaryPassword" autocomplete="off"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && frm.controls.temporaryPassword.errors }" />
                        <div *ngIf="submitted && frm.controls.temporaryPassword.errors" class="invalid-feedback">
                            <div *ngIf="frm.controls.temporaryPassword.errors.required">Temporary Password is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="labelWrp"><label>New Password</label></div>
                    <div class="fieldWrp">
                        <input type="password" formControlName="newPassword" autocomplete="off" class="form-control"
                        [ngClass]="{ 'is-invalid': (submitted && !passwordIsValid) || (submitted && frm.controls.newPassword.errors)}" />
                        <div *ngIf="submitted && frm.controls.newPassword.errors" class="invalid-feedback">
                            <div *ngIf="frm.controls.newPassword.errors.required">New Password is required
                            </div>
                            <div *ngIf="frm.controls.newPassword.errors.minlength">New Password must be at least 6
                                characters
                            </div>
                            <div *ngIf="frm.controls.newPassword.errors.maxlength">New Password must be at max 20
                                characters
                            </div>
                            <div *ngIf="frm.controls.newPassword.errors.mustNotMatch">Temporary Password and New
                                Password can't be same</div>
                        </div>
                        <div *ngIf="submitted && !passwordIsValid" class="invalid-feedback">
                            Use combination of Captial, Small, Numbers and Special characters
                        </div>
                        <app-password-strength [passwordToCheck]="frm.controls.newPassword.value"
                            (passwordStrength)="passwordValid($event)"></app-password-strength>
                    </div>
                </div>
                <div class="form-group">
                    <div class="labelWrp"><label>Confirm Password</label></div>
                    <div class="fieldWrp">
                        <input type="password" formControlName="confirmPassword" autocomplete="off"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && frm.controls.confirmPassword.errors }" />
                        <div *ngIf="submitted && frm.controls.confirmPassword.errors" class="invalid-feedback">
                            <div *ngIf="frm.controls.confirmPassword.errors.required">Confirm Password is required
                            </div>
                            <div *ngIf="frm.controls.confirmPassword.errors.mustMatch">New Password and Confirm Password
                                must match</div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <button class="btn btn-primary">Activate</button>
                </div>
            </form>

        </div>
        <div class="wellcomDashboard">
            <h1>Welcome to <strong>My Dashboard</strong></h1>
            <p>Login to access your account</p>
        </div>
        <div class="copyright">
            <p>Copyright &copy; 2020. All rights reserved.</p>
        </div>
    </div>
</section>