import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApplicationRoleType } from 'src/app/enums';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { AppUtils } from 'src/app/helpers';
import { AccountRecoverModel, SelectListModel, UserDetailModel } from 'src/app/models';
import { AuthenticationService, CompanyService, FilterStateService, MessagingService, StoreService, UserService, VendorService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { UserDeleteConfirmationComponent } from '../delete-confirmation/user.delete.confirmation.component';
import { ResetOtherUserPasswordConfirmationComponent } from '../reset-other-user-password/reset.other.user.password.confirmation.component';
import { UserStatusChangeConfirmationComponent } from '../status-change-confirmation/user.status.change.confirmation.component';
import { WelcomeMailConfirmationComponent } from '../welcome-mail-confirmation/welcome.mail.confirmation.component';

@Component({
    selector: 'app-user-manage',
    templateUrl: './user.manage.component.html',
    styleUrls: ['./user.manage.component.css']
})
export class UserManageComponent implements OnInit, AfterViewInit, OnDestroy {

    @BlockUI('container-blockui-manage') blockUI: NgBlockUI;
    @ViewChild('deleteConfirmationModal', { static: false }) deleteConfirmationModal: UserDeleteConfirmationComponent;
    @ViewChild('statusChangeConfirmationModal', { static: false }) statusChangeConfirmationModal: UserStatusChangeConfirmationComponent;
    @ViewChild('resetPasswordOtherUserConfirmationModal', { static: false }) resetPasswordOtherUserConfirmationModal: ResetOtherUserPasswordConfirmationComponent;
    @ViewChild('welcomeMailConfirmationModal', { static: false }) welcomeMailConfirmationModal: WelcomeMailConfirmationComponent;

    //Data Table Options
    @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtInstance: DataTables.Api;
    rowIndex = 0;
    statusOptions: any;
    ApplicationRoleType = ApplicationRoleType;
    companies: Array<SelectListModel> = new Array<SelectListModel>();
    stores: Array<SelectListModel> = new Array<SelectListModel>();
    vendors: Array<SelectListModel> = new Array<SelectListModel>();


    //Context for which users will be created
    entityName: string;
    selectedApplicationRoleType: ApplicationRoleType = ApplicationRoleType.None;

    form: FormGroup;
    roleTypeFilterOptions: any;

    constructor(private router: Router,
        public route: ActivatedRoute,
        private userService: UserService,
        private formBuilder: FormBuilder,
        private storeService: StoreService,
        private vendorService: VendorService,
        private authenticationService: AuthenticationService,
        private messagingService: MessagingService,
        private companyService: CompanyService,
        public appUtils: AppUtils,
        private filterStateService: FilterStateService
    ) {
        this.statusOptions = this.appUtils.getStatusOptions();
        this.roleTypeFilterOptions = this.appUtils.getApplicationRoleTypeOptions();
    }

    ngOnInit(): void {
        this.initDataTable();
        this.initForm();
    }


    private loadCompanies() {
        this.blockUI.start();
        this.companyService.getCompanies().subscribe((data: Array<SelectListModel>) => {
            this.companies = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    private loadStores() {
        this.blockUI.start();
        this.storeService.storeSelectList().subscribe((data: Array<SelectListModel>) => {
            this.stores = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    private loadVendors() {
        this.blockUI.start();
        this.vendorService.vendorSelectList().subscribe((data: Array<SelectListModel>) => {
            this.vendors = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    applyApplicationRoleTypeFilter() {
        this.selectedApplicationRoleType = Number(this.form.controls.applicationRoleType.value);
        if (this.selectedApplicationRoleType == ApplicationRoleType.Partner && this.companies.length === 0) {
            this.loadCompanies();
        }
        if (this.selectedApplicationRoleType == ApplicationRoleType.Store && this.stores.length === 0) {
            this.loadStores();
        }
        if (this.selectedApplicationRoleType == ApplicationRoleType.Vendor && this.vendors.length === 0) {
            this.loadVendors();
        }

        this.dtInstance.ajax.reload();
    }

    ngAfterViewInit() {
        this.updateFormFromFilterModel();
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);
    }

    updateFormFromFilterModel() {
        const tempFilterModel = this.filterStateService.getFilterModel(FilterPageType.userManage);
        if (tempFilterModel && tempFilterModel.isActive) {
            this.form.controls.statusOption.setValue(tempFilterModel.statusOption);
            this.form.controls.applicationRoleType.setValue(tempFilterModel.extras.roleTypeId);
            this.form.controls.company.setValue(tempFilterModel.extras.company);
            this.form.controls.vendor.setValue(tempFilterModel.extras.vendor);
            this.form.controls.store.setValue(tempFilterModel.extras.store);
        }
    }

    updateFilterModel() {
        const filterModel = this.filterStateService.getFilterModel(FilterPageType.userManage);
        filterModel.page = FilterPageType.userManage;
        filterModel.statusOption = this.form.controls.statusOption.value;
        filterModel.extras.roleTypeId = this.form.controls.applicationRoleType.value;
        filterModel.extras.company = this.form.controls.company.value;
        filterModel.extras.vendor = this.form.controls.vendor.value;
        filterModel.extras.store = this.form.controls.store.value;
        filterModel.isActive = false;
    }

    ngOnDestroy(): void {
    }

    initForm() {
        this.form = this.formBuilder.group({
            applicationRoleType: [ApplicationRoleType.None],
            company: [''],
            store: [''],
            vendor: [''],
            statusOption: [2] //Active
        });

        this.form.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
            this.applyApplicationRoleTypeFilter();
        });
    }

    initDataTable() {

        //Data Table Example.
        const self = this;
        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter...',
                paginate: {
                    first: '<em class="fa fa-angle-double-left">',
                    last: '<em class="fa fa-angle-double-right">',
                    previous: '<em class="fa fa-angle-left">',
                    next: '<em class="fa fa-angle-right">'
                }
            },
            search: { search: null },
            searching: false,
            displayStart: self.rowIndex,
            paging: false,
            pagingType: 'full_numbers',
            pageLength: environment.paging.defaultPageSize,
            lengthMenu: environment.paging.lengthMenu,
            order: [[0, 'asc']],
            stateSave: true,
            info: false,
            scrollY: (window.innerHeight - 70 - 45) + "px",
            scrollCollapse: true,
            ajax: (dataTablesParameters: any, callback) => {
                self.updateFilterModel();

                self.poulateDataTableParameters(dataTablesParameters, self);
                self.blockUI.start();
                self.userService.query(dataTablesParameters)
                    .subscribe(resp => {
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
                        self.blockUI.stop();
                    }, error => {
                        self.messagingService.ProcessErrorResponse(error);
                        self.blockUI.stop();
                    });
            },
            columns: [
                {
                    data: 'name',
                    title: 'Name',
                    width: '12%',
                    render: function (data, type, row) {
                        return `<span class='link' action-type='link-detail'>${row.name}</a>`;
                    }
                },
                {
                    data: 'userName',
                    title: 'User Name',
                    width: '12%'
                },
                {
                    data: 'email',
                    title: 'Email',
                    width: '14%'
                },
                {
                    data: 'phoneNumber',
                    title: 'Phone Number',
                    width: '10%',
                    render: function (data, type, row) {
                        return `<span>${row.phoneCode} ${row.phoneNumber}</span>`;
                    }
                },
                {
                    data: 'applicationRoleType',
                    title: 'Role Type',
                    width: '8%',
                    render: function (applicationRoleType) {
                        return self.roleTypeFilterOptions.find((x: UserDetailModel) => x.id == applicationRoleType).name;
                    }
                },
                {
                    data: 'roles',
                    title: 'Roles',
                    orderable: false,
                    width: '15%',
                    render: function (roles) {
                        if (!Array.isArray(roles))
                            return;
                        return roles.join(', ');
                    }
                },
                {
                    data: 'isActive',
                    title: 'Status',
                    width: '6%',
                    render: function (isActive) {
                        return isActive
                            ? '<span class="badge badge-success">Active</span>'
                            : '<span class="badge badge-danger">Inactive</span>';
                    }
                },
                {
                    data: null,
                    title: 'Action',
                    width: '16%',
                    orderable: false,
                    className: 'text-center',
                    visible: true,
                    render: function (data, type, row: UserDetailModel) {
                        //Render Activate or Deactivate Button
                        let statusHtml = row.isActive ?
                            `<button title='Deactivate' action-type='toggle-status'><em class="fa fa-ban" aria-hidden="true"></em></button>` :
                            `<button title='Activate' action-type='toggle-status'><em class="fa fa-check" aria-hidden="true"></em></button>`;
                        let sendActivationMailHtml = row.emailConfirmed
                            ? ''
                            : `<button title='Send Welcome Mail' action-type='activation-link'><em class="fa fa-envelope" aria-hidden="true"></em></button>`;

                        return `
                         <button title='View Detail' action-type='view'><em class="fa fa-eye" aria-hidden="true"></em></button>
                         <button title='Reset Password' action-type='reset-password'><em class="fa fa-key" aria-hidden="true"></em></button>
                         ${sendActivationMailHtml}
                         ${statusHtml}
                         <button title='Edit' action-type='edit'><em class="fa fa-edit" aria-hidden="true"></em></button>
                         <button title='Delete' action-type='delete'><em class="fa fa-trash" aria-hidden="true"></em></button>
                         `;
                    }
                }
            ],
            rowCallback: function (row, data: UserDetailModel) {
                const _row = $(row);

                _row.find('[action-type="toggle-status"]')
                    .off('click')
                    .on('click', function () {
                        self.openStatusChangeConfirmation(data);
                    });

                _row.find('[action-type="edit"]')
                    .off('click')
                    .on('click', function () {
                        self.router.navigate(['/edit/user', data.id])
                    });

                _row.find('[action-type="delete"]')
                    .off('click')
                    .on('click', function () {
                        self.openDeleteConfirmation(data);
                    });

                _row.find('[action-type="link-detail"],[action-type="view"]')
                    .off('click')
                    .on('click', function () {
                        self.router.navigate(['/user/detail', data.id]);
                    });

                _row.find('[action-type="activation-link"]')
                    .off('click')
                    .on('click', function () {
                        self.openActivationMailConfirmation(data);
                    });

                _row.find('[action-type="reset-password"]')
                    .off('click')
                    .on('click', function () {
                        self.openResetPasswordOtherUserConfirmation(data);
                    });
            }
        };
    }

    private poulateDataTableParameters(dataTablesParameters: any, self: this) {

        dataTablesParameters.applicationRoleType = null;
        dataTablesParameters.companyId = null;
        dataTablesParameters.storeId = null;
        dataTablesParameters.vendorId = null;
        dataTablesParameters.status = self.statusOptions.find(x => x.id == self.form.value.statusOption).value;

        if (self.selectedApplicationRoleType != ApplicationRoleType.None) {
            dataTablesParameters.applicationRoleType = self.selectedApplicationRoleType;
            switch (self.selectedApplicationRoleType) {
                case ApplicationRoleType.Partner:
                    if (self.form.controls.company.value) {
                        dataTablesParameters.companyId = Number(self.form.controls.company.value);
                    }
                    break;
                case ApplicationRoleType.Store:
                    if (self.form.controls.store.value) {
                        dataTablesParameters.storeId = Number(self.form.controls.store.value);
                    }
                    break;
                case ApplicationRoleType.Vendor:
                    if (self.form.controls.vendor.value) {
                        dataTablesParameters.vendorId = Number(self.form.controls.vendor.value);
                    }
                    break;
            }
        }
    }

    openDeleteConfirmation(user: UserDetailModel) {
        this.deleteConfirmationModal.openModal(user);
    }

    delete(user: UserDetailModel) {
        this.blockUI.start();
        this.userService.delete(user.id).subscribe(
            () => {
                setTimeout(() => {
                    this.dtInstance.ajax.reload(null, false);
                });
                this.messagingService.success(`User has been deleted successfully.`);
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
            });
    }

    openStatusChangeConfirmation(user: UserDetailModel) {
        this.statusChangeConfirmationModal.openModal(user);
    }

    toggle(user: UserDetailModel) {
        this.blockUI.start();
        this.userService.toggleStatus(user.id).subscribe(
            () => {
                setTimeout(() => {
                    this.dtInstance.ajax.reload(null, false);
                });
                this.messagingService.success(`User has been ${(user.isActive ? 'deactivated' : 'activated')} successfully.`);
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
            });
    }

    openResetPasswordOtherUserConfirmation(user: UserDetailModel) {
        this.resetPasswordOtherUserConfirmationModal.openModal(user);
    }

    resetPassword(user: UserDetailModel) {
        this.blockUI.start();
        let accountRecoverModel = new AccountRecoverModel();
        accountRecoverModel.email = user.userName;
        this.authenticationService.recover(accountRecoverModel).subscribe(
            () => {

                this.messagingService.success(`Password for ${user.userName} reset successfully.`);
                this.blockUI.stop();
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
                this.blockUI.stop();
            });
    }

    openActivationMailConfirmation(user: UserDetailModel) {
        this.welcomeMailConfirmationModal.openModal(user);
    }

    sendWelcomeMail(user: UserDetailModel) {
        this.authenticationService.sendActivationMail(user.id).subscribe(
            () => {
                this.messagingService.success(`Welcome mail sent for ${user.userName} successfully.`);
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            }, () => {
                this.blockUI.stop();
            });
    }
}
