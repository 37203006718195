<section class="datatableSer">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <h1>Car Layout Side</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="tableWrp">
                    <div *blockUI="'container-blockui-grid'" class="bluetableWrp table-responsive">
                        <table datatable [dtOptions]="dtOptions" data-page="2"
                            class="table-hover break-all table w-100" summary="Car Layout Panels"></table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>