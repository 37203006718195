import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { StringLengthConstants } from "src/app/helpers";
import { SelectListModel, VehicleQueueNoteAddModel, VehicleQueueNoteModel } from "src/app/models";
import { ApplicationContextService, MessagingService, VehicleQueueNoteService, VehicleQueueService } from "src/app/services";

@Component({
    selector: 'app-vehicle-queue-note',
    templateUrl: './vehicle.queue-note.component.html',
    styleUrls: ['./vehicle.queue-note.component.css']
})

export class VehicleQueueNoteComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @BlockUI('container-blockui-vehicle-note') blockUI: NgBlockUI;
    @ViewChild('queueNoteModal', { static: false }) confirmationModal: any;
    @Input() vehicleQueueId: number;
    @Input() queueNotes: Array<VehicleQueueNoteModel>;
    modalReference: NgbModalRef;
    frmNote: FormGroup;
    lastComment: string = 'No Comment';
    storeSubscription: any;
    submitted: boolean = false;
    constructor(
        private formBuilder: FormBuilder,
        private modalService: NgbModal,
        private stringLengthConstants: StringLengthConstants,
        private messagingService: MessagingService,
        private vehicleQueueService: VehicleQueueService,
        private applicationContextService: ApplicationContextService
    ) {
        this.queueNotes = new Array<VehicleQueueNoteModel>();
    }

    ngOnInit() {
        this.frmNote = this.formBuilder.group({
            storeId: [],
            note: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Default)]]
        });
    }

    ngAfterViewInit() {
        this.storeSubscription = this.applicationContextService.store$.subscribe((storeSelectListItem: SelectListModel) => {
            if (storeSelectListItem) {
                this.frmNote.controls.storeId.setValue(storeSelectListItem.id);
            } else {
                this.frmNote.controls.storeId.setValue(null);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.queueNotes.length > 0) {
            this.lastComment = this.queueNotes[0].comment;
        }
    }

    loadNotes() {
        this.vehicleQueueService.getNotes(this.vehicleQueueId)
            .subscribe((data: Array<VehicleQueueNoteModel>) => {
                this.queueNotes = data;
                if (this.queueNotes.length > 0) {
                    this.lastComment = this.queueNotes[0].comment;
                }
            }, error => {
                this.messagingService.ProcessErrorResponse(error);
            }, () => {
                this.blockUI.stop();
            });
    }

    openModal() {
        this.loadNotes();
        this.modalReference = this.modalService.open(this.confirmationModal, { centered: true, scrollable: true, size: 'lg' });
    }

    onSubmit() {
        this.submitted = true;
        if (this.frmNote.invalid) {
            return;
        }

        let model = new VehicleQueueNoteAddModel();
        model.vehicleQueueId = this.vehicleQueueId;
        model.comment = this.frmNote.controls.note.value;
        model.storeId = this.frmNote.controls.storeId.value;

        this.blockUI.start();
        this.vehicleQueueService.add(model)
            .subscribe(() => {
                this.frmNote.controls.note.setValue('');
                this.loadNotes();
                this.submitted = false;
            }, error => {
                this.messagingService.ProcessErrorResponse(error);
                this.blockUI.stop();
            }, () => {
                this.blockUI.stop();
            });
    }

    onCancel() {
        if (!this.modalReference) {
            return;
        }
        this.modalReference.close();
    }

    ngOnDestroy(): void {
        this.onCancel();
        this.storeSubscription.unsubscribe();
    }
}