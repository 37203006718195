import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { FilterPageType } from "src/app/enums/filter.page.type.enum";
import { AppUtils } from "src/app/helpers";
import { SubCategoryDetailModel, VendorDetailModel } from "src/app/models";
import { FilterStateService, MessagingService, SubCategoryService } from "src/app/services";
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-manage-sub-category',
    templateUrl: './manage.sub-category.component.html',
    styleUrls: ['./manage.sub-category.component.css']
})

export class SubCategoryManageComponent implements OnInit {
    frmSearch: FormGroup;
    filterOptions: any;
    statusOptions: any;
    //Data Table Options
    @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtInstance: DataTables.Api;
    rowIndex: number = 0;

    @BlockUI('container-blockui-vendor-grid') blockUI: NgBlockUI;
    @ViewChild('deleteConfirmationModal', { static: true }) deleteConfirmationModal: any;
    @ViewChild('statusChangeConfirmationModal', { static: true }) statusChangeConfirmationModal: any;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private messagingService: MessagingService,
        private subCategoryService: SubCategoryService,
        public appUtils: AppUtils,
        private filterStateService: FilterStateService
    ) {
        this.filterOptions = this.appUtils.getFilterOperationOptions();
        this.statusOptions = this.appUtils.getStatusOptions();
    }

    ngAfterViewInit() {
        this.updateFormFromFilterModel();

        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);
    }

    updateFormFromFilterModel() {
        const tempFilterModel = this.filterStateService.getFilterModel(FilterPageType.toolManage);
        if (tempFilterModel && tempFilterModel.isActive) {
            this.frmSearch.controls.search.setValue(tempFilterModel.filterKey);
            this.frmSearch.controls.filterOption.setValue(tempFilterModel.filterOption);
            this.frmSearch.controls.statusOption.setValue(tempFilterModel.statusOption);
        }
    }

    updateFilterModel() {
        const filterModel = this.filterStateService.getFilterModel(FilterPageType.toolManage);
        filterModel.page = FilterPageType.toolManage;
        filterModel.filterKey = this.frmSearch.controls.search.value;
        filterModel.filterOption = this.frmSearch.controls.filterOption.value;
        filterModel.statusOption = this.frmSearch.controls.statusOption.value;
        filterModel.isActive = false;
    }

    ngOnDestroy(): void {
    }

    ngOnInit() {
        this.frmSearch = this.formBuilder.group({
            search: [''],       // Change here 
            filterOption: [1], //Contains
            statusOption: [2] //Active
        });
        this.initDataTable();

        this.frmSearch.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
            this.applyFilter();
        });
    }

    private initDataTable() {
        const self = this;
        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter user groups...',
                paginate: {
                    first: '<em class="fa fa-angle-double-left">',
                    last: '<em class="fa fa-angle-double-right">',
                    previous: '<em class="fa fa-angle-left">',
                    next: '<em class="fa fa-angle-right">'
                }
            },
            search: { search: null },
            searching: false,
            displayStart: self.rowIndex,
            paging: false,
            pagingType: 'full_numbers',
            pageLength: environment.paging.defaultPageSize,
            lengthMenu: environment.paging.lengthMenu,
            order: [[0, 'asc']],
            stateSave: true,
            info: false,
            scrollY: (window.innerHeight - 70 - 45) + "px",
            scrollCollapse:true,
            ajax: (dataTablesParameters: any, callback) => {

                self.updateFilterModel();

                dataTablesParameters.customSearch = self.frmSearch.controls.search.value; //Changes done here//
                dataTablesParameters.filterBy = Number(self.frmSearch.controls.filterOption.value);
                dataTablesParameters.status = self.statusOptions.find(x => x.id == self.frmSearch.controls.statusOption.value).value;

                self.blockUI.start();
                self.subCategoryService.query(dataTablesParameters)
                    .subscribe(resp => {
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
						self.blockUI.stop();
                    }, error => {
                        self.messagingService.ProcessErrorResponse(error);
                        self.blockUI.stop();
                    });
            },
            columns: [               
                {
                    data: 'categoryName',
                    title: 'Category Name'
                },
                {
                    data: 'name',
                    title: 'Name',
                    render: function (data, type, row) {
                        return `<span class='link' action-type='link-detail'>${row.name}</a>`; 
                    }
                },
                {
                    data: 'description',
                    title: 'Description'
                },
                {
                    data: 'code',
                    title: 'Code',
                    width: '8%'
                },
                {
                    data: 'sequence',
                    title: 'IVL Rank',
                    width: '8%'
                },
                {
                    data: 'isActive',
                    title: 'Status',
                    width: '8%',
                    render: function (isActive) {
                        return isActive
                            ? '<span class="badge badge-success">Active</span>'
                            : '<span class="badge badge-danger">Inactive</span>';
                    }
                },
                {
                    data: null,
                    title: 'Action',
                    width: '15%',
                    orderable: false,
                    className: 'text-center',
                    visible: true,
                    render: function (data, type, row) {
                        //Render Activate or Deactivate Button
                        let statusHtml = row.isActive ?
                            `<button title='Deactivate' action-type='toggle-status'><em class="fa fa-ban" aria-hidden="true"></em></button>` :
                            `<button title='Activate' action-type='toggle-status'><em class="fa fa-check" aria-hidden="true"></em></button>`;
                        return `
                        <button title='View Detail' action-type='view'><em class="fa fa-eye" aria-hidden="true"></em></button>
                        ${statusHtml}
                        <button title='Edit' action-type='edit'><em class="fa fa-edit" aria-hidden="true"></em></button>
                        <button title='Delete' action-type='delete'><em class="fa fa-trash" aria-hidden="true"></em></button>

                        `;
                    }
                }
            ],
            rowCallback: function (row, data: SubCategoryDetailModel) {
                const _row = $(row);

                _row.find('[action-type="view"],[action-type="link-detail"]')
                    .off('click')
                    .on('click', function () {
                        self.router.navigate(['sub-category', 'detail', data.id]);
                    });

                _row.find('[action-type="edit"]')
                    .off('click')
                    .on('click', function () {
                        self.router.navigate(['edit', 'sub-category', data.id]);
                    });

                _row.find('[action-type="delete"]')
                    .off('click')
                    .on('click', function () {
                        self.openCheckInTypeDeleteModal(data);
                    });

                _row.find('[action-type="toggle-status"]')
                    .off('click')
                    .on('click', function () {
                        self.openCheckInTypeStatusModal(data);
                    });
            }
        };
    }

    applyFilter() {
        this.dtInstance.ajax.reload();
    }

    private openCheckInTypeDeleteModal(data) {
        this.deleteConfirmationModal.openModal(data);
    }

    delete(subCategory: SubCategoryDetailModel) {
        this.blockUI.start();
        this.subCategoryService.delete(subCategory.id).subscribe(
            () => {
                setTimeout(() => {
                    this.dtInstance.ajax.reload(null, false);
                });
                this.messagingService.success(`Tool has been deleted successfully.`);
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
            });
    }

    private openCheckInTypeStatusModal(data) {
        this.statusChangeConfirmationModal.openModal(data);
    }

    toggle(vendor: SubCategoryDetailModel) {
        this.blockUI.start();
        this.subCategoryService.toggleStatus(vendor.id).subscribe(
            () => {
                setTimeout(() => {
                    this.dtInstance.ajax.reload(null, false);
                });
                this.messagingService.success(`Tool has been ${(vendor.isActive ? 'deactivated' : 'activated')} successfully.`);
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
            });
    }
}