<ng-template #importModal let-modal>
    <div>
        <div class="modal-body">
            <div class="workflowPopupWrp" *blockUI="'container-blockui-import'">
                <h2>Associate Workflow</h2>
                <form [formGroup]="form" (ngSubmit)="onSubmit()">

                    <div class="row">
                        <div class="col-12 col-sm-3">
                            <div class="from-group"><label>Store</label></div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="from-group" *ngIf="storeName"><label>{{storeName}}</label></div>
                            <div class="from-group" *ngIf="!storeName">
                                <select class="form-control" formControlName="storeId" placeholder="storeId"
                                    (change)="onStoreChange(form.controls.storeId.value)"
                                    [ngClass]="{ 'is-invalid': submitted && form.controls.storeId.errors }">
                                    <option [value]="null">Select Store</option>
                                    <option *ngFor="let item of stores" [value]="item.id">
                                        {{item.name}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && form.controls.storeId.errors" class="invalid-feedback">
                                    <div *ngIf="form.controls.storeId.errors.required">Store
                                        is required</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-sm-3">
                            <div class="from-group"><label>Check-in Type </label></div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="from-group">
                                <select class="form-control" formControlName="checkInTypeId" placeholder="Check-in Type"                                    
                                    [ngClass]="{ 'is-invalid': submitted && form.controls.checkInTypeId.errors }">
                                    <option [value]="null">Select Check-in Type</option>
                                    <option *ngFor="let item of checkInTypeSelectList" [value]="item.checkinTypeId">
                                        {{item.checkinTypeName}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && form.controls.checkInTypeId.errors" class="invalid-feedback">
                                    <div *ngIf="form.controls.checkInTypeId.errors.required">Check-in Type
                                        is required</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="fonm-control">
                                <div class="btnWrp">
                                    <button class="btn btn-red" type="submit">Associate</button>
                                    <button class="btn btn-tsrp" type="button" (click)="onCancel()">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>