var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI } from 'ng-block-ui';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { AppUtils } from 'src/app/helpers';
import { MessagingService, WorkflowService, StoreService, ApplicationContextService, FilterStateService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { WorkflowImportComponent } from '../import/workflow.import.component';
export class WorkflowManageComponent {
    constructor(router, route, messagingService, workflowService, formBuilder, storeService, appUtils, applicationContextService, filterStateService) {
        this.router = router;
        this.route = route;
        this.messagingService = messagingService;
        this.workflowService = workflowService;
        this.formBuilder = formBuilder;
        this.storeService = storeService;
        this.appUtils = appUtils;
        this.applicationContextService = applicationContextService;
        this.filterStateService = filterStateService;
        this.dtOptions = {};
        this.rowIndex = 0;
        this.storeSelectList = new Array();
        this.statusOptions = this.appUtils.getStatusOptions();
        this.deprecatedOptions = this.appUtils.getDeprecatedOptions();
    }
    ngAfterViewInit() {
        this.updateFormFromFilterModel();
        this.datatableElement.dtInstance.then((dtInstance) => this.dtInstance = dtInstance);
    }
    updateFormFromFilterModel() {
        const tempFilterModel = this.filterStateService.getFilterModel(FilterPageType.workFlowManage);
        if (tempFilterModel && tempFilterModel.isActive) {
            this.frm.controls.statusOption.setValue(tempFilterModel.statusOption);
            this.frm.controls.viewOnlySystemWorkflow.setValue(tempFilterModel.extras.viewOnlySystemWorkflow);
            this.frm.controls.isDeprecated.setValue(tempFilterModel.extras.isDeprecated);
        }
    }
    updateFilterModel() {
        const filterModel = this.filterStateService.getFilterModel(FilterPageType.workFlowManage);
        filterModel.page = FilterPageType.workFlowManage;
        filterModel.statusOption = this.frm.controls.statusOption.value;
        filterModel.extras.viewOnlySystemWorkflow = this.frm.controls.viewOnlySystemWorkflow.value;
        filterModel.extras.isDeprecated = this.frm.controls.isDeprecated.value;
        filterModel.isActive = false;
    }
    ngOnInit() {
        this.frm = this.formBuilder.group({
            storeId: [-1],
            viewOnlySystemWorkflow: [false],
            checkInTypeId: [''],
            statusOption: [2],
            isDeprecated: [3] //Not Deprecated
        });
        // this.loadData();
        this.initDataTable();
        this.companySubscription = this.applicationContextService.company$.subscribe((company) => {
            if (company != null) {
                this.storeSelectList = company.stores;
            }
        });
        this.storeSubscription = this.applicationContextService.store$.subscribe((storeSelectListItem) => {
            if (storeSelectListItem) {
                this.frm.controls.storeId.setValue(storeSelectListItem.id);
            }
            else {
                this.frm.controls.storeId.setValue(null);
            }
        });
        //Context form when store or company change in the header
        this.frm.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
            this.dtInstance.ajax.reload();
        });
    }
    ngOnDestroy() {
        this.companySubscription.unsubscribe();
        this.storeSubscription.unsubscribe();
    }
    initDataTable() {
        const self = this;
        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: '...',
                paginate: {
                    first: '<em class="fa fa-angle-double-left">',
                    last: '<em class="fa fa-angle-double-right">',
                    previous: '<em class="fa fa-angle-left">',
                    next: '<em class="fa fa-angle-right">'
                }
            },
            search: { search: null },
            searching: false,
            displayStart: self.rowIndex,
            paging: false,
            pagingType: 'full_numbers',
            pageLength: environment.paging.defaultPageSize,
            lengthMenu: environment.paging.lengthMenu,
            order: [[0, 'desc']],
            stateSave: true,
            info: false,
            scrollY: (window.innerHeight - 70 - 45) + "px",
            scrollCollapse: true,
            ajax: (dataTablesParameters, callback) => {
                this.updateFilterModel();
                if (self.frm.value.viewOnlySystemWorkflow) {
                    dataTablesParameters.storeId = null;
                }
                else {
                    dataTablesParameters.storeId = self.frm.controls.storeId.value;
                }
                if (dataTablesParameters.storeId == -1) {
                    return;
                }
                // dataTablesParameters.checkInTypeId = self.frm.controls.checkInTypeId.value ? Number(self.frm.controls.checkInTypeId.value) : null;
                dataTablesParameters.status = self.statusOptions.find(x => x.id == self.frm.controls.statusOption.value).value;
                dataTablesParameters.isDeprecated = self.deprecatedOptions.find(x => x.id == self.frm.controls.isDeprecated.value).value;
                self.blockUI.start();
                self.workflowService.query(dataTablesParameters)
                    .subscribe(resp => {
                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: resp.data
                    });
                    self.blockUI.stop();
                }, error => {
                    self.messagingService.ProcessErrorResponse(error);
                    self.blockUI.stop();
                });
            },
            columns: [
                {
                    data: 'id',
                    title: 'id',
                    visible: false,
                },
                {
                    data: 'checkInTypeName',
                    title: 'Check-In Type'
                },
                {
                    data: 'storeName',
                    title: 'Store'
                },
                {
                    data: 'isDefault',
                    title: 'Is Default',
                    width: '10%',
                    render: function (isDefault) {
                        return isDefault
                            ? '<span class="">Yes</span>'
                            : '<span class="">No</span>';
                    }
                },
                {
                    data: 'isDeprecated',
                    title: 'Discontinued',
                    width: '10%',
                    render: function (isDeprecated) {
                        return isDeprecated
                            ? 'Yes'
                            : 'No';
                    }
                },
                {
                    data: 'deprecatedDate',
                    title: 'Discontinued On',
                    width: '15%',
                    render: function (deprecatedDate) {
                        return self.appUtils.getFormattedDateTime(deprecatedDate);
                    }
                },
                {
                    data: 'isActive',
                    title: 'Status',
                    width: '8%',
                    render: function (isActive) {
                        return isActive
                            ? '<span class="badge badge-success">Active</span>'
                            : '<span class="badge badge-danger">Inactive</span>';
                    }
                },
                {
                    data: null,
                    title: 'Action',
                    width: '15%',
                    orderable: false,
                    className: 'text-center',
                    visible: true,
                    render: function (data, type, row) {
                        if (row.isDeprecated) {
                            return `<button title='View Detail' action-type='view'><em class="fa fa-eye" aria-hidden="true"></em></button>`;
                        }
                        else {
                            //Render Activate or Deactivate Button
                            let statusHtml = row.isActive ?
                                `<button title='Deactivate' action-type='toggle-status'><em class="fa fa-ban" aria-hidden="true"></em></button>` :
                                `<button title='Activate' action-type='toggle-status'><em class="fa fa-check" aria-hidden="true"></em></button>`;
                            return `
								<button title='View Detail' action-type='view'><em class="fa fa-eye" aria-hidden="true"></em></button>
								${statusHtml}
								<button title='Edit' action-type='edit'><em class="fa fa-edit" aria-hidden="true"></em></button>
								<button title='Delete' action-type='delete'><em class="fa fa-trash" aria-hidden="true"></em></button>
							`;
                        }
                    }
                }
            ],
            rowCallback: function (row, data, dataIndex) {
                const _row = $(row);
                _row.find('[action-type="toggle-status"]')
                    .off('click')
                    .on('click', function () {
                    self.openStatusChangeConfirmation(data, dataIndex);
                });
                _row.find('[action-type="edit"]')
                    .off('click')
                    .on('click', function () {
                    self.router.navigate(['/workflow', data.id, 'edit']);
                });
                _row.find('[action-type="delete"]')
                    .off('click')
                    .on('click', function () {
                    self.openDeleteConfirmation(data, dataIndex);
                });
                _row.find('[action-type="link-detail"],[action-type="view"]')
                    .off('click')
                    .on('click', function () {
                    self.router.navigate(['/workflow', data.id, 'detail']);
                });
            }
        };
    }
    openDeleteConfirmation(workflow, dataIndex) {
        this.deleteConfirmationModal.openModal(workflow);
    }
    delete(workflow) {
        this.blockUI.start();
        this.workflowService.delete(workflow.id).subscribe(() => {
            setTimeout(() => {
                this.dtInstance.ajax.reload(null, false);
            });
            this.messagingService.success(`Workflow has been deleted successfully.`);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    openStatusChangeConfirmation(workflow, dataIndex) {
        this.statusChangeConfirmationModal.openModal(workflow);
    }
    toggle(workflow) {
        this.blockUI.start();
        this.workflowService.toggleStatus(workflow.id).subscribe(() => {
            setTimeout(() => {
                this.dtInstance.ajax.reload(null, false);
            });
            this.messagingService.success(`Workflow has been ${(workflow.isActive ? 'deactivated' : 'activated')} successfully.`);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    openImportModal() {
        this.importModal.openModal();
    }
    imported(workflowImportModel) {
        this.router.navigate([`/store/${workflowImportModel.storeId}/associate/workflow/${workflowImportModel.workflowId}`]);
    }
}
__decorate([
    BlockUI('container-blockui-grid'),
    __metadata("design:type", Object)
], WorkflowManageComponent.prototype, "blockUI", void 0);
