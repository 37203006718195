var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { WorkflowModel } from 'src/app/models';
import { ApplicationContextService, CategoryService, CheckInTypeService, FilterStateService, MessagingService, StoreService, SubCategoryService, WorkflowService } from 'src/app/services';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
export class WorkflowDetailComponent {
    constructor(router, route, location, messagingService, checkInTypeService, categoryService, workflowService, storeService, subCategoryService, applicationContextService, filterStateService) {
        this.router = router;
        this.route = route;
        this.location = location;
        this.messagingService = messagingService;
        this.checkInTypeService = checkInTypeService;
        this.categoryService = categoryService;
        this.workflowService = workflowService;
        this.storeService = storeService;
        this.subCategoryService = subCategoryService;
        this.applicationContextService = applicationContextService;
        this.filterStateService = filterStateService;
        this.categories = new Array();
        this.checkInTypeSelectList = new Array();
        this.storeSelectList = new Array();
        this.subCategories = new Array();
        filterStateService.changeFilterModelStatues(FilterPageType.workFlowManage, true);
        this.workflowModel = new WorkflowModel();
        this.route.params.subscribe(params => {
            this.workflowModel.id = params.id;
        });
    }
    ngOnInit() {
        this.loadData();
    }
    loadData() {
        this.blockUI.start();
        forkJoin([
            this.categoryService.detailSelectList(),
            this.checkInTypeService.selectList(),
            this.workflowService.get(this.workflowModel.id),
            this.storeService.storeSelectList(),
            this.subCategoryService.getAllSubCategories()
        ]).subscribe((result) => {
            this.categories = result[0];
            this.checkInTypeSelectList = result[1];
            this.workflowModel = result[2];
            this.storeSelectList = result[3];
            this.subCategories = result[4];
            let checkInType = this.checkInTypeSelectList.find(x => x.id == this.workflowModel.checkInTypeId);
            if (checkInType != null) {
                this.checkInTypeName = checkInType.name;
            }
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    openDeleteConfirmation() {
        this.deleteConfirmationModal.openModal(this.workflowModel);
    }
    delete() {
        this.blockUI.start();
        this.workflowService.delete(this.workflowModel.id).subscribe(() => {
            setTimeout(() => {
                this.router.navigate(['/manage/workflow']);
            });
            this.messagingService.success(`Workflow has been deleted successfully.`);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    openStatusChangeConfirmation() {
        this.statusChangeConfirmationModal.openModal(this.workflowModel);
    }
    toggle() {
        this.blockUI.start();
        this.workflowService.toggleStatus(this.workflowModel.id).subscribe(() => {
            this.messagingService.success(`Workflow has been ${(this.workflowModel.isActive ? 'deactivated' : 'activated')} successfully.`);
            this.workflowModel.isActive = !this.workflowModel.isActive;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
}
__decorate([
    BlockUI('container-blockui'),
    __metadata("design:type", Object)
], WorkflowDetailComponent.prototype, "blockUI", void 0);
