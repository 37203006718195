import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { mapTo, tap, catchError, map } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import * as jwt_decode from 'jwt-decode';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AuthenticationService {
    constructor(http) {
        this.http = http;
        this.JWT_TOKEN = 'JWT_TOKEN';
        this.REFRESH_TOKEN = 'REFRESH_TOKEN';
        this.userSubject$ = new BehaviorSubject(null);
        this.user$ = this.userSubject$.asObservable();
    }
    login(authenticateRequestModel) {
        const formData = new FormData();
        formData.append('userName', authenticateRequestModel.userName);
        formData.append('password', authenticateRequestModel.password);
        return this.http.post(`${environment.apiBaseUrl}/auth/login`, formData)
            .pipe(tap((user) => {
            this.doLoginUser(user);
        }), mapTo(true), catchError(error => {
            throw error;
        }));
    }
    logout() {
        const formData = new FormData();
        formData.append('refreshToken', this.getRefreshToken());
        formData.append('jwt', this.getJwtToken());
        return this.http.post(`${environment.apiBaseUrl}/auth/logout`, formData)
            .pipe(tap(() => {
            this.doLogoutUser();
        }), mapTo(true), catchError(error => {
            this.doLogoutUser();
            return of(false);
        }));
    }
    changePassword(model) {
        const formData = new FormData();
        formData.append('currentPassword', model.currentPassword);
        formData.append('newPassword', model.newPassword);
        formData.append('confirmPassword', model.confirmPassword);
        return this.http.post(`${environment.apiBaseUrl}/auth/change-password`, formData);
    }
    recover(model) {
        const formData = new FormData();
        formData.append('email', model.email);
        return this.http.post(`${environment.apiBaseUrl}/auth/recover`, formData);
    }
    reset(model) {
        const formData = new FormData();
        formData.append('key', model.key);
        formData.append('secret', model.secret);
        formData.append('newPassword', model.newPassword);
        formData.append('confirmPassword', model.confirmPassword);
        formData.append('temporaryPassword', model.temporaryPassword);
        return this.http.post(`${environment.apiBaseUrl}/auth/reset`, formData);
    }
    sendActivationMail(applicationUserId) {
        const formData = new FormData();
        formData.append('applicationUserId', applicationUserId);
        return this.http.post(`${environment.apiBaseUrl}/auth/send/activation/mail`, formData);
    }
    activate(model) {
        const formData = new FormData();
        formData.append('key', model.key);
        formData.append('secret', model.secret);
        formData.append('password', model.password);
        formData.append('confirmPassword', model.confirmPassword);
        formData.append('temporaryPassword', model.temporaryPassword);
        return this.http.post(`${environment.apiBaseUrl}/auth/activate`, formData);
    }
    isLoggedIn() {
        return !!this.getJwtToken();
    }
    refreshToken() {
        const formData = new FormData();
        formData.append('refreshToken', this.getRefreshToken());
        formData.append('jwt', this.getJwtToken());
        return this.http.post(`${environment.apiBaseUrl}/auth/refresh`, formData)
            .pipe(tap((user) => {
            this.doLoginUser(user);
        }), mapTo(true), catchError(error => {
            this.doLogoutUser();
            return of(false);
        }));
    }
    isTokenExpired() {
        let token = this.getJwtToken();
        if (!token)
            return true;
        const date = this.getTokenExpirationDate(token);
        if (date === undefined)
            return false;
        return !(date.valueOf() > new Date().valueOf());
    }
    validatePermission(permissions) {
        return this.user$.pipe(map((user) => {
            if (user == null || user.permissions == null || user.permissions.length == 0) {
                return false;
            }
            return user.permissions.some(permission => permissions.includes(permission));
        }));
    }
    // Used in interceptor
    getJwtToken() {
        return localStorage.getItem(this.JWT_TOKEN);
    }
    doLoginUser(authenticateResponseModel) {
        this.storeTokens(authenticateResponseModel);
        this.userSubject$.next(authenticateResponseModel);
    }
    doLogoutUser() {
        //Clear all data
        localStorage.clear();
        this.removeTokens();
        this.userSubject$.next(null);
    }
    getRefreshToken() {
        return localStorage.getItem(this.REFRESH_TOKEN);
    }
    storeTokens(authenticateResponseModel) {
        localStorage.setItem(this.JWT_TOKEN, authenticateResponseModel.jwt);
        localStorage.setItem(this.REFRESH_TOKEN, authenticateResponseModel.refreshToken);
    }
    removeTokens() {
        localStorage.removeItem(this.JWT_TOKEN);
        localStorage.removeItem(this.REFRESH_TOKEN);
    }
    getTokenExpirationDate(token) {
        const decoded = jwt_decode(token);
        if (decoded.exp === undefined) {
            return null;
        }
        ;
        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthenticationService, providedIn: "root" });
