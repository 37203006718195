export enum PermissionType {
    InvoicingFunction = 1,
    WorkflowAdjustment = 2,
    ClientCreation = 3,
    // MechanicalApproval = 4,
    Dashboard = 5,
    DailyEmailQueueAlert = 6,
    VendorAddition = 7,
    VendorPricingAdjustment = 8,
    CqHgGqEntry = 9,
    MechanicalAdvisingDataEntry = 10,
    WorkflowStatusView = 11,
    VehicleInformationAdjustment = 12,
    WholesaleButtonAction = 13,
    NewOPCodeAdditionCreation = 14,
    VehicleInput = 15,
    Master = 16,
    // QuoteApproval = 17,
    // AddedCosmeticsApproval = 18,
    AddedCosmeticsPricingAdjustment = 19,
    ManagingCompanyAdmin = 20,
    StoreAdmin = 21,
    StoreManagement = 22,
    StoreAccounting = 23,
    VendorAdmin = 24,
    VendorAccounting = 25,
    // LandingPage = 26,
    VehicleImport = 27,
    Assessment = 28,
    EditPricingonAssessmentSummaryTab = 29,
    Approval = 30,
    Recon_PkgAdmin = 31,
    // MechAdvisingDataEntry = 32,
    RoEntry = 33,
    PricingAdjustmentOPCodesIncludedInReconPackage = 34,
    PricingAdjustmentOPCodesNotIncludedInReconPackage = 35,
    SoldButtonAction = 36,
    POEntry = 37,
    WorkflowAddition = 38,
    AddedCosmetics = 39,
    Inspection = 40,
    Photo = 41,
    Vendor = 42,
    Mechanical = 43,
    Porter = 44
}
