import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SelectListModel, WorkflowImportModel, WorkflowImportSelectListModel } from 'src/app/models';
import { ApplicationContextService, CheckInTypeService, MessagingService } from 'src/app/services';

@Component({
    selector: 'app-workflow-import',
    templateUrl: './workflow.import.component.html',
    styleUrls: ['./workflow.import.component.css']
})
export class WorkflowImportComponent implements OnChanges, OnDestroy {
    @BlockUI('container-blockui-import') blockUI: NgBlockUI;
    @ViewChild('importModal', { static: false }) importModal: any;

    @Input('stores') stores: Array<SelectListModel>;
    @Output() imported = new EventEmitter<WorkflowImportModel>();

    modalReference: NgbModalRef;
    form: FormGroup;
    submitted: boolean;
    checkInTypeSelectList: Array<WorkflowImportSelectListModel> = new Array<WorkflowImportSelectListModel>();
    storeName: string = null;
    storeSubscription: any;

    constructor(private modalService: NgbModal,
        private formBuilder: FormBuilder,
        private checkInTypeService: CheckInTypeService,
        private messagingService: MessagingService,
        private applicationContextService: ApplicationContextService,
    ) {

    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    public init() {
        this.form = this.formBuilder.group({
            storeId: [null, Validators.required],
            checkInTypeId: [null, Validators.required]
        });
        this.storeName = null;
    }

    public openModal() {
        this.init();

        this.storeSubscription = this.applicationContextService.store$.subscribe((storeSelectListItem: SelectListModel) => {
			if (storeSelectListItem) {
				this.form.controls.storeId.setValue(storeSelectListItem.id);
                this.onStoreChange(storeSelectListItem.id);
			} else {
				this.form.controls.storeId.setValue(null);
			}
		});

        this.modalReference = this.modalService.open(this.importModal, { size: 'lg', centered: true });
    }

    public onStoreChange(storeId: any) {
        if (!storeId) {
            return;
        }

        storeId = Number(storeId);
        this.blockUI.start();
        this.checkInTypeService.getCheckInTypeExcludingExistingWorkflowsforStore(storeId).subscribe((data: Array<WorkflowImportSelectListModel>) => {
            this.checkInTypeSelectList = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    public onSubmit() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }

        let model = new WorkflowImportModel();

        model.checkInTypeId = Number(this.form.controls.checkInTypeId.value);
        model.storeId = Number(this.form.controls.storeId.value);
        model.workflowId = this.checkInTypeSelectList.find(x => x.checkinTypeId == model.checkInTypeId).workflowId;

        setTimeout(() => {
            this.imported.emit(model);
        });
        this.onCancel();

        this.form.reset();
        this.submitted = false;
    }

    public onCancel() {
        if (!this.modalReference) {
            return;
        }

        this.modalReference.close();
    }

    ngOnDestroy(): void {
        this.onCancel();
        this.storeSubscription && this.storeSubscription.unsubscribe();
    }
}
