import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { debounceTime, distinctUntilChanged, pairwise } from "rxjs/operators";
import { Category, PermissionType, VehicleQueueStatus } from "src/app/enums";
import { AuthenticateResponseModel, CompanyWithStoreModel, LandingPageQueueModel, SelectListModel, VehicleQueueCategoryModel, VehicleQueueFilterResponseModel, VehicleQueueModel, VehicleQueueRequestModel, VehicleQueueSignalModel, VehicleQueueStatusModel } from "src/app/models";
import { ApplicationContextService, AuthenticationService, CheckInTypeService, MessagingService, VehicleQueueService, VehicleQueueSignalService } from "src/app/services";

@Component({
    selector: 'app-vehicle-queue',
    templateUrl: './vehicle-queue.component.html',
    styleUrls: ['./vehicle-queue.component.css']
})
export class VehicleQueueComponent implements OnInit, OnDestroy {

    @BlockUI('container-blockui') blockUI: NgBlockUI;
    innerWidth: any;

    openAccordionByIds = {
        "vehicle-filter": true,
        "checkintype-0": true,
        "checkintype-1": true,
        "workflow-stage-status-filter": true
    };

    PermissionType = PermissionType;
    authenticateUser: AuthenticateResponseModel;

    searchForm: FormGroup;
    checkInTypeForm: FormGroup;
    workflowCategoriesForm: FormGroup;
    contextForm: FormGroup;
    workflowStageStatusForm: FormGroup;

    vehicleQueueRequestModel: VehicleQueueRequestModel = new VehicleQueueRequestModel();
    checkInTypes: Array<SelectListModel> = new Array<SelectListModel>();
    vehicleQueueCategories: Array<VehicleQueueCategoryModel> = new Array<VehicleQueueCategoryModel>();

    companySubscription: any;
    storeSubscription: any;

    landingPageQueueModels: Array<LandingPageQueueModel> = new Array<LandingPageQueueModel>();

    constructor(
        private checkInTypeService: CheckInTypeService,
        private formBuilder: FormBuilder,
        private vehicleQueueService: VehicleQueueService,
        private messagingService: MessagingService,
        private authenticationService: AuthenticationService,
        private applicationContextService: ApplicationContextService,
        private vehicleQueueSignalService: VehicleQueueSignalService
    ) {
        this.authenticateUser = null;
    }

    private defineLandingPageQueueModels() {
        let fullVehicleQueueModel = new LandingPageQueueModel();
        fullVehicleQueueModel.name = 'Vehicle Queue';
        fullVehicleQueueModel.isExpaned = true;
        fullVehicleQueueModel.isFullQueue = true;
        fullVehicleQueueModel.sequence = 100;
        fullVehicleQueueModel.permissions.push(PermissionType.WorkflowStatusView);
        this.landingPageQueueModels.push(fullVehicleQueueModel);
        

        let reconPackageQueue = new LandingPageQueueModel();
        reconPackageQueue.name = 'Recon Package';
        reconPackageQueue.sequence = 90;
        reconPackageQueue.categories.push(Category.ReconPackage);
        reconPackageQueue.permissions.push(PermissionType.Recon_PkgAdmin);
        this.landingPageQueueModels.push(reconPackageQueue);

        let assesmentQueue = new LandingPageQueueModel();
        assesmentQueue.name = 'Assessment';
        assesmentQueue.sequence = 10;
        assesmentQueue.categories.push(Category.Assessment);
        assesmentQueue.permissions.push(PermissionType.Assessment);
        this.landingPageQueueModels.push(assesmentQueue);

        let photoQueue = new LandingPageQueueModel();
        photoQueue.name = 'Photo';
        photoQueue.sequence = 15;
        photoQueue.categories.push(Category.PhotoFirst);
        photoQueue.categories.push(Category.PhotoFinal);
        photoQueue.permissions.push(PermissionType.Photo);
        this.landingPageQueueModels.push(photoQueue);

        let quoteQueue = new LandingPageQueueModel();
        quoteQueue.name = 'Quote';
        quoteQueue.sequence = 20;
        quoteQueue.categories.push(Category.Quote);
        quoteQueue.permissions.push(PermissionType.CqHgGqEntry);
        this.landingPageQueueModels.push(quoteQueue);

        let advisorQueue = new LandingPageQueueModel();
        advisorQueue.name = 'Advising';
        advisorQueue.sequence = 30;
        advisorQueue.categories.push(Category.MechanicalAdvising);
        advisorQueue.permissions.push(PermissionType.MechanicalAdvisingDataEntry);
        this.landingPageQueueModels.push(advisorQueue);

        let approvalQueue = new LandingPageQueueModel();
        approvalQueue.name = 'Approval';
        approvalQueue.sequence = 40;
        approvalQueue.categories.push(Category.Approval);
        approvalQueue.permissions.push(PermissionType.Approval);
        this.landingPageQueueModels.push(approvalQueue);

        let dispatchQueue = new LandingPageQueueModel();
        dispatchQueue.name = 'Mechanical';
        dispatchQueue.sequence = 45;
        dispatchQueue.categories.push(Category.Mechanical);
        dispatchQueue.permissions.push(PermissionType.Mechanical);
        this.landingPageQueueModels.push(dispatchQueue);

        let vendorActionQueue = new LandingPageQueueModel();
        vendorActionQueue.name = 'Vendor';
        vendorActionQueue.sequence = 50;
        vendorActionQueue.categories.push(Category.Paint);
        vendorActionQueue.categories.push(Category.Wheel);
        vendorActionQueue.categories.push(Category.DentRemoval);
        vendorActionQueue.categories.push(Category.Interior);
        vendorActionQueue.categories.push(Category.Glass);
        vendorActionQueue.permissions.push(PermissionType.Vendor);
        this.landingPageQueueModels.push(vendorActionQueue);

        let inspectionQueue = new LandingPageQueueModel();
        inspectionQueue.name = 'Inspection';
        inspectionQueue.sequence = 60;
        inspectionQueue.categories.push(Category.FinalInspection);
        inspectionQueue.permissions.push(PermissionType.Inspection);
        this.landingPageQueueModels.push(inspectionQueue);

        let poQueue = new LandingPageQueueModel();
        poQueue.name = 'Purchase Order';
        poQueue.sequence = 65;
        poQueue.categories.push(Category.PurchaseOrder);
        poQueue.permissions.push(PermissionType.POEntry);
        this.landingPageQueueModels.push(poQueue);

        let porterQueue = new LandingPageQueueModel();
        porterQueue.name = 'Porter';
        porterQueue.sequence = 50;

        porterQueue.categories.push(Category.PorterReturnToStore);
        porterQueue.categories.push(Category.PorterToWholesale);
        porterQueue.categories.push(Category.PorterToSales);
        porterQueue.categories.push(Category.PorterToCollision);
        porterQueue.categories.push(Category.PorterToPhoto);
        porterQueue.categories.push(Category.PorterToDetail);
        porterQueue.categories.push(Category.PorterToVendorAction);
        porterQueue.categories.push(Category.PorterToMechanical);
        porterQueue.categories.push(Category.PorterToAssessment);
        porterQueue.categories.push(Category.PorterToChangePlates);

        porterQueue.permissions.push(PermissionType.Porter);
        this.landingPageQueueModels.push(porterQueue);

        this.landingPageQueueModels.sort((a, b) => a.sequence - b.sequence);
    }


    activeIds: Array<string> = new Array<string>();
    isToggleClass: boolean = true;
    hideSidebar() {
        this.isToggleClass = !this.isToggleClass;
    }

    onSideBarAccordionChange(event) {
        this.openAccordionByIds[event.panelId] = event.nextState;
    }

    onQueueAccordionChange(event) {
        let queue = this.landingPageQueueModels.find(x => x.panelId == event.panelId);
        queue.isExpaned = event.nextState;
    }

    ngOnInit() {

        this.innerWidth = window.innerWidth;

        this.authenticationService.user$.subscribe((user) => {
            let isValidUser = user != null && !this.authenticationService.isTokenExpired();
            if (isValidUser) {
                this.authenticateUser = user;
            }
        });

        this.searchForm = this.formBuilder.group({
            searchText: [],
            searchByItems: this.formBuilder.array([])
        });

        this.contextForm = this.formBuilder.group({
            companyId: [null],
            storeId: [null]
        });

        this.checkInTypeForm = this.formBuilder.group({
            allCheckInTypes: [true],
            checkInTypes: this.formBuilder.array([])
        });

        this.workflowCategoriesForm = this.formBuilder.group({
            allCategories: [true],
            vehicleQueueCategories: this.formBuilder.array([])
        });

        this.workflowStageStatusForm = this.formBuilder.group({
            workflowStageStatuses: this.formBuilder.array([])
        });

        this.populateVehicleQueueStages();
        this.createSearchByForm();

        this.searchForm.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
            this.fetchQueueItems();
        });

        this.defineLandingPageQueueModels();

        //Context form when store or company change in the header
        this.contextForm.controls.storeId
            .valueChanges
            .pipe(pairwise(), debounceTime(400), distinctUntilChanged())
            .subscribe(([oldStoreId, newStoreId]: [number, number]) => {
                this.loadCheckInTypes();
                this.vehicleQueueSignalService.associateStore(oldStoreId, newStoreId);
            });

        this.companySubscription = this.applicationContextService.company$.subscribe((company: CompanyWithStoreModel) => {
            if (company == null) {
                this.contextForm.controls.companyId.setValue(null);
            } else {
                this.contextForm.controls.companyId.setValue(company.id);
            }
        });

        this.storeSubscription = this.applicationContextService.store$.subscribe((storeSelectListItem: SelectListModel) => {
            if (storeSelectListItem) {
                this.contextForm.controls.storeId.setValue(storeSelectListItem.id);
            } else {
                this.contextForm.controls.storeId.setValue(null);
            }
            this.contextForm.controls.storeId.updateValueAndValidity({
                onlySelf: true,
                emitEvent: true
            });
        });

        this.vehicleQueueSignalService.vehicleQueueUpdated.subscribe((model: VehicleQueueSignalModel) => {
            this.fetchQueueItems();
            this.updateCategoriesWithVehichleCount();
        });
    }

    ngOnDestroy(): void {
        this.companySubscription.unsubscribe();
        this.storeSubscription.unsubscribe();
    }

    private createSearchByForm() {
        let fa = (this.searchForm.controls.searchByItems as FormArray);
        fa.push(this.formBuilder.group({
            key: ['searchByStockNumber'],
            isSelected: [false],
            label: ['Stock #']
        }));
        fa.push(this.formBuilder.group({
            key: ['searchByVIN'],
            isSelected: [false],
            label: ['VIN']
        }));
        fa.push(this.formBuilder.group({
            key: ['searchByRONumber'],
            isSelected: [false],
            label: ['RO #']
        }));
    }

    private loadCheckInTypes() {
        if (!this.contextForm.value.companyId || !this.contextForm.value.storeId) {
            return;
        }
        this.checkInTypeService.getFilteredCheckInTypes(this.contextForm.value.companyId, this.contextForm.value.storeId).subscribe(checkInTypes => {
            this.populateCheckInTypesFormGroup(checkInTypes);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        });
    }

    private populateCheckInTypesFormGroup(checkInTypes: Array<SelectListModel>) {
        let fa = this.checkInTypeForm.controls.checkInTypes as FormArray;
        fa.clear();
        checkInTypes.forEach((checkInType: SelectListModel) => {
            let fb = this.formBuilder.group({
                isSelected: [false],
                name: [checkInType.name],
                id: [checkInType.id]
            });
            fa.push(fb);
        });
        this.loadCategoriesWithVehichleCount();
    }

    private loadCategoriesWithVehichleCount() {
        if (!this.contextForm.value.companyId || !this.contextForm.value.storeId) {
            return;
        }

        this.populateRequestModel();

        this.vehicleQueueService.getVehicleQueueFilterCount(this.vehicleQueueRequestModel).subscribe((vehicleQueueFilterResponseModel: VehicleQueueFilterResponseModel) => {
            this.vehicleQueueCategories = vehicleQueueFilterResponseModel.vehicleQueueCategories;
            this.fetchQueueItems();
            this.populateWorkflowCategoriesFormGroup(vehicleQueueFilterResponseModel.vehicleQueueCategories);
            this.updateVehicleQueueStages(vehicleQueueFilterResponseModel.vehicleQueueStatuses);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        });
    }

    private populateWorkflowCategoriesFormGroup(vehicleQueueCategoryModels: Array<VehicleQueueCategoryModel>) {
        let fa = this.workflowCategoriesForm.controls.vehicleQueueCategories as FormArray;
        fa.clear();
        vehicleQueueCategoryModels.forEach((vehicleQueueCategoryModel: VehicleQueueCategoryModel) => {
            let fb = this.formBuilder.group({
                isSelected: [false],
                name: [vehicleQueueCategoryModel.categoryName],
                id: [vehicleQueueCategoryModel.categoryId],
                count: [vehicleQueueCategoryModel.vehicleCount]
            });
            fa.push(fb);
        });
    }

    private updateWorkflowCategoriesFormGroup(vehicleQueueCategoryModels: Array<VehicleQueueCategoryModel>) {
        let fa = this.workflowCategoriesForm.controls.vehicleQueueCategories as FormArray;
        fa.controls.forEach((fg: FormGroup) => {
            let model = vehicleQueueCategoryModels.find(x => x.categoryId == fg.value.id);
            if (model != null) {
                fg.controls.count.setValue(model.vehicleCount);
            } else {
                fg.controls.count.setValue(0);
            }
        });
    }

    private populateVehicleQueueStages() {
        let fa = this.workflowStageStatusForm.controls.workflowStageStatuses as FormArray;
        fa.push(this.formBuilder.group({
            isSelected: [false],
            name: "Not Started",
            count: 0,
            id: VehicleQueueStatus.NotStarted
        }));
        fa.push(this.formBuilder.group({
            isSelected: [false],
            name: "In Progress",
            count: 0,
            id: VehicleQueueStatus.InProgress
        }));
        fa.push(this.formBuilder.group({
            isSelected: [false],
            name: "Waiting",
            count: 0,
            id: VehicleQueueStatus.Waiting
        }));
        fa.push(this.formBuilder.group({
            isSelected: [false],
            name: "Completed",
            count: 0,
            id: VehicleQueueStatus.Completed
        }));
        fa.push(this.formBuilder.group({
            isSelected: [false],
            name: "Hold",
            count: 0,
            id: VehicleQueueStatus.Hold
        }));
    }

    private updateVehicleQueueStages(vehicleQueueStatuses: Array<VehicleQueueStatusModel>) {
        let fs = this.workflowStageStatusForm.controls.workflowStageStatuses as FormArray;
        fs.controls.forEach((fg: FormGroup) => {
            let model = vehicleQueueStatuses.find(x => x.status == fg.value.id);
            if (model != null) {
                fg.controls.count.setValue(model.vehicleCount);
            } else {
                fg.controls.count.setValue(0);
            }
        });
    }

    private updateCategoriesWithVehichleCount() {
        if (!this.contextForm.value.companyId || !this.contextForm.value.storeId) {
            return;
        }

        this.populateRequestModel();

        this.vehicleQueueService.getVehicleQueueFilterCount(this.vehicleQueueRequestModel).subscribe((vehicleQueueFilterResponseModel: VehicleQueueFilterResponseModel) => {
            this.updateVehicleQueueStages(vehicleQueueFilterResponseModel.vehicleQueueStatuses);
            this.updateWorkflowCategoriesFormGroup(vehicleQueueFilterResponseModel.vehicleQueueCategories);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        });
    }

    public updateCategoriesWithVehichleCountAndQueue() {
        this.updateCategoriesWithVehichleCount();
        this.fetchQueueItems();
    }

    private fetchQueueItems() {

        if (!this.contextForm.value.companyId || !this.contextForm.value.storeId) {
            return;
        }

        this.populateRequestModel();

        this.blockUI.start();
        this.vehicleQueueService.fetchQueueItems(this.vehicleQueueRequestModel).subscribe((vehicleQueueItems) => {

            let isCategoryFilterApplied = this.vehicleQueueRequestModel.categoryIds.length > 0;
            this.landingPageQueueModels.forEach(queue => {
                queue.vehicleQueueItems = new Array<VehicleQueueModel>();
                queue.isVisible = false;
                if (queue.isFullQueue && !isCategoryFilterApplied && this.authenticateUser.permissions.includes(PermissionType.WorkflowStatusView)) {
                    queue.vehicleQueueItems = [...vehicleQueueItems];
                    queue.isVisible = queue.vehicleQueueItems.length > 0;
                    return;
                }

                //Render Selected Queues only
                if (this.vehicleQueueRequestModel.categoryIds.length > 0) {
                    let categoryNames = this.workflowCategoriesForm.value.vehicleQueueCategories.filter(x => x.isSelected).map(x => x.name);
                    if (!queue.categories.some(x => categoryNames.includes(x))) {
                        return;
                    }
                }

                if (queue.permissions.some(permission => this.authenticateUser.permissions.includes(permission))) {
                    let items = vehicleQueueItems.filter(vehicleQueueItem => {
                        return vehicleQueueItem.vehicleQueueStages.some(s => queue.categories.includes(s.categoryName)
                            && (s.status === VehicleQueueStatus.InProgress || s.status === VehicleQueueStatus.Waiting));
                    });

                    //Push Unique Vehicle in Vendor Action Queue
                    items.forEach(item => {
                        if (queue.vehicleQueueItems.find(x => x.id == item.id) == null) {
                            queue.vehicleQueueItems.push(item);
                        }
                    });
                }
                queue.isVisible = queue.vehicleQueueItems.length > 0;
            });

            //Calculate Active Ids
            this.activeIds = this.landingPageQueueModels.filter(x => {
                return x.isVisible  && (x.isFullQueue || isCategoryFilterApplied);
            }).map((e) => e.panelId);
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }

    private populateRequestModel() {
        if (this.workflowStageStatusForm.value.workflowStageStatuses.length) {
            this.vehicleQueueRequestModel.queueStatuses = this.workflowStageStatusForm.value.workflowStageStatuses.filter(x => x.isSelected).map(x => x.id);
        }

        this.vehicleQueueRequestModel.searchText = this.searchForm.value.searchText;
        this.searchForm.value.searchByItems.forEach(searchByItem => {
            this.vehicleQueueRequestModel[searchByItem.key] = searchByItem.isSelected;
        });

        this.vehicleQueueRequestModel.companyId = this.contextForm.value.companyId;
        this.vehicleQueueRequestModel.storeId = this.contextForm.value.storeId;

        if (this.checkInTypeForm.value.checkInTypes.length) {
            this.vehicleQueueRequestModel.checkInTypes = this.checkInTypeForm.value.checkInTypes.filter(x => x.isSelected).map(x => x.id);
        }

        if (this.workflowCategoriesForm.value.vehicleQueueCategories.length) {
            this.vehicleQueueRequestModel.categoryIds = this.workflowCategoriesForm.value.vehicleQueueCategories.filter(x => x.isSelected).map(x => x.id);
        }
    }
}
