import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { KpiFilterModel } from '../models';

@Injectable({
    providedIn: 'root'
})
export class KpiBannerService {

    constructor(private http: HttpClient) {
    }
    // turn time
    getTurnTimeForCurrentMonth(filterModel: KpiFilterModel): Observable<any> {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/current-month-turn-time${filterModel.queryParams()}`);
    }

    getTurnTimeForLastOneMonth(filterModel: KpiFilterModel): Observable<any> {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/last-one-month-turn-time${filterModel.queryParams()}`);
    }

    getTurnTimeForLastTwoMonth(filterModel: KpiFilterModel): Observable<any> {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/last-two-month-turn-time${filterModel.queryParams()}`);
    }

    // Shop time

    getShopTimeForCurrentMonth(filterModel: KpiFilterModel): Observable<any> {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/current-month-shop-time${filterModel.queryParams()}`);
    }

    getShopTimeForLastOneMonth(filterModel: KpiFilterModel): Observable<any> {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/last-one-month-shop-time${filterModel.queryParams()}`);
    }

    getShopTimeForLastTwoMonth(filterModel: KpiFilterModel): Observable<any> {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/last-two-month-shop-time${filterModel.queryParams()}`);
    }

    // Vendor Time

    getVendorTimeForCurrentMonth(filterModel: KpiFilterModel): Observable<any> {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/current-month-vendor-time${filterModel.queryParams()}`);
    }

    getVendorTimeForLastOneMonth(filterModel: KpiFilterModel): Observable<any> {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/last-one-month-vendor-time${filterModel.queryParams()}`);
    }

    getVendorTimeForLastTwoMonth(filterModel: KpiFilterModel): Observable<any> {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/last-two-month-vendor-time${filterModel.queryParams()}`);
    }

    // QuoteSaving

    getQuoteSavingForCurrentMonth(filterModel: KpiFilterModel): Observable<any> {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/current-month-quote-saving${filterModel.queryParams()}`);
    }

    getQuoteSavingForLastOneMonth(filterModel: KpiFilterModel): Observable<any> {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/last-one-month-quote-saving${filterModel.queryParams()}`);
    }

    getQuoteSavingForLastTwoMonth(filterModel: KpiFilterModel): Observable<any> {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/last-two-month-quote-saving${filterModel.queryParams()}`);
    }
}
