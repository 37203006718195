

import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { SubCategoryDetailModel, VendorDetailModel } from "src/app/models";
import { MessagingService, SubCategoryService } from "src/app/services";


@Component({
    selector: 'app-delete-confirmation-sub-category',
    templateUrl: './delete-confirmation.sub-category.component.html',
    styleUrls: ['./delete-confirmation.sub-category.component.css']
})

export class SubCategoryDeleteConfirmationComponent implements OnDestroy {
    @BlockUI('container-blockui-confirmation') blockUI: NgBlockUI;
    @ViewChild('confirmationModal', { static: false }) confirmationModal: any;
    @Output() delete = new EventEmitter<any>();

    modalReference: NgbModalRef;
    model: SubCategoryDetailModel;
    form: FormGroup;
    submitted: boolean;

    constructor(private modalService: NgbModal,
        private formBuilder: FormBuilder) {
    }

    openModal(subCategoryModel: SubCategoryDetailModel) {
        this.model = subCategoryModel;
        this.form = this.formBuilder.group({
        });

        this.modalReference = this.modalService.open(this.confirmationModal, { centered: true });
    }

    onSubmit() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        this.form.reset();
        this.submitted = false;

        setTimeout(() => {
            this.delete.emit(this.model);
        });
        this.onCancel();
    }

    onCancel() {
        if(!this.modalReference){
            return;
        }
        this.modalReference.close();
    }
    
    ngOnDestroy(): void {
        this.onCancel();
    }
}