<section class="vehicleDtlSec">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6">
        <h2>Recon Package</h2>
      </div>
      <div class="col-12 col-md-6">
        <app-queue-timer [vehicleQueueStageModel]="vehicleQueueStageModel"></app-queue-timer>
      </div>
    </div>
    <form [formGroup]="frmReconPkg" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-12">
          <div class="bluetableWrp">
            <app-vehicle-info [vehicleQueueModel]="vehicleQueueModel" [showFullVin]="false"></app-vehicle-info>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="addMore">
            <a href="javascript:void(0)" (click)="onAddOpCode()">
              <em class="fa fa-plus-circle fa-custom" aria-hidden="true"></em>
            </a>
          </div>
        </div>
        <div class="col-12">
          <div class="tableWrp table-responsive">
            <div class="bluetableWrp">
              <table class="table dataTable table-striped" summary="Recon Package list">
                <thead>
                  <tr>
                    <th scope="col" style="min-width: 150px;">Operation Code</th>
                    <th scope="col" style="min-width: 90px;">Category</th>
                    <th scope="col" style="min-width: 90px;">Tool</th>
                    <th scope="col" style="min-width: 90px;">Panel</th>
                    <th scope="col">Location</th>
                    <th scope="col" style="min-width: 70px;">Side</th>
                    <th scope="col" style="min-width: 90px;">Cost ($)</th>
                    <th scope="col" style="min-width: 90px;">Parts ($)</th>
                    <th scope="col" style="min-width: 90px;">Labor ($)</th>
                    <th scope="col" style="min-width: 90px;">Total ($)</th>
                    <th scope="col" style="min-width: 75px;">Added Date</th>
                    <!-- <th scope="col" style="min-width: 90px;">Worker Name</th>
                    <th scope="col" style="min-width: 115px;">Queue Entry Date</th> -->
                    <th scope="col" style="min-width: 65px;">Status</th>
                    <!-- <th scope="col" style="min-width: 105px;">Completed Date</th>
                    <th scope="col" style="min-width: 90px;">Time</th> -->
                    <th scope="col" style="min-width: 200px;">Comment</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody formArrayName="opCodes">
                  <tr *ngFor="let opCode of getFormArrayControls(); let i = index;" [formGroupName]="i">
                    <td>
                      <div class="form-group">
                        <select class="form-control" formControlName="operationCodeId"
                          [ngClass]="{ 'is-invalid': submitted &&  opCode.controls.operationCodeId.errors }"
                          (change)="onOperationCodeChange(opCode.controls.operationCodeId.value, i)">
                          <option value="">Select Operation Code</option>
                          <option *ngFor="let item of viewModels[i].operationCodes" [value]="item.operationCodeDetailModel.id">
                            {{item.operationCodeDetailModel.name}}
                          </option>
                        </select>
                        <div *ngIf="submitted &&  opCode.controls.operationCodeId.errors" class="invalid-feedback">
                          Operation code is required
                        </div>
                      </div>
                    </td>
                    
                    <td [innerHtml]="opCode.controls.categoryName.value"></td>
                    <td [innerHtml]="opCode.controls.subCategoryName.value"></td>
                    <td [innerHtml]="opCode.controls.panelName.value"></td>
                    <td [innerHtml]="opCode.controls.locationName.value"></td>
                    <td [innerHtml]="opCode.controls.sideName.value"></td>
                    <td>
                      <div class="form-group">
                        <input class="form-control" formControlName="cost"  mask="separator.2" thousandSeparator=","
                          [ngClass]="{ 'is-invalid': submitted &&  opCode.controls.cost.errors }" />
                        <div *ngIf="submitted &&  opCode.controls.cost.errors" class="invalid-feedback">
                          <div *ngIf="opCode.controls.cost.errors.required">
                            Cost is required</div>
                          <div *ngIf="opCode.controls.cost.errors.pattern">
                            Cost should be in numeric
                          </div>
                          <div *ngIf="opCode.controls.cost.errors.min">
                            Cost should be greater or equal with 0
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <input type="number" class="form-control" formControlName="part"  mask="separator.2" thousandSeparator=","
                          [ngClass]="{ 'is-invalid': submitted &&  opCode.controls.part.errors }" />
                        <div *ngIf="submitted &&  opCode.controls.part.errors" class="invalid-feedback">
                          <div *ngIf="opCode.controls.part.errors.required">
                            Part is required
                          </div>
                          <div *ngIf="opCode.controls.part.errors.pattern">
                            Part should be in numeric
                          </div>
                          <div *ngIf="opCode.controls.part.errors.min">
                            Part should be greater or equal with 0
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <input type="number" class="form-control" formControlName="labor"  mask="separator.2" thousandSeparator=","
                          [ngClass]="{ 'is-invalid': submitted &&  opCode.controls.labor.errors }" />
                        <div *ngIf="submitted &&  opCode.controls.labor.errors" class="invalid-feedback">
                          <div *ngIf="opCode.controls.labor.errors.required">
                            Labor is required
                          </div>
                          <div *ngIf="opCode.controls.labor.errors.pattern">
                            Labor should be in numeric
                          </div>
                          <div *ngIf="opCode.controls.labor.errors.min">
                            Labor should be greater or equal with 0
                          </div>
                        </div>
                      </div>
                    </td>
                    <td [innerHtml]="opCode.controls.total.value"></td>
                    <td [innerHtml]="opCode.controls.addedDate.value | date:'MM/dd/yyyy'"></td>
                    <!-- <td [innerHtml]="opCode.controls.workerName.value"></td>
                    <td [innerHtml]="opCode.controls.queueEntryDate.value | date:'MM/dd/yyyy'"></td> -->
                    <td [innerHtml]="opCode.controls.queueStatus.value"></td>
                    <!-- <td [innerHtml]="opCode.controls.queueCompletedDate.value | date:'MM/dd/yyyy'"></td>
                    <td [innerHtml]="opCode.controls.queueTime.value"></td> -->
                    <td>
                      <div class="form-group">
                        <textarea cols="1" rows="1" class="form-control" formControlName="comment"
                          [ngClass]="{ 'is-invalid': submitted &&  opCode.controls.comment.errors }"
                          (blur)="onBlurOnComment($event)">
                        </textarea>
                        <div *ngIf="submitted &&  opCode.controls.comment.errors" class="invalid-feedback">
                          <div *ngIf="opCode.controls.comment.errors.maxLength">
                            Comment should be
                            {{opCode.controls.comment.maxlength.requiredLength}}
                            characters
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="text-center nowrap">
                      <button type="button" title='Delete' action-type='delete'>
                        <em class="fa fa-minus" aria-hidden="true" (click)="onRemoveOpCode(i)"></em>
                      </button>
                      <button type="button" title='Edit' action-type='edit' class="ml-1">
                        <em class="fa fa-edit" aria-hidden="true" (click)="onEditOpCode(i)"></em>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="constTable table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Total Cost</th>
                  <th scope="col">Cost</th>
                  <th scope="col">Net</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Internal</td>
                  <td>${{internalTotalCost | currency : 'USD' : '' : '1.2-2' }}</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Reconditioning</td>
                  <td>${{reconditioningTotalCost| currency : 'USD' : '' : '1.2-2' }}</td>
                  <td>${{reconditioningCost| currency : 'USD' : '' : '1.2-2' }}</td>
                  <td>${{reconditioningNet| currency : 'USD' : '' : '1.2-2' }}</td>
                </tr>
                <tr>
                  <td>Added Cosmetics</td>
                  <td>${{addedCosmeticTotalCost| currency : 'USD' : '' : '1.2-2' }}</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Quoted Work</td>
                  <td>${{quotedTotalCost| currency : 'USD' : '' : '1.2-2' }}</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>Total Cost Store</td>
                  <td>: ${{totalStoreCost| currency : 'USD' : '' : '1.2-2' }}</td>
                </tr>
                <tr>
                  <td>Net</td>
                  <td>: ${{netCost| currency : 'USD' : '' : '1.2-2' }}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="approveBtnWrp">
            <button class="btn btn-red">Approve</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
