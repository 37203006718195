import { Component } from '@angular/core';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css']
})
export class InvoiceListComponent {
  prinImg: string = 'assets/images/print.png';
  downloadImg: string = 'assets/images/download2.png';
  calenderImg: string = 'assets/images/calender-icon.png';
  toDate: any;
  fromDate: any;
  validateInput: any;

  onDateSelection($event:any){}
}
