import { CompanyContactModel, CompanyDocumentModel } from "..";

export class CompanyDetailModel {
    public id: number;
    public name: string;
    public streetAddress: string;
    public blockAddress: string;
    public city: string;
    public state: string;
    public country: string;
    public email: string;
    public zipCode:string;
    public phoneCode:string;
    public phoneNumber: string;
    public accountManagerId: string;
    public accountManagerName: string;
    public quickBookClientId: string;
    public quickBookClientSecret: string;
    public quickBookAppEnvironment: string;
    
    public taxId: string;
    public contacts: Array<CompanyContactModel>;
    public documents: Array<CompanyDocumentModel>;

    public isActive: boolean;

    constructor() {
        this.contacts = new Array<CompanyContactModel>();
        this.documents = new Array<CompanyDocumentModel>();
    }
}