<section class="compnyDtlPSec" *blockUI="'container-blockui-company'">
     <div class="container">
          <div class="row">
               <div class="col-12">
                    <a [routerLink]="['/manage/company']" class="backtolist"><em class="fa fa-long-arrow-left"
                              aria-hidden="true"></em> Back to list</a>
               </div>
          </div>
          <div class="row">
               <div class="col-12 col-md-12 col-lg-8 col-xl-8 offset-xl-2 offset-lg-2">
                    <div class="compnydtlwrp" *ngIf="model">
                         <div class="compnydtlH">
                              <div class="row">
                                   <div class="col-8 col-md-8">
                                        <h2>Managing Company Detail</h2>
                                   </div>
                                   <div class="col-4 col-md-4">
                                        <div class="form-group">
                                             <div ngbDropdown class="actionWrp">
                                                  <button class="btn btn-red" id="dropdownBasic1"
                                                       ngbDropdownToggle>Actions</button>
                                                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                       <ul>
                                                            <li>
                                                                 <a ngbDropdownItem href="javascript:void(0);"
                                                                      (click)="goToEdit()">
                                                                      <em class="fa fa-edit" aria-hidden="true"></em>
                                                                      Edit
                                                                 </a>
                                                            </li>
                                                            <li>
                                                                 <a ngbDropdownItem href="javascript:void(0);"
                                                                      (click)="openStatusChangeConfirmation()">
                                                                      <em class="fa fa-times-circle-o"
                                                                           aria-hidden="true"></em>
                                                                      {{model.isActive ? "Deactivate" : "Activate" }}
                                                                 </a>
                                                            </li>
                                                            <li><a ngbDropdownItem href="javascript:void(0);"
                                                                      (click)="openDeleteConfirmation()">
                                                                      <em class="fa fa-trash-o" aria-hidden="true"></em>
                                                                      Delete</a>
                                                            </li>
                                                       </ul>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>

                         <h4>General Information</h4>
                         <div class="row">
                              <div class="col-12">
                                   <ul class="cmpnyDtlList">
                                        <!-- <li><span>Id</span> <span>:</span> <span>{{model.id}}</span></li> -->
                                        <li><span>Managing Company Name</span> <span>:</span> <span>{{model.name}}</span></li>
                                        <li><span>Street line 1</span> <span>:</span>
                                             <span>{{model.streetAddress}}</span>
                                        </li>
                                        <li><span>Street line 2</span> <span>:</span>
                                             <span>{{model.blockAddress}}</span>
                                        </li>
                                        <li><span>City</span> <span>:</span> <span>{{model.city}}</span></li>
                                        <li><span>State</span> <span>:</span> <span>{{model.state}}</span></li>
                                        <li><span>Country</span> <span>:</span> <span>{{model.country}}</span></li>
                                        <li><span>ZIP Code</span> <span>:</span> <span>{{model.zipCode}}</span></li>
                                        <li><span>Email Address</span> <span>:</span> <span>{{model.email}}</span></li>
                                        <li><span>Phone Number</span> <span>:</span> <span>{{model.phoneCode}} {{model.phoneNumber}}</span>
                                        </li>
                                        <li><span>Account Manager</span> <span>:</span> <span>{{model.accountManagerName}}</span></li>
                                        <li><span>Tax ID</span> <span>:</span> <span>{{model.taxId}}</span></li>
                                        <li><span>Contact Name</span> <span>:</span> <span>{{contacts}}</span></li>

                                        <li *ngIf="model.documents.length">
                                             <span>Attachments</span>
                                             <span>:</span>
                                             <span>
                                                  <div *ngFor="let item of model.documents" class="attchDownlod">
                                                       <a [href]="item.fileUrl" title="{{item.originalFileName}}"
                                                            download="{{item.originalFileName}}" target="_blank">
                                                            <!-- <em class="fa fa-picture-o" aria-hidden="true"></em> -->
                                                            <span class="attachmentUrl"
                                                                 [textContent]="item.originalFileName"></span>
                                                            <span class="downloadFile"><em class="fa fa-download"
                                                                      aria-hidden="true"></em></span>
                                                       </a>
                                                  </div>
                                             </span>
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <br>
                         <div *ngIf="model.quickBookClientId">
                              <h4>QuickBooks online integration keys</h4>
                              <div class="row">
                                   <div class="col-12">
                                        <ul class="cmpnyDtlList">
                                             <li><span>QuickBooks Client Id</span> <span>:</span>
                                                  <span>{{model.quickBookClientId}}</span>
                                             </li>
                                             <li><span>QuickBooks Client Secret</span> <span>:</span>
                                                  <span>{{model.quickBookClientSecret}}</span>
                                             </li>
                                             <li><span>QuickBooks Application Environment</span> <span>:</span>
                                                  <span>{{model.quickBookAppEnvironment}}</span>
                                             </li>
                                        </ul>
                                   </div>

                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</section>

<app-company-delete-confirmation #deleteConfirmationModal (delete)="delete($event)"> </app-company-delete-confirmation>
<app-company-status-change-confirmation #statusChangeConfirmationModal (toggle)="toggle($event)">
</app-company-status-change-confirmation>