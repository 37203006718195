import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { CategoryDetailModel, SubCategorySelectListModel, WorkflowStageCategoryModel, WorkflowStageModel } from 'src/app/models';

@Component({
	selector: 'app-workflow-stage-detail-widget',
	templateUrl: './workflow.stage.detail.widget.component.html',
	styleUrls: ['./workflow.stage.detail.widget.component.css']
})
export class WorkflowStageDetailWidgetComponent implements OnChanges {

	@ViewChild('detailModal', { static: false }) detailModal: any;

	@Input('categories') categories: Array<CategoryDetailModel>;
	@Input('subCategories') subCategories: Array<SubCategorySelectListModel>;
	@Input('workflowStageModel') workflowStageModel: WorkflowStageModel;

	ngOnChanges(changes: SimpleChanges): void {
		this.processModelChange();
	}

	openWorkflowStepDetailModal() {
		this.detailModal.openModal(this.workflowStageModel);
	}

	private processModelChange() {
		this.workflowStageModel.workflowStageCategories.forEach((elem: WorkflowStageCategoryModel) => {
			elem.categoryName = this.categories.find(x => x.id === elem.categoryId).name;
			if (elem.subCategoryId != null) {
				elem.subCategoryName = this.subCategories.find(x => x.id === elem.subCategoryId).name;
			}
		});
	}

	get isAllCategoriesDisabled() {
		return !this.workflowStageModel.workflowStageCategories.every(x => x.isActive);
	}

	get hasMandatoryOPCodes() {
		return this.workflowStageModel.workflowStageCategories.some(x => x.operationCodes.length > 0);
	}
}
