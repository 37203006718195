<ng-template #selectVehicle let-modal>
    <div class="modal-body commentPopupWrp">
        <div class="vehicleAssessed">
            <h2>Select the type of vehicle to be Assessed.</h2>
            <h4>Select the options the best describe your vehicle.</h4>
            <form class="vehicleAssessedForm" [formGroup]="frmVehicleType" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-12 col-sm-4">
                        <div class=" vehicle">
                            <img [src]="car" alt="Car" />
                            <h5>Car</h5>
                            <span>
                                <input type="radio" [value]="VehicleType.Car" class="form-control"
                                    formControlName="vehicleType" id="car" />
                                <label for="car"><span>Car</span></label>
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4">
                        <div class="vehicle">
                            <img [src]="suv" alt="SUV" />
                            <h5>SUV</h5>
                            <span>
                                <input type="radio" [value]="VehicleType.Suv" class="form-control"
                                    formControlName="vehicleType" id="suv" />
                                <label for="suv"><span>SUV</span></label>
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4">
                        <div class="vehicle">
                            <img [src]="truck" alt="Truck"/>
                            <h5>Truck</h5>
                            <span>
                                <input type="radio" [value]="VehicleType.Truck" class="form-control"
                                    formControlName="vehicleType" id="truck" />
                                <label for="truck"><span>Truck</span></label>
                            </span>
                        </div>
                    </div>
                    <div class="col-12 mt-4">
                        <button class="btn btn-red m-r-5" type="submit">Save</button>
                        <button class="btn btn-tsrp" type="button" (click)="closeModal()">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>