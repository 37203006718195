import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CategoryDetailModel, OperationCodeSelectListModel, SubCategorySelectListModel, WorkflowStageAddCategoriesViewModel, WorkflowStageCategoryModel, WorkflowStageCategoryOperationCodeModel, WorkflowStageModel } from 'src/app/models';
import { MessagingService, OperationCodeService } from 'src/app/services';

@Component({
    selector: 'app-detail-workflow-stage',
    templateUrl: './detail-workflow-stage.component.html',
    styleUrls: ['./detail-workflow-stage.component.css']
})
export class WorkflowDetailStageComponent implements OnChanges {
    @BlockUI('container-blockui-work-step') blockUI: NgBlockUI;
    @ViewChild('stepDetail', { static: false }) addNewTask: any;

    @Input('categories') categories: Array<CategoryDetailModel>;
    @Input('subCategories') subCategories: Array<SubCategorySelectListModel>;
    modalReference: NgbModalRef;
    model: WorkflowStageModel;
    viewModels: Array<WorkflowStageAddCategoriesViewModel>;


    constructor(private modalService: NgbModal,
        private messagingService: MessagingService,
        private operationCodeService: OperationCodeService
    ) {

    }

    ngOnChanges(changes: SimpleChanges): void {
    }


    public init(model: WorkflowStageModel) {
        this.model = model;
        this.viewModels = new Array<WorkflowStageAddCategoriesViewModel>();

        //Populate categories
        if (this.model.workflowStageCategories.length > 0) {

            this.model.workflowStageCategories.forEach((x: WorkflowStageCategoryModel, i: number) => {
                //Populate View MOdel
                let vm = new WorkflowStageAddCategoriesViewModel();
                vm.categoryId = x.categoryId;
                vm.categoryName = this.categories.find(c => c.id === x.categoryId).name;
                vm.subCategoryId = x.subCategoryId;
                if (vm.subCategoryId != null) {
                    vm.subCategoryName = this.subCategories.find(x => x.id === vm.subCategoryId).name;
                }
                vm.selectedOperationCodeIds = x.operationCodes.map((o: WorkflowStageCategoryOperationCodeModel) => {
                    return o.operationCodeId;
                });
                this.viewModels.push(vm);
                this.onCategoryChange(x.categoryId, i);
            });
        }
    }

    public openModal(model: WorkflowStageModel) {
        // Clone so that if click on cancelled underlying
        this.init(model);
        this.modalReference = this.modalService.open(this.addNewTask, { size: 'lg', centered: true });
    }

    onClose() {
        this.modalReference.close();
    }

    onCategoryChange(categoryId: any, index: number) {
        if (!categoryId) {
            return;
        }

        categoryId = Number(categoryId);
        this.blockUI.start();
        this.operationCodeService.selectListByCategoryId(categoryId).subscribe((data: Array<OperationCodeSelectListModel>) => {
            let vm = this.viewModels[index];
            vm.selectedOperationCodeSelectList = data.filter(x => vm.selectedOperationCodeIds.includes(x.id)) || new Array<OperationCodeSelectListModel>();
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
}
