<section class="advisorSec">
  <div class="container-fluid">
    <div class="main-vehiclSec">
      <form [formGroup]="frmMechAdvising" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-12 col-md-12 col-lg-6">
            <h2>Mechanical Advising</h2>
          </div>
          <div class="col-12 col-md-12 col-lg-6">
            <app-queue-timer [vehicleQueueStageModel]="vehicleQueueStageModel"></app-queue-timer>
          </div>
        </div>
        <div class="dispatch-dtl-FormWrp">
          <div class="tableWrp table-responsive">
            <table class="table dataTable table-striped" summary="Mechanical Advising">
              <thead>
                <tr>
                  <th scope="col">Year</th>
                  <th scope="col">Make</th>
                  <th scope="col">Model</th>
                  <th scope="col">Color</th>
                  <th scope="col">Stock#</th>
                  <th scope="col">VIN</th>
                  <th scope="col">RO#</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="col">{{vehicleQueueModel.year}}</td>
                  <td scope="col">{{vehicleQueueModel.make}}</td>
                  <td scope="col">{{vehicleQueueModel.model}}</td>
                  <td scope="col">{{vehicleQueueModel.basicColor}}</td>
                  <td scope="col">{{vehicleQueueModel.stockNumber}}</td>
                  <td scope="col">{{vehicleQueueModel.vehicleIdentificationNumber | slice:-8}}</td>
                  <td scope="col">
                    <input type="text" class="form-control" placeholder="" formControlName="roNumber"
                      [ngClass]="{ 'is-invalid': submitted && frmMechAdvising.controls.roNumber.errors }" />
                    <div *ngIf="submitted && frmMechAdvising.controls.roNumber.errors"
                      class="invalid-feedback text-left">
                      RO Number is required
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="addMore">
            <a href="javascript:void(0)" (click)="onAddOpCode()">
              <em class="fa fa-plus-circle fa-custom" aria-hidden="true"></em>
            </a>
          </div>
          <div class="tableWrp dispatchCostTbl table-responsive">
            <table class="table dataTable table-striped">
              <thead>
                <tr>
                  <th scope="col">Tool</th>
                  <th scope="col">Op-Codes</th>
                  <th scope="col">Parts Cost ($)</th>
                  <th scope="col">Labor Cost ($)</th>
                  <th scope="col">Total Cost ($)</th>
                  <th scope="col">Comments</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody formArrayName="opCodePrices">
                <tr *ngFor="let opCodePrice of getControls(); let i = index;" [formGroupName]="i">
                  <td style="width: 150px;">
                    <select class="form-control" formControlName="opCodeId" placeholder="Store"
                      [ngClass]="{ 'is-invalid': submitted && opCodePrice.controls.opCodeId.errors }"
                      (change)="onOpCodeSelect(i)">
                      <option value="">Select Sub Category</option>
                      <option *ngFor="let item of opCodes" [value]="item.id">
                        {{item.subCategoryName}}
                      </option>
                    </select>
                    <div *ngIf="submitted && opCodePrice.controls.opCodeId.errors" class="invalid-feedback">
                      Sub Category is required
                    </div>
                  </td>
                  <td>
                    <span [innerHtml]="opCodePrice.controls.opCodeName.value"></span>
                  </td>
                  <td>
                    <input class="form-control" placeholder="" formControlName="parts" mask="separator.2" thousandSeparator=","
                      [ngClass]="{ 'is-invalid': submitted && opCodePrice.controls.parts.errors }" />
                    <div *ngIf="submitted && opCodePrice.controls.parts.errors" class="invalid-feedback">
                      <div *ngIf="opCodePrice.controls.parts.errors.required">
                        Parts is required
                      </div>
                      <div *ngIf="opCodePrice.controls.parts.errors.numeric">
                        Parts should be numbers
                      </div>
                      <div *ngIf="opCodePrice.controls.parts.errors.minNumber">
                        Parts should be min {{opCodePrice.controls.parts.validatorConfig.minNumber.value}}
                      </div>
                    </div>
                  </td>
                  <td>
                    <input class="form-control" placeholder="" formControlName="labor" mask="separator.2" thousandSeparator=","
                      [ngClass]="{ 'is-invalid': submitted && opCodePrice.controls.labor.errors }" />
                    <div *ngIf="submitted && opCodePrice.controls.labor.errors" class="invalid-feedback">
                      <div *ngIf="opCodePrice.controls.labor.errors.required">
                        Labor is required
                      </div>
                      <div *ngIf="opCodePrice.controls.labor.errors.numeric">
                        Labor should be numbers
                      </div>
                      <div *ngIf="opCodePrice.controls.labor.errors.minNumber">
                        Labor should be min {{opCodePrice.controls.labor.validatorConfig.minNumber.value}}
                      </div>
                    </div>
                  </td>
                  <td>
                    <span [innerHtml]="currencyPipe.transform(opCodePrice.controls.cost.value, 'USD', '', '1.2-2') "></span>
                  </td>
                  <td>
                    <input type="text" class="form-control" placeholder="" formControlName="comments"
                      [ngClass]="{ 'is-invalid': submitted && opCodePrice.controls.comments.errors }" />
                    <div *ngIf="submitted && opCodePrice.controls.comments.errors" class="invalid-feedback">
                      <div *ngIf="opCodePrice.controls.comments.errors.maxNumber">
                        Comments should be max {{opCodePrice.controls.comments.validatorConfig.maxNumber.value}}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <a *ngIf="getControls().length != 1" href="javascript:void(0)" (click)="removeOpCode(i)">
                        <em class="fa fa-minus-circle fa-custom" aria-hidden="true"></em>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tr>
                <td></td>
                <td class="text-right">Total Price</td>
                <td>
                  <span [innerHtml]="currencyPipe.transform(totalParts, 'USD', '', '1.2-2')"></span>
                </td>
                <td>
                  <span [innerHtml]="currencyPipe.transform(totalLabor, 'USD', '', '1.2-2')"></span>
                </td>
                <td>
                  <span [innerHtml]="currencyPipe.transform(totalCost, 'USD', '', '1.2-2')"></span>
                </td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </div>
          <div class="row">
            <div class="col-12 col-md-12">
              <div class="btnWrp text-right mt-0">
                <button class="btn btn-red">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>