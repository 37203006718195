import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlobService, UploadParams } from 'angular-azure-blob-service';
import * as uuid from 'uuid';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { StorageContainerType } from 'src/app/enums';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { CompanyContactModel, CompanyDetailModel, CompanyDocumentModel, CompanyEditModel, CountryModel, StateModel, UserModel } from 'src/app/models';
import { CompanyService, CountryService, FilterStateService, MessagingService, StorageService, UserService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';

@Component({
    selector: 'app-company-edit',
    templateUrl: './company.edit.component.html',
    styleUrls: ['./company.edit.component.css']
})
export class CompanyEditComponent {
    @BlockUI('container-blockui-company') blockUI: NgBlockUI;
    @ViewChild('documentAttachment', { static: false }) documentAttachment: ElementRef;

    accountMangers: Array<UserModel> = new Array<UserModel>();
    countries: Array<CountryModel> = new Array<CountryModel>();
    states: Array<StateModel> = new Array<StateModel>();
    form: FormGroup;
    submitted = false;
    model: CompanyEditModel;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
        private messagingService: MessagingService,
        private storageService: StorageService,
        private countryService: CountryService,
        private blobService: BlobService,
        private companyService: CompanyService,
        public appUtils: AppUtils,
        private formBuilder: FormBuilder,
        private stringLengthConstants: StringLengthConstants,
        private filterStateService: FilterStateService,
    ) {
        this.model = new CompanyEditModel();
        filterStateService.changeFilterModelStatues(FilterPageType.companyManage, true);
        this.route.params.subscribe(params => this.model.id = params['id']);
    }

    ngOnInit() {
        //Create Empty group
        this.form = this.formBuilder.group({
            companyName: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            streetAddress: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            blockAddress: [null, [Validators.maxLength(this.stringLengthConstants.Name)]],
            city: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            state: ['', [Validators.required]],
            country: ['', [Validators.required]],
            zipCode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(this.stringLengthConstants.PostalCode), Validators.pattern(new RegExp('^[a-zA-Z0-9-]*$', 'g'))]],
            email: ['', [Validators.required, Validators.email, Validators.maxLength(this.stringLengthConstants.Email)]],
            phoneCode:['',[Validators.required]],//renamed
            phoneNumber: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.PostalCode), this.appUtils.validatePhoneNumber()]],
            accountManagerId: ['', [Validators.required]],

            quickBookClientId: ['', [Validators.required]],
            quickBookClientSecret: ['', [Validators.required]],
            quickBookAppEnvironment: ['', [Validators.required]],

            taxId: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.TaxId), Validators.pattern("^[0-9]*$")]],
            contacts: this.formBuilder.array([], this.hasContacts())
        });

        this.loadCompany();
        this.loadAccountManagers();
        this.loadCountries();
    }

    private loadCompany() {
        this.blockUI.start();
        this.companyService.get(this.model.id).subscribe((data: any) => {
            this.model = data;
            this.initForm();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    private initForm() {
        this.form.controls.companyName.setValue(this.model.name);
        this.form.controls.streetAddress.setValue(this.model.streetAddress);
        this.form.controls.blockAddress.setValue(this.model.blockAddress);
        this.form.controls.city.setValue(this.model.city);

        this.form.controls.city.setValue(this.model.city);
        this.form.controls.state.setValue(this.model.state);
        this.form.controls.country.setValue(this.model.country);
        this.form.controls.zipCode.setValue(this.model.zipCode);

        this.form.controls.email.setValue(this.model.email);
        this.form.controls.phoneCode.setValue(this.model.phoneCode);
        this.form.controls.phoneNumber.setValue(this.model.phoneNumber);
        this.form.controls.accountManagerId.setValue(this.model.accountManagerId);
        this.form.controls.taxId.setValue(this.model.taxId);

        this.form.controls.quickBookClientId.setValue(this.model.quickBookClientId);
        this.form.controls.quickBookClientSecret.setValue(this.model.quickBookClientSecret);
        this.form.controls.quickBookAppEnvironment.setValue(this.model.quickBookAppEnvironment);

        let items = this.form.get('contacts') as FormArray;
        this.model.contacts.forEach(contact => {
            items.push(this.formBuilder.group({
                name: [contact.name, [Validators.maxLength(this.stringLengthConstants.Name)]],
                id: contact.id,
                isDeleted: contact.isDeleted,
                companyId: contact.companyId
            }));
        });
    }

    private createContact(): FormGroup {
        return this.formBuilder.group({
            name: ['', [Validators.maxLength(this.stringLengthConstants.Name)]],
            id: 0,
            isDeleted: false,
            companyId: this.model.id
        });
    }

    addContact(): void {
        let items = this.form.get('contacts') as FormArray;
        items.push(this.createContact());
    }

    private hasContacts(): ValidatorFn {
        const validator: ValidatorFn = (formArray: FormArray) => {
            const hasContact = formArray.controls.some(control => control.value.name.trim().length >= 2);
            return hasContact ? null : { required: true };
        };
        return validator;
    }

    uploadedPercent = 0;
    onDocumentSelect(event: any) {
        if (!event.target.files || event.target.files.length === 0) {
            return;
        }

        [...event.target.files].forEach(file => this.processDocument(file));
    }

    private processDocument(file: any) {
        this.storageService.getSasToken().subscribe(async (sasToken) => {
            const cloudConfig: UploadParams = {
                sas: sasToken.toString(),
                storageAccount: environment.azureAccount,
                containerName: StorageContainerType.CompanyDocuments
            };
    
            const fileName = `${uuid.v4()}.${this.appUtils.getFileExtension(file.name)}`;
            const blobUrl = this.blobService.generateBlobUrl(cloudConfig, fileName);
    
            let document = new CompanyDocumentModel();
            document.originalFileName = file.name;
            document.fileName = fileName;
            document.isDeleted = false;
    
            this.blockUI.start();
            let config = {
                baseUrl: `${blobUrl}?`,
                sasToken: cloudConfig.sas,
                blockSize: 1024 * 64,
                file: file,
                complete: () => {
                    this.storageService.getPublicUrl(StorageContainerType.CompanyDocuments, document.fileName).subscribe(res => {
                        document.fileUrl = res.toString();
                    });
                    this.model.documents.push(document);
                    this.blockUI.stop();
                },
                error: (err) => {
                    this.messagingService.ProcessErrorResponse(err, 'File upload timeout.');
                    this.blockUI.stop();
                },
                progress: (percent) => {
                    this.uploadedPercent = percent;
                }
            };
            setTimeout(() => {
                this.blobService.upload(config);
            });
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
        
    }

    public deleteDocument(document: CompanyDocumentModel, index: number) {
        this.model.documents.splice(index, 1);
    }

    private loadSASToken() {
        this.blockUI.start();
        
    }

    onSubmit() {

        this.submitted = true;
        if (this.form.invalid || this.model.documents.length === 0) {
            return;
        }

        this.model.name = this.form.controls.companyName.value;
        this.model.streetAddress = this.form.controls.streetAddress.value;
        this.model.blockAddress = this.form.controls.blockAddress.value;
        this.model.city = this.form.controls.city.value;
        this.model.state = this.form.controls.state.value;
        this.model.country = this.form.controls.country.value;
        this.model.zipCode = this.form.controls.zipCode.value;
        this.model.email = this.form.controls.email.value;
        this.model.phoneCode = this.form.controls.phoneCode.value; 
        this.model.phoneNumber = this.form.controls.phoneNumber.value;
        this.model.accountManagerId = this.form.controls.accountManagerId.value;

        this.model.quickBookClientId = this.form.controls.quickBookClientId.value;
        this.model.quickBookClientSecret = this.form.controls.quickBookClientSecret.value;
        this.model.quickBookAppEnvironment = this.form.controls.quickBookAppEnvironment.value;

        this.model.taxId = this.form.controls.taxId.value;
        this.model.contacts = this.form.controls.contacts.value.filter((x: any) => x.name != null && x.name.length > 0).map(x => {
            var cm = new CompanyContactModel();
            cm.name = x.name;
            cm.id = x.id;
            cm.isDeleted = x.isDeleted;
            cm.companyId = x.companyId;
            return cm;
        });

        this.blockUI.start();
        this.companyService.edit(this.model)
            .subscribe(
                (data: CompanyDetailModel) => {
                    setTimeout(() => {
                        this.router.navigate(['/manage/company']);
                    }, 10);
                    setTimeout(() => {
                        this.messagingService.success('Managing Company has been updated successfully.');
                    }, 300);
                    this.blockUI.stop();
                },
                error => {
                    this.messagingService.ProcessErrorResponse(error);
                    this.blockUI.stop();
                });
    }

    onCancel() {
        this.router.navigate(['/manage/company']);
    }

    private loadAccountManagers() {
        this.blockUI.start();
        this.userService.getAccountManagers().subscribe((data: any) => {
            this.accountMangers = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    private loadCountries() {
        this.blockUI.start();
        this.countryService.getCountries().subscribe((data: any) => {
            this.countries = data;
            if (this.countries.length == 1) {
                this.loadStates(this.countries[0].id);
                this.form.controls['country'].setValue(this.countries[0].shortName);
                this.form.controls['phoneCode'].setValue(this.countries[0].phoneCode);
            }
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    private loadStates(countryId: number) {
        this.blockUI.start();
        this.countryService.getStates(countryId).subscribe((data: any) => {
            this.states = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
}
