import { Component } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { data } from 'jquery';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { InspectionStatus, WorkStatus } from 'src/app/enums';
import { StringLengthConstants, AppUtils } from 'src/app/helpers';
import { FinalInspectionItemModel, FinalInspectionModel, VehicleQueueModel, VehicleQueueStageModel, VehicleWorkDetailModel } from 'src/app/models';
import { ApplicationContextService, MessagingService, VehicleQueueService, CategoryService, SubCategoryService, CarLayoutPanelService, CarLayoutLocationService, CarLayoutSideService, OperationCodeService, PriceMatrixService, ApprovalService } from 'src/app/services';

@Component({
  selector: 'app-final-Inspection',
  templateUrl: './final-Inspection.component.html',
  styleUrls: ['./final-Inspection.component.css']
})
export class FinalInspectionComponent {
  vehicleinqueue: string = "assets/images/vechile-in-queue.png";
  vehicleGraph: string = "assets/images/vechile-queue-graph.png";

  @BlockUI('container-blockui') blockUI: NgBlockUI;
  frmFinalInspection: FormGroup;
  private vehicleQueueId: number;
  private vehicleQueueStageId: number;

  vehicleQueueModel: VehicleQueueModel = new VehicleQueueModel();
  vehicleQueueStageModel: VehicleQueueStageModel = new VehicleQueueStageModel();
  vehicleWorkDetailModel: Array<VehicleWorkDetailModel> = new Array<VehicleWorkDetailModel>();


  constructor(private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private messagingService: MessagingService,
    private vehicleQueueService: VehicleQueueService,
  ) {
    this.activatedRoute.params.subscribe((data) => {
      this.vehicleQueueId = data.vehicleQueueId;
      this.vehicleQueueStageId = data.vehicleQueueStageId;
    });
  }

  ngOnInit() {
    this.frmFinalInspection = this.formBuilder.group({
      selectAll: [false],
      opCodes: this.formBuilder.array([]),
    });

    this.loadVehicleQueue();
  }

  public getFormArrayControls() {
    return (this.frmFinalInspection.controls.opCodes as FormArray).controls;
  }

  private loadVehicleQueue() {
    this.blockUI.start();
    this.vehicleQueueService.get(this.vehicleQueueId)
      .subscribe((vehicleQueue) => {
        if (!vehicleQueue) {
          this.messagingService.ProcessErrorResponse(null, "This vehicle is not belongs to the selected store");
          return;
        }

        this.vehicleQueueModel = vehicleQueue;
        this.vehicleQueueStageModel = this.vehicleQueueModel.vehicleQueueStages.find(x => x.id == this.vehicleQueueStageId);

        this.loadData();

      }, error => {
        this.messagingService.ProcessErrorResponse(error);
      }, () => {
        this.blockUI.stop();
      });
  }

  private loadData() {
    this.blockUI.start();
    this.vehicleQueueService.getFinalInspectionWorkDetail(this.vehicleQueueId)
      .subscribe((workDetails) => {
        this.vehicleWorkDetailModel = workDetails;

        this.loadFormData();
      }, error => {
        this.messagingService.ProcessErrorResponse(error);
      }, () => {
        this.blockUI.stop();
      });
  }

  private loadFormData() {
    this.vehicleWorkDetailModel.forEach(data => {
      let isApproved = data.inspectionStatus == InspectionStatus.Accepted;
      let frm = this.formBuilder.group({
        workDetailId: [data.id],
        operationCodeId: [data.operationCodeId],
        operationCodeName: [data.operationCodeDetailModel.name],
        categoryName: [data.operationCodeDetailModel.categoryName],
        subCategoryName: [data.operationCodeDetailModel.subCategoryName],
        locationName: [data.operationCodeDetailModel.carLayoutLocationName],
        panelName: [data.operationCodeDetailModel.carLayoutPanelName],
        sideName: [data.operationCodeDetailModel.carLayoutSideName],
        workerName: [data.workerUser],
        isApproved: [{ value: isApproved, disabled: isApproved }],
      });

      (this.frmFinalInspection.controls.opCodes as FormArray).push(frm);
    });

    let hasNotStarted = this.vehicleWorkDetailModel.every(x => x.inspectionStatus == InspectionStatus.NotStarted);
    if (!hasNotStarted)
      this.frmFinalInspection.controls.selectAll.disable();
  }

  public onSelectAllChange() {
    let opCodes = this.frmFinalInspection.controls.opCodes as FormArray;
    opCodes.controls.forEach(elem => {
      let fb = elem as FormGroup;
      fb.controls.isApproved.setValue(this.frmFinalInspection.controls.selectAll.value);
    });
  }

  public onIsSelectedChange() {
    let opCodes = this.frmFinalInspection.controls.opCodes as FormArray;
    let selectValues = opCodes.controls.map(elem => {
      let fb = elem as FormGroup;
      return fb.controls.isApproved.value;
    });

    //If every element is selected
    this.frmFinalInspection.controls.selectAll.setValue(selectValues.every(x => x == true));
  }

  public onSubmit() {
    let model = new FinalInspectionModel();
    model.vehicleQueueId = this.vehicleQueueId;
    model.vehicleQueueStageId = this.vehicleQueueStageId;
    (this.frmFinalInspection.controls.opCodes as FormArray).controls.forEach(element => {
      let fb = element as FormGroup;
      let itemModel = new FinalInspectionItemModel();
      itemModel.workDetailId = Number(fb.controls.workDetailId.value);
      itemModel.operationCodeId = Number(fb.controls.operationCodeId.value);
      itemModel.inspectionStatus = Boolean(fb.controls.isApproved.value) ?
        InspectionStatus.Accepted : InspectionStatus.Rejected;

      model.finalInspectionItemModels.push(itemModel);
    });

    this.vehicleQueueService.saveFinalInspectionWorkDetail(model).subscribe(() => {
      setTimeout(() => {
        this.router.navigate(['/']);
      }, 10)
      setTimeout(() => {
        this.messagingService.success('Final inspection successfully.');
      }, 300)
    }, error => {
      this.messagingService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }
}
