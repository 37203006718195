import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserService {
    constructor(http) {
        this.http = http;
    }
    add(model) {
        return this.http.post(`${environment.apiBaseUrl}/user/add`, model);
    }
    edit(model) {
        return this.http.patch(`${environment.apiBaseUrl}/user/edit`, model);
    }
    delete(id) {
        return this.http.delete(`${environment.apiBaseUrl}/user/${id}`);
    }
    toggleStatus(id) {
        return this.http.patch(`${environment.apiBaseUrl}/user/toggle-status/${id}`, null);
    }
    getMyProfile() {
        return this.http.get(`${environment.apiBaseUrl}/user/my/profile`);
    }
    getUserDetail(id) {
        return this.http.get(`${environment.apiBaseUrl}/user/${id}`);
    }
    getAccountManagers() {
        return this.http.get(`${environment.apiBaseUrl}/user/account-managers`);
    }
    query(dataTablesParameters) {
        return this.http.post(`${environment.apiBaseUrl}/user/query`, dataTablesParameters, {});
    }
    updateProfile(model) {
        return this.http.patch(`${environment.apiBaseUrl}/user/profile`, model);
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
