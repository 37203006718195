import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CategoryService {
    constructor(http) {
        this.http = http;
    }
    get(id) {
        return this.http.get(`${environment.apiBaseUrl}/category/${id}`);
    }
    add(model) {
        return this.http.post(`${environment.apiBaseUrl}/category`, model);
    }
    update(model) {
        return this.http.put(`${environment.apiBaseUrl}/category`, model);
    }
    delete(id) {
        return this.http.delete(`${environment.apiBaseUrl}/category/${id}`);
    }
    toggleStatus(id) {
        return this.http.patch(`${environment.apiBaseUrl}/category/toggle-status/${id}`, null);
    }
    query(dataTablesParameters) {
        return this.http.post(`${environment.apiBaseUrl}/category/query`, dataTablesParameters, {});
    }
    selectList() {
        return this.http.get(`${environment.apiBaseUrl}/category/select-list`);
    }
    detailSelectList() {
        return this.http.get(`${environment.apiBaseUrl}/category/detail/select-list`);
    }
    selectListByStore(id) {
        return this.http.get(`${environment.apiBaseUrl}/store/${id}/category/select-list`);
    }
    selectListForIVL(storeId, workflowId) {
        return this.http.get(`${environment.apiBaseUrl}/category/ivl/store/${storeId}/workflow/${workflowId}/select-list`);
    }
}
CategoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CategoryService_Factory() { return new CategoryService(i0.ɵɵinject(i1.HttpClient)); }, token: CategoryService, providedIn: "root" });
