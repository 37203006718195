<section class="compnyDtlPSec addRoleSec" *blockUI="'container-blockui-edit-role'">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <a [routerLink]="['/manage/role']" class="backtolist"><em class="fa fa-long-arrow-left" aria-hidden="true"></em>
          Back to list</a>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-8 col-xl-8 offset-xl-2 offset-lg-2">
        <div class="compnydtlwrp addRolePwrp">
          <form [formGroup]="roleFrm" (ngSubmit)="onSubmit()">
            <div class="compnydtlH">
              <div class="row">
                <div class="col-12 col-md-6">
                  <h2>Edit Role</h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Role Name</label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <input type="text" class="form-control" formControlName="roleName" placeholder="Role Name" 
                    [ngClass]="{ 'is-invalid': submitted && roleFrm.controls.roleName.errors }">
                  <div *ngIf="submitted && roleFrm.controls.roleName.errors" class="invalid-feedback">
                    <div *ngIf="roleFrm.controls.roleName.errors.required">
                      Role Name is required
                    </div>
                    <div *ngIf="roleFrm.controls.roleName.errors.maxlength">
                      Role name should be max {{form.controls.roleName.errors.maxlength.requiredLength}}
                      characters
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Role Type</label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <select class="form-control" formControlName="applicationRoleType" placeholder="Role Type"
                    [ngClass]="{ 'is-invalid': submitted && roleFrm.controls.applicationRoleType.errors }">
                    <option value="">Select Role Type</option>
                    <option *ngFor="let item of applicationRoleTypes" [value]="item.id">
                      {{item.name}}
                    </option>
                  </select>
                  <div *ngIf="submitted && roleFrm.controls.applicationRoleType.errors" class="invalid-feedback">
                    <div *ngIf="roleFrm.controls.applicationRoleType.errors.required">Role Type
                      is required</div>
                  </div>
                </div>
              </div>
            </div>
           
            <h4>Permissions:</h4>
            <div class="row" formArrayName="permissions">
              <div class="col-12 col-sm-6" *ngFor="let item of roleFrm.get('permissions').controls;let i = index;"
                [formGroupName]="i">
                <div class="form-check">
                  <div class="form-group">
                    <input type="checkbox" class="form-check-input" id="chkPrevillage{{i}}"
                      formControlName="isSelected" />
                    <label class="form-check-label" for="chkPrevillage{{i}}">{{item.value.name}}</label>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="btnwrp">
              <button class="btn btn-red" type="submit">Save</button>
              <button class="btn btn-tsrp" type="button" (click)="onCancel()">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</section>