<section class="gnrlformSec">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <a [routerLink]="['/manage/price-matrix']" class="backtolist">
          <em class="fa fa-long-arrow-left" aria-hidden="true"></em>
          Back to list
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12 col-lg-8 col-xl-8 offset-xl-2 offset-lg-2">
        <div class="gnrlformbox">
          <h2>Clone Pricing Matrix</h2>
          <div class="gnrlformDtl">
            <form class="matrixFrom" [formGroup]="frmPriceMatrix" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <div class="matrixFromLabel">
                  <h5>Store </h5>
                </div>
                <div class="matrixFromField">
                  <select class="form-control hDropDown" formControlName="storeId"
                    [ngClass]="{ 'is-invalid': submitted && frmPriceMatrix.controls.storeId.errors }"
                    (change)="onStoreSelectionChange($event)">
                    <option value="">Select Store</option>
                    <option *ngFor="let item of stores" [value]="item.id">
                      {{item.name}}
                    </option>
                  </select>
                  <div *ngIf="submitted && frmPriceMatrix.controls.storeId.errors" class="invalid-feedback">
                    <div *ngIf="frmPriceMatrix.controls.storeId.errors.required">
                      Store is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="matrixFromLabel">
                  <h5>Check-In Type </h5>
                </div>
                <div class="matrixFromField">
                  <select class="form-control hDropDown" formControlName="checkInTypeId"
                    [ngClass]="{ 'is-invalid': submitted && frmPriceMatrix.controls.checkInTypeId.errors }">
                    <option value="">Select Check-In Type</option>
                    <option *ngFor="let item of checkInTypes" [value]="item.id">
                      {{item.name}}
                    </option>
                  </select>
                  <div *ngIf="submitted && frmPriceMatrix.controls.checkInTypeId.errors" class="invalid-feedback">
                    <div *ngIf="frmPriceMatrix.controls.checkInTypeId.errors.required">
                      Check-In type is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="matrixFromLabel">
                  <h5>Category </h5>
                </div>
                <div class="matrixFromField">
                  <select class="form-control hDropDown" formControlName="categoryId"
                    [ngClass]="{ 'is-invalid': !isCategoryAssociatedWithStore || (submitted && frmPriceMatrix.controls.categoryId.errors) }"
                    (change)="onCategorySelectionChange($event)">
                    <option value="">Select Category</option>
                    <option *ngFor="let item of categories" [value]="item.id">
                      {{item.name}}
                    </option>
                  </select>
                  <div *ngIf="submitted && frmPriceMatrix.controls.categoryId.errors" class="invalid-feedback">
                    <div *ngIf="frmPriceMatrix.controls.categoryId.errors.required">Category is
                      required</div>
                  </div>
                  <div *ngIf="!isCategoryAssociatedWithStore" class="invalid-feedback">
                    Category is not associated with this store. Associate this category with the
                    store to use it.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="matrixFromLabel">
                  <h5>Tool </h5>
                </div>
                <div class="matrixFromField">
                  <select class="form-control hDropDown" formControlName="subCategoryId"
                    [ngClass]="{ 'is-invalid': !isSubCategoryAssociatedWithStore || (submitted && frmPriceMatrix.controls.subCategoryId.errors) }"
                    (change)="onSubCategorySelectionChange($event)">
                    <option value="">Select Tool</option>
                    <option *ngFor="let item of subCategories" [value]="item.id">
                      {{item.name}}
                    </option>
                  </select>
                  <div *ngIf="submitted && frmPriceMatrix.controls.subCategoryId.errors" class="invalid-feedback">
                    <div *ngIf="frmPriceMatrix.controls.subCategoryId.errors.required">
                      Tool is required
                    </div>
                  </div>
                  <div *ngIf="!isSubCategoryAssociatedWithStore" class="invalid-feedback">
                    Tool is not associated with this store. Associate this tool with the store to
                    use it.</div>
                </div>
              </div>
              <div class="form-group">
                <div class="matrixFromLabel">
                  <h5>Panel </h5>
                </div>
                <div class="matrixFromField">
                  <ng-multiselect-dropdown [placeholder]="'Select Panel'" [disabled]="!hasPanel" [data]="panels"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedPanels"
                    [settings]="panelDropdownSettings"
                    [ngClass]="{ 'is-invalid': submitted && selectedPanels.length == 0 && frmPriceMatrix.controls.unitOfMeasure.value != UnitOfMeasure.Vehicle}">
                  </ng-multiselect-dropdown>

                  <div
                    *ngIf="submitted && selectedPanels.length == 0 && frmPriceMatrix.controls.unitOfMeasure.value != UnitOfMeasure.Vehicle,"
                    class="invalid-feedback">
                    Panel is required
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="matrixFromLabel">
                  <h5>Unit of measure for Pricing</h5>
                </div>
                <div class="matrixFromField">
                  <p *ngIf="hasPanel">
                    <input type="radio" formControlName="unitOfMeasure" [value]="UnitOfMeasure.Panel" id="panel"
                      (change)="onUnitOfMeasureChange()">
                    <label for="panel">Panel</label>
                  </p>
                  <p *ngIf="hasPanel">
                    <input type="radio" formControlName="unitOfMeasure" [value]="UnitOfMeasure.Spot" id="spot"
                      (change)="onUnitOfMeasureChange()">
                    <label for="spot">Spot</label>
                  </p>
                  <p>
                    <input type="radio" formControlName="unitOfMeasure" [value]="UnitOfMeasure.Vehicle" id="vehicle"
                      (change)="onUnitOfMeasureChange()">
                    <label for="vehicle">Vehicle</label>
                  </p>

                  <div class="measurePricing" formArrayName="unitPrices">
                    <div class="measurePricingPrice">
                      <div class="measurePricingLabel">
                        <h5>Price</h5>
                      </div>
                      <div class="measurePricingField plushWrp" *ngFor="let item of getControls(); let i = index;"
                        [formGroupName]="i">
                        <div class="priceWrp">
                          <input class="form-control" formControlName="price" placeholder="" min="0"  mask="separator.2" thousandSeparator=","
                            [ngClass]="{ 'is-invalid': submitted && (frmPriceMatrix.controls.unitPrices.errors || item.controls.price.errors) }">
                          <div *ngIf="submitted && item.controls.price.errors" class="invalid-feedback">
                            <div *ngIf="item.controls.price.errors.required">
                              Price is required
                            </div>
                            <div *ngIf="item.controls.price.errors.min">
                              Price should be greater than 0
                            </div>
                            <div *ngIf="item.controls.price.errors.pattern">
                              Please enter numeric value
                            </div>
                          </div>
                        </div>
                        <div class="plushMinusWrp"
                          *ngIf="frmPriceMatrix.controls.unitOfMeasure.value != UnitOfMeasure.Vehicle">
                          <a *ngIf="getControls().length != 1" href="javascript:void(0)" (click)="removeUnitPrice(i)">
                            <em class="fa fa-minus-circle fa-custom" aria-hidden="true"></em>
                          </a>
                          <a *ngIf="getControls().length == (i+1)" href="javascript:void(0)"
                            (click)="addUnitPrice()">
                            <em class="fa fa-plus-circle fa-custom" aria-hidden="true"></em>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="matrixFromLabel">
                  <h5>Pricing Type</h5>
                </div>
                <div class="matrixFromField">
                  <p>
                    <input type="checkbox" formControlName="isReconPackage" id="icdReconPkg">
                    <label for="icdReconPkg">Included in Recon Package</label>
                  </p>
                  <p>
                    <input type="checkbox" formControlName="isApprovalRequired" id="quoteAppvlReqr">
                    <label for="quoteAppvlReqr">Approval Required</label>
                  </p>
                </div>
              </div>
              <div class="form-group">
                <div class="matrixFromLabel">
                  <h5>Is PO Required? </h5>
                </div>
                <div class="matrixFromField">
                  <input class="form-check-input" id="chkPoRequired" type="checkbox" formControlName="isPORequired">
                  <label class="form-check-label" for="chkPoRequired">
                  </label>
                </div>
              </div>
              <div class="form-group">
                <div class="matrixFromLabel">
                  <h5>Is Shared Work Queue?</h5>
                </div>
                <div class="matrixFromField">
                  <input class="form-check-input" id="chkSharedQueue" type="checkbox"
                    formControlName="isSharedWorkQueue">
                  <label class="form-check-label" for="chkSharedQueue">
                  </label>
                </div>
              </div>
              <div class="form-group">
                <div class="matrixFromLabel">
                  <h5>Is Inspection Required?</h5>
                </div>
                <div class="matrixFromField">
                  <input class="form-check-input" id="chkInspectionRequired" type="checkbox"
                    formControlName="isInspectionRequired">
                  <label class="form-check-label" for="chkInspectionRequired">
                  </label>
                </div>
              </div>
              <div class="form-group">
                <div class="matrixFromLabel">
                  <h5>Is Invoice Required?</h5>
                </div>
                <div class="matrixFromField">
                  <input class="form-check-input" id="chkInvoice" type="checkbox" formControlName="isInvoice">
                  <label class="form-check-label" for="chkInvoice">
                  </label>
                </div>
              </div>
              <div class="btnWrp">
                <button class="btn btn-red" type="submit">Save</button>
                <button class="btn btn-tsrp" type="button" [routerLink]="['/manage/price-matrix']">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
