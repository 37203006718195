import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PricingType } from 'src/app/enums';
import { AppUtils } from 'src/app/helpers';
import { OperationCodeDetailModel } from 'src/app/models';
import { FilterStateService, MessagingService, OperationCodeService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { noop as _noop } from 'lodash-es';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';

@Component({
    selector: 'app-manage-op-code',
    templateUrl: './manage-op-code.component.html',
    styleUrls: ['./manage-op-code.component.css']
})
export class ManageOpCodeComponent {
    frmSearch: FormGroup;
    filterOptions: any;
    statusOptions: any;

    //Data Table Options
    @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtInstance: DataTables.Api;
    rowIndex: number = 0;

    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild('deleteConfirmationModal', { static: true }) deleteConfirmationModal: any;
    @ViewChild('statusChangeConfirmationModal', { static: true }) statusChangeConfirmationModal: any;

    constructor(private formBuilder: FormBuilder,
        private router: Router,
        private messagingService: MessagingService,
        public appUtils: AppUtils,
        private operationCodeService: OperationCodeService,
        private filterStateService: FilterStateService) {
        this.filterOptions = this.appUtils.getFilterOperationOptions();
        this.statusOptions = this.appUtils.getStatusOptions();
    }

    ngAfterViewInit() {
        this.updateFormFromFilterModel();

        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);

    }

    updateFormFromFilterModel() {
        const tempFilterModel = this.filterStateService.getFilterModel(FilterPageType.opCodeManage);
        if (tempFilterModel && tempFilterModel.isActive) {
            this.frmSearch.controls.opCodeName.setValue(tempFilterModel.filterKey);
            this.frmSearch.controls.filterOption.setValue(tempFilterModel.filterOption);
            this.frmSearch.controls.statusOption.setValue(tempFilterModel.statusOption);
        }
    }

    updateFilterModel() {
        const filterModel = this.filterStateService.getFilterModel(FilterPageType.opCodeManage);
        filterModel.page = FilterPageType.opCodeManage;
        filterModel.filterKey = this.frmSearch.controls.opCodeName.value;
        filterModel.filterOption = this.frmSearch.controls.filterOption.value;
        filterModel.statusOption = this.frmSearch.controls.statusOption.value;
        filterModel.isActive = false;
    }

    private isCompleteDataTable: boolean = false;
    handleScroll = (scrolled: boolean) => {
        (scrolled && !this.isCompleteDataTable) ? this.dtInstance.ajax.reload() : _noop();
    }

    ngOnDestroy(): void {

    }

    ngOnInit() {
        this.frmSearch = this.formBuilder.group({
            opCodeName: [''],
            filterOption: [1], //Contains
            statusOption: [2] //Active
        });

        this.initDataTable();

        this.frmSearch.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
            this.applyFilter();
        });
    }

    applyFilter() {
        this.dtInstance.ajax.reload();
    }
    initDataTable() {
        //Data Table Example.
        const self = this;
        let length = environment.paging.defaultPageSize;
        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter...',
                paginate: {
                    first: '<em class="fa fa-angle-double-left">',
                    last: '<em class="fa fa-angle-double-right">',
                    previous: '<em class="fa fa-angle-left">',
                    next: '<em class="fa fa-angle-right">'
                }
            },
            search: { search: null },
            searching: false,
            displayStart: self.rowIndex,
            paging: false,
            pagingType: 'full_numbers',
            pageLength: environment.paging.defaultPageSize,
            lengthMenu: environment.paging.lengthMenu,
            order: [[0, 'asc']],
            info: false,
            stateSave: true,
            scrollY: (window.innerHeight - 70 - 45) + "px",
            scrollCollapse: true,
            ajax: (dataTablesParameters: any, callback) => {

                self.updateFilterModel();

                // dataTablesParameters.length = length;
                dataTablesParameters.name = self.frmSearch.controls.opCodeName.value;
                dataTablesParameters.filterBy = Number(self.frmSearch.controls.filterOption.value);
                dataTablesParameters.status = self.statusOptions.find(x => x.id == self.frmSearch.controls.statusOption.value).value;

                self.blockUI.start();
                self.operationCodeService.query(dataTablesParameters)
                    .subscribe(resp => {
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });

                        self.isCompleteDataTable = resp.recordsTotal == resp.data.length;
                        length += environment.paging.defaultPageSize;

                        self.blockUI.stop();
                    }, error => {
                        self.messagingService.ProcessErrorResponse(error);
                        self.blockUI.stop();
                    });
            },
            columns: [
                {
                    data: 'name',
                    title: 'OP Code Name',
                    width: '12%',
                    render: function (data, type, row) {
                        return `<span class='link' action-type='link-detail'>${row.name}</a>`;
                    }
                },
                {
                    data: 'categoryName',
                    title: 'Category',
                    width: '12%'
                },
                {
                    data: 'subCategoryName',
                    title: 'Tool',
                    width: '12%'
                },
                {
                    data: 'carLayoutPanelName',
                    title: 'Panel',
                    width: '12%'
                },
                {
                    data: 'carLayoutLocationName',
                    title: 'Location',
                    width: '8%'
                },
                {
                    data: 'carLayoutSideName',
                    title: 'Side',
                    width: '8%'
                },
                {
                    data: 'isLocationSpecific',
                    title: 'Location Specific',
                    width: '8%',
                    render: function (isLocationSpecific) {
                        return isLocationSpecific
                            ? '<span class="">Yes</span>'
                            : '<span class="">No</span>';
                    }
                },
                {
                    data: 'isTiedToIVL',
                    title: 'Tied To IVL',
                    width: '7%',
                    render: function (isTiedToIVL) {
                        return isTiedToIVL
                            ? '<span class="">Yes</span>'
                            : '<span class="">No</span>';
                    }
                },
                {
                    data: 'isActive',
                    title: 'Status',
                    width: '8%',
                    render: function (isActive) {
                        return isActive
                            ? '<span class="badge badge-success">Active</span>'
                            : '<span class="badge badge-danger">Inactive</span>';
                    }
                },
                {
                    data: null,
                    title: 'Action',
                    width: '20%',
                    orderable: false,
                    className: 'text-center',
                    visible: true,
                    render: function (data, type, row) {
                        //Render Activate or Deactivate Button
                        let statusHtml = row.isActive ?
                            `<button class='btn' type='button' title='Deactivate' action-type='toggle-status'><em class="fa fa-ban" aria-hidden="true"></em></button>` :
                            `<button class='btn' type='button' title='Activate' action-type='toggle-status'><em class="fa fa-check" aria-hidden="true"></em></button>`;

                        return `<button class='btn' type='button' title='View Detail' action-type='view'><em class="fa fa-eye" aria-hidden="true"></em></button>
                                ${statusHtml}
                                <button class='btn' type='button' title='Edit' action-type='edit'><em class="fa fa-edit" aria-hidden="true"></em></button>
                                <button class='btn' type='button' title='Delete' action-type='delete'><em class="fa fa-trash" aria-hidden="true"></em></button>
                            `;
                    }
                }
            ],
            rowCallback: function (row, data: OperationCodeDetailModel) {
                const _row = $(row);

                _row.find('[action-type="link-detail"],[action-type="view"]')
                    .off('click')
                    .on('click', function () {
                        self.router.navigate(['op-code', data.id, 'detail']);
                    });

                _row.find('[action-type="edit"]')
                    .off('click')
                    .on('click', function () {
                        self.router.navigate(['op-code', data.id, 'edit']);
                    });

                _row.find('[action-type="delete"]')
                    .off('click')
                    .on('click', function () {
                        self.openOperationCodeDeleteModal(data);
                    });

                _row.find('[action-type="toggle-status"]')
                    .off('click')
                    .on('click', function () {
                        self.openOperationCodeStatusModal(data);
                    });
            }
        };
    }

    openOperationCodeDeleteModal(data: OperationCodeDetailModel) {
        this.deleteConfirmationModal.openModal(data);
    }

    delete(operationCode: OperationCodeDetailModel) {
        this.blockUI.start();
        this.operationCodeService.delete(operationCode.id).subscribe(
            () => {
                setTimeout(() => {
                    this.dtInstance.ajax.reload(null, false);
                });
                this.messagingService.success(`OP Code has been deleted successfully.`);
                this.blockUI.stop();
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
                this.blockUI.stop();
            });
    }

    openOperationCodeStatusModal(data: OperationCodeDetailModel) {
        this.statusChangeConfirmationModal.openModal(data);
    }

    toggle(operationCode: OperationCodeDetailModel) {
        this.blockUI.start();
        this.operationCodeService.toggleStatus(operationCode.id).subscribe(
            () => {
                setTimeout(() => {
                    this.dtInstance.ajax.reload(null, false);
                });
                this.messagingService.success(`OP Code has been ${(operationCode.isActive ? 'deactivated' : 'activated')} successfully.`);
                this.blockUI.stop();
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
                this.blockUI.stop();
            });
    }
}
