<div class="gnrlformSec">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <a class="backtolist" (click)="navigateToManageStore()">
          <em class="fa fa-long-arrow-left" aria-hidden="true"></em> Back to list
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12 col-lg-8 col-xl-8 offset-xl-2 offset-lg-2">
        <div class="gnrlformbox" *blockUI="'container-blockui-store'">
          <h2>Create Store</h2>
          <form class="matrixFrom" [formGroup]="frmStore" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <div class="matrixFromLabel">
                <h5>Auto Group Name</h5>
              </div>
              <div class="matrixFromField">
                <input type="text" class="form-control" formControlName="autoGroupName" [ngbTypeahead]="autoGroupSearch"
                  [resultFormatter]="formatter" (focus)="focus$.next($any($event).target.value)"
                  (click)="click$.next($any($event).target.value)" #instance="ngbTypeahead"
                  [ngClass]="{ 'is-invalid': submitted && frmStore.controls.autoGroupName.errors }" />
                <div *ngIf="submitted && frmStore.controls.autoGroupName.errors" class="invalid-feedback">
                  <div *ngIf="frmStore.controls.autoGroupName.errors.required">
                    Auto group name is required
                  </div>
                  <div *ngIf="frmStore.controls.autoGroupName.errors.maxlength">
                    Auto group name should be max
                    {{frmStore.controls.autoGroupName.errors.maxlength.requiredLength}}
                    characters
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="matrixFromLabel">
                <h5>Store Name</h5>
              </div>
              <div class="matrixFromField">
                <input type="text" class="form-control" formControlName="name"
                  [ngClass]="{ 'is-invalid': submitted && frmStore.controls.name.errors }" />
                <div *ngIf="submitted && frmStore.controls.name.errors" class="invalid-feedback">
                  <div *ngIf="frmStore.controls.name.errors.required">
                    Store name is required
                  </div>
                  <div *ngIf="frmStore.controls.name.errors.maxlength">
                    Store name should be max
                    {{frmStore.controls.name.errors.maxlength.requiredLength}}
                    characters
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="matrixFromLabel">
                <h5>Street Line 1</h5>
              </div>
              <div class="matrixFromField">
                <input type="text" class="form-control" formControlName="streetAddress"
                  [ngClass]="{ 'is-invalid': submitted && frmStore.controls.streetAddress.errors }" />
                <div *ngIf="submitted && frmStore.controls.streetAddress.errors" class="invalid-feedback">
                  <div *ngIf="frmStore.controls.streetAddress.errors.required">Street
                    Line 1 is required</div>
                  <div *ngIf="frmStore.controls.streetAddress.errors.maxlength">
                    Street line 1 should be max
                    {{frmStore.controls.streetAddress.errors.maxlength.requiredLength}}
                    characters
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="matrixFromLabel">
                <h5>Street Line 2</h5>
              </div>
              <div class="matrixFromField">
                <input type="text" class="form-control" formControlName="blockAddress"
                  [ngClass]="{ 'is-invalid': submitted && frmStore.controls.blockAddress.errors }" />
                <div *ngIf="submitted && frmStore.controls.blockAddress.errors" class="invalid-feedback">
                  <div *ngIf="frmStore.controls.blockAddress.errors.maxlength">
                    Street line 1 should be max
                    {{frmStore.controls.blockAddress.errors.maxlength.requiredLength}}
                    characters
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="matrixFromLabel">
                <h5>City</h5>
              </div>
              <div class="matrixFromField">
                <input type="text" class="form-control" formControlName="city"
                  [ngClass]="{ 'is-invalid': submitted && frmStore.controls.city.errors }" />
                <div *ngIf="submitted && frmStore.controls.city.errors" class="invalid-feedback">
                  <div *ngIf="frmStore.controls.city.errors.required">
                    City is required
                  </div>
                  <div *ngIf="frmStore.controls.city.errors.maxlength">
                    City should be max
                    {{frmStore.controls.city.errors.maxlength.requiredLength}}
                    characters
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="matrixFromLabel">
                <h5>State</h5>
              </div>
              <div class="matrixFromField">
                <select class="form-control hDropDown" formControlName="state"
                  [ngClass]="{ 'is-invalid': submitted && frmStore.controls.state.errors }">
                  <option value="">Select State</option>
                  <option *ngFor="let item of states" [value]="item.shortName">{{item.shortName}}
                  </option>
                </select>
                <div *ngIf="submitted && frmStore.controls.state.errors" class="invalid-feedback">
                  <div *ngIf="frmStore.controls.state.errors.required">State is required
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="matrixFromLabel">
                <h5>Country</h5>
              </div>
              <div class="matrixFromField">
                <select class="form-control hDropDown" formControlName="country"
                  [ngClass]="{ 'is-invalid': submitted && frmStore.controls.country.errors }">
                  <option value="">Select Country</option>
                  <option *ngFor="let item of countries" [value]="item.shortName">
                    {{item.shortName}}
                  </option>
                </select>
                <div *ngIf="submitted && frmStore.controls.country.errors" class="invalid-feedback">
                  <div *ngIf="frmStore.controls.country.errors.required">Country is
                    required</div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="matrixFromLabel">
                <h5>ZIP Code</h5>
              </div>
              <div class="matrixFromField">
                <input type="text" class="form-control" formControlName="zipCode"
                  [ngClass]="{ 'is-invalid': submitted && frmStore.controls.zipCode.errors }" />
                <div *ngIf="submitted && frmStore.controls.zipCode.errors" class="invalid-feedback">
                  <div *ngIf="frmStore.controls.zipCode.errors.required">
                    ZIP Code is required
                  </div>
                  <div
                    *ngIf="frmStore.controls.zipCode.errors.pattern || frmStore.controls.zipCode.errors.minlength || frmStore.controls.zipCode.errors.maxlength">
                    ZIP Code should be valid
                  </div>
                  <div *ngIf="frmStore.controls.zipCode.errors.maxlength">
                    Zip code should be max
                    {{frmStore.controls.zipCode.errors.maxlength.requiredLength}} digit
                  </div>
                </div>
              </div>
            </div>
            
            <div class="form-group">
              <div class="matrixFromLabel">
                  <h5>Phone Number</h5>
              </div>
              
              <div class="matrixFromField">
              <div class="row">
              <div class="col-4">
                  <select class="form-control hDropDown" formControlName = "phoneCode"
                  [ngClass]="{'is-invalid':submitted && frmStore.controls.phoneCode.errors}">
                      <option *ngFor="let item of countries " [value]="item.phoneCode">{{item.phoneCode}} </option>
                  </select>
              </div>
              <div class="col-8">
                  <input type="text" class="form-control" formControlName="phoneNumber" placeholder="Phone Number"
                      [ngClass]="{ 'is-invalid': submitted && frmStore.controls.phoneNumber.errors }" />
                  <div *ngIf="submitted && frmStore.controls.phoneNumber.errors" class="invalid-feedback">
                      <div *ngIf="frmStore.controls.phoneNumber.errors.required">Phone Number
                          is required</div>
                      <div *ngIf="frmStore.controls.phoneNumber.errors.pattern">Phone Number
                          should be valid format i.e. XXXXXXXXXX</div>
                      <div *ngIf="frmStore.controls.phoneNumber.errors.maxlength">
                          Phone Number should be max
                          {{form.controls.phoneNumber.errors.maxlength.requiredLength}} characters
                      </div>
                  </div>
              </div>
              </div>
              </div>
          </div>


            <div class="form-group">
              <div class="matrixFromLabel">
                <h5>Store VAuto ID</h5>
              </div>
              <div class="matrixFromField">
                <input type="text" class="form-control" formControlName="vAutoStoreId"
                  [ngClass]="{ 'is-invalid': submitted && frmStore.controls.vAutoStoreId.errors }" />
                <div *ngIf="submitted && frmStore.controls.vAutoStoreId.errors" class="invalid-feedback">
                  <div *ngIf="frmStore.controls.vAutoStoreId.errors.required">
                    Store VAuto ID is required
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="matrixFromLabel">
                <h5>Invoicing Email</h5>
              </div>
              <div class="matrixFromField">
                <input type="text" class="form-control" formControlName="invocingEmail"
                  [ngClass]="{ 'is-invalid': submitted && frmStore.controls.invocingEmail.errors }" />
                <div *ngIf="submitted && frmStore.controls.invocingEmail.errors" class="invalid-feedback">
                  <div *ngIf="frmStore.controls.invocingEmail.errors.required">
                    Invoicing Email is required
                  </div>
                  <div *ngIf="frmStore.controls.invocingEmail.errors.email">
                    Invalid invoicing email
                  </div>
                  <div *ngIf="frmStore.controls.invocingEmail.errors.maxlength">
                    Invalid invoicing email should be max
                    {{frmStore.zipCode.errors.maxlength.requiredLength}} characters
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="matrixFromLabel">
                <h5>Managing Company</h5>
              </div>
              <div class="matrixFromField">
                <select class="form-control hDropDown" formControlName="companyId"
                  [ngClass]="{ 'is-invalid': submitted && frmStore.controls.companyId.errors }">
                  <option value="">Select Managing Company</option>
                  <option *ngFor="let item of companies" [value]="item.id">
                    {{item.name}}
                  </option>
                </select>
                <div *ngIf="submitted && frmStore.controls.companyId.errors" class="invalid-feedback">
                  <div *ngIf="frmStore.controls.companyId.errors.required">Managing Company is
                    required</div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="matrixFromLabel">
                <h5>Invoicing Structure</h5>
              </div>
              <div class="matrixFromField">
                <span><input type="radio" formControlName="invoicingStructure" [value]="InvoicingStructure.Consolidate"
                    id="consolidate"><label for="consolidate"> Consolidate</label></span>
                <span><input type="radio" formControlName="invoicingStructure" [value]="InvoicingStructure.Direct"
                    id="direct"><label for="direct">
                    Direct</label></span>
              </div>
            </div>
            <div class="form-group">
              <div class="matrixFromLabel">
                <h5>Invoicing Preference </h5>
              </div>
              <div class="matrixFromField">
                <span>
                  <input type="radio" formControlName="invoicingPreference" [value]="InvoicingPreference.SingleInvoice"
                    id="singleInvoice">
                  <label for="singleInvoice"> Single Invoice</label>
                </span>
                <span>
                  <input type="radio" formControlName="invoicingPreference" [value]="InvoicingPreference.BatchInvoice"
                    id="batchInvoice">
                  <label for="batchInvoice"> Batch Invoice</label>
                </span>
              </div>
            </div>
            <div class="form-group">
              <div class="matrixFromLabel">
                <h5>Category </h5>
              </div>
              <div class="matrixFromField">
                <ng-multiselect-dropdown [placeholder]="'Select Category'" [data]="categories"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedCategories"
                  [settings]="categoryDropdownSettings"
                  [ngClass]="{ 'is-invalid': submitted && selectedCategories.length == 0 }"
                  (onSelect)="onCategorySelect()" (onDeSelect)="onCategorySelect()"
                  (onSelectAll)="onCategorySelectAll()" (onDeSelectAll)="onCategoryDeSelectAll()">
                </ng-multiselect-dropdown>
                <div *ngIf="submitted && selectedCategories.length == 0" class="invalid-feedback">
                  Category is required
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="matrixFromLabel">
                <h5>Tool</h5>
              </div>
              <div class="matrixFromField">
                <ng-multiselect-dropdown [placeholder]="'Select Tool'" [data]="filteredSubCategories"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedSubCategories"
                  [settings]="subCategoryDropdownSettings"
                  [ngClass]="{ 'is-invalid': submitted && selectedSubCategories.length == 0 }">
                </ng-multiselect-dropdown>
              </div>
            </div>
            <div class="btnWrp">
              <button class="btn btn-red" type="submit">Save</button>
              <button class="btn btn-tsrp" type="button" (click)="navigateToManageStore()">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
