/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact.us.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./contact.us.component";
var styles_ContactUsComponent = [i0.styles];
var RenderType_ContactUsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactUsComponent, data: {} });
export { RenderType_ContactUsComponent as RenderType_ContactUsComponent };
export function View_ContactUsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Contact Us\n"]))], null, null); }
export function View_ContactUsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contact-us", [], null, null, null, View_ContactUsComponent_0, RenderType_ContactUsComponent)), i1.ɵdid(1, 49152, null, 0, i2.ContactUsComponent, [], null, null)], null, null); }
var ContactUsComponentNgFactory = i1.ɵccf("app-contact-us", i2.ContactUsComponent, View_ContactUsComponent_Host_0, {}, {}, []);
export { ContactUsComponentNgFactory as ContactUsComponentNgFactory };
