
<div class="gnrlformSec">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <a class="backtolist" [routerLink]="['/manage/sub-category']">
                    <em class="fa fa-long-arrow-left" aria-hidden="true"></em> Back to list
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-12 col-lg-8 col-xl-8 offset-xl-2 offset-lg-2">
                <div class="gnrlformbox" *blockUI="'blockui-container'">
                    <h2>Update Tool</h2>
                    <form class="matrixFrom" [formGroup]="frmSubCategory" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <div class="matrixFromLabel">
                                <h5>Category</h5>
                            </div>
                            <div class="matrixFromField">
                                <select class="form-control hDropDown" formControlName="categoryId" readonly
                                    [ngClass]="{ 'is-invalid': submitted && frmSubCategory.controls.categoryId.errors }">
                                    <option value="">Select Category</option>
                                    <option *ngFor="let item of categories" [value]="item.id">
                                        {{item.name}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && frmSubCategory.controls.categoryId.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="frmSubCategory.controls.categoryId.errors.required">
                                        Category is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="matrixFromLabel">
                                <h5>Name</h5>
                            </div>
                            <div class="matrixFromField">
                                <input type="text" class="form-control" formControlName="name"
                                    [ngClass]="{ 'is-invalid': submitted && frmSubCategory.controls.name.errors }" />
                                <div *ngIf="submitted && frmSubCategory.controls.name.errors" class="invalid-feedback">
                                    <div *ngIf="frmSubCategory.controls.name.errors.required">
                                        Tool name is required
                                    </div>
                                    <div *ngIf="frmSubCategory.controls.name.errors.maxlength">
                                        Tool name should be max
                                        {{frmSubCategory.controls.name.errors.maxlength.requiredLength}}
                                        characters
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="matrixFromLabel">
                                <h5>Description</h5>
                            </div>
                            <div class="matrixFromField">
                                <input type="text" class="form-control" formControlName="description"
                                    [ngClass]="{ 'is-invalid': submitted && frmSubCategory.controls.description.errors }" />
                                <div *ngIf="submitted && frmSubCategory.controls.description.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="frmSubCategory.controls.description.errors.maxlength">
                                        Description should be max
                                        {{frmSubCategory.controls.description.errors.maxlength.requiredLength}}
                                        characters
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="matrixFromLabel">
                                <h5>Code</h5>
                            </div>
                            <div class="matrixFromField">
                                <input type="text" class="form-control" formControlName="code" [readonly]="!canEdit"
                                    [ngClass]="{ 'is-invalid': submitted && frmSubCategory.controls.code.errors }" />
                                <div *ngIf="submitted && frmSubCategory.controls.code.errors" class="invalid-feedback">
                                  <div *ngIf="frmSubCategory.controls.code.errors.required">
                                    Code is required
                                  </div>
                                  <div *ngIf="frmSubCategory.controls.code.errors.maxlength">
                                    Code should be max
                                    {{frmSubCategory.controls.code.errors.maxlength.requiredLength}}
                                    characters
                                  </div>
                                  <div *ngIf="frmSubCategory.controls.code.errors.minlength">
                                    Code should be min {{frmSubCategory.controls.code.errors.minlength.requiredLength}}
                                    characters
                                  </div>
                                  <div *ngIf="frmSubCategory.controls.code.errors.pattern">
                                    Only Alpha-numeric characters are allowed
                                  </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="matrixFromLabel">
                                <h5>IVL Rank</h5>
                            </div>
                            <div class="matrixFromField">
                                <input type="number" class="form-control" formControlName="sequence" min="0"
                                    [ngClass]="{ 'is-invalid': submitted && frmSubCategory.controls.sequence.errors }" />
                                <div *ngIf="submitted && frmSubCategory.controls.sequence.errors" class="invalid-feedback">
                                    <div *ngIf="frmSubCategory.controls.sequence.errors.required">
                                        IVL Rank is required
                                    </div>
                                    <div *ngIf="frmSubCategory.controls.sequence.errors.min">
                                        IVL Rank should be min 1
                                    </div>
                                    <div *ngIf="frmSubCategory.controls.sequence.errors.pattern">
                                        Only numeric are allowed
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="btnWrp">
                            <button class="btn btn-red" type="submit">Save</button>
                            <button class="btn btn-tsrp" type="button"
                                [routerLink]="['/manage/sub-category']">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
