import { NotificationType } from "src/app/enums";

export class UserModel {
    public id: string;
    public email: string;
    public name: string;
    public phoneCode: string;
    public phoneNumber: string;
    public isSMSNotificationActive: boolean;
    public isEmailNotificationActive: boolean;
}