import { VehicleAgeType, VehicleQueueStatus, VehicleType, VehicleVideoType } from "src/app/enums";
import { VehicleQueueNoteModel, VehicleQueueStageModel } from "..";

export class VehicleQueueModel {
    public id: number;
    public status: VehicleQueueStatus;
    public storeId: number;
    public workflowId: number;
    public vAutoId: string;
    public vehicleIdentificationNumber: string;
    public year: number;
    public make: string;
    public model: string;
    public modelNumber: string;
    public series: string;
    public repairOrderNumber: string;
    public color: string;
    public basicColor: string;
    public stockNumber: string;
    public mileage: number;
    public inventoryDate: Nullable<Date>;
    public vehicleAgeType: VehicleAgeType;
    public body: string;
    public startDate: Nullable<Date>;
    public endDate: Nullable<Date>;
    public reconditioningAgeInSeconds: Nullable<number>;
    public vehicleQueueStages: Array<VehicleQueueStageModel>;
    public vehicleQueueNotes: Array<VehicleQueueNoteModel>;
    public storeName: string;
    public checkInTypeName: string;
    public vehicleType: VehicleType;
    public vehicleVideos: Array<VehicleVideoModel>;
    public workedStagecatIds: Array<number>;
    public imageUrl: string;
    constructor() {
        this.vehicleVideos = new Array<VehicleVideoModel>();
    }
}

export class VehicleVideoModel {
    public id: number;
    public fileName: string;
    public vehicleVideoType: VehicleVideoType;
}