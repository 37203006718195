<section class="loginSec">
  <div class="container">
    <div class="loginLogo"><a href="#"><img [src]="logo" alt="Recon Partners Logo" /></a></div>
    <div class="loginWrp" *blockUI="'blockui-frm-login'">
      <form class="loginform" [formGroup]="form" (ngSubmit)="onSubmit()">
        <h2>Sign In</h2>
        <div class="form-group">
          <div class="labelWrp"><label>User Name/Email</label></div>
          <div class="fieldWrp">
            <input type="text" formControlName="userName" class="form-control" autocomplete="off"
              [ngClass]="{ 'is-invalid': submitted && form.controls.userName.errors }" />
            <div *ngIf="submitted && form.controls.userName.errors" class="invalid-feedback">
              <div *ngIf="form.controls.userName.errors.required">User Name/Email Id is required</div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="labelWrp">
            <label>Password</label>
          </div>
          <div class="fieldWrp">
            <input type="password" formControlName="password" class="form-control" autocomplete="off"
              [ngClass]="{ 'is-invalid': submitted && form.controls.password.errors }" />
            <div *ngIf="submitted && form.controls.password.errors" class="invalid-feedback">
              <div *ngIf="form.controls.password.errors.required">Password is required</div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <button class="btn btn-primary">Log In</button>
        </div>
      </form>
      <div class="forgotKpLogin">
        <div class="forgotpLink">
          <a [routerLink]="['/account/forgot-password']">Forgot password?</a>
        </div>
      </div>
    </div>
    <div class="wellcomDashboard">
      <h1>Welcome to <strong>My Dashboard</strong></h1>
    </div>
    <div class="copyright">
      <p>Copyright &copy; 2020. All rights reserved.</p>
    </div>
  </div>
</section>