import { ApplicationRoleType } from "src/app/enums";

export class RoleAddModel {
    public name: string;
    public permissions: Array<number>;
    public applicationRoleType: ApplicationRoleType;

    constructor() {
        this.permissions = new Array<number>();
    }
}