import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PriceMatrixService {
    constructor(http) {
        this.http = http;
    }
    query(dataTablesParameters) {
        return this.http.post(`${environment.apiBaseUrl}/price-matrix/query`, dataTablesParameters);
    }
    get(id) {
        return this.http.get(`${environment.apiBaseUrl}/price-matrix/${id}`);
    }
    add(data) {
        return this.http.post(`${environment.apiBaseUrl}/price-matrix`, data);
    }
    update(data) {
        return this.http.put(`${environment.apiBaseUrl}/price-matrix`, data);
    }
    delete(id) {
        return this.http.delete(`${environment.apiBaseUrl}/price-matrix/${id}`);
    }
    toggleStatus(id) {
        return this.http.patch(`${environment.apiBaseUrl}/price-matrix/toggle-status/${id}`, null);
    }
    getPriceOfSelectedOpCode(storeId, workflowId, opCodeIds) {
        return this.http.post(`${environment.apiBaseUrl}/price-matrix/store/${storeId}/workflow/${workflowId}/selected-op-code`, opCodeIds);
    }
    getAllPriceMatricesByUnitofMeasure(storeId, workflowId, unitOfMeasure) {
        return this.http.get(`${environment.apiBaseUrl}/price-matrix/unit-measure/${unitOfMeasure}/store/${storeId}/workflow/${workflowId}`);
    }
    getPriceMatrixByCategory(storeId, workflowId, categoryName) {
        return this.http.get(`${environment.apiBaseUrl}/price-matrix/store/${storeId}/workflow/${workflowId}/category?categoryName=${categoryName}`);
    }
}
PriceMatrixService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PriceMatrixService_Factory() { return new PriceMatrixService(i0.ɵɵinject(i1.HttpClient)); }, token: PriceMatrixService, providedIn: "root" });
