/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./play-video.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng-block-ui";
import * as i3 from "@angular/common";
import * as i4 from "./play-video.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../../services/storage.service";
var styles_PlayVideoComponent = [i0.styles];
var RenderType_PlayVideoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PlayVideoComponent, data: {} });
export { RenderType_PlayVideoComponent as RenderType_PlayVideoComponent };
function View_PlayVideoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn-fav"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "em", [["class", "fa fa-play"]], null, null, null, null, null))], null, null); }
function View_PlayVideoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "playbtn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Play Video "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "Play"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.playImg; _ck(_v, 3, 0, currVal_0); }); }
function View_PlayVideoComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "modal-body commentPopupWrp"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [["videoTag", 1]], null, 0, "video", [["controls", ""], ["controlsList", "nodownload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "buttonWrp"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "btn btn-tsrp"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPlayRecording(i1.ɵnov(_v, 2)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Play"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-tsrp"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], null, null); }
function View_PlayVideoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlayVideoComponent_4)), i1.ɵdid(1, 212992, null, 0, i2.ɵf, [i2.BlockUIService, i2.ɵc, i1.ViewContainerRef, i1.TemplateRef, i1.Renderer2, i1.ComponentFactoryResolver], { blockUI: [0, "blockUI"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = "container-blockui"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PlayVideoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { playVideoModal: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlayVideoComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlayVideoComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [[1, 2], ["playVideoModal", 2]], null, 0, null, View_PlayVideoComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.recordingFileName && _co.isFavIcon); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.recordingFileName && !_co.isFavIcon); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_PlayVideoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-play-video", [], null, null, null, View_PlayVideoComponent_0, RenderType_PlayVideoComponent)), i1.ɵdid(1, 245760, null, 0, i4.PlayVideoComponent, [i5.NgbModal, i6.StorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PlayVideoComponentNgFactory = i1.ɵccf("app-play-video", i4.PlayVideoComponent, View_PlayVideoComponent_Host_0, { recordingFileName: "recordingFileName", isFavIcon: "isFavIcon" }, {}, []);
export { PlayVideoComponentNgFactory as PlayVideoComponentNgFactory };
