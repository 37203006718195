import { Component } from '@angular/core';

@Component({
  selector: 'app-invoice-view',
  templateUrl: './invoice-view.component.html',
  styleUrls: ['./invoice-view.component.css']
})
export class InvoiceViewComponent{
  audiCar: string="/assets/images/audi.png";
  mercedes1: string="/assets/images/mercedes1.png";
  Mercedes2: string="/assets/images/Mercedes2.png";
  chart: string="/assets/images/chart.png";


    prinImg: string ="assets/images/print.png";
    downloadImg: string ="assets/images/download2.png";
  
  
}
