<section class="gnrlformSec" *blockUI="'container-blockui'">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-12 col-lg-6">
                <h2 class="approvalH">Approval Form</h2>
            </div>

            <div class="col-12 col-md-12 col-lg-6 text-right">
                <app-queue-timer [vehicleQueueStageModel]="vehicleQueueStageModel"></app-queue-timer>                
            </div>
        </div>
        <div class="tableWrp table-responsive">
            <app-vehicle-info [vehicleQueueModel]="vehicleQueueModel" [showFullVin]="false"></app-vehicle-info>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="gnrlformbox">

                    <div class="gnrlformDtl">
                        <form class="matrixFrom" [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div *ngIf="getQuotes().length">

                                <ng-container formArrayName="quotes">
                                    <div class="quoteWrp" *ngFor="let quote of getQuotes(); let i= index;" [formGroupName]="i">
                                        <h4>{{quote.controls.subCategoryName.value}}</h4>
                                        <div style="color:#dc3545"
                                            *ngIf="submitted && quote.get('vendorQuotations').errors">
                                            <div *ngIf="quote.get('vendorQuotations').errors.required">
                                                Please select a quotation to approve
                                            </div>
                                        </div>

                                        <ngb-accordion formArrayName="vendorQuotations">
                                            <ngb-panel
                                                *ngFor="let vendorQuotation of quote.get('vendorQuotations').controls;let j=index;"
                                                [formGroupName]="j" [id]="vendorQuotation.controls.id.value">

                                                <ng-template ngbPanelTitle>
                                                    <div class="accordionBtnWrp">
                                                        <div class="quotelabel">
                                                            <h5>{{vendorQuotation.controls.vendorName.value}}</h5>
                                                        </div>
                                                        <div class="totalPrice">
                                                            Total PRICE : <span>$
                                                            {{vendorQuotation.controls.totalPrice.value| currency : 'USD' : '' : '1.2-2' }}</span>
                                                        </div>
                                                        <div class="approveicon accordionApprovalWrp">
                                                            <span (click)="$event.stopPropagation();">
                                                                <input type="radio"
                                                                    id="approve{{vendorQuotation.controls.id.value}}"
                                                                    [value]="vendorQuotation.controls.id.value"
                                                                    [name]="quote.controls.subCategoryIdentifier.value"
                                                                    (change)="onApprovalChange(quote, vendorQuotation.controls.id.value)">
                                                                <label
                                                                    for="approve{{vendorQuotation.controls.id.value}}">Approve</label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template ngbPanelContent>
                                                    <div class="tableWrp dispatchCostTbl table-responsive">
                                                        <table class="table dataTable table-striped"
                                                            summary="Quotation detail to be submitted by {{vendorQuotation.controls.vendorName.value}}">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Category</th>
                                                                    <th scope="col">Tool</th>
                                                                    <th scope="col">Panel</th>
                                                                    <th scope="col">Location</th>
                                                                    <th scope="col">Side</th>
                                                                    <th scope="col">OP Code</th>
                                                                    <th scope="col">Price ($)</th>
                                                                    <th scope="col">Comment</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody formArrayName="quotationItems">
                                                                <tr *ngFor="let item of vendorQuotation.get('quotationItems').controls; let k= index;"
                                                                    [formGroupName]="k">
                                                                    <td>{{item.controls.operationCodeDetailModel.value.categoryName}}
                                                                    </td>
                                                                    <td>{{item.controls.operationCodeDetailModel.value.subCategoryName}}
                                                                    </td>
                                                                    <td>{{item.controls.operationCodeDetailModel.value.carLayoutPanelName}}
                                                                    </td>
                                                                    <td>{{item.controls.operationCodeDetailModel.value.carLayoutLocationName}}
                                                                    </td>
                                                                    <td>{{item.controls.operationCodeDetailModel.value.carLayoutSideName}}
                                                                    </td>
                                                                    <td>{{item.controls.operationCodeDetailModel.value.name}}
                                                                    </td>
                                                                    <td>{{item.controls.price.value| currency : 'USD' : '' : '1.2-2'}}</td>
                                                                    <td>{{item.controls.comment.value}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </ng-template>

                                            </ngb-panel>
                                        </ngb-accordion>
                                    </div>
                                </ng-container>

                            </div>

                            <div class="quoteWrp" *ngIf="getMechanicalAdvisingItems().length">
                                <h4>Mechanical Advising</h4>
                                <ngb-accordion>
                                    <ngb-panel>
                                        <ng-template ngbPanelTitle>
                                            <div class="accordionBtnWrp">
                                                <div class="quotelabel">
                                                    <h5>Mechanical Advising</h5>
                                                </div>
                                                <div class="totalPrice">
                                                    Total PRICE : <span>$
                                                    {{form.controls.mechanicalAdvisingTotalPrice.value| currency : 'USD' : '' : '1.2-2' }}</span>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div class="tableWrp dispatchCostTbl table-responsive">
                                                <table class="table dataTable table-striped"
                                                    summary="Mechanical Advising Details">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Category</th>
                                                            <th scope="col">Tool</th>
                                                            <th scope="col">OP Code</th>
                                                            <th scope="col">Labor Price ($)</th>
                                                            <th scope="col">Part Price ($)</th>
                                                            <th scope="col">Comment</th>
                                                            <th scope="col" class="matrixFromFieldWrp">
                                                                <div class="yesNoQ">
                                                                    <span>
                                                                        <input type="radio" id="mech-advising-approve"
                                                                            [value]="ApprovalStatus.Accepted"
                                                                            formControlName="mechanicalAdvisingApprovalStatus"
                                                                            (change)="onMechanicalAdvisingHeaderChange(ApprovalStatus.Accepted)">
                                                                        <label for="mech-advising-approve">Y</label>
                                                                    </span>
                                                                    <span>
                                                                        <input type="radio" id="mech-advising-reject"
                                                                            [value]="ApprovalStatus.Rejected"
                                                                            formControlName="mechanicalAdvisingApprovalStatus"
                                                                            (change)="onMechanicalAdvisingHeaderChange(ApprovalStatus.Rejected)">
                                                                        <label for="mech-advising-reject">N</label>
                                                                    </span>
                                                                    <span>
                                                                        <input type="radio" id="mech-advising-question"
                                                                            [value]="ApprovalStatus.Question"
                                                                            formControlName="mechanicalAdvisingApprovalStatus"
                                                                            (change)="onMechanicalAdvisingHeaderChange(ApprovalStatus.Question)">
                                                                        <label for="mech-advising-question">?</label>
                                                                    </span>
                                                                </div>
                                                            </th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody formArrayName="mechanicalAdvisingItems">
                                                        <tr *ngFor="let item of getMechanicalAdvisingItems(); let i= index;"
                                                            [formGroupName]="i">
                                                            <td>{{item.controls.operationCodeDetailModel.value.categoryName}}
                                                            </td>
                                                            <td>{{item.controls.operationCodeDetailModel.value.subCategoryName}}
                                                            </td>
                                                            <td>{{item.controls.operationCodeDetailModel.value.name}}
                                                            </td>
                                                            <td>{{item.controls.laborPrice.value| currency : 'USD' : '' : '1.2-2'}}</td>
                                                            <td>{{item.controls.partsPrice.value| currency : 'USD' : '' : '1.2-2'}}</td>
                                                            <td>{{item.controls.comment.value}}</td>
                                                            <td class="matrixFromFieldWrp">
                                                                <div class="yesNoQ"
                                                                    [ngClass]="{ 'is-invalid': submitted && item.controls.approvalStatus.errors }">
                                                                    <span>
                                                                        <input type="radio"
                                                                            id="mech-advising-approve-{{i}}"
                                                                            [value]="ApprovalStatus.Accepted"
                                                                            formControlName="approvalStatus"
                                                                            (change)="onMechanicalAdvisingItemChange()">
                                                                        <label
                                                                            for="mech-advising-approve-{{i}}">Y</label>
                                                                    </span>
                                                                    <span>
                                                                        <input type="radio"
                                                                            id="mech-advising-reject-{{i}}"
                                                                            [value]="ApprovalStatus.Rejected"
                                                                            formControlName="approvalStatus"
                                                                            (change)="onMechanicalAdvisingItemChange()">
                                                                        <label
                                                                            for="mech-advising-reject-{{i}}">N</label>
                                                                    </span>
                                                                    <span>
                                                                        <input type="radio"
                                                                            id="mech-advising-question-{{i}}"
                                                                            [value]="ApprovalStatus.Question"
                                                                            formControlName="approvalStatus"
                                                                            (change)="onMechanicalAdvisingItemChange()">
                                                                        <label
                                                                            for="mech-advising-question-{{i}}">?</label>
                                                                    </span>
                                                                </div>
                                                                <div *ngIf="submitted && item.controls.approvalStatus.errors"
                                                                    class="invalid-feedback">
                                                                    <div
                                                                        *ngIf="item.controls.approvalStatus.errors.required">
                                                                        Approval is required.
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <input type="text" class="form-control"
                                                                    placeholder="Comment"
                                                                    formControlName="approverComment"
                                                                    [ngClass]="{ 'is-invalid': submitted && item.controls.approverComment.errors }" />
                                                                <div *ngIf="submitted && item.controls.approverComment.errors"
                                                                    class="invalid-feedback">

                                                                    <div
                                                                        *ngIf="item.controls.approverComment.errors.required">
                                                                        Comment are required
                                                                    </div>
                                                                    <div
                                                                        *ngIf="item.controls.approverComment.errors.maxlength">
                                                                        Comment should be maximum
                                                                        {{item.controls.approverComment.errors.maxlength.requiredLength}}
                                                                        characters
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </div>


                            <div class="quoteWrp" *ngIf="getAddedCosmeticsItems().length">
                                <h4>Added Cosmetics</h4>
                                <ngb-accordion>
                                    <ngb-panel>
                                        <ng-template ngbPanelTitle>
                                            <div class="accordionBtnWrp">
                                                <div class="quotelabel">
                                                    <h5>Added Cosmetics</h5>
                                                </div>
                                                <div class="totalPrice">
                                                    Total PRICE : <span>$
                                                    {{form.controls.addedCosmeticTotalPrice.value| currency : 'USD' : '' : '1.2-2' }} </span>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div class="tableWrp dispatchCostTbl table-responsive">
                                                <table class="table dataTable table-striped"
                                                    summary="Added Cosmetics Details">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Category</th>
                                                            <th scope="col">Tool</th>
                                                            <th scope="col">Panel</th>
                                                            <th scope="col">Location</th>
                                                            <th scope="col">Side</th>
                                                            <th scope="col">OP Code</th>
                                                            <th scope="col">Price ($)</th>
                                                            <th scope="col">Comment</th>
                                                            <th scope="col" class="matrixFromFieldWrp">
                                                                <div class="yesNoQ">
                                                                    <span>
                                                                        <input type="radio" id="added-cosmetic-approve"
                                                                            [value]="ApprovalStatus.Accepted"
                                                                            formControlName="addedCosmeticApprovalStatus"
                                                                            (change)="onAddedCosmeticHeaderChange(ApprovalStatus.Accepted)">
                                                                        <label for="added-cosmetic-approve">Y</label>
                                                                    </span>
                                                                    <span>
                                                                        <input type="radio" id="added-cosmetic-reject"
                                                                            [value]="ApprovalStatus.Rejected"
                                                                            formControlName="addedCosmeticApprovalStatus"
                                                                            (change)="onAddedCosmeticHeaderChange(ApprovalStatus.Rejected)">
                                                                        <label for="added-cosmetic-reject">N</label>
                                                                    </span>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody formArrayName="addedCosmeticsItems">
                                                        <tr *ngFor="let item of getAddedCosmeticsItems(); let i= index;"
                                                            [formGroupName]="i">
                                                            <td>{{item.controls.operationCodeDetailModel.value.categoryName}}
                                                            </td>
                                                            <td>{{item.controls.operationCodeDetailModel.value.subCategoryName}}
                                                            </td>
                                                            <td>{{item.controls.operationCodeDetailModel.value.carLayoutPanelName}}
                                                            </td>
                                                            <td>{{item.controls.operationCodeDetailModel.value.carLayoutLocationName}}
                                                            </td>
                                                            <td>{{item.controls.operationCodeDetailModel.value.carLayoutSideName}}
                                                            </td>
                                                            <td>{{item.controls.operationCodeDetailModel.value.name}}
                                                            <td>{{item.controls.totalPrice.value| currency : 'USD' : '' : '1.2-2'}}</td>
                                                            <td>{{item.controls.comment.value}}</td>
                                                            <td class="matrixFromFieldWrp">
                                                                <div class="yesNoQ"
                                                                    [ngClass]="{ 'is-invalid': submitted && item.controls.approvalStatus.errors }">
                                                                    <span>
                                                                        <input type="radio"
                                                                            id="added-cosmetic-approve-{{i}}"
                                                                            [value]="ApprovalStatus.Accepted"
                                                                            formControlName="approvalStatus"
                                                                            (change)="onAddedCosmeticItemChange()">
                                                                        <label
                                                                            for="added-cosmetic-approve-{{i}}">Y</label>
                                                                    </span>
                                                                    <span>
                                                                        <input type="radio"
                                                                            id="added-cosmetic-reject-{{i}}"
                                                                            [value]="ApprovalStatus.Rejected"
                                                                            formControlName="approvalStatus"
                                                                            (change)="onAddedCosmeticItemChange()">
                                                                        <label
                                                                            for="added-cosmetic-reject-{{i}}">N</label>
                                                                    </span>
                                                                </div>
                                                                <div *ngIf="submitted && item.controls.approvalStatus.errors"
                                                                    class="invalid-feedback">
                                                                    <div
                                                                        *ngIf="item.controls.approvalStatus.errors.required">
                                                                       Approval is required.
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </div>

                            <div class="form-group mt-4">
                                <div class="matrixFromLabel">
                                    <h5><strong>Total : <span>$ {{form.controls.totalPrice.value| currency : 'USD' : '' : '1.2-2'}}</span></strong> </h5>
                                </div>
                                <div class="matrixFromField plushWrp">
                                    <app-wholesale-button></app-wholesale-button>
                                    <button class="btn btn-tsrp">Approved</button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>