<section class="vehicleDtlSec">
  <div class="container-fluid">
    <div class="vehicleGraphWrp">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="vehicleQueue">
            <div class="vehicleQueueInner">
              <img [src]=vehicleinqueue alt="" />
              <span>100</span>
            </div>
            <h4>Vehicle in Queue</h4>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="vehivleGraph">
            <img [src]=vehicleGraph alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="vehicleDtlHeading">
          <h2>Vehicle Queue</h2>
        </div>
      </div>
    </div>
  </div>
  <div class="vehicleDtlTableSec">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="bluetableWrp table-responsive">
            <table class="table dataTable table-striped">
              <thead>
                <tr>
                  <th scope="col">Vehicle Age</th>
                  <th scope="col">Year</th>
                  <th scope="col">Make</th>
                  <th scope="col">Model</th>
                  <th scope="col">Color</th>
                  <th scope="col">VIN</th>
                  <th scope="col">Waiting Time</th>
                  <th scope="col">Total Time</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>3.0 Days</td>
                  <td>2010</td>
                  <td>Kia </td>
                  <td>Forte</td>
                  <td>White</td>
                  <td>41234567890</td>
                  <td></td>
                  <td></td>
                  <td><button class="btn btn-red">Resume</button></td>
                </tr>
                <tr>
                  <td>2.5 Days</td>
                  <td>2011</td>
                  <td>Audi</td>
                  <td>Q3</td>
                  <td>Black</td>
                  <td>42345434543</td>
                  <td></td>
                  <td></td>
                  <td><button class="btn btn-red">View More</button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
