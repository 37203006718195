var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI } from 'ng-block-ui';
import { VehicleLayoutType, PricingType } from 'src/app/enums';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { OperationCodeDetailModel } from 'src/app/models';
import { FilterStateService, MessagingService, OperationCodeService } from 'src/app/services';
export class OpCodeDetailComponent {
    constructor(router, route, messagingService, operationCodeService, filterStateService) {
        this.router = router;
        this.route = route;
        this.messagingService = messagingService;
        this.operationCodeService = operationCodeService;
        this.filterStateService = filterStateService;
        this.PricingType = PricingType;
        this.VehicleLayoutType = VehicleLayoutType;
        filterStateService.changeFilterModelStatues(FilterPageType.opCodeManage, true);
        this.model = new OperationCodeDetailModel();
        this.route.params.subscribe(params => {
            this.model.id = params.id;
        });
    }
    ngOnInit() {
        this.loadData();
    }
    loadData() {
        this.blockUI.start();
        this.operationCodeService.get(this.model.id).subscribe((data) => {
            this.model = data;
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    openOperationCodeDeleteModal() {
        this.deleteConfirmationModal.openModal(this.model);
    }
    delete(operationCode) {
        this.blockUI.start();
        this.operationCodeService.delete(operationCode.id).subscribe(() => {
            setTimeout(() => {
                this.router.navigate(['/manage/op-code']);
            });
            this.messagingService.success(`OP Code has been deleted successfully.`);
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    openOperationCodeStatusModal() {
        this.statusChangeConfirmationModal.openModal(this.model);
    }
    toggle(operationCode) {
        this.blockUI.start();
        this.operationCodeService.toggleStatus(operationCode.id).subscribe(() => {
            this.messagingService.success(`OP Code has been ${(operationCode.isActive ? 'deactivated' : 'activated')} successfully.`);
            this.blockUI.stop();
            this.model.isActive = !this.model.isActive;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
}
__decorate([
    BlockUI('container-blockui'),
    __metadata("design:type", Object)
], OpCodeDetailComponent.prototype, "blockUI", void 0);
