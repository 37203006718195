<div class="taskBox" [ngClass]="{ 'disabled': isAllCategoriesDisabled, 'highlight': hasMandatoryOPCodes}">
    <div class="taskBoxInner" *ngFor="let item of workflowStageModel.workflowStageCategories; let i = index;">
        <span class="taskContent">
            {{item.categoryName}} <span *ngIf="item.subCategoryName">- {{item.subCategoryName}}</span></span>

        <span class="taskIcon" *ngIf="i === 0">
            <button type="button" *ngIf="workflowStageModel.dependencyLocked" title="Dependency Locked">
                <em class="fa fa-lock" aria-hidden="true"></em></button>
            <button type="button" (click)="openWorkflowStepDetailModal()" title="View Detail"><em class="fa fa-eye"
                    aria-hidden="true"></em></button>
        </span>
    </div>
</div>

<app-detail-workflow-stage #detailModal [categories]='categories' [subCategories]='subCategories'>
</app-detail-workflow-stage>