import { VehicleAgeType,  VehicleType } from "src/app/enums";

export class VehicleImportFilterModel {
    public vehicleIdentificationNumber: string;
    public stockNumber: string;
    public companyId: Nullable<number>;
    public storeId: Nullable<number>;
    public vehicleAgeType: Nullable<VehicleAgeType>;
    public vehicleType: Nullable<VehicleType>;
    public onHold: Nullable<boolean>;
}