import { Injectable } from "@angular/core";

@Injectable()
export class StringLengthConstants {

    /// <summary>
    /// For Application User Id
    /// </summary>
    public ApplicationUserId = 450;

    /// <summary>
    /// For Default Length.
    /// </summary>
    public Default = 256;

    /// <summary>
    /// For user full name, file name, title etc.
    /// </summary>
    public Name = 256;

    /// <summary>
    /// For description field
    /// </summary>
    public Description = 1024;

    /// <summary>
    /// For any VIN coming from VAuto
    /// </summary>
    public VIN = 17;

    /// <summary>
    /// For State Postal Code
    /// </summary>
    public PostalCode = 20;

    /// <summary>
    /// For Phone Number
    /// </summary>
    public PhoneNumber = 20;

    /// <summary>
    /// For user Email
    /// </summary>
    public Email = 256;

    /// <summary>
    /// For user Email
    /// </summary>
    public TaxId = 15;

    /// <summary>
    /// Maximum Url length can be 2083
    /// </summary>
    public Url = 2083;

    /// <summary>
    /// Maximum short code length can 4
    /// </summary>
    public ShortCode = 4;

    public CountryCode =3;

}