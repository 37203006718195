var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI } from 'ng-block-ui';
import { InvoicingPreference, InvoicingStructure } from 'src/app/enums';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { AppUtils } from 'src/app/helpers';
import { StoreDetailModel, StoreListItemModel } from 'src/app/models';
import { ApplicationContextService, FilterStateService, MessagingService } from 'src/app/services';
import { StoreService } from 'src/app/services/store.service';
export class StoreDetailComponent {
    constructor(router, activeRoute, appUtils, messagingService, storeService, applicationContextService, filterStateService) {
        this.router = router;
        this.activeRoute = activeRoute;
        this.appUtils = appUtils;
        this.messagingService = messagingService;
        this.storeService = storeService;
        this.applicationContextService = applicationContextService;
        this.filterStateService = filterStateService;
        this.InvoicingStructure = InvoicingStructure;
        this.InvoicingPreference = InvoicingPreference;
        filterStateService.changeFilterModelStatues(FilterPageType.storeManage, true);
        this.activeRoute.params.subscribe(params => this.storeId = params.storeId);
        this.model = new StoreDetailModel();
    }
    ngOnInit() {
        this.loadStore();
    }
    loadStore() {
        this.blockUI.start();
        this.storeService.get(this.storeId).subscribe((data) => {
            this.model = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    goToEdit() {
        this.router.navigate(['edit', 'store', this.model.id]);
    }
    goToClone() {
        this.router.navigate(['clone', 'store', this.model.id]);
    }
    openStoreDeleteModal() {
        let data = new StoreListItemModel();
        data.id = this.model.id;
        this.deleteStore.openModal(data);
    }
    emitDeleteConfirmation(store) {
        this.blockUI.start();
        this.storeService.delete(store.id)
            .subscribe(() => {
            setTimeout(() => {
                this.navigateToManageStore();
            });
            this.messagingService.success(`Store has been deleted successfully.`);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    toggleStatus() {
        this.statusChangeConfirmationModal.openModal(this.model);
    }
    toggle(store) {
        this.blockUI.start();
        this.storeService.toggleStatus(store.id).subscribe(() => {
            this.messagingService.success(`Store has been ${(store.isActive ? 'deactivated' : 'activated')} successfully.`);
            this.model.isActive = !this.model.isActive;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    navigateToManageStore() {
        this.router.navigate(['manage', 'store']);
    }
    getConCatStringFromStringArray(values) {
        if (!values)
            return;
        return values.join(", ");
    }
}
__decorate([
    BlockUI('container-blockui-store'),
    __metadata("design:type", Object)
], StoreDetailComponent.prototype, "blockUI", void 0);
