import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { DataTableResponseModel, UserAddModel, UserDetailModel, UserEditModel, UserModel } from "../models";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(private http: HttpClient) {
    }

    public add(model: UserAddModel): Observable<UserDetailModel> {
        return this.http.post<UserDetailModel>(`${environment.apiBaseUrl}/user/add`, model);
    }

    public edit(model: UserEditModel) {
        return this.http.patch(`${environment.apiBaseUrl}/user/edit`, model);
    }

    public delete(id: string) {
        return this.http.delete(`${environment.apiBaseUrl}/user/${id}`);
    }

    public toggleStatus(id: string) {
        return this.http.patch(`${environment.apiBaseUrl}/user/toggle-status/${id}`, null);
    }

    public getMyProfile(): Observable<UserDetailModel> {
        return this.http.get<UserDetailModel>(`${environment.apiBaseUrl}/user/my/profile`);
    }

    public getUserDetail(id: string): Observable<UserDetailModel> {
        return this.http.get<UserDetailModel>(`${environment.apiBaseUrl}/user/${id}`);
    }

    public getAccountManagers(): Observable<UserModel[]> {
        return this.http.get<UserModel[]>(`${environment.apiBaseUrl}/user/account-managers`);
    }

    public query(dataTablesParameters: any) {
        return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/user/query`, dataTablesParameters, {});
    }

    public updateProfile(model: UserModel) {
        return this.http.patch(`${environment.apiBaseUrl}/user/profile`, model);
    }
}
