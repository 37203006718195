var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { FormBuilder, Validators } from '@angular/forms';
import { BlockUI } from 'ng-block-ui';
import { FilterStateService, MessagingService, RoleService } from 'src/app/services';
import { RoleEditModel } from 'src/app/models';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { ApplicationRoleType } from 'src/app/enums';
import { forkJoin } from 'rxjs';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
export class RoleEditComponent {
    constructor(formBuilder, router, activeRoute, messagingService, roleService, stringLengthConstants, appUtils, filterStateService) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.activeRoute = activeRoute;
        this.messagingService = messagingService;
        this.roleService = roleService;
        this.stringLengthConstants = stringLengthConstants;
        this.appUtils = appUtils;
        this.filterStateService = filterStateService;
        this.submitted = false;
        filterStateService.changeFilterModelStatues(FilterPageType.roleManage, true);
        this.model = new RoleEditModel();
        this.activeRoute.params.subscribe(params => this.model.id = params['id']);
        this.applicationRoleTypes = this.appUtils.getApplicationRoleTypes();
    }
    ngOnInit() {
        this.roleFrm = this.formBuilder.group({
            id: this.model.id,
            roleName: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            permissions: this.formBuilder.array([]),
            applicationRoleType: [ApplicationRoleType.System]
        });
        this.loadData();
    }
    loadData() {
        this.blockUI.start();
        forkJoin([
            this.roleService.getAllPermissions(),
            this.roleService.get(this.model.id)
        ]).subscribe(([permissions, roleEditModel]) => {
            this.model = roleEditModel;
            this.roleFrm.controls.roleName.setValue(this.model.name);
            this.roleFrm.controls.applicationRoleType.setValue(this.model.applicationRoleType);
            const fa = this.roleFrm.get('permissions');
            fa.clear();
            permissions.forEach(p => {
                fa.push(this.formBuilder.group({
                    permission: [p.permission],
                    name: [p.name],
                    description: [p.description],
                    groupName: [p.groupName],
                    isSelected: [this.model.permissions.includes(p.permission)]
                }));
            });
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    onSubmit() {
        this.submitted = true;
        if (this.roleFrm.invalid)
            return;
        this.model.name = this.roleFrm.controls.roleName.value;
        this.model.permissions = this.roleFrm.value.permissions.filter(x => x.isSelected).map(x => x.permission);
        this.model.applicationRoleType = Number(this.roleFrm.controls.applicationRoleType.value);
        this.blockUI.start();
        this.roleService.update(this.model).subscribe(() => {
            setTimeout(() => {
                this.router.navigate(['/manage/role']);
            }, 10);
            setTimeout(() => {
                this.messagingService.success('Role has been updated successfully.');
            }, 300);
            this.blockUI.stop();
        }, (error) => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    onCancel() {
        this.router.navigate(['/manage/role']);
    }
}
__decorate([
    BlockUI('container-blockui-edit-role'),
    __metadata("design:type", Object)
], RoleEditComponent.prototype, "blockUI", void 0);
