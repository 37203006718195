<section class="gnrlformSec">
    <div class="container">
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <div class="gnrlformbox" *blockUI="'container-blockui-change-password'">
                    <div class="changepH">
                        <h2>Change password</h2>
                    </div>
                    <div class="gnrlformDtl">
                        <form class="matrixFrom" [formGroup]="frm" (ngSubmit)="onSubmit()">
                            <div class="form-group">
                                <div class="matrixFromLabel"><label>Old Password</label></div>
                                <div class="matrixFromField">
                                    <input type="password" formControlName="currentPassword" autocomplete="off"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && frm.controls.currentPassword.errors }" />
                                    <div *ngIf="submitted && frm.controls.currentPassword.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="frm.controls.currentPassword.errors.required">Current Password is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel"><label>New Password</label></div>
                                <div class="matrixFromField">
                                    <input type="password" formControlName="newPassword" autocomplete="off"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': (submitted && !passwordIsValid) || (submitted && frm.controls.newPassword.errors)}" />
                                    <small>
                                        Use combination of Captial, Small, Numbers and Special characters
                                    </small>
                                    <div *ngIf="submitted && frm.controls.newPassword.errors" class="invalid-feedback">
                                        <div *ngIf="frm.controls.newPassword.errors.required">New Password is required
                                        </div>
                                        <div *ngIf="frm.controls.newPassword.errors.minlength">New Password must be at
                                            least 6
                                            characters
                                        </div>
                                        <div *ngIf="frm.controls.newPassword.errors.maxlength">New Password must be at
                                            max 20
                                            characters
                                        </div>
                                        <div *ngIf="frm.controls.newPassword.errors.mustNotMatch">Current Password and
                                            New
                                            Password can't be same</div>
                                    </div>
                                    <app-password-strength [passwordToCheck]="frm.controls.newPassword.value"
                                        (passwordStrength)="passwordValid($event)"></app-password-strength>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel"><label>Confirm Password</label></div>
                                <div class="matrixFromField">
                                    <input type="password" formControlName="confirmPassword" autocomplete="off"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && frm.controls.confirmPassword.errors }" />
                                    <div *ngIf="submitted && frm.controls.confirmPassword.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="frm.controls.confirmPassword.errors.required">Confirm Password is
                                            required
                                        </div>
                                        <div *ngIf="frm.controls.confirmPassword.errors.mustMatch">Password and Confirm
                                            Password
                                            must match</div>
                                    </div>
                                </div>
                            </div>
                            <div class="btnWrp">
                                <button class="btn btn-red">Change Password</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>