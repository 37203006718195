<section class="gnrlformSec">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <a [routerLink]="['/manage/vendor']" class="backtolist"><em class="fa fa-long-arrow-left"
                        aria-hidden="true"></em> Back to list</a>
            </div>
        </div>
        <div class="row" *blockUI="'container-blockui-vendor'">
            <div class="col-12 col-md-12 col-lg-8 col-xl-8 offset-xl-2 offset-lg-2">
                <div class="gnrlformbox">
                    <h2>Create Vendor</h2>
                    <div class="gnrlformDtl">
                        <form class="matrixFrom" [formGroup]="frmAddVendor" (ngSubmit)="onSubmit()">
                            <h4>General Information</h4>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Vendor Name </h5>
                                </div>
                                <div class="matrixFromField">
                                    <input type="text" class="form-control" formControlName="name"
                                        placeholder="Enter Vendor Name"
                                        [ngClass]="{ 'is-invalid': submitted && frmAddVendor.controls.name.errors }" />
                                    <div *ngIf="submitted && frmAddVendor.controls.name.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="frmAddVendor.controls.name.errors.required">
                                            Name is required
                                        </div>
                                        <div *ngIf="frmAddVendor.controls.name.errors.maxlength">
                                            Name should be max
                                            {{frmAddVendor.controls.name.errors.maxlength.requiredLength}}
                                            characters
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Store </h5>
                                </div>
                                <div class="matrixFromField">
                                    <ng-multiselect-dropdown [placeholder]="'Select Stores'" [data]="storeSelectList"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedStores"
                                        [settings]="storeDropdownSettings"
                                        [ngClass]="{ 'is-invalid': submitted && selectedStores.length == 0 }">
                                    </ng-multiselect-dropdown>
                                    <div *ngIf="submitted && selectedStores.length == 0" class="invalid-feedback">
                                        Store is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group align-items-baseline">
                                <div class="matrixFromLabel">
                                    <h5>Address</h5>
                                </div>
                                <div class="matrixFromField assignto">
                                    <div class="row">
                                        <div class="col-6 mb-3">
                                            <input type="text" class="form-control" formControlName="streetAddress"
                                                placeholder="Street 1"
                                                [ngClass]="{ 'is-invalid': submitted && frmAddVendor.controls.streetAddress.errors }" />
                                            <div *ngIf="submitted && frmAddVendor.controls.streetAddress.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="frmAddVendor.controls.streetAddress.errors.required">Street
                                                    1 is required
                                                </div>
                                                <div *ngIf="frmAddVendor.controls.streetAddress.errors.maxlength">
                                                    Street 1 should be max
                                                    {{frmAddVendor.controls.streetAddress.errors.maxlength.requiredLength}}
                                                    characters
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 mb-3">
                                            <input type="text" class="form-control" formControlName="blockAddress"
                                                placeholder="Street 2"
                                                [ngClass]="{ 'is-invalid': submitted && frmAddVendor.controls.blockAddress.errors }" />
                                            <div *ngIf="submitted && frmAddVendor.controls.blockAddress.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="frmAddVendor.controls.blockAddress.errors.required">Street
                                                    2 is required
                                                </div>
                                                <div *ngIf="frmAddVendor.controls.blockAddress.errors.maxlength">
                                                    Street 2 should be max
                                                    {{frmAddVendor.controls.blockAddress.errors.maxlength.requiredLength}}
                                                    characters
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 mb-3">
                                            <input type="text" class="form-control" formControlName="city"
                                                placeholder="City"
                                                [ngClass]="{ 'is-invalid': submitted && frmAddVendor.controls.city.errors }" />
                                            <div *ngIf="submitted && frmAddVendor.controls.city.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="frmAddVendor.controls.city.errors.required">
                                                    City is required
                                                </div>
                                                <div *ngIf="frmAddVendor.controls.city.errors.maxlength">
                                                    City should be max
                                                    {{frmAddVendor.controls.city.errors.maxlength.requiredLength}}
                                                    characters
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 mb-3">
                                            <select class="form-control hDropDown" formControlName="state"
                                                [ngClass]="{ 'is-invalid': submitted && frmAddVendor.controls.state.errors }">
                                                <option value="">Select State</option>
                                                <option *ngFor="let item of states" [value]="item.shortName">
                                                    {{item.shortName}}
                                                </option>
                                            </select>
                                            <div *ngIf="submitted && frmAddVendor.controls.state.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="frmAddVendor.controls.state.errors.required">
                                                    State is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 mb-3">
                                            <input type="text" class="form-control" formControlName="zipCode"
                                                placeholder="ZIP"
                                                [ngClass]="{ 'is-invalid': submitted && frmAddVendor.controls.zipCode.errors }" />
                                            <div *ngIf="submitted && frmAddVendor.controls.zipCode.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="frmAddVendor.controls.zipCode.errors.required">
                                                    ZIP Code is required
                                                </div>
                                                <div
                                                    *ngIf="frmAddVendor.controls.zipCode.errors.pattern || frmAddVendor.controls.zipCode.errors.minlength || frmAddVendor.controls.zipCode.errors.maxlength">
                                                    ZIP Code should be valid
                                                </div>
                                                <div *ngIf="frmAddVendor.controls.zipCode.errors.maxlength">
                                                    Zip code should be max
                                                    {{frmAddVendor.controls.zipCode.errors.maxlength.requiredLength}}
                                                    characters
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 mb-3">
                                            <select class="form-control hDropDown" formControlName="country"
                                                [ngClass]="{ 'is-invalid': submitted && frmAddVendor.controls.country.errors }">
                                                <option *ngFor="let item of countries" [value]="item.shortName">
                                                    {{item.shortName}}
                                                </option>
                                            </select>
                                            <div *ngIf="submitted && frmAddVendor.controls.country.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="frmAddVendor.controls.country.errors.required">Country is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Tax ID</h5>
                                </div>
                                <div class="matrixFromField">
                                    <input type="text" class="form-control" formControlName="taxId"
                                        [ngClass]="{ 'is-invalid': submitted && frmAddVendor.controls.taxId.errors }" />
                                    <div *ngIf="submitted && frmAddVendor.controls.taxId.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="frmAddVendor.controls.taxId.errors.required">Tax ID is required
                                        </div>
                                        <div *ngIf="frmAddVendor.controls.taxId.errors.pattern">Tax ID should be numeric
                                        </div>
                                        <div *ngIf="frmAddVendor.controls.taxId.errors.maxlength">
                                            Tax id should be max
                                            {{frmAddVendor.controls.taxId.errors.maxlength.requiredLength}}
                                            characters or digits
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Name of Insurance Provider</h5>
                                </div>
                                <div class="matrixFromField">
                                    <input type="text" formControlName="insuranceProvider"
                                        placeholder="Insurance Provider" class="form-control"
                                        [ngClass]="{'is-invalid': submitted && frmAddVendor.controls.insuranceProvider.errors}" />
                                    <div *ngIf="submitted && frmAddVendor.controls.insuranceProvider.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="frmAddVendor.controls.insuranceProvider.errors.required">
                                            Insurance Provider is required
                                        </div>
                                        <div *ngIf="frmAddVendor.controls.insuranceProvider.errors.maxlength">
                                            Insurance Provider should be max
                                            {{frmAddVendor.controls.insuranceProvider.errors.maxlength.requiredLength}}
                                            characters
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Insurance Policy Number</h5>
                                </div>
                                <div class="matrixFromField">
                                    <input type="text" formControlName="insurancePolicy" placeholder="Insurance Policy Number"
                                        class="form-control"
                                        [ngClass]="{'is-invalid': submitted && frmAddVendor.controls.insurancePolicy.errors}" />
                                    <div *ngIf="submitted && frmAddVendor.controls.insurancePolicy.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="frmAddVendor.controls.insurancePolicy.errors.required">
                                            Insurance Policy Number is required
                                        </div>
                                        <div *ngIf="frmAddVendor.controls.insurancePolicy.errors.maxlength">
                                            Insurance Policy Number should be max
                                            {{frmAddVendor.controls.insurancePolicy.errors.maxlength.requiredLength}}
                                            characters
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Insurance Policy Expiration Date</h5>
                                </div>
                                <div class="matrixFromField">
                                    <div class="datePicker">
                                        <input class="form-control" placeholder="MM/DD/YYYY" [minDate]="minExpireDate"
                                            formControlName="insuranceExpireDate" ngbDatepicker #d="ngbDatepicker"
                                            [ngClass]="{'is-invalid': submitted && frmAddVendor.controls.insuranceExpireDate.errors}" />
                                        <div class="input-group-append">
                                            <button class="btn calendarBtn" (click)="d.toggle()" type="button"><img
                                                    [src]=calenderIcon /> </button>
                                        </div>
                                        <div *ngIf="submitted && frmAddVendor.controls.insuranceExpireDate.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="frmAddVendor.controls.insuranceExpireDate.errors.required">
                                                Insurance Expire Date is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Vendor Accounting Email </h5>
                                </div>
                                <div class="matrixFromField">
                                    <input type="text" class="form-control" formControlName="invoiceEmail"
                                        [ngClass]="{ 'is-invalid': submitted && frmAddVendor.controls.invoiceEmail.errors }" />
                                    <div *ngIf="submitted && frmAddVendor.controls.invoiceEmail.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="frmAddVendor.controls.invoiceEmail.errors.required">
                                            Vendor Accounting Email is required
                                        </div>
                                        <div *ngIf="frmAddVendor.controls.invoiceEmail.errors.email">
                                            Vendor Accounting Email should be valid
                                        </div>
                                        <div *ngIf="frmAddVendor.controls.invoiceEmail.errors.maxlength">
                                            Vendor Accounting email should be max
                                            {{frmAddVendor.controls.invoiceEmail.errors.maxlength.requiredLength}}
                                            characters
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Phone Number</h5>
                                </div>
                                <div class="matrixFromField">
                                <div class="row">
                                <div class="col-4">
                                    <select class="form-control hDropDown" formControlName = "phoneCode"
                                    [ngClass]="{'is-invalid':submitted && frmAddVendor.controls.phoneCode.errors}">
                                        <option *ngFor="let item of countries " [value]="item.phoneCode">{{item.phoneCode}} </option>
                                    </select>
                                </div>
                                <div class="col-8">
                                    <input type="text" class="form-control" formControlName="phoneNumber"
                                        placeholder="Phone Number"
                                        [ngClass]="{ 'is-invalid': submitted && frmAddVendor.controls.phoneNumber.errors }" />
                                    <div *ngIf="submitted && frmAddVendor.controls.phoneNumber.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="frmAddVendor.controls.phoneNumber.errors.required">Phone Number
                                            is required</div>
                                        <div *ngIf="frmAddVendor.controls.phoneNumber.errors.pattern">Phone Number
                                            should be valid format i.e. XXXXXXXXXX</div>
                                        <div *ngIf="frmAddVendor.controls.phoneNumber.errors.maxlength">
                                            Phone number should be max
                                            {{frmAddVendor.controls.phoneNumber.errors.maxlength.requiredLength}}
                                            digits
                                        </div>
                                    </div>
                                </div>
                                </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Invoice Method</h5>
                                </div>
                                <div class="matrixFromField">
                                    <span>
                                        <input type="radio" formControlName="invoicingPreference"
                                            [value]="InvoicingPreference.SingleInvoice" id="singleInvoice">
                                        <label for="singleInvoice"> Single Invoice</label>
                                    </span>
                                    <span>
                                        <input type="radio" formControlName="invoicingPreference"
                                            [value]="InvoicingPreference.BatchInvoice" id="batchInvoice">
                                        <label for="batchInvoice"> Batch Invoice</label>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Notification Method</h5>
                                </div>
                                <div class="matrixFromField">
                                    <span>
                                        <input class="form-check-input" id="chkNotificationTypeEmail" type="checkbox"
                                            formControlName="isEmailNotificationActive">
                                        <label class="form-check-label" for="chkNotificationTypeEmail">
                                            Email </label>
                                    </span>
                                    <span>
                                        <input class="form-check-input" id="chkNotificationTypeSMS" type="checkbox"
                                            formControlName="isSMSNotificationActive">
                                        <label class="form-check-label" for="chkNotificationTypeSMS">
                                            SMS </label>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>W9</h5>
                                </div>
                                <div class="matrixFromField">
                                    <button type="button" class="form-control attachBtn"
                                        (click)="documentAttachment.click()"
                                        [ngClass]="{ 'is-invalid': submitted && model.documents.length == 0 }">Choose
                                        your file</button>
                                    <input type="file" class="d-none" accept="*" #documentAttachment multiple
                                        (change)="onDocumentSelect($event)" />
                                    <div *ngIf="submitted && model.documents.length == 0" class="invalid-feedback">
                                        <div>Please upload W-9 document </div>
                                    </div>
                                    <div class="attachedFile" *ngIf="model.documents.length">
                                        <ul>
                                            <li *ngFor="let item of model.documents;">
                                                <div class="fileNameWrp">
                                                    <span class="fileName" [textContent]="item.originalFileName"></span>
                                                </div>
                                                <div class="downloadClose">
                                                    <a [href]="item.fileUrl" title="{{item.originalFileName}}"
                                                        download="{{item.originalFileName}}" target="_blank"
                                                        class="downloadAttch">
                                                        <img [src]="downloadIcon" alt="Download"/>
                                                    </a>
                                                    <a href="javascript:void(0)" class="deleteAttach"
                                                        (click)="deleteDocument(item, index)"
                                                        title="Delete {{item.originalFileName}}">
                                                        <em class="fa fa-times" aria-hidden="true"></em>
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Category </h5>
                                </div>
                                <div class="matrixFromField">
                                    <ng-multiselect-dropdown [placeholder]="'Select Category'" [data]="categories"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedCategories"
                                        [settings]="categoryDropdownSettings"
                                        [ngClass]="{ 'is-invalid': submitted && selectedCategories.length == 0 }"
                                        (onSelect)="onCategorySelect()" (onDeSelect)="onCategorySelect()"
                                        (onSelectAll)="onCategorySelectAll()" (onDeSelectAll)="onCategoryDeSelectAll()">
                                    </ng-multiselect-dropdown>
                                    <div *ngIf="submitted && selectedCategories.length == 0" class="invalid-feedback">
                                        Category is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Tool</h5>
                                </div>
                                <div class="matrixFromField">
                                    <ng-multiselect-dropdown [placeholder]="'Select Tool'"
                                        [data]="filteredSubCategories" [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="selectedSubCategories" [settings]="subCategoryDropdownSettings"
                                        [ngClass]="{ 'is-invalid': submitted && selectedSubCategories.length == 0 }">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <div class="btnWrp">
                                <button class="btn btn-red" type="submit">Save</button>
                                <button class="btn btn-tsrp" type="button"
                                    [routerLink]="['/manage/vendor']">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>