import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { FilterPageType } from "src/app/enums/filter.page.type.enum";
import { AppUtils } from "src/app/helpers";
import { CheckInTypeDetailModel } from "src/app/models";
import { CheckInTypeService, FilterStateService, MessagingService } from "src/app/services";
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-manage-check-in-type',
    templateUrl: './manage.check-in-type.component.html',
    styleUrls: ['./manage.check-in-type.component.css']
})

export class CheckInTypeManageComponent implements OnInit {
    frmSearch: FormGroup;
    filterOptions: any;
    statusOptions: any;
    //Data Table Options
    @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtInstance: DataTables.Api;
    rowIndex: number = 0;

    @BlockUI('container-blockui-grid') blockUI: NgBlockUI;
    @ViewChild('deleteConfirmationModal', { static: true }) deleteConfirmationModal: any;
    @ViewChild('statusChangeConfirmationModal', { static: true }) statusChangeConfirmationModal: any;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private messagingService: MessagingService,
        private checkInTypeService: CheckInTypeService,
        public appUtils: AppUtils,
        private filterStateService: FilterStateService
    ) {
        this.filterOptions = this.appUtils.getFilterOperationOptions();
        this.statusOptions = this.appUtils.getStatusOptions();

    }

    ngAfterViewInit() {

        this.updateFormFromFilterModel();

        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);
    }

    updateFormFromFilterModel() {
        const tempFilterModel = this.filterStateService.getFilterModel(FilterPageType.checkInTypeManage);
        if (tempFilterModel && tempFilterModel.isActive) {
            this.frmSearch.controls.name.setValue(tempFilterModel.filterKey);
            this.frmSearch.controls.filterOption.setValue(tempFilterModel.filterOption);
            this.frmSearch.controls.statusOption.setValue(tempFilterModel.statusOption);
        }
    }

    updateFilterModel() {
        const filterModel = this.filterStateService.getFilterModel(FilterPageType.checkInTypeManage);
        filterModel.page = FilterPageType.checkInTypeManage;
        filterModel.filterKey = this.frmSearch.controls.name.value;
        filterModel.filterOption = this.frmSearch.controls.filterOption.value;
        filterModel.statusOption = this.frmSearch.controls.statusOption.value;
        filterModel.isActive = false;
    }


    ngOnDestroy(): void {
    }

    ngOnInit() {
        this.frmSearch = this.formBuilder.group({
            name: [''],
            filterOption: [1], //Contains
            statusOption: [2] //Active
        });
        this.initDataTable();
    }

    private initDataTable() {
        const self = this;
        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter...',
                paginate: {
                    first: '<em class="fa fa-angle-double-left">',
                    last: '<em class="fa fa-angle-double-right">',
                    previous: '<em class="fa fa-angle-left">',
                    next: '<em class="fa fa-angle-right">'
                }
            },
            search: { search: null },
            searching: false,
            displayStart: self.rowIndex,
            paging: false,
            pagingType: 'full_numbers',
            pageLength: environment.paging.defaultPageSize,
            lengthMenu: environment.paging.lengthMenu,
            order: [[0, 'asc']],
            info: false,
            stateSave: true,
            scrollY: (window.innerHeight - 70 - 45) + "px",
            scrollCollapse:true,
            ajax: (dataTablesParameters: any, callback) => {

                self.updateFilterModel();
                
                dataTablesParameters.name = self.frmSearch.controls.name.value;
                dataTablesParameters.filterBy = Number(self.frmSearch.controls.filterOption.value);
                dataTablesParameters.status = self.statusOptions.find(x => x.id == self.frmSearch.controls.statusOption.value).value;

                self.blockUI.start();
                self.checkInTypeService.query(dataTablesParameters)
                    .subscribe(resp => {
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
                        self.blockUI.stop();
                    }, error => {
                        self.messagingService.ProcessErrorResponse(error);
                        self.blockUI.stop();
                    });
            },
            columns: [
                {
                    data: 'name',
                    title: 'Name',
                    width: '10%',
                    render: function (data, type, row) {
                        return `<span class='link' action-type='link-detail'>${row.name}</a>`;
                    }
                },
                {
                    data: 'description',
                    title: 'Description',
                    width: '10%'
                },
                {
                    data: 'isActive',
                    title: 'Status',
                    width: '10%',
                    render: function (isActive) {
                        return isActive
                            ? '<span class="badge badge-success">Active</span>'
                            : '<span class="badge badge-danger">Inactive</span>';
                    }
                },
                {
                    data: null,
                    title: 'Action',
                    width: '8%',
                    orderable: false,
                    className: 'text-center',
                    visible: true,
                    render: function (data, type, row) {
                        //Render Activate or Deactivate Button
                        let statusHtml = row.isActive ?
                            `<button title='Deactivate' action-type='toggle-status'><em class="fa fa-ban" aria-hidden="true"></em></button>` :
                            `<button title='Activate' action-type='toggle-status'><em class="fa fa-check" aria-hidden="true"></em></button>`;

                        return `
                        <button title='View Detail' action-type='view'><em class="fa fa-eye" aria-hidden="true"></em></button>
                        ${statusHtml}
                        <button title='Edit' action-type='edit'><em class="fa fa-edit" aria-hidden="true"></em></button>
                        <button title='Delete' action-type='delete'><em class="fa fa-trash" aria-hidden="true"></em></button>
                    `;
                    }
                }
            ],
            rowCallback: function (row, data: CheckInTypeDetailModel, dataIndex: number) {
                const _row = $(row);

                _row.find('[action-type="link-detail"],[action-type="view"]')
                    .off('click')
                    .on('click', function () {
                        self.router.navigate(['check-in-type', 'detail', data.id]);
                    });

                _row.find('[action-type="edit"]')
                    .off('click')
                    .on('click', function () {
                        self.router.navigate(['edit', 'check-in-type', data.id]);
                    });

                _row.find('[action-type="delete"]')
                    .off('click')
                    .on('click', function () {
                        self.openCheckInTypeDeleteModal(data);
                    });

                _row.find('[action-type="toggle-status"]')
                    .off('click')
                    .on('click', function () {
                        self.openCheckInTypeStatusModal(data);
                    });
            }
        };
    }

    applyFilter() {
        this.dtInstance.ajax.reload();
    }

    private openCheckInTypeDeleteModal(data: CheckInTypeDetailModel) {
        this.deleteConfirmationModal.openModal(data);
    }

    delete(checkInType: CheckInTypeDetailModel) {
        this.blockUI.start();
        this.checkInTypeService.delete(checkInType.id).subscribe(
            () => {
                setTimeout(() => {
                    this.dtInstance.ajax.reload(null, false);
                });
                this.messagingService.success(`Category has been deleted successfully.`);
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
            });
    }

    private openCheckInTypeStatusModal(data: CheckInTypeDetailModel) {
        this.statusChangeConfirmationModal.openModal(data);
    }

    toggle(checkInType: CheckInTypeDetailModel) {
        this.blockUI.start();
        this.checkInTypeService.toggleStatus(checkInType.id).subscribe(
            () => {
                setTimeout(() => {
                    this.dtInstance.ajax.reload(null, false);
                });
                this.messagingService.success(`Category has been ${(checkInType.isActive ? 'deactivated' : 'activated')} successfully.`);
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
            });
    }
}