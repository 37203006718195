var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ChangeDetectorRef } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockUI } from "ng-block-ui";
import { AccountActivateModel } from "src/app/models";
import { AuthenticationService, MessagingService } from "src/app/services";
import { MustMatch, MustNotMatch } from "src/app/validators";
export class AccountActivateComponent {
    constructor(router, route, messagingService, authenticationService, cdr, formBuilder) {
        this.router = router;
        this.route = route;
        this.messagingService = messagingService;
        this.authenticationService = authenticationService;
        this.cdr = cdr;
        this.formBuilder = formBuilder;
        this.loginImage = "assets/images/login-img.png";
        this.loginImage2 = "assets/images/login-img2.png";
        this.logo = "assets/images/recon-partners-logo.png";
        this.submitted = false;
        this.passwordIsValid = false;
        this.model = new AccountActivateModel();
        this.route.params.subscribe((params) => {
            this.model.key = params['key'];
            this.model.secret = params['secret'];
        });
    }
    ngOnInit() {
        this.frm = this.formBuilder.group({
            temporaryPassword: ['', [Validators.required]],
            newPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
            confirmPassword: ['', Validators.required]
        }, {
            validator: [
                MustNotMatch('temporaryPassword', 'newPassword'),
                MustMatch('newPassword', 'confirmPassword')
            ]
        });
    }
    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid.
        if (!this.passwordIsValid || this.frm.invalid) {
            return;
        }
        this.model.temporaryPassword = this.frm.controls.temporaryPassword.value;
        this.model.password = this.frm.controls.newPassword.value;
        this.model.confirmPassword = this.frm.controls.confirmPassword.value;
        this.blockUI.start();
        this.authenticationService.activate(this.model)
            .subscribe(() => {
            this.blockUI.stop();
            setTimeout(() => {
                this.router.navigate(['/account/login']);
            }, 100);
            setTimeout(() => {
                this.messagingService.success('Your account has been activated please log in to continue');
            }, 500);
        }, error => {
            this.blockUI.stop();
            this.messagingService.ProcessErrorResponse(error);
        });
    }
    passwordValid($event) {
        this.passwordIsValid = $event;
        this.cdr.detectChanges();
    }
}
__decorate([
    BlockUI('container-blockui'),
    __metadata("design:type", Object)
], AccountActivateComponent.prototype, "blockUI", void 0);
