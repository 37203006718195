var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EventEmitter, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI } from 'ng-block-ui';
export class WorkflowDeleteConfirmationComponent {
    constructor(modalService) {
        this.modalService = modalService;
        this.delete = new EventEmitter();
    }
    openModal(model) {
        this.model = model;
        this.modalReference = this.modalService.open(this.confirmationModal, { centered: true });
    }
    onSubmit() {
        setTimeout(() => {
            this.delete.emit(this.model);
        });
        this.onCancel();
    }
    onCancel() {
        if (!this.modalReference) {
            return;
        }
        this.modalReference.close();
    }
    ngOnDestroy() {
        this.onCancel();
    }
}
__decorate([
    BlockUI('container-blockui-confirmation'),
    __metadata("design:type", Object)
], WorkflowDeleteConfirmationComponent.prototype, "blockUI", void 0);
