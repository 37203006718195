
export class UserStoreAssociationListModel {
    public id: number;
    public name: string;
    public autoGroupName: string;

    constructor(id: number, name: string, autoGroupName: string) {
        this.id = id;
        this.name = name;
        this.autoGroupName = autoGroupName;
    }
}
