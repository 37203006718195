import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CategoryAddModel, CategoryDetailModel, CategoryEditModel, DataTableResponseModel, IVLCategorySelectListModel, SelectListModel } from "../models";


@Injectable({
    providedIn: 'root'
})

export class CategoryService {
    constructor(private http: HttpClient) {

    }

    public get(id: number): Observable<CategoryDetailModel> {
        return this.http.get<CategoryDetailModel>(`${environment.apiBaseUrl}/category/${id}`);
    }

    public add(model: CategoryAddModel): Observable<CategoryEditModel> {
        return this.http.post<CategoryEditModel>(`${environment.apiBaseUrl}/category`, model);
    }

    public update(model: CategoryEditModel): Observable<CategoryEditModel> {
        return this.http.put<CategoryEditModel>(`${environment.apiBaseUrl}/category`, model);
    }

    public delete(id: number) {
        return this.http.delete(`${environment.apiBaseUrl}/category/${id}`);
    }

    public toggleStatus(id: number) {
        return this.http.patch(`${environment.apiBaseUrl}/category/toggle-status/${id}`, null);
    }

    public query(dataTablesParameters: any) {
        return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/category/query`, dataTablesParameters, {});
    }

    public selectList(): Observable<Array<SelectListModel>> {
        return this.http.get<Array<SelectListModel>>(`${environment.apiBaseUrl}/category/select-list`);
    }

    public detailSelectList(): Observable<Array<CategoryDetailModel>> {
        return this.http.get<Array<CategoryDetailModel>>(`${environment.apiBaseUrl}/category/detail/select-list`);
    }

    public selectListByStore(id: number): Observable<Array<SelectListModel>> {
        return this.http.get<Array<SelectListModel>>(`${environment.apiBaseUrl}/store/${id}/category/select-list`);
    }

    public selectListForIVL(storeId: number, workflowId: number): Observable<Array<IVLCategorySelectListModel>> {
        return this.http.get<Array<IVLCategorySelectListModel>>(`${environment.apiBaseUrl}/category/ivl/store/${storeId}/workflow/${workflowId}/select-list`);
    }

}