import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BlobService, UploadParams } from 'angular-azure-blob-service';
import * as uuid from 'uuid';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { InvoicingPreference, NotificationType, StorageContainerType } from 'src/app/enums';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { CompanyWithStoreModel, CountryModel, SelectListModel, StateModel, SubCategorySelectListModel, VendorAddModel, VendorDetailModel, VendorDocumentModel } from 'src/app/models';
import { ApplicationContextService, CategoryService, CountryService, MessagingService, StorageService, StoreService, SubCategoryService, VendorService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-vendor-add',
    templateUrl: './vendor.add.component.html',
    styleUrls: ['./vendor.add.component.css']
})
export class VendorAddComponent implements OnInit, OnDestroy {
    dateModel: NgbDateStruct;
    calenderIcon: string = "/assets/images/calender-icon.png";
    downloadIcon: string = "/assets/images/download.png";

    @BlockUI('container-blockui-vendor') blockUI: NgBlockUI;
    InvoicingPreference = InvoicingPreference;
    NotificationType = NotificationType;
    storeSelectList: Array<SelectListModel>;
    countries: Array<CountryModel> = new Array<CountryModel>();
    states: Array<StateModel> = new Array<StateModel>();
    categories: Array<SelectListModel> = new Array<SelectListModel>();
    subCategories: Array<SubCategorySelectListModel> = new Array<SubCategorySelectListModel>();
    frmAddVendor: FormGroup;

    //For store multi select 
    storeDropdownSettings: IDropdownSettings = {};
    categoryDropdownSettings: IDropdownSettings = {};
    subCategoryDropdownSettings: IDropdownSettings = {};

    selectedStores: SelectListModel[] = new Array<SelectListModel>();
    selectedCategories: SelectListModel[] = new Array<SelectListModel>();
    selectedSubCategories: SubCategorySelectListModel[] = new Array<SubCategorySelectListModel>();

    filteredSubCategories: SubCategorySelectListModel[] = new Array<SubCategorySelectListModel>();

    vendorAutoId: string;
    submitted: boolean = false;
    model: VendorAddModel;

    companySubscription: any;
    storeSubscription: any;

    minExpireDate = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate()
    };

    constructor(private formBuilder: FormBuilder,
        private router: Router,
        private messagingService: MessagingService,
        public appUtils: AppUtils,
        private blobService: BlobService,
        private storageService: StorageService,
        private storeService: StoreService,
        private countryService: CountryService,
        private vendorService: VendorService,
        private categoryService: CategoryService,
        private subCategoryService: SubCategoryService,
        private stringLengthConstants: StringLengthConstants,
        private applicationContextService: ApplicationContextService
    ) {
        this.storeSelectList = new Array<SelectListModel>();
        this.categories = new Array<SelectListModel>();
        this.subCategories = new Array<SubCategorySelectListModel>();
        this.model = new VendorAddModel();
    }

    ngOnInit() {
        this.multiSelectDropdownSettings();
        this.initForm();
        this.loadData();

        this.storeSubscription = this.applicationContextService.store$.subscribe((storeSelectListItem: SelectListModel) => {
            if (storeSelectListItem) {
                this.selectedStores = new Array<SelectListModel>()
                this.selectedStores.push(storeSelectListItem);
            }
        });
    }

    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
    }

    private initForm() {
        this.frmAddVendor = this.formBuilder.group({
            // storeIds:['',Validators.required],
            name: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            streetAddress: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Default)]],
            blockAddress: [null, [Validators.maxLength(this.stringLengthConstants.Default)]],
            city: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Default)]],
            state: ['', [Validators.required]],
            country: ['', [Validators.required]],

            zipCode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5),
            Validators.pattern(new RegExp('^[a-zA-Z0-9-]*$', 'g')), Validators.maxLength(this.stringLengthConstants.PostalCode)]],
            phoneCode: ['', [Validators.required]],
            phoneNumber: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.PhoneNumber),
            this.appUtils.validatePhoneNumber()]],

            taxId: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(this.stringLengthConstants.TaxId)]],
            invoiceEmail: ['', [Validators.required, Validators.email, Validators.maxLength(this.stringLengthConstants.Email)]],
            insurancePolicy: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Default)]],
            insuranceProvider: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Default)]],
            insuranceExpireDate: ['', [Validators.required]],
            invoicingPreference: [InvoicingPreference.SingleInvoice, Validators.required],
            isEmailNotificationActive: [true],
            isSMSNotificationActive: [true]
        });

        this.vendorAutoId = Math.floor(Math.random() * (99999 - 10000) + 10000).toString();
    }

    uploadedPercent = 0;
    public onDocumentSelect(event: any) {
        if (!event.target.files || event.target.files.length === 0) {
            return;
        }

        [...event.target.files].forEach(file => this.processDocument(file));
        event.target.value = null;
    }

    private processDocument(file: any) {
        this.storageService.getSasToken().subscribe(async (sasToken) => {
            const cloudConfig: UploadParams = {
                sas: sasToken.toString(),
                storageAccount: environment.azureAccount,
                containerName: StorageContainerType.VendorDocument
            };

            const fileName = `${uuid.v4()}.${this.appUtils.getFileExtension(file.name)}`;
            const blobUrl = this.blobService.generateBlobUrl(cloudConfig, fileName);

            let document = new VendorDocumentModel();
            document.originalFileName = file.name;
            document.fileName = fileName;
            document.isDeleted = false;

            this.blockUI.start();
            let config = {
                baseUrl: `${blobUrl}?`,
                sasToken: cloudConfig.sas,
                blockSize: 1024 * 64,
                file: file,
                complete: () => {
                    this.storageService.getPublicUrl(StorageContainerType.VendorDocument, document.fileName).subscribe(res => {
                        document.fileUrl = res.toString();
                    });
                    this.model.documents.push(document);
                    this.blockUI.stop();
                },
                error: (err) => {
                    this.messagingService.ProcessErrorResponse(err, 'Vendor File upload timeout.');
                    this.blockUI.stop();
                },
                progress: (percent) => {
                    this.uploadedPercent = percent;
                }
            };
            setTimeout(() => {
                this.blobService.upload(config);
            });
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    public deleteDocument(document: VendorDocumentModel, index: number) {
        this.blockUI.start();
        this.vendorService.deleteDocument(document.fileName).subscribe(() => {
            this.model.documents.splice(index, 1);
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }

    onSubmit() {
        this.submitted = true;
        if (this.frmAddVendor.invalid
            || this.selectedStores.length == 0
            || this.selectedCategories.length == 0
            || this.model.documents.length == 0) {
            return;
        }

        this.model.storeIds = this.selectedStores.map(x => x.id);

        this.model.categoryIds = this.selectedCategories.map(x => x.id);
        this.model.subCategoryIds = this.selectedSubCategories.map(x => x.id);

        this.model.name = this.frmAddVendor.controls.name.value;
        this.model.streetAddress = this.frmAddVendor.controls.streetAddress.value;
        this.model.blockAddress = this.frmAddVendor.controls.blockAddress.value;
        this.model.city = this.frmAddVendor.controls.city.value;
        this.model.state = this.frmAddVendor.controls.state.value;
        this.model.country = this.frmAddVendor.controls.country.value;
        this.model.zipCode = this.frmAddVendor.controls.zipCode.value;
        this.model.phoneCode = this.frmAddVendor.controls.phoneCode.value;
        this.model.phone = this.frmAddVendor.controls.phoneNumber.value;
        this.model.taxId = this.frmAddVendor.controls.taxId.value;
        this.model.invoiceEmail = this.frmAddVendor.controls.invoiceEmail.value;
        this.model.insurancePolicy = this.frmAddVendor.controls.insurancePolicy.value;
        this.model.insuranceProvider = this.frmAddVendor.controls.insuranceProvider.value;
        this.model.insuranceExpireDate = this.frmAddVendor.controls.insuranceExpireDate.value;
        this.model.invoicingPreference = this.frmAddVendor.controls.invoicingPreference.value;
        this.model.isEmailNotificationActive = this.frmAddVendor.controls.isEmailNotificationActive.value;
        this.model.isSMSNotificationActive = this.frmAddVendor.controls.isSMSNotificationActive.value;

        this.vendorService.add(this.model)
            .subscribe((data: VendorDetailModel) => {
                setTimeout(() => {
                    this.router.navigate(['/manage/vendor']);
                }, 10);
                setTimeout(() => {
                    this.messagingService.success('Vendor has been added successfully.');
                }, 300);
                this.blockUI.stop();
            },
                error => {
                    this.messagingService.ProcessErrorResponse(error);
                    this.blockUI.stop();
                });
    }

    private loadData() {
        this.blockUI.start();
        forkJoin([
            this.countryService.getCountries(),
            this.categoryService.selectList(),
            this.subCategoryService.getAllSubCategories(),
            this.storeService.storeSelectList()
        ]).subscribe(([
            countries,
            categories,
            subCategories,
            stores
        ]) => {
            this.countries = countries;
            if (this.countries.length == 1) {
                this.loadStates(this.countries[0].id);
                this.frmAddVendor.controls['country'].setValue(this.countries[0].shortName);
                this.frmAddVendor.controls['phoneCode'].setValue(this.countries[0].phoneCode);
            }

            this.categories = categories;
            this.subCategories = subCategories;
            this.storeSelectList = stores;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    private loadStates(countryId: number) {
        this.blockUI.start();
        this.countryService.getStates(countryId).subscribe((data: any) => {
            this.states = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    public onCategorySelect() {
        let categoryIds = this.selectedCategories.map(x => x.id);
        if (categoryIds.length == 0) {
            this.onCategoryDeSelectAll();
            return;
        }

        this.filteredSubCategories = [...this.subCategories.filter(x => categoryIds.find(z => z == x.categoryId))]

        this.selectedSubCategories = this.selectedSubCategories.filter(x =>
            this.filteredSubCategories.find(z => z.id == x.id));
    }

    public onCategorySelectAll() {
        this.filteredSubCategories = [...this.subCategories];

        this.selectedSubCategories = this.selectedSubCategories.filter(x =>
            this.filteredSubCategories.find(z => z.id == x.id));
    }

    public onCategoryDeSelectAll() {
        this.selectedSubCategories = this.filteredSubCategories = new Array<SubCategorySelectListModel>();
    }

    private multiSelectDropdownSettings() {
        this.storeDropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };

        this.categoryDropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };

        this.subCategoryDropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };

    }
}
