var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BlockUI } from "ng-block-ui";
import { ColorPickerService } from "ngx-color-picker";
import { ToastrService } from "ngx-toastr";
import { AppUtils, StringLengthConstants } from "src/app/helpers";
import { CategoryAddModel } from "src/app/models";
import { CategoryService, MessagingService } from "src/app/services";
export class CategoryAddComponent {
    constructor(formBuilder, router, toastr, messagingService, categoryService, stringLengthConstants, cpService, appUtils) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.toastr = toastr;
        this.messagingService = messagingService;
        this.categoryService = categoryService;
        this.stringLengthConstants = stringLengthConstants;
        this.cpService = cpService;
        this.appUtils = appUtils;
        this.submitted = false;
        this.model = new CategoryAddModel();
        this.presetColors = this.appUtils.getPresetColors();
        this.colorCode = this.presetColors[0];
    }
    ngOnInit() {
        this.frmCategory = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            code: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.ShortCode), Validators.minLength(2), Validators.pattern(new RegExp('^[A-Za-z0-9]*$'))]],
            description: ['', [Validators.maxLength(this.stringLengthConstants.Description)]],
            sequence: ['', [Validators.min(1), Validators.pattern(new RegExp('^[-+]?[0-9]*\.?[0-9]*$'))]],
            useToolInWorkflow: [false]
        });
    }
    onSubmit() {
        this.submitted = true;
        if (this.frmCategory.invalid) {
            return;
        }
        this.model.name = this.frmCategory.controls.name.value;
        this.model.description = this.frmCategory.controls.description.value;
        this.model.code = this.frmCategory.controls.code.value;
        this.model.sequence = Number(this.frmCategory.controls.sequence.value);
        this.model.colorCode = this.colorCode;
        this.model.useToolInWorkflow = this.frmCategory.controls.useToolInWorkflow.value;
        this.categoryService.add(this.model)
            .subscribe((data) => {
            setTimeout(() => {
                this.router.navigate(['/manage/category']);
            }, 10);
            setTimeout(() => {
                this.toastr.success('Category has been added successfully.');
            }, 300);
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    onChangeColorHex8(color) {
        const hsva = this.cpService.stringToHsva(color, true);
        if (hsva) {
            this.colorCode = this.cpService.outputFormat(hsva, "hex", null);
            return this.colorCode;
        }
        return "";
    }
}
__decorate([
    BlockUI('blockui-container'),
    __metadata("design:type", Object)
], CategoryAddComponent.prototype, "blockUI", void 0);
