import { PricingType, VehicleLayoutType } from "src/app/enums";

export class OperationCodeDetailModel {
    public id: number;
    public name: string;
    public description: string;
    public categoryId: number;
    public categoryName: string;    
    public subCategoryId: Nullable<number>;
    public subCategoryName: string;
    public carLayoutPanelId: Nullable<number>;
    public carLayoutPanelName: string;
    public carLayoutSideId: Nullable<number>;
    public carLayoutSideName: string;
    public carLayoutLocationId: Nullable<number>;
    public carLayoutLocationName: string;
    public isLocationSpecific: boolean;
    public isActive: boolean;
    public isTiedToIVL: boolean;
    public vehicleLayoutType: VehicleLayoutType;
}
