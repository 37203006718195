export enum SubCategory {
    RearBrakePadsAndRotors="Rear Brake Pads & Rotors",
    TintRemoval="Tint Removal",
    LotWash="Lot Wash",
    Odor="Odor",
    FullCarBuff="Full Car Buff",
    FullDetail="Full Detail",
    Haze="Haze",
    CollisionQuote="Collision Quote",
    HailQuote="Hail Quote",
    GlassQuote="Glass Quote",
    Chip="Chip",
    PDR="PDR",
    Steel="Steel",
    BAndRCombo="B&R Combo",
    Bent="Bent",
    Machined="Machined",
    Refinish="Refinish",
    ExcessiveDamage="Excessive Damage",
    PanelBuff="Panel Buff",
    WetSand="Wet Sand",
    Touchup="Touch up",
    PlasticPaint="Plastic Paint",
    WholesaleDetail="Wholesale Detail",
    MetalPaint="Metal Paint",
    Damage="Damage",
    MechanicalPerDescription="Mechanical Per Description",
    ResurfaceRearBrakePads="Resurface Rear Brake Pads",
    FrontBrakePadsAndRotors="Front Brake Pads & Rotors",
    ResurfaceFrontBrakePads="Resurface Front Brake Pads",
    RearRotors="Rear Rotors",
    FrontRotors="Front Rotors",
    WheelLocks="Wheel Locks",
    Wipers="Wipers",
    AirFilter="Air Filter",
    CabinAirFilter="Cabin Air Filter",
    Alignment="Alignment",
    LubeOilFilter="Lube Oil Filter",
    Certification="Certification",
    EmissionInspection="Emission Inspection",
    StateInspection="State Inspection",
    VehicleInspection="Vehicle Inspection",
    BrakeFluidService="Brake Fluid Service",
    OneTire="1 Tire",
    TwoTire="2 Tire",
    FourTire="4 Tire",
    RotateAndBalanceTires="Rotate & Balance Tires",
    MountAndBalance1Tire="Mount & Balance 1 Tire",
    MountAndBalance2Tire="Mount & Balance 2 Tire",
    MountAndBalance4Tire="Mount & Balance 4 Tire",
    Battery="Battery",
    CheckEngineLightOn="Check Engine Light On",
    ChangestatusinInventoryManagementsystem="Change status in Inventory Management system", 
}


