import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { DataTableResponseModel, SelectListModel, SubCategoryAddModel, SubCategoryDetailModel, SubCategoryEditModel, SubCategorySelectListModel } from "../models";


@Injectable({
    providedIn: 'root'
})

export class SubCategoryService {
    constructor(private http: HttpClient) {

    }

    public get(id: number): Observable<SubCategoryDetailModel> {
        return this.http.get<SubCategoryDetailModel>(`${environment.apiBaseUrl}/sub-category/${id}`);
    }

    public add(model: SubCategoryAddModel): Observable<SubCategoryDetailModel> {
        return this.http.post<SubCategoryDetailModel>(`${environment.apiBaseUrl}/sub-category`, model);
    }

    public update(model: SubCategoryEditModel): Observable<SubCategoryDetailModel> {
        return this.http.put<SubCategoryDetailModel>(`${environment.apiBaseUrl}/sub-category`, model);
    }

    public delete(id: number) {
        return this.http.delete(`${environment.apiBaseUrl}/sub-category/${id}`);
    }

    public toggleStatus(id: number) {
        return this.http.patch(`${environment.apiBaseUrl}/sub-category/toggle-status/${id}`, null);
    }

    public query(dataTablesParameters: any) {
        return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/sub-category/query`, dataTablesParameters, {});
    }

    public selectList(categoryId: number): Observable<Array<SelectListModel>> {
        return this.http.get<Array<SelectListModel>>(`${environment.apiBaseUrl}/category/${categoryId}/sub-category/select-list`);
    }

    public getAllSubCategories(): Observable<Array<SubCategorySelectListModel>> {
        return this.http.get<Array<SubCategorySelectListModel>>(`${environment.apiBaseUrl}/sub-category/select-list`);
    }

    public selectListByStore(id: number, categoryId: number): Observable<Array<SubCategorySelectListModel>> {
        return this.http.get<Array<SubCategorySelectListModel>>(`${environment.apiBaseUrl}/store/${id}/${categoryId}/sub-category/select-list`);
    }
}