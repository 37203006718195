import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RxwebValidators, NumericValueType } from '@rxweb/reactive-form-validators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Category, ApprovalStatus, WorkStatus } from 'src/app/enums';
import { StringLengthConstants } from 'src/app/helpers';
import { VehicleQueueModel, VehicleQueueStageModel, OperationCodeSelectListModel, PriceMatrixEngineModel, VehicleWorkDetailModel, MechanicalOperationCodeModel, MechanicalDispatchModel } from 'src/app/models';
import { MessagingService, VehicleQueueService, OperationCodeService, PriceMatrixService } from 'src/app/services';

@Component({
  selector: 'app-mechanical-dispatch',
  templateUrl: './mechanical-dispatch.component.html',
  styleUrls: ['./mechanical-dispatch.component.css']
})
export class MechanicalDispatchComponent implements OnInit, OnDestroy {
  @BlockUI('container-blockui') blockUI: NgBlockUI;

  frmMechDispatch: FormGroup;
  private storeId: number;
  private vehicleQueueId: number;
  private vehicleQueueStageId: number;
  vehicleQueueModel: VehicleQueueModel = new VehicleQueueModel();
  vehicleQueueStageModel: VehicleQueueStageModel = new VehicleQueueStageModel();
  ApprovalStatus = ApprovalStatus;
  opCodes: Array<OperationCodeSelectListModel> = new Array<OperationCodeSelectListModel>();
  priceMatrices: Array<PriceMatrixEngineModel> = new Array<PriceMatrixEngineModel>();
  totalParts: number = 0;
  totalLabor: number = 0;
  totalCost: number = 0;
  submitted = false;

  constructor(private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private stringLengthConstants: StringLengthConstants,
    private messagingService: MessagingService,
    private vehicleQueueService: VehicleQueueService,
    private operationCodeService: OperationCodeService,
    private priceMatrixService: PriceMatrixService,
    public currencyPipe: CurrencyPipe
  ) {
    this.activatedRoute.params.subscribe((data) => {
      this.storeId = data.storeId;
      this.vehicleQueueId = data.vehicleQueueId;
      this.vehicleQueueStageId = data.vehicleQueueStageId;
    });
  }

  ngOnInit() {
    this.frmMechDispatch = this.formBuilder.group({
      selectAll: [],
      opCodes: this.formBuilder.array([]),
    });
    this.onAddOpCode();

    this.frmMechDispatch.controls.opCodes.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
      this.calculatePrices();
    });

    this.loadVehicleDetail();
  }

  getControls() {
    return (this.frmMechDispatch.get('opCodes') as FormArray).controls;
  }

  getOpCodeFormArray() {
    return this.frmMechDispatch.get('opCodes') as FormArray;
  }

  ngOnDestroy(): void {
  }

  private loadVehicleDetail() {
    this.blockUI.start();
    this.vehicleQueueService.get(this.vehicleQueueId).subscribe((vehicleQueue) => {
      if (!vehicleQueue) {
        this.messagingService.ProcessErrorResponse(null, "This vehicle is not belongs to the selected store");
        return;
      }

      if (vehicleQueue.storeId != this.storeId) {
        this.messagingService.ProcessErrorResponse(null, `This vehicle(VehicleQueueId ${vehicleQueue.id}| Store(${vehicleQueue.storeId})) is not belongs to the selected store(${this.storeId})`);
        return;
      }

      this.vehicleQueueModel = vehicleQueue;
      this.vehicleQueueStageModel = this.vehicleQueueModel.vehicleQueueStages.find(x => x.id == this.vehicleQueueStageId);
      this.loadData();
    }, error => {
      this.messagingService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }

  private loadData() {
    forkJoin([
      this.priceMatrixService.getPriceMatrixByCategory(this.storeId, this.vehicleQueueModel.workflowId, Category.Mechanical),
      this.operationCodeService.getSelectListByCategory(Category.Mechanical),
      this.vehicleQueueService.getApproveMechanicalAdvise(this.vehicleQueueId),
    ]).subscribe(([
      priceMatrices,
      opCodes,
      mechnicalVehicleWorkDetails
    ]) => {
      this.opCodes = opCodes;
      this.priceMatrices = priceMatrices;

      if (mechnicalVehicleWorkDetails.length == 0) {
        this.onAddOpCode();
      }
      this.fillOpCodePricing(mechnicalVehicleWorkDetails);
    }, error => {
      this.messagingService.ProcessErrorResponse(error);
    }, () => {

    });
  }

  public onAddOpCode() {
    this.addOpCodePricing();
  }

  public removeOpCode(index) {
    let items = this.getOpCodeFormArray();
    if (items.length != 1) {
      items.removeAt(index);
    }
  }

  private addOpCodePricing(): any {
    let fb = this.formBuilder.group({
      id: [],
      opCodeId: ['', [RxwebValidators.compose({
        validators: [
          RxwebValidators.required()
        ]
      })]],
      opCodeName: [],
      parts: [0, [RxwebValidators.compose({
        validators: [
          RxwebValidators.required(),
          RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
          RxwebValidators.minNumber({ value: 0 })
        ]
      })]],
      labor: [0, [RxwebValidators.compose({
        validators: [
          RxwebValidators.required(),
          RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
          RxwebValidators.minNumber({ value: 0 })
        ]
      })]],
      cost: [0],
      comment: ['', [RxwebValidators.compose({
        validators: [
          RxwebValidators.maxLength({ value: this.stringLengthConstants.Default })
        ]
      })]],
      approvalStatus: [],
      hasApprovalStatus: [false]
    });

    this.getOpCodeFormArray().push(fb);
  }

  private fillOpCodePricing(data): any {
    data.forEach((x: VehicleWorkDetailModel) => {
      let isDisabled = x.approvalStatus != ApprovalStatus.None || x.workStatus == WorkStatus.Completed;
      let fb = this.formBuilder.group({
        id: [x.id],
        opCodeId: [{ value: x.operationCodeId, disabled: isDisabled },
        [RxwebValidators.compose({
          validators: [
            RxwebValidators.required()
          ]
        })]],
        opCodeName: [x.operationCodeDetailModel.name],
        parts: [{ value: x.approvalStatus == ApprovalStatus.Rejected ? 0 : x.partPrice, disabled: isDisabled },
        [RxwebValidators.compose({
          validators: [
            RxwebValidators.required(),
            RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
            RxwebValidators.minNumber({ value: 0 })
          ]
        })]],
        labor: [{ value: x.approvalStatus == ApprovalStatus.Rejected ? 0 : x.laborPrice, disabled: isDisabled },
        [RxwebValidators.compose({
          validators: [
            RxwebValidators.required(),
            RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
            RxwebValidators.minNumber({ value: 0 })
          ]
        })]],
        cost: [0],
        comment: [x.commentModels.length > 0 ? x.commentModels[x.commentModels.length - 1].comment : '', [RxwebValidators.compose({
          validators: [
            RxwebValidators.maxLength({ value: this.stringLengthConstants.Default })
          ]
        })]],
        approvalStatus: [{ value: x.approvalStatus, disabled: x.approvalStatus != ApprovalStatus.None }],
        hasApprovalStatus: [x.approvalStatus != ApprovalStatus.None]
      });

      this.getOpCodeFormArray().push(fb);
    });

    this.removeOpCode(0);
  }

  private calculatePrices() {
    this.totalParts = 0;
    this.totalLabor = 0;
    this.totalCost = 0;
    this.frmMechDispatch.getRawValue().opCodes.forEach((x: any, index: number) => {
      let labor = Number(x.labor);
      let parts = Number(x.parts);
      let cost = parts + labor;
      (this.getControls()[index] as FormGroup).controls.cost.setValue(cost);
      this.totalParts += parts;
      this.totalLabor += labor;
      this.totalCost += cost;
    });
  }

  public onOpCodeSelect(index: number) {
    let fg = this.getControls()[index] as FormGroup;
    let priceMatrix = this.priceMatrices.filter(x => x.operationCodeId == fg.value.opCodeId)[0];
    fg.controls.labor.setValue(priceMatrix.price);
    fg.controls.opCodeName.setValue(this.opCodes.filter(x => x.id == fg.value.opCodeId)[0].name);
  }

  onSubmit() {
    this.submitted = true;
    if (this.frmMechDispatch.invalid) {
      return;
    }

    let adviseModel = new MechanicalDispatchModel();
    adviseModel.vehicleQueueId = Number(this.vehicleQueueId);
    adviseModel.vehicleQueueStageId = Number(this.vehicleQueueStageId);
    this.getOpCodeFormArray().controls.forEach((elem, index) => {
      let fg = elem as FormGroup;
      if (fg.valid && !fg.value.hasApprovalStatus) {
        let model = new MechanicalOperationCodeModel();
        model.id = Number(fg.controls.id.value);
        model.operationCodeId = Number(fg.controls.opCodeId.value);
        model.partsPrice = Number(fg.controls.parts.value);
        model.laborPrice = Number(fg.controls.labor.value);
        model.comment = fg.controls.comment.value;
        adviseModel.mechanicalOperationCodes.push(model);
      }
    });

    this.blockUI.start();
    this.vehicleQueueService.addMechanicalDispatch(adviseModel).subscribe(() => {
      setTimeout(() => {
        this.router.navigate(['/']);
      }, 10)
      setTimeout(() => {
        this.messagingService.success('Mechanical dispatch complete successfully.');
      }, 300)
    }, error => {
      this.messagingService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }

  public onBlurOnComment(event: any) {
    event.target.scrollTop = 0;
  }
}

