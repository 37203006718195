import { EventEmitter, Injectable } from "@angular/core";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { environment } from "src/environments/environment";
import { VehicleQueueSignalModel } from "../models";


@Injectable({
    providedIn: 'root'
})
export class VehicleQueueSignalService {

    isConnectionEstablished: boolean;
    connectionEstablished = new EventEmitter<boolean>();
    vehicleQueueUpdated = new EventEmitter<VehicleQueueSignalModel>();

    private _hubConnection: HubConnection;

    constructor() {
        this.init();
    }

    private init(): void {

        this._hubConnection = new HubConnectionBuilder()
            .withUrl(`${environment.signalRUrl}`)
            .withAutomaticReconnect()
            .build();

        this._hubConnection
            .start()
            .then(() => {
                this.isConnectionEstablished = true;
                this.connectionEstablished.emit(true);
            })
            .catch(err => {
                console.log('Error while establishing connection, retrying...', err);
            });

        this._hubConnection.on('VehicleUpdated', (data: any) => {
            this.vehicleQueueUpdated.emit(data);
        });
    }   

    public associateStore(oldStoreId: any, newStoreId: any) {
        this._hubConnection.invoke("AssociateStore", {
            oldStoreId: oldStoreId,
            currentStoreId: newStoreId
        }).catch(function (err) {
            return console.error(err.toString());
        });
    }
}
