import { OperationCodeDetailModel } from "../op-code/op-code.detail.model";

export class QuotationItemModel {
    public id: number;
    public operationCodeId: number;
    public price: number;
    public comment: string;

    public carLayoutLocationName: string;
    public carLayoutPanelName: string;
    public carLayoutSideName: string;
    public categoryName: string;
    public subCategoryName: string;
    public operationCodeName: string;

    public assessorComment: string;
    public description: string;

    public operationCodeDetailModel: OperationCodeDetailModel;

    constructor() {
        this.operationCodeDetailModel = new OperationCodeDetailModel();
    }
}