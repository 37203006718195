import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NotificationType, InvoicingPreference } from 'src/app/enums';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { AppUtils } from 'src/app/helpers';
import { SelectListModel, UserDetailModel, VendorDetailModel } from 'src/app/models';
import { FilterStateService, MessagingService, StoreService, VendorService } from 'src/app/services';

@Component({
    selector: 'app-vendor-detail',
    templateUrl: './vendor.detail.component.html',
    styleUrls: ['./vendor.detail.component.css']
})
export class VendorDetailComponent implements OnInit {
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild('deleteConfirmationModal', { static: false }) deleteConfirmationModal: any;
    @ViewChild('statusChangeConfirmationModal', { static: false }) statusChangeConfirmationModal: any;

    NotificationType = NotificationType;
    InvoicingPreference = InvoicingPreference;

    model: VendorDetailModel;
    stores: string;

    constructor(private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private messagingService: MessagingService,
        public appUtils: AppUtils,
        private vendorService: VendorService,
        private filterStateService: FilterStateService) {
        filterStateService.changeFilterModelStatues(FilterPageType.vendorManage, true);
        this.model = new VendorDetailModel();

        this.route.params.subscribe(params => {
            this.model.id = params.vendorId;
        });
    }

    ngOnInit() {
        this.loadVendor();
    }

    loadVendor() {
        this.blockUI.start();
        this.vendorService.get(this.model.id).subscribe((data: VendorDetailModel) => {
            this.model = data;
            this.stores = this.model.storeNames.join(', ');
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    openVendorDeleteModal() {
        this.deleteConfirmationModal.openModal(this.model);
    }

    delete(vendor: VendorDetailModel) {
        this.blockUI.start();
        this.vendorService.delete(vendor.id).subscribe(
            () => {
                setTimeout(() => {
                    this.router.navigate(['/manage/vendor']);
                });
                this.messagingService.success(`vendor has been deleted successfully.`);
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
            });
    }


    openVendorStatusModal() {
        this.statusChangeConfirmationModal.openModal(this.model);
    }

    toggle(vendor: VendorDetailModel) {
        this.blockUI.start();
        this.vendorService.toggleStatus(vendor.id).subscribe(
            () => {
                this.messagingService.success(`Vendor has been ${(vendor.isActive ? 'deactivated' : 'activated')} successfully.`);
                this.model.isActive = !this.model.isActive;
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
            });
    }

    getNotificationText(): string {
        let arr = new Array<string>();
        if (this.model !== null) {
            if (this.model.isEmailNotificationActive) {
                arr.push('Email');
            }
            if (this.model.isSMSNotificationActive) {
                arr.push('SMS');
            }
        }
        return arr.join(',&nbsp;');
    }

    getConCatStringFromStringArray(values: Array<string>) {
        if (!values)
            return;
        return values.join(", ");
    }
}
