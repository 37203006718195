import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar.chart.component.html',
  styleUrls: ['./bar.chart.component.css']
})
export class BarChartComponent implements OnInit {

  chart: any;
  @ViewChild('canvas', { static: false }) canvas: ElementRef;

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    //Chart
    let context = (<HTMLCanvasElement>this.canvas.nativeElement).getContext('2d');
    this.chart = new Chart(context, {
      plugins: [ChartDataLabels],
      type: 'bar',
      data: {
        labels: [
          'Series A', 'Series B', 'Series C'
        ],
        datasets: [{
          label: '# of Votes',
          data: [2.5, 2.0, 1.9],
          backgroundColor: [
            '#e5444d',
            '#eb6066',
            '#ef9296'
          ],
          borderColor: [
            '#e5444d',
            '#eb6066',
            '#ef9296'
          ],
          borderWidth: 1
        }]
      },
      options: {
        maintainAspectRatio: true,
        // scaleShowVerticalLines: false,
        responsive: false,
        scales: {
          xAxes: [{
            gridLines: {
              offsetGridLines: false
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              min: 0,
              max: 3
            }
          }]
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
          }
        },
        title: {
          display: true,
          position: 'bottom',
          text: 'Total Turn Time'
        },
        legend: {
          display: false
        },
      }
    });

    (<HTMLCanvasElement>this.canvas.nativeElement).style.display = 'inline';
  }
}
