export enum Category {
    Assessment = 'Assessment',
    Approval = 'Approval',
    Quote = 'Quote', //We need to send email for quotation
    MechanicalAdvising = 'Mechanical Advising',
    Mechanical = 'Mechanical',
    ReconPackage = 'Recon Package',
    Detail = 'Detail', //Vehicle Details screen
    FinalInspection = 'Final Inspection',
    Wholesale = 'Wholesale',
    PurchaseOrder='Purchase Order',

    Paint = 'Paint',
    Wheel = 'Wheel',
    DentRemoval = 'Dent Removal',
    Glass = 'Glass',
    Interior = 'Interior',

    PorterReturnToStore = 'Porter Return to Store',
    PorterToWholesale = 'Porter to Wholesale',
    PorterToSales = 'Porter to Sales',
    PorterToCollision = 'Porter to Collision',
    PorterToPhoto = 'Porter to Photo',
    PorterToDetail = 'Porter to Detail',
    PorterToVendorAction = 'Porter to Vendor Action',
    PorterToMechanical = 'Porter to Mechanical',
    PorterToAssessment = 'Porter to Assessment',
    PorterToChangePlates = 'Porter to Change Plates',    

    PhotoFirst = 'Photo First',
    PhotoFinal = 'Photo Final'
}