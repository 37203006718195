import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { PermissionType } from "src/app/enums";
import { AuthenticationService } from "src/app/services";

@Directive({
    selector: '[hasPermissions]'
})

export class HasPermissionsDirective {

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authenticationService: AuthenticationService
    ) { }

    @Input() set hasPermissions(permissions: PermissionType[]) {
        this.authenticationService.validatePermission(permissions).subscribe(valid => {
            if (valid) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }
        });
    }
}