import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class VendorService {
    constructor(http) {
        this.http = http;
    }
    query(dataTablesParameters) {
        return this.http.post(`${environment.apiBaseUrl}/vendor/query`, dataTablesParameters);
    }
    get(id) {
        return this.http.get(`${environment.apiBaseUrl}/vendor/${id}`);
    }
    add(data) {
        return this.http.post(`${environment.apiBaseUrl}/vendor`, data);
    }
    update(data) {
        return this.http.put(`${environment.apiBaseUrl}/vendor`, data);
    }
    delete(id) {
        return this.http.delete(`${environment.apiBaseUrl}/vendor/${id}`);
    }
    toggleStatus(id) {
        return this.http.patch(`${environment.apiBaseUrl}/vendor/toggle-status/${id}`, null);
    }
    deleteDocument(fileName) {
        return this.http.delete(`${environment.apiBaseUrl}/vendor/delete-document/${fileName}`);
    }
    vendorSelectList() {
        return this.http.get(`${environment.apiBaseUrl}/vendor/select-list-vendors`);
    }
}
VendorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VendorService_Factory() { return new VendorService(i0.ɵɵinject(i1.HttpClient)); }, token: VendorService, providedIn: "root" });
