<section class="compnyDtlPSec">
     <div class="container">
          <div class="row">
               <div class="col-12">
                    <a [routerLink]="['/manage/category']" class="backtolist"><em class="fa fa-long-arrow-left"
                              aria-hidden="true"></em> Back to list</a>
               </div>
          </div>
          <div class="row" *blockUI="'container-blockui'">
               <div class="col-12 col-md-12 col-lg-8 col-xl-8 offset-xl-2 offset-lg-2">
                    <div class="compnydtlwrp">
                         <div class="compnydtlH">
                              <div class="row">
                                   <div class="col-8 col-md-8">
                                        <h2>Category Detail</h2>
                                   </div>
                                   <div class="col-4 col-md-4">
                                        <div class="form-group">
                                             <div ngbDropdown class="actionWrp dropdown">
                                                  <button class="btn btn-red" id="dropdownBasic1"
                                                       ngbDropdownToggle>Actions</button>
                                                  <div class="dropdown-menu" ngbDropdownMenu
                                                       aria-labelledby="dropdownBasic1">
                                                       <ul>
                                                            <li>
                                                                 <a ngbDropdownItem
                                                                      [routerLink]="['/edit/category',model.id]">
                                                                      <em class="fa fa-edit" aria-hidden="true"></em>
                                                                      Edit
                                                                 </a>
                                                            </li>
                                                            <li>
                                                                 <a ngbDropdownItem href="javascript:void(0);"
                                                                      (click)="openStatusModal()">
                                                                      <em class="fa fa-times-circle-o"
                                                                           aria-hidden="true"></em>
                                                                      <span
                                                                           [innerHTML]="model.isActive?'Deactivate':'Activate'"></span>
                                                                 </a>
                                                            </li>
                                                            <li>
                                                                 <a ngbDropdownItem href="javascript:void(0);"
                                                                      (click)="openDeleteModal()">
                                                                      <em class="fa fa-trash-o" aria-hidden="true"></em>
                                                                      Delete
                                                                 </a>
                                                            </li>
                                                       </ul>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div class="row">
                              <div class="col-12">
                                   <ul class="cmpnyDtlList">
                                        <li>
                                             <span>Name</span>
                                             <span>:</span>
                                             <span>{{model.name}}</span>
                                        </li>
                                        <li>
                                             <span>Code</span>
                                             <span>:</span>
                                             <span>{{model.code}}</span>
                                        </li>
                                        <li>
                                             <span>Description</span>
                                             <span>:</span>
                                             <span>{{model.description}}</span>
                                        </li>
                                        <li>
                                             <span>IVL Rank</span>
                                             <span>:</span>
                                             <span>{{model.sequence}}</span>
                                        </li>
                                        <li>
                                             <span>Use Tool in Workflow</span>
                                             <span>:</span>
                                             <span>{{model.useToolInWorkflow ? 'Yes' : 'No'}}</span>
                                        </li>                                        
                                        <li>
                                             <span>Color</span>
                                             <span>:</span>
                                             <span [style.background]="model.colorCode"></span>
                                        </li>
                                   </ul>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</section>
<app-delete-confirmation-category #deleteConfirmationModal (delete)="delete($event)">
</app-delete-confirmation-category>
<app-status-confirmation-category #statusChangeConfirmationModal (toggle)="toggle($event)">
</app-status-confirmation-category>