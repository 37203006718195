/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wholesale-button.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./wholesale-button.component";
import * as i4 from "../../../services/application.context.service";
import * as i5 from "../../../services/workflow.service";
var styles_WholesaleButtonComponent = [i0.styles];
var RenderType_WholesaleButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WholesaleButtonComponent, data: {} });
export { RenderType_WholesaleButtonComponent as RenderType_WholesaleButtonComponent };
function View_WholesaleButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btnOrange"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onWholesale() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Wholesale\n"]))], null, null); }
export function View_WholesaleButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_WholesaleButtonComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasWholesale; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_WholesaleButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-wholesale-button", [], null, null, null, View_WholesaleButtonComponent_0, RenderType_WholesaleButtonComponent)), i1.ɵdid(1, 114688, null, 0, i3.WholesaleButtonComponent, [i4.ApplicationContextService, i5.WorkflowService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WholesaleButtonComponentNgFactory = i1.ɵccf("app-wholesale-button", i3.WholesaleButtonComponent, View_WholesaleButtonComponent_Host_0, {}, { emitAfterWholesaleAction: "emitAfterWholesaleAction" }, []);
export { WholesaleButtonComponentNgFactory as WholesaleButtonComponentNgFactory };
