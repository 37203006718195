import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
    selector: 'app-workflow-delete-sequence-confirmation',
    templateUrl: './workflow.delete.sequence.confirmation.component.html',
    styleUrls: ['./workflow.delete.sequence.confirmation.component.css']
})
export class WorkflowSequenceDeleteConfirmationComponent implements OnDestroy {
    @BlockUI('container-blockui-confirmation') blockUI: NgBlockUI;
    @ViewChild('confirmationModal', { static: false }) confirmationModal: any;
    @Output() delete = new EventEmitter<any>();

    modalReference: NgbModalRef;
    model: any;
    submitted: boolean;

    constructor(private modalService: NgbModal) {
    }

    openModal(model: any) {
        this.model = model;
        this.modalReference = this.modalService.open(this.confirmationModal, { centered: true });
    }

    onSubmit() {
        setTimeout(() => {
            this.delete.emit(this.model);
        });
        this.onCancel();
    }

    onCancel() {
        if(!this.modalReference){
            return;
        }
        this.modalReference.close();
    }
    
    ngOnDestroy(): void {
        this.onCancel();
    }
}
