import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { RoleAddModel } from 'src/app/models/role/role.add.model';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { RoleDetailModel, SelectListModel } from 'src/app/models';
import { MessagingService, RoleService } from 'src/app/services';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { ApplicationRoleType } from 'src/app/enums';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';

@Component({
	selector: 'app-role-add',
	templateUrl: './add.role.component.html',
	styleUrls: ['./add.role.component.css']
})

export class RoleAddComponent implements OnInit {
	roleFrm: FormGroup;
	submitted: boolean = false;
	model: RoleAddModel;
	applicationRoleTypes: Array<SelectListModel>;

	@BlockUI('container-blockui-role-add') blockUI: NgBlockUI;

	constructor(
		private router: Router,
		private formBuilder: FormBuilder,
		private messagingService: MessagingService,
		private roleService: RoleService,
		private stringLengthConstants: StringLengthConstants,
		private appUtils: AppUtils
	) {
		this.model = new RoleAddModel();
		this.applicationRoleTypes = this.appUtils.getApplicationRoleTypes();
	}

	ngOnInit() {

		this.roleFrm = this.formBuilder.group({
			roleName: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
			permissions: this.formBuilder.array([]),
			applicationRoleType: [ApplicationRoleType.System]
		});

		this.loadData();
	}

	private loadData() {
		this.blockUI.start();
		forkJoin([
			this.roleService.getAllPermissions()
		]).subscribe(([
			permissions
		]) => {
			
			const fa: FormArray = this.roleFrm.get('permissions') as FormArray;
			fa.clear();
			permissions.forEach(p => {
				fa.push(this.formBuilder.group({
					permission: [p.permission],
					name: [p.name],
					description: [p.description],
					groupName: [p.groupName],
					isSelected: [false]
				}));
			});
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
		}, () => {
			this.blockUI.stop();
		});
	}

	onSubmit() {
		this.submitted = true;
		if (this.roleFrm.invalid)
			return;

		this.model.name = this.roleFrm.controls.roleName.value;
		this.model.permissions = this.roleFrm.value.permissions.filter(x => x.isSelected).map(x => x.permission);
		this.model.applicationRoleType = Number(this.roleFrm.controls.applicationRoleType.value);

		this.blockUI.start();
		this.roleService.add(this.model).subscribe((data: RoleDetailModel) => {
			setTimeout(() => {
				this.router.navigate(['/manage/role']);
			}, 10);
			setTimeout(() => {
				this.messagingService.success('Role has been added successfully.');
			}, 300);
			this.blockUI.stop();
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
			this.blockUI.stop();
		});
	}

	onCancel() {
        this.router.navigate(['/manage/role']);
    }
}
