<section class="gnrlformSec">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <a [routerLink]="['/manage/op-code']" class="backtolist">
                    <em class="fa fa-long-arrow-left" aria-hidden="true"></em> Back to list</a>
            </div>
        </div>
        <div class="row" *blockUI="'container-blockui'">
            <div class="col-12 col-md-12 col-lg-8 col-xl-8 offset-xl-2 offset-lg-2">
                <div class="gnrlformbox">
                    <h2>Edit OP Code</h2>
                    <div class="gnrlformDtl">
                        <form class="matrixFrom" [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Name</h5>
                                </div>
                                <div class="matrixFromField">
                                    {{model.name}}
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Description</h5>
                                </div>
                                <div class="matrixFromField">
                                    <input type="text" class="form-control" formControlName="description"
                                        [ngClass]="{ 'is-invalid': submitted && form.controls.description.errors }" />
                                    <div *ngIf="submitted && form.controls.description.errors" class="invalid-feedback">
                                        <div *ngIf="form.controls.description.errors.required">Description is required
                                        </div>
                                        <div *ngIf="form.controls.description.errors.maxlength">
                                            Description should be max
                                            {{form.controls.description.errors.maxlength.requiredLength}} characters
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Is Location Specific</h5>
                                </div>
                                <div class="matrixFromField">
                                    <input class="form-check-input" id="chkIsLocationSpecific" type="checkbox"
                                        formControlName="isLocationSpecific">
                                    <label class="form-check-label" for="chkIsLocationSpecific">
                                    </label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Category</h5>
                                </div>
                                <div class="matrixFromField">
                                    <select class="form-control" formControlName="categoryId" placeholder="Category"
                                        (change)="onCategoryChange()"
                                        [ngClass]="{ 'is-invalid': submitted && form.controls.categoryId.errors }">
                                        <option value="">Select Category</option>
                                        <option *ngFor="let item of categorySelectList" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="submitted && form.controls.categoryId.errors" class="invalid-feedback">
                                        <div *ngIf="form.controls.categoryId.errors.required">Category
                                            is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Tool</h5>
                                </div>
                                <div class="matrixFromField">
                                    <select class="form-control" formControlName="subCategoryId" placeholder="Tool"
                                        [ngClass]="{ 'is-invalid': submitted && form.controls.subCategoryId.errors }">
                                        <option value="">Select Tool</option>
                                        <option *ngFor="let item of subCategorySelectList" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="submitted && form.controls.subCategoryId.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="form.controls.subCategoryId.errors.required">Tool
                                            is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="form.controls.isLocationSpecific.value">
                                <div class="matrixFromLabel">
                                    <h5>Panel</h5>
                                </div>
                                <div class="matrixFromField">
                                    <select class="form-control" formControlName="carLayoutPanelId" placeholder="Panel"
                                        [ngClass]="{ 'is-invalid': submitted && form.controls.carLayoutPanelId.errors }">
                                        <option value="">Select Panel</option>
                                        <option *ngFor="let item of carLayoutPanelSelectList" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="submitted && form.controls.carLayoutPanelId.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="form.controls.carLayoutPanelId.errors.required">Panel
                                            is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="form.controls.isLocationSpecific.value">
                                <div class="matrixFromLabel">
                                    <h5>Location</h5>
                                </div>
                                <div class="matrixFromField">
                                    <select class="form-control" formControlName="carLayoutLocationId"
                                        placeholder="Location"
                                        [ngClass]="{ 'is-invalid': submitted && form.controls.carLayoutLocationId.errors }">
                                        <option value="">Select Location</option>
                                        <option *ngFor="let item of carLayoutLocationSelectList" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="submitted && form.controls.carLayoutLocationId.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="form.controls.carLayoutLocationId.errors.required">Location
                                            is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="form.controls.isLocationSpecific.value">
                                <div class="matrixFromLabel">
                                    <h5>Side</h5>
                                </div>
                                <div class="matrixFromField">
                                    <select class="form-control" formControlName="carLayoutSideId"
                                        placeholder="Location"
                                        [ngClass]="{ 'is-invalid': submitted && form.controls.carLayoutSideId.errors }">
                                        <option value="">Select Side</option>
                                        <option *ngFor="let item of carLayoutSideSelectList" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="submitted && form.controls.carLayoutSideId.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="form.controls.carLayoutSideId.errors.required">Side
                                            is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="matrixFromLabel">
                                    <h5>Is Tied To Intelligent Layout</h5>
                                </div>
                                <div class="matrixFromField">
                                    <span>
                                        <input class="form-check-input" id="chkIsTiedToIVL" type="checkbox"
                                            formControlName="isTiedToIVL">
                                        <label class="form-check-label" for="chkIsTiedToIVL"></label>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="form.controls.isTiedToIVL.value">
                                <div class="matrixFromLabel">
                                    <h5>Layout</h5>
                                </div>
                                <div class="matrixFromField">
                                    <select class="form-control" formControlName="vehicleLayoutType"
                                        [ngClass]="{ 'is-invalid': submitted && form.controls.vehicleLayoutType.errors }">
                                        <option value="">Select Layout</option>
                                        <option *ngFor="let item of vehicleLayoutTypeOptions" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="submitted && form.controls.vehicleLayoutType.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="form.controls.vehicleLayoutType.errors.required">Layout
                                            is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="btnWrp">
                                <button class="btn btn-red" type="submit">Save</button>
                                <button class="btn btn-tsrp" type="button"
                                    [routerLink]="['/manage/op-code']">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>