<section class="gnrlformSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-6"><h2 class="approvalH">Invoice : #Rec987678</h2></div>
            <div class="col-6 text-right">
                <ul class="printDown">
                    <li><a href="#"><img [src]="prinImg" alt="Print" /> </a></li>
                    <li><a href="#"><img [src]="downloadImg" alt="Download" /></a></li>
                </ul>
            </div>
        </div>
        <div class="bluetableWrp table-responsive">
            <table class="table dataTable table-striped">
                <thead>
                    <tr>
                        <th scope="col">Year</th>
                        <th scope="col">Make</th>
                        <th scope="col">Model</th>
                        <th scope="col">Color</th>
                        <th scope="col">Check-IN</th>
                        <th scope="col">Stock#</th>
                        <th scope="col">VIN</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>2008</td>
                        <td>Kia</td>
                        <td>Forte</td>
                        <td>Gray</td>
                        <td>Stock-in</td>
                        <td>KU022934SV</td>
                        <td>41258...</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <h2 class="approvalH">Invoice Summary</h2>
        <div class="bluetableWrp table-responsive">
            <table class="table dataTable table-striped">
                <thead>
                    <tr>
                        <th scope="col">Description </th>
                        <th scope="col">Price</th>
                        <th scope="col">Service</th>
                        <th scope="col">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Dummy Text</td>
                        <td>$ 44.00</td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="totalWrp">
            <p>Total : <span>$ 220.00</span><br>
                Tax : <span>$ 18.00</span></p>
            <p class="final"><strong>$238</strong></p>
        </div>
    </div>
</section>