import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";
import { FilterPageType } from "src/app/enums/filter.page.type.enum";
import { StringLengthConstants } from "src/app/helpers";
import { SubCategoryEditModel, SelectListModel } from "src/app/models";
import { CategoryService, MessagingService, SubCategoryService, OperationCodeService, FilterStateService } from "src/app/services";


@Component({
    selector: 'app-edit-sub-category',
    templateUrl: './edit.sub-category.component.html',
    styleUrls: ['./edit.sub-category.component.css']
})

export class SubCategoryEditComponent implements OnInit {
    @BlockUI('blockui-container') blockUI: NgBlockUI;
    frmSubCategory: FormGroup;
    submitted: boolean = false;
    canEdit: boolean = false;
    model: SubCategoryEditModel;
    categories: Array<SelectListModel>;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private messagingService: MessagingService,
        private categoryService: CategoryService,
        private subCategoryService: SubCategoryService,
        private operationCodeService: OperationCodeService,
        private stringLengthConstants: StringLengthConstants,
        private filterStateService: FilterStateService
    ) {
        filterStateService.changeFilterModelStatues(FilterPageType.toolManage, true);
        this.model = new SubCategoryEditModel();
        this.categories = new Array<SelectListModel>();
        this.route.params.subscribe(p => this.model.id = Number(p.subCategoryId));
    }

    ngOnInit() {
        this.loadCategory();

        this.frmSubCategory = this.formBuilder.group({
            categoryId: ['', [Validators.required]],
            name: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            code: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.ShortCode), Validators.minLength(2), Validators.pattern(new RegExp('^[A-Za-z0-9]*$'))]],
            description: ['', [Validators.maxLength(this.stringLengthConstants.Description)]],
            sequence: ['', [Validators.min(1), Validators.pattern(new RegExp('^[-+]?[0-9]*\.?[0-9]*$'))]],
        });
    }

    private loadSubcategory() {
        this.blockUI.start();
        forkJoin([
            this.subCategoryService.get(this.model.id),
            this.operationCodeService.selectListBySubCategoryId(this.model.id)]
        ).subscribe((result) => {
            this.model = result[0];
            this.canEdit = result[1].length === 0;
            this.initForm();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    private initForm() {
        this.frmSubCategory.controls.name.setValue(this.model.name);
        this.frmSubCategory.controls.code.setValue(this.model.code);
        this.frmSubCategory.controls.description.setValue(this.model.description);
        this.frmSubCategory.controls.categoryId.setValue(this.model.categoryId);
        this.frmSubCategory.controls.sequence.setValue(this.model.sequence);
    }

    onSubmit() {
        this.submitted = true;
        if (this.frmSubCategory.invalid) {
            return;
        }

        this.model.categoryId = this.frmSubCategory.controls.categoryId.value;
        this.model.name = this.frmSubCategory.controls.name.value;
        this.model.description = this.frmSubCategory.controls.description.value;
        this.model.code = this.frmSubCategory.controls.code.value;
        this.model.sequence = Number(this.frmSubCategory.controls.sequence.value);

        this.subCategoryService.update(this.model)
            .subscribe((data: SubCategoryEditModel) => {
                setTimeout(() => {
                    this.router.navigate(['/manage/sub-category']);
                }, 10);
                setTimeout(() => {
                    this.toastr.success('Tool has been updated successfully.');
                }, 300);
                this.blockUI.stop();
            }, error => {
                this.messagingService.ProcessErrorResponse(error);
                this.blockUI.stop();
            });
    }

    private loadCategory() {
        this.blockUI.start();
        this.categoryService.selectList().subscribe((data: any) => {
            this.categories = data;
            this.loadSubcategory();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
}
