import { HttpClient } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ApplicationContextService } from "./application.context.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./application.context.service";
export class CompanyService {
    constructor(http, applicationContextService) {
        this.http = http;
        this.applicationContextService = applicationContextService;
    }
    add(model) {
        return this.http.post(`${environment.apiBaseUrl}/company/add`, model).pipe(tap(() => {
            this.applicationContextService.update();
        }), catchError(error => {
            throw error;
        }));
    }
    edit(model) {
        return this.http.patch(`${environment.apiBaseUrl}/company/edit`, model).pipe(tap(() => {
            this.applicationContextService.update();
        }), catchError(error => {
            throw error;
        }));
    }
    get(id) {
        return this.http.get(`${environment.apiBaseUrl}/company/${id}`);
    }
    getBasicInformation(id) {
        return this.http.get(`${environment.apiBaseUrl}/company/${id}`);
    }
    delete(model) {
        return this.http.delete(`${environment.apiBaseUrl}/company/${model.id}/${model.reassignedCompanyId}`).pipe(tap(() => {
            this.applicationContextService.update();
        }), catchError(error => {
            throw error;
        }));
    }
    toggleStatus(model) {
        return this.http.patch(`${environment.apiBaseUrl}/company/toggle-status`, model).pipe(tap(() => {
            this.applicationContextService.update();
        }), catchError(error => {
            throw error;
        }));
    }
    query(dataTablesParameters) {
        return this.http.post(`${environment.apiBaseUrl}/company/query`, dataTablesParameters, {});
    }
    deleteDocument(fileName) {
        return this.http.delete(`${environment.apiBaseUrl}/company/delete-document/${fileName}`);
    }
    getCompanies() {
        return this.http.get(`${environment.apiBaseUrl}/company/companies`);
    }
    getCompaniesWithStores() {
        return this.http.get(`${environment.apiBaseUrl}/company/companies/with/stores`);
    }
    getLoggedInUserCompaniesWithStores() {
        return this.http.get(`${environment.apiBaseUrl}/user/companies/with/stores`);
    }
}
CompanyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanyService_Factory() { return new CompanyService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ApplicationContextService)); }, token: CompanyService, providedIn: "root" });
