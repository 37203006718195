import { PricingType } from "src/app/enums";

export class PriceMatrixListModel {
    public storeName: string;
    public categoryName: string;
    public subCategoryName: string;
    public unitOfMeasure: number;
    public isReconPackage: boolean;
    public isApprovalRequired: boolean;
    public checkInTypeName: string;

    public carLayoutPanelIds: Array<number>;
    public carLayoutPanelNames: Array<string>;
    public unitPrices: Array<number>;
    constructor() {
        this.carLayoutPanelIds = new Array<number>();
        this.carLayoutPanelNames = new Array<string>();
        this.unitPrices = new Array<number>();
    }
}