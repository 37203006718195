import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { UnitOfMeasure } from 'src/app/enums';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { CompanyWithStoreModel, PriceMatrixDetailModel, PriceMatrixUnitPriceDetailModel, SelectListModel, SubCategorySelectListModel } from 'src/app/models';
import { MessagingService, CategoryService, SubCategoryService, PriceMatrixService, CarLayoutPanelService, ApplicationContextService, CheckInTypeService, FilterStateService } from 'src/app/services';
import { conditionalValidator } from 'src/app/validators';

@Component({
    selector: 'app-edit-price-matrix',
    templateUrl: './edit.price-matrix.component.html',
    styleUrls: ['./edit.price-matrix.component.css']
})
export class EditPriceMatrixComponent implements OnInit, OnDestroy {
    @BlockUI('container-blockui-store') blockUI: NgBlockUI;

    UnitOfMeasure = UnitOfMeasure;
    submitted: boolean = false;
    frmPriceMatrix: FormGroup;
    stores: Array<SelectListModel> = new Array<SelectListModel>();
    categories: Array<SelectListModel> = new Array<SelectListModel>();
    subCategories: Array<SubCategorySelectListModel> = new Array<SubCategorySelectListModel>();
    checkInTypes: Array<SelectListModel> = new Array<SelectListModel>();
    panels: Array<SelectListModel> = new Array<SelectListModel>();
    model: PriceMatrixDetailModel;
    hasPanel: boolean = true;

    panelDropdownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true
    };

    selectedPanels: Array<SelectListModel> = new Array<SelectListModel>();
    companySubscription: any;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private messagingService: MessagingService,
        private categoryService: CategoryService,
        private subCategoryService: SubCategoryService,
        private carLayoutPanelService: CarLayoutPanelService,
        private checkInTypeService: CheckInTypeService,
        private priceMatrixService: PriceMatrixService,
        private applicationContextService: ApplicationContextService,
        private filterStateService: FilterStateService
    ) {
        filterStateService.changeFilterModelStatues(FilterPageType.priceMatrixManage, true);
        this.model = new PriceMatrixDetailModel();
        this.route.params.subscribe(params => this.model.id = Number(params.priceMatrxId));
    }


    ngOnInit() {
        this.initForm();
        this.loadDetail();

        this.companySubscription = this.applicationContextService.company$.subscribe((company: CompanyWithStoreModel) => {
            if (company == null) {
                this.stores = new Array<SelectListModel>();
            } else {
                this.stores = [...company.stores];
                this.frmPriceMatrix.controls.companyId.setValue(company.id);
            }
        });
    }

    ngOnDestroy(): void {
        this.companySubscription.unsubscribe();
    }

    private initForm() {
        this.frmPriceMatrix = this.formBuilder.group({
            companyId: ['', [Validators.required]],
            storeId: ['', [Validators.required]],
            checkInTypeId: ['', [Validators.required]],
            categoryId: ['', [Validators.required]],
            subCategoryId: ['', [conditionalValidator(() => this.subCategories.length > 0, Validators.required)]],
            unitOfMeasure: [UnitOfMeasure.Panel],
            unitPrices: this.formBuilder.array([]),
            isReconPackage: [false],
            isApprovalRequired: [false],
            isPORequired: [false],
            isSharedWorkQueue: [false],
            isInspectionRequired: [false],
            isInvoice: [false]
        });
    }

    getControls() {
        return (this.frmPriceMatrix.get('unitPrices') as FormArray).controls;
    }

    getUnitPriceFormArray() {
        return this.frmPriceMatrix.get('unitPrices') as FormArray;
    }

    private createUnitPrice(): FormGroup {
        return this.formBuilder.group({
            id: 0,
            price: [0, [Validators.required, Validators.min(0)]]
        });
    }

    public addUnitPrice(): void {
        this.getUnitPriceFormArray().push(this.createUnitPrice());
    }

    public removeUnitPrice(index: number): void {
        let items = this.getUnitPriceFormArray();
        if (items.length != 1) {
            items.removeAt(index);
        }
    }

    public onUnitOfMeasureChange() {
        let items = this.getUnitPriceFormArray();
        items.clear();
        items.push(this.createUnitPrice());
    }

    public onStoreSelectionChange(): void {
        this.blockUI.start();
        this.frmPriceMatrix.controls.categoryId.setValue('');
        this.categoryService.selectListByStore(this.frmPriceMatrix.controls.storeId.value)
            .subscribe((data) => {
                this.categories = data;
            }, error => {
                this.messagingService.ProcessErrorResponse(error);
            }, () => {
                this.blockUI.stop();
            });
    }

    public onCategorySelectionChange(event): void {
        if (!event.target.value) {
            this.subCategories = new Array<SubCategorySelectListModel>();
            return;
        }

        this.blockUI.start();
        this.frmPriceMatrix.controls.subCategoryId.setValue('');
        let storeId = this.frmPriceMatrix.controls.storeId.value as number;
        this.subCategoryService.selectListByStore(storeId, event.target.value)
            .subscribe((data) => {
                this.subCategories = data;
                this.frmPriceMatrix.controls.subCategoryId.updateValueAndValidity();

                if (this.subCategories.length > 0) {
                    this.frmPriceMatrix.controls.subCategoryId.enable();
                } else {
                    this.frmPriceMatrix.controls.subCategoryId.disable();
                    this.loadPanel(false);
                }
            }, error => {
                this.messagingService.ProcessErrorResponse(error);
            }, () => {
                this.blockUI.stop();
            });
    }

    public onSubCategorySelectionChange(event): void {
        if (!event.target.value) {
            this.panels = new Array<SelectListModel>();
            this.selectedPanels = new Array<SelectListModel>();
            return;
        }

        this.loadPanel(true);
    }


    public loadPanel(hasSubCategory): void {
        this.blockUI.start();
        let categoryId = this.frmPriceMatrix.controls.categoryId.value as number;
        let subCategoryId = this.frmPriceMatrix.controls.subCategoryId.value as number;

        this.selectedPanels = new Array<SelectListModel>();
        if (!hasSubCategory) {
            this.carLayoutPanelService.selectListByCategory(categoryId)
                .subscribe((data) => {
                    this.panels = data;
                    this.enablePanelSelection();
                }, error => {
                    this.messagingService.ProcessErrorResponse(error);
                }, () => {
                    this.blockUI.stop();
                });

        } else {
            this.carLayoutPanelService.selectListByCategoryAndSubCategory(categoryId, subCategoryId)
                .subscribe((data) => {
                    this.panels = data;
                    this.enablePanelSelection();
                }, error => {
                    this.messagingService.ProcessErrorResponse(error);
                }, () => {
                    this.blockUI.stop();
                });
        }
    }

    private enablePanelSelection() {
        this.hasPanel = this.panels.length > 0;
        if (this.hasPanel == false) {
            this.frmPriceMatrix.controls.unitOfMeasure.setValue(UnitOfMeasure.Vehicle);
            this.onUnitOfMeasureChange();
        }
    }

    loadDetail() {
        this.blockUI.start();
        this.priceMatrixService.get(this.model.id).subscribe((data: any) => {
            this.model = data;
            this.loadData();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    private loadData() {
        this.blockUI.start();
        forkJoin([
            this.categoryService.selectListByStore(this.model.storeId),
            this.subCategoryService.selectListByStore(this.model.storeId, this.model.categoryId),
            this.carLayoutPanelService.selectListByCategory(this.model.categoryId),
            this.carLayoutPanelService.selectListByCategoryAndSubCategory(this.model.categoryId, this.model.subCategoryId),
            this.checkInTypeService.getFilteredCheckInTypes(this.frmPriceMatrix.value.companyId, this.model.storeId)
        ]).subscribe(([
            categories,
            subCategories,
            categoryPanels,
            subCategoryPanels,
            checkInTypes
        ]) => {
            this.categories = categories;
            this.subCategories = subCategories;
            this.checkInTypes = checkInTypes;

            if (subCategoryPanels.length > 0) {
                this.panels = subCategoryPanels;
            } else {
                this.panels = categoryPanels;
            }

            this.fillFormData();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        })
    }

    private fillFormData() {
        this.frmPriceMatrix.patchValue({
            storeId: this.model.storeId,
            checkInTypeId: this.model.checkInTypeId,
            categoryId: this.model.categoryId,
            subCategoryId: this.model.subCategoryId,
            unitOfMeasure: this.model.unitOfMeasure,
            isReconPackage: this.model.isReconPackage,
            isApprovalRequired: this.model.isApprovalRequired,
            isPORequired: this.model.isPORequired,
            isSharedWorkQueue: this.model.isSharedWorkQueue,
            isInspectionRequired: this.model.isInspectionRequired,
            isInvoice: this.model.isInvoice
        });

        this.frmPriceMatrix.controls.subCategoryId.updateValueAndValidity();
        if (this.model.subCategoryId > 0) {
            this.frmPriceMatrix.controls.subCategoryId.enable();
        } else {
            this.frmPriceMatrix.controls.subCategoryId.disable();
        }

        this.frmPriceMatrix.controls.subCategoryId.updateValueAndValidity();
        if (this.model.subCategoryId > 0) {
            this.frmPriceMatrix.controls.subCategoryId.enable();
        } else {
            this.frmPriceMatrix.controls.subCategoryId.disable();
        }
        
        this.loadPanel(this.model.subCategoryId > 0);

        setTimeout(()=>{
            let items = this.frmPriceMatrix.get('unitPrices') as FormArray;
            items.clear();
            this.model.unitPrices.forEach(unitPrice => {
                let minValue = 0;
                items.push(this.formBuilder.group({
                    id: unitPrice.id,
                    price: [unitPrice.price, [Validators.required, Validators.min(minValue)]],
                }));
            });
        },200);

        this.selectedPanels = this.panels.filter(x => this.model.carLayoutPanelIds.includes(x.id));
    }

    public onSubmit() {
        this.submitted = true;
        if (this.frmPriceMatrix.invalid) {
            return;
        }

        this.blockUI.start();

        this.model.storeId = Number(this.frmPriceMatrix.controls.storeId.value);
        this.model.checkInTypeId = Number(this.frmPriceMatrix.controls.checkInTypeId.value);
        this.model.categoryId = Number(this.frmPriceMatrix.controls.categoryId.value);

        let subCategoryId = Number(this.frmPriceMatrix.controls.subCategoryId.value)
        this.model.subCategoryId = subCategoryId > 0 && this.subCategories.length > 0 ? subCategoryId : null;

        this.model.carLayoutPanelIds = this.selectedPanels.map(x => x.id);

        this.model.unitOfMeasure = this.frmPriceMatrix.controls.unitOfMeasure.value;
        this.model.unitPrices = this.frmPriceMatrix.controls.unitPrices.value
            .filter((x: any) => x.price != null && (x.price as number) >= 0).map((x: any) => {
                let unitPrice = new PriceMatrixUnitPriceDetailModel();
                unitPrice.id = x.id;
                unitPrice.price = Number(x.price);

                return unitPrice;
            });

        this.model.isApprovalRequired = this.frmPriceMatrix.controls.isApprovalRequired.value;
        this.model.isReconPackage = this.frmPriceMatrix.controls.isReconPackage.value;
        this.model.isPORequired = this.frmPriceMatrix.controls.isPORequired.value;
        this.model.isSharedWorkQueue = this.frmPriceMatrix.controls.isSharedWorkQueue.value;
        this.model.isInspectionRequired = this.frmPriceMatrix.controls.isInspectionRequired.value;
        this.model.isInvoice = this.frmPriceMatrix.controls.isInvoice.value;

        this.priceMatrixService.update(this.model)
            .subscribe(() => {
                setTimeout(() => {
                    this.router.navigate(['/manage/price-matrix']);
                }, 10);
                setTimeout(() => {
                    this.toastr.success('Price matrix has been updated successfully.');
                }, 300);
            }, error => {
                this.messagingService.ProcessErrorResponse(error);
            }, () => {
                this.blockUI.stop();
            });
    }
}
