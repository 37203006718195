
import { CurrencyPipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { UnitOfMeasure } from "src/app/enums";
import { FilterPageType } from "src/app/enums/filter.page.type.enum";
import { PriceMatrixDetailModel } from "src/app/models";
import { FilterStateService, MessagingService, PriceMatrixService } from "src/app/services";


@Component({
    selector: 'app-detail-price-matrix',
    templateUrl: './detail.price-matrix.component.html',
    styleUrls: ['./detail.price-matrix.component.css']
})

export class PriceMatrixDetailComponent implements OnInit {

    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild('deleteConfirmationModal', { static: false }) deleteConfirmationModal: any;
    @ViewChild('statusChangeConfirmationModal', { static: true }) statusChangeConfirmationModal: any;

    UnitOfMeasure = UnitOfMeasure;
    model: PriceMatrixDetailModel;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private messagingService: MessagingService,
        private priceMatrixService: PriceMatrixService,
        private currencyPipe: CurrencyPipe,
        private filterStateService: FilterStateService    ) {
            filterStateService.changeFilterModelStatues(FilterPageType.priceMatrixManage, true);
        this.model = new PriceMatrixDetailModel();
        this.route.params.subscribe(params => this.model.id = Number(params.priceMatrxId));
    }

    ngOnInit() {
        this.loadDetail();
    }

    loadDetail() {
        this.blockUI.start();
        this.priceMatrixService.get(this.model.id).subscribe((data: any) => {
            this.model = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    openDeleteModal() {
        this.deleteConfirmationModal.openModal(this.model);
    }

    delete(priceMatrix: PriceMatrixDetailModel) {
        this.blockUI.start();
        this.priceMatrixService.delete(priceMatrix.id).subscribe(
            () => {
                setTimeout(() => {
                    this.router.navigate(['manage/price-matrix']);
                });
                this.messagingService.success(`Price matrix has been deleted successfully.`);
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
            });
    }

    openStatusModal() {
        this.statusChangeConfirmationModal.openModal(this.model);
    }

    toggle(checkInType: PriceMatrixDetailModel) {
        this.blockUI.start();
        this.priceMatrixService.toggleStatus(checkInType.id).subscribe(
            () => {
                this.messagingService.success(`Price matrix has been ${(checkInType.isActive ? 'deactivated' : 'activated')} successfully.`);
                this.model.isActive = !this.model.isActive;
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
            });
    }

    getPanelNames(){
        return this.model.carLayoutPanelNames.join(', ');
    }
    
    getUnitPrices() {
        let prices = this.model.unitPrices.map(x => x.price);
        if (this.model.unitOfMeasure == UnitOfMeasure.Vehicle) {
            return `<span>$ ${prices[0]}`;
        }

        let prefix = this.model.unitOfMeasure == UnitOfMeasure.Spot ? 'Spot' : 'Panel';
        
        return prices.map((price, index) => {
            const totalPrice = this.currencyPipe.transform(price, 'USD' , '', '1.2-2');
            return `<span>${prefix} ${index + 1}: $ ${totalPrice} </span>`;
        }).join('<br/> ');
    }
}