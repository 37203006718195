import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { AuthenticationService } from "../services";

@Injectable()
export class PermissionGuard implements CanActivate {

    constructor(private router: Router, private authenticationService: AuthenticationService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        // Assuming user is valid. Only check for permissions
        if (route.data.permissions == null || route.data.permissions.length == 0) {
            return of(true);
        }

        // If user has valid permission
        // Then proceed otherwise redirect it to forbidden page
        return this.authenticationService.validatePermission(route.data.permissions).pipe(map(valid => {
            if (!valid) {
                this.router.navigate(['/error/403']);
            }
            return valid;
        }));
    }
}