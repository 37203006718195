import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { VehicleType } from 'src/app/enums';

@Component({
	selector: 'app-vehicle-type-confirmation',
	templateUrl: './vehicle.type.confirmation.component.html',
	styleUrls: ['./vehicle.type.confirmation.component.css']
})

export class VehicleTypeConfirmationComponent implements OnInit, OnDestroy {

	@ViewChild('selectVehicle', { static: false }) confirmationModal: any;
	@Output() onSelectVehicleType = new EventEmitter<VehicleType>();

	car: string = "/assets/images/car.png";
	suv: string = "/assets/images/suv.png";
	truck: string = "/assets/images/truck.png";
	frmVehicleType: FormGroup;
	modalReference: NgbModalRef;
	VehicleType = VehicleType;

	constructor(
		private fb: FormBuilder,
		private modalService: NgbModal
	) {
	}

	ngOnInit() {
		this.frmVehicleType = this.fb.group({
			vehicleType: [VehicleType.Car]
		});
	}

	openModal() {
		this.modalReference = this.modalService.open(this.confirmationModal, { size: 'lg', centered: true });
	}

	onSubmit() {
		setTimeout(() => {
			this.onSelectVehicleType.emit(this.frmVehicleType.controls.vehicleType.value);
		});
		this.closeModal();
	}

	closeModal() {
		if (!this.modalReference) {
			return;
		}
		this.modalReference.close();
	}

	ngOnDestroy(): void {
		this.closeModal();
	}
}
