import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { UnitOfMeasure } from 'src/app/enums';
import { CompanyWithStoreModel, PriceMatrixAddModel, SelectListModel, SubCategorySelectListModel } from 'src/app/models';
import { ApplicationContextService, CarLayoutPanelService, CategoryService, CheckInTypeService, MessagingService, PriceMatrixService, SubCategoryService } from 'src/app/services';
import { conditionalValidator } from 'src/app/validators';

@Component({
    selector: 'app-add-pricing-matrix',
    templateUrl: './add.price-matrix.component.html',
    styleUrls: ['./add.price-matrix.component.css']
})
export class AddPriceMatrixComponent implements OnInit, OnDestroy {
    @BlockUI('container-blockui-store') blockUI: NgBlockUI;

    UnitOfMeasure = UnitOfMeasure;
    submitted: boolean = false;
    hasPanel: boolean = true;
    frmPriceMatrix: FormGroup;

    stores: Array<SelectListModel> = new Array<SelectListModel>();
    model: PriceMatrixAddModel = new PriceMatrixAddModel();
    panels: Array<SelectListModel> = new Array<SelectListModel>();
    categories: Array<SelectListModel> = new Array<SelectListModel>();
    subCategories: Array<SubCategorySelectListModel> = new Array<SubCategorySelectListModel>();
    checkInTypes: Array<SelectListModel> = new Array<SelectListModel>();

    panelDropdownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true
    };

    selectedPanels: Array<SelectListModel> = new Array<SelectListModel>();
    storeSubscription: any;
    companySubscription: any;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private toastr: ToastrService,
        private messagingService: MessagingService,
        private categoryService: CategoryService,
        private checkInTypeService: CheckInTypeService,
        private subCategoryService: SubCategoryService,
        private carLayoutPanelService: CarLayoutPanelService,
        private priceMatrixService: PriceMatrixService,
        private applicationContextService: ApplicationContextService
    ) {

    }

    ngOnInit() {
        this.initForm();
    }

    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
        this.companySubscription.unsubscribe();
    }

    private initForm() {
        this.frmPriceMatrix = this.formBuilder.group({
            companyId: ['', [Validators.required]],
            storeId: ['', [Validators.required]],
            checkInTypeId: ['', [Validators.required]],
            categoryId: ['', [Validators.required]],
            subCategoryId: ['', [conditionalValidator(() => this.subCategories.length > 0, Validators.required)]],
            unitOfMeasure: [UnitOfMeasure.Panel],
            unitPrices: this.formBuilder.array([this.createUnitPrice()]),
            isReconPackage: [false],
            isApprovalRequired: [false],
            isPORequired: [false],
            isSharedWorkQueue: [false],
            isInspectionRequired: [false],
            isInvoice: [false]
        });

        //this.setValidationForFirstUnitPrice();

        this.companySubscription = this.applicationContextService.company$.subscribe((company: CompanyWithStoreModel) => {
            if (company == null) {
                this.stores = new Array<SelectListModel>();
            } else {
                this.stores = [...company.stores];
                this.frmPriceMatrix.controls.companyId.setValue(company.id);
            }
        });

        this.storeSubscription = this.applicationContextService.store$.subscribe((storeSelectListItem: SelectListModel) => {
            if (storeSelectListItem) {
                this.frmPriceMatrix.controls.storeId.setValue(storeSelectListItem.id);
                this.frmPriceMatrix.controls.storeId.disable();
                this.onStoreSelectionChange();
                this.loadCheckInTypes();
            }
        });
    }

    private setValidationForFirstUnitPrice() {
        let unitPriceControls = this.getControls();
        if (unitPriceControls.length == 0)
            return;

        let firstControl = unitPriceControls[0] as FormGroup;
        firstControl.controls.price.setValidators([Validators.required, Validators.min(1)]);
        firstControl.controls.price.updateValueAndValidity();
    }

    private createUnitPrice(): FormGroup {
        return this.formBuilder.group({
            price: ['', [Validators.required, Validators.min(0)]]
        });
    }

    getControls() {
        return (this.frmPriceMatrix.get('unitPrices') as FormArray).controls;
    }

    getUnitPriceFormArray() {
        return this.frmPriceMatrix.get('unitPrices') as FormArray;
    }

    public addUnitPrice(): void {
        this.getUnitPriceFormArray().push(this.createUnitPrice());
        //this.setValidationForFirstUnitPrice();
    }

    public removeUnitPrice(index): void {
        let items = this.getUnitPriceFormArray();
        if (items.length != 1) {
            items.removeAt(index);
        }
        //this.setValidationForFirstUnitPrice();
    }

    public onUnitOfMeasureChange() {
        let items = this.getUnitPriceFormArray();
        items.clear();
        items.push(this.createUnitPrice());
        //this.setValidationForFirstUnitPrice();
    }

    public loadCheckInTypes(): void {
        this.blockUI.start();
        this.checkInTypeService.getFilteredCheckInTypes(
            this.frmPriceMatrix.controls.companyId.value,
            this.frmPriceMatrix.controls.storeId.value)
            .subscribe((data) => {
                this.checkInTypes = data;
            }, error => {
                this.messagingService.ProcessErrorResponse(error);
            }, () => {
                this.blockUI.stop();
            });
    }

    public onStoreSelectionChange(): void {
        this.blockUI.start();
        this.categoryService.selectListByStore(this.frmPriceMatrix.controls.storeId.value)
            .subscribe((data) => {
                this.categories = data;
            }, error => {
                this.messagingService.ProcessErrorResponse(error);
            }, () => {
                this.blockUI.stop();
            });
    }

    public onCategorySelectionChange(event): void {
        if (!event.target.value) {
            this.frmPriceMatrix.controls.subCategoryId.setValue(null);
            this.subCategories = new Array<SubCategorySelectListModel>();
            return;
        }

        this.blockUI.start();
        let storeId = this.frmPriceMatrix.controls.storeId.value as number;
        this.subCategoryService.selectListByStore(storeId, event.target.value)
            .subscribe((data) => {
                this.subCategories = data;
                this.frmPriceMatrix.controls.subCategoryId.updateValueAndValidity();

                if (this.subCategories.length > 0) {
                    this.frmPriceMatrix.controls.subCategoryId.enable();
                } else {
                    this.frmPriceMatrix.controls.subCategoryId.disable();
                    this.loadPanel(false);
                }
            }, error => {
                this.messagingService.ProcessErrorResponse(error);
            }, () => {
                this.blockUI.stop();
            });
    }

    public onSubCategorySelectionChange(event): void {
        if (!event.target.value) {
            this.panels = new Array<SelectListModel>();
            this.selectedPanels = new Array<SelectListModel>();
            return;
        }

        this.loadPanel(true);
    }


    public loadPanel(hasSubCategory): void {
        this.blockUI.start();
        let categoryId = this.frmPriceMatrix.controls.categoryId.value as number;
        let subCategoryId = this.frmPriceMatrix.controls.subCategoryId.value as number;

        this.selectedPanels = new Array<SelectListModel>();
        if (!hasSubCategory) {
            this.carLayoutPanelService.selectListByCategory(categoryId)
                .subscribe((data) => {
                    this.panels = data;
                    this.enablePanelSelection();
                }, error => {
                    this.messagingService.ProcessErrorResponse(error);
                }, () => {
                    this.blockUI.stop();
                });

        } else {
            this.carLayoutPanelService.selectListByCategoryAndSubCategory(categoryId, subCategoryId)
                .subscribe((data) => {
                    this.panels = data;
                    this.enablePanelSelection();
                }, error => {
                    this.messagingService.ProcessErrorResponse(error);
                }, () => {
                    this.blockUI.stop();
                });
        }
    }

    private enablePanelSelection() {
        this.hasPanel = this.panels.length > 0
        if (!this.hasPanel) {
            this.frmPriceMatrix.controls.unitOfMeasure.setValue(UnitOfMeasure.Vehicle);
            this.onUnitOfMeasureChange();
        }
    }

    public onSubmit() {
        this.submitted = true;
        if (this.frmPriceMatrix.invalid) {
            return;
        }

        if (this.selectedPanels.length == 0 && this.frmPriceMatrix.controls.unitOfMeasure.value != UnitOfMeasure.Vehicle) {
            return;
        }

        this.blockUI.start();
        this.model.storeId = Number(this.frmPriceMatrix.controls.storeId.value);
        this.model.checkInTypeId = Number(this.frmPriceMatrix.controls.checkInTypeId.value);
        this.model.categoryId = Number(this.frmPriceMatrix.controls.categoryId.value);

        let subCategoryId = Number(this.frmPriceMatrix.controls.subCategoryId.value);
        this.model.subCategoryId = subCategoryId > 0 && this.subCategories.length > 0 ? subCategoryId : null;
        this.model.carLayoutPanelIds = this.selectedPanels.map(x => x.id);

        this.model.unitOfMeasure = this.frmPriceMatrix.controls.unitOfMeasure.value;
        // this.model.unitPrices = Number.parseFloat();
        this.model.unitPrices = this.frmPriceMatrix.controls.unitPrices.value
            .filter((x: any) => x.price != null && (x.price as number) >= 0).map((x: any) => Number(x.price));

        this.model.isApprovalRequired = this.frmPriceMatrix.controls.isApprovalRequired.value;
        this.model.isReconPackage = this.frmPriceMatrix.controls.isReconPackage.value;
        this.model.isPORequired = this.frmPriceMatrix.controls.isPORequired.value;
        this.model.isSharedWorkQueue = this.frmPriceMatrix.controls.isSharedWorkQueue.value;
        this.model.isInspectionRequired = this.frmPriceMatrix.controls.isInspectionRequired.value;
        this.model.isInvoice = this.frmPriceMatrix.controls.isInvoice.value;

        this.priceMatrixService.add(this.model)
            .subscribe(() => {
                setTimeout(() => {
                    this.router.navigate(['/manage/price-matrix']);
                }, 10);
                setTimeout(() => {
                    this.toastr.success('Price matrix has been created successfully.');
                }, 300);
            }, error => {
                this.messagingService.ProcessErrorResponse(error);
            }, () => {
                this.blockUI.stop();
            });
    }
}
