import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AuthenticateRequestModel } from 'src/app/models';
import { AuthenticationService, MessagingService } from 'src/app/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @BlockUI('blockui-frm-login') blockUI: NgBlockUI;
  returnUrl: string;

  loginImage: string = "assets/images/login-img.png";
  loginImage2: string = "assets/images/login-img2.png";
  logo: string = "assets/images/recon-partners-logo.png";

  form: FormGroup;
  submitted = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private messagingService: MessagingService,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder) {

    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      userName: ['', [
        Validators.required
      ]],
      password: ['', [
        Validators.required
      ]]
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid.
    if (this.form.invalid) {
      return;
    }

    this.blockUI.start();
    let authenticateRequestModel = new AuthenticateRequestModel();
    authenticateRequestModel.userName = this.form.value.userName;
    authenticateRequestModel.password = this.form.value.password;
    this.authenticationService.login(authenticateRequestModel).subscribe((isLoggedIn: boolean) => {
      if (!isLoggedIn) {
        return;
      }

      if (this.returnUrl) {
        this.router.navigate([this.returnUrl]);
      } else {
        this.router.navigate(['/']);
      }
      this.blockUI.stop();
    }, error => {
      this.messagingService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }
}
