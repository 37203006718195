import { PricingType } from "src/app/enums";

export class PriceMatrixAddModel {
    public storeId: number;
    public categoryId: number;
    public subCategoryId: number;
    public carLayoutPanelIds: Array<number>;
    public unitOfMeasure: number;
    public unitPrices: number[];
    public checkInTypeId: number;
    public isReconPackage: boolean;
    public isApprovalRequired: boolean;
    public isPORequired: boolean;
    public isSharedWorkQueue: boolean;
    public isInspectionRequired: boolean;
    public isInvoice: boolean;

    constructor() {
        this.carLayoutPanelIds = new Array<number>();
    }
}