<section class="workFlowSection">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <a [routerLink]="['/manage/workflow']" class="backtolist">
                    <em class="fa fa-long-arrow-left" aria-hidden="true"></em>
                    Back to list
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-10 col-xl-10 offset-xl-1 offset-lg-1">
                <div class="compnydtlwrp addRolePwrp">
                    <div class="workflowContentWr" *blockUI="'container-blockui'">
                        <div class="row">
                            <div class="col-8 col-md-9">
                                <h2> Workflow Detail</h2>
                            </div>
                            <div class="col-4 col-md-3" *ngIf="!workflowModel.isDeprecated">
                                <div class="form-group">
                                    <div ngbDropdown class="actionWrp">
                                        <button class="btn btn-red" id="dropdownBasic1" ngbDropdownToggle>Actions</button>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                            <ul>
                                                <li>
                                                    <a ngbDropdownItem href="javascript:void(0);"
                                                        [routerLink]="['/workflow',workflowModel.id, 'edit']">
                                                        <em class="fa fa-edit" aria-hidden="true"></em>
                                                        Edit
                                                    </a>
                                                </li>
                                                <li>
                                                    <a ngbDropdownItem href="javascript:void(0);"
                                                        (click)="openStatusChangeConfirmation()">
                                                        <em class="fa fa-times-circle-o" aria-hidden="true"></em>
                                                        <span
                                                            [innerHTML]="workflowModel.isActive?'Deactivate':'Activate'"></span>
                                                    </a>
                                                </li>
                                                <li><a ngbDropdownItem href="javascript:void(0);"
                                                        (click)="openDeleteConfirmation()">
                                                        <em class="fa fa-trash-o" aria-hidden="true"></em>
                                                        Delete
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <div class="form-group">
                                    <label>Check-in Type</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label>{{checkInTypeName}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <div class="form-group">
                                    <label>Is Default</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label>{{workflowModel.isDefault ? 'Yes' : 'No'}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="stageWrp"
                            *ngFor="let workflowSequenceModel of workflowModel.workflowDynamicSequences; let i = index;">
                            <h3>Dynamic Sequence {{i+1}}</h3>
                            <div class="stageBox">
                                <div class="taskWr">
                                    <div
                                        *ngFor="let workflowStageModel of workflowSequenceModel.workflowStages; let j = index;">
                                        <app-workflow-stage-detail-widget [categories]='categories'
                                            [subCategories]='subCategories' [workflowStageModel]="workflowStageModel">
                                        </app-workflow-stage-detail-widget>
                                    </div>
    
                                </div>
                            </div>
                        </div>
    
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-workflow-delete-confirmation #deleteConfirmationModal (delete)="delete()">
</app-workflow-delete-confirmation>
<app-workflow-status-change-confirmation #statusChangeConfirmationModal (toggle)="toggle()">
</app-workflow-status-change-confirmation>