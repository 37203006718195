

import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { ToastrService } from "ngx-toastr";
import { StringLengthConstants } from "src/app/helpers";
import { CheckInTypeAddModel, CheckInTypeDetailModel } from "src/app/models";
import { CheckInTypeService, MessagingService } from "src/app/services";


@Component({
    selector: 'app-add-check-in-type',
    templateUrl: './add.check-in-type.component.html',
    styleUrls: ['./add.check-in-type.component.css']
})

export class CheckInTypeAddComponent implements OnInit {
    frmCheckInType: FormGroup;
    submitted: boolean = false;
    model: CheckInTypeAddModel;
    @BlockUI('blockui-container') blockUI: NgBlockUI;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private toastr: ToastrService,
        private messagingService: MessagingService,
        private checkInTypeService: CheckInTypeService,
        private stringLengthConstants: StringLengthConstants
    ) {
        this.model = new CheckInTypeAddModel();
    }

    ngOnInit() {
        this.frmCheckInType = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            description: ['', [Validators.maxLength(this.stringLengthConstants.Description)]]
        });
    }

    onSubmit() {
        this.submitted = true;
        if (this.frmCheckInType.invalid) {
            return;
        }

        this.model.name = this.frmCheckInType.controls.name.value;
        this.model.description = this.frmCheckInType.controls.description.value;
        
        this.checkInTypeService.add(this.model)
            .subscribe((data: CheckInTypeDetailModel) => {
                setTimeout(() => {
                    //this.router.navigate(['/check-in-type/detail', data.id]);
                    this.router.navigate(['add/workflow/check-in-type', data.id])
                }, 10);
                setTimeout(() => {
                    this.toastr.success('Check-In type has been added successfully.');
                }, 300);
                this.blockUI.stop();
            }, error => {
                this.messagingService.ProcessErrorResponse(error);
                this.blockUI.stop();
            });
    }
}