import { ApplicationRoleType } from "src/app/enums";

export class RoleEditModel {
    public id: string;
    public name: string;
    public applicationRoleType: ApplicationRoleType;
    public permissions: Array<number>;
    constructor() {
        this.permissions = new Array<number>();
    }
}