import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'app-403',
    templateUrl: './error.403.component.html',
    styleUrls:['./error.403.component.css']
})

export class Error403Component {
    @HostBinding('class.full_width') full_width = true;
}

