import { OperationCodePriceModel, VehicleVideoAddModel } from "../..";

export class VehicleAssessmentAddModel {
    public vehicleQueueId: number;
    public storeId: number;
    public vehicleQueueStageId: number;
    public operationCodePrices: Array<OperationCodePriceModel>;
    public vehicleVideos: Array<VehicleVideoAddModel>;

    constructor() {
        this.operationCodePrices = new Array<OperationCodePriceModel>();
        this.vehicleVideos = new Array<VehicleVideoAddModel>();
    }
}