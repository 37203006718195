import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Category, WorkStatus } from 'src/app/enums';
import { StringLengthConstants, AppUtils } from 'src/app/helpers';
import { OperationCodeSelectListModel, ReconPackageApprovalModel, ReconPackageItemModel, SelectListModel, SubCategorySelectListModel, VehicleQueueModel, VehicleQueueStageModel, VehicleWorkDetailModel } from 'src/app/models';
import { ApplicationContextService, MessagingService, VehicleQueueService, OperationCodeService, PriceMatrixService, CategoryService, SubCategoryService, CarLayoutPanelService, CarLayoutLocationService, CarLayoutSideService, ApprovalService } from 'src/app/services';

@Component({
  selector: 'app-recon-package',
  templateUrl: './recon-package.component.html',
  styleUrls: ['./recon-package.component.css']
})
export class ReconPackageComponent {
  @BlockUI('container-blockui') blockUI: NgBlockUI;
  frmReconPkg: FormGroup;
  private vehicleQueueId: number;
  private vehicleQueueStageId: number;
  vehicleQueueModel: VehicleQueueModel = new VehicleQueueModel();
  vehicleQueueStageModel: VehicleQueueStageModel = new VehicleQueueStageModel();
  vehicleWorkDetailModel: Array<VehicleWorkDetailModel> = new Array<VehicleWorkDetailModel>();

  categories: Array<SelectListModel> = new Array<SelectListModel>();
  subCategories: Array<SubCategorySelectListModel> = new Array<SubCategorySelectListModel>();
  carPanels: Array<SelectListModel> = new Array<SelectListModel>();
  carLocations: Array<SelectListModel> = new Array<SelectListModel>();
  carSides: Array<SelectListModel> = new Array<SelectListModel>();
  reconPkgOpCodes: Array<OperationCodeSelectListModel> = new Array<OperationCodeSelectListModel>();

  internalTotalCost: number = 0;
  reconditioningTotalCost: number = 0;
  reconditioningCost: number = 0;
  reconditioningNet: number = 0;
  addedCosmeticTotalCost: number = 0;
  quotedTotalCost: number = 0;
  totalStoreCost: number = 0;
  netCost: number = 0;

  constructor(private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private stringLengthConstants: StringLengthConstants,
    private messagingService: MessagingService,
    private vehicleQueueService: VehicleQueueService,
    private operationCodeService: OperationCodeService,
    private approvalService: ApprovalService
  ) {
    this.activatedRoute.params.subscribe((data) => {
      this.vehicleQueueId = data.vehicleQueueId;
      this.vehicleQueueStageId = data.vehicleQueueStageId;
    });
  }



  ngOnInit() {
    this.frmReconPkg = this.formBuilder.group({
      opCodes: this.formBuilder.array([]),
    });

    this.loadVehicleQueue();
  }

  private loadData() {
    this.blockUI.start();
    forkJoin([
      this.operationCodeService.getReconPackageOperationCodes(this.vehicleQueueModel.storeId, this.vehicleQueueModel.workflowId),
      this.vehicleQueueService.getApprovWorkDetails(this.vehicleQueueId)
    ]).subscribe(([
      reconPkgOpCodes,
      workDetails
    ]) => {
      this.reconPkgOpCodes = reconPkgOpCodes;
      this.vehicleWorkDetailModel = workDetails;
      this.vehicleWorkDetailModel.filter(x => x.isReconPackage || x.operationCodeDetailModel.categoryName == Category.ReconPackage)
        .forEach(item => this.getFormArray().push(this.createOpCode(item)));
      this.calculatePrice();
    }, error => {
      this.messagingService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }

  private loadVehicleQueue() {
    this.blockUI.start();
    this.vehicleQueueService.get(this.vehicleQueueId)
      .subscribe((vehicleQueue) => {
        if (!vehicleQueue) {
          this.messagingService.ProcessErrorResponse(null, "This vehicle is not belongs to the selected store");
          return;
        }

        this.vehicleQueueModel = vehicleQueue;
        this.vehicleQueueStageModel = this.vehicleQueueModel.vehicleQueueStages.find(x => x.id == this.vehicleQueueStageId);

        this.loadData();

      }, error => {
        this.messagingService.ProcessErrorResponse(error);
      }, () => {
        this.blockUI.stop();
      });
  }

  public getFormArrayControls() {
    return (this.frmReconPkg.controls.opCodes as FormArray).controls;
  }

  private getFormArray() {
    return this.frmReconPkg.controls.opCodes as FormArray;
  }

  private getOperationCode() {
    let opCodeIds = new Array<number>();
    (this.frmReconPkg.controls.opCodes as FormArray).controls.forEach((elem, index) => {
      let fb = elem as FormGroup;
      opCodeIds.push(Number(fb.controls.operationCodeId.value));
    });

    return this.vehicleWorkDetailModel.filter(x =>
      !opCodeIds.includes(x.operationCodeId));
  }

  public viewModels: Array<any> = new Array<any>();

  private createOpCode(data: VehicleWorkDetailModel = undefined) {
    let index = this.getFormArray().length;
    this.viewModels[index] = {
      operationCodes: this.getOperationCode(),
    };

    let frm = this.formBuilder.group({
      workDetailId: [data ? data.id : ''],
      operationCodeId: [{ value: data ? data.operationCodeId : '', disabled: data ? true : false },
      [Validators.required]],
      categoryName: [
        { value: data ? data.operationCodeDetailModel.categoryName : '', disabled: data ? true : false },
        [Validators.required]
      ],
      subCategoryName: [
        { value: data ? data.operationCodeDetailModel.subCategoryName : '', disabled: data ? true : false },
      ],
      locationName: [
        { value: data ? data.operationCodeDetailModel.carLayoutLocationName : '', disabled: data ? true : false },
      ],
      panelName: [
        { value: data ? data.operationCodeDetailModel.carLayoutPanelName : '', disabled: data ? true : false },
      ],
      sideName: [
        { value: data ? data.operationCodeDetailModel.carLayoutSideName : '', disabled: data ? true : false },
      ],
      cost: [
        { value: data ? data.cost : 0, disabled: data ? true : false },
        [Validators.required, Validators.min(0)]
      ],
      part: [
        { value: data ? data.partPrice : 0, disabled: data ? true : false },
        [Validators.required, Validators.min(0)]
      ],
      labor: [
        { value: data ? data.laborPrice : 0, disabled: data ? true : false },
        [Validators.required, Validators.min(0)]
      ],
      total: [{ value: data ? (data.cost + data.partPrice + data.laborPrice) : 0, disabled: data ? true : false }],
      comment: [
        { value: '', disabled: data ? true : false },
        [Validators.maxLength(this.stringLengthConstants.Default)]
      ],
      addedDate: [data ? data.addedOn : new Date()],
      workerName: [data ? data.workerUser : ''],
      queueEntryDate: [data ? data.addedOn : ''],
      queueStatus: [data ? WorkStatus[data.workStatus] : ''],
      queueCompletedDate: [data ? data.addedOn : ''],
      queueTime: [data ? '' : '']
    });


    frm.controls.cost.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
      this.calculatePrice();
    });

    frm.controls.part.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
      this.calculatePrice();
    });

    frm.controls.labor.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
      this.calculatePrice();
    });


    return frm;
  }

  public onOperationCodeChange(operationCodeId: number, index: number) {
    let workDetail = this.vehicleWorkDetailModel.find(x => x.operationCodeId == operationCodeId);

    let frmGroup = this.getFormArray().controls[index] as FormGroup;
    frmGroup.controls.categoryName.setValue(workDetail.operationCodeDetailModel.categoryName);
    frmGroup.controls.subCategoryName.setValue(workDetail.operationCodeDetailModel.subCategoryName);
    frmGroup.controls.panelName.setValue(workDetail.operationCodeDetailModel.carLayoutPanelName);
    frmGroup.controls.locationName.setValue(workDetail.operationCodeDetailModel.carLayoutLocationName);
    frmGroup.controls.sideName.setValue(workDetail.operationCodeDetailModel.carLayoutSideName);
    frmGroup.controls.cost.setValue(workDetail.cost);
    frmGroup.controls.part.setValue(workDetail.partPrice);
    frmGroup.controls.labor.setValue(workDetail.laborPrice);
  }

  public onAddOpCode() {
    let frm = this.createOpCode();
    this.getFormArray().push(frm);
  }

  public onRemoveOpCode(index: number) {
    this.getFormArray().removeAt(index);
  }

  public onEditOpCode(index: number) {
    let frmGroup = this.getFormArray().controls[index] as FormGroup;
    frmGroup.controls.cost.enable();
    frmGroup.controls.part.enable();
    frmGroup.controls.labor.enable();
    frmGroup.controls.comment.enable();
  }


  public calculatePrice() {

    this.reconditioningNet = 0;
    this.reconditioningTotalCost = 0;
    this.internalTotalCost = 0;
    this.totalStoreCost = 0;
    this.addedCosmeticTotalCost = 0;
    this.quotedTotalCost = 0;;

    if (this.vehicleWorkDetailModel.some(x => x.operationCodeDetailModel.categoryName == Category.ReconPackage)) {
      this.reconditioningTotalCost = this.vehicleWorkDetailModel.filter(x => x.operationCodeDetailModel.categoryName == Category.ReconPackage)[0].cost;
    }

    this.frmReconPkg.getRawValue().opCodes.forEach((x: any, index: number) => {
      let labor = Number(x.labor);
      let parts = Number(x.part);
      let cost = Number(x.cost);
      (this.getFormArrayControls()[index] as FormGroup).controls.total.setValue((cost + parts + labor));

      var workDetail = this.vehicleWorkDetailModel.find(x => x.id == x.id);

      this.totalStoreCost += x.total;

      if (workDetail.isReconPackage) {
        this.reconditioningCost += x.total;
      }

      // if (workDetail.isQuoteRequired) {
      //   this.quotedTotalCost += x.total;
      // }

      // if (!workDetail.isReconPackage && !workDetail.isQuoteRequired) {
      //   this.addedCosmeticTotalCost += x.total;
      // }
    });

    this.reconditioningNet = this.reconditioningTotalCost - this.reconditioningCost;

    // this.internalTotalCost = this.totalStoreCost - this.reconditioningTotalCost - this.addedCosmeticTotalCost - this.quotedTotalCost;

  }

  submitted: boolean = false;
  public onSubmit() {
    this.submitted = true;
    if (this.frmReconPkg.invalid) {
      return;
    }

    let model = new ReconPackageApprovalModel();
    model.vehicleQueueId = Number(this.vehicleQueueId);
    model.vehicleQueueStageId = Number(this.vehicleQueueStageId);
    (this.frmReconPkg.controls.opCodes as FormArray).controls.forEach((elem, index) => {
      let fb = elem as FormGroup;
      let reconItemModel = new ReconPackageItemModel();
      reconItemModel.workDetailId = Number(fb.controls.workDetailId.value);
      reconItemModel.operationCodeId = Number(fb.controls.operationCodeId.value);
      reconItemModel.cost = Number(fb.controls.cost.value);
      reconItemModel.parts = Number(fb.controls.part.value);
      reconItemModel.labor = Number(fb.controls.labor.value);
      reconItemModel.comment = fb.controls.comment.value;

      if (model.reconPackageItemModels.filter(x => x.operationCodeId == reconItemModel.operationCodeId).length > 1) {
        this.messagingService.error("Same operation code selected multiple time, please remove.");
        return;
      }

      model.reconPackageItemModels.push(reconItemModel);
    });

    if (this.frmReconPkg.invalid) {
      return;
    }

    this.blockUI.start();
    this.approvalService.submitReconPackageApproval(model)
      .subscribe((data) => {
        setTimeout(() => {
          this.router.navigate(['/']);
        }, 10)
        setTimeout(() => {
          this.messagingService.success('Recon package approved successfully.');
        }, 300)
      }, error => {
        this.messagingService.ProcessErrorResponse(error);
      }, () => {
        this.blockUI.stop();
      });
  }

  public onBlurOnComment(event: any) {
    event.target.scrollTop = 0;
  }
}
