var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators';
import { BlockUI } from 'ng-block-ui';
import { VehicleType } from 'src/app/enums';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { VehicleQueueModel } from 'src/app/models';
import { ApplicationContextService, MessagingService, VehicleQueueService } from 'src/app/services';
export class EditVehicleDetailsComponent {
    constructor(fb, route, router, appUtils, messagingService, stringLengthConstants, applicationContextService, vehicleQueueService) {
        this.fb = fb;
        this.route = route;
        this.router = router;
        this.appUtils = appUtils;
        this.messagingService = messagingService;
        this.stringLengthConstants = stringLengthConstants;
        this.applicationContextService = applicationContextService;
        this.vehicleQueueService = vehicleQueueService;
        this.basicColors = new Array();
        this.vehicleTypes = new Array();
        this.submitted = false;
        this.model = new VehicleQueueModel();
        this.route.params.subscribe((data) => {
            this.model.id = Number(data.vehicleQueueModelId);
        });
        this.vehicleTypes = this.appUtils.getVehicleTypes();
    }
    ngOnDestroy() {
        this.storeSubscription.unsubscribe();
    }
    ngAfterViewInit() {
        this.storeSubscription = this.applicationContextService.store$.subscribe((storeSelectListItem) => {
            if (storeSelectListItem) {
                this.model.storeId = Number(storeSelectListItem.id);
            }
            else {
                this.model.storeId = null;
            }
        });
    }
    ngOnInit() {
        this.basicColors = this.appUtils.getBasicColors();
        this.frmVehicleDetail = this.fb.group({
            year: ['', [RxwebValidators.compose({
                        validators: [
                            RxwebValidators.required(),
                            RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: false }),
                            RxwebValidators.minNumber({ value: 1900 }),
                            RxwebValidators.maxNumber({ value: new Date().getFullYear() })
                        ]
                    })]],
            make: ['', [RxwebValidators.compose({
                        validators: [
                            RxwebValidators.maxLength({ value: this.stringLengthConstants.Default })
                        ]
                    })]],
            model: ['', [RxwebValidators.compose({
                        validators: [
                            RxwebValidators.required(),
                            RxwebValidators.maxLength({ value: this.stringLengthConstants.Default }),
                        ]
                    })]],
            basicColor: ['', [RxwebValidators.compose({
                        validators: [
                            RxwebValidators.required()
                        ]
                    })]],
            vin: ['', [RxwebValidators.compose({
                        validators: [
                            RxwebValidators.required(),
                            RxwebValidators.alphaNumeric(),
                            RxwebValidators.upperCase(),
                            RxwebValidators.minLength({ value: this.stringLengthConstants.VIN }),
                            RxwebValidators.maxLength({ value: this.stringLengthConstants.VIN })
                        ]
                    })]],
            series: ['', [RxwebValidators.compose({
                        validators: [
                            RxwebValidators.maxLength({ value: this.stringLengthConstants.Default })
                        ]
                    })]],
            stockNumber: ['', [RxwebValidators.compose({
                        validators: [
                            RxwebValidators.required(),
                            RxwebValidators.alphaNumeric(),
                            RxwebValidators.upperCase(),
                        ]
                    })]],
            mileage: ['', [RxwebValidators.compose({
                        validators: [
                            RxwebValidators.required(),
                            RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: false })
                        ]
                    })]],
            vehicleType: [VehicleType.None]
        });
        this.getVehicleDetail();
    }
    getVehicleDetail() {
        this.vehicleQueueService.get(this.model.id).subscribe((data) => {
            this.frmVehicleDetail.patchValue({
                year: data.year,
                make: data.make,
                model: data.model,
                basicColor: data.basicColor || "",
                vin: data.vehicleIdentificationNumber,
                stockNumber: data.stockNumber,
                mileage: data.mileage,
                series: data.series,
                vehicleType: data.vehicleType
            });
        });
    }
    navigateToDashboard() {
        this.router.navigate(["/"]);
    }
    onSubmit() {
        this.submitted = true;
        if (this.frmVehicleDetail.invalid)
            return;
        this.model.vehicleIdentificationNumber = this.frmVehicleDetail.value.vin;
        this.model.stockNumber = this.frmVehicleDetail.value.stockNumber;
        this.model.vehicleIdentificationNumber = this.frmVehicleDetail.value.vin;
        this.model.year = Number(this.frmVehicleDetail.value.year);
        this.model.make = this.frmVehicleDetail.value.make;
        this.model.model = this.frmVehicleDetail.value.model;
        this.model.basicColor = this.frmVehicleDetail.value.basicColor;
        this.model.mileage = Number(this.frmVehicleDetail.value.mileage);
        this.model.series = this.frmVehicleDetail.value.series || null;
        this.model.vehicleType = Number(this.frmVehicleDetail.value.vehicleType);
        this.blockUI.start();
        this.vehicleQueueService.updateVehicleInfo(this.model)
            .subscribe(() => {
            setTimeout(() => {
                this.navigateToDashboard();
            }, 10);
            setTimeout(() => {
                this.messagingService.success("Vehicle info updated successfully.");
            }, 300);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        }, () => {
            this.blockUI.stop();
        });
    }
}
__decorate([
    BlockUI('container-blockui-store'),
    __metadata("design:type", Object)
], EditVehicleDetailsComponent.prototype, "blockUI", void 0);
