import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { forkJoin, from, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { InvoicingPreference, InvoicingStructure } from 'src/app/enums';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { CountryModel, OperationCodeSelectListModel, SelectListModel, StateModel, StoreDetailModel, StoreEditModel, SubCategorySelectListModel } from 'src/app/models';
import { ApplicationContextService, CategoryService, CompanyService, CountryService, FilterStateService, MessagingService, OperationCodeService, SubCategoryService } from 'src/app/services';
import { StoreService } from 'src/app/services/store.service';

@Component({
    selector: 'app-store-edit',
    templateUrl: './store.edit.component.html',
    styleUrls: ['./store.edit.component.css']
})
export class StoreEditComponent {
    frmStore: FormGroup;
    submitted: boolean;
    model: StoreEditModel;
    storeDetail: StoreDetailModel;
    isCreateAnotherStore: boolean = false;
    InvoicingStructure = InvoicingStructure;
    InvoicingPreference = InvoicingPreference;
    @BlockUI('container-blockui-store') blockUI: NgBlockUI;

    //For dropdown
    countries: Array<CountryModel> = new Array<CountryModel>();
    states: Array<StateModel> = new Array<StateModel>();
    companies: Array<SelectListModel> = new Array<SelectListModel>();
    categories: Array<SelectListModel> = new Array<SelectListModel>();
    subCategories: Array<SubCategorySelectListModel> = new Array<SubCategorySelectListModel>();
    autoGroups: Array<string> = new Array<string>();

    //For store multi select 
    categoryDropdownSettings: IDropdownSettings = {};
    subCategoryDropdownSettings: IDropdownSettings = {};

    //For get/set mutli select dropdown value
    selectedCategories: SelectListModel[] = new Array<SelectListModel>();
    selectedSubCategories: SubCategorySelectListModel[] = new Array<SubCategorySelectListModel>();

    filteredSubCategories: SubCategorySelectListModel[] = new Array<SubCategorySelectListModel>();
    
    //For auto complete
    @ViewChild('instance', { static: true }) instance: NgbTypeahead;
    focus$ = new Subject<string>();
    click$ = new Subject<string>();
    autoGroupSearch = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => this.instance && !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            map((term: string) => term === ''
                ? this.autoGroups
                : this.autoGroups.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
            )
        );
    }

    constructor(private formBuilder: FormBuilder,
        private messagingService: MessagingService,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private countryService: CountryService,
        private companyService: CompanyService,
        private appUtils:AppUtils,
        private storeService: StoreService,
        private categoryService: CategoryService,
        private subCategoryService: SubCategoryService,
        private stringLengthConstants: StringLengthConstants,
        private applicationContextService: ApplicationContextService,
        private filterStateService: FilterStateService
    ) {
        this.model = new StoreEditModel();
        this.categories = new Array<SelectListModel>();
        this.subCategories = new Array<SubCategorySelectListModel>();
        this.activeRoute.params.subscribe(p => this.model.id = parseInt(p.storeId));
        filterStateService.changeFilterModelStatues(FilterPageType.storeManage, true);
    }

    ngOnInit() {
        this.multiSelectDropdownSettings();
        this.resetForm();
        this.loadData();
    }

    private resetForm() {
        this.submitted = false;
        this.frmStore = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            autoGroupName: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Default)]],
            streetAddress: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Default)]],
            blockAddress: [null, [Validators.maxLength(this.stringLengthConstants.Default)]],
            city: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Default)]],
            state: ['', [Validators.required]],
            country: ['', [Validators.required]],
            phoneCode:['', [Validators.required]],
            phoneNumber: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.PhoneNumber),this.appUtils.validatePhoneNumber()]],
            zipCode: ['', [Validators.required, Validators.minLength(5),
            Validators.maxLength(5), Validators.pattern(new RegExp('^[a-zA-Z0-9-]*$', 'g')),
            Validators.maxLength(this.stringLengthConstants.PostalCode)]],
            vAutoStoreId: ['', [Validators.required]],
            invocingEmail: ['', [Validators.required, Validators.email,
            Validators.maxLength(this.stringLengthConstants.Email)]],
            invoicingStructure: [null],
            invoicingPreference: [null],
            companyId: ['', [Validators.required]]
        });
        this.frmStore.controls.invoicingStructure.setValue(InvoicingStructure.Consolidate);
        this.frmStore.controls.invoicingPreference.setValue(InvoicingPreference.SingleInvoice);
    }

    private loadData() {
        this.blockUI.start();
        forkJoin([
            this.storeService.get(this.model.id),
            this.countryService.getCountries(),
            this.companyService.getCompanies(),
            this.categoryService.selectList(),
            this.subCategoryService.getAllSubCategories(),
            this.storeService.allAutoGroupName(),
        ]).subscribe(([
            store,
            countries,
            companies,
            categories,
            subCategories,
            autogroups
        ]) => {
            this.storeDetail = store;
            this.countries = countries;
            if (this.countries.length == 1) {
                this.loadStates(this.countries[0].id);
                this.frmStore.controls['country'].setValue(this.countries[0].shortName);
                this.frmStore.controls['phoneCode'].setValue(this.countries[0].phoneCode);
            }
            this.companies = companies;
            this.categories = categories;
            this.subCategories = subCategories;
            this.autoGroups = autogroups;

            this.filteredSubCategories = this.subCategories.filter(x =>
                this.storeDetail.categoryIds.find(z => x.categoryId == z));

            this.initForm();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    private loadStates(countryId: number) {
        this.blockUI.start();
        this.countryService.getStates(countryId).subscribe((data: any) => {
            this.states = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    private initForm() {

        this.selectedCategories = this.categories.filter(x => this.storeDetail.categoryIds.includes(x.id));
        this.selectedSubCategories = this.subCategories.filter(x => this.storeDetail.subCategoryIds.includes(x.id));

        this.frmStore.controls.autoGroupName.setValue(this.storeDetail.autoGroupName);
        this.frmStore.controls.name.setValue(this.storeDetail.name);
        this.frmStore.controls.streetAddress.setValue(this.storeDetail.streetAddress);
        this.frmStore.controls.blockAddress.setValue(this.storeDetail.blockAddress);
        this.frmStore.controls.city.setValue(this.storeDetail.city);

        this.frmStore.controls.state.setValue(this.storeDetail.state);
        this.frmStore.controls.country.setValue(this.storeDetail.country);
        this.frmStore.controls.zipCode.setValue(this.storeDetail.zipCode);
        this.frmStore.controls.phoneCode.setValue(this.storeDetail.phoneCode);
        this.frmStore.controls.phoneNumber.setValue(this.storeDetail.phoneNumber);

        this.frmStore.controls.vAutoStoreId.setValue(this.storeDetail.vAutoId);
        this.frmStore.controls.invocingEmail.setValue(this.storeDetail.invocingEmail);
        this.frmStore.controls.companyId.setValue(this.storeDetail.companyId);
        this.frmStore.controls.invoicingStructure.setValue(this.storeDetail.invoicingStructure);
        this.frmStore.controls.invoicingPreference.setValue(this.storeDetail.invoicingPreference);

    }

    onSubmit() {
        this.submitted = true;

        if (this.frmStore.invalid
            || this.selectedCategories.length == 0) {
            return;
        }

        this.model.categoryIds = this.selectedCategories.map(x => x.id);
        this.model.subCategoryIds = this.selectedSubCategories.map(x => x.id);

        this.model.autoGroupName = this.frmStore.controls.autoGroupName.value;
        this.model.name = this.frmStore.controls.name.value;
        this.model.streetAddress = this.frmStore.controls.streetAddress.value;
        this.model.blockAddress = this.frmStore.controls.blockAddress.value;
        this.model.city = this.frmStore.controls.city.value;
        this.model.state = this.frmStore.controls.state.value;
        this.model.country = this.frmStore.controls.country.value;
        this.model.phoneCode = this.frmStore.controls.phoneCode.value;
        this.model.phoneNumber = this.frmStore.controls.phoneNumber.value;
        this.model.zipCode = this.frmStore.controls.zipCode.value;
        this.model.vAutoId = this.frmStore.controls.vAutoStoreId.value;
        this.model.invocingEmail = this.frmStore.controls.invocingEmail.value;
        this.model.companyId = parseInt(this.frmStore.controls.companyId.value);
        this.model.invoicingStructure = parseInt(this.frmStore.controls.invoicingStructure.value);
        this.model.invoicingPreference = parseInt(this.frmStore.controls.invoicingPreference.value);

        this.blockUI.start();
        this.storeService.update(this.model)
            .subscribe((data: StoreDetailModel) => {
                setTimeout(() => {
                    this.navigateToManageStore();
                }, 10);
                setTimeout(() => {
                    this.messagingService.success('Store has been updated successfully.');
                }, 300);
                this.blockUI.stop();
            }, error => {
                this.messagingService.ProcessErrorResponse(error);
                this.blockUI.stop();
            });
        if (this.isCreateAnotherStore) {
            this.resetForm();
            this.isCreateAnotherStore = false;
        }
    }

    navigateToManageStore() {
        this.router.navigate(['manage', 'store']);
    }

    public onCategorySelect() {
        let categoryIds = this.selectedCategories.map(x => x.id);
        if (categoryIds.length == 0) {
            this.onCategoryDeSelectAll();
            return;
        }

        this.filteredSubCategories = [...this.subCategories.filter(x => categoryIds.find(z => z == x.categoryId))]
        
        this.selectedSubCategories = this.selectedSubCategories.filter(x =>
            this.filteredSubCategories.find(z => z.id == x.id));
    }

    public onCategorySelectAll() {
        this.filteredSubCategories = [...this.subCategories];
    }

    public onCategoryDeSelectAll() {
        this.selectedSubCategories = this.filteredSubCategories = new Array<SubCategorySelectListModel>();
    }

    private multiSelectDropdownSettings() {
        this.categoryDropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };

        this.subCategoryDropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
    }
}
