/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./queue-timer.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../timer/times.component.ngfactory";
import * as i3 from "../timer/times.component";
import * as i4 from "./queue-timer.component";
var styles_QueueTimerComponent = [i0.styles];
var RenderType_QueueTimerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QueueTimerComponent, data: {} });
export { RenderType_QueueTimerComponent as RenderType_QueueTimerComponent };
export function View_QueueTimerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "queueTImeWrp"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "queueTimer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 16, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "timerH"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Waiting"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Time "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-time", [], null, null, null, i2.View_TimerComponent_0, i2.RenderType_TimerComponent)), i1.ɵdid(10, 573440, null, 0, i3.TimerComponent, [], { intervalInSeconds: [0, "intervalInSeconds"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 7, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "timerH"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Total"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Time "])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "app-time", [], null, null, null, i2.View_TimerComponent_0, i2.RenderType_TimerComponent)), i1.ɵdid(18, 573440, null, 0, i3.TimerComponent, [], { intervalInSeconds: [0, "intervalInSeconds"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.vehicleQueueStageModel.queueTimeInSeconds; _ck(_v, 10, 0, currVal_0); var currVal_1 = (_co.vehicleQueueStageModel.queueTimeInSeconds + _co.vehicleQueueStageModel.workTimeInSeconds); _ck(_v, 18, 0, currVal_1); }, null); }
export function View_QueueTimerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-queue-timer", [], null, null, null, View_QueueTimerComponent_0, RenderType_QueueTimerComponent)), i1.ɵdid(1, 245760, null, 0, i4.QueueTimerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QueueTimerComponentNgFactory = i1.ɵccf("app-queue-timer", i4.QueueTimerComponent, View_QueueTimerComponent_Host_0, { vehicleQueueStageModel: "vehicleQueueStageModel" }, {}, []);
export { QueueTimerComponentNgFactory as QueueTimerComponentNgFactory };
