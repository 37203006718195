<div class="driveCom-msg" (click)="openModal()">
  <span>
    <em class="fa fa-comments" aria-hidden="true"></em>
  </span>
  {{lastComment}}
</div>

<ng-template #queueNoteModal let-modal>
  <div class="modal-body commentPopupWrp" *blockUI="'container-blockui-vehicle-note'">
    <div class="tableWrp">
      <div class="table-responsive">
        <table class="table-hover break-all table">
          <thead>
            <tr>
              <th scope="col">Comment</th>
              <th scope="col">Comment By</th>
              <th scope="col">Comment Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of queueNotes">
              <td>{{item.comment}}</td>
              <td>{{item.addedByUser}}</td>
              <td>{{item.addedOnDate | date:'MM/dd/yyyy hh:mm a' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <form [formGroup]="frmNote" (ngSubmit)="onSubmit()">
      <div class="commentForm">
        <div class="form-group">
          <label>Add New Comment</label>
          <textarea class="form-control" placeholder="Enter new comment here" formControlName="note"
            [ngClass]="{ 'is-invalid': submitted && frmNote.controls.note.errors }">
          </textarea>
          <div *ngIf="submitted && frmNote.controls.note.errors" class="invalid-feedback">
            <div *ngIf="frmNote.controls.note.errors.required">
              Comment is required
            </div>
            <div *ngIf="frmNote.controls.note.errors.maxlength">
              Comment should be max
              {{frmNote.controls.note.errors.maxlength.requiredLength}}
              characters.
            </div>
          </div>
        </div>
      </div>
      <div class="buttonWrp">
        <button type="submit" class="btn btn-red m-r-5">Submit</button>
        <button type="button" class="btn btn-tsrp" (click)="onCancel()">Close</button>
      </div>
    </form>
  </div>

</ng-template>
