var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Category, WorkStatus } from 'src/app/enums';
import { StringLengthConstants } from 'src/app/helpers';
import { ReconPackageApprovalModel, ReconPackageItemModel, VehicleQueueModel, VehicleQueueStageModel } from 'src/app/models';
import { MessagingService, VehicleQueueService, OperationCodeService, ApprovalService } from 'src/app/services';
export class ReconPackageComponent {
    constructor(formBuilder, activatedRoute, router, stringLengthConstants, messagingService, vehicleQueueService, operationCodeService, approvalService) {
        this.formBuilder = formBuilder;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.stringLengthConstants = stringLengthConstants;
        this.messagingService = messagingService;
        this.vehicleQueueService = vehicleQueueService;
        this.operationCodeService = operationCodeService;
        this.approvalService = approvalService;
        this.vehicleQueueModel = new VehicleQueueModel();
        this.vehicleQueueStageModel = new VehicleQueueStageModel();
        this.vehicleWorkDetailModel = new Array();
        this.categories = new Array();
        this.subCategories = new Array();
        this.carPanels = new Array();
        this.carLocations = new Array();
        this.carSides = new Array();
        this.reconPkgOpCodes = new Array();
        this.internalTotalCost = 0;
        this.reconditioningTotalCost = 0;
        this.reconditioningCost = 0;
        this.reconditioningNet = 0;
        this.addedCosmeticTotalCost = 0;
        this.quotedTotalCost = 0;
        this.totalStoreCost = 0;
        this.netCost = 0;
        this.viewModels = new Array();
        this.submitted = false;
        this.activatedRoute.params.subscribe((data) => {
            this.vehicleQueueId = data.vehicleQueueId;
            this.vehicleQueueStageId = data.vehicleQueueStageId;
        });
    }
    ngOnInit() {
        this.frmReconPkg = this.formBuilder.group({
            opCodes: this.formBuilder.array([]),
        });
        this.loadVehicleQueue();
    }
    loadData() {
        this.blockUI.start();
        forkJoin([
            this.operationCodeService.getReconPackageOperationCodes(this.vehicleQueueModel.storeId, this.vehicleQueueModel.workflowId),
            this.vehicleQueueService.getApprovWorkDetails(this.vehicleQueueId)
        ]).subscribe(([reconPkgOpCodes, workDetails]) => {
            this.reconPkgOpCodes = reconPkgOpCodes;
            this.vehicleWorkDetailModel = workDetails;
            this.vehicleWorkDetailModel.filter(x => x.isReconPackage || x.operationCodeDetailModel.categoryName == Category.ReconPackage)
                .forEach(item => this.getFormArray().push(this.createOpCode(item)));
            this.calculatePrice();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    loadVehicleQueue() {
        this.blockUI.start();
        this.vehicleQueueService.get(this.vehicleQueueId)
            .subscribe((vehicleQueue) => {
            if (!vehicleQueue) {
                this.messagingService.ProcessErrorResponse(null, "This vehicle is not belongs to the selected store");
                return;
            }
            this.vehicleQueueModel = vehicleQueue;
            this.vehicleQueueStageModel = this.vehicleQueueModel.vehicleQueueStages.find(x => x.id == this.vehicleQueueStageId);
            this.loadData();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    getFormArrayControls() {
        return this.frmReconPkg.controls.opCodes.controls;
    }
    getFormArray() {
        return this.frmReconPkg.controls.opCodes;
    }
    getOperationCode() {
        let opCodeIds = new Array();
        this.frmReconPkg.controls.opCodes.controls.forEach((elem, index) => {
            let fb = elem;
            opCodeIds.push(Number(fb.controls.operationCodeId.value));
        });
        return this.vehicleWorkDetailModel.filter(x => !opCodeIds.includes(x.operationCodeId));
    }
    createOpCode(data = undefined) {
        let index = this.getFormArray().length;
        this.viewModels[index] = {
            operationCodes: this.getOperationCode(),
        };
        let frm = this.formBuilder.group({
            workDetailId: [data ? data.id : ''],
            operationCodeId: [{ value: data ? data.operationCodeId : '', disabled: data ? true : false },
                [Validators.required]],
            categoryName: [
                { value: data ? data.operationCodeDetailModel.categoryName : '', disabled: data ? true : false },
                [Validators.required]
            ],
            subCategoryName: [
                { value: data ? data.operationCodeDetailModel.subCategoryName : '', disabled: data ? true : false },
            ],
            locationName: [
                { value: data ? data.operationCodeDetailModel.carLayoutLocationName : '', disabled: data ? true : false },
            ],
            panelName: [
                { value: data ? data.operationCodeDetailModel.carLayoutPanelName : '', disabled: data ? true : false },
            ],
            sideName: [
                { value: data ? data.operationCodeDetailModel.carLayoutSideName : '', disabled: data ? true : false },
            ],
            cost: [
                { value: data ? data.cost : 0, disabled: data ? true : false },
                [Validators.required, Validators.min(0)]
            ],
            part: [
                { value: data ? data.partPrice : 0, disabled: data ? true : false },
                [Validators.required, Validators.min(0)]
            ],
            labor: [
                { value: data ? data.laborPrice : 0, disabled: data ? true : false },
                [Validators.required, Validators.min(0)]
            ],
            total: [{ value: data ? (data.cost + data.partPrice + data.laborPrice) : 0, disabled: data ? true : false }],
            comment: [
                { value: '', disabled: data ? true : false },
                [Validators.maxLength(this.stringLengthConstants.Default)]
            ],
            addedDate: [data ? data.addedOn : new Date()],
            workerName: [data ? data.workerUser : ''],
            queueEntryDate: [data ? data.addedOn : ''],
            queueStatus: [data ? WorkStatus[data.workStatus] : ''],
            queueCompletedDate: [data ? data.addedOn : ''],
            queueTime: [data ? '' : '']
        });
        frm.controls.cost.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
            this.calculatePrice();
        });
        frm.controls.part.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
            this.calculatePrice();
        });
        frm.controls.labor.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
            this.calculatePrice();
        });
        return frm;
    }
    onOperationCodeChange(operationCodeId, index) {
        let workDetail = this.vehicleWorkDetailModel.find(x => x.operationCodeId == operationCodeId);
        let frmGroup = this.getFormArray().controls[index];
        frmGroup.controls.categoryName.setValue(workDetail.operationCodeDetailModel.categoryName);
        frmGroup.controls.subCategoryName.setValue(workDetail.operationCodeDetailModel.subCategoryName);
        frmGroup.controls.panelName.setValue(workDetail.operationCodeDetailModel.carLayoutPanelName);
        frmGroup.controls.locationName.setValue(workDetail.operationCodeDetailModel.carLayoutLocationName);
        frmGroup.controls.sideName.setValue(workDetail.operationCodeDetailModel.carLayoutSideName);
        frmGroup.controls.cost.setValue(workDetail.cost);
        frmGroup.controls.part.setValue(workDetail.partPrice);
        frmGroup.controls.labor.setValue(workDetail.laborPrice);
    }
    onAddOpCode() {
        let frm = this.createOpCode();
        this.getFormArray().push(frm);
    }
    onRemoveOpCode(index) {
        this.getFormArray().removeAt(index);
    }
    onEditOpCode(index) {
        let frmGroup = this.getFormArray().controls[index];
        frmGroup.controls.cost.enable();
        frmGroup.controls.part.enable();
        frmGroup.controls.labor.enable();
        frmGroup.controls.comment.enable();
    }
    calculatePrice() {
        this.reconditioningNet = 0;
        this.reconditioningTotalCost = 0;
        this.internalTotalCost = 0;
        this.totalStoreCost = 0;
        this.addedCosmeticTotalCost = 0;
        this.quotedTotalCost = 0;
        ;
        if (this.vehicleWorkDetailModel.some(x => x.operationCodeDetailModel.categoryName == Category.ReconPackage)) {
            this.reconditioningTotalCost = this.vehicleWorkDetailModel.filter(x => x.operationCodeDetailModel.categoryName == Category.ReconPackage)[0].cost;
        }
        this.frmReconPkg.getRawValue().opCodes.forEach((x, index) => {
            let labor = Number(x.labor);
            let parts = Number(x.part);
            let cost = Number(x.cost);
            this.getFormArrayControls()[index].controls.total.setValue((cost + parts + labor));
            var workDetail = this.vehicleWorkDetailModel.find(x => x.id == x.id);
            this.totalStoreCost += x.total;
            if (workDetail.isReconPackage) {
                this.reconditioningCost += x.total;
            }
            // if (workDetail.isQuoteRequired) {
            //   this.quotedTotalCost += x.total;
            // }
            // if (!workDetail.isReconPackage && !workDetail.isQuoteRequired) {
            //   this.addedCosmeticTotalCost += x.total;
            // }
        });
        this.reconditioningNet = this.reconditioningTotalCost - this.reconditioningCost;
        // this.internalTotalCost = this.totalStoreCost - this.reconditioningTotalCost - this.addedCosmeticTotalCost - this.quotedTotalCost;
    }
    onSubmit() {
        this.submitted = true;
        if (this.frmReconPkg.invalid) {
            return;
        }
        let model = new ReconPackageApprovalModel();
        model.vehicleQueueId = Number(this.vehicleQueueId);
        model.vehicleQueueStageId = Number(this.vehicleQueueStageId);
        this.frmReconPkg.controls.opCodes.controls.forEach((elem, index) => {
            let fb = elem;
            let reconItemModel = new ReconPackageItemModel();
            reconItemModel.workDetailId = Number(fb.controls.workDetailId.value);
            reconItemModel.operationCodeId = Number(fb.controls.operationCodeId.value);
            reconItemModel.cost = Number(fb.controls.cost.value);
            reconItemModel.parts = Number(fb.controls.part.value);
            reconItemModel.labor = Number(fb.controls.labor.value);
            reconItemModel.comment = fb.controls.comment.value;
            if (model.reconPackageItemModels.filter(x => x.operationCodeId == reconItemModel.operationCodeId).length > 1) {
                this.messagingService.error("Same operation code selected multiple time, please remove.");
                return;
            }
            model.reconPackageItemModels.push(reconItemModel);
        });
        if (this.frmReconPkg.invalid) {
            return;
        }
        this.blockUI.start();
        this.approvalService.submitReconPackageApproval(model)
            .subscribe((data) => {
            setTimeout(() => {
                this.router.navigate(['/']);
            }, 10);
            setTimeout(() => {
                this.messagingService.success('Recon package approved successfully.');
            }, 300);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    onBlurOnComment(event) {
        event.target.scrollTop = 0;
    }
}
__decorate([
    BlockUI('container-blockui'),
    __metadata("design:type", Object)
], ReconPackageComponent.prototype, "blockUI", void 0);
