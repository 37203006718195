import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { DataTableResponseModel, SelectListModel } from "../models";

@Injectable({
    providedIn: 'root'
})

export class CarLayoutSideService {
    constructor(private http: HttpClient) {
    }

    public query(dataTablesParameters: any):Observable<DataTableResponseModel>{
        return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/car-layout-side/query`, dataTablesParameters);
    }
    
    public selectList(): Observable<Array<SelectListModel>> {
        return this.http.get<Array<SelectListModel>>(`${environment.apiBaseUrl}/car-layout-side/select-list`);
    }
}