import { VendorDocumentModel } from "..";

export class VendorAddModel{
    public name:string;
    public streetAddress:string;
    public blockAddress:string;
    public city:string;
    public state:string;
    public country:string;
    public zipCode:string;
    public phoneCode:string;
    public phone:string;
    public taxId: string;
    public insurancePolicy:string;
    public insuranceProvider:string;
    public insuranceExpireDate:Date;
    public invoiceEmail:string;
    public invoicingPreference:number;
    public isSMSNotificationActive: boolean;
    public isEmailNotificationActive: boolean;
    public storeIds:number[];
    public categoryIds:number[];
    public subCategoryIds:number[];
    public documents: Array<VendorDocumentModel>;

    constructor() {
        this.documents = new Array<VendorDocumentModel>();
    }
}
