import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { FilterPageType } from "src/app/enums/filter.page.type.enum";
import { CategoryDetailModel } from "src/app/models";
import { CategoryService, FilterStateService, MessagingService } from "src/app/services";


@Component({
    selector: 'app-detail-category',
    templateUrl: './detail.category.component.html',
    styleUrls: ['./detail.category.component.css']
})

export class CategoryDetailComponent implements OnInit {

    @BlockUI('container-blockui') blockUI: NgBlockUI;
    @ViewChild('deleteConfirmationModal', { static: false }) deleteConfirmationModal: any;
    @ViewChild('statusChangeConfirmationModal', { static: true }) statusChangeConfirmationModal: any;

    model: CategoryDetailModel;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private messagingService: MessagingService,
        private categoryService: CategoryService,
        private filterStateService: FilterStateService
    ) {
        filterStateService.changeFilterModelStatues(FilterPageType.categoryManage, true);
        this.model = new CategoryDetailModel();
        this.route.params.subscribe(param => this.model.id = param.categoryId);
    }

    ngOnInit() {
        this.loadCategory();
    }

    private loadCategory() {
        this.blockUI.start();
        this.categoryService.get(this.model.id).subscribe((data: any) => {
            this.model = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    
    openDeleteModal() {
        this.deleteConfirmationModal.openModal(this.model);
    }

    delete(checkInType: CategoryDetailModel) {
        this.blockUI.start();
        this.categoryService.delete(checkInType.id).subscribe(
            () => {
                setTimeout(() => {
                    this.router.navigate(['manage/category']);
                });
                this.messagingService.success(`Category has been deleted successfully.`);
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
            });
    }

    openStatusModal() {
        this.statusChangeConfirmationModal.openModal(this.model);
    }

    toggle(checkInType: CategoryDetailModel) {
        this.blockUI.start();
        this.categoryService.toggleStatus(checkInType.id).subscribe(
            () => {
                this.messagingService.success(`Category has been ${(checkInType.isActive ? 'deactivated' : 'activated')} successfully.`);
                this.model.isActive = !this.model.isActive;
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
            });
    }
}