import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CompanyWithStoreModel, SelectListModel } from "../models";
import * as uuid from 'uuid';

@Injectable({
    providedIn: 'root'
})
export class ApplicationContextService {
    private contextSubject$ = new BehaviorSubject<string>(null);
    public context$ = this.contextSubject$.asObservable();

    private companySubject$ = new BehaviorSubject<CompanyWithStoreModel>(null);
    public company$ = this.companySubject$.asObservable();

    private storeSubject$ = new BehaviorSubject<SelectListModel>(null);
    public store$ = this.storeSubject$.asObservable();

    public setCompany(model: CompanyWithStoreModel) {
        this.companySubject$.next(model);
        localStorage.setItem('selectedCompanyId', model.id.toString());
    }

    public setStore(model: SelectListModel) {
        this.storeSubject$.next(model);
        localStorage.setItem('selectedStoreId', model.id.toString());
    }

    public update(){
        this.contextSubject$.next(uuid.v4());
    }
}