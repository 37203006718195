export enum CarPartComponentType {
  Wheel_Front_Right = "Wheel_Right_Driver",
  Wheel_Front_Left = "Wheel_Left_Driver",
  Wheel_Rear_Right = "Wheel_Right_Passenger",
  Wheel_Rear_Left = "Wheel_Left_Passenger",
  Door_Front_Right = "Door_Right_Driver",
  Door_Front_Left = "Door_Left_Driver",
  Door_Rear_Right = "Door_Right_Passenger",
  Door_Rear_Left = "Door_Left_Passenger",
  Mirror_Right = "Mirror_Right_Passenger",
  Mirro_Left = "Mirror_Left_Driver",
  Rocker_Left = "Rocker_Down_Passenger",
  Quarter_Left = "Quarter_Left_Passenger",
  Quarter_Right = "Quarter_Right_Driver",
  Fender_Left = "Fender_Left_Driver",
  Fender_Right = "Fender_Right_Passenger",
  Rocker_Right = "Rocker_Down_Driver",
  Pillar_Left_Passenger = "Pillar_Left_Passenger",
  Pillar_Right_Passnger = "Pillar_Right_Passnger",
  Pillar_Right_Driver = "Pillar_Right_Driver",
  Pillar_Left_Driver = "Pillar_Left_Driver",
  Rail_Up_Passenger = "Rail_Up_Passenger",
  Rail_Up_Driver = "Rail_Up_Driver",
  HeadLight_Right_Passenger = "HeadLight_Right_Passenger",
  HeadLight_Left_Driver = "HeadLight_Left_Driver",
  FogLight_Left_Driver = "FogLight_Left_Driver",
  FogLight_Right_Passenger = "FogLight_Right_Passenger",
  Bumper_Right_Passenger = "Bumper_Right_Passenger",
  Bumper_Left_Driver = "Bumper_Left_Driver",
  TailLight_Left_Passenger = "TailLight_Left_Passenger",
  Bumper_Left_Passenger = "Bumper_Left_Passenger",
  TailLight_Right_Driver = "TailLight_Right_Driver",
  Bumper_Right_Driver = "Bumper_Right_Driver",
  LowerGrill_Down_Front = "LowerGrill_Down_Front",
  UpperGrill_Up_Front = "UpperGrill_Up_Front",
  FogLight_Left_Front = "FogLight_Left_Front",
  HeadLight_Left_Front = "HeadLight_Left_Front",
  FogLight_Right_Front = "FogLight_Right_Front",
  HeadLight_Right_Front = "HeadLight_Right_Front",
  Bumper_Right_Front = "Bumper_Right_Front",
  Bumper_Left_Front = "Bumper_Left_Front",
  TailLight_Right_Rear = "TailLight_Right_Rear",
  TailLight_Left_Rear = "TailLight_Left_Rear",
  Marker_Left_Rear = "Marker_Left_Rear",
  Marker_Right_Rear = "Marker_Right_Rear",
  Bumper_Down_Rear = "Bumper_Down_Rear",
  Spoiler_Up_Rear = "Spoiler_Up_Rear",
  Window_Up_Rear = "Window_Up_Rear",
  Trunk_Up_Rear = "Trunk_Up_Rear",
  Roof_Up_Top = "Roof_Up_Top",
  Window_Up_Front = "Window_Up_Front",
  Hood_Up_Front = "Hood_Up_Front"
}
