var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI } from 'ng-block-ui';
import { VehicleVideoType } from 'src/app/enums';
import * as RecordRTC from 'recordrtc';
export class VideoRecorderComponent {
    constructor(modalService) {
        this.modalService = modalService;
        this.VidoeIcon = "/assets/images/vidoe-icon.png";
        this.emitRecordedData = new EventEmitter();
        this.VehicleVideoType = VehicleVideoType;
        this.devices = new Array();
        this.isStarted = false;
        this.isStartRecording = false;
        this.isRecorded = false;
        this.videoConstraints = {
            audio: {
                echoCancellation: { exact: true }
            },
            video: {
                width: { exact: 640 },
                height: { exact: 390 },
                deviceId: {
                    exact: null
                }
            },
        };
    }
    ngOnInit() {
        this.selectedUploadTypeOption = this.uploadTypes[0];
    }
    ngOnDestroy() {
        this.onCancel();
    }
    openModal() {
        this.isStarted = this.isStartRecording = false;
        this.modalReference = this.modalService.open(this.recordModal, {
            centered: true,
            scrollable: true,
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });
        this.isRecorded = false;
        this.getCameraOptions();
    }
    getCameraOptions() {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
            this.devices = devices.filter(x => x.kind === "videoinput");
            this.selectedCameraOption = this.devices[0].deviceId;
        });
    }
    onCancel() {
        if (!this.modalReference) {
            return;
        }
        this.onStop();
        setTimeout(() => {
            if (this.recordedBlobs && this.recordedBlobs.size > 0) {
                this.emitRecordedData.emit({
                    blobData: this.recordedBlobs,
                    vehicleVideoType: this.selectedUploadTypeOption
                });
            }
        }, 300);
        this.modalReference.close();
    }
    onStart(videoTag) {
        this.isRecorded = false;
        this.video = videoTag;
        this.video.muted = true;
        this.video.volume = 0;
        this.initRecorder();
    }
    onStartRecording() {
        this.isStartRecording = true;
        this.recorder.startRecording();
    }
    onStopRecording() {
        this.isStartRecording = false;
        if (!this.recorder) {
            return;
        }
        this.recorder.stopRecording(() => {
            this.recordedBlobs = this.recorder.getBlob();
            this.isRecorded = true;
        });
    }
    onStop() {
        this.isStarted = false;
        this.streamData = null;
        this.onStopRecording();
        if (this.video == undefined)
            return;
        const stream = this.video.srcObject;
        this.video.srcObject = null;
        if (stream == null)
            return;
        const tracks = stream.getTracks();
        tracks.forEach(function (track) {
            track.stop();
        });
    }
    cameraSelection() {
        this.onStop();
        this.initRecorder();
    }
    initRecorder() {
        this.recordedBlobs = null;
        this.videoConstraints.video.deviceId.exact = this.selectedCameraOption;
        // Some browsers partially implement mediaDevices. We can't just assign an object
        // with getUserMedia as it would overwrite existing properties.
        // Here, we will just add the getUserMedia property if it's missing.
        if (navigator.mediaDevices.getUserMedia === undefined) {
            navigator.mediaDevices.getUserMedia = function (constraints) {
                // First get ahold of the legacy getUserMedia, if present
                var getUserMedia = navigator["webkitGetUserMedia"] || navigator["mozGetUserMedia"];
                // Some browsers just don't implement it - return a rejected promise with an error
                // to keep a consistent interface
                if (!getUserMedia) {
                    return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
                }
                // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
                return new Promise(function (resolve, reject) {
                    getUserMedia.call(navigator, constraints, resolve, reject);
                });
            };
        }
        navigator.mediaDevices.getUserMedia(this.videoConstraints).then((stream) => {
            if (!this.selectedCameraOption)
                this.getCameraOptions();
            setTimeout(() => {
                this.isStarted = true;
                this.streamData = this.video.srcObject = stream;
                this.video.play();
                this.recorder = RecordRTC(this.streamData, {
                    type: 'video',
                    mimeType: 'video/mp4\;codecs=vp8,opus',
                });
            }, 300);
        }, error => {
            console.error('navigator.getUserMedia error:', error);
        });
    }
}
__decorate([
    BlockUI('container-blockui-vehicle-note'),
    __metadata("design:type", Object)
], VideoRecorderComponent.prototype, "blockUI", void 0);
