<section class="compnyDtlPSec" *blockUI="'container-blockui-store'">
     <div class="container">
          <div class="row">
               <div class="col-12">
                    <a (click)="navigateToManageStore()" class="backtolist"><em class="fa fa-long-arrow-left"
                              aria-hidden="true"></em> Back to list</a>
               </div>
          </div>
          <div class="row">
               <div class="col-12 col-md-12 col-lg-8 col-xl-8 offset-xl-2 offset-lg-2">
                    <div class="compnydtlwrp">
                         <div class="compnydtlH">
                              <div class="row">
                                   <div class="col-8 col-md-8">
                                        <h2>Store Detail</h2>
                                   </div>
                                   <div class="col-4 col-md-4">
                                        <div class="form-group">
                                             <div ngbDropdown class="actionWrp dropdown">
                                                  <button class="btn btn-red" id="dropdownBasic1"
                                                       ngbDropdownToggle>Actions</button>
                                                  <div class="dropdown-menu" ngbDropdownMenu
                                                       aria-labelledby="dropdownBasic1">
                                                       <ul>
                                                            <li>
                                                                 <a ngbDropdownItem href="javascript:void(0);"
                                                                      (click)="goToClone()">
                                                                      <em class="fa fa-clone" aria-hidden="true"></em>
                                                                      Clone
                                                                 </a>
                                                            </li>
                                                            <li>
                                                                 <a ngbDropdownItem href="javascript:void(0);"
                                                                      (click)="goToEdit()">
                                                                      <em class="fa fa-edit" aria-hidden="true"></em>
                                                                      Edit
                                                                 </a>
                                                            </li>
                                                            <li>
                                                                 <a ngbDropdownItem href="javascript:void(0);"
                                                                      (click)="toggleStatus()">
                                                                      <em class="fa fa-times-circle-o"
                                                                           aria-hidden="true"></em>
                                                                      <span
                                                                           [innerHTML]="model.isActive?'Deactivate':'Activate'"></span>
                                                                 </a>
                                                            </li>
                                                            <li>
                                                                 <a ngbDropdownItem href="javascript:void(0);"
                                                                      (click)="openStoreDeleteModal()">
                                                                      <em class="fa fa-trash-o" aria-hidden="true"></em>
                                                                      Delete
                                                                 </a>
                                                            </li>
                                                       </ul>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>

                         <h4>General Information</h4>
                         <div class="row">
                              <div class="col-12">
                                   <ul class="cmpnyDtlList">
                                        <li>
                                             <span>Name</span>
                                             <span>:</span>
                                             <span>{{model.name}}</span>
                                        </li>
                                        <li>
                                             <span>Auto Group Name</span>
                                             <span>:</span>
                                             <span>{{model.autoGroupName}}</span>
                                        </li>
                                        <li>
                                             <span>Managing Company Name</span>
                                             <span>:</span>
                                             <span>{{model.companyName}}</span>
                                        </li>
                                        <li>
                                             <span>Street line 1</span>
                                             <span>:</span>
                                             <span>{{model.streetAddress}}</span>
                                        </li>
                                        <li>
                                             <span>Street line 2</span>
                                             <span>:</span>
                                             <span>{{model.blockAddress}}</span>
                                        </li>
                                        <li>
                                             <span>City</span>
                                             <span>:</span>
                                             <span>{{model.city}}</span>
                                        </li>
                                        <li>
                                             <span>State</span>
                                             <span>:</span>
                                             <span>{{model.state}}</span>
                                        </li>
                                        <li>
                                             <span>Country</span>
                                             <span>:</span>
                                             <span>{{model.country}}</span>
                                        </li>
                                        <li>
                                             <span>Zip Code</span>
                                             <span>:</span>
                                             <span>{{model.zipCode}}</span>
                                        </li>
                                        
                                        <li>
                                             <span>Phone Number</span>
                                             <span>:</span>
                                             <span>{{model.phoneCode}} {{model.phoneNumber}}</span>
                                        </li>

                                        <li>
                                             <span>VAuto Id</span>
                                             <span>:</span>
                                             <span>{{model.vAutoId}}</span>
                                        </li>
                                        <li>
                                             <span>Invoicing Email</span>
                                             <span>:</span>
                                             <span>{{model.invocingEmail}}</span>
                                        </li>
                                        <li>
                                             <span>Invoicing Structure</span>
                                             <span>:</span>
                                             <span>{{InvoicingStructure[model.invoicingStructure]}}</span>
                                        </li>
                                        <li>
                                             <span>Invoicing Preference</span>
                                             <span>:</span>
                                             <span>{{InvoicingPreference[model.invoicingPreference]}}</span>
                                        </li>
                                        <li>
                                             <span>Categories</span> <span>:</span>
                                             <span
                                                  [innerHTML]="getConCatStringFromStringArray(model.categoryNames)"></span>
                                        </li>
                                        <li>
                                             <span>Tools</span> <span>:</span>
                                             <span
                                                  [innerHTML]="getConCatStringFromStringArray(model.subCategoryNames)"></span>
                                        </li>
                                   </ul>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</section>
<app-store-delete-confirmation #deleteStore (delete)="emitDeleteConfirmation($event)">
</app-store-delete-confirmation>
<app-store-status-change-confirmation #statusChangeConfirmationModal (toggle)="toggle($event)">
</app-store-status-change-confirmation>