var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BlockUI } from 'ng-block-ui';
import { ChangePasswordModel } from 'src/app/models';
import { MessagingService, AuthenticationService } from 'src/app/services';
import { MustMatch, MustNotMatch } from 'src/app/validators';
export class ChangePasswordComponent {
    constructor(cdr, messagingService, authenticationService, formBuilder) {
        this.cdr = cdr;
        this.messagingService = messagingService;
        this.authenticationService = authenticationService;
        this.formBuilder = formBuilder;
        this.submitted = false;
        this.passwordIsValid = false;
    }
    ngOnInit() {
        this.frm = this.formBuilder.group({
            currentPassword: ['', [Validators.required]],
            newPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
            confirmPassword: ['', Validators.required]
        }, {
            validator: [
                MustNotMatch('currentPassword', 'newPassword'),
                MustMatch('newPassword', 'confirmPassword')
            ]
        });
    }
    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid.
        if (!this.passwordIsValid || this.frm.invalid) {
            return;
        }
        let model = new ChangePasswordModel();
        model.currentPassword = this.frm.controls.currentPassword.value;
        model.newPassword = this.frm.controls.newPassword.value;
        model.confirmPassword = this.frm.controls.confirmPassword.value;
        this.blockUI.start();
        this.authenticationService.changePassword(model)
            .subscribe(() => {
            this.blockUI.stop();
            this.messagingService.success('Your password has been changed successfully');
            this.submitted = false;
            this.frm.reset();
        }, error => {
            this.blockUI.stop();
            this.messagingService.ProcessErrorResponse(error);
        });
    }
    passwordValid($event) {
        this.passwordIsValid = $event;
        this.cdr.detectChanges();
    }
}
__decorate([
    BlockUI('container-blockui-change-password'),
    __metadata("design:type", Object)
], ChangePasswordComponent.prototype, "blockUI", void 0);
