import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { CompanyDeleteModel, CompanyDetailModel, CompanyStatusToggleModel } from 'src/app/models';
import { CompanyService, FilterStateService, MessagingService } from 'src/app/services';

@Component({
    selector: 'app-company-detail',
    templateUrl: './company.detail.component.html',
    styleUrls: ['./company.detail.component.css']
})
export class CompanyDetailComponent implements OnInit {
    @BlockUI('container-blockui-company') blockUI: NgBlockUI;
    @ViewChild('deleteConfirmationModal', { static: false }) deleteConfirmationModal: any;
    @ViewChild('statusChangeConfirmationModal', { static: false }) statusChangeConfirmationModal: any;

    companyId: number;
    model: CompanyDetailModel;
    contacts: string;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private filterStateService: FilterStateService,
        private messagingService: MessagingService,
        private companyService: CompanyService
    ) {
        filterStateService.changeFilterModelStatues(FilterPageType.companyManage, true);
        this.route.params.subscribe(params => this.companyId = params['id']);
    }

    ngOnInit() {
        this.loadCompany();
    }

    loadCompany() {
        this.blockUI.start();
        this.companyService.get(this.companyId).subscribe((data: any) => {
            this.model = data;
            this.contacts = this.model.contacts.filter(x => !x.isDeleted).map(x => x.name).join(',');
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    openDeleteConfirmation() {
        this.deleteConfirmationModal.openModal(this.model);
    }

    delete(companyDeleteModel: CompanyDeleteModel) {
        this.blockUI.start();
        this.companyService.delete(companyDeleteModel).subscribe(
            () => {
                setTimeout(() => {
                    this.router.navigate(['/manage/company']);
                });
                this.messagingService.success(`Managing Company has been deleted successfully.`);
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
            });
    }

    openStatusChangeConfirmation() {
        this.statusChangeConfirmationModal.openModal(this.model);
    }

    toggle(companyStatusToggleModel: CompanyStatusToggleModel) {
        this.blockUI.start();
        this.companyService.toggleStatus(companyStatusToggleModel).subscribe(
            () => {
                this.messagingService.success(`Managing Company has been ${(this.model.isActive ? 'deactivated' : 'activated')} successfully.`);
                this.model.isActive = !this.model.isActive;
            },
            error => {
                this.messagingService.ProcessErrorResponse(error);
            },
            () => {
                this.blockUI.stop();
            });
    }

    goToEdit() {
        this.router.navigate(['/edit/company', this.model.id]);
    }
}
