var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { ApplicationRoleType, NotificationType } from 'src/app/enums';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { UserAddModel } from 'src/app/models';
import { UserService, MessagingService, RoleService, CompanyService, VendorService, StoreService, CountryService } from 'src/app/services';
import { conditionalValidator } from 'src/app/validators';
export class UserAddComponent {
    constructor(router, userService, companyService, countryService, messagingService, storeService, vendorService, appUtils, formBuilder, roleService, stringLengthConstants) {
        this.router = router;
        this.userService = userService;
        this.companyService = companyService;
        this.countryService = countryService;
        this.messagingService = messagingService;
        this.storeService = storeService;
        this.vendorService = vendorService;
        this.appUtils = appUtils;
        this.formBuilder = formBuilder;
        this.roleService = roleService;
        this.stringLengthConstants = stringLengthConstants;
        this.countries = new Array();
        this.states = new Array();
        this.submitted = false;
        //Role Dropdown list
        this.allRoles = new Array();
        this.roles = new Array();
        this.selectedRoles = new Array();
        this.roleDropdownSettings = {};
        this.ApplicationRoleType = ApplicationRoleType;
        this.NotificationType = NotificationType;
        this.companies = new Array();
        this.stores = new Array();
        this.autoGroupNames = new Array();
        this.vendors = new Array();
        this.storeDropdownSettings = {};
        this.selectedApplicationRoleType = ApplicationRoleType.System;
        this.selectedStores = new Array();
        this.roleTypeFilterOptions = this.appUtils.getApplicationRoleTypes();
    }
    ngOnInit() {
        this.loadData();
        this.loadCountries();
        this.form = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            email: ['', [Validators.required, Validators.email, Validators.maxLength(this.stringLengthConstants.Email)]],
            phoneCode: ['', [Validators.required]],
            phoneNumber: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.PostalCode), this.appUtils.validatePhoneNumber()]],
            applicationRoleType: [ApplicationRoleType.System, [Validators.required]],
            company: ['', [
                    conditionalValidator(() => Number(this.form.get('applicationRoleType').value) == ApplicationRoleType.Partner, Validators.required)
                ]],
            vendor: ['', [
                    conditionalValidator(() => Number(this.form.get('applicationRoleType').value) == ApplicationRoleType.Vendor, Validators.required)
                ]],
            autoGroupName: ['', [
                    conditionalValidator(() => Number(this.form.get('applicationRoleType').value) == ApplicationRoleType.Store, Validators.required)
                ]],
            isEmailNotificationActive: [true],
            isSMSNotificationActive: [true]
        });
        this.roleDropdownSettings = {
            singleSelection: false,
            idField: 'name',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
        this.storeDropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
    }
    onSubmit() {
        this.submitted = true;
        if (this.form.invalid || this.selectedRoles.length == 0) {
            return;
        }
        let model = new UserAddModel();
        model.name = this.form.controls.name.value;
        model.email = this.form.controls.email.value;
        model.phoneCode = this.form.controls.phoneCode.value;
        model.phoneNumber = this.form.controls.phoneNumber.value;
        model.roles = this.selectedRoles.map(x => x.name);
        model.applicationRoleType = this.selectedApplicationRoleType;
        model.isEmailNotificationActive = this.form.controls.isEmailNotificationActive.value;
        model.isSMSNotificationActive = this.form.controls.isSMSNotificationActive.value;
        if (this.selectedApplicationRoleType != ApplicationRoleType.System) {
            switch (this.selectedApplicationRoleType) {
                case ApplicationRoleType.Partner:
                    model.userCompanyAssociations.push(Number(this.form.controls.company.value));
                    break;
                case ApplicationRoleType.Store:
                    model.userStoreAssociations = this.selectedStores.map(x => x.id);
                    break;
                case ApplicationRoleType.Vendor:
                    model.userVendorAssociations.push(Number(this.form.controls.vendor.value));
                    break;
            }
        }
        this.blockUI.start();
        this.userService.add(model).subscribe(() => {
            setTimeout(() => {
                this.router.navigate(['/manage/user']);
            }, 10);
            setTimeout(() => {
                this.messagingService.success('User has been added successfully.');
            }, 300);
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    onCancel() {
        this.router.navigate(['/manage/user']);
    }
    loadData() {
        this.blockUI.start();
        forkJoin([
            this.roleService.getAlRoles()
        ]).subscribe(([roles]) => {
            this.allRoles = roles;
            this.roles = [...this.allRoles];
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    loadCompanies() {
        this.blockUI.start();
        this.companyService.getCompanies().subscribe((data) => {
            this.companies = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    loadStores(selectAutoGroupName) {
        this.blockUI.start();
        this.storeService.autoGroupStoreSelectList(selectAutoGroupName).subscribe((data) => {
            this.stores = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    loadAutoGroupName() {
        this.blockUI.start();
        this.storeService.allAutoGroupName().subscribe((data) => {
            this.autoGroupNames = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    loadVendors() {
        this.blockUI.start();
        this.vendorService.vendorSelectList().subscribe((data) => {
            this.vendors = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    applyApplicationRoleTypeFilter() {
        this.selectedApplicationRoleType = Number(this.form.controls.applicationRoleType.value);
        this.form.controls.company.updateValueAndValidity();
        this.form.controls.vendor.updateValueAndValidity();
        this.form.controls.autoGroupName.updateValueAndValidity();
        this.roles = this.allRoles.filter(x => x.applicationRoleType == this.selectedApplicationRoleType);
        this.selectedRoles = new Array();
        switch (this.selectedApplicationRoleType) {
            case ApplicationRoleType.Partner:
                this.companies.length === 0 && this.loadCompanies();
                break;
            case ApplicationRoleType.Store:
                this.autoGroupNames.length === 0 && this.loadAutoGroupName();
                break;
            case ApplicationRoleType.Vendor:
                this.vendors.length === 0 && this.loadVendors();
                break;
        }
    }
    autoGroupSelectFilter() {
        this.loadStores(this.form.controls.autoGroupName.value);
    }
    loadCountries() {
        this.blockUI.start();
        this.countryService.getCountries().subscribe((data) => {
            this.countries = data;
            if (this.countries.length == 1) {
                this.loadStates(this.countries[0].id);
                this.form.controls['phoneCode'].setValue(this.countries[0].phoneCode);
            }
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    loadStates(countryId) {
        this.blockUI.start();
        this.countryService.getStates(countryId).subscribe((data) => {
            this.states = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
}
__decorate([
    BlockUI('container-blockui-add-user'),
    __metadata("design:type", Object)
], UserAddComponent.prototype, "blockUI", void 0);
