import { Component } from '@angular/core';

@Component({
  selector: 'app-invoice-format',
  templateUrl: './invoice-format.component.html',
  styleUrls: ['./invoice-format.component.css']
})
export class InvoiceFormatComponent{

  
}
