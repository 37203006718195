var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BlockUI } from 'ng-block-ui';
import { AuthenticateRequestModel } from 'src/app/models';
import { AuthenticationService, MessagingService } from 'src/app/services';
export class LoginComponent {
    constructor(router, route, messagingService, authenticationService, formBuilder) {
        this.router = router;
        this.route = route;
        this.messagingService = messagingService;
        this.authenticationService = authenticationService;
        this.formBuilder = formBuilder;
        this.loginImage = "assets/images/login-img.png";
        this.loginImage2 = "assets/images/login-img2.png";
        this.logo = "assets/images/recon-partners-logo.png";
        this.submitted = false;
        this.route.queryParams.subscribe(params => {
            this.returnUrl = params['returnUrl'];
        });
    }
    ngOnInit() {
        this.form = this.formBuilder.group({
            userName: ['', [
                    Validators.required
                ]],
            password: ['', [
                    Validators.required
                ]]
        });
    }
    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid.
        if (this.form.invalid) {
            return;
        }
        this.blockUI.start();
        let authenticateRequestModel = new AuthenticateRequestModel();
        authenticateRequestModel.userName = this.form.value.userName;
        authenticateRequestModel.password = this.form.value.password;
        this.authenticationService.login(authenticateRequestModel).subscribe((isLoggedIn) => {
            if (!isLoggedIn) {
                return;
            }
            if (this.returnUrl) {
                this.router.navigate([this.returnUrl]);
            }
            else {
                this.router.navigate(['/']);
            }
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
}
__decorate([
    BlockUI('blockui-frm-login'),
    __metadata("design:type", Object)
], LoginComponent.prototype, "blockUI", void 0);
