import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Label } from 'ng2-charts';
import { forkJoin, Subscription } from 'rxjs';
import { subscribeOn } from 'rxjs/operators';
import { CompanyWithStoreModel, KpiFilterModel, SelectListModel } from 'src/app/models';
import { ApplicationContextService, KpiBannerService, MessagingService } from 'src/app/services';

@Component({
  selector: 'app-shop-time-chart',
  templateUrl: './shop-time-chart.component.html',
  styleUrls: ['./shop-time-chart.component.css']
})
export class ShopTimeChartComponent implements OnInit, OnDestroy {
  @BlockUI('container-blockui-shop-time') blockUI: NgBlockUI;
  @ViewChild('canvas', { static: false }) canvas: ElementRef;

  maxValue: number;
  chartData = false;
  barChartData: ChartDataSets[];
  barChartLabels: Label[] = ['Series A', 'Series B', 'Series C'];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartOptions: ChartOptions;
  filterModel = new KpiFilterModel();

  subscriptions = new Array<Subscription>();

  constructor(private kpiBannerService: KpiBannerService,
    private messagingService: MessagingService,
    private applicationContextService: ApplicationContextService) {

    const storeSubscription = this.applicationContextService.store$.subscribe((storeSelectListItem: SelectListModel) => {
      if (storeSelectListItem) {
        this.filterModel.storeId = storeSelectListItem.id;
      } else {
        this.filterModel.storeId = null;
      }
      this.loadData();
    });
    this.subscriptions.push(storeSubscription);
  }



  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  private loadData() {
    this.blockUI.start();
    forkJoin([
      this.kpiBannerService.getShopTimeForCurrentMonth(this.filterModel),
      this.kpiBannerService.getShopTimeForLastOneMonth(this.filterModel),
      this.kpiBannerService.getShopTimeForLastTwoMonth(this.filterModel),
    ]).subscribe((result) => {
      this.barChartData = [{
        data: [result[2].totalShopTime, result[1].totalShopTime, result[0].totalShopTime], label: '',
        backgroundColor: ['#e5444d', '#eb6066', '#ef9296'],
        borderColor: ['#e5444d', '#eb6066', '#ef9296'],
      }];
      const greatestValue = Math.round(Math.max(result[2].totalShopTime, result[1].totalShopTime, result[0].totalShopTime) + 10);
      const calculatedMaxValue = Math.ceil((greatestValue + 1) / 10) * 10;
      this.maxValue = Math.max(calculatedMaxValue, 50);

      this.barChartOptions = {
        responsive: false,
        maintainAspectRatio: true,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              offsetGridLines: false
            }
          }],
          yAxes: [{
            ticks: {
              stepSize: 10,
              beginAtZero: true,
              min: 0,
              max: this.maxValue
            }
          }]
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
          }
        },
      };

      this.chartData = true;
      this.blockUI.stop();
    }, error => {
      this.messagingService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }
}
