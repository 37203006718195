import { ChangeDetectorRef, Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { AccountResetModel } from "src/app/models";
import { MessagingService, AuthenticationService } from "src/app/services";
import { MustMatch, MustNotMatch } from "src/app/validators";

@Component({
    selector: 'app-account-reset',
    templateUrl: './account.reset.component.html',
    styleUrls: ['./account.reset.component.css']
})
export class AccountResetComponent {

    loginImage: string = "assets/images/login-img.png";
    loginImage2: string = "assets/images/login-img2.png";
    logo: string = "assets/images/recon-partners-logo.png";

    @BlockUI('container-blockui') blockUI: NgBlockUI;
    frm: FormGroup;
    submitted = false;
    passwordIsValid = false;

    model: AccountResetModel = new AccountResetModel();

    constructor(private router: Router,
        private route: ActivatedRoute,
        private messagingService: MessagingService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
        private formBuilder: FormBuilder) {

        this.route.params.subscribe((params) => {
            this.model.key = params['key'];
            this.model.secret = params['secret'];
        });
    }

    ngOnInit() {
        this.frm = this.formBuilder.group({
            temporaryPassword: ['', [Validators.required]],
            newPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
            confirmPassword: ['', Validators.required]
        }, {
            validator: [
                MustNotMatch('temporaryPassword', 'newPassword'),
                MustMatch('newPassword', 'confirmPassword')
            ]
        });
    }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid.
        if (!this.passwordIsValid || this.frm.invalid) {
            return;
        }

        this.model.temporaryPassword = this.frm.controls.temporaryPassword.value;
        this.model.newPassword = this.frm.controls.newPassword.value;
        this.model.confirmPassword = this.frm.controls.confirmPassword.value;

        this.blockUI.start();
        this.authenticationService.reset(this.model)
            .subscribe(
                () => {
                    this.blockUI.stop();
                    setTimeout(() => {
                        this.router.navigate(['/account/login']);
                    }, 100);
                    setTimeout(() => {
                        this.messagingService.success('Your account has been reset successfully. Please login to continue');
                    }, 500);
                },
                error => {
                    this.blockUI.stop();
                    this.messagingService.ProcessErrorResponse(error);
                });
    }

    passwordValid($event: boolean) {
        this.passwordIsValid = $event;
        this.cdr.detectChanges();
    }
}