import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { DataTableResponseModel, SelectListModel, VendorAddModel, VendorDetailModel } from "../models";

@Injectable({
    providedIn: 'root'
})

export class VendorService{
    constructor(private http: HttpClient) {
    }
  
    query(dataTablesParameters: any) {
      return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/vendor/query`, dataTablesParameters);
    }
  
    get(id: number): Observable<VendorDetailModel> {
      return this.http.get<VendorDetailModel>(`${environment.apiBaseUrl}/vendor/${id}`);
    }
  
    add(data: VendorAddModel) {
      return this.http.post<VendorDetailModel>(`${environment.apiBaseUrl}/vendor`, data);
    }
  
    update(data: VendorDetailModel) {
      return this.http.put<VendorDetailModel>(`${environment.apiBaseUrl}/vendor`, data);
    }
  
    delete(id: number) {
      return this.http.delete<object>(`${environment.apiBaseUrl}/vendor/${id}`);
    }
  
    toggleStatus(id: number) {
      return this.http.patch<object>(`${environment.apiBaseUrl}/vendor/toggle-status/${id}`, null);
    }

    deleteDocument(fileName: string) {
      return this.http.delete<object>(`${environment.apiBaseUrl}/vendor/delete-document/${fileName}`);
    }

    vendorSelectList():Observable<Array<SelectListModel>>{
      return this.http.get<Array<SelectListModel>>(`${environment.apiBaseUrl}/vendor/select-list-vendors`);
    }
}