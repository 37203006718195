<div class="chart-container">
    <canvas baseChart [datasets]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="barChartLegend"
    [chartType]="barChartType" *ngIf="chartData" width="250" height="150px"></canvas>
    <div class="row">
        <div class="col-12 text-center">
            <h6> Total Shop Time</h6>
        </div>
    </div>
</div>