import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { VehicleType } from "../enums";
import { FinalInspectionModel, MechanicalAdviseAddModel, MechanicalDispatchModel, VehicleAssessmentAddModel, VehicleAssessmentModel, VehicleQueueFilterResponseModel, VehicleQueueModel, VehicleQueueNoteAddModel, VehicleQueueNoteModel, VehicleQueueRequestModel, VehicleWorkDetailModel, VendorActionModel } from "../models";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class VehicleQueueService {
    constructor(http) {
        this.http = http;
    }
    get(id) {
        return this.http.get(`${environment.apiBaseUrl}/vehicle/queue/${id}`);
    }
    fetchQueueItems(model) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/queue`, model);
    }
    getVehicleQueueFilterCount(model) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/queue/filters/count`, model);
    }
    startQueueStage(vehicleQueueId, vehicleQueueStageId, storeId) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/stage/${vehicleQueueStageId}/start`, null);
    }
    startAssessmentQueueStage(vehicleQueueId, vehicleQueueStageId, vehicleType, storeId) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/stage/${vehicleQueueStageId}/vehicle/${vehicleType}/start`, null);
    }
    cancelQueueStage(vehicleQueueId, storeId, vehicleQueueStageId) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/stage/${vehicleQueueStageId}/cancel`, null);
    }
    pauseQueueStage(vehicleQueueId, storeId, vehicleQueueStageId) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/stage/${vehicleQueueStageId}/pause`, null);
    }
    stopQueueStage(vehicleQueueId, storeId, vehicleQueueStageId) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/stage/${vehicleQueueStageId}/stop`, null);
    }
    updateVehicleInfo(model) {
        return this.http.patch(`${environment.apiBaseUrl}/vehicle/queue/update/vehicle-info`, model);
    }
    //vehicle queue note
    getNotes(vehicelQueueId) {
        return this.http.get(`${environment.apiBaseUrl}/vehicle-queue-note/notes/${vehicelQueueId}`);
    }
    add(model) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle-queue-note`, model);
    }
    //Assesment
    saveVehicleAssessment(model) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/assessment`, model);
    }
    draftVehicleAssessment(model) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/assessment/draft`, model);
    }
    getVehicleQuoteAssessment(vehicelQueueId) {
        return this.http.get(`${environment.apiBaseUrl}/vehicle/queue/${vehicelQueueId}/quote/assessments`);
    }
    //mechanical advise
    addMechanicalAdvise(model) {
        return this.http.post(`${environment.apiBaseUrl}/mechanical/assessment`, model);
    }
    getMechanicalAdvise(vehicleQueueId) {
        return this.http.get(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/mechanical/assessments`);
    }
    getAddedCosmetics(vehicleQueueId) {
        return this.http.get(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/added-cosmetics`);
    }
    getApprovWorkDetails(vehicleQueueId) {
        return this.http.get(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/approved`);
    }
    //mechanical dispatch
    getApproveMechanicalAdvise(vehicleQueueId) {
        return this.http.get(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/mechanical/approve`);
    }
    addMechanicalDispatch(model) {
        return this.http.post(`${environment.apiBaseUrl}/mechanical/dispatch`, model);
    }
    getVendorAction(vehicleQueueId, vehicleQueueStageId) {
        return this.http.get(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/stage/${vehicleQueueStageId}/work/detail`);
    }
    getVehicleWorkDetailByStage(vehicleQueueId, vehicleQueueStageId) {
        return this.http.get(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/stage/${vehicleQueueStageId}/work/detail/stage`);
    }
    //Vendor Actions
    saveVendorActions(model) {
        return this.http.post(`${environment.apiBaseUrl}/vendor/action`, model);
    }
    rejectQueueStage(model) {
        return this.http.post(`${environment.apiBaseUrl}/vendor/action/stage/reject`, model);
    }
    //final inspection
    getFinalInspectionWorkDetail(vehicleQueueId) {
        return this.http.get(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/final/inspection`);
    }
    saveFinalInspectionWorkDetail(model) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/final/inspection`, model);
    }
}
VehicleQueueService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VehicleQueueService_Factory() { return new VehicleQueueService(i0.ɵɵinject(i1.HttpClient)); }, token: VehicleQueueService, providedIn: "root" });
