import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { forkJoin } from 'rxjs';
import { ApplicationRoleType, NotificationType } from 'src/app/enums';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { RoleModel, SelectListModel, UserDetailModel, UserEditModel,CountryModel,StateModel } from 'src/app/models';
import { UserService, CompanyService, MessagingService, RoleService, StoreService, VendorService , CountryService, FilterStateService } from 'src/app/services';
import { conditionalValidator } from 'src/app/validators';

@Component({
    selector: 'app-user-edit',
    templateUrl: './user.edit.component.html',
    styleUrls: ['./user.edit.component.css']
})
export class UserEditComponent implements OnInit {
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    countries: Array<CountryModel> = new Array<CountryModel>();
    states: Array<StateModel> = new Array<StateModel>();

    //Context for which users will be created
    selectedApplicationRoleType: ApplicationRoleType = ApplicationRoleType.System;
    roleTypeFilterOptions: Array<SelectListModel>;

    //Role Dropdown list
    allRoles: RoleModel[] = new Array<RoleModel>();
    roles: RoleModel[] = new Array<RoleModel>();
    selectedRoles: RoleModel[] = new Array<RoleModel>();
    roleDropdownSettings: IDropdownSettings = {};
    storeDropdownSettings: IDropdownSettings = {};
    ApplicationRoleType = ApplicationRoleType;
    NotificationType = NotificationType;
    companies: Array<SelectListModel> = new Array<SelectListModel>();
    stores: Array<SelectListModel> = new Array<SelectListModel>();
    autoGroupNames: Array<string> = new Array<string>();
    vendors: Array<SelectListModel> = new Array<SelectListModel>();
    selectedStores: Array<SelectListModel> = new Array<SelectListModel>();
    form: FormGroup;
    model: UserDetailModel;
    submitted = false;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
        private companyService: CompanyService,
        public countryService: CountryService,
        private messagingService: MessagingService,
        private storeService: StoreService,
        private vendorService: VendorService,
        public appUtils: AppUtils,
        private formBuilder: FormBuilder,
        private roleService: RoleService,
        private stringLengthConstants: StringLengthConstants,
        private filterStateService: FilterStateService
    ) {

        filterStateService.changeFilterModelStatues(FilterPageType.userManage, true);
        this.model = new UserDetailModel();

        this.route.params.subscribe(params => {
            this.model.id = params.userId;
        });

        this.form = this.formBuilder.group({
            id: [null],
            name: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            email: ['', [Validators.required, Validators.email, Validators.maxLength(this.stringLengthConstants.Email)]],
            phoneCode: ['',[Validators.required]],
            phoneNumber: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.PostalCode), this.appUtils.validatePhoneNumber()]],
            applicationRoleType: ['', [Validators.required]],
            company: ['', [
                conditionalValidator(() => Number(this.form.get('applicationRoleType').value) == ApplicationRoleType.Partner,
                    Validators.required)
            ]],
            vendor: ['', [
                conditionalValidator(() => Number(this.form.get('applicationRoleType').value) == ApplicationRoleType.Vendor,
                    Validators.required)
            ]],
            autoGroupName: ['', [
                conditionalValidator(() => Number(this.form.get('applicationRoleType').value) == ApplicationRoleType.Store,
                    Validators.required)
            ]],
            isEmailNotificationActive: [false],
            isSMSNotificationActive: [false]
        });

        this.roleDropdownSettings = {
            singleSelection: false,
            idField: 'name',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };

        this.storeDropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };

        this.roleTypeFilterOptions = this.appUtils.getApplicationRoleTypes();
    }


    ngOnInit() {
        this.loadData();
        this.loadCountries();
    }

    updateUser() {
        this.submitted = true;

        if (this.form.invalid || this.selectedRoles.length == 0) {
            return;
        }

        let model = new UserEditModel();
        model.id = this.model.id;
        model.name = this.form.controls.name.value;
        model.phoneCode = this.form.controls.phoneCode.value;
        model.phoneNumber = this.form.controls.phoneNumber.value;
        model.email = this.form.controls.email.value;
        model.roles = this.selectedRoles.map(x => x.name);
        model.applicationRoleType = this.selectedApplicationRoleType;
        model.isEmailNotificationActive = this.form.controls.isEmailNotificationActive.value;
        model.isSMSNotificationActive = this.form.controls.isSMSNotificationActive.value;
        if (this.selectedApplicationRoleType != ApplicationRoleType.System) {
            switch (this.selectedApplicationRoleType) {
                case ApplicationRoleType.Partner:
                    model.userCompanyAssociations.push(Number(this.form.controls.company.value));
                    break;
                case ApplicationRoleType.Store:
                    model.userStoreAssociations = this.selectedStores.map(x => x.id);
                    break;
                case ApplicationRoleType.Vendor:
                    model.userVendorAssociations.push(Number(this.form.controls.vendor.value));
                    break;
            }
        }

        this.blockUI.start();
        this.userService.edit(model).subscribe(() => {
            this.blockUI.stop();
            this.messagingService.success('User has been updated successfully.');
            this.router.navigate(['/manage/user']);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }

    private loadData() {
        this.blockUI.start();
        forkJoin([
            this.roleService.getAlRoles(),
            this.userService.getUserDetail(this.model.id)
        ]).subscribe(([
            roles,
            userModel
        ]) => {
            this.allRoles = roles;
            this.model = userModel;
            this.setFormData();
            this.applyApplicationRoleTypeFilter();
            this.selectedRoles = this.allRoles.filter(x => this.model.roles.includes(x.name));
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    private setFormData() {
        this.form.patchValue({
            id: this.model.id,
            name: this.model.name,
            email: this.model.email,
            phoneCode: this.model.phoneCode,
            phoneNumber: this.model.phoneNumber,
            applicationRoleType: this.model.applicationRoleType,
            isEmailNotificationActive: this.model.isEmailNotificationActive,
            isSMSNotificationActive: this.model.isSMSNotificationActive
        });

        if (this.model.applicationRoleType == ApplicationRoleType.Partner) {
            this.form.controls.company.setValue(this.model.userCompanyAssociations[0].id);
        } else if (this.model.applicationRoleType == ApplicationRoleType.Vendor) {
            this.form.controls.vendor.setValue(this.model.userVendorAssociations[0].id);
        } else if (this.model.applicationRoleType == ApplicationRoleType.Store) {
            this.form.controls.autoGroupName.setValue(this.model.userStoreAssociations[0].autoGroupName);
            this.autoGroupSelectFilter();
        }
    }

    private loadCompanies() {
        this.blockUI.start();
        this.companyService.getCompanies().subscribe((data: Array<SelectListModel>) => {
            this.companies = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    private loadStores(selectAutoGroupName: string) {
        this.blockUI.start();
        this.storeService.autoGroupStoreSelectList(selectAutoGroupName).subscribe((data: Array<SelectListModel>) => {
            this.stores = data;
            if (this.model && this.model.applicationRoleType) {
                this.selectedStores = data.filter(x => this.model.userStoreAssociations.some(y => y.id === x.id));
            }
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    private loadAutoGroupName() {
        this.blockUI.start();
        this.storeService.allAutoGroupName().subscribe((data: Array<string>) => {
            this.autoGroupNames = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    private loadVendors() {
        this.blockUI.start();
        this.vendorService.vendorSelectList().subscribe((data: Array<SelectListModel>) => {
            this.vendors = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    private loadCountries() {
        this.blockUI.start();
        this.countryService.getCountries().subscribe((data: any) => {
            this.countries = data;
            if (this.countries.length == 1) 
            {
                this.loadStates(this.countries[0].id);
                this.form.controls['phoneCode'].setValue(this.countries[0].phoneCode);
            }
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    private loadStates(countryId: number) {
        this.blockUI.start();
        this.countryService.getStates(countryId).subscribe((data: any) => {
            this.states = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    applyApplicationRoleTypeFilter() {
        this.selectedApplicationRoleType = Number(this.form.controls.applicationRoleType.value);

        this.form.controls.company.updateValueAndValidity();
        this.form.controls.vendor.updateValueAndValidity();
        this.form.controls.autoGroupName.updateValueAndValidity();

        this.roles = this.allRoles.filter(x => x.applicationRoleType == this.selectedApplicationRoleType);
        this.selectedRoles = new Array<RoleModel>();

        switch (this.selectedApplicationRoleType) {
            case ApplicationRoleType.Partner:
                this.companies.length === 0 && this.loadCompanies();
                break;
            case ApplicationRoleType.Store:
                this.autoGroupNames.length === 0 && this.loadAutoGroupName();
                break;
            case ApplicationRoleType.Vendor:
                this.vendors.length === 0 && this.loadVendors();
                break;
        }
    }

    autoGroupSelectFilter() {
        this.loadStores(this.form.controls.autoGroupName.value);
    }
}
