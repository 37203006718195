import { PricingType } from "src/app/enums";
import { PriceMatrixUnitPriceDetailModel } from "..";

export class PriceMatrixDetailModel {
    public id: number;
    public storeId: number;
    public storeName: string;

    public categoryId: number;
    public categoryName: string;

    public subCategoryId: number;
    public subCategoryName: string;

    public carLayoutPanelIds: Array<number>;
    public carLayoutPanelNames: Array<string>;
    public isActive: boolean;

    public unitOfMeasure: number;
    public unitPrices: PriceMatrixUnitPriceDetailModel[];

    public checkInTypeId: number;
    public checkInTypeName: string;
    public isReconPackage: boolean;
    public isApprovalRequired: boolean;
    public isPORequired: boolean;
    public isSharedWorkQueue: boolean;
    public isInspectionRequired: boolean;
    public isInvoice: boolean;
    
    constructor() {
        this.carLayoutPanelIds = new Array<number>();
        this.carLayoutPanelNames = new Array<string>();
        this.unitPrices = new Array<PriceMatrixUnitPriceDetailModel>();
    }
}