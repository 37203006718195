var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockUI } from "ng-block-ui";
import { ColorPickerService } from "ngx-color-picker";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";
import { FilterPageType } from "src/app/enums/filter.page.type.enum";
import { AppUtils, StringLengthConstants } from "src/app/helpers";
import { CategoryEditModel } from "src/app/models";
import { CategoryService, OperationCodeService, MessagingService, FilterStateService } from "src/app/services";
export class CategoryEditComponent {
    constructor(formBuilder, router, route, toastr, messagingService, operationCodeService, categoryService, stringLengthConstants, cpService, appUtils, filterStateService) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.route = route;
        this.toastr = toastr;
        this.messagingService = messagingService;
        this.operationCodeService = operationCodeService;
        this.categoryService = categoryService;
        this.stringLengthConstants = stringLengthConstants;
        this.cpService = cpService;
        this.appUtils = appUtils;
        this.filterStateService = filterStateService;
        this.submitted = false;
        this.canEdit = false;
        this.operationCodeSelectList = new Array();
        filterStateService.changeFilterModelStatues(FilterPageType.categoryManage, true);
        this.model = new CategoryEditModel();
        this.route.params.subscribe(p => this.model.id = Number(p.categoryId));
        this.presetColors = this.appUtils.getPresetColors();
        this.colorCode = this.presetColors[0];
    }
    ngOnInit() {
        this.frmCategory = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            code: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.ShortCode), Validators.minLength(2), Validators.pattern(new RegExp('^[A-Za-z0-9]*$'))]],
            description: ['', [Validators.maxLength(this.stringLengthConstants.Description)]],
            sequence: ['', [Validators.min(1), Validators.pattern(new RegExp('^[-+]?[0-9]*\.?[0-9]*$'))]],
            useToolInWorkflow: [false]
        });
        this.loadCategory();
    }
    loadCategory() {
        this.blockUI.start();
        forkJoin([
            this.categoryService.get(this.model.id),
            this.operationCodeService.selectListByCategoryId(this.model.id)
        ]).subscribe((result) => {
            this.model = result[0];
            this.canEdit = result[1].length === 0;
            this.initForm();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    initForm() {
        this.frmCategory.controls.name.setValue(this.model.name);
        this.frmCategory.controls.code.setValue(this.model.code);
        this.frmCategory.controls.description.setValue(this.model.description);
        this.frmCategory.controls.sequence.setValue(this.model.sequence);
        this.frmCategory.controls.useToolInWorkflow.setValue(this.model.useToolInWorkflow);
        this.colorCode = this.model.colorCode;
    }
    onSubmit() {
        this.submitted = true;
        if (this.frmCategory.invalid) {
            return;
        }
        // this.model.name = this.frmCategory.controls.name.value;
        this.model.code = this.frmCategory.controls.code.value;
        this.model.description = this.frmCategory.controls.description.value;
        this.model.sequence = Number(this.frmCategory.controls.sequence.value);
        this.model.colorCode = this.colorCode;
        this.model.useToolInWorkflow = this.frmCategory.controls.useToolInWorkflow.value;
        this.categoryService.update(this.model)
            .subscribe((data) => {
            setTimeout(() => {
                this.router.navigate(['/manage/category']);
            }, 10);
            setTimeout(() => {
                this.toastr.success('Category has been updated successfully.');
            }, 300);
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    onChangeColorHex8(color) {
        const hsva = this.cpService.stringToHsva(color, true);
        if (hsva) {
            this.colorCode = this.cpService.outputFormat(hsva, "hex", null);
            return this.colorCode;
        }
        return "";
    }
}
__decorate([
    BlockUI('blockui-container'),
    __metadata("design:type", Object)
], CategoryEditComponent.prototype, "blockUI", void 0);
