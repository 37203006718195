import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

import { VehicleType, VehicleQueueStatus } from "../enums";
import { FinalInspectionModel, MechanicalAdviseAddModel, MechanicalDispatchModel, RejectedVehicleWorkDetailModel, VehicleAssessmentAddModel, VehicleAssessmentModel, VehicleQueueCategoryModel, VehicleQueueFilterResponseModel, VehicleQueueModel, VehicleQueueNoteAddModel, VehicleQueueNoteModel, VehicleQueueRequestModel, VehicleWorkDetailModel, VendorActionModel, WorkflowModel } from "../models";

@Injectable({
    providedIn: 'root'
})
export class VehicleQueueService {

    constructor(private http: HttpClient) {
    }

    public get(id: number): Observable<VehicleQueueModel> {
        return this.http.get<VehicleQueueModel>(`${environment.apiBaseUrl}/vehicle/queue/${id}`);
    }

    public fetchQueueItems(model: VehicleQueueRequestModel): Observable<VehicleQueueModel[]> {
        return this.http.post<VehicleQueueModel[]>(`${environment.apiBaseUrl}/vehicle/queue`, model);
    }

    public getVehicleQueueFilterCount(model: VehicleQueueRequestModel): Observable<VehicleQueueFilterResponseModel> {
        return this.http.post<VehicleQueueFilterResponseModel>(`${environment.apiBaseUrl}/vehicle/queue/filters/count`, model);
    }

    public startQueueStage(vehicleQueueId: number, vehicleQueueStageId: number, storeId: number) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/stage/${vehicleQueueStageId}/start`, null);
    }

    public startAssessmentQueueStage(vehicleQueueId: number, vehicleQueueStageId: number, vehicleType: VehicleType, storeId: number) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/stage/${vehicleQueueStageId}/vehicle/${vehicleType}/start`, null);
    }

    public cancelQueueStage(vehicleQueueId: number, storeId: number, vehicleQueueStageId: number) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/stage/${vehicleQueueStageId}/cancel`, null);
    }

    public pauseQueueStage(vehicleQueueId: number, storeId: number, vehicleQueueStageId: number) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/stage/${vehicleQueueStageId}/pause`, null);
    }

    public stopQueueStage(vehicleQueueId: number, storeId: number, vehicleQueueStageId: number) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/stage/${vehicleQueueStageId}/stop`, null);
    }

    public updateVehicleInfo(model: VehicleQueueModel) {
        return this.http.patch(`${environment.apiBaseUrl}/vehicle/queue/update/vehicle-info`, model);
    }

    //vehicle queue note
    getNotes(vehicelQueueId: number): Observable<Array<VehicleQueueNoteModel>> {
        return this.http.get<Array<VehicleQueueNoteModel>>(`${environment.apiBaseUrl}/vehicle-queue-note/notes/${vehicelQueueId}`);
    }

    add(model: VehicleQueueNoteAddModel) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle-queue-note`, model);
    }

    //Assesment
    public saveVehicleAssessment(model: VehicleAssessmentAddModel) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/assessment`, model);
    }

    public draftVehicleAssessment(model: VehicleAssessmentAddModel) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/assessment/draft`, model);
    }

    public getVehicleQuoteAssessment(vehicelQueueId: number): Observable<Array<VehicleAssessmentModel>> {
        return this.http.get<Array<VehicleAssessmentModel>>(`${environment.apiBaseUrl}/vehicle/queue/${vehicelQueueId}/quote/assessments`);
    }

    //mechanical advise
    addMechanicalAdvise(model: MechanicalAdviseAddModel) {
        return this.http.post(`${environment.apiBaseUrl}/mechanical/assessment`, model);
    }
    getMechanicalAdvise(vehicleQueueId: number): Observable<Array<VehicleWorkDetailModel>> {
        return this.http.get<Array<VehicleWorkDetailModel>>(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/mechanical/assessments`);
    }


    getAddedCosmetics(vehicleQueueId: number): Observable<Array<VehicleWorkDetailModel>> {
        return this.http.get<Array<VehicleWorkDetailModel>>(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/added-cosmetics`);
    }

    getApprovWorkDetails(vehicleQueueId: number): Observable<Array<VehicleWorkDetailModel>> {
        return this.http.get<Array<VehicleWorkDetailModel>>(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/approved`);
    }

    //mechanical dispatch
    getApproveMechanicalAdvise(vehicleQueueId: number): Observable<Array<VehicleWorkDetailModel>> {
        return this.http.get<Array<VehicleWorkDetailModel>>(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/mechanical/approve`);
    }

    addMechanicalDispatch(model: MechanicalDispatchModel) {
        return this.http.post(`${environment.apiBaseUrl}/mechanical/dispatch`, model);
    }

    getVendorAction(vehicleQueueId: number, vehicleQueueStageId: number): Observable<Array<VehicleWorkDetailModel>> {
        return this.http.get<Array<VehicleWorkDetailModel>>(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/stage/${vehicleQueueStageId}/work/detail`);
    }

    getVehicleWorkDetailByStage(vehicleQueueId: number, vehicleQueueStageId: number,): Observable<Array<VehicleWorkDetailModel>> {
        return this.http.get<Array<VehicleWorkDetailModel>>(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/stage/${vehicleQueueStageId}/work/detail/stage`);
    }

    //Vendor Actions
    saveVendorActions(model: VendorActionModel) {
        return this.http.post(`${environment.apiBaseUrl}/vendor/action`, model);
    }

    public rejectQueueStage(model: VendorActionModel) {
        return this.http.post(`${environment.apiBaseUrl}/vendor/action/stage/reject`, model);
    }

    //final inspection
    getFinalInspectionWorkDetail(vehicleQueueId: number): Observable<Array<VehicleWorkDetailModel>> {
        return this.http.get<Array<VehicleWorkDetailModel>>(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/final/inspection`);
    }

    saveFinalInspectionWorkDetail(model: FinalInspectionModel) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/final/inspection`, model);
    }

}

