/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./workflow.stage.detail.widget.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../detail/detail-workflow-stage.component.ngfactory";
import * as i4 from "../detail/detail-workflow-stage.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../../../services/messaging.service";
import * as i7 from "../../../../services/operation.code.service";
import * as i8 from "./workflow.stage.detail.widget.component";
var styles_WorkflowStageDetailWidgetComponent = [i0.styles];
var RenderType_WorkflowStageDetailWidgetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WorkflowStageDetailWidgetComponent, data: {} });
export { RenderType_WorkflowStageDetailWidgetComponent as RenderType_WorkflowStageDetailWidgetComponent };
function View_WorkflowStageDetailWidgetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["- ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.subCategoryName; _ck(_v, 1, 0, currVal_0); }); }
function View_WorkflowStageDetailWidgetComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["title", "Dependency Locked"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "em", [["aria-hidden", "true"], ["class", "fa fa-lock"]], null, null, null, null, null))], null, null); }
function View_WorkflowStageDetailWidgetComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "taskIcon"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkflowStageDetailWidgetComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["title", "View Detail"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openWorkflowStepDetailModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "em", [["aria-hidden", "true"], ["class", "fa fa-eye"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.workflowStageModel.dependencyLocked; _ck(_v, 2, 0, currVal_0); }, null); }
function View_WorkflowStageDetailWidgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "taskBoxInner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [["class", "taskContent"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkflowStageDetailWidgetComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkflowStageDetailWidgetComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.subCategoryName; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.index === 0); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.categoryName; _ck(_v, 2, 0, currVal_0); }); }
export function View_WorkflowStageDetailWidgetComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { detailModal: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "taskBox"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "disabled": 0, "highlight": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkflowStageDetailWidgetComponent_1)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-detail-workflow-stage", [], null, null, null, i3.View_WorkflowDetailStageComponent_0, i3.RenderType_WorkflowDetailStageComponent)), i1.ɵdid(8, 573440, [[1, 4], ["detailModal", 4]], 0, i4.WorkflowDetailStageComponent, [i5.NgbModal, i6.MessagingService, i7.OperationCodeService], { categories: [0, "categories"], subCategories: [1, "subCategories"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "taskBox"; var currVal_1 = _ck(_v, 4, 0, _co.isAllCategoriesDisabled, _co.hasMandatoryOPCodes); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.workflowStageModel.workflowStageCategories; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.categories; var currVal_4 = _co.subCategories; _ck(_v, 8, 0, currVal_3, currVal_4); }, null); }
export function View_WorkflowStageDetailWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workflow-stage-detail-widget", [], null, null, null, View_WorkflowStageDetailWidgetComponent_0, RenderType_WorkflowStageDetailWidgetComponent)), i1.ɵdid(1, 573440, null, 0, i8.WorkflowStageDetailWidgetComponent, [], null, null)], null, null); }
var WorkflowStageDetailWidgetComponentNgFactory = i1.ɵccf("app-workflow-stage-detail-widget", i8.WorkflowStageDetailWidgetComponent, View_WorkflowStageDetailWidgetComponent_Host_0, { categories: "categories", subCategories: "subCategories", workflowStageModel: "workflowStageModel" }, {}, []);
export { WorkflowStageDetailWidgetComponentNgFactory as WorkflowStageDetailWidgetComponentNgFactory };
