import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class KpiBannerService {
    constructor(http) {
        this.http = http;
    }
    // turn time
    getTurnTimeForCurrentMonth(filterModel) {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/current-month-turn-time${filterModel.queryParams()}`);
    }
    getTurnTimeForLastOneMonth(filterModel) {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/last-one-month-turn-time${filterModel.queryParams()}`);
    }
    getTurnTimeForLastTwoMonth(filterModel) {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/last-two-month-turn-time${filterModel.queryParams()}`);
    }
    // Shop time
    getShopTimeForCurrentMonth(filterModel) {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/current-month-shop-time${filterModel.queryParams()}`);
    }
    getShopTimeForLastOneMonth(filterModel) {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/last-one-month-shop-time${filterModel.queryParams()}`);
    }
    getShopTimeForLastTwoMonth(filterModel) {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/last-two-month-shop-time${filterModel.queryParams()}`);
    }
    // Vendor Time
    getVendorTimeForCurrentMonth(filterModel) {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/current-month-vendor-time${filterModel.queryParams()}`);
    }
    getVendorTimeForLastOneMonth(filterModel) {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/last-one-month-vendor-time${filterModel.queryParams()}`);
    }
    getVendorTimeForLastTwoMonth(filterModel) {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/last-two-month-vendor-time${filterModel.queryParams()}`);
    }
    // QuoteSaving
    getQuoteSavingForCurrentMonth(filterModel) {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/current-month-quote-saving${filterModel.queryParams()}`);
    }
    getQuoteSavingForLastOneMonth(filterModel) {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/last-one-month-quote-saving${filterModel.queryParams()}`);
    }
    getQuoteSavingForLastTwoMonth(filterModel) {
        return this.http.get(`${environment.apiBaseUrl}/kpi-banner/last-two-month-quote-saving${filterModel.queryParams()}`);
    }
}
KpiBannerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function KpiBannerService_Factory() { return new KpiBannerService(i0.ɵɵinject(i1.HttpClient)); }, token: KpiBannerService, providedIn: "root" });
