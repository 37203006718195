export class VehicleAssessmentModel {
    public carLayoutLocationId: number;
    public carLayoutLocationName: string;

    public carLayoutPanelId: number;
    public carLayoutPanelName: string;

    public carLayoutSideId: number;
    public carLayoutSideName: string;

    public categoryId: number;
    public categoryName: string;

    public subCategoryId: number;
    public subCategoryName: string;

    public operationCodeId: number;
    public operationCodeName: string;
    
    public comment: string;
    public description: string;
}