var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlobService } from 'angular-azure-blob-service';
import * as uuid from 'uuid';
import { BlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { InvoicingPreference, NotificationType, StorageContainerType } from 'src/app/enums';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { VendorDocumentModel, VendorDetailModel } from 'src/app/models';
import { MessagingService, StorageService, CountryService, StoreService, VendorService, CategoryService, SubCategoryService, FilterStateService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
export class VendorEditComponent {
    constructor(formBuilder, router, route, messagingService, appUtils, blobService, storageService, countryService, storeService, vendorService, categoryService, subCategoryService, stringLengthConstants, filterStateService) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.route = route;
        this.messagingService = messagingService;
        this.appUtils = appUtils;
        this.blobService = blobService;
        this.storageService = storageService;
        this.countryService = countryService;
        this.storeService = storeService;
        this.vendorService = vendorService;
        this.categoryService = categoryService;
        this.subCategoryService = subCategoryService;
        this.stringLengthConstants = stringLengthConstants;
        this.filterStateService = filterStateService;
        this.calenderIcon = "/assets/images/calender-icon.png";
        this.downloadIcon = "/assets/images/download.png";
        this.InvoicingPreference = InvoicingPreference;
        this.NotificationType = NotificationType;
        this.countries = new Array();
        this.states = new Array();
        this.categories = new Array();
        this.subCategories = new Array();
        //For store multi select 
        this.storeDropdownSettings = {};
        this.categoryDropdownSettings = {};
        this.subCategoryDropdownSettings = {};
        this.selectedStores = new Array();
        this.selectedCategories = new Array();
        this.selectedSubCategories = new Array();
        this.filteredSubCategories = new Array();
        this.submitted = false;
        this.minExpireDate = {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate()
        };
        this.uploadedPercent = 0;
        filterStateService.changeFilterModelStatues(FilterPageType.vendorManage, true);
        this.storeSelectList = new Array();
        this.categories = new Array();
        this.subCategories = new Array();
        this.model = new VendorDetailModel();
        this.route.params.subscribe(params => {
            this.model.id = params.vendorId;
        });
    }
    ngOnInit() {
        this.multiSelectDropdownSettings();
        this.initForm();
        this.loadData();
    }
    ngOnDestroy() {
    }
    initForm() {
        this.frmEditVendor = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            streetAddress: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Default)]],
            blockAddress: [null, [Validators.maxLength(this.stringLengthConstants.Default)]],
            city: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Default)]],
            state: ['', [Validators.required]],
            country: ['', [Validators.required]],
            zipCode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5),
                    Validators.pattern(new RegExp('^[a-zA-Z0-9-]*$', 'g')), Validators.maxLength(this.stringLengthConstants.PostalCode)]],
            phoneCode: ['', [Validators.required]],
            phoneNumber: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.PhoneNumber),
                    this.appUtils.validatePhoneNumber()]],
            taxId: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(this.stringLengthConstants.TaxId)]],
            invoiceEmail: ['', [Validators.required, Validators.email, Validators.maxLength(this.stringLengthConstants.Email)]],
            insurancePolicy: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Default)]],
            insuranceProvider: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Default)]],
            insuranceExpireDate: ['', [Validators.required]],
            invoicingPreference: [InvoicingPreference.SingleInvoice, Validators.required],
            isEmailNotificationActive: [true],
            isSMSNotificationActive: [true]
        });
    }
    fillFormData() {
        this.frmEditVendor.controls.name.setValue(this.model.name);
        this.selectedStores = this.storeSelectList.filter(x => this.model.storeIds.includes(x.id));
        this.selectedCategories = this.categories.filter(x => this.model.categoryIds.includes(x.id));
        this.selectedSubCategories = this.subCategories.filter(x => this.model.subCategoryIds.includes(x.id));
        this.frmEditVendor.controls.streetAddress.setValue(this.model.streetAddress);
        this.frmEditVendor.controls.blockAddress.setValue(this.model.blockAddress);
        this.frmEditVendor.controls.city.setValue(this.model.city);
        this.frmEditVendor.controls.state.setValue(this.model.state);
        this.frmEditVendor.controls.country.setValue(this.model.country);
        this.frmEditVendor.controls.zipCode.setValue(this.model.zipCode);
        this.frmEditVendor.controls.phoneCode.setValue(this.model.phoneCode);
        this.frmEditVendor.controls.phoneNumber.setValue(this.model.phone);
        this.frmEditVendor.controls.taxId.setValue(this.model.taxId);
        this.frmEditVendor.controls.invoiceEmail.setValue(this.model.invoiceEmail);
        this.frmEditVendor.controls.insurancePolicy.setValue(this.model.insurancePolicy);
        this.frmEditVendor.controls.insuranceProvider.setValue(this.model.insuranceProvider);
        this.frmEditVendor.controls.insuranceExpireDate.setValue(this.model.insuranceExpireDate);
        this.frmEditVendor.controls.isEmailNotificationActive.setValue(this.model.isEmailNotificationActive);
        this.frmEditVendor.controls.isSMSNotificationActive.setValue(this.model.isSMSNotificationActive);
        this.frmEditVendor.controls.invoicingPreference.setValue(this.model.invoicingPreference);
        let expireDate = new Date(this.model.insuranceExpireDate);
        if (expireDate < new Date(new Date().toLocaleDateString())) {
            this.minExpireDate = {
                year: expireDate.getFullYear(),
                month: expireDate.getMonth() + 1,
                day: expireDate.getDate()
            };
        }
    }
    onDocumentSelect(event) {
        if (!event.target.files || event.target.files.length === 0) {
            return;
        }
        [...event.target.files].forEach(file => this.processDocument(file));
        event.target.value = null;
    }
    processDocument(file) {
        this.storageService.getSasToken().subscribe((sasToken) => __awaiter(this, void 0, void 0, function* () {
            const cloudConfig = {
                sas: sasToken.toString(),
                storageAccount: environment.azureAccount,
                containerName: StorageContainerType.VendorDocument
            };
            const fileName = `${uuid.v4()}.${this.appUtils.getFileExtension(file.name)}`;
            const blobUrl = this.blobService.generateBlobUrl(cloudConfig, fileName);
            let document = new VendorDocumentModel();
            document.originalFileName = file.name;
            document.fileName = fileName;
            document.isDeleted = false;
            this.blockUI.start();
            let config = {
                baseUrl: `${blobUrl}?`,
                sasToken: cloudConfig.sas,
                blockSize: 1024 * 64,
                file: file,
                complete: () => {
                    this.storageService.getPublicUrl(StorageContainerType.VendorDocument, document.fileName).subscribe(res => {
                        document.fileUrl = res.toString();
                    });
                    this.model.documents.push(document);
                    this.blockUI.stop();
                },
                error: (err) => {
                    this.messagingService.ProcessErrorResponse(err, 'Vendor File upload timeout.');
                    this.blockUI.stop();
                },
                progress: (percent) => {
                    this.uploadedPercent = percent;
                }
            };
            setTimeout(() => {
                this.blobService.upload(config);
            });
        }), error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    deleteDocument(document, index) {
        this.blockUI.start();
        this.vendorService.deleteDocument(document.fileName).subscribe(() => {
            this.model.documents.splice(index, 1);
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    onSubmit() {
        this.submitted = true;
        if (this.frmEditVendor.invalid
            || this.selectedStores.length == 0
            || this.selectedCategories.length == 0
            || this.model.documents.length == 0) {
            return;
        }
        this.model.storeIds = this.selectedStores.map(x => x.id);
        this.model.categoryIds = this.selectedCategories.map(x => x.id);
        this.model.subCategoryIds = this.selectedSubCategories.map(x => x.id);
        this.model.name = this.frmEditVendor.controls.name.value;
        this.model.streetAddress = this.frmEditVendor.controls.streetAddress.value;
        this.model.blockAddress = this.frmEditVendor.controls.blockAddress.value;
        this.model.city = this.frmEditVendor.controls.city.value;
        this.model.state = this.frmEditVendor.controls.state.value;
        this.model.country = this.frmEditVendor.controls.country.value;
        this.model.zipCode = this.frmEditVendor.controls.zipCode.value;
        this.model.phoneCode = this.frmEditVendor.controls.phoneCode.value;
        this.model.phone = this.frmEditVendor.controls.phoneNumber.value;
        this.model.taxId = this.frmEditVendor.controls.taxId.value;
        this.model.invoiceEmail = this.frmEditVendor.controls.invoiceEmail.value;
        this.model.insurancePolicy = this.frmEditVendor.controls.insurancePolicy.value;
        this.model.insuranceProvider = this.frmEditVendor.controls.insuranceProvider.value;
        this.model.insuranceExpireDate = this.frmEditVendor.controls.insuranceExpireDate.value;
        this.model.invoicingPreference = this.frmEditVendor.controls.invoicingPreference.value;
        this.model.isEmailNotificationActive = this.frmEditVendor.controls.isEmailNotificationActive.value;
        this.model.isSMSNotificationActive = this.frmEditVendor.controls.isSMSNotificationActive.value;
        this.blockUI.start();
        this.vendorService.update(this.model)
            .subscribe((data) => {
            setTimeout(() => {
                this.router.navigate(['/manage/vendor']);
            }, 10);
            setTimeout(() => {
                this.messagingService.success('Vendor has been updated successfully.');
            }, 300);
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    loadData() {
        this.blockUI.start();
        forkJoin([
            this.vendorService.get(this.model.id),
            this.countryService.getCountries(),
            this.categoryService.selectList(),
            this.subCategoryService.getAllSubCategories(),
            this.storeService.storeSelectList()
        ]).subscribe(([vendor, countries, categories, subCategories, stores]) => {
            this.model = vendor;
            this.countries = countries;
            if (this.countries.length == 1) {
                this.loadStates(this.countries[0].id);
                this.frmEditVendor.controls['country'].setValue(this.countries[0].shortName);
                this.frmEditVendor.controls['phoneCode'].setValue(this.countries[0].phoneCode);
            }
            this.categories = categories;
            this.subCategories = subCategories;
            this.storeSelectList = stores;
            this.fillFormData();
            this.filteredSubCategories = this.subCategories.filter(x => this.model.categoryIds.find(z => x.categoryId == z));
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    loadStates(countryId) {
        this.blockUI.start();
        this.countryService.getStates(countryId).subscribe((data) => {
            this.states = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    onCategorySelect() {
        let categoryIds = this.selectedCategories.map(x => x.id);
        if (categoryIds.length == 0) {
            this.onCategoryDeSelectAll();
            return;
        }
        this.filteredSubCategories = [...this.subCategories.filter(x => categoryIds.find(z => z == x.categoryId))];
        this.selectedSubCategories = this.selectedSubCategories.filter(x => this.filteredSubCategories.find(z => z.id == x.id));
    }
    onCategorySelectAll() {
        this.filteredSubCategories = [...this.subCategories];
        this.selectedSubCategories = this.selectedSubCategories.filter(x => this.filteredSubCategories.find(z => z.id == x.id));
    }
    onCategoryDeSelectAll() {
        this.selectedSubCategories = this.filteredSubCategories = new Array();
    }
    multiSelectDropdownSettings() {
        this.storeDropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
        this.categoryDropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
        this.subCategoryDropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
    }
}
__decorate([
    BlockUI('container-blockui-vendor'),
    __metadata("design:type", Object)
], VendorEditComponent.prototype, "blockUI", void 0);
