import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { VehicleImportFilterModel, VehicleImportModel } from "../models";

@Injectable({
    providedIn: 'root'
})
export class VehicleImportService {

    constructor(private http: HttpClient) {
    }

    public fetchImportableVehicles(vehicleImportFilterModel: VehicleImportFilterModel): Observable<VehicleImportModel[]> {
        return this.http.post<VehicleImportModel[]>(`${environment.apiBaseUrl}/vehicle/import/importable`, vehicleImportFilterModel);
    }

    public delete(model: Array<number>) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/import/delete`, model);
    }

    public putOnHold(model: Array<number>) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/import/put-on-hold`, model);
    }

    public removeHold(model: Array<number>) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/import/remove-hold`, model);
    }

    public import(vehicles: VehicleImportModel[]): Observable<VehicleImportModel[]> {
        return this.http.post<VehicleImportModel[]>(`${environment.apiBaseUrl}/vehicle/import`, vehicles);
    }
}