import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
    selector: 'app-time',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnChanges {
    @Input('seconds') intervalInSeconds: number;

    secondsInDay = 86400; //24 * 60 * 60;
    secondsInHour = 3600; // 60 * 60

    //View Properties
    days: number;
    hours: number;
    minutes: number;
    seconds: number;

    ngOnChanges(changes: SimpleChanges): void {
        if (this.intervalInSeconds > 0) {
            //Calculate the Day and Substract Seconds in days 
            this.days = Math.floor(this.intervalInSeconds / this.secondsInDay);
            this.intervalInSeconds = this.intervalInSeconds - this.days * this.secondsInDay;

            //Calculate the Hour and Substract Seconds in hour 
            this.hours = Math.floor(this.intervalInSeconds / this.secondsInHour);
            this.intervalInSeconds = this.intervalInSeconds - this.hours * this.secondsInHour;

            this.minutes = Math.floor(this.intervalInSeconds / 60);
            this.seconds = Math.ceil(this.intervalInSeconds - this.minutes * 60);
        }
    }
}