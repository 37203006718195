import { Component, OnInit } from '@angular/core';
import { UserDetailModel, UserModel, CountryModel, StateModel } from 'src/app/models';
import { MessagingService, UserService,CountryService } from 'src/app/services';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { NotificationType } from 'src/app/enums';
import { validate } from 'uuid';

@Component({
	selector: 'app-user-edit-profile',
	templateUrl: './user.edit.profile.component.html',
	styleUrls: ['./user.edit.profile.component.css']
})
export class UserEditProfileComponent implements OnInit {
	@BlockUI('container-blockui') blockUI: NgBlockUI;
	countries: Array<CountryModel> = new Array<CountryModel>();
    states: Array<StateModel> = new Array<StateModel>();
	form: FormGroup;
	model: UserDetailModel;
	submitted = false;
	NotificationType = NotificationType;

	constructor(private userService: UserService,
		private formBuilder: FormBuilder,
		private messagingService: MessagingService,
		private countryService: CountryService,
		public appUtils: AppUtils,
		private router: Router,
		private stringLengthConstants: StringLengthConstants
	) {

		this.model = new UserDetailModel();
		this.form = this.formBuilder.group({
			id: [null],
			name: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
			email: ['', [Validators.required, Validators.email, Validators.maxLength(this.stringLengthConstants.Email)]],
			phoneCode: ['',[Validators.required]],
			phoneNumber: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.PostalCode), this.appUtils.validatePhoneNumber()]],
			isEmailNotificationActive: [false],
            isSMSNotificationActive: [false]
		});
	}

	ngOnInit() {
		this.blockUI.start();
		this.userService.getMyProfile().subscribe((data: UserDetailModel) => {
			this.model = data;
			this.setformdata();
			this.blockUI.stop();
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
			this.blockUI.stop();
		});

		this.loadCountries();
	}


	setformdata() {
		this.form.patchValue({
			id: this.model.id,
			name: this.model.name,
			email: this.model.email,
			phoneCode: this.model.phoneCode,
			phoneNumber: this.model.phoneNumber,
			isEmailNotificationActive: this.model.isEmailNotificationActive,
            isSMSNotificationActive: this.model.isSMSNotificationActive
		});
	}

	updateUser() {
		this.submitted = true;
		if (this.form.invalid) {
			return;
		}

		let model = new UserModel();
		model.id = this.model.id;
		model.name = this.form.controls.name.value;
		model.phoneCode = this.form.controls.phoneCode.value;
		model.phoneNumber = this.form.controls.phoneNumber.value;
		model.email = this.form.controls.email.value;
		model.isEmailNotificationActive = this.form.controls.isEmailNotificationActive.value;
        model.isSMSNotificationActive = this.form.controls.isSMSNotificationActive.value;
		this.blockUI.start();
		this.userService.updateProfile(model).subscribe(() => {
			this.router.navigate(['my/profile']);
			this.messagingService.success('User profile has been updated successfully.');
			this.blockUI.stop();
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
			this.blockUI.stop();
		});
	}

	private loadCountries() {
        this.blockUI.start();
        this.countryService.getCountries().subscribe((data: any) => {
            this.countries = data;
            if (this.countries.length == 1) 
            {
                this.loadStates(this.countries[0].id);
                this.form.controls['phoneCode'].setValue(this.countries[0].phoneCode);
            }
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    private loadStates(countryId: number) {
        this.blockUI.start();
        this.countryService.getStates(countryId).subscribe((data: any) => {
            this.states = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
}
