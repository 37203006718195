export const environment = {
    production: false,
    env: "demo",
    apiBaseUrl: 'https://demoreconpartnersapi.successive.work/api',
    signalRUrl: 'https://demoreconpartnersapi.successive.work/signalhub',

    azureAccount: 'demoreconpartners',

    paging: {
        defaultPageSize: 50,
        lengthMenu: [50, 100, 200, 500]
    }
};
