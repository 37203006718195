/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shop-time-chart.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng2-charts";
import * as i3 from "@angular/common";
import * as i4 from "./shop-time-chart.component";
import * as i5 from "../../../services/kpi.banner.service";
import * as i6 from "../../../services/messaging.service";
import * as i7 from "../../../services/application.context.service";
var styles_ShopTimeChartComponent = [i0.styles];
var RenderType_ShopTimeChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShopTimeChartComponent, data: {} });
export { RenderType_ShopTimeChartComponent as RenderType_ShopTimeChartComponent };
function View_ShopTimeChartComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "canvas", [["baseChart", ""], ["height", "150px"], ["width", "250"]], null, null, null, null, null)), i1.ɵdid(1, 999424, null, 0, i2.BaseChartDirective, [i1.ElementRef, i2.ThemeService], { datasets: [0, "datasets"], labels: [1, "labels"], options: [2, "options"], chartType: [3, "chartType"], legend: [4, "legend"], plugins: [5, "plugins"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.barChartData; var currVal_1 = _co.barChartLabels; var currVal_2 = _co.barChartOptions; var currVal_3 = _co.barChartType; var currVal_4 = _co.barChartLegend; var currVal_5 = _co.barChartPlugins; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_ShopTimeChartComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { canvas: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "chart-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShopTimeChartComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "col-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Total Shop Time"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chartData; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ShopTimeChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-shop-time-chart", [], null, null, null, View_ShopTimeChartComponent_0, RenderType_ShopTimeChartComponent)), i1.ɵdid(1, 245760, null, 0, i4.ShopTimeChartComponent, [i5.KpiBannerService, i6.MessagingService, i7.ApplicationContextService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShopTimeChartComponentNgFactory = i1.ɵccf("app-shop-time-chart", i4.ShopTimeChartComponent, View_ShopTimeChartComponent_Host_0, {}, {}, []);
export { ShopTimeChartComponentNgFactory as ShopTimeChartComponentNgFactory };
