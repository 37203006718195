import { Injectable } from '@angular/core';
import { FilterStateModel } from '../models';

@Injectable({
    providedIn: 'root'
})
export class FilterStateService {
    private filterStateModels = new Array<FilterStateModel>();

    getFilterModel(page: string): FilterStateModel {
        const model = this.filterStateModels.find(x => x.page == page);
        if (model) {
            return model;
        } else {
            const newFilterModelState = new FilterStateModel();
            newFilterModelState.page = page;
            this.filterStateModels.push(newFilterModelState);
            return newFilterModelState;
        }
    }

    changeFilterModelStatues(page: string, isActive: boolean) {
        const selectedFilterModel = this.filterStateModels.find(x => x.page == page);
        selectedFilterModel.isActive = isActive;
    }

    removeFilterModel(page: string) {
        const selectedFilterModel = this.filterStateModels.find(x => x.page == page);
        const index = this.filterStateModels.indexOf(selectedFilterModel);
        this.filterStateModels.slice(index, 1);
    }
}
