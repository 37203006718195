var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { UserDetailModel, UserModel } from 'src/app/models';
import { MessagingService, UserService, CountryService } from 'src/app/services';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI } from 'ng-block-ui';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { NotificationType } from 'src/app/enums';
export class UserEditProfileComponent {
    constructor(userService, formBuilder, messagingService, countryService, appUtils, router, stringLengthConstants) {
        this.userService = userService;
        this.formBuilder = formBuilder;
        this.messagingService = messagingService;
        this.countryService = countryService;
        this.appUtils = appUtils;
        this.router = router;
        this.stringLengthConstants = stringLengthConstants;
        this.countries = new Array();
        this.states = new Array();
        this.submitted = false;
        this.NotificationType = NotificationType;
        this.model = new UserDetailModel();
        this.form = this.formBuilder.group({
            id: [null],
            name: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            email: ['', [Validators.required, Validators.email, Validators.maxLength(this.stringLengthConstants.Email)]],
            phoneCode: ['', [Validators.required]],
            phoneNumber: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.PostalCode), this.appUtils.validatePhoneNumber()]],
            isEmailNotificationActive: [false],
            isSMSNotificationActive: [false]
        });
    }
    ngOnInit() {
        this.blockUI.start();
        this.userService.getMyProfile().subscribe((data) => {
            this.model = data;
            this.setformdata();
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
        this.loadCountries();
    }
    setformdata() {
        this.form.patchValue({
            id: this.model.id,
            name: this.model.name,
            email: this.model.email,
            phoneCode: this.model.phoneCode,
            phoneNumber: this.model.phoneNumber,
            isEmailNotificationActive: this.model.isEmailNotificationActive,
            isSMSNotificationActive: this.model.isSMSNotificationActive
        });
    }
    updateUser() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        let model = new UserModel();
        model.id = this.model.id;
        model.name = this.form.controls.name.value;
        model.phoneCode = this.form.controls.phoneCode.value;
        model.phoneNumber = this.form.controls.phoneNumber.value;
        model.email = this.form.controls.email.value;
        model.isEmailNotificationActive = this.form.controls.isEmailNotificationActive.value;
        model.isSMSNotificationActive = this.form.controls.isSMSNotificationActive.value;
        this.blockUI.start();
        this.userService.updateProfile(model).subscribe(() => {
            this.router.navigate(['my/profile']);
            this.messagingService.success('User profile has been updated successfully.');
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    loadCountries() {
        this.blockUI.start();
        this.countryService.getCountries().subscribe((data) => {
            this.countries = data;
            if (this.countries.length == 1) {
                this.loadStates(this.countries[0].id);
                this.form.controls['phoneCode'].setValue(this.countries[0].phoneCode);
            }
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    loadStates(countryId) {
        this.blockUI.start();
        this.countryService.getStates(countryId).subscribe((data) => {
            this.states = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
}
__decorate([
    BlockUI('container-blockui'),
    __metadata("design:type", Object)
], UserEditProfileComponent.prototype, "blockUI", void 0);
