<div class="queueTImeWrp">
	<div class="queueTimer">
		<ul>
			<li>
				<div class="timerH">
					<h4>Waiting</h4><small>Time </small>
				</div>
				<app-time [seconds]="vehicleQueueStageModel.queueTimeInSeconds"></app-time>
			</li>
			<li>
				<div class="timerH">
					<h4>Total</h4><small>Time </small>
				</div>
				<app-time
					[seconds]="vehicleQueueStageModel.queueTimeInSeconds + vehicleQueueStageModel.workTimeInSeconds">
				</app-time>
			</li>
		</ul>
	</div>
</div>