import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { QuotationModel } from "../models";

@Injectable({
    providedIn: 'root'
})
export class QuotationService {

    constructor(private http: HttpClient) {
    }

    getQuotation(id: number): Observable<QuotationModel> {
        return this.http.get<QuotationModel>(`${environment.apiBaseUrl}/quotation/${id}`);
    }

    quotationNoBid(id: number, storeId: number, vehicleQueueId: number) {
        return this.http.patch(`${environment.apiBaseUrl}/quotation/${id}/no-bid`, {});
    }

    addQuotation(storeId: number, model: QuotationModel) {
        return this.http.post(`${environment.apiBaseUrl}/quotation`, model);
    }

    startQuotation(quotationId: number, storeId: number, vehicleQueueId: number) {
        return this.http.post(`${environment.apiBaseUrl}/quotation/${quotationId}/start`, {});
    }

    pauseQuotation(quotationId: number) {
        return this.http.post(`${environment.apiBaseUrl}/quotation/${quotationId}/pause`, {});
    }

    cancelQuotation(quotationId: number) {
        return this.http.post(`${environment.apiBaseUrl}/quotation/${quotationId}/cancel`, {});
    }

    getSubmittedQuotations(vehicleQueueId: number): Observable<Array<QuotationModel>> {
        return this.http.get<Array<QuotationModel>>(`${environment.apiBaseUrl}/vehicle/queue/${vehicleQueueId}/quotations`);
    }

    getVehicleQueueStageQuotations(vehicleQueueStageId: number): Observable<Array<QuotationModel>> {
        return this.http.get<Array<QuotationModel>>(`${environment.apiBaseUrl}/vehicle/queue/stage/${vehicleQueueStageId}/quotations`);
    }
}