<ng-template #confirmationModal let-modal>
    <div *blockUI="'container-blockui-confirmation'">
        <div class="modal-body">

            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="confirmPopup">


                    <h5 class="modal-title">{{company.isActive ? "Deactivate" : "Activate"}}</h5>

                    <!-- Activation Section Starts -->

                    <p *ngIf="!company.isActive">Are you sure that you want to <span class="highlight">activate</span>
                        this Managing Company?</p>

                    <!-- Activation Section End -->

                    <!-- Deactivation Section Starts -->
                    <div *ngIf="company.isActive && companies.length">
                        <p>Are you sure that you want to <span class="highlight">deactivate</span> this Managing
                            Company?</p>

                        <p>Select the managing company to which stores will be assigned</p>
                        <div class="form-group">
                            <select class="form-control hDropDown" formControlName="companyId"
                                [ngClass]="{ 'is-invalid': submitted && form.controls.companyId.errors }"
                                placeholder="Managing Company">
                                <option value="">Select Managing Company</option>
                                <option *ngFor="let item of companies" [value]="item.id">
                                    {{item.name}}
                                </option>
                            </select>
                            <div *ngIf="submitted && form.controls.companyId.errors" class="invalid-feedback">
                                <div *ngIf="form.controls.companyId.errors.required">Managing Company is
                                    required</div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="company.isActive && !companies.length">
                        <p>{{company.name}} is only mnaging company which exists. You can't <span
                                class="highlight">deactivate</span> it.</p>
                    </div>


                    <!-- Deactivation Section End -->

                    <div class="buttonWrp">
                        <button type="submit" class="btn btn-red m-r-5"
                            *ngIf="!company.isActive || (company.isActive && companies.length)">Yes</button>
                        <button type="button" class="btn btn-tsrp" (click)="onCancel($event)"
                            *ngIf="!company.isActive || (company.isActive && companies.length)">No</button>

                        <button type="button" class="btn btn-tsrp" (click)="onCancel($event)"
                            *ngIf="company.isActive && companies.length == 0">Ok</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>