import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DndDropEvent } from 'ngx-drag-drop';
import { forkJoin } from 'rxjs';
import { VehicleQueueModel, CategoryDetailModel, SelectListModel, StoreDetailModel, UpdateVehicleWorkflowModel, SubCategorySelectListModel, WorkflowModel, WorkflowSequenceModel, WorkflowStageCategoryModel, WorkflowStageModel } from 'src/app/models';
import { MessagingService, CategoryService, WorkflowService, CheckInTypeService, StoreService, SubCategoryService, ApplicationContextService, VehicleQueueService } from 'src/app/services';


@Component({
	selector: 'app-update-vehicle-workflow',
	templateUrl: './update-vehicle-workflow.component.html',
	styleUrls: ['./update-vehicle-workflow.component.css']
})
export class UpdateVehicleWorkflowComponent implements OnInit {

	dragType = {
		CATEGORY: 'category',
		WORKFLOWSTAGE: 'workflowStage'
	};

	form: FormGroup;
	submitted = false;

	@BlockUI('container-blockui') blockUI: NgBlockUI;
	@ViewChild('addWorkflowStageModal', { static: false }) addWorkflowStageModal: any;
	@ViewChild('deleteSequenceConfirmationModal', { static: false }) deleteSequenceConfirmationModal: any;

	categories: Array<CategoryDetailModel> = new Array<CategoryDetailModel>();
	checkInTypeSelectList: Array<SelectListModel> = new Array<SelectListModel>();
	store: StoreDetailModel = new StoreDetailModel();
	subCategories: Array<SubCategorySelectListModel> = new Array<SubCategorySelectListModel>();
	workflowModel: WorkflowModel;
	checkInTypeName: string;
	vehicleQueueId: number;
	vehicleQueueStageId: number;
	vehicleQueueModel: VehicleQueueModel = new VehicleQueueModel();
	lockStageIds: number[];
	sequenceFilter: number;
	activeCategory: number[];

	constructor(private router: Router,
		private route: ActivatedRoute,
		public location: Location,
		private formBuilder: FormBuilder,
		private messagingService: MessagingService,
		private checkInTypeService: CheckInTypeService,
		private categoryService: CategoryService,
		private workflowService: WorkflowService,
		private storeService: StoreService,
		private subCategoryService: SubCategoryService,
		private applicationContextService: ApplicationContextService,
		private vehicleQueueService: VehicleQueueService,
	) {
		this.workflowModel = new WorkflowModel();

		this.route.params.subscribe(params => {
			this.workflowModel.id = params.id;
			this.vehicleQueueId = Number(params.vehicleQueueId);
			this.vehicleQueueStageId = Number(params.vehicleQueueStageId);
			if (params.storeId) {
				this.store.id = params.storeId;
			}
		});


	}

	ngOnInit(): void {
		this.loadData();

		this.form = this.formBuilder.group({
			checkInTypeId: ['', [Validators.required]]
		});
	}

	private loadData() {
		this.blockUI.start();
		forkJoin([
			this.categoryService.detailSelectList(),
			this.checkInTypeService.selectList(),
			this.workflowService.get(this.workflowModel.id),
			this.subCategoryService.getAllSubCategories(),
			this.vehicleQueueService.get(this.vehicleQueueId)
		]).subscribe(([
			categories,
			checkInTypeSelectList,
			workflowModel,
			subCategories,
			vehicleQueueModel
		]) => {
			this.vehicleQueueModel = vehicleQueueModel;
			this.sequenceFilter = this.vehicleQueueModel.vehicleQueueStages.filter(a => a.id == this.vehicleQueueStageId).map(t => t.sequence)[0];
			this.lockStageIds = vehicleQueueModel.workedStagecatIds;
			this.lockStageIds = this.vehicleQueueModel.vehicleQueueStages.filter(a => a.sequence <= this.sequenceFilter).map(t => t.workflowStageCategoryId);

			this.activeCategory = this.vehicleQueueModel.vehicleQueueStages.filter(a => a.isActive == true).map(t => t.workflowStageCategoryId);

			this.categories = categories;
			this.checkInTypeSelectList = checkInTypeSelectList;
			this.workflowModel = workflowModel;

			var i;
			for (i = this.workflowModel.workflowDynamicSequences.length - 1; i >= 0; i--) {
				var isSequenceContainCategory = false;

				var k;
				for (k = this.workflowModel.workflowDynamicSequences[i].workflowStages.length - 1; k >= 0; k--) {
					var j;
					for (j = this.workflowModel.workflowDynamicSequences[i].workflowStages[k].workflowStageCategories.length - 1; j >= 0; j--) {
						if (this.activeCategory.indexOf(this.workflowModel.workflowDynamicSequences[i].workflowStages[k].workflowStageCategories[j].id) > -1) {
							isSequenceContainCategory = true;
						}
						else {
							this.workflowModel.workflowDynamicSequences[i].workflowStages[k].workflowStageCategories.splice(j, 1);
						}
					}

					if (this.workflowModel.workflowDynamicSequences[i].workflowStages[k].workflowStageCategories.length == 0) {
						this.workflowModel.workflowDynamicSequences[i].workflowStages.splice(k, 1);
					}
				}

				if (!isSequenceContainCategory) {
					this.workflowModel.workflowDynamicSequences.splice(i, 1);
				}
			}

			this.subCategories = subCategories;
			this.form.controls.checkInTypeId.setValue(this.workflowModel.checkInTypeId);
			let checkInType = this.checkInTypeSelectList.find(x => x.id == this.workflowModel.checkInTypeId);
			if (checkInType != null) {
				this.checkInTypeName = checkInType.name;
			}

			if (this.workflowModel.storeId != null && !this.store.id) {
				this.store.id = this.workflowModel.storeId;
			}
			this.loadStore();
			this.blockUI.stop();
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
			this.blockUI.stop();
		});
	}

	private loadStore() {
		if (!this.store.id) {
			return;
		}

		this.storeService.get(this.store.id).subscribe((store) => {
			this.store = store;
			this.setCategoryStatus();
			this.updateCategoryUsage();
		});
	}

	setCategoryStatus() {
		this.categories.forEach(x => {
			x.isActive = this.store.categoryIds.includes(x.id);
		});
		this.workflowModel.workflowDynamicSequences.forEach(workflowDynamicSequence => {
			workflowDynamicSequence.workflowStages.forEach(workflowStage => {
				workflowStage.workflowStageCategories.forEach(x => {
					x.isActive = this.categories.some(z => z.id == x.categoryId && z.isActive);
				});
			});
		});
	}

	public associateCategoryToStore(categoryDetailModel: CategoryDetailModel) {
		this.blockUI.start();
		this.storeService.associateCategory(this.store.id, categoryDetailModel.id).subscribe(() => {
			this.store.categoryIds.push(categoryDetailModel.id);
			this.setCategoryStatus();
			this.blockUI.stop();
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
			this.blockUI.stop();
		})
	};

	//Open Modal for Addition of WorkflowSequenceModel
	operatingWorkflowSequenceModel: WorkflowSequenceModel
	public openAddWorkflowCategoryModal(workflowSequenceModel: WorkflowSequenceModel) {
		this.operatingWorkflowSequenceModel = workflowSequenceModel;
		this.addWorkflowStageModal.openModal(new WorkflowStageModel());
	}

	//Push WorkflowSequenceModel to Stage
	public addWorkflowStage(model: WorkflowStageModel) {
		//Dependency locked is false, break into separate workflow categories
		if (model.dependencyLocked === false) {
			model.workflowStageCategories.forEach(x => {
				let categoryModel = new WorkflowStageCategoryModel();
				categoryModel.categoryId = x.categoryId;
				categoryModel.subCategoryId = x.subCategoryId;
				categoryModel.operationCodes = x.operationCodes;
				categoryModel.isActive = this.categories.some(z => z.id == x.categoryId && z.isActive);;
				let nm = new WorkflowStageModel();
				nm.workflowStageCategories.push(categoryModel);

				this.operatingWorkflowSequenceModel.workflowStages.push(nm);
			});
		} else {
			this.operatingWorkflowSequenceModel.workflowStages.push(model);
		}
		this.updateCategoryUsage();
	}

	public deleteWorkflowStage(workflowStageModel: WorkflowStageModel, workflowSequenceModel: WorkflowSequenceModel) {
		let index = workflowSequenceModel.workflowStages.findIndex(x => x.uniqueIdentifer == workflowStageModel.uniqueIdentifer);
		if (index > -1) {
			workflowSequenceModel.workflowStages.splice(index, 1);
		}
		this.updateCategoryUsage();
	}

	public updateWorkflowStage(model: WorkflowStageModel, workflowSequenceModel: WorkflowSequenceModel) {
		if (model.dependencyLocked === false) {
			let newModels = model.workflowStageCategories.map(x => {
				let categoryModel = new WorkflowStageCategoryModel();
				categoryModel.categoryId = x.categoryId;
				categoryModel.subCategoryId = x.subCategoryId;
				categoryModel.operationCodes = x.operationCodes;
				categoryModel.isActive = this.categories.some(z => z.id == x.categoryId && z.isActive);
				let sm = new WorkflowStageModel();
				sm.workflowStageCategories.push(categoryModel);

				return sm;
			});

			//New to create new models
			let index = workflowSequenceModel.workflowStages.findIndex(x => x.uniqueIdentifer == model.uniqueIdentifer);
			if (index > -1) {
				workflowSequenceModel.workflowStages.splice(index, 1, ...newModels);
			}
		} else {
			let index = workflowSequenceModel.workflowStages.findIndex(x => x.uniqueIdentifer == model.uniqueIdentifer);
			if (index > -1) {
				workflowSequenceModel.workflowStages.splice(index, 1, model);
			}
		}

		this.updateCategoryUsage();
	}

	private updateCategoryUsage() {
		this.categories.forEach(category => {
			category.isUsedInWorkflow = this.workflowModel.workflowDynamicSequences.some(stage => {
				return stage.workflowStages.some(stage => {
					return stage.workflowStageCategories.some(x => x.categoryId == category.id);
				});
			});
		});
	}

	//Add New Workflow Stage
	public addWorkflowSequence(currentIndex: number) {
		this.workflowModel.workflowDynamicSequences.splice(currentIndex + 1, 0, new WorkflowSequenceModel());
	}

	public removeWorkflowSequence(currentIndex: number) {
		if (this.workflowModel.workflowDynamicSequences[currentIndex].workflowStages.length === 0) {
			this.deleteWorkflowSequence(currentIndex)
		} else {
			this.deleteSequenceConfirmationModal.openModal(currentIndex);
		}
	}

	//Remove New Workflow Stage
	public deleteWorkflowSequence(currentIndex: number) {
		this.workflowModel.workflowDynamicSequences.splice(currentIndex, 1);
		this.updateCategoryUsage();
	}

	//Submit form for creation, Remove New Workflow Stage
	public onSubmit() {
		this.submitted = true;
		if (this.form.invalid) {
			return;
		}

		//If Store Id is defined set it
		if (this.store.id) {
			this.workflowModel.storeId = Number(this.store.id);
		}
		this.workflowModel.vehicleQueueId = this.vehicleQueueId;
		this.workflowModel.parentWorkflowId = this.workflowModel.id;
		this.workflowModel.checkInTypeId = Number(this.form.controls.checkInTypeId.value);

		//Filter Out Empty Dynamic Sequence
		this.workflowModel.workflowDynamicSequences = this.workflowModel.workflowDynamicSequences.filter(x => x.workflowStages.length > 0)

		//Iterate and assign sequence
		this.workflowModel.workflowDynamicSequences.forEach((workflowSequenceModel: WorkflowSequenceModel, index: number) => {
			workflowSequenceModel.sequence = index + 1;
			workflowSequenceModel.workflowStages.forEach((workflowStageModel: WorkflowStageModel, idx: number) => {
				workflowStageModel.sequence = idx + 1;
				workflowStageModel.workflowStageCategories.forEach((workflowStageCategoryModel: WorkflowStageCategoryModel, i: number) => {
					workflowStageCategoryModel.sequence = i + 1;
				});
			});
		});


		this.blockUI.start();
		this.workflowService.edit(this.workflowModel).subscribe((data: WorkflowModel) => {
			setTimeout(() => {
				this.messagingService.success('Workflow has been updated successfully.');
			}, 10);
			setTimeout(() => {
				this.router.navigate(['/']);
			}, 200);
			this.blockUI.stop();
		}, error => {
			this.messagingService.ProcessErrorResponse(error);
			this.blockUI.stop();
		});
	}


	//-----------Drag Methods--------------

	selectedCategoryDetailModel: CategoryDetailModel = null;
	categoryDragStart(categoryDetailModel: CategoryDetailModel) {
		this.selectedCategoryDetailModel = categoryDetailModel;
	}
	categoryDragEnd() {
		this.selectedCategoryDetailModel = null;
	}

	selectedWorkflowStageModel: WorkflowStageModel = null;
	workflowStageDragStart(workflowStageModel: WorkflowStageModel) {
		this.selectedWorkflowStageModel = workflowStageModel;
	}
	workflowStageDragEnd() {
		this.selectedWorkflowStageModel = null;
	}

	workflowDropDisableDropIfStageHasCategory(workflowSequenceModel: WorkflowSequenceModel) {
		// if (this.selectedCategoryDetailModel != null) {
		// 	return this.getCategoriesUsedInWorkFlowStage(workflowSequenceModel).includes(this.selectedCategoryDetailModel.id);
		// }
		return false;
	}

	workflowDrop($event: DndDropEvent, workflowSequenceModel: WorkflowSequenceModel) {
		//Handle CATEGORY Drop
		if ($event.type === this.dragType.CATEGORY) {
			let workflowStageCategoryModel = new WorkflowStageCategoryModel();
			workflowStageCategoryModel.categoryId = $event.data.id;
			workflowStageCategoryModel.isActive = this.categories.some(z => z.id == $event.data.id && z.isActive);
			let workflowStageModel = new WorkflowStageModel();
			workflowStageModel.workflowStageCategories.push(workflowStageCategoryModel);

			workflowSequenceModel.workflowStages.push(workflowStageModel);
			this.updateCategoryUsage();
		}
		//Handle WORKFLOWSTAGE Drop
		else if ($event.type === this.dragType.WORKFLOWSTAGE) {

			//Remove Workflow stage from old stage
			this.workflowModel.workflowDynamicSequences.forEach(x => {
				let idx = x.workflowStages.findIndex(y => y.uniqueIdentifer == $event.data.uniqueIdentifer);
				if (idx > -1) {
					x.workflowStages.splice(idx, 1);
				}
			});

			//Push to new stage
			workflowSequenceModel.workflowStages.push($event.data);
		}
	}


	getCategoriesUsedInWorkFlowStage(workflowSequenceModel: WorkflowSequenceModel): Array<number> {
		let categoryIds = new Array<number>();
		workflowSequenceModel.workflowStages.forEach(x => {
			x.workflowStageCategories.forEach(y => {
				categoryIds.push(y.categoryId);
			});
		});
		return categoryIds;
	}

	onCancel() {
		this.router.navigate(['/']);
	}

	ifStageComplete(WorkflowStageModel) {
		var res = false;
		WorkflowStageModel.workflowStageCategories.forEach(x => {
			if (this.lockStageIds.indexOf(x.id) > -1 && this.activeCategory.indexOf(x.id) > -1) {
				res = true;
				return res;
			}
		});
		return res;
	}
}
