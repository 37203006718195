var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI } from 'ng-block-ui';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { CompanyService, FilterStateService, MessagingService } from 'src/app/services';
export class CompanyDetailComponent {
    constructor(router, route, filterStateService, messagingService, companyService) {
        this.router = router;
        this.route = route;
        this.filterStateService = filterStateService;
        this.messagingService = messagingService;
        this.companyService = companyService;
        filterStateService.changeFilterModelStatues(FilterPageType.companyManage, true);
        this.route.params.subscribe(params => this.companyId = params['id']);
    }
    ngOnInit() {
        this.loadCompany();
    }
    loadCompany() {
        this.blockUI.start();
        this.companyService.get(this.companyId).subscribe((data) => {
            this.model = data;
            this.contacts = this.model.contacts.filter(x => !x.isDeleted).map(x => x.name).join(',');
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    openDeleteConfirmation() {
        this.deleteConfirmationModal.openModal(this.model);
    }
    delete(companyDeleteModel) {
        this.blockUI.start();
        this.companyService.delete(companyDeleteModel).subscribe(() => {
            setTimeout(() => {
                this.router.navigate(['/manage/company']);
            });
            this.messagingService.success(`Managing Company has been deleted successfully.`);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    openStatusChangeConfirmation() {
        this.statusChangeConfirmationModal.openModal(this.model);
    }
    toggle(companyStatusToggleModel) {
        this.blockUI.start();
        this.companyService.toggleStatus(companyStatusToggleModel).subscribe(() => {
            this.messagingService.success(`Managing Company has been ${(this.model.isActive ? 'deactivated' : 'activated')} successfully.`);
            this.model.isActive = !this.model.isActive;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    goToEdit() {
        this.router.navigate(['/edit/company', this.model.id]);
    }
}
__decorate([
    BlockUI('container-blockui-company'),
    __metadata("design:type", Object)
], CompanyDetailComponent.prototype, "blockUI", void 0);
