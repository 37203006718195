import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { UnitOfMeasure } from "../enums";
import { DataTableResponseModel, PriceMatrixEngineModel, PriceMatrixAddModel, PriceMatrixDetailModel } from "../models";

@Injectable({
	providedIn: 'root'
})

export class PriceMatrixService {
	constructor(private http: HttpClient) {
	}
	query(dataTablesParameters: any) {
		return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/price-matrix/query`, dataTablesParameters);
	}

	get(id: number): Observable<PriceMatrixDetailModel> {
		return this.http.get<PriceMatrixDetailModel>(`${environment.apiBaseUrl}/price-matrix/${id}`);
	}

	add(data: PriceMatrixAddModel): Observable<PriceMatrixDetailModel> {
		return this.http.post<PriceMatrixDetailModel>(`${environment.apiBaseUrl}/price-matrix`, data);
	}

	update(data: PriceMatrixDetailModel) {
		return this.http.put<PriceMatrixDetailModel>(`${environment.apiBaseUrl}/price-matrix`, data);
	}

	delete(id: number) {
		return this.http.delete<object>(`${environment.apiBaseUrl}/price-matrix/${id}`);
	}

	toggleStatus(id: number) {
		return this.http.patch<object>(`${environment.apiBaseUrl}/price-matrix/toggle-status/${id}`, null);
	}

	getPriceOfSelectedOpCode(storeId: number, workflowId: number, opCodeIds: Array<number>) {
		return this.http.post<Array<PriceMatrixEngineModel>>(`${environment.apiBaseUrl}/price-matrix/store/${storeId}/workflow/${workflowId}/selected-op-code`, opCodeIds);
	}

	getAllPriceMatricesByUnitofMeasure(storeId: number, workflowId: number, unitOfMeasure: UnitOfMeasure) {
		return this.http.get<Array<PriceMatrixDetailModel>>(`${environment.apiBaseUrl}/price-matrix/unit-measure/${unitOfMeasure}/store/${storeId}/workflow/${workflowId}`);
	}

	getPriceMatrixByCategory(storeId: number, workflowId: number, categoryName: string) {
		return this.http.get<Array<PriceMatrixEngineModel>>(`${environment.apiBaseUrl}/price-matrix/store/${storeId}/workflow/${workflowId}/category?categoryName=${categoryName}`);
	}
}