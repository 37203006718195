var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI } from 'ng-block-ui';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { AppUtils } from 'src/app/helpers';
import { ApplicationContextService, FilterStateService, MessagingService, VendorService } from 'src/app/services';
import { environment } from 'src/environments/environment';
export class VendorManageComponent {
    constructor(formBuilder, router, messagingService, appUtils, vendorService, applicationContextService, filterStateService) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.messagingService = messagingService;
        this.appUtils = appUtils;
        this.vendorService = vendorService;
        this.applicationContextService = applicationContextService;
        this.filterStateService = filterStateService;
        this.dtOptions = {};
        this.rowIndex = 0;
        this.filterOptions = this.appUtils.getFilterOperationOptions();
        this.statusOptions = this.appUtils.getStatusOptions();
    }
    ngAfterViewInit() {
        this.updateFormFromFilterModel();
        this.datatableElement.dtInstance.then((dtInstance) => this.dtInstance = dtInstance);
    }
    updateFormFromFilterModel() {
        const tempFilterModel = this.filterStateService.getFilterModel(FilterPageType.vendorManage);
        if (tempFilterModel && tempFilterModel.isActive) {
            this.frmSearch.controls.vendorName.setValue(tempFilterModel.filterKey);
            this.frmSearch.controls.filterOption.setValue(tempFilterModel.filterOption);
            this.frmSearch.controls.statusOption.setValue(tempFilterModel.statusOption);
        }
    }
    updateFilterModel() {
        const filterModel = this.filterStateService.getFilterModel(FilterPageType.vendorManage);
        filterModel.page = FilterPageType.vendorManage;
        filterModel.filterKey = this.frmSearch.controls.vendorName.value;
        filterModel.filterOption = this.frmSearch.controls.filterOption.value;
        filterModel.statusOption = this.frmSearch.controls.statusOption.value;
        filterModel.isActive = false;
    }
    ngOnDestroy() {
        this.storeSubscription.unsubscribe();
    }
    ngOnInit() {
        this.frmSearch = this.formBuilder.group({
            vendorName: [''],
            storeId: [null],
            filterOption: [1],
            statusOption: [2] //Active
        });
        this.storeSubscription = this.applicationContextService.store$.subscribe((storeSelectListItem) => {
            if (storeSelectListItem) {
                this.frmSearch.controls.storeId.setValue(storeSelectListItem.id);
            }
            else {
                this.frmSearch.controls.storeId.setValue(null);
            }
        });
        //Listen to Check-in Type Checkboxes 
        this.frmSearch.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
            this.applyFilter();
        });
        this.initDataTable();
    }
    applyFilter() {
        this.dtInstance.ajax.reload();
    }
    initDataTable() {
        //Data Table Example.
        const self = this;
        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter...',
                paginate: {
                    first: '<em class="fa fa-angle-double-left">',
                    last: '<em class="fa fa-angle-double-right">',
                    previous: '<em class="fa fa-angle-left">',
                    next: '<em class="fa fa-angle-right">'
                }
            },
            search: { search: null },
            searching: false,
            displayStart: self.rowIndex,
            paging: false,
            pagingType: 'full_numbers',
            pageLength: environment.paging.defaultPageSize,
            lengthMenu: environment.paging.lengthMenu,
            order: [[0, 'asc']],
            stateSave: true,
            info: false,
            scrollY: (window.innerHeight - 70 - 45) + "px",
            scrollCollapse: true,
            ajax: (dataTablesParameters, callback) => {
                self.updateFilterModel();
                dataTablesParameters.storeId = self.frmSearch.value.storeId != null ? Number(self.frmSearch.value.storeId) : null;
                dataTablesParameters.name = self.frmSearch.value.vendorName;
                dataTablesParameters.filterBy = Number(self.frmSearch.value.filterOption);
                dataTablesParameters.status = self.statusOptions.find(x => x.id == self.frmSearch.value.statusOption).value;
                self.blockUI.start();
                self.vendorService.query(dataTablesParameters)
                    .subscribe(resp => {
                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: resp.data
                    });
                    self.blockUI.stop();
                }, error => {
                    self.messagingService.ProcessErrorResponse(error);
                    self.blockUI.stop();
                });
            },
            columns: [
                {
                    data: 'name',
                    title: 'Name',
                    width: '15%',
                    render: function (data, type, row) {
                        return `<span class='link' action-type='link-detail'>${row.name}</a>`;
                    }
                },
                {
                    data: 'categories',
                    title: 'Categories',
                    orderable: false,
                    width: '20%',
                    render: function (categories) {
                        return categories.join(', ');
                    }
                },
                {
                    data: 'subCategories',
                    title: 'Tools',
                    orderable: false,
                    width: '20%',
                    render: function (subCategories) {
                        if (!Array.isArray(subCategories))
                            return;
                        return subCategories.join(', ');
                    }
                },
                {
                    data: 'invoiceEmail',
                    title: 'Vendor Accounting Email',
                    width: '15%'
                },
                {
                    data: 'phone',
                    title: 'Phone Number',
                    width: '12%',
                    render: function (data, type, row) {
                        return `<span>${row.phoneCode} ${row.phone}</span>`;
                    }
                },
                {
                    data: 'isActive',
                    title: 'Status',
                    width: '6%',
                    render: function (isActive) {
                        return isActive
                            ? '<span class="badge badge-success">Active</span>'
                            : '<span class="badge badge-danger">Inactive</span>';
                    }
                },
                {
                    data: null,
                    title: 'Action',
                    width: '15%',
                    orderable: false,
                    className: 'text-center',
                    visible: true,
                    render: function (data, type, row) {
                        //Render Activate or Deactivate Button
                        let statusHtml = row.isActive ?
                            `<button title='Deactivate' action-type='toggle-status'><em class="fa fa-ban" aria-hidden="true"></em></button>` :
                            `<button title='Activate' action-type='toggle-status'><em class="fa fa-check" aria-hidden="true"></em></button>`;
                        return `
            <button title='View Detail' action-type='view'><em class="fa fa-eye" aria-hidden="true"></em></button>
            ${statusHtml}
            <button title='Edit' action-type='edit'><em class="fa fa-edit" aria-hidden="true"></em></button>
            <button title='Delete' action-type='delete'><em class="fa fa-trash" aria-hidden="true"></em></button>

            `;
                    }
                }
            ],
            rowCallback: function (row, data, dataIndex) {
                const _row = $(row);
                _row.find('[action-type="link-detail"],[action-type="view"]')
                    .off('click')
                    .on('click', function () {
                    self.router.navigate(['vendor', 'detail', data.id]);
                });
                _row.find('[action-type="edit"]')
                    .off('click')
                    .on('click', function () {
                    self.router.navigate(['edit', 'vendor', data.id]);
                });
                _row.find('[action-type="delete"]')
                    .off('click')
                    .on('click', function () {
                    self.openVendorDeleteModal(data);
                });
                _row.find('[action-type="toggle-status"]')
                    .off('click')
                    .on('click', function () {
                    self.openVendorStatusModal(data);
                });
            }
        };
    }
    openVendorDeleteModal(data) {
        this.deleteConfirmationModal.openModal(data);
    }
    delete(vendor) {
        this.blockUI.start();
        this.vendorService.delete(vendor.id).subscribe(() => {
            setTimeout(() => {
                this.dtInstance.ajax.reload(null, false);
            });
            this.messagingService.success(`vendor has been deleted successfully.`);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
    openVendorStatusModal(data) {
        this.statusChangeConfirmationModal.openModal(data);
    }
    toggle(vendor) {
        this.blockUI.start();
        this.vendorService.toggleStatus(vendor.id).subscribe(() => {
            setTimeout(() => {
                this.dtInstance.ajax.reload(null, false);
            });
            this.messagingService.success(`Vendor has been ${(vendor.isActive ? 'deactivated' : 'activated')} successfully.`);
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
}
__decorate([
    BlockUI('container-blockui-vendor-grid'),
    __metadata("design:type", Object)
], VendorManageComponent.prototype, "blockUI", void 0);
