import { MechanicalOperationCodeModel } from "./mechanical.op-code.model";

export class MechanicalAdviseAddModel {
    public vehicleQueueId: number;
    public vehicleQueueStageId: number;
    public roNumber: string;
    public mechanicalOperationCodes: MechanicalOperationCodeModel[];
    constructor() {
        this.mechanicalOperationCodes = new Array<MechanicalOperationCodeModel>();
    }
}
