<div class="vehiclBoxes-1">
  <div class="vehiclBoxes-conts">
    <div class="fourWheeler-conts" [ngClass]="getQueueStatus()">
      <h3 class="fourWheeler-modal">{{vehicleQueueModel.year}} {{vehicleQueueModel.make}} {{vehicleQueueModel.model}}
      </h3>
      <ul class="fourWheeler-details">
        <li class="fourWheeler-items">{{vehicleQueueModel.basicColor}}</li>
        <li class="fourWheeler-items">{{vehicleQueueModel.mileage}}</li>
        <li class="fourWheeler-items">{{vehicleQueueModel.checkInTypeName}}</li>
      </ul>

      <div class="advsVehicleWrp">
        <div class="vehiclBoxes-nmbr"> <img [src]="clockTimer" alt="Timer" /> <span
            class="vehiclBoxesCnt">{{convertSecondsIntoDays(vehicleQueueModel.reconditioningAgeInSeconds)}}</span></div>
        <div class="fourWheeler-img" *ngIf="vehicleImageUrl"><img [src]="vehicleImageUrl" alt="Vehicle Image" /></div>
      </div>
    </div>

    <div class="fourWheeler-points">
      <div class="driveCom">
        <div class="vehlNumberWrp">
          <ul>
            <li>Store: {{vehicleQueueModel.storeName}}</li>
            <li>SN: {{vehicleQueueModel.stockNumber}}</li>
            <li>VIN: {{vehicleQueueModel.vehicleIdentificationNumber | slice:-8}}</li>
            <li>RO #: {{vehicleQueueModel.repairOrderNumber}}</li>
          </ul>
        </div>
        <div class="vehicleActWrp">
          <app-vehicle-queue-note [vehicleQueueId]="vehicleQueueModel.id"
            [queueNotes]="vehicleQueueModel.vehicleQueueNotes">
          </app-vehicle-queue-note>
          <div ngbDropdown class="actionWrp">
            <button class="btn btn-red" id="dropdownBasic1" ngbDropdownToggle type="button">Action</button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <ul>
                <li *hasPermissions='[PermissionType.VehicleInformationAdjustment]'>
                  <a ngbDropdownItem [routerLink]="['/edit/vehicle-detail',vehicleQueueModel.id]"><em class="fa fa-edit"
                      aria-hidden="true"></em>Edit Vehicle Info</a>
                </li>
                <li *ngIf="showStart()">
                  <a ngbDropdownItem href="javascript:void(0)" (click)="start()">
                    <em class="fa fa-hourglass-start" aria-hidden="true"></em>Start
                  </a>
                </li>
                <li *ngIf="showResume()">
                  <a ngbDropdownItem href="javascript:void(0)" (click)="start()">
                    <em class="fa fa-hourglass-start" aria-hidden="true"></em>Resume
                  </a>
                </li>
                <li *ngIf="showPause()">
                  <a ngbDropdownItem href="javascript:void(0)" (click)="pause()">
                    <em class="fa fa-pause" aria-hidden="true"></em>Pause
                  </a>
                </li>
                <li *ngIf="showStop()">
                  <a ngbDropdownItem href="javascript:void(0)" (click)="stop()">
                    <em class="fa fa-hourglass-end" aria-hidden="true"></em>Finish
                  </a>
                </li>
                <li *ngIf="showStop() || showResume()">
                  <a ngbDropdownItem href="javascript:void(0)" (click)="reject()">
                    <em class="fa fa-ban" aria-hidden="true"></em>Stop
                  </a>
                </li>
                <li *ngIf="showUpdateWorkflow()">
                  <a ngbDropdownItem href="javascript:void(0)" (click)="navigateToUpdateWorkflow()">
                    <em class="fa fa-edit" aria-hidden="true"></em>Update Workflow
                  </a>
                </li>
                <!-- <li>
                  <a ngbDropdownItem href="javascript:void(0)" (click)="revise()">
                    <em class="fa fa-undo" aria-hidden="true"></em>Revise
                  </a>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="assest-points">
        <ngx-slick-carousel class="carousel lpPageSlider" #slickCarouselComponent="slick-carousel"
          [config]="slideConfig">
          <div ngxSlickItem
            *ngFor="let vehicleQueueStage of vehicleQueueModel.vehicleQueueStages;let first = first;let last = last;"
            class="slide assest-Txt-items" [ngClass]="getStageStatus(vehicleQueueStage)"
            (click)="onSlideClick(vehicleQueueStage)">
            <div class="activePoints">
              <div *ngIf="vehicleQueueStage.status == VehicleQueueStatus.InProgress">
                {{vehicleQueueStage.workerUserName}}</div>
            </div>
            <div class="r-poitsWrp" [ngClass]="{'r-poitsWrp-first':first,'r-poitsWrp-last': last}">
              <div class="r-poits">
              </div>
            </div>
            <div class="assest-Txt-nmbr" *ngIf=" vehicleQueueModel.status != VehicleQueueStatus.NotStarted">
              {{convertSecondsIntoDays(vehicleQueueStage.workTimeInSeconds + vehicleQueueStage.queueTimeInSeconds)}}
            </div>
            <div class="assest-Txt-conts">{{vehicleQueueStage.categoryName}} {{ vehicleQueueStage.subCategoryName ? ' - ' +
              vehicleQueueStage.subCategoryName:''}}</div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </div>
</div>

<app-vehicle-type-confirmation #vehicleTypeConfirmationComponent (onSelectVehicleType)="onSelectVehicleType($event)">
</app-vehicle-type-confirmation>
<app-vendor-quote #vendorQuoteComponent [vendors]="vendors" (select)="onSelectVendor($event)">
</app-vendor-quote>
<app-vendor-status #vendorStatusComponent>
</app-vendor-status>
