import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { VehicleType } from 'src/app/enums';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { SelectListModel, VehicleQueueModel } from 'src/app/models';
import { ApplicationContextService, MessagingService, VehicleQueueService } from 'src/app/services';

@Component({
	selector: 'app-edit-vehicle-details',
	templateUrl: './edit.vehicle-details.component.html',
	styleUrls: ['./edit.vehicle-details.component.css']
})
export class EditVehicleDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
	@BlockUI('container-blockui-store') blockUI: NgBlockUI;
	frmVehicleDetail: FormGroup;

	private model: VehicleQueueModel;
	basicColors: Array<string> = new Array<string>();
	vehicleTypes: Array<SelectListModel> = new Array<SelectListModel>();

	submitted: boolean = false;
	storeSubscription: any;
	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private appUtils: AppUtils,
		private messagingService: MessagingService,
		private stringLengthConstants: StringLengthConstants,
		private applicationContextService: ApplicationContextService,
		private vehicleQueueService: VehicleQueueService
	) {
		this.model = new VehicleQueueModel();
		this.route.params.subscribe((data) => {
			this.model.id = Number(data.vehicleQueueModelId);
		});

		this.vehicleTypes = this.appUtils.getVehicleTypes();
	}
	ngOnDestroy(): void {
		this.storeSubscription.unsubscribe();
	}

	ngAfterViewInit() {
		this.storeSubscription = this.applicationContextService.store$.subscribe((storeSelectListItem: SelectListModel) => {
			if (storeSelectListItem) {
				this.model.storeId = Number(storeSelectListItem.id);
			} else {
				this.model.storeId = null;
			}
		});
	}

	ngOnInit() {
		this.basicColors = this.appUtils.getBasicColors();

		this.frmVehicleDetail = this.fb.group({
			year: ['', [RxwebValidators.compose({
				validators: [
					RxwebValidators.required(),
					RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: false }),
					RxwebValidators.minNumber({ value: 1900 }),
					RxwebValidators.maxNumber({ value: new Date().getFullYear() })
				]
			})]],
			make: ['', [RxwebValidators.compose({
				validators: [
					RxwebValidators.maxLength({ value: this.stringLengthConstants.Default })
				]
			})]],
			model: ['', [RxwebValidators.compose({
				validators: [
					RxwebValidators.required(),
					RxwebValidators.maxLength({ value: this.stringLengthConstants.Default }),
				]
			})]],
			basicColor: ['', [RxwebValidators.compose({
				validators: [
					RxwebValidators.required()
				]
			})]],
			vin: ['', [RxwebValidators.compose({
				validators: [
					RxwebValidators.required(),
					RxwebValidators.alphaNumeric(),
					RxwebValidators.upperCase(),
					RxwebValidators.minLength({ value: this.stringLengthConstants.VIN }),
					RxwebValidators.maxLength({ value: this.stringLengthConstants.VIN })
				]
			})]],
			series: ['', [RxwebValidators.compose({
				validators: [
					RxwebValidators.maxLength({ value: this.stringLengthConstants.Default })
				]
			})]],
			stockNumber: ['', [RxwebValidators.compose({
				validators: [
					RxwebValidators.required(),
					RxwebValidators.alphaNumeric(),
					RxwebValidators.upperCase(),
				]
			})]],
			mileage: ['', [RxwebValidators.compose({
				validators: [
					RxwebValidators.required(),
					RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: false })
				]
			})]],
			vehicleType: [VehicleType.None]
		});
		this.getVehicleDetail();
	}

	private getVehicleDetail() {
		this.vehicleQueueService.get(this.model.id).subscribe((data) => {
			this.frmVehicleDetail.patchValue({
				year: data.year,
				make: data.make,
				model: data.model,
				basicColor: data.basicColor || "",
				vin: data.vehicleIdentificationNumber,
				stockNumber: data.stockNumber,
				mileage: data.mileage,
				series: data.series,
				vehicleType: data.vehicleType
			});
		});
	}

	navigateToDashboard() {
		this.router.navigate(["/"]);
	}

	onSubmit() {
		this.submitted = true;
		if (this.frmVehicleDetail.invalid)
			return;

		this.model.vehicleIdentificationNumber = this.frmVehicleDetail.value.vin;
		this.model.stockNumber = this.frmVehicleDetail.value.stockNumber;
		this.model.vehicleIdentificationNumber = this.frmVehicleDetail.value.vin;
		this.model.year = Number(this.frmVehicleDetail.value.year);
		this.model.make = this.frmVehicleDetail.value.make;
		this.model.model = this.frmVehicleDetail.value.model;
		this.model.basicColor = this.frmVehicleDetail.value.basicColor;
		this.model.mileage = Number(this.frmVehicleDetail.value.mileage);
		this.model.series = this.frmVehicleDetail.value.series || null;
		this.model.vehicleType = Number(this.frmVehicleDetail.value.vehicleType);

		this.blockUI.start();
		this.vehicleQueueService.updateVehicleInfo(this.model)
			.subscribe(() => {
				setTimeout(() => {
					this.navigateToDashboard();
				}, 10);
				setTimeout(() => {
					this.messagingService.success("Vehicle info updated successfully.");
				}, 300);
			}, error => {
				this.messagingService.ProcessErrorResponse(error);
				this.blockUI.stop();
			}, () => {
				this.blockUI.stop();
			});
	}
}
