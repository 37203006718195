import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NotificationType } from 'src/app/enums';
import { AppUtils } from 'src/app/helpers';
import { UserDetailModel } from 'src/app/models';
import { MessagingService, UserService } from 'src/app/services';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user.profile.component.html',
    styleUrls: ['./user.profile.component.css']
})
export class UserProfileComponent implements OnInit {
    @BlockUI('container-blockui') blockUI: NgBlockUI;
    model: UserDetailModel;
    roles: string;

    constructor(private userService: UserService,
        public appUtils: AppUtils, 
        private messagingService: MessagingService) {
        this.model = new UserDetailModel();
    }

    ngOnInit() {
        this.loadUser();
    }

    loadUser() {
        this.blockUI.start();
        this.userService.getMyProfile().subscribe((data: UserDetailModel) => {
            this.model = data;
            this.roles = this.model.roles.join(', ');
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }

    getNotificationText(): string {
        let arr = new Array<string>();
        if (this.model != null) {
            if (this.model.isEmailNotificationActive) {
                arr.push('Email');
            }
            if (this.model.isSMSNotificationActive) {
                arr.push('SMS');
            }
        }
        return arr.join(',&nbsp;');
    }
}
