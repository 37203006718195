<header *ngIf="isValidUser">
  <nav class="navbar navbar-expand-xl navbar-toggleable-xl bg-white box-shadow">
    <div class="container-fluid">
      <a class="navbar-brand img-responsive" [routerLink]="['/']">
        <img [src]="applicationLogo" alt="Recon Partners Logo" />
      </a>

      <div class="d-inline-block hDropDownWrp" *ngIf="headerConfig.showControls">
        <form [formGroup]="form" class="">
          <select class="form-control hDropDown" formControlName="company" placeholder="Managing Company"
            (change)="onCompanyChange()" [ngClass]="{ 'is-invalid': submitted && form.controls.company.errors }">
            <option *ngFor="let item of companies" [value]="item.id">
              {{item.name}}
            </option>
          </select>
          <select *ngIf="headerConfig.showStoreControl" class="form-control hDropDown" formControlName="store"
            placeholder="Store" [ngClass]="{ 'is-invalid': submitted && form.controls.store.errors }"
            (change)="onStoreChange()">
            <!-- <option value="">All</option> -->
            <option *ngFor="let item of stores" [value]="item.id">
              {{item.name}}
            </option>
          </select>
        </form>
      </div>


      <button class="navbar-toggler menuButton" type="button" data-toggle="collapse" data-target=".navbar-collapse"
        aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded" (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse d-xl-inline-flex flex-xl-row-reverse headerMenuWrp"
        [ngClass]="{ show: isExpanded }">
        <ul class="navbar-nav flex-grow headerMenu">
          <!-- <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"
            *hasPermissions='[PermissionType.DashboardAccess]'>
            <a class="nav-link" [routerLink]="['/dashboard']">Dashboard</a>
          </li> -->
          <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"
            *hasPermissions='[PermissionType.VehicleImport]'>
            <a class="nav-link" [routerLink]="['/inventory']">Inventory</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"
            *hasPermissions='[PermissionType.Master, PermissionType.ManagingCompanyAdmin, PermissionType.StoreAdmin, PermissionType.VendorAdmin, PermissionType.StoreManagement,
                             PermissionType.WorkflowAddition, PermissionType.WorkflowAdjustment, PermissionType.PricingAdjustmentOPCodesNotIncludedInReconPackage, 
                             PermissionType.PricingAdjustmentOPCodesIncludedInReconPackage]'>
            <div ngbDropdown class="userNameWrp">
              <a class="nav-link" id="dropdownAdmin" ngbDropdownToggle href="javascript:void(0);">Manage</a>
              <div ngbDropdownMenu aria-labelledby="dropdownAdmin">
                <ul class="">
                  <li ngbDropdownItem *hasPermissions='[PermissionType.Master, PermissionType.ManagingCompanyAdmin]'>
                    <a [routerLink]="['/manage/company']">Managing Company</a>
                  </li>
                  <li ngbDropdownItem
                    *hasPermissions='[PermissionType.Master, PermissionType.StoreAdmin, PermissionType.StoreManagement]'>
                    <a [routerLink]="['/manage/store']">Stores</a>
                  </li>
                  <li ngbDropdownItem *hasPermissions='[PermissionType.Master,PermissionType.VendorAdmin]'>
                    <a [routerLink]="['/manage/vendor']">Vendors</a>
                  </li>
                  <li ngbDropdownItem *hasPermissions='[PermissionType.Master]'>
                    <a [routerLink]="['/manage/category']">Categories</a>
                  </li>
                  <li ngbDropdownItem *hasPermissions='[PermissionType.Master]'>
                    <a [routerLink]="['/manage/sub-category']">Tools</a>
                  </li>
                  <li ngbDropdownItem *hasPermissions='[PermissionType.Master]'>
                    <a [routerLink]="['/car-layout-panel']">Panels</a>
                  </li>
                  <li ngbDropdownItem *hasPermissions='[PermissionType.Master]'>
                    <a [routerLink]="['/car-layout-location']">Locations</a>
                  </li>
                  <li ngbDropdownItem *hasPermissions='[PermissionType.Master]'>
                    <a [routerLink]="['/car-layout-side']">Sides</a>
                  </li>
                  <li ngbDropdownItem *hasPermissions='[PermissionType.Master]'>
                    <a [routerLink]="['/manage/op-code']">OP Codes</a>
                  </li>
                  <li ngbDropdownItem *hasPermissions='[PermissionType.Master]'>
                    <a [routerLink]="['/manage/check-in-type']">Check-In Types</a>
                  </li>
                  <li ngbDropdownItem
                    *hasPermissions='[PermissionType.Master, PermissionType.WorkflowAddition, PermissionType.WorkflowAdjustment]'>
                    <a [routerLink]="['/manage/workflow']">Workflow</a>
                  </li>
                  <li ngbDropdownItem
                    *hasPermissions='[PermissionType.Master, PermissionType.PricingAdjustmentOPCodesNotIncludedInReconPackage, PermissionType.PricingAdjustmentOPCodesIncludedInReconPackage]'>
                    <a [routerLink]="['/manage/price-matrix']">Price Matrices</a>
                  </li>
                  <li ngbDropdownItem *hasPermissions='[PermissionType.Master]'>
                    <a [routerLink]="['/manage/role']">Roles</a>
                  </li>
                  <li ngbDropdownItem
                    *hasPermissions='[PermissionType.Master, PermissionType.ManagingCompanyAdmin, PermissionType.StoreAdmin, PermissionType.VendorAdmin]'>
                    <a [routerLink]="['/manage/user']">Users</a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"
            *hasPermissions='[PermissionType.Master, PermissionType.ManagingCompanyAdmin, PermissionType.StoreAdmin, PermissionType.StoreManagement, PermissionType.VendorAccounting]'>
            <a class="nav-link" [routerLink]="['/accounting']">Accounting</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link" [routerLink]="['/faq']">FAQ's</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link" [routerLink]="['/contact-us']">Contact Us</a>
          </li>
          <li>
            <div ngbDropdown class="d-inline-block userNameWrp">
              <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
                <span class="userName">{{user.name}} <em class="fa fa-angle-down" aria-hidden="true"></em></span>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <ul>
                  <li>
                    <a ngbDropdownItem [routerLink]="['/my/profile']"><em class="fa fa-user" aria-hidden="true"></em>
                      Profile</a>
                  </li>
                  <li>
                    <a ngbDropdownItem [routerLink]="['/account/change-password']"><em class="fa fa-key"
                        aria-hidden="true"></em> Change
                      Password</a>
                  </li>
                  <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
                    <a ngbDropdownItem href="javascript:void(0)" (click)="logout()"><em class="fa fa-sign-out"
                        aria-hidden="true"></em>
                      Logout</a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
