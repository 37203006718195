var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators';
import { BlockUI } from 'ng-block-ui';
import { StringLengthConstants } from 'src/app/helpers';
import { VehicleQueueModel } from 'src/app/models';
import { MessagingService, VehicleQueueService } from 'src/app/services';
export class VendorItemRejectionComponent {
    constructor(modalService, formBuilder, stringLengthConstants, messagingService, vehicleQueueService) {
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.stringLengthConstants = stringLengthConstants;
        this.messagingService = messagingService;
        this.vehicleQueueService = vehicleQueueService;
        this.reject = new EventEmitter();
        this.vehicleQueueStageModel = null;
        this.vehicleQueueModel = new VehicleQueueModel();
    }
    openModal(model, rejectedComment, newCost) {
        this.model = model;
        this.form = this.formBuilder.group({
            cost: [newCost || 0, [RxwebValidators.compose({
                        validators: [
                            RxwebValidators.required(),
                            RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
                            RxwebValidators.minNumber({ value: 0 })
                        ]
                    })]],
            comment: [rejectedComment || '', [RxwebValidators.compose({
                        validators: [
                            RxwebValidators.required(),
                            RxwebValidators.maxLength({ value: this.stringLengthConstants.Default }),
                        ]
                    })]]
        });
        this.modalReference = this.modalService.open(this.confirmationModal, { centered: true });
    }
    onSubmit() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        setTimeout(() => {
            this.reject.emit({
                id: this.model.id,
                comment: this.form.controls.comment.value,
                cost: this.form.controls.cost.value
            });
            this.submitted = false;
        }, 0);
    }
    onCancel() {
        if (!this.modalReference) {
            return;
        }
        this.modalReference.close();
    }
    ngOnDestroy() {
        this.onCancel();
    }
}
__decorate([
    BlockUI('container-blockui-confirmation'),
    __metadata("design:type", Object)
], VendorItemRejectionComponent.prototype, "blockUI", void 0);
