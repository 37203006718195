import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApplicationContextService } from './application.context.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./application.context.service";
export class StoreService {
    constructor(http, applicationContextService) {
        this.http = http;
        this.applicationContextService = applicationContextService;
    }
    query(dataTablesParameters) {
        return this.http.post(`${environment.apiBaseUrl}/store/query`, dataTablesParameters);
    }
    get(id) {
        return this.http.get(`${environment.apiBaseUrl}/store/${id}`);
    }
    add(data) {
        return this.http.post(`${environment.apiBaseUrl}/store`, data).pipe(tap(() => {
            this.applicationContextService.update();
        }), catchError(error => {
            throw error;
        }));
    }
    update(data) {
        return this.http.put(`${environment.apiBaseUrl}/store`, data).pipe(tap(() => {
            this.applicationContextService.update();
        }), catchError(error => {
            throw error;
        }));
    }
    clone(data) {
        return this.http.post(`${environment.apiBaseUrl}/store/clone`, data).pipe(tap(() => {
            this.applicationContextService.update();
        }), catchError(error => {
            throw error;
        }));
    }
    delete(id) {
        return this.http.delete(`${environment.apiBaseUrl}/store/${id}`).pipe(tap(() => {
            this.applicationContextService.update();
        }), catchError(error => {
            throw error;
        }));
    }
    associateCategory(id, categoryId) {
        return this.http.post(`${environment.apiBaseUrl}/store/${id}/associate/category/${categoryId}`, null);
    }
    toggleStatus(id) {
        return this.http.patch(`${environment.apiBaseUrl}/store/toggle-status/${id}`, null).pipe(tap(() => {
            this.applicationContextService.update();
        }), catchError(error => {
            throw error;
        }));
    }
    storeSelectList() {
        return this.http.get(`${environment.apiBaseUrl}/store/select-list-stores`);
    }
    allAutoGroupName() {
        return this.http.get(`${environment.apiBaseUrl}/store/all-auto-group-names`);
    }
    autoGroupStoreSelectList(term) {
        return this.http.get(`${environment.apiBaseUrl}/store/auto-group-stores?term=${term}`);
    }
}
StoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StoreService_Factory() { return new StoreService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ApplicationContextService)); }, token: StoreService, providedIn: "root" });
