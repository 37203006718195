import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { ToastrService } from "ngx-toastr";
import { StringLengthConstants } from "src/app/helpers";
import { SelectListModel, SubCategoryAddModel, SubCategoryEditModel } from "src/app/models";
import { CategoryService, MessagingService, SubCategoryService } from "src/app/services";

@Component({
    selector: 'app-addsub--category',
    templateUrl: './add.sub-category.component.html',
    styleUrls: ['./add.sub-category.component.css']
})

export class SubCategoryAddComponent implements OnInit {
    @BlockUI('blockui-container') blockUI: NgBlockUI;
    frmSubCategory: FormGroup;
    submitted: boolean = false;
    model: SubCategoryAddModel;
    categories: Array<SelectListModel>;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private toastr: ToastrService,
        private messagingService: MessagingService,
        private categoryService: CategoryService,
        private subCategoryService: SubCategoryService,
        private stringLengthConstants: StringLengthConstants
    ) {
        this.model = new SubCategoryAddModel();
        this.categories = new Array<SelectListModel>();
    }

    ngOnInit() {
        this.loadCategory();

        this.frmSubCategory = this.formBuilder.group({
            categoryId: ['', [Validators.required]],
            name: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.Name)]],
            code: ['', [Validators.required, Validators.maxLength(this.stringLengthConstants.ShortCode), Validators.minLength(2), Validators.pattern(new RegExp('^[A-Za-z0-9]*$'))]],
            description: ['', [Validators.maxLength(this.stringLengthConstants.Description)]],
            sequence: ['', [Validators.min(1), Validators.pattern(new RegExp('^[-+]?[0-9]*\.?[0-9]*$'))]]
        });
    }

    onSubmit() {
        this.submitted = true;
        if (this.frmSubCategory.invalid) {
            return;
        }

        this.model.categoryId = Number(this.frmSubCategory.controls.categoryId.value);
        this.model.name = this.frmSubCategory.controls.name.value;
        this.model.description = this.frmSubCategory.controls.description.value;
        this.model.code = this.frmSubCategory.controls.code.value;
        this.model.sequence = Number(this.frmSubCategory.controls.sequence.value);
        
        this.subCategoryService.add(this.model)
            .subscribe((data: SubCategoryEditModel) => {
                setTimeout(() => {
                    this.router.navigate(['/manage/sub-category']);
                }, 10);
                setTimeout(() => {
                    this.toastr.success('Tool has been added successfully.');
                }, 300);
                this.blockUI.stop();
            }, error => {
                this.messagingService.ProcessErrorResponse(error);
                this.blockUI.stop();
            });
    }

    private loadCategory() {
        this.blockUI.start();
        this.categoryService.selectList().subscribe((data: any) => {
            this.categories = data;
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
        }, () => {
            this.blockUI.stop();
        });
    }
}
