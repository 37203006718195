var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { BlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { PricingType } from 'src/app/enums';
import { FilterPageType } from 'src/app/enums/filter.page.type.enum';
import { AppUtils, StringLengthConstants } from 'src/app/helpers';
import { OperationCodeEditModel } from 'src/app/models';
import { MessagingService, OperationCodeService, CarLayoutSideService, CarLayoutPanelService, CategoryService, CarLayoutLocationService, SubCategoryService, FilterStateService } from 'src/app/services';
export class EditOpCodeComponent {
    constructor(router, route, messagingService, appUtils, formBuilder, stringLengthConstants, operationCodeService, carLayoutSideService, carLayoutPanelService, categoryService, carLayoutLocationService, subCategoryService, filterStateService) {
        this.router = router;
        this.route = route;
        this.messagingService = messagingService;
        this.appUtils = appUtils;
        this.formBuilder = formBuilder;
        this.stringLengthConstants = stringLengthConstants;
        this.operationCodeService = operationCodeService;
        this.carLayoutSideService = carLayoutSideService;
        this.carLayoutPanelService = carLayoutPanelService;
        this.categoryService = categoryService;
        this.carLayoutLocationService = carLayoutLocationService;
        this.subCategoryService = subCategoryService;
        this.filterStateService = filterStateService;
        this.PricingType = PricingType;
        this.submitted = false;
        this.categorySelectList = new Array();
        this.subCategorySelectList = new Array();
        this.carLayoutSideSelectList = new Array();
        this.carLayoutPanelSelectList = new Array();
        this.carLayoutLocationSelectList = new Array();
        this.vehicleLayoutTypeOptions = new Array();
        filterStateService.changeFilterModelStatues(FilterPageType.opCodeManage, true);
        this.model = new OperationCodeEditModel();
        this.vehicleLayoutTypeOptions = this.appUtils.getVehicleLayoutTypeOptions();
        this.route.params.subscribe(params => {
            this.model.id = params.id;
        });
    }
    ngOnInit() {
        this.loadData();
        this.form = this.formBuilder.group({
            id: [null],
            isLocationSpecific: [false],
            isTiedToIVL: [false],
            vehicleLayoutType: ['',
                [RxwebValidators.required({ conditionalExpression: (x, y) => x.isTiedToIVL == true })]
            ],
            categoryId: ['', [Validators.required]],
            subCategoryId: ['',
                RxwebValidators.required({ conditionalExpression: (x, y) => x.categoryId && Array.isArray(this.subCategorySelectList) && this.subCategorySelectList.length > 0 }),
            ],
            carLayoutPanelId: ['', [
                    RxwebValidators.required({ conditionalExpression: (x, y) => x.isLocationSpecific == true })
                ]],
            carLayoutSideId: ['', []],
            carLayoutLocationId: ['', []],
            description: ['', [Validators.maxLength(this.stringLengthConstants.Default)]]
        });
        this.form.valueChanges.subscribe(() => {
            this.model = this.populateOperationCodeEditModel();
        });
    }
    loadData() {
        this.blockUI.start();
        forkJoin([
            this.categoryService.selectList(),
            this.carLayoutSideService.selectList(),
            this.carLayoutPanelService.selectList(),
            this.carLayoutLocationService.selectList(),
            this.operationCodeService.get(this.model.id)
        ]).subscribe((result) => {
            this.categorySelectList = result[0];
            this.carLayoutSideSelectList = result[1];
            this.carLayoutPanelSelectList = result[2];
            this.carLayoutLocationSelectList = result[3];
            this.model = result[4];
            //If Sub Catgory Exists
            if (this.model.subCategoryId != null) {
                this.subCategoryService.selectList(this.model.categoryId).subscribe((data) => {
                    this.subCategorySelectList = data;
                    this.setFormData();
                    this.blockUI.stop();
                }, error => {
                    this.messagingService.ProcessErrorResponse(error);
                    this.blockUI.stop();
                });
            }
            else {
                this.setFormData();
                this.blockUI.stop();
            }
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    setFormData() {
        this.form.patchValue({
            id: this.model.id,
            isLocationSpecific: this.model.isLocationSpecific,
            isTiedToIVL: this.model.isTiedToIVL,
            vehicleLayoutType: this.model.vehicleLayoutType != 0 ? this.model.vehicleLayoutType : '',
            name: this.model.name,
            description: this.model.description,
            categoryId: this.model.categoryId,
            subCategoryId: this.model.subCategoryId || '',
            carLayoutPanelId: this.model.carLayoutPanelId || '',
            carLayoutLocationId: this.model.carLayoutLocationId || '',
            carLayoutSideId: this.model.carLayoutSideId || ''
        });
    }
    onCategoryChange() {
        if (!this.form.controls.categoryId.value) {
            return;
        }
        this.blockUI.start();
        this.subCategoryService.selectList(Number(this.form.controls.categoryId.value)).subscribe((data) => {
            this.subCategorySelectList = data;
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        this.model = this.populateOperationCodeEditModel();
        this.blockUI.start();
        this.operationCodeService.edit(this.model).subscribe((data) => {
            setTimeout(() => {
                this.router.navigate(['/manage/op-code']);
            }, 10);
            setTimeout(() => {
                this.messagingService.success('Operation Code has been updated successfully.');
            }, 300);
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    populateOperationCodeEditModel() {
        let model = new OperationCodeEditModel();
        model.id = Number(this.form.controls.id.value);
        model.isLocationSpecific = this.form.controls.isLocationSpecific.value;
        model.isTiedToIVL = this.form.controls.isTiedToIVL.value;
        model.vehicleLayoutType = this.form.controls.isTiedToIVL.value ?
            Number(this.form.controls.vehicleLayoutType.value) : 0;
        model.description = this.form.controls.description.value;
        model.categoryId = Number(this.form.controls.categoryId.value);
        if (this.form.controls.subCategoryId.value) {
            model.subCategoryId = Number(this.form.controls.subCategoryId.value);
        }
        else {
            model.subCategoryId = null;
        }
        if (model.isLocationSpecific) {
            if (this.form.controls.carLayoutSideId.value) {
                model.carLayoutSideId = Number(this.form.controls.carLayoutSideId.value);
            }
            else {
                model.carLayoutSideId = null;
            }
            if (this.form.controls.carLayoutPanelId.value) {
                model.carLayoutPanelId = Number(this.form.controls.carLayoutPanelId.value);
            }
            else {
                model.carLayoutPanelId = null;
            }
            if (this.form.controls.carLayoutLocationId.value) {
                model.carLayoutLocationId = Number(this.form.controls.carLayoutLocationId.value);
            }
            else {
                model.carLayoutLocationId = null;
            }
        }
        else {
            model.carLayoutSideId = null;
            model.carLayoutSideId = null;
            model.carLayoutSideId = null;
        }
        model.name = this.createOPCodeName(model);
        return model;
    }
    createOPCodeName(model) {
        let category = this.categorySelectList.find(x => x.id == model.categoryId);
        let subCategory = this.subCategorySelectList.find(x => x.id == model.subCategoryId);
        let carLayoutPanel = this.carLayoutPanelSelectList.find(x => x.id == model.carLayoutPanelId);
        let carLayoutLocation = this.carLayoutLocationSelectList.find(x => x.id == model.carLayoutLocationId);
        let carLayoutSide = this.carLayoutSideSelectList.find(x => x.id == model.carLayoutSideId);
        return this.appUtils.generateOperationCode(category == null ? null : category.code, subCategory == null ? null : subCategory.code, carLayoutPanel == null ? null : carLayoutPanel.code, carLayoutLocation == null ? null : carLayoutLocation.code, carLayoutSide == null ? null : carLayoutSide.code);
    }
}
__decorate([
    BlockUI('container-blockui'),
    __metadata("design:type", Object)
], EditOpCodeComponent.prototype, "blockUI", void 0);
