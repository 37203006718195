import { Component, ViewChild } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { AppUtils } from "src/app/helpers";
import { MessagingService, CarLayoutSideService } from "src/app/services";
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-car-layout-side',
    templateUrl: './manage.car-layout.side.component.html',
    styleUrls: ['./manage.car-layout.side.component.css']
  })
  
export class CarLayoutManageSideComponent{
    @BlockUI('container-blockui-grid') blockUI: NgBlockUI;
    @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtInstance: DataTables.Api;
    rowIndex: number = 0;

    constructor(
        private messagingService: MessagingService,
        private carLayoutPanelService: CarLayoutSideService,
        public appUtils: AppUtils,
    ) {
    }

    
    ngAfterViewInit() {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => this.dtInstance = dtInstance);

        $(document).on('mouseup.custom', function (e) {
            if ($(e.target).closest(".dropdown-menu.action-dropdown").length === 0) {
                $(".dropdown-menu.action-dropdown").hide();
            }
        });
    }

    ngOnDestroy(): void {
        $(document).off('mouseup.custom');
    }

    ngOnInit() {
        this.initDataTable();
    }

    
    private initDataTable() {
        const self = this;
        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                searchPlaceholder: 'Filter user groups...',
                paginate: {
                    first: '<em class="fa fa-angle-double-left">',
                    last: '<em class="fa fa-angle-double-right">',
                    previous: '<em class="fa fa-angle-left">',
                    next: '<em class="fa fa-angle-right">'
                }
            },
            search: { search: null },
            searching: false,
            displayStart: self.rowIndex,
            paging: false,
            pagingType: 'full_numbers',
            pageLength: environment.paging.defaultPageSize,
            lengthMenu: environment.paging.lengthMenu,
            order: [[0, 'asc']],
            info: false,
            stateSave: true,
            scrollY: (window.innerHeight - 70 - 45) + "px",
            scrollCollapse:true,
            ajax: (dataTablesParameters: any, callback) => {
                self.blockUI.start();
                self.carLayoutPanelService.query(dataTablesParameters)
                    .subscribe(resp => {
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
						self.blockUI.stop();
                    }, error => {
                        self.messagingService.ProcessErrorResponse(error);
                        self.blockUI.stop();
                    });
            },
            columns: [
                {
                    data: 'name',
                    title: 'Name',
                    width: '10%'
                },
                {
                    data: 'code',
                    title: 'Code',
                    width: '10%'
                }
            ]
        };
    }
}