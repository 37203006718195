import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CarLayoutPanelService {
    constructor(http) {
        this.http = http;
    }
    query(dataTablesParameters) {
        return this.http.post(`${environment.apiBaseUrl}/car-layout-panel/query`, dataTablesParameters);
    }
    selectList() {
        return this.http.get(`${environment.apiBaseUrl}/car-layout-panel/select-list`);
    }
    selectListByCategory(categoryId) {
        return this.http.get(`${environment.apiBaseUrl}/car-layout-panel/category/${categoryId}/select-list`);
    }
    selectListByCategoryAndSubCategory(categoryId, subCategoryId) {
        return this.http.get(`${environment.apiBaseUrl}/car-layout-panel/category/${categoryId}/sub-category/${subCategoryId}/select-list`);
    }
}
CarLayoutPanelService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CarLayoutPanelService_Factory() { return new CarLayoutPanelService(i0.ɵɵinject(i1.HttpClient)); }, token: CarLayoutPanelService, providedIn: "root" });
