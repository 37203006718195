import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ApprovalService {
    constructor(http) {
        this.http = http;
    }
    submitApproval(model) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/approval`, model);
    }
    submitReconPackageApproval(model) {
        return this.http.post(`${environment.apiBaseUrl}/vehicle/recon-package/approval`, model);
    }
}
ApprovalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApprovalService_Factory() { return new ApprovalService(i0.ɵɵinject(i1.HttpClient)); }, token: ApprovalService, providedIn: "root" });
