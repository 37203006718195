import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { QuotationStatus } from "src/app/enums";
import { QuotationModel } from "src/app/models";
import { QuotationService } from "src/app/services";

@Component({
    selector: 'app-vendor-status',
    templateUrl: './vendor.status.component.html',
    styleUrls: ['./vendor.status.component.css']
})

export class VendorStatusComponent implements OnInit, OnDestroy {
    quotations: Array<QuotationModel> = new Array<QuotationModel>();
    @ViewChild('confirmationModal', { static: false }) confirmationModal: any;
    modalReference: NgbModalRef;

    form: FormGroup;
    submitted: boolean = false;


    constructor(
        private formBuilder: FormBuilder,
        private modalService: NgbModal,
        private quotationService: QuotationService
    ) {

    }

    ngOnInit() {
        this.form = this.formBuilder.group({
        });
    }

    openModal(vehicleQueueStageId: number) {
        this.quotationService.getVehicleQueueStageQuotations(vehicleQueueStageId).subscribe((quotations) => {
            this.quotations = quotations;
            this.modalReference = this.modalService.open(this.confirmationModal, { size: 'lg', centered: true, scrollable: true });
        });
    }

    onSubmit() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        this.onCancel();
    }

    onCancel() {
        if (!this.modalReference) {
            return;
        }
        this.modalReference.close();
    }

    ngOnDestroy(): void {
        this.onCancel();
    }

    public getVendorShortName(str: string) {
        var matches = str.match(/\b(\w)/g);
        return matches.join('');
    }

    public getStatus(status: QuotationStatus): string {
        if (status == QuotationStatus.NotStarted || status == QuotationStatus.Cancelled) {
            return 'Not Started';
        }
        if (status == QuotationStatus.InProgress) {
            return 'In Progress';
        }
        if (status == QuotationStatus.Submitted) {
            return 'Submitted';
        }
        if (status == QuotationStatus.NoBid) {
            return 'No Bid';
        }
        return '';
    }

    public getQuoteAmount(quotation: QuotationModel)  {
        if (quotation == null || !Array.isArray(quotation.quotationItems) || quotation.quotationItems.length == 0) {
            return '';
        }

        return quotation.quotationItems.map(x => x.price).reduce((a, b) => a + b, 0);
    }
}