import { VehicleAgeType, VehicleType } from "src/app/enums";
import { SelectListModel } from "../../generic/select.list.model";

export class VehicleImportModel {
    public id: Nullable<number>;
    public vAutoId: string;
    public vehicleIdentificationNumber: string;
    public year: number;
    public make: string;
    public model: string;
    public modelNumber: string;
    public series: string;
    public color: string;
    public basicColor: string;
    public stockNumber: string;
    public mileage: number;
    public inventoryDate: any;
    public vehicleAgeType: VehicleAgeType;
    public body: string;
    public vehicleType: VehicleType;
    public onHold: boolean;
    public storeIds: Array<number>;
    public isAddedManually: boolean;
    public isImported: boolean;

    public errorMessage: string;
    public storeId: number;
    public workflowId: number;

    constructor() {
        this.storeIds = new Array<number>();
        this.inventoryDate = new Date();
    }
}


export class WorkflowSelectListModel {
    public id: number;
    public name: string;
    public code: string;
    public checkInTypeId: number;
    public checkInTypeName: string;
    public storeId: number;
    public isDefault: boolean;
}

export class AutoImportViewModel {
    public autoImportModel: VehicleImportModel;
    public workflowSelectList: Array<WorkflowSelectListModel>;
    public selectedStores: SelectListModel[];

    constructor(model: VehicleImportModel) {
        this.autoImportModel = model;
        this.workflowSelectList = new Array<WorkflowSelectListModel>();
        this.selectedStores = new Array<SelectListModel>();
    }
}

