var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI } from 'ng-block-ui';
import { WorkflowStageAddCategoriesViewModel } from 'src/app/models';
import { MessagingService, OperationCodeService } from 'src/app/services';
export class WorkflowDetailStageComponent {
    constructor(modalService, messagingService, operationCodeService) {
        this.modalService = modalService;
        this.messagingService = messagingService;
        this.operationCodeService = operationCodeService;
    }
    ngOnChanges(changes) {
    }
    init(model) {
        this.model = model;
        this.viewModels = new Array();
        //Populate categories
        if (this.model.workflowStageCategories.length > 0) {
            this.model.workflowStageCategories.forEach((x, i) => {
                //Populate View MOdel
                let vm = new WorkflowStageAddCategoriesViewModel();
                vm.categoryId = x.categoryId;
                vm.categoryName = this.categories.find(c => c.id === x.categoryId).name;
                vm.subCategoryId = x.subCategoryId;
                if (vm.subCategoryId != null) {
                    vm.subCategoryName = this.subCategories.find(x => x.id === vm.subCategoryId).name;
                }
                vm.selectedOperationCodeIds = x.operationCodes.map((o) => {
                    return o.operationCodeId;
                });
                this.viewModels.push(vm);
                this.onCategoryChange(x.categoryId, i);
            });
        }
    }
    openModal(model) {
        // Clone so that if click on cancelled underlying
        this.init(model);
        this.modalReference = this.modalService.open(this.addNewTask, { size: 'lg', centered: true });
    }
    onClose() {
        this.modalReference.close();
    }
    onCategoryChange(categoryId, index) {
        if (!categoryId) {
            return;
        }
        categoryId = Number(categoryId);
        this.blockUI.start();
        this.operationCodeService.selectListByCategoryId(categoryId).subscribe((data) => {
            let vm = this.viewModels[index];
            vm.selectedOperationCodeSelectList = data.filter(x => vm.selectedOperationCodeIds.includes(x.id)) || new Array();
            this.blockUI.stop();
        }, error => {
            this.messagingService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
}
__decorate([
    BlockUI('container-blockui-work-step'),
    __metadata("design:type", Object)
], WorkflowDetailStageComponent.prototype, "blockUI", void 0);
