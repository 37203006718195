import { VehicleQueueStatus } from "src/app/enums";

export class VehicleQueueRequestModel {

    searchText: string;
    searchByVIN: boolean;
    searchByStockNumber: boolean;
    searchByRONumber: boolean;

    companyId: number;
    storeId: number;
    checkInTypes: Array<number>;
    categoryIds: Array<number>;
    queueStatuses: Array<VehicleQueueStatus>;

    constructor() {
        this.checkInTypes = new Array<number>();
        this.categoryIds = new Array<number>();
        this.queueStatuses = new Array<VehicleQueueStatus>();
    }
}