<section class="workFlowSection">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <a [routerLink]="['/vehicle/approval/',vehicleQueueId,vehicleQueueStageId]" class="backtolist">
                    <em class="fa fa-long-arrow-left" aria-hidden="true"></em>
                    Back to list
                </a>
            </div>
        </div>
        <div class="workFlowWrp">
            <div class="workflowCategory">
                <h2>Category</h2>
                <div class="categoriesWrp">
                    <ng-container *ngFor="let categoryDetailModel of categories">
                        <div class="workflowCat" [dndDraggable]="categoryDetailModel" [dndType]="dragType.CATEGORY"
                            (dndStart)="categoryDragStart(categoryDetailModel)" (dndEnd)="categoryDragEnd()"
                            dndEffectAllowed="move"
                            [dndDisableIf]="!categoryDetailModel.isActive"
                            *ngIf="categoryDetailModel.useToolInWorkflow || !categoryDetailModel.isUsedInWorkflow">
                            <span [ngClass]="{ 'disabled': !categoryDetailModel.isActive }">{{categoryDetailModel.name}}
                            </span>
                            <button *ngIf="!categoryDetailModel.isActive"
                                (click)="associateCategoryToStore(categoryDetailModel)" class="stagebtn-add"
                                title="Associate Category to Store"><em class="fa fa-handshake-o"
                                    aria-hidden="true"></em></button>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="workflowContentWr" *blockUI="'container-blockui'">
                <h2>Update Workflow</h2>
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="col-12 col-md-8">
                            <div class="row">
                                <div class="col-12 col-md-4">
                                    <div class="form-group">
                                        <label>Check-in Type</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label>{{checkInTypeName}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="stageWrp"
                        *ngFor="let workflowSequenceModel of workflowModel.workflowDynamicSequences; let i = index;">

                        <h3>Dynamic Sequence {{i+1}}</h3>
                        <div class="d-flex align-items-center">
                            <div class="stageBox">
                                <div class="taskWr">
                                    <div *ngFor="let workflowStageModel of workflowSequenceModel.workflowStages; let j = index;">
                                        <div *ngIf="ifStageComplete(workflowStageModel)" id="{{workflowStageModel.id}}" >
                                            <app-workflow-stage-detail-widget [categories]='categories'
                                                [subCategories]='subCategories' [workflowStageModel]="workflowStageModel">
                                            </app-workflow-stage-detail-widget>
                                        </div>
                                        <div *ngIf="!ifStageComplete(workflowStageModel)" id="{{workflowStageModel.id}}" 
                                            [dndDraggable]="workflowStageModel" [dndType]="dragType.WORKFLOWSTAGE"
                                            (dndStart)="workflowStageDragStart( workflowStageModel)"
                                            (dndEnd)="workflowStageDragEnd()" dndEffectAllowed="move">

                                            <app-workflow-stage-editable-widget [categories]='categories' 
                                                [subCategories]='subCategories' [workflowStageModel]="workflowStageModel"
                                                (updateWorkflowStage)="updateWorkflowStage($event, workflowSequenceModel)"
                                                (deleteWorkflowStage)="deleteWorkflowStage($event, workflowSequenceModel)">
                                            </app-workflow-stage-editable-widget>

                                        </div>
                                    </div>
                                    <div *ngIf="workflowSequenceModel.sequence > sequenceFilter" class="dragDrop" [dndDropzone]="[dragType.CATEGORY, dragType.WORKFLOWSTAGE]"
                                        [dndHorizontal]="true" [dndDragoverClass]="'drag-placeholder'"
                                        [dndDisableDropIf]="workflowDropDisableDropIfStageHasCategory(workflowSequenceModel)"
                                        (dndDrop)="workflowDrop($event, workflowSequenceModel)">

                                        <a href="javascript:void(0)"
                                            (click)="openAddWorkflowCategoryModal(workflowSequenceModel)">Drag here
                                            / Add New</a>
                                    </div>
                                </div>
                            </div>

                            <div class="stagebtn" *ngIf="workflowSequenceModel.sequence > sequenceFilter">
                                <button type="button" class="stagebtn-sub" (click)="removeWorkflowSequence(i)"
                                    *ngIf="workflowModel.workflowDynamicSequences.length > 1"><em
                                        class="fa fa-minus-circle" aria-hidden="true"></em></button>
                                <button type="button" class="stagebtn-add" (click)="addWorkflowSequence(i)"><em
                                        class="fa fa-plus-circle" aria-hidden="true"></em></button>
                            </div>
                        </div>
                    </div>
                    <div class="btnWrp">
                        <button type="submit" class="btn btn-red">Save</button>
                        <button type="button" class="btn btn-tsrp" (click)="onCancel()">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>

<app-add-workflow-stage #addWorkflowStageModal (added)="addWorkflowStage($event)" [categories]='categories'
    [subCategories]='subCategories'>
</app-add-workflow-stage>
<app-workflow-delete-sequence-confirmation #deleteSequenceConfirmationModal (delete)="deleteWorkflowSequence($event)">
</app-workflow-delete-sequence-confirmation>