export class CompanyListItemModel {
    public id: number;
    public name: string;
    public streetAddress: string;
    public blockAddress: string;
    public city: string;
    public state: string;
    public country: string;
    public email: string;
    public zipCode: string;
    public phoneNumber: string;
    public isActive: boolean;

    public taxId: string;
    public accountMangerId: string;
    public accountMangerName: string;
}