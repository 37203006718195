<section class="advisorSec">
  <div class="container-fluid">
    <div class="main-vehiclSec">
      <form [formGroup]="frmMechDispatch" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-12 col-md-12 col-lg-6">
            <h2>Mechanical</h2>
          </div>
          <div class="col-12 col-md-12 col-lg-6">
            <app-queue-timer [vehicleQueueStageModel]="vehicleQueueStageModel"></app-queue-timer>
          </div>
        </div>
        <div class="dispatch-dtl-FormWrp">
          <div class="tableWrp table-responsive">
            <app-vehicle-info [vehicleQueueModel]="vehicleQueueModel" [showFullVin]="false"></app-vehicle-info>
          </div>
          <div class="addMore">
            <a href="javascript:void(0)" (click)="onAddOpCode()">
              <em class="fa fa-plus-circle fa-custom" aria-hidden="true"></em>
            </a>            
          </div>
          <div class="tableWrp dispatchCostTbl table-responsive">
            <table class="table dataTable table-striped">
              <thead>
                <tr>
                  <th scope="col">Sub Category</th>
                  <th scope="col">Op-Codes</th>
                  <th scope="col" style="width: 90px;">Parts ($)</th>
                  <th scope="col" style="width: 90px;">Labor ($)</th>
                  <th scope="col">Cost ($)</th>
                  <th scope="col" style="width: 250px;">Comment</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody formArrayName="opCodes">
                <tr *ngFor="let opCode of getControls(); let i = index;" [formGroupName]="i">
                  <td style="width: 150px;">
                    <select class="form-control" formControlName="opCodeId" placeholder="Store"
                      [ngClass]="{ 'is-invalid': submitted && opCode.controls.opCodeId.errors }"
                      (change)="onOpCodeSelect(i)">
                      <option value="">Select Sub Category</option>
                      <option *ngFor="let item of opCodes" [value]="item.id">
                        {{item.subCategoryName}}
                      </option>
                    </select>
                    <div *ngIf="submitted && opCode.controls.opCodeId.errors" class="invalid-feedback">
                      Sub Category is required
                    </div>
                  </td>
                  <td>
                    <span [innerHtml]="opCode.controls.opCodeName.value"></span>
                  </td>
                  <td>
                    <input class="form-control" placeholder="" formControlName="parts" mask="separator.2" thousandSeparator=","
                      [ngClass]="{ 'is-invalid': submitted && opCode.controls.parts.errors }" />
                    <div *ngIf="submitted && opCode.controls.parts.errors" class="invalid-feedback">
                      <div *ngIf="opCode.controls.parts.errors.required">
                        Parts is required
                      </div>
                      <div *ngIf="opCode.controls.parts.errors.numeric">
                        Parts should be numbers
                      </div>
                      <div *ngIf="opCode.controls.parts.errors.minNumber">
                        Parts should be min {{opCode.controls.parts.validatorConfig.minNumber.value}}
                      </div>
                    </div>
                  </td>
                  <td>
                    <input class="form-control" placeholder="" formControlName="labor" mask="separator.2" thousandSeparator=","
                      [ngClass]="{ 'is-invalid': submitted && opCode.controls.labor.errors }" />
                    <div *ngIf="submitted && opCode.controls.labor.errors" class="invalid-feedback">
                      <div *ngIf="opCode.controls.labor.errors.required">
                        Labor is required
                      </div>
                      <div *ngIf="opCode.controls.labor.errors.numeric">
                        Labor should be numbers
                      </div>
                      <div *ngIf="opCode.controls.labor.errors.minNumber">
                        Labor should be min {{opCode.controls.labor.validatorConfig.minNumber.value}}
                      </div>
                    </div>
                  </td>
                  <td>
                    <span [innerHtml]="currencyPipe.transform(opCode.controls.cost.value, 'USD', '', '1.2-2')"></span>
                  </td>
                  <td>
                    <textarea class="form-control" placeholder="" formControlName="comment" rows="1" cols="1"
                      [ngClass]="{ 'is-invalid': submitted && opCode.controls.comment.errors }"
                      (blur)="onBlurOnComment($event)">
                    </textarea>
                    <div *ngIf="submitted && opCode.controls.comment.errors" class="invalid-feedback">
                      <div *ngIf="opCode.controls.comment.errors.maxLength">
                        Comment should be max {{opCode.controls.comment.validatorConfig.maxLength.value}}
                      </div>
                    </div>
                  </td>
                  <td class="matrixFromFieldWrp text-center">
                    <div class="yesNoQ" *ngIf="opCode.controls.hasApprovalStatus.value">
                      <span>
                        <input type="radio" id="approve-{{i}}" [value]="ApprovalStatus.Accepted"
                          formControlName="approvalStatus">
                        <label for="approve-{{i}}">Y</label>
                      </span>
                      <span>
                        <input type="radio" id="reject-{{i}}" [value]="ApprovalStatus.Rejected"
                          formControlName="approvalStatus">
                        <label for="reject-{{i}}">N</label>
                      </span>
                    </div>
                    <div>
                      <a *ngIf="getControls().length != 1 && !opCode.controls.hasApprovalStatus.value"
                        href="javascript:void(0)" (click)="removeOpCode(i)">
                        <em class="fa fa-minus-circle fa-custom" aria-hidden="true"></em>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tr>
                <td></td>
                <td class="text-right">Total Price</td>
                <td>
                  <span [innerHtml]="currencyPipe.transform(totalParts, 'USD', '', '1.2-2')"></span>
                </td>
                <td>
                  <span [innerHtml]="currencyPipe.transform(totalLabor, 'USD', '', '1.2-2')"></span>
                </td>
                <td>
                  <span [innerHtml]="currencyPipe.transform(totalCost, 'USD', '', '1.2-2')"></span>
                </td>
                <td></td>
              </tr>
            </table>
          </div>
          <div class="row">
            <div class="col-12 col-md-12">
              <div class="btnWrp text-right mt-0">
                <button class="btn btn-red">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
