import { UnitOfMeasure } from "src/app/enums";

export class PriceMatrixEngineModel {
    public operationCodeId: number;
    public operationCodeName: string;
    public price: number;

    public categoryId: number;
    public categoryName: number;

    public subCategoryId: Nullable<number>;
    public subCategoryName: string;

    public carLayoutPanelId: Nullable<number>;
    public carLayoutPanelName: string;

    public carLayoutLocationId: Nullable<number>;
    public carLayoutLocationName: string;

    public carLayoutSideId: Nullable<number>;
    public carLayoutSideName: string;

    public unitOfMeasure: UnitOfMeasure;
}